.money-transfer-card {
  position: relative;
  width: 400px;
  border-radius: 12px;
}

@media (max-width: 980px) {
  .money-transfer-card {
    width: 100%;
  }
}