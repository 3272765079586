.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-registration {
  max-width: 1300px;
  padding: 50px 50px 130px 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.income-registration__top {
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 39px;
}
.income-registration__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.income-registration__content {
  display: flex;
}
.income-registration__card-wrap {
  width: 670px;
  min-width: 600px;
  margin-right: 60px;
}
.income-registration__card-link-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.income-registration__card-link {
  width: 252px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.income-registration__card-link:hover {
  background: var(--dusk-blue);
}
.income-registration__useful-links-title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.income-registration__useful-links-item-wrap {
  margin-bottom: 10px;
  display: flex;
}
.income-registration__useful-links-item-wrap:last-child {
  margin-bottom: 0;
}
.income-registration__useful-links-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
}
.income-registration__useful-links-item-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
}
.income-registration__useful-links-item-text:hover {
  border-bottom-color: transparent;
}
.income-registration__useful-links-item:disabled .income-registration__useful-links-item-text {
  color: var(--grey-blue);
  border-bottom: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.income-registration__bill-wrap {
  width: 291px;
  min-width: 291px;
  margin-right: 52px;
}
.income-registration__success {
  width: 286px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.income-registration__success-tip {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.income-registration__success-tip-link {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-left: 5px;
}
.income-registration__success-tip-link:hover {
  text-decoration: none;
}
.income-registration__share-block {
  display: flex;
  margin-bottom: 13px;
}
.income-registration__share-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-right: 10px;
  white-space: nowrap;
}
.income-registration__share-list {
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
}
.income-registration__share-item {
  margin-right: 10px;
  padding: 0;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  outline: none;
}
.income-registration__share-item-icon {
  width: 20px;
  height: 15px;
}
.income-registration__share-item-icon_envelope {
  height: 14px;
}
.income-registration__share-item-icon_envelope g {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_envelope g {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_share {
  width: 15px;
  height: 14px;
}
.income-registration__share-item-icon_share path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_share path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_vk path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_vk path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_wa path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_wa path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_ok path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_ok path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_fb path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_fb path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_tg path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_tg path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-link-copied {
  height: 20px;
  border-radius: 10px;
  background-color: #00adb5;
  padding: 0 12px;
  color: white;
  font-size: 12px;
  line-height: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 1194px) {
  .income-registration__card-wrap {
    width: 580px;
    min-width: 580px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 980px) {
  .income-registration {
    padding: 0 0 100px 0;
    margin-top: -90px;
  }
  .income-registration__header {
    background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
    min-height: 220px;
    margin-bottom: 36px;
    position: relative;
    overflow: hidden;
    padding: 90px 50px 35px 50px;
  }
  .income-registration__header_fail {
    margin-bottom: 0;
  }
  .income-registration__header-icon-wrap {
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .income-registration__header-icon {
    fill: var(--deep-sky-blue);
    width: 29px;
    height: 26px;
  }
  .income-registration__header-bg {
    position: absolute;
    right: -80px;
    bottom: -30px;
    width: 260px;
    height: 320px;
    object-fit: contain;
    z-index: 10;
  }
  .income-registration__header-title {
    font-size: 28px;
    line-height: 1.29;
    font-family: "Circe-Bold", sans-serif;
    color: white;
    position: relative;
    z-index: 20;
  }
  .income-registration__content {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    position: relative;
    z-index: 20;
  }
  .income-registration__bill-wrap {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .income-registration__success-tip {
    margin-bottom: 60px;
  }
  .income-registration__success {
    width: auto;
    max-width: 400px;
  }
  .income-registration__card-link-wrap {
    display: flex;
    justify-content: center;
  }
  .income-registration__card-link {
    border-radius: 30px;
    max-width: 375px;
    width: 100%;
  }
  .income-registration__card-wrap {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 800px) {
  .income-registration {
    margin-top: -70px;
  }
  .income-registration__header {
    padding: 70px 50px 35px 50px;
  }
}
@media screen and (max-width: 600px) {
  .income-registration__header {
    padding: 70px 20px 35px 20px;
  }
  .income-registration__share-list {
    flex-wrap: wrap;
  }
  .income-registration__share-link-copied {
    position: absolute;
    bottom: 25px;
  }
}
@media screen and (max-width: 350px) {
  .income-registration__header {
    padding: 70px 14px 35px 14px;
  }
  .income-registration__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
@media screen and (max-width: 980px) {
  .money-request__header__bg {
    position: absolute;
    right: -80px;
    bottom: -30px;
    width: 260px;
    height: 320px;
    object-fit: contain;
    z-index: 10;
  }
}