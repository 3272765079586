.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.partnerPaymentsMenu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
}

.statsSection {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.statsSectionBlock {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.statsSectionBlock > div:nth-child(1) {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #9da6ba;
}
.statsSectionBlock > div:nth-child(2) {
  font-family: "Circe-Regular", sans-serif;
  line-height: 28px;
  font-size: 20px;
  color: #1d2e69;
}

.navSection {
  position: relative;
}

.navButtonGroup {
  display: flex;
  align-items: center;
  height: 40px;
}

.navButton {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  border: 1px solid #e4e8ee;
  background-color: #f6f8fb;
  line-height: 20px;
  font-size: 14px;
  color: #616a88;
}
.navButton:first-child {
  border-radius: 8px 0 0 8px;
}
.navButton:last-child {
  border-radius: 0 8px 8px 0;
}
.navButton > svg {
  width: 16px;
  height: 16px;
}
.navButtonActive {
  border-color: #1d2e69;
  background-color: #1d2e69;
  color: #fff;
}

.navMobileButton {
  display: none;
}

@media (max-width: 980px) {
  .partnerPaymentsMenu {
    flex-direction: column-reverse;
    padding: 20px 0;
    box-shadow: none;
    background-color: transparent;
  }
  .statsSection {
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 6px;
    box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
    background-color: #fff;
  }
  .statsSectionBlock {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 2px;
  }
  .statsSectionBlock:first-child {
    margin-top: 0;
  }
  .statsSectionBlock > div {
    width: 50%;
  }
  .navSection {
    width: 100%;
  }
  .navButton {
    flex: 1;
    paddin: 0;
  }
  .navDesktopButton {
    display: none;
  }
  .navMobileButton {
    display: flex;
  }
}