.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.history {
  padding-top: 50px;
  max-width: 1300px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.history__top {
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 50px;
}
.history__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.history__tabs-wrap {
  position: relative;
  margin-bottom: 41px;
}
.history__list {
  width: 100%;
  display: flex;
}
.history__left-column {
  width: calc((100% - 90px) / 3);
  min-width: calc((100% - 90px) / 3);
  margin-right: 30px;
}
.history__main-column {
  flex-grow: 100;
  display: flex;
  flex-wrap: wrap;
}
.history__item {
  margin-bottom: 20px;
}
.history__item.service_small {
  padding: 18px 48px 23px 30px;
}
.history__item .service__icon-wrap {
  right: 20px;
  top: 34px;
}
.history__item .service_special .service__icon-wrap {
  right: 0;
}
.history__main-column .history__item {
  width: calc((100% - 30px) / 2);
  margin-right: 30px;
}
.history__main-column .history__item:last-child {
  margin-right: 0;
}
.history__statistic-card {
  width: 100%;
  height: 400px;
  display: flex;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.history__statistic-card-nav {
  display: flex;
  position: absolute;
  right: 0;
  top: -30px;
  height: 30px;
  z-index: 10;
}
.history__statistic-card-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 150px;
  text-align: center;
  font-size: 14px;
  height: 30px;
  color: var(--bluey-grey);
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.history__statistic-card-btn_active {
  color: var(--grey-blue);
  background: white;
  position: relative;
  z-index: 30;
}
.history__statistic-card-btn_active + .history__statistic-card-btn {
  margin-left: -20px;
  padding-left: 20px;
}
.history__statistic-card-btn_active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 14px;
  right: 14px;
  height: 1px;
  background: var(--dark-slate-blue);
}
.history__statistic-card-btn:first-child:not(.history__statistic-card-btn_active) {
  margin-right: -20px;
  padding-right: 20px;
}
.history__responsive-table-content {
  margin-bottom: 20px;
  width: 100%;
}
.history__operations {
  position: relative;
  z-index: 20;
}
.history__requests {
  position: relative;
  z-index: 20;
}
.history__user-balance-card {
  margin-bottom: 20px;
}

@media screen and (max-width: 1194px) {
  .history__list {
    display: flex;
    flex-wrap: wrap;
  }
  .history__left-column {
    width: 100%;
    margin-right: 0;
  }
  .history__main-column {
    width: 100%;
  }
  .history__item {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;
  }
  .history__item:nth-child(3) {
    margin-right: 0;
  }
  .history__statistic-card {
    width: 100%;
    height: 400px;
    display: flex;
    margin-bottom: 20px;
  }
  .history__user-balance-card {
    width: 100%;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 980px) {
  .history__user-balance-card {
    margin-bottom: 20px;
  }
  .history__item {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .history__main-column .history__item {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .history {
    padding-left: 20px;
    padding-right: 20px;
  }
  .history__top {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 25px;
  }
  .history__title {
    font-size: 28px;
  }
}
@media screen and (max-width: 350px) {
  .history {
    padding-left: 14px;
    padding-right: 14px;
  }
}