.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-list {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.save-money-list__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.save-money-list__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-list__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.save-money-list__title-wrap {
  margin-bottom: 40px;
}
.save-money-list__title-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.save-money-list__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.save-money-list__count-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5px;
}
.save-money-list__count-text {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 5px;
}
.save-money-list__count {
  font-size: 16px;
  color: #5f6a8b;
  font-family: "Circe-Regular", sans-serif;
}
.save-money-list__content {
  display: flex;
}
.save-money-list__main {
  width: 65.83%;
  min-width: 65.83%;
  margin-right: 30px;
}
.save-money-list__create-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 80px;
  border-radius: 8px;
  border: 1px dashed var(--bluey-grey);
  width: 100%;
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  transition: all 0.3s ease;
  margin-bottom: 30px;
}
.save-money-list__create-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.save-money-list__create-button:hover:not(:disabled) {
  color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
}
.save-money-list__no-goals {
  padding-top: 55px;
}
.save-money-list__no-goals-container {
  position: relative;
  max-width: 440px;
  margin: 0 auto;
}
.save-money-list__no-goals-arrow-icon {
  width: 41px;
  height: 131px;
  position: absolute;
  top: -55px;
  right: calc(100% - 15px);
}
.save-money-list__list-wrap {
  padding-top: 30px;
  border-top: 1px solid rgba(228, 232, 238, 0.5);
}
.save-money-list__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #ffffff;
  padding: 23px 20px 18px 20px;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: default;
}
.save-money-list__item:last-child {
  margin-bottom: 0;
}
.save-money-list__item:disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}
.save-money-list__item-progress {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: 0;
}
.save-money-list__item-progress .progress-bar__progress {
  border-bottom: 0;
}
.save-money-list__item-top {
  display: flex;
  align-items: flex-start;
}
.save-money-list__item-title-wrap {
  max-width: 320px;
  width: 100%;
  margin-right: 40px;
  text-align: left;
  padding-bottom: 0;
}
.save-money-list__item-label {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.save-money-list__item-title {
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  word-break: break-all;
  text-decoration: none;
}
.save-money-list__item-top-right-part {
  flex-grow: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-list__item-sum-wrap {
  text-align: left;
  width: 125px;
  flex-shrink: 0;
  margin-right: 20px;
}
.save-money-list__item-deadline-wrap {
  text-align: left;
  width: 125px;
  flex-shrink: 0;
  margin-right: 20px;
}
.save-money-list__item-sum {
  font-size: 18px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.save-money-list__item-controls {
  display: flex;
  align-items: center;
}
.save-money-list__item-control {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.save-money-list__item-control:last-child {
  margin-right: 0;
}
.save-money-list__item-control:disabled {
  cursor: not-allowed;
}
.save-money-list__item-control-icon {
  pointer-events: none;
}
.save-money-list__item-control-icon_copy path {
  fill: #b0b5c4;
  transition: all 0.3s ease;
}
.save-money-list__item-control:hover:not(:disabled) .save-money-list__item-control-icon_copy path {
  fill: var(--dark-slate-blue);
}
.save-money-list__item-control-icon_edit {
  width: 14px;
  height: 15px;
}
.save-money-list__item-control-icon_edit path {
  transition: all 0.3s ease;
}
.save-money-list__item-control:hover .save-money-list__item-control-icon_edit path {
  fill: var(--dark-slate-blue);
}
.save-money-list__item-control-icon_basket path {
  transition: all 0.3s ease;
}
.save-money-list__item-control:hover .save-money-list__item-control-icon_basket path {
  fill: var(--reddish-pink);
}

@media screen and (max-width: 1194px) {
  .save-money-list__content {
    display: block;
    padding-bottom: 67px;
  }
  .save-money-list__main {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
  }
  .save-money-list__hints {
    width: 100%;
  }
  .save-money-list__item-title-wrap {
    max-width: 500px;
  }
}
@media screen and (max-width: 980px) {
  .save-money-list__container {
    padding-top: 20px;
  }
  .save-money-list__header {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 800px) {
  .save-money-list__item-top {
    flex-direction: column;
  }
  .save-money-list__item-title-wrap {
    margin-bottom: 20px;
  }
  .save-money-list__item-title-wrap {
    margin-right: 0;
    max-width: none;
  }
  .save-money-list__item-top-right-part {
    flex-grow: 0;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .save-money-list__item-sum-wrap {
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  .save-money-list__item-deadline-wrap {
    width: calc(50% - 10px);
    margin-right: 0;
  }
  .save-money-list__item-controls {
    border-top: solid 1px rgba(228, 232, 238, 0.5);
    width: calc(100% + 20px + 20px);
    margin: 20px -20px -18px;
  }
  .save-money-list__item-control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.3333333333%;
    margin-right: 0;
    height: 60px;
    border-right: solid 1px rgba(228, 232, 238, 0.5);
  }
  .save-money-list__item-control:last-child {
    border-right: 0;
  }
  .save-money-list__item-control-icon_copy {
    width: 18px;
    height: 21px;
  }
  .save-money-list__item-control-icon_copy path {
    fill: var(--dark-slate-blue);
  }
  .save-money-list__item-control-icon_edit {
    width: 19px;
    height: 19px;
  }
  .save-money-list__item-control-icon_edit path {
    fill: var(--dark-slate-blue);
  }
  .save-money-list__item-control-icon_basket {
    width: 30px;
    height: 30px;
  }
  .save-money-list__item-control-icon_basket path {
    fill: var(--reddish-pink);
  }
  .save-money-list__copied {
    border-radius: 0 0 8px 8px;
    align-items: center;
    justify-content: center;
    top: auto;
    height: 60px;
    padding: 10px 20px;
  }
}
@media screen and (max-width: 600px) {
  .save-money-list__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .save-money-list__title-wrap {
    width: calc(100% + 20px + 20px);
    margin: 0 -20px 40px;
  }
  .save-money-list__title-container {
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .save-money-list__title {
    font-size: 28px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .save-money-list__item-sum-wrap {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .save-money-list__item-deadline-wrap {
    width: 100%;
  }
}