.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-info-card {
  width: 100%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  padding: 30px 20px 26px 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.tax-info-card__tooltip-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.tax-info-card__tooltip-btn-icon {
  width: 16px;
  height: 16px;
}
.tax-info-card__tooltip-btn-icon path {
  fill: var(--grey-blue);
}
.tax-info-card__tooltip-btn-icon g {
  fill: var(--grey-blue);
}
.tax-info-card__tooltip-wrap {
  position: absolute;
  top: 28px;
  right: 26px;
}
.tax-info-card__tooltip {
  position: absolute;
  top: 27px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 265px;
  min-height: 220px;
  border-radius: 8px;
  background-color: var(--pale-grey);
  padding: 15px 14px 28px 25px;
  opacity: 0;
  visibility: hidden;
}
.tax-info-card__tooltip_open {
  opacity: 1;
  visibility: visible;
}
.tax-info-card__tooltip:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--pale-grey);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0);
}
.tax-info-card__tooltip-text {
  font-size: 10px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 16px;
}
.tax-info-card__tooltip-text:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-card__tooltip-text-wrap .tax-info-card__tooltip-text {
  margin-bottom: 8px;
}
.taxes-to-pay-card__tooltip-text-wrap .tax-info-card__tooltip-text:last-child {
  margin-bottom: 0;
}
.tax-info-card__title {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 14px;
}
.tax-info-card__sum {
  margin-bottom: 15px;
  font-size: 40px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.tax-info-card__bank-details-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--bluey-grey);
  text-decoration: underline;
  transition: color 0.3s ease;
}
.tax-info-card__bank-details-btn:hover {
  color: var(--grey-blue);
}
.tax-info-card__sum-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
.tax-info-card__sum-input {
  background: #f6f8fb;
  border-radius: 8px;
  border: none;
  height: 60px;
  font-size: 40px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 14px;
}
.tax-info-card__sum-icon path {
  fill: #616a88;
}