.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.small-popup-with-emoji {
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-popup-with-emoji__autoheight .small-popup-with-emoji__popup {
  min-height: unset !important;
  height: auto;
}
.small-popup-with-emoji__popup {
  border-radius: 10px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 40px 60px;
  width: 553px;
  position: relative;
  min-height: 471px;
}
.small-popup-with-emoji__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-popup-with-emoji__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-ExtraBold", sans-serif;
  text-align: center;
  margin-bottom: 30px;
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
}
.small-popup-with-emoji__icon-wrap {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-popup-with-emoji__icon {
  width: 40px;
  height: 40px;
}
.small-popup-with-emoji__text {
  margin-bottom: 40px;
}
.small-popup-with-emoji__text p {
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.small-popup-with-emoji__text button,
.small-popup-with-emoji__text a {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  outline: none;
  text-decoration: underline;
  margin-left: 3px;
}
.small-popup-with-emoji__text button:hover,
.small-popup-with-emoji__text a:hover {
  text-decoration: none;
}
.small-popup-with-emoji__button-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.small-popup-with-emoji__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 60px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  margin-right: 20px;
}
.small-popup-with-emoji__button:last-child {
  margin-right: 0;
}
.small-popup-with-emoji__button_grey-border {
  border: 1px solid #616a88;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  color: #616a88;
}
.small-popup-with-emoji__button_grey-border:hover:not(:disabled) {
  background: #616a88;
  color: white;
}
.small-popup-with-emoji__button_red-border {
  border: 1px solid var(--reddish-pink);
  color: var(--reddish-pink);
}
.small-popup-with-emoji__button_red-border:hover:not(:disabled) {
  background: var(--reddish-pink);
  color: white;
}
.small-popup-with-emoji__button_blue-border {
  border: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.small-popup-with-emoji__button_blue-border:hover:not(:disabled) {
  background: var(--dark-slate-blue);
  color: white;
}
.small-popup-with-emoji__button_blue {
  background: var(--dark-slate-blue);
  color: white;
}
.small-popup-with-emoji__button_blue:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.small-popup-with-emoji__close-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
  height: 20px;
  width: 20px;
}
.small-popup-with-emoji__close-button-icon {
  height: 20px;
  width: 20px;
}
.small-popup-with-emoji__close-button-icon path {
  transition: all 0.3s ease;
}
.small-popup-with-emoji__close-button:hover .small-popup-with-emoji__close-button-icon path {
  stroke: var(--deep-sky-blue);
}

@media screen and (max-width: 600px) {
  .small-popup-with-emoji {
    display: block;
    padding: 50px 0;
  }
  .small-popup-with-emoji__popup {
    padding: 70px 15px 30px 15px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
  .small-popup-with-emoji__button {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .small-popup-with-emoji__button:last-child {
    margin-bottom: 0;
  }
}