.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-profile-tab {
  width: 100%;
  padding-left: 110px;
}
.profile-settings-profile-tab__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}
.profile-settings-profile-tab__top-main {
  width: 100%;
}
.profile-settings-profile-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 43px;
  padding-top: 16px;
}
.profile-settings-profile-tab__avatar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-settings-profile-tab__error-massage {
  position: relative;
}
.profile-settings-profile-tab__content {
  display: flex;
  width: 100%;
}
.profile-settings-profile-tab__content-col:first-child {
  width: 310px;
  min-width: 310px;
  margin-right: 60px;
}
.profile-settings-profile-tab__content-col:last-child {
  width: calc(100% - 310px);
}
.profile-settings-profile-tab__limits {
  margin-bottom: 40px;
}
.profile-settings-profile-tab__input {
  margin-bottom: 12px;
}
.profile-settings-profile-tab__input:last-child {
  margin-bottom: 0;
}
.profile-settings-profile-tab__input .input-with-label-animation__input {
  color: #616a88;
}
.profile-settings-profile-tab__input_password .input-with-label-animation__input {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input:-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input:-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-ms-clear {
  display: none;
}
.profile-settings-profile-tab__input-list {
  display: flex;
}
.profile-settings-profile-tab__password-change-sidebar {
  align-items: flex-start;
}
.profile-settings-profile-tab__password-change-inner {
  max-width: 603px;
}
.profile-settings-profile-tab__change-phone-sidebar {
  align-items: center;
}
.profile-settings-profile-tab__change-phone-inner {
  margin-top: -90px;
  max-width: 678px;
}

@media screen and (max-width: 1194px) {
  .profile-settings-profile-tab {
    padding-left: 0;
  }
}
@media screen and (max-width: 800px) {
  .profile-settings-profile-tab {
    padding-bottom: 60px;
  }
  .profile-settings-profile-tab__top {
    display: block;
  }
  .profile-settings-profile-tab__top-main {
    width: 100%;
    margin-bottom: 30px;
  }
  .profile-settings-profile-tab__avatar {
    display: block;
  }
  .profile-settings-profile-tab__avatar .profile-settings-profile-tab__content-col {
    margin-bottom: 30px !important;
  }
  .profile-settings-profile-tab__error-massage-wrap {
    width: 100%;
    min-width: 100%;
    margin-top: 0;
  }
  .profile-settings-profile-tab__content {
    display: block;
  }
  .profile-settings-profile-tab__content-col:first-child {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
  }
  .profile-settings-profile-tab__content-col:last-child {
    width: 100%;
  }
  .profile-settings-profile-tab__input-list {
    display: flex;
  }
  .profile-settings-profile-tab__password-change-sidebar {
    align-items: flex-start;
  }
  .profile-settings-profile-tab__password-change-inner {
    max-width: 603px;
  }
  .profile-settings-profile-tab__change-phone-sidebar {
    align-items: center;
  }
  .profile-settings-profile-tab__change-phone-inner {
    margin-top: -90px;
    max-width: 678px;
  }
}