.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.accounting {
  padding: 54px 50px 50px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.accounting__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 20px;
}
.accounting__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.accounting__nav-wrap {
  position: relative;
  margin-bottom: 41px;
}
.accounting__nav {
  display: flex;
}
.accounting__nav-item {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  margin-right: 40px;
}
.accounting__nav-item:last-child {
  margin-right: 0;
}
.accounting__nav-item_active {
  color: var(--dark-slate-blue);
}
.accounting__nav-bar {
  position: absolute;
  bottom: -6px;
  left: 0;
}
.accounting__nav-bar-active {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  transition: all 0.3s ease;
  border-bottom: 2px dotted var(--dark-slate-blue);
}
.accounting__content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  min-height: 610px;
}
.accounting__content-tab {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  z-index: 10;
}
.accounting__content-tab_active {
  z-index: 20;
}
.accounting__content-tab-list {
  width: calc((100% - 90px) / 3 * 2 + 60px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.accounting__item {
  width: calc((100% - 90px) / 3);
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 20px;
  text-decoration: none;
}
.accounting__item.service_small {
  padding: 17px 44px 23px 23px;
}
.accounting__item_taxes-to-pay-card {
  z-index: 1;
}
.accounting__content-tab-list .accounting__item {
  width: calc((100% - 60px) / 2);
}
.accounting__deals-wrap {
  width: 100%;
  display: flex;
}
.accounting__deals {
  height: 399px;
  width: 64.4%;
  margin-right: 30px;
  margin-left: 15px;
}
.accounting__check-wrap {
  width: 291px;
}
.accounting__check {
  margin-bottom: 18px;
}
.accounting__annul-check-button-wrap {
  display: flex;
  justify-content: center;
}
.accounting__annul-check-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-slate-blue);
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  padding: 0 0 6px 0;
  text-decoration: none;
}
.accounting__annul-check-button:hover {
  border-bottom-color: transparent;
}

@media screen and (max-width: 1194px) {
  .accounting__content {
    min-height: 1300px;
  }
  .accounting__content_deals {
    min-height: 540px;
  }
  .accounting__content-tab-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 900px;
  }
  .accounting__item {
    width: calc((100% - 60px) / 2);
  }
  .accounting__item_taxes {
    width: calc(100% - 30px);
  }
  .accounting__item:first-child {
    width: 100%;
  }
  .accounting__content-tab-list .accounting__item_income-card {
    width: 100%;
  }
  .accounting__deals {
    width: calc(100% - 291px - 45px);
  }
}
@media screen and (max-width: 1000px) {
  .accounting__content {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    min-height: 1400px;
  }
  .accounting__content_deals {
    min-height: 540px;
  }
  .accounting__item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .accounting__content-tab-list .accounting__item {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .accounting__top {
    border-bottom: 0;
  }
  .accounting__title {
    font-size: 28px;
  }
  .accounting__item_taxes {
    height: auto;
  }
  .accounting__item.service_small {
    padding: 17px 20px 23px 23px;
  }
  .accounting__item .service__icon {
    width: 50px;
    height: 70px;
  }
  .accounting__deals {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .accounting__check-wrap {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 600px) {
  .accounting {
    padding-top: 104px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .accounting__nav-wrap {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 20px;
  }
  .accounting__nav-item {
    width: 50%;
    margin-right: 0;
  }
  .accounting__nav-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--bluey-grey);
  }
  .accounting__nav-bar-active {
    position: absolute;
    bottom: -1px;
    top: auto;
    left: 0;
    height: 3px;
    transition: all 0.3s ease;
    border-bottom: 3px solid var(--dark-slate-blue);
  }
}
@media screen and (max-width: 350px) {
  .accounting {
    padding-left: 14px;
    padding-right: 14px;
  }
  .accounting__nav-item {
    font-size: 14px;
  }
}