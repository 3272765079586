.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.avatar-upload {
  position: relative;
}
.avatar-upload__avatar-small {
  width: 90px;
  height: 90px;
}
.avatar-upload__avatar-large {
  width: 120px;
  height: 120px;
}
.avatar-upload__avatar-wrap {
  width: inherit;
  height: inherit;
  border: solid 2px white;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-upload__avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-upload__avatar-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 20px;
  margin-top: -10px;
  margin-left: -12px;
  pointer-events: none;
}
.avatar-upload__avatar-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
}
.avatar-upload__avatar-loading {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--bluey-grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
}
.avatar-upload__dropdown {
  position: absolute;
  top: calc(100% + 3px);
  left: calc(50% - 90px);
  background: var(--white);
  width: 180px;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0px 4px 27px 0px #e8ecf2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px 10px 18px;
}
.avatar-upload__dropdown-item {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.avatar-upload__dropdown-item-icon {
  width: 14px;
  height: 14px;
  margin-right: 9px;
}
.avatar-upload__dropdown-item-name {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-slate-blue);
  width: 100%;
}
.avatar-upload__dropzone-wrap {
  width: inherit;
  height: inherit;
  border: solid 2px white;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.avatar-upload__dropzone-wrap_error {
  border-color: var(--reddish-pink);
}
.avatar-upload__dropzone-wrap section {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
}
.avatar-upload__dropzone-wrap section div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
}

@media screen and (max-width: 800px) {
  .avatar-upload__dropdown {
    left: 0;
  }
}