.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.se-registration-container {
  display: flex;
  flex-grow: 1000;
  position: relative;
}

.se-register {
  width: 100%;
  display: flex;
  flex-grow: 100;
  position: relative;
  z-index: 10;
}
.se-register__container {
  display: flex;
  padding-top: 20vh;
  align-items: flex-start;
  justify-content: space-between;
}
.se-register__step-5 .se-register__container {
  justify-content: flex-start;
}
.se-register__content {
  width: 100%;
  min-width: auto;
  max-width: 560px;
  padding-left: 70px;
  margin-top: 0;
}
.se-register__step-3 .se-register__content {
  max-width: 570px;
}
.se-register__title {
  color: var(--dark-slate-blue);
  font-size: 36px;
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 20px;
  padding-right: 70px;
  line-height: 1.4;
}
.se-register__step-1 .se-register__title {
  margin-bottom: 17px;
  line-height: 1.2;
}
.se-register__step-2 .se-register__title {
  margin-bottom: 23px;
  width: 90%;
}
.se-register__step-4 .se-register__title {
  margin-bottom: 24px;
  padding-right: 20px;
}
.se-register__step-5 .se-register__title {
  margin-bottom: 17px;
}
.se-register__step-no-sz .se-register__title {
  margin-bottom: 24px;
  padding-right: 20px;
}
.se-register__desc-wrap {
  margin-bottom: 30px;
  width: 90%;
}
.se-register__desc-wrap .hints-list__title {
  margin-bottom: 10px;
}
.se-register__desc {
  font-size: 18px;
  line-height: 1.33;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 10px;
}
.se-register__step-4 .se-register__desc {
  font-size: 16px;
}
.se-register__step-no-sz .se-register__desc {
  font-size: 16px;
}
.se-register__desc-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
}
.se-register__desc-link-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
}
.se-register__desc-link-text:hover {
  border-bottom-color: transparent;
}
.se-register__warning-wrap {
  width: 90%;
  margin-bottom: 30px;
}
.se-register__warning {
  position: relative;
  color: var(--grey-blue);
  line-height: 1.5;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.se-register__warning-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 2px;
}
.se-register__warning-link {
  color: var(--dusk-blue);
  line-height: 1.5;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  margin-left: 5px;
  margin-right: 3px;
}
.se-register__warning-link:hover {
  text-decoration: none;
}
.se-register__form {
  margin-bottom: 40px;
}
.se-register__step-3 .se-register__form {
  width: calc(100% - 10px);
}
.se-register__form-block {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}
.se-register__input-holder {
  position: relative;
  max-width: 420px;
  margin-right: 10px;
  width: 100%;
}
.se-register__input-check {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -6px;
}
.se-register__input-check path {
  stroke: var(--dark-slate-blue);
}
.se-register__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  font-size: 18px;
}
.se-register__input::-webkit-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input::-moz-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input:-moz-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input:-ms-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input::-ms-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input::-ms-clear {
  display: none;
}
.se-register__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.se-register__next-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.se-register__next-button:hover {
  background: var(--dusk-blue);
}
.se-register__next-button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.se-register__next-button-icon {
  margin-left: 5px;
}
.se-register__tip {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.se-register__tip-link {
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.se-register__btn-list {
  display: flex;
  align-items: center;
  width: 83%;
}
.se-register__btn {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.se-register__btn:last-child {
  margin-right: 0;
}
.se-register__send-button {
  max-width: 240px;
  width: 100%;
}
.se-register__hint-block {
  width: 50%;
  display: flex;
  justify-content: center;
}
.se-register__step-2 .se-register__hint-block {
  padding-left: 60px;
  padding-top: 50px;
  justify-content: flex-start;
}
.se-register__step-3 .se-register__hint-block {
  justify-content: flex-start;
  padding-left: 60px;
  padding-top: 70px;
}
.se-register__step-4 .se-register__hint-block {
  padding-left: 60px;
  padding-top: 36px;
  justify-content: flex-start;
  flex-direction: column;
}
.se-register__step-no-sz .se-register__hint-block {
  padding-left: 60px;
  padding-top: 36px;
  justify-content: flex-start;
  flex-direction: column;
}
.se-register__step-5 .se-register__hint-block {
  padding-left: 30px;
  padding-top: 61px;
}
.se-register__hint {
  filter: drop-shadow(0 4px 27px #e8ecf2);
  background: white;
  background-size: cover;
  padding: 39px;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  position: relative;
}
.se-register__step-3 .se-register__hint {
  padding: 29px 39px 49px 39px;
}
.se-register__step-4 .se-register__hint {
  padding: 27px 39px 30px 39px;
}
.se-register__step-no-sz .se-register__hint {
  padding: 27px 70px 30px 39px;
}
.se-register__hint-triangle {
  position: absolute;
  bottom: -23px;
  left: 22px;
  width: 29px;
  height: 23px;
}
.se-register__hint-text {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-right: 10px;
}
.se-register__hint-icon_wink {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-register__hint-icon_rock {
  width: 18px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-register__hint-icon_sad {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
  margin-right: 5px;
}
.se-register__hint-icon_man {
  width: 20px;
  height: 19px;
  display: inline-block;
  vertical-align: -3px;
}
.se-register__hint-phone {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-right: 10px;
}
.se-register__hint-phone a {
  text-decoration: none;
  color: var(--dark-slate-blue);
}
.se-register__hint-phone-change {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: inline;
}
.se-register__error-list-wrap {
  margin-top: 7.5vh;
}
.se-register__step-4 .se-register__error-list-wrap {
  margin-top: 9.5vh;
}
.se-register__step-no-sz .se-register__error-list-wrap {
  margin-top: 9.5vh;
}
.se-register__error-list-wrap .hints-list__title {
  margin-bottom: 10px;
}
.se-register__image {
  width: 385px;
  height: 279px;
  position: absolute;
  right: 17%;
  top: 26%;
}

@media screen and (max-height: 750px) {
  .se-register__container {
    padding-top: 10vh;
  }
}
@media screen and (max-width: 1194px) {
  .se-register__container {
    padding-top: 10vh;
  }
  .se-register__content {
    padding-left: 0;
    margin-top: 0;
  }
  .se-register__hint-block {
    width: 40%;
  }
  .se-register__step-2 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-3 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-4 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-no-sz .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-5 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__hint {
    padding: 25px;
  }
  .se-register__image {
    right: 7%;
  }
}
@media screen and (max-height: 600px) {
  .se-register {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1100px) {
  .se-register {
    padding-top: 50px;
    padding-bottom: 200px;
  }
  .se-register__container {
    display: block;
    padding-top: 0;
  }
  .se-register__content {
    width: 100%;
  }
  .se-register__title {
    padding-right: 20px;
  }
  .se-register__step-1 .se-register__title {
    margin-bottom: 27px;
  }
  .se-register__step-2 .se-register__title {
    margin-bottom: 29px;
  }
  .se-register__step-4 .se-register__title {
    margin-bottom: 29px;
  }
  .se-register__step-no-sz .se-register__title {
    margin-bottom: 29px;
  }
  .se-register__step-1 .se-register__desc-wrap {
    width: 90%;
  }
  .se-register__step-4 .se-register__desc-wrap {
    max-width: 100%;
    width: 100%;
  }
  .se-register__step-no-sz .se-register__desc-wrap {
    max-width: 100%;
    width: 100%;
  }
  .se-register__desc {
    color: var(--bluey-grey);
  }
  .se-register__hint-block {
    width: 100%;
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .se-register__step-5 .se-register__hint-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .se-register__hint {
    filter: none;
    padding: 0;
    max-width: 376px;
    width: 100%;
    border-radius: 0;
  }
  .se-register__hint:after {
    display: none;
  }
  .se-register__step-4 .se-register__hint {
    max-width: 400px;
    padding: 0;
    margin-bottom: 30px;
  }
  .se-register__step-no-sz .se-register__hint {
    max-width: 400px;
    padding: 0;
    margin-bottom: 30px;
  }
  .se-register__step-3 .se-register__hint {
    padding: 0;
  }
  .se-register__btn-list {
    width: 100%;
    flex-direction: column;
  }
  .se-register__btn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .se-register__btn:last-child {
    margin-bottom: 0;
  }
  .se-register__step-3 .se-register__btn {
    max-width: 240px;
  }
  .se-register__step-no-sz .se-register__btn {
    max-width: 420px;
  }
  .se-register__step-4 .se-register__error-list-wrap {
    margin-top: 0;
  }
  .se-register__step-no-sz .se-register__error-list-wrap {
    margin-top: 0;
  }
  .se-register__image {
    right: 1%;
    top: 52%;
  }
}
@media screen and (max-width: 700px) {
  .se-register__step-4 .se-register__title {
    line-height: 1.15;
  }
  .se-register__step-no-sz .se-register__title {
    line-height: 1.15;
  }
  .se-register__step-2 .se-register__warning-wrap {
    width: 100%;
  }
  .se-register__warning-icon {
    position: static;
    display: inline-block;
    vertical-align: -4px;
    margin-right: 5px;
  }
  .se-register__input-holder {
    margin-right: 0;
  }
  .se-register__step-4 .se-register__hint-block {
    margin-top: 0;
    margin-bottom: 53px;
  }
  .se-register__step-no-sz .se-register__hint-block {
    margin-top: 0;
    margin-bottom: 53px;
  }
  .se-register__error-list-wrap {
    margin-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .se-register__image {
    right: auto;
    top: 70%;
    left: -20%;
    transform: rotate(15deg);
    z-index: 10;
  }
}
@media screen and (max-width: 360px) {
  .register-container {
    z-index: 70;
  }
  .register-container_menu-open {
    z-index: 50;
  }
}