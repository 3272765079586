.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.business-card-container {
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
.business-card-container_menu-open {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
.business-card-container__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  width: 100%;
  z-index: 110;
}
.business-card-container__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  margin-left: 26px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.business-card-container__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.business-card-container__burger-span path {
  transition: all 0.3s ease;
}
.business-card-container__burger-span:first-child {
  animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card-container__burger-span:nth-child(2) {
  animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.business-card-container__burger-span:nth-child(3) {
  animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:first-child {
  animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(2) {
  animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(3) {
  animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.business-card-container__loader {
  flex-grow: 20;
  position: static;
  position: relative;
}
.business-card-container__error {
  align-items: center;
  display: flex;
  flex-grow: 200;
  justify-content: center;
}
.business-card-container__error-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 20px;
  text-align: center;
}
.business-card-container__link-wrap {
  align-items: flex-start;
  display: flex;
  flex: 4000 0 auto;
  justify-content: center;
  padding-top: 30px;
  width: 100%;
}
.business-card-container__link {
  margin-bottom: 40px;
  position: relative;
  z-index: 100;
}

@media screen and (max-width: 1194px) {
  .business-card-container__header {
    height: 90px;
    min-height: 90px;
    padding-top: 0;
  }
}
@media screen and (max-width: 980px) {
  .business-card-container__header {
    height: 65px;
    min-height: 65px;
    padding-top: 0;
  }
  .business-card-container__link-wrap {
    padding-top: 0;
  }
}