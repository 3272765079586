.partnerPaymentsTab {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.partnerPaymentsTabContent {
  position: relative;
  width: 790px;
}
.partnerPaymentsTabCheck {
  width: 290px;
}

@media (max-width: 1199px) {
  .partnerPaymentsTabCheck {
    display: none;
  }
}
@media (max-width: 980px) {
  .partnerPaymentsTab {
    margin-top: 10px;
  }
  .partnerPaymentsTabContent {
    width: 100%;
  }
}