.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.date-picker-custom-input {
  position: relative;
}
.date-picker-custom-input__itself {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  -webkit-appearance: none;
  outline: none;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
}
.date-picker-custom-input__itself::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself::-ms-clear {
  display: none;
}