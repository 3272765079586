.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint-cancel-request-sz-status__title {
  font-size: 28px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 30px;
}
.hint-cancel-request-sz-status__text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  margin-bottom: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.hint-cancel-request-sz-status__text-link {
  font-size: 15px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-cancel-request-sz-status__text-link:hover {
  text-decoration: none;
}
.hint-cancel-request-sz-status__button-wrap {
  display: flex;
  margin-bottom: 20px;
}
.hint-cancel-request-sz-status__button {
  min-width: 189px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  height: 60px;
  padding: 0 37px;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
  line-height: 60px;
  text-decoration: none;
}
.hint-cancel-request-sz-status__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.hint-cancel-request-sz-status__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.hint-cancel-request-sz-status__continue-button-wrap {
  display: flex;
}
.hint-cancel-request-sz-status__continue-button {
  font-size: 16px;
  color: var(--dark-slate-blue);
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding-bottom: 6px;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.hint-cancel-request-sz-status__continue-button:hover {
  border-bottom-color: transparent;
}

@media screen and (max-width: 980px) {
  .hint-cancel-request-sz-status__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
}