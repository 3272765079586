.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.page-preview-popup {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  width: 70%;
  display: flex;
  align-items: center;
  z-index: 200;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.page-preview-popup-enter {
  opacity: 0;
  left: 100%;
}
.page-preview-popup-enter-active {
  opacity: 1;
  left: 30%;
}
.page-preview-popup-enter-done {
  opacity: 1;
  left: 30%;
}
.page-preview-popup-exit {
  opacity: 1;
  left: 30%;
}
.page-preview-popup-exit-active {
  opacity: 0;
  left: 100%;
}
.page-preview-popup__inner {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.page-preview-popup__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
.page-preview-popup__image {
  max-width: 900px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  max-height: 550px;
  border-radius: 12px;
}
.page-preview-popup__close {
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 38px;
  right: 58px;
  border: none;
}
.page-preview-popup__close path {
  transition: stroke 0.2s ease-in-out;
}
.page-preview-popup__close:hover path {
  stroke: var(--reddish-pink);
}

@media screen and (max-width: 1149px) {
  .page-preview-popup {
    width: 100%;
  }
  .page-preview-popup-enter-active {
    left: 0;
  }
  .page-preview-popup-enter-done {
    left: 0;
  }
  .page-preview-popup-exit {
    left: 0;
  }
}
@media screen and (max-width: 980px) {
  .page-preview-popup {
    align-items: flex-start;
    padding-top: 100px;
  }
  .page-preview-popup__close {
    right: 20px;
  }
}