.money-transfer-card-header-sbp {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 22px;
  border-radius: 12px 12px 0 0;
  background-color: #1d1346;

  &__logo {
    width: 167px;
    height: 46px;
  }
}
