.partnerPaymentsTab {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  &Content {
    position: relative;
    width: 790px;
  }

  &Check {
    width: 290px;
  }
}

@media (max-width: 1199px) {
  .partnerPaymentsTab {
    &Check {
      display: none;
    }
  }
}

@media (max-width: 980px) {
  .partnerPaymentsTab {
    margin-top: 10px;

    &Content {
      width: 100%;
    }
  }
}
