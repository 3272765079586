.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.popupFooter {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 50px;
  background-color: var(--pale-grey);
}
@media (max-width: 480px) {
  .popupFooter {
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
  }
}
.popupFooterLinks {
  display: flex;
  align-items: center;
}
.popupFooterLinks > p {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: var(--light-grey-blue);
}
.popupFooterLink {
  margin-left: 28px;
  text-decoration: none;
}
.popupFooterLinkIconAppStore {
  width: 18px;
  height: 22px;
}
.popupFooterLinkIconGooglePlay {
  width: 16px;
  height: 18px;
  margin-top: 4px;
}
.popupFooterSupport {
  font-family: "Circe-Regular", sans-serif;
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: 14px;
  color: var(--light-grey-blue);
}
@media (max-width: 480px) {
  .popupFooterSupport {
    display: none;
  }
}
.popupFooterSupport:hover {
  text-decoration: none;
}
.popupFooterSupport:hover > div {
  animation: support-button-shaking 0.2s ease-in-out forwards;
  animation-iteration-count: 3;
}
.popupFooterSupportIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-left: 17px;
  border-radius: 50%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
}
.popupFooterSupportIcon {
  width: 21px;
  height: 21px;
}

@keyframes support-button-shaking {
  from, to {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-25deg);
  }
}