@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono|Rubik:400,500&display=swap&subset=cyrillic");
@font-face {
  font-display: swap;
  font-family: "Circe-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Circe-Regular.eot");
  src: local("☺"), url("static/fonts/Circe-Regular.woff") format("woff"), url("static/fonts/Circe-Regular.ttf") format("truetype"), url("static/fonts/Circe-Regular.svg") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Circe-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Circe-Bold.eot");
  src: local("☺"), url("static/fonts/Circe-Bold.woff") format("woff"), url("static/fonts/Circe-Bold.ttf") format("truetype"), url("static/fonts/Circe-Bold.svg") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Circe-ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/Circe-ExtraBold.eot");
  src: local("☺"), url("static/fonts/Circe-ExtraBold.woff") format("woff"), url("static/fonts/Circe-ExtraBold.ttf") format("truetype"), url("static/fonts/Circe-ExtraBold.svg") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

:root {
  --dark-slate-blue: #1d2e69;
  --dusk-blue: #284195;
  --grey-blue: #616a88;
  --normal-grey-blue: #9da6ba;
  --light-grey-blue: #b0b5c4;
  --light-blue: #f2f8ff;
  --pale-grey: #f6f8fb;
  --white: #ffffff;
  --bluey-grey: #b0b5c4;
  --reddish-pink: #ff2d55;
  --light-mustard: #ffd254;
  --turquoise-blue: #00adb5;
  --deep-sky-blue: #007aff;
  --marigold: #ffcc00;
  --robin-s-egg: #5ac8fa;
  --purpley: #6c5ce7;
  --dandelion: #fad961;
  --pumpkin: #f76b1c;
  --orange: #ff8246;
  --save-money-color-theme-1: linear-gradient(to left, var(--robin-s-egg), var(--deep-sky-blue));
  --save-money-color-theme-2: linear-gradient(
    to right,
    var(--reddish-pink) -31%,
    var(--light-mustard) 95%
  );
  --save-money-color-theme-3: linear-gradient(to right, var(--purpley), var(--robin-s-egg) 123%);
  --save-money-color-theme-4: linear-gradient(251deg, #a0ddfc, #7ab0ff);
  --save-money-color-theme-5: linear-gradient(to left, var(--deep-sky-blue), var(--dusk-blue));
  --save-money-color-theme-6: linear-gradient(60deg, var(--pumpkin) -16%, var(--dandelion) 80%);
}

/*RESET CSS*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  font: inherit;
}

input,
textarea {
  outline: none;
}

textarea {
  resize: none;
}

/*HTML5 display-role reset for older browsers*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  outline: none;
}

.fade {
  position: absolute;
  top: 0;
  transition: all 300ms ease-in-out;
}
.fade-enter {
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 0;
  transform: translateY(50px);
}
.fade-enter-active {
  position: absolute !important;
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 1;
  transform: translateY(0);
}
.fade-enter-done {
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 1;
  transform: translateY(0);
}
.fade-exit {
  position: absolute !important;
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 0;
  transform: translateY(-50px);
}

.js-help {
  cursor: pointer;
}

.special-next-btn {
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 100;
}

.animation-text-out {
  opacity: 1;
  transform: translateY(0);
  transition: 0.6s opacity cubic-bezier(0.4, 0, 0.68, 1.94), 0.6s translateY cubic-bezier(0.4, 0, 0.68, 0.06);
}
.animation-text-out-enter {
  opacity: 1;
  transform: translateY(0);
}
.animation-text-out-enter-active {
  opacity: 1;
  transform: translateY(0);
}
.animation-text-out-enter-done {
  opacity: 1;
  transform: translateY(0);
}
.animation-text-out-exit {
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-out-exit-active {
  opacity: 0;
  transform: translateY(-30px);
}

.animation-menu-open_user {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  transition-duration: 300ms;
}
.animation-menu-open_user-enter {
  opacity: 0;
}
.animation-menu-open_user-enter-active {
  opacity: 1;
}
.animation-menu-open_user-enter-done {
  opacity: 1;
}
.animation-menu-open_user-exit {
  opacity: 1;
}
.animation-menu-open_user-exit-active {
  opacity: 0;
}

.animation-menu-open_no-user {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  transition-duration: 500ms;
}
.animation-menu-open_no-user-enter {
  opacity: 0;
}
.animation-menu-open_no-user-enter-active {
  opacity: 1;
}
.animation-menu-open_no-user-enter-done {
  opacity: 1;
}
.animation-menu-open_no-user-exit {
  opacity: 1;
}
.animation-menu-open_no-user-exit-active {
  opacity: 0;
}

.animation-from-top-to-bottom-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  transform: translateY(-30px);
}
.animation-from-top-to-bottom-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  transform: translateY(0);
}
.animation-from-top-to-bottom-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-from-top-to-bottom-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.animation-from-top-to-bottom-normal-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  transform: translateY(-30px);
}
.animation-from-top-to-bottom-normal-enter-active {
  opacity: 1;
  transform: translateY(0);
}
.animation-from-top-to-bottom-normal-exit {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(0);
}
.animation-from-top-to-bottom-normal-exit-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.animation-from-bottom-to-top-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  transform: translateY(30px);
}
.animation-from-bottom-to-top-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  transform: translateY(0);
}
.animation-from-bottom-to-top-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(30px);
}
.animation-from-bottom-to-top-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(30px);
}

.animation-from-bottom-to-top-normal-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  transform: translateY(30px);
}
.animation-from-bottom-to-top-normal-enter-active {
  opacity: 1;
  transform: translateY(0);
}
.animation-from-bottom-to-top-normal-exit {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(0);
}
.animation-from-bottom-to-top-normal-exit-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(30px);
}

.animation-fade-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
}
.animation-fade-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
}
.animation-fade-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
}
.animation-fade-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
}
.animation-fade-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
}

.animation-manual-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  transform: translateY(30px);
}
.animation-manual-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  transform: translateY(0);
}
.animation-manual-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(0);
}
.animation-manual-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(30px);
}

.container {
  max-width: 1440px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.container_small {
  max-width: 1300px;
}

@media screen and (max-width: 980px) {
  .fade-enter {
    transform: translateY(0);
  }
  .fade-enter-active {
    transform: translateY(0);
  }
  .fade-enter-done {
    transform: translateY(0);
  }
  .fade-exit {
    transform: translateY(0);
  }
  .fade-exit-active {
    transform: translateY(0);
  }
}
@media screen and (max-width: 600px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 350px) {
  .container {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.dotted-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  text-decoration: none;
  padding-bottom: 3px;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.dotted-link:hover {
  border-bottom-color: transparent;
}

::-webkit-scrollbar {
  width: 14px;
  border-radius: 15px;
  background-color: var(--white);
  cursor: default;
}

::-webkit-scrollbar-thumb {
  background-color: var(--light-grey-blue);
  border-radius: 30px;
  border: 4px solid var(--white);
  cursor: default;
}

::-webkit-scrollbar-button {
  display: none;
}