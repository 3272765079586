.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-bank-card {
  position: relative;
}
.money-transfer-bank-card__background {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 400px;
  height: 250px;
  border-radius: 12px;
  box-shadow: 0 4px 27px #e8ecf2;
}
.money-transfer-bank-card__card {
  position: relative;
  height: 250px;
  margin-bottom: 20px;
  padding: 0 23px;
  box-shadow: 0 2px 24px 0 rgba(156, 171, 222, 0.39);
}
.money-transfer-bank-card__card-bank-logo {
  width: auto;
  height: 22px;
  margin-top: 34px;
  margin-bottom: 40px;
}
.money-transfer-bank-card__card-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding-top: 40px;
  margin-bottom: 16px;
  font-size: 16px;
  color: var(--dark-slate-blue);
}
.money-transfer-bank-card__card-input-wrap {
  position: relative;
}
.money-transfer-bank-card__card-input {
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  height: 60px;
  background: white;
  -webkit-appearance: none;
  box-shadow: none;
  text-align: left;
  border-radius: 8px;
  border: solid 1px #9da6ba;
  padding: 0 20px;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.money-transfer-bank-card__card-input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input::-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input:-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input:-ms-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input::-ms-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input::-ms-clear {
  display: none;
}
.money-transfer-bank-card__card-input[type=number]::-webkit-outer-spin-button, .money-transfer-bank-card__card-input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.money-transfer-bank-card__card-input[type=number] {
  -moz-appearance: textfield;
}
.money-transfer-bank-card__card-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  cursor: not-allowed;
}
.money-transfer-bank-card__card-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]::-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]:-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]:-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]::-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-clear-button {
  position: absolute;
  right: 22px;
  top: 50%;
  width: 17px;
  height: 17px;
  margin-top: -8px;
  border-radius: 50%;
  background-color: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.money-transfer-bank-card__card-clear-button:hover {
  background: var(--dusk-blue);
}
.money-transfer-bank-card__card-clear-button-icon {
  width: 7px;
  height: 7px;
}
.money-transfer-bank-card__card-clear-button-icon path {
  stroke: white;
}
.money-transfer-bank-card__card-error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-bank-card__card-brand-name {
  position: absolute;
  right: 23px;
  bottom: 23px;
  max-width: 40px;
  max-height: 25px;
}
.money-transfer-bank-card__payment-providers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.money-transfer-bank-card__payment-providers_mobile {
  display: none;
  margin-top: 28px;
}
.money-transfer-bank-card__payment-provider_visa {
  width: 45px;
  height: 20px;
  margin-right: 30px;
}
.money-transfer-bank-card__payment-provider_msc {
  width: 31px;
  height: 19px;
  margin-right: 24px;
}
.money-transfer-bank-card__payment-provider_mir {
  width: 46px;
  height: 19px;
  margin-right: 19px;
}
.money-transfer-bank-card__payment-provider_pci {
  width: 44px;
  height: 18px;
}
.money-transfer-bank-card__nav {
  margin-top: 20px;
}

@media (max-width: 980px) {
  .money-transfer-bank-card__background {
    display: none;
  }
  .money-transfer-bank-card__payment-providers_desktop {
    display: none;
  }
  .money-transfer-bank-card__payment-providers_mobile {
    display: flex;
  }
}
@media (min-width: 1px) {
  .money-transfer-bank-card__card-input {
    font-size: 15px;
  }
}
@media (min-width: 375px) {
  .money-transfer-bank-card__card-input {
    font-size: 18px;
  }
}
@media (min-width: 414px) {
  .money-transfer-bank-card__card-input {
    font-size: 22px;
  }
}