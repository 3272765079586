.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 100%;
  height: 280px;
  min-height: 280px;
}
.taxes__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 36px;
  position: relative;
}
.taxes__header::after {
  background: var(--pale-grey);
  bottom: 0;
  content: "";
  height: 2px;
  left: 30px;
  position: absolute;
  right: 30px;
}
.taxes__wrap {
  bottom: 42px;
  left: 30px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: 68px;
}
.taxes__content {
  overflow-y: auto;
}
.taxes__title {
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}
.taxes__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.taxes__item-title-wrap {
  align-items: center;
  display: flex;
  flex-grow: 20;
  justify-content: space-between;
  padding-right: 60px;
}
.taxes__header .taxes__item-title-wrap {
  flex-grow: unset;
  justify-content: flex-start;
  min-width: 30%;
  padding-right: 0;
  width: 30%;
}
.taxes__item-title-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.taxes__item-icon {
  margin-right: 20px;
}
.taxes__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.taxes__header .taxes__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.taxes__item:hover .taxes__item-title {
  color: var(--dark-slate-blue);
}
.taxes__item_active .taxes__item-title {
  color: var(--dark-slate-blue);
}
.taxes__item-status {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border-radius: 10px;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  min-width: 100px;
  text-align: center;
  width: 100px;
}
.taxes__item-status_not-payed {
  background: rgba(255, 45, 85, 0.1);
  color: var(--reddish-pink);
}
.taxes__item-status_payed {
  background: rgba(0, 173, 181, 0.1);
  color: var(--turquoise-blue);
}
.taxes__item-date {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 12px;
  max-width: 150px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.taxes__header .taxes__item-date {
  align-items: center;
  border: 1px solid rgba(176, 181, 196, 0.1);
  border-radius: 4px;
  display: flex;
  height: 30px;
  justify-content: space-between;
  min-width: 180px;
  width: 180px;
}
.taxes__item:hover .taxes__item-date {
  color: var(--dark-slate-blue);
}
.taxes__item_active .taxes__item-date {
  color: var(--dark-slate-blue);
}
.taxes__item-date-itself {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.taxes__item-date-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}
.taxes__item-date-btn-icon {
  height: 10px;
}
.taxes__item-date-btn-icon path {
  stroke: var(--bluey-grey);
}
.taxes__item-date-btn_prev .taxes__item-date-btn-icon {
  transform: rotate(-180deg);
}
.taxes__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 16px;
  min-width: 90px;
  text-align: right;
  width: auto;
}
.taxes__header .taxes__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
  min-width: 90px;
  width: auto;
}
.taxes__message {
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 16px;
  line-height: 1.5;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 33px;
}
.taxes__message-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 16px;
  text-decoration: underline;
}
.taxes__message-link:hover {
  text-decoration: none;
}
.taxes__year-nav {
  height: 30px;
  align-items: center;
  border: solid 1px var(--bluey-grey);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}
.taxes__year-nav-itself {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 18px;
}
.taxes__year-nav-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}
.taxes__year-nav-btn-icon {
  height: 10px;
}
.taxes__year-nav-btn-icon path {
  stroke: var(--bluey-grey);
}
.taxes__item-date-btn_prev .taxes__year-nav-btn-icon {
  transform: rotate(-180deg);
}
.taxes__track {
  background: transparent;
  border-radius: 4px;
  height: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 10px;
  user-select: none;
  width: 4px;
}
.taxes__thumb {
  background-color: var(--pale-grey);
  border-radius: 2px;
  position: absolute;
  width: 4px;
}
.taxes__item-left {
  min-width: 50%;
  width: 50%;
}
.taxes__item-right {
  min-width: 50%;
  width: 50%;
}
.taxes__loading {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 800px) {
  .taxes {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    width: 100%;
  }
  .taxes__list {
    padding: 0 10px;
  }
  .taxes__item {
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .taxes__item-title {
    margin-bottom: 10px;
    text-align: left;
  }
  .taxes__item-status {
    background: transparent;
    height: auto;
    line-height: normal;
    text-align: right;
    width: auto;
  }
  .taxes__item-date {
    text-align: left;
  }
  .taxes__item-sum {
    margin-bottom: 10px;
    text-align: right;
  }
  .taxes__message {
    line-height: 1.5;
    color: var(--bluey-grey);
    text-align: center;
    font-size: 16px;
    font-family: "Circe-Regular", sans-serif;
    max-width: 54%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 33px;
  }
}