.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tabs-with-scrollable-active-state {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.tabs-with-scrollable-active-state__nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--bluey-grey);
  font-size: 16px;
  padding: 0 0 4px 0;
  text-decoration: none;
  margin-right: 43px;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.tabs-with-scrollable-active-state__nav-item:last-child {
  margin-right: 0;
}
.tabs-with-scrollable-active-state__nav-item:hover {
  color: var(--dusk-blue);
}
.tabs-with-scrollable-active-state__nav-item_active {
  color: var(--dark-slate-blue);
}
.tabs-with-scrollable-active-state__tabs-bar {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
}
.tabs-with-scrollable-active-state__tabs-bar-active {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  transition: all 0.3s ease;
  border-bottom: 2px dotted var(--dark-slate-blue);
}

@media screen and (max-width: 600px) {
  .tabs-with-scrollable-active-state {
    width: calc(100% + 40px - 20px);
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  .tabs-with-scrollable-active-state__nav-item {
    margin-right: 0;
  }
  .tabs-with-scrollable-active-state__tabs-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--bluey-grey);
    width: 100%;
  }
  .tabs-with-scrollable-active-state__tabs-bar-active {
    position: absolute;
    bottom: -1px;
    top: auto;
    left: 0;
    height: 3px;
    transition: all 0.3s ease;
    border-bottom: 3px solid var(--dark-slate-blue);
  }
}