.floatingDetails {
  position: absolute;
  top: 120px;
  right: 64px;
  width: 663px;
  height: 440px;
}
.floatingDetailsShape1, .floatingDetailsShape2, .floatingDetailsShape3, .floatingDetailsShape4, .floatingDetailsShape5, .floatingDetailsShape6, .floatingDetailsShape7, .floatingDetailsShape8, .floatingDetailsShape9 {
  position: absolute;
  pointer-events: none;
}
.floatingDetailsShape1 {
  top: 52.27%;
  left: 93.97%;
  width: 20px;
  height: 20px;
}
.floatingDetailsShape2 {
  top: 24.09%;
  left: 0;
  width: 20px;
  height: 20px;
}
.floatingDetailsShape3 {
  top: 94.42%;
  left: 31.83%;
  width: 23px;
  height: 24px;
}
.floatingDetailsShape4 {
  top: 0;
  left: 33.24%;
  width: 44.83px;
  height: 48.21px;
}
.floatingDetailsShape5 {
  top: 4.7%;
  left: 59.2%;
  width: 25px;
  height: 27px;
}
.floatingDetailsShape6 {
  top: 61.77%;
  left: 70.69%;
  width: 60.28px;
  height: 77.32px;
}
.floatingDetailsShape7 {
  top: 7.5%;
  left: 48.11%;
  width: 228.04px;
  height: 293.49px;
}
.floatingDetailsShape8 {
  top: 29.09%;
  left: 45.85%;
  width: 56px;
  height: 59px;
}
.floatingDetailsShape9 {
  top: 49.59%;
  left: -0.39%;
  width: 84.55px;
  height: 88.45px;
}

@media (max-width: 980px) {
  .floatingDetails {
    top: 90px;
    right: 0;
    width: 50%;
    height: 170px;
  }
}