.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.partnerBankCard {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.partnerBankCardWrapper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 50px 100px;
}
.partnerBankCardFooter {
  margin-top: auto;
}
.partnerBankCardTitle {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 102px;
  line-height: 40px;
  font-size: 32px;
  color: #1d2e69;
}
.partnerBankCardForm {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.partnerBankCardInputWrap {
  width: 240px;
  height: 60px;
  overflow: hidden;
  border: 1px solid #e4e8ee;
  border-radius: 8px;
  background-color: #fff;
}
.partnerBankCardInputTitle {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  line-height: 8px;
  font-size: 8px;
  color: #616a88;
}
.partnerBankCardInput {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin-top: 8px;
  padding: 0 20px;
  border: 0;
  background-color: transparent;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.partnerBankCardInputWrapEmpty {
  background-color: #f6f8fb;
}
.partnerBankCardInputWrapEmpty .partnerBankCardInput {
  font-family: "Circe-Regular", sans-serif;
  height: 100%;
  margin-top: 0;
  border-color: #1d2e69;
  font-size: 18px;
  color: #b0b5c4;
}
.partnerBankCardButton {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 60px;
  margin-left: 16px;
  border-radius: 8px;
  background-color: #1d2e69;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}
.partnerBankCardButton:disabled {
  cursor: not-allowed;
  background-color: #f6f8fb;
  color: #9da6ba;
}
.partnerBankCardText {
  width: 389px;
  margin-top: 50px;
}
.partnerBankCardDescription, .partnerBankCardNotice {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
}
.partnerBankCardDescription {
  color: #616a88;
}
.partnerBankCardNotice {
  margin-top: 16px;
  color: #b0b5c4;
}

@media (max-width: 980px) {
  .partnerBankCardWrapper {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  .partnerBankCardTitle {
    margin-top: 190px;
    line-height: 32px;
    font-size: 24px;
  }
  .partnerBankCardForm {
    flex-direction: column;
    margin-top: 30px;
  }
  .partnerBankCardInputWrap {
    width: 100%;
  }
  .partnerBankCardButton {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
  .partnerBankCardText {
    width: 100%;
    margin-top: 40px;
  }
}