.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.form {
  width: 100%;
}
.form__block-list {
  margin-bottom: 25px;
}
.form__block {
  align-items: center;
  display: flex;
  margin-bottom: 25px;
  position: relative;
}
.register__step-1 .form__block {
  margin-bottom: 21px;
}
.form__block-list .form__block {
  margin-bottom: 10px;
}
.form__block-list .form__block:only-child {
  margin-bottom: 0;
}
.form__block-list .form__block:last-child {
  margin-bottom: 0;
}
.form__input-holder {
  margin-bottom: 11px;
  position: relative;
}
.form_register .form__input-holder {
  flex-grow: 20;
  margin-bottom: 0;
  margin-right: 10px;
  max-width: 420px;
}
.form__block_code .form__input-holder {
  flex-grow: unset;
  margin-right: 16px;
  position: relative;
  width: 48px;
}
.form__block_code .form__input-holder_filled::after {
  opacity: 0;
}
.form__input-holder_blur {
  position: relative;
}
.form__input-holder_blur::after {
  background: black;
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
}
.form__block_code .form__input-holder_error::after {
  background: var(--reddish-pink);
}
.form__input-check {
  margin-top: -6px;
  position: absolute;
  right: 24px;
  top: 50%;
}
.form__block_code .form__input-check {
  margin-top: 0;
  position: relative;
  right: auto;
  top: 0;
}
.form__input {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  -webkit-appearance: none;
  background: white;
  border: 1px solid white;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 60px;
  padding: 0 24px;
  text-align: center;
  width: 100%;
}
.form__input::-webkit-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input::-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input:-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input:-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input::-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input::-ms-clear {
  display: none;
}
.form__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.form__input:disabled {
  opacity: 1;
}
.form_register .form__input {
  font-size: 18px;
  text-align: left;
}
.form_register .form__input::-webkit-input-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input::-moz-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input:-moz-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input:-ms-input-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input::-ms-input-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.password-recovery .form__input {
  text-align: left;
}
.form__input.red {
  border-color: var(--reddish-pink);
  color: var(--reddish-pink);
}
.form__input.yellow {
  border-color: var(--light-mustard);
  color: var(--light-mustard);
}
.form__input.green {
  border-color: var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.form__input.password-visible {
  letter-spacing: 0;
}
.form__input_disabled {
  background: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  box-shadow: none;
  color: #b0b5c4;
}
.form__input_success {
  border: solid 1px var(--turquoise-blue);
  color: var(--turquoise-blue);
  -webkit-text-fill-color: var(--turquoise-blue);
}
.form__input_fail {
  border: solid 1px var(--reddish-pink);
  box-shadow: none;
  color: var(--reddish-pink);
  -webkit-text-fill-color: var(--reddish-pink);
}
.form__block_code .form__input_fail {
  box-shadow: none;
}
.form__input[type=number]::-webkit-outer-spin-button, .form__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form__input[type=number] {
  -moz-appearance: textfield;
}
.form__input-edit {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: -10px 0 0;
  position: absolute;
  right: 30px;
  top: 50%;
}
.se-register__step-2 .form__input-edit {
  right: 40px;
}
.form__link-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.form__link {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 14px;
}
.form__link:hover {
  text-decoration: none;
}
.form__error-wrap .form__link {
  margin-left: 5px;
}
.form__btn-wrap {
  display: flex;
  justify-content: center;
}
.form__next-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background: var(--dusk-blue);
  border-radius: 50%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  height: 60px;
  justify-content: center;
  min-width: 60px;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
  width: 60px;
}
.form__next-btn.visible {
  opacity: 1;
  visibility: visible;
}
.form__next-btn:hover:not(:disabled) {
  background: var(--dark-slate-blue);
}
.form__next-btn:disabled {
  background: var(--pale-grey);
  cursor: not-allowed;
}
.form__next-btn-icon {
  margin-left: 5px;
}
.form__tip {
  font-family: "Circe-Regular", sans-serif;
  color: var(--light-grey-blue);
  font-size: 14px;
}
.register__step-3 .form__tip {
  width: 60%;
}
.form__tip.red {
  color: var(--reddish-pink);
}
.password-recovery .form__tip {
  margin-bottom: 20px;
  text-align: center;
}
.register__step-1 .form__tip {
  margin-bottom: 10px;
}
.form__tip-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--light-grey-blue);
  display: inline;
  font-size: 14px;
  margin-left: 3px;
  text-decoration: underline;
}
.form__tip-link:hover {
  text-decoration: none;
}
.form__agreement {
  align-items: center;
  display: flex;
}
.form__agreement-checkbox {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background-color: var(--pale-grey);
  border: solid 1px var(--bluey-grey);
  border-radius: 50%;
  height: 20px;
  margin: 0 12px 0 0;
  position: relative;
  width: 20px;
  min-width: 20px;
}
.form__agreement-checkbox::after {
  background-color: var(--dusk-blue);
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 8px;
}
.form__agreement-checkbox[aria-checked=true] {
  border: solid 1px var(--dark-slate-blue);
}
.form__agreement-checkbox[aria-checked=true]::after {
  opacity: 1;
}
.form__agreement-text {
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 14px;
}
.form__agreement-text.checked {
  color: var(--dusk-blue);
}
.form__agreement-text:hover {
  text-decoration: none;
}
.form__agreement-text-wrap .form__agreement-text:first-child {
  margin-right: 3px;
}
.form__error-wrap {
  margin-bottom: 20px;
}
.form__error-message {
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 5px;
}
.form__error-wrap .form__error-message {
  display: inline;
  margin-left: 0;
}
.password-recovery .form__error-message {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.form__loader-wrap {
  margin-left: 10px;
  position: relative;
}
.password-recovery .form__small-loader {
  margin-top: -11px;
  position: absolute;
  right: 50px;
  top: 50%;
}
.se-register__step-2 .form__small-loader {
  position: absolute;
  right: 12px;
  top: 20px;
}
.form__password-secure-wrap {
  position: relative;
}
.form__password-secure-lock {
  left: 50%;
  margin-left: -6px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
}
.form__password-secure-lock.red path {
  fill: var(--reddish-pink);
}
.form__password-secure-lock.yellow path {
  fill: var(--light-mustard);
}
.form__password-secure-lock_grey path {
  fill: var(--bluey-grey);
}
.form__password-secure-lock_green path {
  fill: var(--turquoise-blue);
}
.form__password-secure-lock_red path {
  fill: var(--reddish-pink);
}
.se-register .form__input-holder .form__password-secure-lock {
  left: auto;
  margin-left: 0;
  right: 25px;
}

@media screen and (max-width: 700px) {
  .form_responsive-code {
    display: flex;
    flex-direction: column-reverse;
  }
  .form__block {
    display: block;
  }
  .form_register .form__input-holder {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .form_register .form__block_code .form__input-holder {
    margin-bottom: 0;
    margin-right: 16px;
  }
  .form__tip {
    font-size: 18px;
  }
  .register__step-2 .form__tip {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    margin-bottom: 33px;
  }
  .register__step-3 .form__tip {
    font-size: 14px;
    line-height: 1.4;
    width: 90%;
  }
  .register__step-4 .form__tip {
    font-size: 14px;
  }
  .form__tip-link {
    font-size: 18px;
  }
  .register__step-2 .form__tip-link {
    line-height: 1.2;
    text-align: left;
  }
  .register__step-4 .form__tip-link {
    font-size: 14px;
  }
  .form__agreement-wrap {
    margin-bottom: 15px;
  }
  .form__agreement-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 400px) {
  .password-recovery .form__small-loader {
    right: 5px;
  }
}