.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-preview-form__block-title {
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
  color: #616a88;
  font-size: 25px;
  margin-bottom: 20px;
}
.save-money-preview-form__block {
  margin-bottom: 22px;
}
.save-money-preview_preview .save-money-preview-form__block {
  margin-bottom: 10px;
}
.save-money-preview-form__block:last-child {
  margin-bottom: 0;
}
.save-money-preview-form__input-holder {
  position: relative;
}
.save-money-preview-form__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 60px;
  padding: 10px 20px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.save-money-preview_preview .save-money-preview-form__input {
  border-radius: 4px;
  font-size: 8px;
  height: 30px;
  padding: 9px 9px 0;
}
.save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input::-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input::-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input:-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input:-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input:-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input:-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input::-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input::-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input::-ms-clear {
  display: none;
}
.save-money-preview-form__input:focus {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__input:disabled {
  cursor: default;
}
.save-money-preview-form__input:hover:not(:disabled) {
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__input-check {
  bottom: 17px;
  position: absolute;
  right: 20px;
}
.save-money-preview-form__input-check path {
  stroke: var(--dark-slate-blue);
}
.save-money-preview-form__textarea {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 130px;
  padding: 29px 20px 16px;
  transition: all 0.3s ease;
  vertical-align: top;
  width: 100%;
}
.save-money-preview_preview .save-money-preview-form__textarea {
  border-radius: 4px;
  font-size: 8px;
  height: 62px;
  padding: 14px 9px 9px;
}
.save-money-preview-form__textarea::-webkit-input-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea::-moz-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea:-moz-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea:-ms-input-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea::-ms-input-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea::-ms-clear {
  display: none;
}
.save-money-preview-form__textarea:focus {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__textarea:disabled {
  cursor: default;
}
.save-money-preview-form__textarea:hover:not(:disabled) {
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__textarea {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__label {
  font-family: "Circe-Regular", sans-serif;
  color: #9da6ba;
  font-size: 18px;
  left: 24px;
  pointer-events: none;
  position: absolute;
  top: 22px;
  transform: scale(1);
  transform-origin: 0 0;
  transition: all 0.3s ease;
}
.save-money-preview_preview .save-money-preview-form__label {
  font-size: 9px;
  left: 10px;
  top: 10px;
}
.save-money-preview-form__input-holder:focus-within .save-money-preview-form__label {
  color: #616a88;
  left: 20px;
  text-transform: uppercase;
  top: 10px;
  transform: scale(0.6);
  white-space: nowrap;
}
.save-money-preview_preview .save-money-preview-form__input-holder:focus-within .save-money-preview-form__label {
  left: 10px;
  top: 5px;
  transform: scale(0.7);
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__label {
  color: #616a88;
  left: 20px;
  text-transform: uppercase;
  top: 10px;
  transform: scale(0.6);
  white-space: nowrap;
}
.save-money-preview_preview .save-money-preview-form__input-holder_focused .save-money-preview-form__label {
  left: 10px;
  top: 5px;
  transform: scale(0.7);
}
.save-money-preview-form__button-wrap {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}
.save-money-preview-form__button-wrap-preview {
  flex-direction: column;
  gap: 6px;
  display: flex;
  width: 100%;
}
.save-money-preview-form__button-wrap-preview .save-money-preview-form__button.standard-button {
  font-weight: bold;
}
.save-money-preview-form__button-wrap-preview .sbp-button__logo {
  height: 14px;
  margin-left: 4px;
  margin-top: 1px;
}
.save-money-preview_preview .save-money-preview-form__button-wrap {
  margin-bottom: 7px;
}
.save-money-preview_preview .save-money-preview-form__button-wrap .payment-box__button {
  border-radius: 4px;
  font-size: 8px;
  height: 30px;
}
.save-money-preview-form__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border: 1px solid var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 16px;
  height: 60px;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 100%;
}
.save-money-preview_preview .save-money-preview-form__button {
  border-radius: 4px;
  font-size: 8px;
  height: 30px;
}
.save-money-preview-form__button:hover {
  background: var(--dusk-blue);
}
.save-money-preview-form__button[disabled] {
  background-color: var(--pale-grey);
  border-color: var(--pale-grey);
  color: #9da6ba;
  cursor: not-allowed;
}
.save-money-preview-form__privacy-policy {
  font-family: "Circe-Regular", sans-serif;
  color: #616a88;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.43;
  margin-bottom: 30px;
  padding: 0 30px;
  text-align: center;
}
.save-money-preview_preview .save-money-preview-form__privacy-policy {
  font-size: 7px;
  margin-bottom: 14px;
  padding: 0 15px;
}
.save-money-preview-form__privacy-policy-link {
  color: var(--dark-slate-blue);
}
.save-money-preview-form__privacy-policy-link:hover {
  text-decoration: none;
}
.save-money-preview-form__payments-type {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.save-money-preview-form__payments-type-item {
  margin: 0 12px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item {
  margin: 0 6px;
}
.save-money-preview-form__payments-type-item_visa {
  height: 20px;
  width: 45px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_visa {
  height: 10px;
  width: 22px;
}
.save-money-preview-form__payments-type-item_msc {
  height: 19px;
  width: 31px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_msc {
  height: 10px;
  width: 15px;
}
.save-money-preview-form__payments-type-item_mir {
  height: 19px;
  width: 46px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_mir {
  height: 10px;
  width: 22px;
}
.save-money-preview-form__payments-type-item_pci {
  height: 18px;
  width: 44px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_pci {
  height: 10px;
  width: 21px;
}
.save-money-preview-form__iframe-wrap {
  height: 440px;
  position: relative;
}
.save-money-preview-form__iframe-wrap-sbp {
  height: 720px;
  position: relative;
  margin-bottom: 0px;
}
.save-money-preview-form__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 120px;
  z-index: 10;
}
.save-money-preview-form__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 600px) {
  .save-money-preview-form__iframe-wrap-sbp {
    height: 720px;
  }
}