.money-transfer-card-header-sz {
  position: relative;
  height: 92px;
  overflow: hidden;
  padding: 36px 36px 20px 36px;
  border-radius: 12px 12px 0 0;
  background-image: linear-gradient(to right, rgba(0, 122, 255, 1) 0%, rgba(90, 200, 250, 1) 100%);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &__logo {
    position: relative;
    object-fit: contain;
    width: 110px;
    height: 38px;
  }

  &__bg {
    position: absolute;
    z-index: 1;
    right: -60px;
    top: -176px;
    width: 284px;
  }
}
