.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint {
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
  background: white;
  bottom: 0;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  align-items: flex-start;
  padding-top: 22%;
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 200;
}
.hint-enter {
  opacity: 0;
  left: 100%;
}
.hint-enter-active {
  opacity: 1;
  left: 50%;
}
.hint-enter-done {
  opacity: 1;
  left: 50%;
}
.hint-exit {
  opacity: 1;
  left: 50%;
}
.hint-exit-active {
  opacity: 0;
  left: 100%;
}
@media screen and (max-width: 1000px) {
  .hint-enter-active {
    left: 0;
  }
  .hint-enter-done {
    left: 0;
  }
  .hint-exit {
    left: 0;
  }
}
.hint_confirm-data {
  align-items: flex-start;
}
.hint_change-data {
  align-items: flex-start;
}
.hint_lift-limits {
  align-items: flex-start;
}
.hint_hint_no-russian-passport {
  align-items: flex-start;
}
.hint_cant-read-passport {
  align-items: flex-start;
}
.hint__inner {
  padding-left: 63px;
  padding-right: 15px;
  max-width: 601px;
  width: 100%;
}
.hint_change-phone .hint__inner {
  margin-top: -90px;
  max-width: 678px;
}
.hint_lift-limits .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_confirm-data .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_change-data .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_no-russian-passport .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_cant-read-passport .hint__inner {
  max-width: 629px;
  padding-top: 300px;
}
.hint__title {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 28px;
  margin-bottom: 28px;
}
.hint_change-password .hint__title {
  margin-bottom: 38px;
}
.hint__text-wrap {
  margin-bottom: 30px;
}
.hint__text {
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
  font-size: 15px;
  line-height: 1.6;
}
.hint__text_error {
  color: var(--reddish-pink);
}
.hint__text p {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin-bottom: 20px;
}
.hint__text p.mod-no-margin-bottom {
  margin-bottom: 0;
}
.hint__text ul {
  padding: 20px 0;
}
.hint__text ul li {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding-left: 20px;
  position: relative;
}
.hint__text ul li::before {
  background: var(--dark-slate-blue);
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 8px;
}
.hint__text-link {
  font-family: "Circe-Regular", sans-serif;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  color: var(--grey-blue);
  cursor: pointer;
  font-size: 15px;
  line-height: 1.6;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0 0 3px;
  text-decoration: underline;
}
.hint__text-link:hover {
  text-decoration: none;
}
.hint__dropzone-wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.hint__dropzone-wrap section {
  width: calc(100% - 68px - 10px - 60px - 10px);
}
.hint__dropzone-icon {
  margin-right: 10px;
}
.hint__dropzone {
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  border: 1px dashed var(--bluey-grey);
  border-radius: 6px;
  color: var(--grey-blue);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 92px;
  justify-content: center;
}
.hint__dropzone_error {
  border: 1px solid var(--reddish-pink);
}
.hint__dropzone-loader {
  min-width: 60px;
  position: relative;
  width: 60px;
}
.hint__dropzone-loader-progress-check {
  height: 11px;
  left: 50%;
  margin-left: -15px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  width: 14px;
}
.hint__dropzone-loader-progress-lock {
  height: 16px;
  left: 50%;
  margin-left: -6px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 12px;
}
.hint__dropzone-loader-progress-lock path {
  fill: var(--grey-blue);
}
.hint__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 56px;
  top: 48px;
  width: 48px;
}
.hint__close-icon path {
  transition: stroke 0.2s ease-in;
}
.hint__close:hover .hint__close-icon path {
  stroke: var(--reddish-pink);
}
.hint__input-block {
  align-items: center;
  display: flex;
}
.hint__input-holder {
  margin-bottom: 20px;
  position: relative;
}
.hint_change-phone .hint__input-holder::before {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  content: "+7 (";
  font-size: 28px;
  left: 0;
  position: absolute;
  top: 16px;
}
.hint_change-phone .hint__input-holder:focus-within::before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.hint_change-phone .hint__input-holder_filled::before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.hint__input-holder_email {
  max-width: 355px;
}
.hint__input {
  font-family: "Circe-Bold", sans-serif;
  border: none;
  border-bottom: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  font-size: 28px;
  height: 60px;
  padding: 0 20px 0 0;
  width: 100%;
}
.hint_change-phone .hint__input {
  padding: 0 20px 0 46px;
}
.hint_change-phone .hint__input:focus {
  padding: 0 20px 0 0;
}
.hint_change-phone .hint__input-holder_filled .hint__input {
  padding: 0 20px 0 0;
}
.hint__input::-webkit-input-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input::-moz-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input:-moz-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input:-ms-input-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input::-ms-input-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input::-ms-clear {
  display: none;
}
.hint__input-loader-wrap {
  margin-top: -10px;
  position: relative;
}
.hint_change-phone .hint__input-loader-wrap {
  margin-left: 15px;
  margin-top: 0;
}
.hint__input-loader-icon {
  left: 50%;
  position: absolute;
  top: 50%;
}
.hint__input-loader-icon_lock {
  margin-left: -6px;
  margin-top: -8px;
}
.hint__input-loader-wrap_fail .hint__input-loader-icon_lock path {
  fill: var(--grey-blue);
}
.hint__input-loader-wrap_loading .hint__input-loader-icon_lock path {
  fill: var(--grey-blue);
}
.hint__input-loader-icon_lock .hint__input-loader-icon_lock path {
  fill: var(--reddish-pink);
}
.hint__input-loader-icon_check {
  margin-left: -7px;
  margin-top: -6px;
}
.hint__btn-wrap {
  display: flex;
}
.hint__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  padding: 0 60px;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.hint__btn:hover {
  background: var(--dusk-blue);
  transition: all 0.2s ease-in-out;
}
.hint__btn_disabled {
  background: var(--light-grey-blue);
  cursor: not-allowed;
  opacity: 0.2;
}
.hint__btn_disabled:hover {
  background: var(--light-grey-blue);
}
.hint__tip {
  font-family: "Circe-Regular", sans-serif;
  color: var(--light-grey-blue);
  font-size: 14px;
  line-height: 1.5;
  max-width: 348px;
  width: 100%;
}
.hint__tip_error {
  color: var(--reddish-pink);
}
.hint__bank-card-details {
  margin-bottom: 20px;
}
.hint__bank-card-details:last-child {
  margin-bottom: 0;
}
.hint__bank-card-details-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #9da6ba;
  font-size: 14px;
  margin-bottom: 5px;
}
.hint__bank-card-details-info-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.hint__bank-card-details-info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 1.33;
  margin-right: 12px;
  word-break: break-word;
}
.hint__copied {
  display: flex;
  align-items: center;
}
.hint__copied-icon-wrap {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.hint__copied-icon path {
  fill: var(--turquoise-blue);
}
.hint__copied-text {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--turquoise-blue);
}

@media screen and (max-width: 1100px) {
  .hint_change-password .hint__content {
    max-width: 400px;
  }
}
@media screen and (max-width: 1000px) {
  .hint {
    align-items: flex-start;
    overflow: auto;
    overflow-x: hidden;
    padding-top: 141px;
    width: 100%;
  }
  .hint__inner {
    overflow-y: scroll;
    padding-bottom: 70px;
  }
  .hint_change-phone .hint__inner {
    margin-top: 0;
  }
  .hint_lift-limits .hint__inner {
    padding-top: 0;
  }
  .hint_confirm-data .hint__inner {
    padding-top: 0;
  }
  .hint_change-data .hint__inner {
    padding-top: 0;
  }
  .hint_change-password .hint__inner {
    padding-top: 0;
  }
  .hint_no-russian-passport .hint__inner {
    padding-top: 0;
  }
  .hint_cant-read-passport .hint__inner {
    padding-top: 0;
  }
  .hint__title {
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    font-size: 36px;
    line-height: 1.11;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .hint__inner {
    padding-left: 15px;
  }
  .hint__close {
    right: 20px;
    top: 25px;
  }
}