.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-settings-step-1 {
  width: 100%;
}
.acquiring-settings-step-1__title {
  margin-bottom: 50px;
}
.acquiring-settings-step-1__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.acquiring-settings-step-1__form {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.acquiring-settings-step-1__documentation {
  margin-bottom: 16px;
}
.acquiring-settings-step-1__documentation-item {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
}
.acquiring-settings-step-1__status-title-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.acquiring-settings-step-1__status-title {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  margin-right: 8px;
}
.acquiring-settings-step-1__status-title-button-wrap {
  width: 16px;
  height: 16px;
  position: relative;
}
.acquiring-settings-step-1__status-title-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.acquiring-settings-step-1__status-title-dropdown {
  position: absolute;
  background: #616a88;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  right: -20px;
  top: 32px;
  width: 290px;
  z-index: 20;
}
.acquiring-settings-step-1__status-title-dropdown:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: #616a88;
  transform: rotate(45deg);
  right: 21px;
  top: -7px;
}
.acquiring-settings-step-1__status-title-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.acquiring-settings-step-1__status-title-icon path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-1__status-list-wrap {
  margin-bottom: 32px;
}
.acquiring-settings-step-1__status-list {
  display: flex;
  width: 100%;
}
.acquiring-settings-step-1__status-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  border-radius: 8px;
  font-size: 14px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
}
.acquiring-settings-step-1__status-item:last-child {
  margin-right: 0;
}
.acquiring-settings-step-1__status-item:disabled {
  cursor: default;
}
.acquiring-settings-step-1__status-item_active {
  background: white;
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.acquiring-settings-step-1__status-item-icon {
  height: 12px;
  margin-right: 10px;
}
.acquiring-settings-step-1__status-item-icon_user path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-1__status-item_active .acquiring-settings-step-1__status-item-icon_user path {
  fill: var(--dark-slate-blue);
}
.acquiring-settings-step-1__status-item-icon_bag path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-1__status-item_active .acquiring-settings-step-1__status-item-icon_bag path {
  fill: var(--dark-slate-blue);
}
.acquiring-settings-step-1__individual-warning {
  margin-top: 10px;
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: var(--turquoise-blue);
}
.acquiring-settings-step-1__input {
  margin-bottom: 20px;
}
.acquiring-settings-step-1__textarea {
  margin-bottom: 18px;
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea {
  height: 120px;
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-webkit-input-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-moz-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea:-moz-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea:-ms-input-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-ms-input-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-ms-clear {
  display: none;
}
.acquiring-settings-step-1__buttons-list {
  display: flex;
}
.acquiring-settings-step-1__buttons-item {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.acquiring-settings-step-1__buttons-item:last-child {
  margin-right: 0;
}
.acquiring-settings-step-1__message-wrap {
  width: calc(100% - 350px - 60px);
  margin-top: -7px;
}
.acquiring-settings-step-1__message {
  border-radius: 8px;
  margin-bottom: 20px;
}
.acquiring-settings-step-1__message:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-1__message:only-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .acquiring-settings-step-1__content {
    flex-direction: column-reverse;
  }
  .acquiring-settings-step-1__form {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .acquiring-settings-step-1__message-wrap {
    width: 100%;
    margin-top: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 980px) {
  .acquiring-settings-step-1__status-title-dropdown {
    right: auto;
    left: -22px;
  }
  .acquiring-settings-step-1__status-title-dropdown:after {
    left: 21px;
    right: auto;
  }
}
@media screen and (max-width: 400px) {
  .acquiring-settings-step-1__status-title-dropdown {
    left: -100px;
  }
}