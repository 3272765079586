.money-transfer-card-header-webmoney {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 36px 36px 20px 36px;
  border-radius: 12px 12px 0 0;
  background-color: #046cb5;
}
.money-transfer-card-header-webmoney__logo {
  width: 107px;
  height: 30px;
}