.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.get-money {
  padding-top: 84px;
  padding-bottom: 50px;
}
.get-money__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 20px;
}
.get-money__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.get-money__withdrawal-btn {
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.get-money__withdrawal-btn:hover {
  color: var(--dark-slate-blue);
}
.get-money__nav-wrap {
  margin-bottom: 34px;
}
.get-money__nav {
  display: flex;
  align-items: center;
  position: relative;
}
.get-money__nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--bluey-grey);
  font-size: 16px;
  padding: 0 0 4px 0;
  text-decoration: none;
  margin-right: 43px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.get-money__nav-item:last-child {
  margin-right: 0;
}
.get-money__nav-item:hover {
  color: var(--dusk-blue);
}
.get-money__nav-item_active {
  color: var(--dark-slate-blue);
}
.get-money__tabs-bar {
  position: absolute;
  bottom: -6px;
  left: 0;
}
.get-money__tabs-bar-active {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  transition: all 0.3s ease;
  border-bottom: 2px dotted var(--dark-slate-blue);
}
.get-money__warning__container {
  display: flex;
  flex-direction: column;
}
.get-money__warning__title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-blue);
}
.get-money__warning__text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 4px;
  line-height: 20px;
  font-size: 14px;
  color: var(--grey-blue);
}
.get-money__warning__text > button {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  color: var(--deep-sky-blue);
}
.get-money__content {
  position: relative;
  min-height: 490px;
}
.get-money__content-tab {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  z-index: 10;
}
.get-money__content-tab_active {
  z-index: 20;
}
.get-money__item {
  width: calc((100% - 56px) / 3);
  margin-right: 27px;
  margin-bottom: 30px;
  text-decoration: none;
}
.get-money__item:nth-child(3n) {
  margin-right: 0;
}
.get-money__item:last-child {
  margin-right: 0;
}
@media (max-width: 480px) {
  .get-money__desktop-notification {
    display: none;
  }
}
.get-money__mobile-notification {
  display: none;
}
@media (max-width: 480px) {
  .get-money__mobile-notification {
    display: block;
  }
}

@media screen and (max-width: 1194px) {
  .get-money__item {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .get-money__item:nth-child(3n) {
    margin-right: 20px;
  }
  .get-money__item:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  .get-money__content {
    min-height: 780px;
  }
  .get-money__item {
    width: 100%;
    margin-right: 0;
  }
  .get-money__item:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 800px) {
  .get-money__top {
    border-bottom: 0;
  }
  .get-money__title {
    font-size: 28px;
  }
}
@media screen and (max-width: 600px) {
  .get-money {
    padding-top: 40px;
  }
  .get-money__nav {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  .get-money__nav-item {
    width: 50%;
    margin-right: 0;
  }
  .get-money__tabs-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--bluey-grey);
    width: 100%;
  }
  .get-money__tabs-bar-active {
    position: absolute;
    bottom: -1px;
    top: auto;
    left: 0;
    height: 3px;
    transition: all 0.3s ease;
    border-bottom: 3px solid var(--dark-slate-blue);
  }
  .get-money__warning {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media screen and (max-width: 350px) {
  .get-money__nav-item {
    font-size: 14px;
  }
}