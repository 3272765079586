.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring {
  padding-top: 40px;
  min-height: 710px;
  position: relative;
}
.acquiring__container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  width: 100%;
  position: relative;
}
.acquiring__sidebar {
  width: 350px;
  min-width: 350px;
}
.acquiring__shops-dropdown {
  margin-bottom: 58px;
}
.acquiring__main {
  width: calc(100% - 350px);
  min-width: calc(100% - 350px);
  padding-left: 60px;
  padding-bottom: 100px;
}
.acquiring__tabs {
  padding-left: 20px;
  margin-bottom: 50px;
}
.acquiring__tabs__nav-icon {
  width: 21px;
  height: 23px;
}
.acquiring__tabs__nav-icon path {
  fill: var(--normal-grey-blue);
}
.acquiring__tabs__nav-icon__active path {
  fill: var(--dark-slate-blue);
}

@media screen and (max-width: 1194px) {
  .acquiring__sidebar {
    width: 300px;
    min-width: 300px;
  }
  .acquiring__main {
    width: calc(100% - 300px);
    min-width: calc(100% - 300px);
    padding-left: 30px;
  }
}
@media screen and (max-width: 1100px) {
  .acquiring {
    padding-top: 0;
  }
  .acquiring__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .acquiring__sidebar {
    width: 230px;
    min-width: 230px;
  }
  .acquiring__main {
    width: calc(100% - 230px);
    min-width: calc(100% - 230px);
    padding-left: 20px;
  }
}
@media screen and (max-width: 800px) {
  .acquiring {
    padding-top: 0;
  }
  .acquiring__tabs-wrap {
    margin-bottom: 40px;
    padding-left: 40px;
  }
  .acquiring__tabs .tabs-with-scrollable-active-state__nav-item {
    white-space: nowrap;
  }
  .acquiring__container {
    display: block;
  }
  .acquiring__nav-select {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: white;
    padding: 0 57px 0 50px;
    border-bottom: 1px solid #e8ecf2;
    margin-bottom: 20px;
  }
  .acquiring__nav-select-text {
    font-size: 24px;
    color: var(--dark-slate-blue);
    font-family: "Circe-Bold", sans-serif;
  }
  .acquiring__nav-select-icon {
    transform: rotate(90deg);
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }
  .acquiring__nav-select-icon path {
    stroke: var(--dark-slate-blue);
  }
  .acquiring__nav-select-icon_open {
    transform: rotate(-90deg);
  }
  .acquiring__main {
    width: 100%;
    min-width: 100%;
    padding-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .acquiring__container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .acquiring__nav-select {
    padding: 0 27px 0 20px;
  }
  .acquiring__main_analytics {
    padding-bottom: 0;
    overflow: hidden;
    width: calc(100% + 40px);
    margin-right: -20px;
    margin-left: -20px;
  }
}