.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.popupConfirmationTitle {
  font-family: "Circe-Bold", sans-serif;
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
}
@media (max-width: 480px) {
  .popupConfirmationTitle {
    line-height: 32px;
    font-size: 24px;
  }
}
.popupConfirmationContent {
  margin-top: 20px;
}
.popupConfirmationMessage1 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-blue);
}
@media (max-width: 480px) {
  .popupConfirmationMessage1 {
    width: 100%;
  }
}
.popupConfirmationMessage2 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: var(--light-grey-blue);
}
.popupConfirmationMessage3 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 14px;
  color: var(--reddish-pink);
}
.popupConfirmationCode {
  margin-top: 30px;
}
.popupConfirmationSendSmsButton {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  background-color: transparent;
  line-height: 20px;
  text-decoration: underline;
  font-size: 14px;
  color: var(--dusk-blue);
}
.popupConfirmationMessages {
  margin-top: 30px;
}