.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.password-block__item {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.password-block_password-recovery .password-block__item {
  margin-bottom: 10px;
}
.password-block_password-recovery .password-block__item:only-child {
  margin-bottom: 30px;
}
.password-block__input-holder {
  margin-bottom: 11px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-right: 10px;
}
.password-block_password-recovery .password-block__input-holder {
  margin-right: 0;
}
.password-block__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  text-align: left;
  color: var(--dark-slate-blue);
  font-size: 16px;
  transition: border-color 0.3s ease;
  font-family: "Circe-Bold", sans-serif;
  letter-spacing: 16px;
  padding: 5px 24px 5px 24px;
  line-height: 50px;
}
.password-block__input__validation {
  padding-right: 94px;
}
.password-block__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.password-block__input::-ms-clear {
  display: none;
}
.password-block__input::-webkit-textfield-decoration-container {
  letter-spacing: normal;
}
.form_register .password-block__input {
  text-align: left;
  font-size: 18px;
}
.form_register .password-block__input::-webkit-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input::-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input:-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input:-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input::-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input::-ms-clear {
  display: none;
}
.password-block__input_red {
  border-color: var(--reddish-pink);
  color: var(--reddish-pink);
}
.password-block__input_red:focus {
  border-color: var(--reddish-pink);
}
.password-block__input_yellow {
  border-color: var(--light-mustard);
  color: var(--light-mustard);
}
.password-block__input_yellow:focus {
  border-color: var(--light-mustard);
}
.password-block__input_green {
  border-color: var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.password-block__input_green:focus {
  border-color: var(--turquoise-blue);
}
.password-block__input_password-visible {
  letter-spacing: 0;
}
.password-block__input_disabled {
  background: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  color: #b0b5c4;
  box-shadow: none;
}
.password-block__input_success {
  border: solid 1px var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.password-block__input_fail {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  box-shadow: none;
}
.form__block_code .password-block__input_fail {
  box-shadow: none;
}
.password-block__password-controls {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.password-block__password-visibility {
  margin-right: 4px;
}
.password-block__password-secure-wrap {
  position: relative;
}
.password-block__password-secure-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -8px;
}
.password-block__password-secure-lock_yellow path {
  fill: var(--light-mustard);
}
.password-block__password-secure-lock_grey path {
  fill: var(--bluey-grey);
}
.password-block__password-secure-lock_green path {
  fill: var(--turquoise-blue);
}
.password-block__password-secure-lock_red path {
  fill: var(--reddish-pink);
}
.password-block__error-wrap {
  margin-bottom: 20px;
}
.password-block__error-message {
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  width: 70%;
}
.password-recovery .password-block__error-message {
  font-size: 16px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.password-block__tip {
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 30px;
  max-width: 400px;
}
.password-block__tip_error {
  color: var(--reddish-pink);
}
.password-block_password-recovery .password-block__tip {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.password-block__next-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--pale-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
}
.password-block__next-btn_visible {
  opacity: 1;
}
.password-block__next-btn_active {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: var(--dusk-blue);
  cursor: pointer;
}
.password-block__next-btn_active:hover {
  background: var(--dark-slate-blue);
}
.password-block__next-btn-icon {
  margin-left: 5px;
}
.password-block__btn-wrap {
  display: flex;
  justify-content: center;
}
.password-block__btn {
  width: 100%;
  margin-bottom: 30px;
}
.password-block_password-recovery .password-block__btn {
  max-width: 240px;
  margin-bottom: 0;
}

@media screen and (max-width: 1100px) {
  .password-block_hint .password-block__input-holder {
    margin-right: 0;
  }
}
@media screen and (max-width: 700px) {
  .form_register .password-block__input-holder {
    margin-right: 0;
    margin-bottom: 31px;
  }
  .password-block_register .password-block__tip {
    width: 90%;
    font-size: 14px;
    line-height: 1.4;
  }
}
@media screen and (max-width: 500px) {
  .password-block__input {
    padding: 0 35px 0 24px;
  }
}