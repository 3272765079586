.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.manual-progress-steps {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 47px;
  left: 50%;
  margin-left: -320px;
}
.manual-progress-steps__item {
  width: 160px;
  min-width: 160px;
  position: relative;
  z-index: 20;
}
.manual-progress-steps__item-number {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--dark-slate-blue);
  font-size: 18px;
  color: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  position: relative;
  transition: all 0.3s ease;
}
.manual-progress-steps__item-number:after {
  content: "";
  position: absolute;
  top: 25px;
  height: 1px;
  background: #f6f8fb;
  left: 50px;
  width: 159px;
}
.manual-progress-steps__item:nth-child(4) .manual-progress-steps__item-number:after {
  display: none;
}
.manual-progress-steps__item_active .manual-progress-steps__item-number {
  background: var(--dark-slate-blue);
  color: white;
}
.manual-progress-steps__item:hover .manual-progress-steps__item-number {
  background: var(--dark-slate-blue);
  color: white;
}
.manual-progress-steps__item_passed .manual-progress-steps__item-number {
  background: #f6f8fb;
  border-color: #f6f8fb;
  color: #b0b5c4;
}
.manual-progress-steps__item_passed .manual-progress-steps__item-number:after {
  background: #9da6ba;
}
.manual-progress-steps__item-title {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.manual-progress-steps__item_passed .manual-progress-steps__item-title {
  color: #b0b5c4;
}