.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.deals {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  padding: 30px 0;
  width: 100%;
}
.deals__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
  padding-left: 30px;
  padding-right: 30px;
}
.deals__content-wrapper {
  height: 300px;
  overflow: hidden;
  padding: 0 30px;
  position: relative;
}
.deals__list {
  padding-bottom: 20px;
}
.deals__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.deals__item:hover {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item_open {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item:first-child {
  border-top: 1px solid var(--pale-grey);
}
.deals__item:first-child:hover {
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item:first-child_active {
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item-title-wrap {
  align-items: center;
  display: flex;
  width: calc(100% - 150px - 150px - 110px);
}
.deals__item-icon {
  margin-right: 20px;
}
.deals__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  width: calc(100% - 50px);
}
.deals__header .deals__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.deals__item:hover .deals__item-title {
  color: var(--dark-slate-blue);
}
.deals__item_active .deals__item-title {
  color: var(--dark-slate-blue);
}
.deals__item-title-service {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item-title-service:after {
  content: ",";
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item-title-service:last-child:after {
  display: none;
}
.deals__item-tax {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 12px;
  min-width: 150px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 150px;
}
.deals__header .deals__item-tax {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item:hover .deals__item-tax {
  color: var(--dark-slate-blue);
}
.deals__item_active .deals__item-tax {
  color: var(--dark-slate-blue);
}
.deals__item-date {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 12px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 150px;
}
.deals__item:hover .deals__item-date {
  color: var(--dark-slate-blue);
}
.deals__item_active .deals__item-date {
  color: var(--dark-slate-blue);
}
.deals__item-date-itself {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--turquoise-blue);
  font-size: 16px;
  min-width: 110px;
  text-align: right;
  width: auto;
}
.deals__item-sum_canceled {
  color: var(--bluey-grey);
}
.deals__header .deals__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__loading {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
}
.deals__loading_full-block {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.deals__track {
  background: transparent;
  border-radius: 4px;
  height: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  right: -20px;
  top: 0;
  user-select: none;
  width: 4px;
}
.deals__thumb {
  background-color: var(--pale-grey);
  border-radius: 2px;
  position: absolute;
  width: 4px;
}

@media screen and (max-width: 1030px) {
  .deals {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    /* &__loading {
      position: static;
      height: 191px;
      display: flex;
      align-items: center;
      justify-content: center;
    }*/
  }
  .deals__date {
    width: 100%;
  }
  .deals__content-wrapper {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
  }
  .deals__item {
    padding-left: 8px;
    padding-right: 8px;
  }
  .deals__item-left-part {
    min-width: 70%;
    padding-right: 10px;
    width: 70%;
  }
  .deals__item-title-wrap {
    margin-bottom: 10px;
    width: auto;
  }
  .deals__item-icon {
    margin-right: 7px;
  }
  .deals__item-title {
    font-size: 16px;
  }
  .deals__item-right-part {
    min-width: 30%;
    text-align: right;
    width: 30%;
  }
  .deals__item-sum {
    font-size: 20px;
    max-width: 100%;
    min-width: 100%;
  }
  .deals__item-tax {
    margin-left: auto;
    margin-right: 0;
    min-width: 100%;
    text-align: right;
    text-transform: lowercase;
    width: 100%;
  }
  .deals__item-date {
    max-width: 100%;
    padding-left: 23px;
    text-align: left;
  }
  .deals__track {
    right: 0;
  }
}
@media screen and (max-width: 800px) {
  .deals_check-open {
    opacity: 0;
  }
}
@media screen and (max-width: 600px) {
  .deals__content-wrapper {
    height: 290px;
  }
  .deals__item {
    padding: 6px;
  }
}