.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-ways-tab__top {
  margin-bottom: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-ways-tab__go-to-demo {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.payment-ways-tab__go-to-demo-text {
  font-size: 16px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 14px;
}
.payment-ways-tab__go-to-demo-icon {
  width: 20px;
  height: 20px;
}
.payment-ways-tab__list {
  display: flex;
  flex-wrap: wrap;
}
.payment-ways-tab__item {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
  margin-bottom: 20px;
}
.payment-ways-tab__item:last-child {
  margin-right: 0;
}
.payment-ways-tab__item:nth-child(2n) {
  margin-right: 0;
}
.payment-ways-tab__item:nth-last-child(-n+2) {
  margin-bottom: 0;
}

@media screen and (max-width: 900px) {
  .payment-ways-tab__top {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .payment-ways-tab__title {
    margin-bottom: 20px;
  }
  .payment-ways-tab__list {
    display: block;
  }
  .payment-ways-tab__item {
    width: 100%;
    margin-right: 0;
  }
  .payment-ways-tab__item:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .payment-ways-tab__item:nth-last-child(-n+2) {
    margin-bottom: 20px;
  }
}