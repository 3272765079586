.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.footer-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.footer-items .footer-app-links {
  display: flex;
  align-items: center;
  position: relative;
}
.footer-items .footer-app-links__text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: #b0b5c4;
  margin-right: 28px;
}
.footer-items .footer-app-links__list {
  display: flex;
  align-items: center;
}
.footer-items .footer-app-links__link {
  margin-right: 28px;
}
.footer-items .footer-app-links__link:last-child {
  margin-right: 0;
}
.footer-items .footer-app-links__icon_app-store {
  width: 18px;
  height: 22px;
}
.footer-items .footer-app-links__icon_play-store {
  width: 16px;
  height: 18px;
  margin-top: 4px;
}
.footer-items .footer-app-links__icon path {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.footer__app-item:hover .footer-items .footer-app-links__icon path {
  fill: var(--dark-slate-blue);
}
.footer-items .footer-privacy {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: var(--dusk-blue);
  margin-right: 28px;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .footer-items {
    flex-direction: column;
  }
}