.money-transfer-card-header-sz {
  position: relative;
  height: 92px;
  overflow: hidden;
  padding: 36px 36px 20px 36px;
  border-radius: 12px 12px 0 0;
  background-image: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(90, 200, 250) 100%);
}
.money-transfer-card-header-sz:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.money-transfer-card-header-sz__logo {
  position: relative;
  object-fit: contain;
  width: 110px;
  height: 38px;
}
.money-transfer-card-header-sz__bg {
  position: absolute;
  z-index: 1;
  right: -60px;
  top: -176px;
  width: 284px;
}