.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.auth-status-result {
  max-width: 485px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: -45px;
  position: relative;
  z-index: 200;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
.auth-status-result__top {
  margin-bottom: 60px;
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-status-result__top-icon {
  width: 30px;
  height: 39px;
}
.auth-status-result__top-loader-icon {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 50%;
  color: rgba(40, 65, 149, 0.1);
  left: 50%;
  margin-top: -9px;
  margin-left: -6px;
}
.auth-status-result__top-loader-icon path {
  fill: var(--bluey-grey);
}
.auth-status-result__top-fail-progress-icon {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 50%;
  color: rgba(40, 65, 149, 0.1);
  left: 50%;
  margin-top: -9px;
  margin-left: -6px;
}
.auth-status-result__top-fail-progress-icon path {
  fill: var(--reddish-pink);
}
.auth-status-result__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  text-align: center;
  font-family: "Circe-Bold", sans-serif;
  line-height: 1.33;
  margin-bottom: 10px;
}
.auth-status-result__desc {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 36px;
  text-align: center;
}
.auth-status-result__link-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.auth-status-result__link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 190px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dusk-blue);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
}
.auth-status-result__link:hover {
  background: var(--dark-slate-blue);
}
.auth-status-result__tip {
  font-size: 15px;
  line-height: 1.6;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .auth-status-result__top {
    margin-bottom: 55px;
  }
  .auth-status-result__title {
    margin-bottom: 38px;
    font-family: "Circe-ExtraBold", sans-serif;
    line-height: 1.25;
  }
  .auth-status-result__desc {
    margin-left: auto;
    margin-right: auto;
    width: 89%;
    margin-bottom: 38px;
  }
  .auth-status-result__link-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .auth-status-result__link {
    width: 100%;
    max-width: 335px;
    height: 60px;
    border-radius: 30px;
    background-color: var(--dark-slate-blue);
    font-size: 16px;
  }
  .auth-status-result__tip {
    font-size: 15px;
    line-height: 1.6;
    color: var(--bluey-grey);
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
  }
}