.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.integration-widget {
  padding-left: 110px;
}
.integration-widget__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 43px;
  margin-top: 16px;
}
.integration-widget__content-wrap {
  width: 100%;
}
.integration-widget__content {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.integration-widget__create-container {
  position: relative;
  background: var(--white);
  box-shadow: 0px 16px 60px rgba(0, 22, 98, 0.07);
  border-radius: 6px;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  height: 124px;
  align-items: center;
  width: 100%;
  padding: 40px;
  gap: 20px;
}
.integration-widget__create-title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-slate-blue);
}
.integration-widget__btn-create {
  min-width: 210px;
}
.integration-widget__btn-add {
  min-width: 198px;
  font-weight: bold;
  margin-top: 40px;
  transition: all 0.3s ease;
}
.integration-widget__error-add {
  color: var(--reddish-pink);
  margin-top: 25px;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.integration-widget__divider {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: #e4e8ee;
  margin-top: 50px;
}
.integration-widget__list-title {
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-slate-blue);
}
.integration-widget__list {
  padding-bottom: 40px;
}
.integration-widget__list-item {
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  gap: 20px;
  box-shadow: 0px 2px 6px 0px rgba(0, 22, 98, 0.1215686275);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}
.integration-widget__list-item-field.date {
  margin-left: auto;
  flex: none;
}
.integration-widget__list-item-field.name {
  overflow: hidden;
}
.integration-widget__list-item-actions {
  display: flex;
}
.integration-widget__list-item-btn {
  border: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.integration-widget__list-item-copied {
  position: absolute;
  top: 40px;
  left: -34px;
  padding: 10px;
  border-radius: 4px;
  background: rgba(29, 46, 105, 0.6);
  color: var(--white);
  transition: all 0.3s ease;
  cursor: auto;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.integration-widget__list-item-copied:before {
  border-bottom: 6px solid rgba(29, 46, 105, 0.6);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -6px;
  position: absolute;
  top: -6px;
  width: 0;
}
.integration-widget__list-item-name {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--light-grey-blue);
}
.integration-widget__list-item-value {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-slate-blue);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.integration-widget__input-holder {
  margin-bottom: 10px;
  position: relative;
}
.integration-widget__input-holder:last-child {
  margin-bottom: 0;
}
.integration-widget__input-name {
  height: 60px;
  border-radius: 8px;
  background-color: var(--white);
  width: 100%;
  border: 1px solid rgba(29, 46, 105, 0.4);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 10px 50px 10px 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.integration-widget__input-name::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.integration-widget__input-name[type=number]::-webkit-outer-spin-button, .integration-widget__input-name[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.integration-widget__input-name[type=number] {
  -moz-appearance: textfield;
}
.integration-widget__input-name_service-name {
  padding: 10px 40px 0 20px;
}
.integration-widget__input-edit {
  position: absolute;
  right: 20px;
  bottom: 24px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
}
.integration-widget__input-edit path {
  fill: var(--dark-slate-blue);
}

@media screen and (max-width: 1100px) {
  .integration-widget {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .integration-widget {
    padding-top: 10px;
  }
  .integration-widget__create-container {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
  }
}
@media screen and (max-width: 800px) {
  .integration-widget {
    padding-bottom: 69px;
  }
  .integration-widget__content-wrap {
    display: block;
  }
  .integration-widget__content {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 710px) {
  .integration-widget {
    padding-bottom: 69px;
  }
  .integration-widget__list-item {
    flex-direction: column;
    padding: 20px;
  }
  .integration-widget__list-item-field {
    width: 100% !important;
    max-width: 100% !important;
  }
  .integration-widget__list-item-actions {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
@media screen and (max-width: 560px) {
  .integration-widget__create-container {
    flex-direction: column;
  }
  .integration-widget__btn-create {
    min-width: 100%;
  }
  .integration-widget__btn-add {
    min-width: 100%;
  }
}