.popupCheck {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;

  &Wrapper {
    box-sizing: border-box;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 50px 50px 100px;
  }

  &Container {
    width: 290px;
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .popupCheck {
    &Wrapper {
      padding-top: 0;
      padding-right: 20px;
      padding-left: 20px;
    }

    &Container {
      width: 100%;
    }
  }
}
