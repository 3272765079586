.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-pages-tab__top {
  margin-bottom: 70px;
}
.payment-pages-tab__title {
  margin-bottom: 30px;
}
.payment-pages-tab__desc {
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  max-width: 70%;
}
.payment-pages-tab__block {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 20px;
}
.payment-pages-tab__block_in-active {
  background: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
}
.payment-pages-tab__block:last-child {
  margin-bottom: 0;
}
.payment-pages-tab__block-top {
  margin-bottom: 20px;
}
.payment-pages-tab__block-top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-pages-tab__block-title {
  font-size: 20px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.payment-pages-tab__block_in-active .payment-pages-tab__block-title {
  color: #9da6ba;
}
.payment-pages-tab__block-desc {
  color: #b0b5c4;
  font-size: 12px;
  line-height: 16px;
  font-family: "Circe-Regular", sans-serif;
  max-width: 100%;
  margin-top: 8px;
}
.payment-pages-tab__block-active-badge {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.payment-pages-tab__block-active-badge-icon {
  margin-right: 12px;
  width: 11px;
  height: 8px;
}
.payment-pages-tab__block-active-badge-text {
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--turquoise-blue);
}
.payment-pages-tab__block-list {
  display: flex;
}
.payment-pages-tab__card {
  padding: 20px;
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
  border-radius: 6px;
  height: 138px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.payment-pages-tab__card:last-child {
  margin-right: 0;
}
.payment-pages-tab__card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-pages-tab__choose-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  line-height: 24px;
  color: white;
  background: rgba(255, 255, 255, 0.26);
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.payment-pages-tab__choose-button:hover:not(:disabled) {
  background: var(--deep-sky-blue);
}
.payment-pages-tab__choose-button_chosen {
  background: white !important;
  color: var(--dark-slate-blue);
  cursor: default;
}
.payment-pages-tab__choose-button_unavailable {
  background: rgba(255, 255, 255, 0.26) !important;
}
.payment-pages-tab__block_in-active .payment-pages-tab__choose-button {
  cursor: default;
}
.payment-pages-tab__card-preview {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.payment-pages-tab__card-preview-text {
  font-size: 12px;
  font-family: "Circe-Regular", sans-serif;
  color: white;
  margin-right: 9px;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.payment-pages-tab__card-preview:hover .payment-pages-tab__card-preview-text {
  color: var(--dark-slate-blue);
}
.payment-pages-tab__card-preview-icon {
  width: 13px;
  height: 10px;
}
.payment-pages-tab__card-preview-icon path {
  transition-property: stroke;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.payment-pages-tab__card-preview:hover .payment-pages-tab__card-preview-icon path {
  stroke: var(--dark-slate-blue);
}
.payment-pages-tab__card-type {
  font-size: 12px;
  color: white;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 4px;
}
.payment-pages-tab__card-title {
  color: #ffffff;
  font-size: 20px;
  font-family: "Circe-Bold", sans-serif;
}
.payment-pages-tab__popup .small-popup-with-emoji__popup {
  min-height: 410px;
}
.payment-pages-tab__popup-desc {
  text-align: center;
}
.payment-pages-tab__loader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 200px;
}

@media screen and (max-width: 780px) {
  .payment-pages-tab__block-list {
    display: block;
  }
  .payment-pages-tab__card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .payment-pages-tab__card:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 500px) {
  .payment-pages-tab__desc {
    max-width: 100%;
  }
  .payment-pages-tab__block-top-wrap {
    display: block;
  }
  .payment-pages-tab__block-active-badge {
    margin-top: 20px;
    justify-content: flex-start;
  }
  .payment-pages-tab__block-desc {
    max-width: 100%;
  }
}