:root {
  --dark-slate-blue: #1d2e69;
  --dusk-blue: #284195;
  --grey-blue: #616a88;
  --normal-grey-blue: #9da6ba;
  --light-grey-blue: #b0b5c4;
  --light-blue: #f2f8ff;
  --pale-grey: #f6f8fb;
  --white: #ffffff;
  --bluey-grey: #b0b5c4;
  --reddish-pink: #ff2d55;
  --light-mustard: #ffd254;
  --turquoise-blue: #00adb5;
  --deep-sky-blue: #007aff;
  --marigold: #ffcc00;
  --robin-s-egg: #5ac8fa;
  --purpley: #6c5ce7;
  --dandelion: #fad961;
  --pumpkin: #f76b1c;
  --orange: #ff8246;
  --save-money-color-theme-1: linear-gradient(to left, var(--robin-s-egg), var(--deep-sky-blue));
  --save-money-color-theme-2: linear-gradient(
    to right,
    var(--reddish-pink) -31%,
    var(--light-mustard) 95%
  );
  --save-money-color-theme-3: linear-gradient(to right, var(--purpley), var(--robin-s-egg) 123%);
  --save-money-color-theme-4: linear-gradient(251deg, #a0ddfc, #7ab0ff);
  --save-money-color-theme-5: linear-gradient(to left, var(--deep-sky-blue), var(--dusk-blue));
  --save-money-color-theme-6: linear-gradient(60deg, var(--pumpkin) -16%, var(--dandelion) 80%);
}
