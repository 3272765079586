.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-webmoney-card {
  position: relative;
}
.money-transfer-webmoney-card__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-webmoney-card__content {
  padding: 20px 23px 16px;
}
.money-transfer-webmoney-card__wallet-number {
  position: relative;
  margin-bottom: 10px;
}
.money-transfer-webmoney-card__wallet-number-input {
  appearance: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-shadow: none;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  font-family: "Circe-Bold", sans-serif;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.money-transfer-webmoney-card__wallet-number-input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input::-ms-clear {
  display: none;
}
.money-transfer-webmoney-card__wallet-number-input:focus {
  border-color: var(--dark-slate-blue);
}
.money-transfer-webmoney-card__wallet-number-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  cursor: not-allowed;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]:-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]:-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-ms-clear {
  display: none;
}
.money-transfer-webmoney-card__wallet-number-icon {
  position: absolute;
  right: 15px;
  bottom: 25px;
}
.money-transfer-webmoney-card__wallet-number-icon path {
  stroke: var(--dark-slate-blue);
}
.money-transfer-webmoney-card__error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-webmoney-card__nav {
  margin-top: 20px;
}