.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.sum-input {
  position: relative;
  width: 100%;
}
.sum-input__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
}
.sum-input__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.sum-input__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.sum-input__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.sum-input_focused .sum-input__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.sum-input_error .sum-input__input {
  color: var(--reddish-pink);
}
.sum-input__input[type=number]::-webkit-outer-spin-button, .sum-input__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sum-input__input[type=number] {
  -moz-appearance: textfield;
}
.sum-input__input::-webkit-input-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input::-webkit-input-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input::-webkit-input-placeholder {
  opacity: 1;
}
.sum-input__input::-moz-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input::-moz-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input::-moz-placeholder {
  opacity: 1;
}
.sum-input__input:-moz-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input:-moz-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input:-moz-placeholder {
  opacity: 1;
}
.sum-input__input:-ms-input-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input:-ms-input-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input:-ms-input-placeholder {
  opacity: 1;
}
.sum-input__input::-ms-input-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input::-ms-input-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input::-ms-input-placeholder {
  opacity: 1;
}
.sum-input__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: scale(1);
  transform-origin: 0 0;
}
.sum-input:focus-within .sum-input__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
  white-space: nowrap;
}
.sum-input_focused .sum-input__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
  white-space: nowrap;
}
.sum-input_focused.sum-input_error .sum-input__label {
  color: var(--reddish-pink);
}
.sum-input_error .sum-input__label {
  color: var(--reddish-pink);
}