.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.notifications-list__itself_loading {
  position: relative;
}
.notifications-list__itself_loading:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
}
.notifications-list__loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
}
.notifications-list__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: block;
  margin-bottom: 13px;
  width: 370px;
  min-height: 105px;
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  padding: 17px 25px;
  background: white;
  text-align: left;
  transition: all 0.2s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  transform: translateX(0);
}
.notifications-list__item:last-child {
  margin-bottom: 0;
}
.notifications-list__item:hover {
  box-shadow: 0px 1px 11px 1px #c6c8cc;
}
.notifications-list__item_has-to-fly-away {
  transform: translateX(-500px);
  opacity: 0;
}
.notifications-list__item-date {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.notifications-list__item-title {
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.notifications-list__item-title:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 7px;
  background-color: var(--turquoise-blue);
}
.notifications-list__item_unread .notifications-list__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.notifications-list__item_unread .notifications-list__item-title:before {
  background-color: var(--reddish-pink);
}
.notifications-list__item-type {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.notifications-list__item-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: normal;
  color: var(--grey-blue);
  word-break: break-word;
  white-space: pre-wrap;
}
.notifications-list__item-text a {
  color: var(--dark-slate-blue);
}
.notifications-list__item-text a:hover {
  text-decoration: none;
}
.notifications-list__pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
}
.notifications-list__pagination-item {
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0 10px 15px 0;
  white-space: nowrap;
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 14px;
}
.notifications-list__pagination-item:hover:not(:disabled) {
  color: var(--dark-slate-blue);
  text-decoration: underline;
}
.notifications-list__pagination-item:disabled {
  cursor: not-allowed;
}
.notifications-list__pagination-item_current {
  color: var(--dark-slate-blue);
}
.notifications-list__pagination-item_prev {
  width: auto;
}
.notifications-list__pagination-item_next {
  width: auto;
}
.notifications-list__pagination-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 500px) {
  .notifications-list__item {
    width: 100%;
  }
  .notifications-list__pagination-item_prev {
    width: 20px;
    text-align: center;
    text-indent: -9999px;
  }
  .notifications-list__pagination-item_prev:after {
    content: "<";
    text-indent: 0;
  }
  .notifications-list__pagination-item_next {
    width: 20px;
    text-align: center;
    text-indent: -9999px;
  }
  .notifications-list__pagination-item_next:after {
    content: ">";
    text-indent: 0;
  }
  .notifications-list__pagination-item:last-child {
    margin-right: 0;
  }
}