.tip {
  position: absolute;
  pointer-events: none;

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #616a88;
  }

  &ToTop {
    bottom: calc(100% + 8px + 8px);

    &::before {
      top: 100%;
      left: calc(50% - 8px);
    }
  }
}
