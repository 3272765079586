.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.popup-header-with-balance__user {
  display: flex;
  align-items: center;
}
.popup-header-with-balance__user-balance-block {
  display: flex;
  align-items: center;
  margin-right: 9px;
}
.popup-header-with-balance__user-balance-text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--light-grey-blue);
  margin-right: 11px;
}
.popup-header-with-balance__user-balance {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.popup-header-with-balance__user-balance-rubles {
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.popup-header-with-balance__user-balance-penny {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.popup-header-with-balance__user-avatar-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  margin-right: 10px;
}
.popup-header-with-balance__user-avatar {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.popup-header-with-balance__user-phone {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--bluey-grey);
  transition: all 0.3s ease;
  margin-right: 5px;
}
.popup-header-with-balance__user-caret {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.popup-header-with-balance__user-caret-icon {
  width: 11px;
  min-width: 11px;
  height: 5px;
  min-height: 5px;
}
.popup-header-with-balance__user-caret-icon use {
  fill: #8e97b4;
  transition: all 0.3s ease;
}
.header__user-caret:hover .popup-header-with-balance__user-caret-icon use {
  fill: var(--dark-slate-blue);
}