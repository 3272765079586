.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-certificate-card__card {
  width: 100%;
  padding: 20px 30px 23px 30px;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 4px;
  height: 150px;
}
.income-certificate-card__title {
  color: var(--dark-slate-blue);
  font-size: 22px;
  font-family: "Circe-Bold", sans-serif;
  text-align: left;
  margin-bottom: 36px;
}
.income-certificate-card__list {
  display: flex;
  flex-wrap: wrap;
}
.income-certificate-card__year {
  color: #9da6ba;
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 17px;
  transition: color 0.3s ease-in-out;
}
.income-certificate-card__year:last-child {
  margin-right: 0;
}
.income-certificate-card__year:hover {
  color: #284195;
}

@media screen and (max-width: 1194px) {
  .income-certificate-card__card {
    padding: 30px 34px 24px 30px;
  }
}
@media screen and (max-width: 1000px) {
  .income-certificate-card__card {
    height: auto;
    min-height: 150px;
  }
}
@media screen and (max-width: 600px) {
  .income-certificate-card__card {
    padding: 35px 20px 10px 18px;
  }
}