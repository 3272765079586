.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //margin: -25px 0 0 -25px;
  width: 60px;
  height: 60px;

  .path {
    stroke: var(--bluey-grey);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  .total {
    stroke: var(--dusk-blue);
    opacity: 0.1;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
