.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.registration-container {
  display: flex;
  flex-grow: 1000;
  position: relative;
}

.register {
  width: 100%;
  display: flex;
  flex-grow: 100;
  position: relative;
  z-index: 10;
}
.register__form__linkSkipEmail {
  text-align: center;
  cursor: pointer;
}
.register__form__linkSkipEmail__btn {
  text-decoration: none;
}
.register__container {
  display: flex;
  padding-top: 20vh;
  align-items: flex-start;
  justify-content: center;
}
.register__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  min-width: auto;
}
.register__title {
  color: var(--dark-slate-blue);
  font-size: 36px;
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 20px;
  line-height: 1.2;
  text-align: center;
}
.register__step-1 .register__title {
  margin-bottom: 17px;
}
.register__step-2 .register__title {
  margin-bottom: 34px;
  padding-right: 0;
}
.register__step-3 .register__title {
  margin-bottom: 36px;
}
.register__step-4 .register__title {
  margin-bottom: 16px;
}
.register__step-5 .register__title {
  margin-bottom: 17px;
}
.register__desc-wrap {
  margin-bottom: 40px;
  text-align: center;
}
.register__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register__desc {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--light-grey-blue);
  font-size: 18px;
  line-height: 24px;
}
.register__desc-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dusk-blue);
  text-decoration: underline;
}
.register__desc-link:hover {
  text-decoration: none;
}
.register__help-block {
  margin-top: 50px;
}
.register__help-title {
  color: var(--bluey-grey);
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.register__help-link-wrap {
  margin-bottom: 15px;
}
.register__help-link-wrap:last-child {
  margin-bottom: 0;
}
.register__btn-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__btn {
  width: 240px;
}
@media (max-width: 450px) {
  .register__btn {
    width: 100%;
  }
}
.register__btn:last-child {
  margin-right: 0;
}
.register__hover-button {
  width: 100%;
  margin: 28px auto;
}
@media (min-width: 440px) {
  .register__hover-button {
    max-width: 240px;
  }
}
.register__step-1 .register__hover-button {
  margin-bottom: 20px;
}
.register__hint-block {
  width: 50%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-top: 20px;
}
.register__hint {
  filter: drop-shadow(0 4px 27px #e8ecf2);
  background: white;
  background-size: cover;
  padding: 39px 28px 39px 39px;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  position: relative;
}
.register__step-2 .register__hint {
  max-width: 396px;
}
.register__hint-triangle {
  position: absolute;
  bottom: -23px;
  left: 22px;
  width: 29px;
  height: 23px;
}
.register__hint-text {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-right: 10px;
}
.register__hint-icon_smile {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.register__hint-phone {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  line-height: 1.5;
  margin-right: 10px;
}
.register__hint-phone a {
  text-decoration: none;
  color: var(--dark-slate-blue);
}
.register__hint-phone-change {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: inline;
}
.register__hint-phone-change path {
  transition: all 0.3s ease;
}
.register__hint-phone-change:hover path {
  fill: var(--dark-slate-blue);
}
.register__image-block {
  display: flex;
  justify-content: center;
  width: 50%;
  min-width: 50%;
}
.register__image-wrap {
  width: 385px;
  height: 279px;
  position: relative;
  top: -8px;
  left: -50px;
}
.register__image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.register__shape {
  position: absolute;
  z-index: 61;
}
.register__shape_1 {
  width: 60px;
  height: 60px;
  top: 7%;
  left: 43.5%;
}
.register__shape_2 {
  width: 31px;
  height: 40px;
  top: 54%;
  left: 49%;
}
.register__shape_3 {
  width: 30px;
  height: 30px;
  top: 87%;
  left: 59.7%;
}
.register__shape_4 {
  width: 20px;
  height: 20px;
  top: 70%;
  left: 89.4%;
}
.register__shape_5 {
  width: 88px;
  height: 102px;
  top: 27.5%;
  left: 84.5%;
}
.register__laptop {
  width: 407px;
  height: 384px;
  position: absolute;
  top: 25%;
  right: 20%;
  z-index: 61;
}
.register__input-holder {
  max-width: 400px;
  margin-right: 10px;
  width: 100%;
}
.register__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 5px 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  line-height: 50px;
}
.register__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input::-ms-clear {
  display: none;
}
.register__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}

@media screen and (max-width: 1194px) {
  .register__content {
    padding-left: 0;
    margin-top: 0;
    max-width: 400px;
  }
  .register__hint-block {
    width: 40%;
  }
  .register__step-2 .register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .register__step-3 .register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .register__step-4 .register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .register__hint {
    padding: 25px;
  }
  .register__step-5 .register__btn-list {
    width: 100%;
  }
}
@media screen and (max-height: 600px) {
  .register {
    padding-bottom: 200px;
  }
}