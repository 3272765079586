.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-ways-item {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 12px;
  padding: 20px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}
.payment-ways-item__status {
  width: 96px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  border: 1px solid var(--deep-sky-blue);
  background: var(--deep-sky-blue);
  color: white;
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
}
.payment-ways-item__status_turned-on {
  border-color: var(--turquoise-blue);
  background: white;
  color: var(--turquoise-blue);
}
.payment-ways-item__status_disabled {
  background: var(--light-grey-blue);
  border-color: var(--light-grey-blue);
}
.payment-ways-item__loader-wrap {
  background: var(--light-grey-blue);
  border-color: var(--light-grey-blue);
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 12px;
}
.payment-ways-item__title {
  font-size: 20px;
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 12px;
  color: var(--dark-slate-blue);
}
.payment-ways-item_disabled .payment-ways-item__title {
  opacity: 0.5;
}
.payment-ways-item__desc {
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  min-height: 48px;
}
.payment-ways-item_disabled .payment-ways-item__desc {
  opacity: 0.5;
}
.payment-ways-item__details {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.payment-ways-item__details-span {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-bottom: 3px;
  background: #c4c4c4;
}
.payment-ways-item__details-span:last-child {
  margin-bottom: 0;
}