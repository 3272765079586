.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

@media (max-width: 980px) {
  .footer {
    padding: 50px 20px;

    &Desktop {
      display: none;
    }
  }
}
