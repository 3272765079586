.apple-pay-set-up-button {
  height: 100%;
  width: 100%;
  position: relative;

  &:disabled {
    cursor: not-allowed;

    &:after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 3px;
    }
  }
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-set-up-button {
    -webkit-appearance: -apple-pay-button;
    display: inline-block;
    -apple-pay-button-style: black;
    -apple-pay-button-type: set-up;
    position: relative;

    &:disabled {
      cursor: not-allowed;

      &:after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 3px;
      }
    }
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-set-up-button {
    background-color: black;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 60%;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    max-height: 64px;
    min-height: 32px;
    min-width: 200px;
    padding: 0;

    &:disabled {
      background-color: rgba(29, 46, 105, 0.1);
      cursor: not-allowed;
    }
  }
}
