@charset "UTF-8";
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.registration-phone-input {
  flex-grow: 20;
  width: 100%;
  margin-bottom: 20px;
}
.registration-phone-input__itself {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  line-height: 60px;
}
.registration-phone-input__itself::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself::-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself:-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself:-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself::-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself::-ms-clear {
  display: none;
}
.registration-phone-input__itself:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.registration-phone-input__check {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -6px;
}

.register__form__agreement {
  margin-bottom: 0;
}
.register__form__agreement span,
.register__form__agreement a {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
}
.register__form__agreement a {
  color: var(--dusk-blue);
  text-decoration: none;
}
.register__form__agreement span.i-agree {
  font-size: 12px;
}
.register__form__agreement ul li {
  padding-inline-start: 12px;
}
.register__form__agreement ul li::before {
  content: "•";
  color: var(--bluey-grey);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  height: 11px;
  font-size: 12px;
}

.register__form__open-login {
  margin-top: 50px;
}
.register__form__open-login span,
.register__form__open-login a {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--bluey-grey);
}
.register__form__open-login a {
  color: var(--dusk-blue);
}