@import 'static/styles/_mixins.scss';

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 30;
  width: 320px;
}

.react-datepicker {
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border: none;

  &__triangle {
    display: none;
  }
  &__day {
    font-size: 14px;
    color: var(--grey-blue);
    @include roboto();
    width: 35px;
    height: 23px;
    line-height: 23px;
    border-radius: 2px;
    &:nth-child(6) {
      color: var(--bluey-grey);
    }
    &:nth-child(7) {
      color: #ff5353;
    }
    &:hover {
      border-radius: 2px;
    }
    &--today {
      color: white;
      background: var(--grey-blue);
    }
    &--selected {
      background-color: var(--deep-sky-blue);
      color: white;
      border-radius: 2px;
      &:hover {
        color: white;
        background: var(--dusk-blue);
      }
    }
    &--keyboard-selected {
      background-color: var(--deep-sky-blue);
      color: white;
      border-radius: 2px;
      &:hover {
        color: white;
        background: var(--dusk-blue);
      }
    }
    &--disabled {
      color: #ccc;

      &.react-datepicker__day--today {
        color: white;
        &:hover {
          color: #ccc;
          background: #f0f0f0;
        }
      }
    }
  }
  &__day-name {
    font-size: 11px;
    color: rgba(178, 178, 178, 0.5);
    @include bold();
  }
  &__header {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__current-month {
    width: 60%;
    margin-right: 10px;
    height: 30px;
    line-height: 30px;
    color: var(--grey-blue);
    font-size: 14px;
    @include rubik-regular();
    border-radius: 4px;
    text-transform: capitalize;
    border: solid 1px rgba(176, 181, 196, 0.1);
    position: relative;
    margin-bottom: 10px;
  }
  &__navigation {
    top: 16px;
    .react-datepicker__year-option & {
      top: -3px;
    }
    &--previous {
      left: 18px;
      .react-datepicker__year-option & {
        top: 0;
      }
    }
    &--next {
      left: 176px;
      right: auto;
      .react-datepicker__year-option & {
        bottom: 0;
      }
    }
  }
  &__day-names {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header__dropdown {
    width: calc(40% - 10px);
    height: 30px;
    border-radius: 4px;
    border: solid 1px rgba(176, 181, 196, 0.1);
    margin-bottom: 10px;
  }
  &__year-dropdown-container {
    width: 100%;
    line-height: 30px;
    color: var(--bluey-grey);
    font-size: 14px;
    @include rubik-medium();
  }
  &__year-dropdown {
    top: 50px;
    left: auto;
    right: 8px;
    background-color: white;
    border: none;
    box-shadow: 0 0 27px 0 #bbc1c9; 
  }
  &__year-read-view {
    position: relative;
    visibility: visible !important;
    text-align: left;
    padding-left: 7px;
    color: var(--grey-blue);
    @include regular;
  }
  &__year-read-view--down-arrow {
    position: absolute;
    float: none;
    right: 10px;
    top: 11px;
  }
  &__year-option {
    @include rubik-regular;
    color: var(--grey-blue);
    .react-datepicker__navigation--years-previous {
      top: 4px;
    }
  }
}

@media screen and (max-width: 350px) {
  .react-datepicker-popper {
    width: 290px;
  }
  .react-datepicker {

    &__current-month {
      width: 140px - 10px;
    }
    &__header__dropdown {
      width: 110px;
    }
  }
}
