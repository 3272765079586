.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment__top {
  padding-bottom: 17px;
  margin-bottom: 40px;
  position: relative;
}
.tax-payment__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.tax-payment__top-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--bluey-grey);
}
.tax-payment__top-progress-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  transition: all 0.3s ease;
}
.tax-payment__content {
  display: flex;
}
.tax-payment__tabs-wrap {
  flex: 0 1 320px;
  margin-right: 100px;
}
.tax-payment__tabs-item {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  box-shadow: none;
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  transition: all 0.3s ease;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
}
.tax-payment__tabs-item:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-color: white;
}
.tax-payment__tabs-item_active {
  background: white;
  border-color: var(--dark-slate-blue);
}
.tax-payment__tabs-item:active {
  background: white;
  border-color: var(--dark-slate-blue);
}
.tax-payment__tabs-item:last-child {
  margin-bottom: 0;
}
.tax-payment__tabs-item:disabled {
  cursor: default;
}
.tax-payment__tabs-item-title {
  font-size: 18px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
  transition: all 0.3s ease;
}
.tax-payment__tabs-item:hover:not(:disabled) .tax-payment__tabs-item-title {
  color: var(--grey-blue);
}
.tax-payment__tabs-item:active .tax-payment__tabs-item-title {
  color: var(--dark-slate-blue);
}
.tax-payment__tabs-item_active .tax-payment__tabs-item-title {
  color: var(--dark-slate-blue);
}
.tax-payment__tabs-item-icon_card path {
  width: 25px;
  height: 18px;
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.tax-payment__tabs-item_active .tax-payment__tabs-item-icon_card path {
  fill: var(--dark-slate-blue);
}
.tax-payment__tabs-item:hover:not(:disabled) .tax-payment__tabs-item-icon_card path {
  fill: var(--dark-slate-blue);
}
.tax-payment__tabs-item-icon_wallet {
  width: 25px;
  height: 22px;
}
.tax-payment__tabs-item-icon_wallet path {
  transition: all 0.3s ease;
}
.tax-payment__tabs-item_active .tax-payment__tabs-item-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.tax-payment__tabs-item:hover:not(:disabled) .tax-payment__tabs-item-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.tax-payment__card-wrap {
  flex: 0 1 400px;
}
.tax-payment__warning {
  margin-bottom: 40px;
}

@media screen and (max-width: 980px) {
  .tax-payment__header {
    margin-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment__title {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment__sub-title {
    font-size: 18px;
    line-height: 1.33;
    color: var(--bluey-grey);
    font-family: "Circe-Regular", sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 20px;
  }
  .tax-payment__content {
    padding-right: 50px;
    padding-left: 50px;
  }
  .tax-payment__tabs-wrap {
    flex: 0 1 100%;
    margin-right: 0;
  }
  .tax-payment__tabs-item-title {
    color: var(--grey-blue);
  }
  .tax-payment__tabs-item-icon_card path {
    fill: var(--dark-slate-blue);
  }
  .tax-payment__tabs-item-icon_wallet path {
    fill: var(--dark-slate-blue);
    stroke: var(--dark-slate-blue);
  }
  .tax-payment__tabs-item-icon_web-money path {
    fill: var(--dark-slate-blue);
  }
  .tax-payment__tabs-item-icon_yandex path:first-child {
    fill: #ffcc00;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment__title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment__sub-title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment__content {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 350px) {
  .tax-payment__title {
    padding-left: 14px;
    padding-right: 14px;
  }
  .tax-payment__sub-title {
    padding-left: 14px;
    padding-right: 14px;
  }
  .tax-payment__content {
    padding-right: 14px;
    padding-left: 14px;
  }
}