.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.qr-code-slider {
  max-width: 100%;
  width: 100%;
}
.qr-code-slider__main {
  margin-bottom: 40px;
}
.qr-code-slider__slider-container {
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.qr-code-slider__item {
  align-items: center;
  background-color: white;
  display: flex;
  height: 412px;
  justify-content: center;
}
.qr-code-slider__item-content-circle {
  position: relative;
  width: 352px;
}
.qr-code-slider__item-qr-code-circle {
  left: 109px;
  position: absolute;
  top: 99px;
}
.qr-code-slider__item-qr-code {
  width: 182px;
}
.qr-code-slider__item-content-square {
  height: 352px;
  position: relative;
}
.qr-code-slider__item-img-square {
  height: 352px;
}
.qr-code-slider__item-qr-code-square {
  left: 57px;
  position: absolute;
  top: 85px;
}
.qr-code-slider__nav {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}
.qr-code-slider__nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-right: 30px;
  padding-left: 14px;
  position: relative;
}
.qr-code-slider__nav-item::before {
  background: var(--bluey-grey);
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 8px;
}
.qr-code-slider__nav-item:last-child {
  margin-right: 0;
}
.qr-code-slider__nav-item_active {
  color: var(--dark-slate-blue);
}
.qr-code-slider__nav-item_active::before {
  background: var(--dark-slate-blue);
}
.qr-code-slider__download-wrap {
  display: flex;
  justify-content: center;
}
.qr-code-slider__generating {
  font-family: "Circe-Regular", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.qr-code-slider__generating-text {
  color: #1d2e69;
  font-size: 16px;
  line-height: 24px;
  margin-left: 14px;
  margin-top: -2px;
}
.qr-code-slider__generating-desc {
  color: #b0b5c4;
  font-size: 14px;
  line-height: 20px;
  max-width: 297px;
  text-align: center;
}
.qr-code-slider__download {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 16px;
  text-decoration: underline;
}
.qr-code-slider__download:hover {
  text-decoration: none;
}

@media screen and (max-width: 980px) {
  .qr-code-slider__download {
    border: 1px solid var(--dark-slate-blue);
    border-radius: 8px;
    height: 60px;
    min-width: 335px;
    text-decoration: none;
    width: 335px;
  }
}
@media screen and (max-width: 600px) {
  .qr-code-slider__main {
    margin-bottom: 20px;
  }
  .qr-code-slider__nav {
    margin-bottom: 30px;
  }
  .qr-code-slider__nav-item {
    background: var(--bluey-grey);
    border-radius: 50%;
    height: 8px;
    margin-right: 8px;
    padding-left: 0;
    width: 8px;
  }
  .qr-code-slider__nav-item::before {
    display: none;
  }
  .qr-code-slider__nav-item_active {
    background: var(--dark-slate-blue);
  }
  .qr-code-slider__download {
    min-width: 100%;
    width: 100%;
  }
}