.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.shop-status-tab__top {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}
.shop-status-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-right: 20px;
}
.shop-status-tab__status {
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
  color: white;
  font-family: "Circe-Bold", sans-serif;
}
.shop-status-tab__main {
  max-width: 508px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.shop-status-tab__icon-wrap {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 40px;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.shop-status-tab__icon_check {
  width: 26.67px;
  height: 18.33px;
}
.shop-status-tab__icon_check path {
  stroke: var(--turquoise-blue);
}
.shop-status-tab__icon_detail {
  width: 30px;
  height: 30px;
}
.shop-status-tab__icon_detail path {
  fill: var(--turquoise-blue);
}
.shop-status-tab__icon_lock {
  width: 30px;
  height: 30px;
}
.shop-status-tab__icon_lock path {
  fill: #9da6ba;
}
.shop-status-tab__icon_close path {
  stroke: var(--reddish-pink);
}
.shop-status-tab__main-title {
  font-size: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
.shop-status-tab__text {
  font-size: 16px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.shop-status-tab__text:last-child {
  margin-bottom: 0;
}
.shop-status-tab__text:only-child {
  margin-bottom: 0;
}
.shop-status-tab__text-link {
  color: var(--deep-sky-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}