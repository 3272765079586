.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.se-from-lk-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.se-from-lk {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
}
.se-from-lk__header {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  padding-top: 102px;
  padding-bottom: 155px;
  overflow: hidden;
  position: relative;
  will-change: transform;
}
.se-from-lk_enter-inn .se-from-lk__header {
  padding-top: 86px;
  padding-bottom: 151px;
}
.se-from-lk_waiting .se-from-lk__header {
  padding-top: 88px;
  padding-bottom: 110px;
}
.se-from-lk_success .se-from-lk__header {
  padding-top: 87px;
  padding-bottom: 117px;
}
.se-from-lk_fail .se-from-lk__header {
  padding-top: 86px;
  padding-bottom: 115px;
}
.se-from-lk__header-container {
  position: relative;
  z-index: 20;
}
.se-from-lk__header-bg {
  width: 773px;
  height: 943px;
  position: absolute;
  right: -125px;
  top: 50%;
  margin-top: -534px;
  z-index: 10;
}
.se-from-lk_enter-inn .se-from-lk__header-bg {
  right: -275px;
  margin-top: -554px;
}
.se-from-lk_fail .se-from-lk__header-bg {
  right: -277px;
  top: 50%;
  margin-top: -554px;
}
.se-from-lk__header-close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 50px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  background: white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.se-from-lk__header-close-icon path {
  transition: all 0.3s ease;
}
.se-from-lk__header-close:hover .se-from-lk__header-close-icon path {
  stroke: var(--reddish-pink);
}
.se-from-lk_waiting .se-from-lk__header-close-icon {
  transform: rotate(180deg);
}
.se-from-lk_waiting .se-from-lk__header-close-icon path {
  stroke: var(--dark-slate-blue);
}
.se-from-lk__header-step {
  margin-bottom: 10px;
}
.se-from-lk_enter-inn .se-from-lk__header-step {
  margin-bottom: 15px;
}
.se-from-lk_waiting .se-from-lk__header-step {
  margin-bottom: 13px;
}
.se-from-lk_success .se-from-lk__header-step {
  margin-bottom: 13px;
}
.se-from-lk__header-title {
  font-size: 36px;
  line-height: 1.33;
  color: white;
  margin-bottom: 10px;
  font-family: "Circe-ExtraBold", sans-serif;
}
.se-from-lk_waiting .se-from-lk__header-title {
  max-width: 450px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.se-from-lk_success .se-from-lk__header-title {
  max-width: 336px;
  line-height: 1.46;
  margin-bottom: 20px;
}
.se-from-lk_fail .se-from-lk__header-title {
  max-width: 442px;
  line-height: 1.46;
  margin-bottom: 20px;
}
.se-from-lk__header-desc {
  font-size: 18px;
  line-height: 1.5;
  color: white;
  font-family: "Circe-Regular", sans-serif;
  position: relative;
  max-width: 470px;
  width: 100%;
}
.se-from-lk_enter-inn .se-from-lk__header-desc {
  margin-bottom: 60px;
}
.se-from-lk_waiting .se-from-lk__header-desc {
  margin-bottom: 30px;
}
.se-from-lk_success .se-from-lk__header-desc {
  max-width: 531px;
  margin-bottom: 27px;
}
.se-from-lk_fail .se-from-lk__header-desc {
  max-width: 531px;
  margin-bottom: 33px;
}
.se-from-lk__content .se-from-lk__header-desc {
  color: var(--bluey-grey);
  line-height: 1.33;
}
.se-from-lk__header-desc-link {
  color: var(--dark-slate-blue);
  font-size: 16px;
  line-height: 1.5;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
}
.se-from-lk__header-desc-link:hover {
  text-decoration: none;
}
.se-from-lk__header-desc-warning-icon {
  position: absolute;
  left: -30px;
  top: 2px;
}
.se-from-lk__phone {
  width: 405px;
  height: 354px;
  object-fit: contain;
  position: absolute;
  bottom: 70px;
  right: 19%;
  z-index: 40;
  pointer-events: none;
}
.se-from-lk__form {
  display: flex;
  max-width: 490px;
  width: 100%;
}
.se-from-lk__form-input-holder {
  width: calc(100% - 70px);
  margin-right: 10px;
}
.se-from-lk__next-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--dusk-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.se-from-lk__next-btn:hover:not(:disabled) {
  background: var(--dark-slate-blue);
}
.se-from-lk__next-btn:disabled {
  background: #f6f8fb;
  cursor: not-allowed;
}
.se-from-lk__next-btn-icon {
  margin-left: 5px;
}
.se-from-lk__next-btn-icon use {
  fill: white;
}
.se-from-lk__next-btn:disabled .se-from-lk__next-btn-icon path {
  stroke: #b0b5c4;
}
.se-from-lk__content {
  flex-grow: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: 60px;
  padding-bottom: 180px;
}
.se-from-lk_waiting .se-from-lk__content {
  justify-content: flex-start;
}
.se-from-lk_fail .se-from-lk__content {
  padding-top: 63px;
  flex-direction: column-reverse;
}
.se-from-lk__wallet-btn-wrap {
  margin-bottom: 50px;
  max-width: 335px;
}
.se-from-lk__hint-block .hints-list__title {
  margin-bottom: 16px;
}
.se-from-lk__loader {
  position: relative;
}
.se-from-lk__loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 15px;
  margin-top: -10px;
  margin-left: -6px;
}
.se-from-lk__loader-icon path {
  fill: white;
}
.se-from-lk__info {
  position: absolute;
  left: calc(55% - 7px);
  top: 41%;
}
.se-from-lk_fail .se-from-lk__info {
  padding: 29px 49px 29px 39px;
}
.se-from-lk__info-icon_wink {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-from-lk__info-icon_rock {
  width: 18px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-from-lk__info-icon_sad {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
  margin-right: 5px;
}
.se-from-lk__info-icon_man {
  width: 20px;
  height: 19px;
  display: inline-block;
  vertical-align: -3px;
}
.se-from-lk__loader-wrap {
  position: relative;
}
.se-from-lk__loader {
  position: relative;
}
.se-from-lk__btn-wrap {
  display: flex;
  max-width: 420px;
  width: 100%;
}
.se-from-lk__btn-wrap_help {
  margin-top: 20px;
}

@media screen and (max-width: 1100px) {
  .se-from-lk__info {
    left: 60%;
  }
  .se-from-lk__phone {
    right: 6%;
  }
}
@media screen and (max-width: 980px) {
  .se-from-lk__header {
    padding-top: 68px;
    padding-bottom: 79px;
  }
  .se-from-lk_enter-inn .se-from-lk__header {
    padding-bottom: 79px;
  }
  .se-from-lk__header-title {
    max-width: 227px;
  }
  .se-from-lk_waiting .se-from-lk__header-title {
    font-size: 28px;
  }
  .se-from-lk_success .se-from-lk__header-title {
    margin-bottom: 0;
  }
  .se-from-lk_fail .se-from-lk__header-title {
    margin-bottom: 0;
  }
  .se-from-lk_enter-inn .se-from-lk__content .se-from-lk__header-desc {
    margin-bottom: 30px;
    color: var(--bluey-grey);
  }
  .se-from-lk_waiting .se-from-lk__content .se-from-lk__header-desc {
    color: var(--bluey-grey);
  }
  .se-from-lk_success .se-from-lk__content .se-from-lk__header-desc {
    color: var(--grey-blue);
    font-size: 18px;
    margin-bottom: 30px;
  }
  .se-from-lk_waiting .se-from-lk__content .se-from-lk__header-desc-warning-icon {
    position: relative;
    top: 2px;
    left: auto;
    display: inline;
    margin-right: 10px;
  }
  .se-from-lk__header-bg {
    width: 331px;
    height: 336px;
    margin-top: 0;
    right: -111px;
    top: -37px;
  }
  .se-from-lk_enter-inn .se-from-lk__header-bg {
    right: -111px;
    margin-top: 0;
    top: auto;
    bottom: 0;
  }
  .se-from-lk_success .se-from-lk__header-bg {
    top: -17px;
  }
  .se-from-lk_fail .se-from-lk__header-bg {
    top: -17px;
    margin-top: 0;
    right: -111px;
  }
  .se-from-lk__header-step {
    margin-bottom: 0;
    position: absolute;
    right: 26px;
    top: 24px;
    z-index: 40;
  }
  .se-from-lk__header-close {
    top: 30px;
    right: auto;
    left: 46px;
    box-shadow: none;
    background: transparent;
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
  .se-from-lk_waiting .se-from-lk__header-close {
    transform: rotate(0);
  }
  .se-from-lk__header-close:hover .se-from-lk__header-close-icon path {
    stroke: var(--reddish-pink);
  }
  .se-from-lk_waiting .se-from-lk__header-close-icon path {
    stroke: white;
  }
  .se-from-lk__form {
    flex-direction: column;
    max-width: 420px;
  }
  .se-from-lk_enter-inn .se-from-lk__form {
    margin-bottom: 33px;
  }
  .se-from-lk_waiting .se-from-lk__form {
    flex-direction: row;
  }
  .se-from-lk_fail .se-from-lk__form {
    margin-bottom: 33px;
  }
  .se-from-lk__form-input-holder {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .se-from-lk__small-loader {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .se-from-lk__content {
    padding-bottom: 60px;
  }
  .se-from-lk_success .se-from-lk__content {
    justify-content: flex-start;
  }
  .se-from-lk_fail .se-from-lk__content {
    justify-content: flex-start;
    flex-direction: column;
  }
  .se-from-lk__info {
    position: relative;
    left: auto;
    top: auto;
    padding: 0;
    max-width: 376px;
    margin-bottom: 30px;
  }
  .se-from-lk__info:after {
    display: none;
  }
  .se-from-lk__hint-block {
    margin-bottom: 50px;
  }
  .se-from-lk_enter-inn .se-from-lk__hint-block {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .se-from-lk_enter-inn .se-from-lk__header {
    padding-bottom: 37px;
    padding-top: 67px;
  }
  .se-from-lk_fail .se-from-lk__header {
    padding-top: 68px;
    padding-bottom: 80px;
  }
  .se-from-lk_waiting .se-from-lk__header {
    padding-bottom: 90px;
  }
  .se-from-lk__header-title {
    font-size: 28px;
    font-family: "Circe-Bold", sans-serif;
  }
  .se-from-lk_waiting .se-from-lk__header-title {
    max-width: 300px;
    margin-bottom: 0;
  }
  .se-from-lk_fail .se-from-lk__header-title {
    line-height: 1.3;
    max-width: 330px;
  }
  .se-from-lk_fail .se-from-lk__header-bg {
    top: -51px;
    margin-top: 0;
    right: -46px;
    width: 231px;
    height: 276px;
  }
  .se-from-lk__header-close {
    left: 20px;
  }
  .se-from-lk_fail .se-from-lk__form-input-holder {
    margin-bottom: 0;
  }
  .se-from-lk__content {
    padding-top: 40px;
  }
  .se-from-lk_fail .se-from-lk__content {
    padding-top: 50px;
  }
}
@media screen and (max-width: 350px) {
  .se-from-lk_fail .se-from-lk__header {
    padding-bottom: 48px;
  }
}