.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-preview-form__block {
  margin-bottom: 30px;
}
.show-case-preview-form__block_info {
  margin-bottom: 42px;
}
.show-case-preview_preview .show-case-preview-form__block {
  margin-bottom: 9px;
}
.show-case-preview-form__block-title {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 22px;
}
.show-case-preview-form__button-wrap {
  display: flex;
  margin-bottom: 10px;
}
.show-case-preview-form__button-wrap-preview {
  flex-direction: column;
  gap: 6px;
  display: flex;
  width: 100%;
}
.show-case-preview-form__button-wrap-preview .show-case-preview_preview.standard-button {
  font-weight: bold;
}
.show-case-preview-form__button-wrap-preview .sbp-button__logo {
  height: 14px;
  margin-left: 4px;
  margin-top: 1px;
}
.show-case-preview-form__button {
  width: 100%;
}
.show-case-preview-form__privacy-policy {
  text-align: center;
  padding: 0 33px;
  font-size: 14px;
  line-height: 1.43;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 20px;
}
.show-case-preview-form__privacy-policy-link {
  font-size: 14px;
  line-height: 1.43;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.show-case-preview-form__payment-ways {
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-case-preview-form__payment-ways-item_visa {
  width: 45px;
  height: 20px;
  margin-right: 30px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_visa {
  width: 22px;
  height: 10px;
  margin-right: 14px;
}
.show-case-preview-form__payment-ways-item_msc {
  width: 31px;
  height: 19px;
  margin-right: 24px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_msc {
  width: 15px;
  height: 10px;
  margin-right: 12px;
}
.show-case-preview-form__payment-ways-item_mir {
  width: 46px;
  height: 19px;
  margin-right: 19px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_mir {
  width: 22px;
  height: 9px;
  margin-right: 9px;
}
.show-case-preview-form__payment-ways-item_pci {
  width: 44px;
  height: 18px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_pci {
  width: 21px;
  height: 9px;
}
.show-case-preview-form__iframe-wrap {
  height: 450px;
  position: relative;
}
.show-case-preview-form__iframe-wrap-sbp {
  height: 720px;
  position: relative;
  margin-bottom: 0px;
}
.show-case-preview-form__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding-top: 100px;
  justify-content: center;
  z-index: 10;
}
.show-case-preview-form__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 600px) {
  .show-case-preview-form__block {
    margin-bottom: 20px;
  }
  .show-case-preview-form__iframe-wrap-sbp {
    height: 720px;
  }
}