.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.service {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 20px 30px 23px 30px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  text-decoration: none;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.service_big {
  height: 300px;
  padding: 160px 37px 10px 30px;
}
.service_white {
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.service_white:hover:not(:disabled) {
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
}
.service_cards {
  background: linear-gradient(to right, rgb(255, 210, 84) 0%, rgb(255, 45, 85) 50%, rgb(255, 210, 84) 100%);
  background-size: 200% 100%;
}
.service_cards:hover:not(:disabled) {
  background-position: 100% 0;
}
.service_cash-back {
  background: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(40, 65, 149) 50%, rgb(0, 122, 255) 100%);
  background-size: 200% 100%;
}
.service_cash-back:hover:not(:disabled) {
  background-position: 100% 0;
}
.service_zkh {
  background: linear-gradient(to right, rgb(90, 200, 250) 0%, rgb(0, 122, 255) 50%, rgb(90, 200, 250) 100%);
  background-size: 200% 100%;
}
.service_zkh:hover:not(:disabled) {
  background-position: 100% 0;
}
.service:disabled {
  cursor: not-allowed;
  border: 1px solid #9da6ba;
  background-color: #e4e8ee;
  color: var(--bluey-grey);
  opacity: 0.2;
}
.service:disabled .service-card-content__icon {
  opacity: 0.2;
}
.profile-settings-sz-tab__service .service {
  height: 170px;
}
.service__new-label {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 26px;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 26px;
  background: rgba(255, 45, 85, 0.1);
  border-radius: 8px;
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--reddish-pink);
}
.service__icon-wrap {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 10;
}
.profile-settings-sz-tab__service .service__icon-wrap {
  right: 30px;
  top: 30px;
}
.profile-settings-sz-tab__service .service__icon {
  width: 50px;
  height: 50px;
}
.service__img-wrap {
  position: absolute;
  right: 40px;
  top: 38px;
  z-index: 10;
}
.service__img {
  position: absolute;
  right: 40px;
  top: 38px;
  z-index: 10;
}
.service__img_card {
  width: 100px;
  height: 129px;
}
.service_small .service__img_card {
  position: absolute;
  top: 14px;
  right: 16px;
}
.service__img_phone {
  width: 66px;
  height: 116px;
}
.service_small .service__img_phone {
  position: absolute;
  top: 19px;
  right: 25px;
}
.service__img_ruble {
  width: 68px;
  height: 82px;
}
.service_small .service__img_ruble {
  position: absolute;
  top: 25px;
  right: 33px;
  transform: rotate(-19deg);
}
.service__img_shape1-with-ruble {
  width: 22px;
  height: 22px;
  right: 110px;
  top: 21px;
}
.service__img_shape2-with-ruble {
  width: 14px;
  height: 14px;
  top: 118px;
  right: 28px;
}
.service__line-group {
  position: absolute;
  right: -1px;
  bottom: -1px;
}

@media screen and (max-width: 1194px) {
  .service {
    height: 150px;
    padding: 30px 34px 24px 30px;
  }
  .profile-settings-sz-tab__service .service {
    padding: 20px 34px 24px 20px;
  }
  .service__img_card {
    min-width: 100px;
  }
  .service__img_phone {
    min-width: 66px;
  }
  .service__img_ruble {
    min-width: 68px;
  }
}
@media screen and (max-width: 1000px) {
  .service {
    height: auto;
    min-height: 150px;
  }
  .profile-settings-sz-tab__service .service {
    padding: 30px 34px 24px 30px;
  }
  .service__icon {
    width: 50px;
    height: 50px;
  }
  .service__img_card {
    width: 70px;
    min-width: 70px;
    height: 90px;
  }
  .service__img_phone {
    width: 46px;
    min-width: 46px;
    height: 81px;
  }
  .service__img_ruble {
    width: 47px;
    min-width: 47px;
    height: 57px;
  }
  .service__img_shape1-with-ruble {
    width: 15px;
    height: 15px;
  }
  .service__img_shape2-with-ruble {
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 600px) {
  .service {
    padding: 35px 20px 10px 18px;
  }
  .profile-settings-sz-tab__service .service {
    padding: 34px 34px 24px 18px;
    height: auto;
  }
  .service__icon-wrap {
    right: 20px;
    top: 50px;
  }
}