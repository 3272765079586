.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.code-input {
  position: relative;
}
.code-input > input {
  width: 50px;
  height: 56px;
  margin-left: 16px;
  padding: 0;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.code-input > input:first-child {
  margin-left: 0;
}
.code-input > input:not(:placeholder-shown):valid {
  border-color: #1d2e69;
  box-shadow: none;
}
.code-input > input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code-input > input[type=number] {
  -moz-appearance: textfield;
}
.code-input_invalid > input {
  border-color: #ff2d55;
  box-shadow: none;
}
.code-input_invalid > input::placeholder {
  opacity: 1;
  font-size: 10px;
  color: #ff2d55;
}