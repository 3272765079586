.cards-floating-details {
  bottom: 100px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 90px;
}
.cards-floating-details_non-visible {
  visibility: hidden;
}
.cards-floating-details__shape {
  pointer-events: none;
  position: absolute;
  z-index: 60;
}
.cards-floating-details__shape_1 {
  height: 40px;
  width: 40px;
}
.cards-floating-details__shape_2 {
  height: 94px;
  width: 94px;
}
.cards-floating-details__shape_3 {
  height: 30px;
  width: 30px;
}
.cards-floating-details__shape_4 {
  height: 20px;
  width: 20px;
}
.cards-floating-details__shape_5 {
  height: 90px;
  width: 79px;
}
.cards-floating-details__laptop {
  height: 394px;
  position: absolute;
  width: 417px;
  z-index: 61;
}

@media screen and (max-width: 875px) {
  .cards-floating-details__shape_3 {
    height: 24px;
    width: 24px;
  }
}