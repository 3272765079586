.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-preview {
  width: 100%;
  padding: 180px 0 80px;
  margin-top: -100px;
}
.save-money-preview_real {
  min-height: 100vh;
}
.save-money-preview_logged-in {
  padding-top: 80px;
  margin-top: 0;
}
.save-money-preview_color-theme-1 {
  background-image: var(--save-money-color-theme-1);
}
.save-money-preview_color-theme-2 {
  background-image: var(--save-money-color-theme-2);
}
.save-money-preview_color-theme-3 {
  background-image: var(--save-money-color-theme-3);
}
.save-money-preview_color-theme-4 {
  background-image: var(--save-money-color-theme-4);
}
.save-money-preview_color-theme-5 {
  background-image: var(--save-money-color-theme-5);
}
.save-money-preview_color-theme-6 {
  background-image: var(--save-money-color-theme-6);
}
.save-money-preview_preview {
  padding: 0 0 50px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  pointer-events: none;
}
.save-money-preview_preview .save-money-preview__header {
  padding: 24px 0 40px;
}
.save-money-preview__header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-preview_preview .save-money-preview__header-top {
  padding: 0 24px;
}
.save-money-preview__header-logo {
  width: 62px;
  height: 21px;
}
.save-money-preview__header-burger {
  width: 14px;
  height: 9px;
}
.save-money-preview__header-burger rect {
  fill: white;
}
.save-money-preview__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  bottom: 35px;
  position: relative;
  display: inline-flex;
  height: 30px;
  justify-content: flex-start;
  transform: rotate(180deg);
  flex-direction: row-reverse;
  gap: 10px;
}
.save-money-preview__back-button-wrapper {
  height: 30px;
}
.save-money-preview__back-button-text {
  transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
.save-money-preview__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.save-money-preview_preview .save-money-preview__container {
  padding: 0 57px;
}
.save-money-preview__col_about {
  padding-right: 20px;
  width: 44%;
}
.save-money-preview__col_form {
  width: 56%;
}
.save-money-preview__author-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 40px;
}
.save-money-preview_preview .save-money-preview__author-block {
  margin-bottom: 20px;
}
.save-money-preview__author-block-avatar-wrap {
  margin-right: 15px;
}
.save-money-preview_preview .save-money-preview__author-block-avatar-wrap {
  margin-right: 7px;
}
.save-money-preview__author-block-avatar-holder {
  overflow: hidden;
  width: 60px;
  height: 60px;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}
.save-money-preview_preview .save-money-preview__author-block-avatar-holder {
  width: 29px;
  height: 29px;
  padding: 1px;
}
.save-money-preview__author-block-avatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.save-money-preview__author-block-info-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  width: 100%;
}
.save-money-preview_preview .save-money-preview__author-block-info-wrap {
  padding-top: 2px;
}
.save-money-preview__info-block {
  margin-bottom: 40px;
}
.save-money-preview_preview .save-money-preview__info-block {
  margin-bottom: 20px;
}
.save-money-preview__author-block .save-money-preview__info-block {
  padding-right: 10px;
  margin-bottom: 0;
  flex: 0 1 50%;
}
.save-money-preview__author-block .save-money-preview__info-block:last-child {
  margin-right: 0;
}
.save-money-preview__info-block:last-child {
  margin-bottom: 0;
}
.save-money-preview__info-block-title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 8px;
}
.save-money-preview_preview .save-money-preview__info-block-title {
  font-size: 6.7px;
  margin-bottom: 3px;
}
.save-money-preview__author-block .save-money-preview__info-block-title {
  margin-bottom: 4px;
}
.save-money-preview_preview .save-money-preview__author-block .save-money-preview__info-block-title {
  margin-bottom: 1px;
}
.save-money-preview__info-block-text {
  font-family: "Circe-Bold", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: white;
  word-break: break-word;
}
.save-money-preview_preview .save-money-preview__info-block-text {
  font-size: 9.6px;
}
.save-money-preview__info-block-text_purpose {
  font-size: 40px;
}
.save-money-preview_preview .save-money-preview__info-block-text_purpose {
  font-size: 19px;
  line-height: 1.2;
  word-break: break-word;
}
.save-money-preview__info-block-text_plain-text {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.33;
}
.save-money-preview_preview .save-money-preview__info-block-text_plain-text {
  font-size: 8.6px;
}
.save-money-preview__form-wrap {
  width: 100%;
  max-width: 460px;
  margin: 0 auto 20px;
  border-radius: 8px;
  background-color: white;
  padding: 40px 30px 30px;
  min-height: 489px;
}
.save-money-preview_preview .save-money-preview__form-wrap {
  max-width: 220px;
  margin-bottom: 10px;
  height: auto;
  min-height: auto;
  border-radius: 4px;
  padding: 19px 14px 14px;
}
.save-money-preview__payment-card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 12px;
  padding-bottom: 30px;
}
.save-money-preview__payment-card_success {
  margin-bottom: 20px;
}
.save-money-preview__payment-fail-reason {
  margin-bottom: 20px;
  text-align: center;
  margin-top: 16px;
}
.save-money-preview__payment-fail-reason-key {
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.save-money-preview__payment-fail-reason-value {
  font-size: 14px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.save-money-preview__payment-button {
  width: 100%;
}
.save-money-preview__share-nav {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.save-money-preview__share-nav-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.save-money-preview__share-nav-btn.disabled {
  cursor: default;
}
.save-money-preview__share-nav-btn:disabled {
  cursor: default;
}
.save-money-preview_preview .save-money-preview__share-nav-btn {
  width: 20px;
  height: 20px;
  margin: 0 3px;
}
.save-money-preview__share-nav-icon path {
  fill: rgba(255, 255, 255, 0.5);
  transition: fill 300ms ease;
}
.save-money-preview__share-nav-btn:hover .save-money-preview__share-nav-icon path {
  fill: rgb(255, 255, 255);
}
.save-money-preview__share-nav-btn.disabled:hover .save-money-preview__share-nav-icon path {
  fill: rgba(255, 255, 255, 0.5);
}
.save-money-preview__share-nav-btn:disabled:hover .save-money-preview__share-nav-icon path {
  fill: rgba(255, 255, 255, 0.5);
}
.save-money-preview__share-nav-icon_tw {
  width: 20px;
  height: 16px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_tw {
  width: 10px;
  height: 8px;
}
.save-money-preview__share-nav-icon_tg {
  width: 20px;
  height: 17px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_tg {
  width: 10px;
  height: 8px;
}
.save-money-preview__share-nav-icon_ok {
  width: 20px;
  height: 17px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_ok {
  width: 10px;
  height: 10px;
}
.save-money-preview__share-nav-icon_wa {
  width: 20px;
  height: 17px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_wa {
  width: 10px;
  height: 8px;
}
.save-money-preview__share-nav-icon_fb {
  width: 11px;
  height: 20px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_fb {
  width: 5px;
  height: 10px;
}
.save-money-preview__share-nav-icon_vk {
  width: 23px;
  height: 14px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_vk {
  width: 11px;
  height: 7px;
}
.save-money-preview__blocked-user {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.save-money-preview__blocked-user-img {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.save-money-preview__blocked-user-text .blocked-user-text__title {
  color: white;
}
.save-money-preview__blocked-user-text .blocked-user-text__desc {
  color: white;
}

@media screen and (max-width: 1194px) {
  .save-money-preview {
    margin-top: -90px;
    padding-top: 170px;
  }
  .save-money-preview_logged-in {
    padding-top: 80px;
    margin-top: 0;
  }
  .save-money-preview_preview {
    margin-top: 0;
    padding-top: 0;
  }
  .save-money-preview_preview .save-money-preview__container {
    padding: 0 24px;
  }
  .save-money-preview__col_about {
    width: 50%;
    padding-right: 0;
  }
  .save-money-preview_preview .save-money-preview__col_about {
    padding-right: 20px;
  }
  .save-money-preview__col_form {
    width: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .save-money-preview__container {
    flex-direction: column;
  }
  .save-money-preview__col_about {
    width: 100%;
    margin-bottom: 40px;
  }
  .save-money-preview__col_form {
    width: 100%;
  }
  .save-money-preview__form-wrap {
    max-width: 450px;
    margin-left: 0;
  }
  .save-money-preview_preview .save-money-preview__form-wrap {
    max-width: 100%;
  }
  .save-money-preview__share-nav {
    max-width: 450px;
    margin-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .save-money-preview_logged-in {
    margin-top: -90px;
    padding-top: 170px;
  }
  .save-money-preview__back-button {
    bottom: 15px;
  }
  .save-money-preview_preview {
    margin-top: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 800px) {
  .save-money-preview {
    margin-top: -70px;
    padding-top: 100px;
  }
  .save-money-preview_logged-in {
    margin-top: -70px;
    padding-top: 100px;
  }
  .save-money-preview_preview {
    margin-top: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .save-money-preview {
    padding-bottom: 0;
  }
  .save-money-preview_preview .save-money-preview__header {
    padding-bottom: 30px;
  }
  .save-money-preview_preview .save-money-preview__header-top {
    padding: 0 14px;
  }
  .save-money-preview__container {
    padding: 0;
  }
  .save-money-preview_preview .save-money-preview__container {
    padding: 0;
  }
  .save-money-preview__col_about {
    padding: 0 20px;
    margin-bottom: 30px;
  }
  .save-money-preview_preview .save-money-preview__col_about {
    padding: 0 14px;
    margin-bottom: 19px;
  }
  .save-money-preview__author-block {
    margin-bottom: 30px;
  }
  .save-money-preview__info-block {
    margin-bottom: 30px;
  }
  .save-money-preview__info-block-text {
    font-size: 16px;
    line-height: 1.5;
  }
  .save-money-preview__info-block-text_purpose {
    font-size: 28px;
    line-height: 1.3;
  }
  .save-money-preview__form-wrap {
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    padding: 40px 20px 30px;
  }
  .save-money-preview_preview .save-money-preview__form-wrap {
    margin-bottom: 0;
    border-radius: 0;
  }
}