.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.filters-side-bar {
  position: fixed;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.filters-side-bar > div {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 415px;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.filters-side-bar__content {
  padding-top: 138px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.filters-side-bar__title {
  font-size: 24px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 40px;
}
.filters-side-bar__list {
  margin-bottom: 30px;
}
.filters-side-bar__list-item {
  margin-bottom: 20px;
}
.filters-side-bar__list-item:last-child {
  margin-bottom: 0;
}
.filters-side-bar__sum-wrap {
  display: flex;
}
.filters-side-bar__sum-item {
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
}
.filters-side-bar__sum-item:last-child {
  margin-right: 0;
}
.filters-side-bar__buttons-item {
  width: 100%;
  margin-bottom: 20px;
}
.filters-side-bar__buttons-item:last-child {
  margin-bottom: 0;
}
.filters-side-bar__close-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filters-side-bar__close-button-icon {
  width: 17px;
  height: 17px;
}
.filters-side-bar__close-button-icon path {
  stroke: #616a88;
}