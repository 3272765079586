.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.footer {
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  opacity: 1;
  z-index: 1;
}
.footer-enter {
  opacity: 0;
  visibility: hidden;
}
.footer-enter-done {
  opacity: 1;
  visibility: visible;
}
.footer-exit {
  opacity: 0;
  visibility: visible;
}
.footer-exit-active {
  opacity: 0;
}
.footer_menu-open {
  z-index: 10;
}
.footer_grey {
  background: var(--pale-grey);
}
.footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
}
.footer__already-registered-wrap {
  margin-top: -3px;
}
.footer__already-registered {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  z-index: 65;
}
.footer__already-registered:hover {
  text-decoration: none;
}
.footer__chat-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: var(--light-grey-blue);
  margin: 0 16px 0 0;
  text-decoration: underline;
  font-family: "Circe-Regular", sans-serif;
  position: relative;
  z-index: 65;
}
.footer__chat-button:hover {
  text-decoration: none;
}
.footer__chat-button-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  transform: rotate(0);
}
.footer__chat-button:hover .footer__chat-button-image {
  animation: chat-animation 0.2s ease-in-out forwards;
  animation-iteration-count: 3;
}
.footer__chat-button-icon {
  width: 21px;
  height: 21px;
}

@media screen and (max-width: 600px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 50px;
    min-height: 134px;
  }
  .footer_login {
    padding-top: 60px;
    background: var(--pale-grey);
  }
  .footer__container {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer__already-registered-wrap {
    margin-bottom: 22px;
    margin-top: 0;
  }
}
@keyframes chat-animation {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0);
  }
}