// Fonts
@mixin regular() {
  font-family: 'Circe-Regular', sans-serif;
}

@mixin bold() {
  font-family: 'Circe-Bold', sans-serif;
}

@mixin extra-bold() {
  font-family: 'Circe-ExtraBold', sans-serif;
}

@mixin rubik-regular() {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

@mixin rubik-medium() {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}

@mixin roboto() {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

@mixin roboto-mono() {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
}

@mixin reset-button-styles() {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

@mixin password {
  font-family: 'password', sans-serif;
}

@mixin std-shadow() {
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
}

@mixin std-card-shadow() {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}

// Transition
@mixin std-transition($properties...) {
  @if length($properties) == 0 {
    $properties: all;
  }
  transition-property: $properties;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

// Std title
@mixin std-title() {
  color: var(--dark-slate-blue);
  font-size: 36px;
  @include extra-bold();
}

@mixin std-desc-text() {
  font-size: 18px;
  line-height: 1.33;
  color: var(--light-grey-blue);
  @include regular();
}

@mixin placeHolderTextBankCardInput() {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  @include regular();
  text-align: center;
}

@mixin focusedInputWithLabelAnimations() {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}

@mixin disabledInputWithLabelAnimations() {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}

@mixin inputWithLabelAnimations() {
  @include reset-button-styles;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  @include rubik-regular();
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
  &:focus {
    @include focusedInputWithLabelAnimations();
  }
  &:hover:not(:disabled) {
    box-shadow: 0 4px 27px 0 #e8ecf2;
    background: white;
  }
  &:disabled {
    @include disabledInputWithLabelAnimations();
  }
}

@mixin labelForInputWithLabelAnimation() {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  @include regular();
  pointer-events: none;
  transition: all 0.3s ease;
  transform: scale(1);
  transform-origin: 0 0;
}

@mixin focusedLabelForInputWithLabelAnimation() {
  top: 10px;
  left: 20px;
  color: #616a88;
  transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}

@mixin dashed-border-button {
  font-size: 16px;
  color: var(--dark-slate-blue);
  @include rubik-medium();
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
  &:hover {
    border-bottom-color: transparent;
  }
}

// Animations
@mixin hint-transition() {
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);

  &-enter {
    opacity: 0;
    left: 100%;
  }
  &-enter-active {
    opacity: 1;
    left: 50%;
  }
  &-enter-done {
    opacity: 1;
    left: 50%;
  }
  &-exit {
    opacity: 1;
    left: 50%;
    transition-duration: 0.4s;
  }
  &-exit-active {
    opacity: 0;
    left: 100%;
    transition-duration: 0.4s;
  }

  @media screen and (max-width: 800px) {
    &-enter-active {
      left: 0;
    }
    &-enter-done {
      left: 0;
    }
    &-exit {
      left: 0;
    }
  }
}

@mixin profile-hint-transition() {
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);

  &-enter {
    opacity: 0;
    left: 100%;
  }
  &-enter-active {
    opacity: 1;
    left: 50%;
  }
  &-enter-done {
    opacity: 1;
    left: 50%;
  }
  &-exit {
    opacity: 1;
    left: 50%;
  }
  &-exit-active {
    opacity: 0;
    left: 100%;
  }

  @media screen and (max-width: 1000px) {
    &-enter-active {
      left: 0;
    }
    &-enter-done {
      left: 0;
    }
    &-exit {
      left: 0;
    }
  }
}

@mixin text-out-transition() {
  transition: 0.6s opacity cubic-bezier(0.4, 0, 0.68, 1.94),
    0.6s translateY cubic-bezier(0.4, 0, 0.68, 0.06);

  &-enter {
    opacity: 1;
    transform: translateY(0);
  }
  &-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &-enter-done {
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    opacity: 1;
    transform: translateY(-30px);
  }
  &-exit-active {
    opacity: 0;
    transform: translateY(-30px);
  }
}

@mixin text-in-transition() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
    opacity: 0;
    transform: translateY(30px);
  }
  &-enter-active {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
    opacity: 0;
    transform: translateY(30px);
  }
  &-enter-done {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 1;
    transform: translateY(-30px);
  }
  &-exit-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(-30px);
  }
}

.animation-text {
  @include text-in-transition();
}

@mixin menu-open() {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);

  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
  }
  &-enter-done {
    opacity: 1;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
  }
}

// Animation for blocks from top to bottom NORMAL
@mixin from-top-to-bottom-normal() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 0;
    transform: translateY(-30px);
  }
  &-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 1;
    transform: translateY(0);
  }
  &-exit-active {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(-30px);
  }
}

// Animation for blocks from top to bottom DELAYED
@mixin from-top-to-bottom() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 0;
    transform: translateY(-30px);
  }
  &-enter-done {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 1;
    transform: translateY(-30px);
  }
  &-exit-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(-30px);
  }
}

// Animation for blocks from bottom to top DELAYED
@mixin from-bottom-to-top() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 0;
    transform: translateY(30px);
  }
  &-enter-done {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 1;
    transform: translateY(30px);
  }
  &-exit-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(30px);
  }
}

// Animation for blocks from bottom to top
@mixin from-bottom-to-top-normal() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 0;
    transform: translateY(30px);
  }
  &-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 1;
    transform: translateY(0);
  }
  &-exit-active {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(30px);
  }
}

$class-slug: delay !default;

@for $i from 1 through 20 {
  .#{$class-slug}-#{$i * 100} {
    transition-delay: 100ms * $i;
  }
}

// Animation for blocks from top to bottom
@mixin fade() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
    opacity: 0;
  }
  &-enter-active {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
    opacity: 0;
  }
  &-enter-done {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
    opacity: 1;
  }
  &-exit {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 1;
  }
  &-exit-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
  }
}

// размеры для @media-запросов
$smallPhoneMediaMaxWidth: 320px;
$widePhoneMediaMaxWidth: 480px;
$tabletMediaMaxWidth: 992px;

// мобильные телефоны
@mixin media-phone {
  @media (max-width: $widePhoneMediaMaxWidth) {
    @content;
  }
}

// телефоны и планшеты
@mixin media-phone-and-tablet {
  @media (max-width: $tabletMediaMaxWidth) {
    @content;
  }
}

// мобильные телефоны с небольшим экраном
@mixin media-phone-small {
  @media (max-width: $smallPhoneMediaMaxWidth) {
    @content;
  }
}

// мобильные телефоны с большим экраном
@mixin media-phone-wide {
  @media (min-width: $smallPhoneMediaMaxWidth + 1) and (max-width: $widePhoneMediaMaxWidth) {
    @content;
  }
}

// планшеты
@mixin media-tablet {
  @media (min-width: $widePhoneMediaMaxWidth + 1) and (max-width: $tabletMediaMaxWidth) {
    @content;
  }
}

// десктопы
@mixin media-desktop {
  @media (min-width: $tabletMediaMaxWidth + 1) {
    @content;
  }
}

@mixin manual-animation() {
  &-enter {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 0;
    transform: translateY(30px);
  }
  &-enter-done {
    transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    opacity: 1;
    transform: translateY(0);
  }
  &-exit {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(0);
  }
  &-exit-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
    opacity: 0;
    transform: translateY(30px);
  }
}
