.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.warning {
  background: rgba(255, 45, 85, 0.05);
  padding: 16px 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
}
.warning_static {
  position: static;
}
.warning_position_absolute {
  position: absolute;
}
.warning__container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}
.warning__unconfirmed-e-mail .warning__container {
  flex-wrap: wrap;
}
.warning__icon {
  margin-right: 10px;
  min-width: 20px;
}
.warning__text {
  flex-grow: 20;
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.warning__unconfirmed-e-mail .warning__text {
  width: calc(100% - 30px);
  min-width: calc(100% - 30px);
}
.warning__text_error {
  color: var(--reddish-pink);
}
.warning__unconfirmed-e-mail .warning__text_error {
  display: inline;
}
.warning__link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: var(--grey-blue);
  text-decoration: underline;
}
.warning__link:hover {
  text-decoration: none;
}
.warning__link_confirm {
  margin-left: 0;
}

@media screen and (max-width: 600px) {
  .warning__container {
    padding-left: 20px;
    padding-right: 10px;
    align-items: flex-start;
  }
  .warning_text .warning__container {
    align-items: center;
  }
  .warning_text .warning__icon {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
  .warning__text {
    line-height: 1.2;
  }
  .warning__link {
    line-height: 1.2;
  }
}