.money-transfer-card-header-qiwi {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 29px 36px 20px 30px;
  border-radius: 12px 12px 0 0;
  background-color: #ff8c00;

  &__logo {
    width: 100px;
    height: 45px;
  }
}
