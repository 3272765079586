.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.bill-creation-card {
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  background-color: white;
  min-height: 390px;
  display: flex;
  flex-direction: column;
}
.bill-creation-card__header {
  padding: 21px 30px;
  min-height: 118px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  border-radius: 14px 14px 0 0;
  overflow: hidden;
}
.bill-creation-card__header-icon-wrap {
  width: 60px;
  height: 60px;
  min-width: 60px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}
.bill-creation-card__header-icon {
  width: 29px;
  height: 26px;
  fill: var(--deep-sky-blue);
}
.bill-creation-card__header-text {
  font-size: 15px;
  line-height: 1.6;
  font-family: "Circe-Regular", sans-serif;
  color: white;
  width: 50%;
}
.bill-creation-card__header-text-link {
  font-size: 15px;
  line-height: 1.6;
  font-family: "Circe-Regular", sans-serif;
  color: white;
  margin-left: 3px;
}
.bill-creation-card__header-text-link:hover {
  text-decoration: none;
}
.bill-creation-card__content {
  padding: 30px 20px 40px 20px;
  display: flex;
  flex-grow: 20;
}
.bill-creation-card__content_error {
  flex-direction: column;
  padding-top: 0;
}
.bill-creation-card__left-part {
  width: 58%;
  min-width: 58%;
  margin-right: 20px;
  flex-grow: 20;
}
.bill-creation-card__input-holder {
  margin-bottom: 10px;
  position: relative;
}
.bill-creation-card__input-holder:last-child {
  margin-bottom: 0;
}
.bill-creation-card__input {
  height: 60px;
  border-radius: 8px;
  background-color: rgba(228, 232, 238, 0.2);
  width: 100%;
  border: 1px solid rgba(228, 232, 238, 0.2);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 10px 10px 0 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.bill-creation-card__input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.bill-creation-card__input[type=number]::-webkit-outer-spin-button, .bill-creation-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bill-creation-card__input[type=number] {
  -moz-appearance: textfield;
}
.bill-creation-card__input_service-name {
  padding: 10px 40px 0 20px;
}
.bill-creation-card__input-label {
  font-size: 8px;
  text-transform: uppercase;
  color: #6b778d;
  position: absolute;
  left: 20px;
  top: 7px;
  font-family: "Circe-Bold", sans-serif;
}
.bill-creation-card__input-check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.bill-creation-card__input-check path {
  stroke: var(--dark-slate-blue);
}
.bill-creation-card__right-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 20;
}
.bill-creation-card__tabs {
  display: flex;
  margin-bottom: 10px;
  height: 36px;
}
.bill-creation-card__tabs-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 36px;
  width: 119px;
  border: 1px solid var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.bill-creation-card__tabs-item:first-child {
  border-radius: 18px 0 0 18px;
  border-right: none;
}
.bill-creation-card__tabs-item:last-child {
  border-radius: 0 18px 18px 0;
  border-left: none;
}
.bill-creation-card__tabs-item_active {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  color: white;
  border: transparent;
}
.bill-creation-card__tip {
  font-size: 14px;
  line-height: 1.71;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.bill-creation-card__main .bill-creation-card__tip {
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 20px;
}
.bill-creation-card__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  font-family: "Circe-Bold", sans-serif;
}
.bill-creation-card__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.bill-creation-card__info {
  width: 53%;
  min-width: 53%;
}
.bill-creation-card__info-block {
  margin-bottom: 15px;
}
.bill-creation-card__info-block:last-child {
  margin-bottom: 0;
}
.bill-creation-card__info-block-label {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.bill-creation-card__info-block-value {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  word-break: break-all;
}
.bill-creation-card__main {
  width: 47%;
  min-width: 47%;
  text-align: right;
}
.bill-creation-card__link-block {
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
}
.bill-creation-card__link-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  width: 100%;
  max-width: 100%;
}
.bill-creation-card__link-icon {
  margin-right: 10px;
  width: 12px;
  min-width: 12px;
  height: 14px;
}
.bill-creation-card__link-icon path {
  transition: all 0.3s ease;
}
.bill-creation-card__link-wrap:hover .bill-creation-card__link-icon path {
  fill: var(--deep-sky-blue);
}
.bill-creation-card__link {
  font-size: 14px;
  color: var(--dark-slate-blue);
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  transition: all 0.3s ease;
  max-width: calc(100% - 12px - 10px);
  word-break: break-all;
  text-align: left;
}
.bill-creation-card__link-wrap:hover .bill-creation-card__link {
  color: var(--deep-sky-blue);
}
.bill-creation-card__link-share-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bill-creation-card__link-share-block_with-copy {
  justify-content: space-between;
}
.bill-creation-card__copy-success {
  width: 100px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  background: rgba(0, 173, 181, 0.1);
  border-radius: 10px;
  font-size: 12px;
  color: var(--turquoise-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.bill-creation-card__link-share-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bill-creation-card__link-share-item {
  margin-right: 10px;
  padding: 0;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  outline: none;
}
.bill-creation-card__link-share-item-icon {
  width: 20px;
  height: 15px;
}
.bill-creation-card__link-share-item-icon_envelope {
  height: 14px;
}
.bill-creation-card__link-share-item-icon_envelope g {
  transition: all 0.3s ease;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_envelope g {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_share {
  width: 15px;
  height: 14px;
}
.bill-creation-card__link-share-item-icon_share path {
  transition: all 0.3s ease;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_share path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_vk path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_vk path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_wa path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_wa path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_ok path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_ok path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_fb path {
  transition: all 0.3s ease;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_fb path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_tg path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_tg path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__requests-history-link {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.bill-creation-card__requests-history-link:hover {
  border-bottom-color: transparent;
}
.bill-creation-card__error-icon-wrap {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -45px auto 30px auto;
  position: relative;
}
.bill-creation-card__error-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -6px;
}
.bill-creation-card__error-icon path {
  fill: var(--reddish-pink);
}
.bill-creation-card__error-message {
  font-size: 12px;
  color: var(--bluey-grey);
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 53px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.bill-creation-card__error-message-link {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.bill-creation-card__error-message-link:hover {
  text-decoration: none;
}
.bill-creation-card__error-link-wrap {
  display: flex;
  justify-content: center;
}
.bill-creation-card__error-link {
  width: 240px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.bill-creation-card__error-link:hover {
  background: var(--dusk-blue);
}

@media screen and (max-width: 980px) {
  .bill-creation-card {
    border-radius: 0;
    box-shadow: none;
    min-height: auto;
    background: transparent;
    overflow: visible;
  }
  .bill-creation-card__content {
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
  }
  .bill-creation-card__content_error {
    flex-direction: column;
    padding-top: 0;
    margin-top: -45px;
  }
  .bill-creation-card__left-part {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .bill-creation-card__input-holder {
    margin-bottom: 20px;
  }
  .bill-creation-card__right-part {
    justify-content: flex-start;
    margin-bottom: 36px;
  }
  .bill-creation-card__tabs {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 36px;
  }
  .bill-creation-card__tip {
    text-align: center;
  }
  .bill-creation-card__main .bill-creation-card__tip {
    width: 100%;
  }
  .bill-creation-card__info .bill-creation-card__tip {
    margin-bottom: 20px;
    text-align: left;
  }
  .bill-creation-card__button {
    border-radius: 30px;
    box-shadow: 0 4px 27px 0 #e8ecf2;
    font-size: 16px;
    font-family: "Circe-Bold", sans-serif;
  }
  .bill-creation-card__info {
    width: 100%;
    min-width: 100%;
  }
  .bill-creation-card__info-block {
    margin-bottom: 15px;
    display: flex;
  }
  .bill-creation-card__info-block:last-child {
    margin-bottom: 0;
  }
  .bill-creation-card__info-block-label {
    margin-bottom: 0;
    margin-right: 5px;
  }
  .bill-creation-card__info-block-label:after {
    content: ":";
    font-size: 16px;
    color: var(--bluey-grey);
    font-family: "Rubik", sans-serif;
    font-weight: 400;
  }
  .bill-creation-card__main {
    width: 100%;
    min-width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .bill-creation-card__link-block {
    margin-bottom: 0;
  }
  .bill-creation-card__link-wrap {
    justify-content: center;
    width: 100%;
  }
  .bill-creation-card__link-icon {
    min-width: 12px;
  }
  .bill-creation-card__link-icon path {
    fill: var(--bluey-grey);
  }
  .bill-creation-card__link {
    color: var(--bluey-grey);
    word-wrap: break-word;
  }
  .bill-creation-card__link-share-block {
    justify-content: center;
    flex-direction: column;
  }
  .bill-creation-card__link-share-block_with-copy {
    justify-content: center;
  }
  .bill-creation-card__copy-success {
    margin-bottom: 10px;
  }
  .bill-creation-card__link-share-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .bill-creation-card__link-share-item {
    margin-right: 10px;
    padding: 0;
    border: none;
    cursor: pointer;
    box-shadow: none;
    background: transparent;
  }
  .bill-creation-card__link-share-item-icon_envelope g {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_share path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_vk path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_wa path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_ok path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_fb path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_tg path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__requests-history-link-wrap {
    display: flex;
    justify-content: center;
  }
  .bill-creation-card__error-message {
    filter: drop-shadow(0 4px 27px #e8ecf2);
    background: white;
    padding: 20px 30px;
    border-radius: 15px;
    position: relative;
    text-align: left;
    line-height: 1.2;
    font-size: 16px;
    color: var(--dark-slate-blue);
  }
  .bill-creation-card__error-message-triangle {
    width: 29px;
    height: 23px;
    position: absolute;
    bottom: -23px;
    left: 22px;
  }
  .bill-creation-card__error-message-link {
    font-size: 16px;
  }
  .bill-creation-card__error-icon-wrap {
    margin-top: 0;
  }
  .bill-creation-card__error-link {
    border-radius: 30px;
    width: 100%;
    min-width: 240px;
  }
}