.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-registration-summary {
  width: 100%;
}
.income-registration-summary__item {
  margin-bottom: 15px;
}
.income-registration-summary__item-label {
  font-size: 18px;
  color: var(--bluey-grey);
  line-height: 1.33;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}
.income-registration-summary__item-text {
  font-size: 18px;
  color: var(--grey-blue);
  line-height: 1.33;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  word-break: break-all;
}
.income-registration-summary__button-wrap {
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
@media (max-width: 600px) {
  .income-registration-summary__button-wrap {
    grid-template-columns: 1fr;
  }
}
.income-registration-summary__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
}
.income-registration-summary__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.income-registration-summary__button:disabled {
  cursor: not-allowed;
  background: rgba(29, 46, 105, 0.1);
}
.income-registration-summary__button_stroked {
  background: white;
  border: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.income-registration-summary__button_stroked:hover:not(:disabled) {
  background: white;
}
.income-registration-summary__button_stroked:disabled {
  background: white;
}

@media screen and (max-width: 600px) {
  .income-registration-summary__button-wrap {
    flex-direction: column-reverse;
    margin-top: 40px;
  }
  .income-registration-summary__button {
    border-radius: 30px;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}