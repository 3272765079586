.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-partners-tab__partners {
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.profile-settings-partners-tab__partners__item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 12px;
  text-decoration: none;
}
.profile-settings-partners-tab__partners__item:hover {
  background-color: var(--pale-grey);
}
.profile-settings-partners-tab__partners__item:hover .profile-settings-partners-tab__partners__arrow {
  opacity: 1;
  transform: translateX(0);
}
.profile-settings-partners-tab__partners__status {
  font-family: "Circe-Regular", sans-serif;
  line-height: 16px;
  font-size: 12px;
}
.profile-settings-partners-tab__partners__status_connected {
  color: var(--grey-blue);
}
.profile-settings-partners-tab__partners__status_archived {
  color: var(--light-grey-blue);
}
.profile-settings-partners-tab__partners__status_other {
  color: var(--marigold);
}
.profile-settings-partners-tab__partners__name {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 2px;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.profile-settings-partners-tab__partners__arrow {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  pointer-events: none;
  width: 20px;
  height: 20px;
  margin: auto 0;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s, transform 0.3s;
}

@media (max-width: 980px) {
  .profile-settings-partners-tab__partners_arrow {
    display: none;
  }
}