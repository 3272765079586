.circle-progress {
  transform: rotate(-90deg);
}
.circle-progress__meter {
  fill: none;
  opacity: 0.1;
}
.circle-progress__value {
  fill: none;
  stroke: #f77a52;
  stroke-linecap: round;
}