.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-sz-tab {
  width: 100%;
  margin-bottom: 102px;
  padding-left: 110px;
}
.profile-settings-sz-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 42px;
  padding-top: 16px;
}
.profile-settings-sz-tab__main {
  display: flex;
  align-items: flex-start;
}
.profile-settings-sz-tab__services-title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}
.profile-settings-sz-tab__item {
  width: calc((100% - 30px) / 2);
  margin-right: 30px;
}
.profile-settings-sz-tab__item:last-child {
  margin-right: 0;
}
.profile-settings-sz-tab__service {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.profile-settings-sz-tab__service:last-child {
  margin-bottom: 0;
}
.profile-settings-sz-tab__service-notice {
  position: absolute;
  width: 100%;
  top: 26px;
  max-width: 280px;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(13, 25, 66, 0.1);
  background-color: var(--pale-grey);
  padding: 20px;
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  left: 50%;
  margin-left: -140px;
  z-index: 50;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.profile-settings-sz-tab__service-notice:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -3px;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--pale-grey) transparent transparent transparent;
}
.profile-settings-sz-tab__service:hover .profile-settings-sz-tab__service-notice {
  opacity: 1;
}

@media screen and (max-width: 1194px) {
  .profile-settings-sz-tab {
    padding-left: 0;
  }
}
@media screen and (max-width: 780px) {
  .profile-settings-sz-tab__main {
    flex-direction: column;
  }
  .profile-settings-sz-tab__item {
    width: 100%;
    margin-right: 0;
  }
  .profile-settings-sz-tab__item:first-child {
    margin-bottom: 40px;
  }
  .profile-settings-sz-tab__item:last-child {
    margin-right: 0;
  }
}