.floatingDetails {
  position: absolute;
  top: 120px;
  right: 64px;
  width: 663px;
  height: 440px;

  &Shape1,
  &Shape2,
  &Shape3,
  &Shape4,
  &Shape5,
  &Shape6,
  &Shape7,
  &Shape8,
  &Shape9 {
    position: absolute;
    pointer-events: none;
  }

  &Shape1 {
    top: 52.27%;
    left: 93.97%;
    width: 20px;
    height: 20px;
  }

  &Shape2 {
    top: 24.09%;
    left: 0;
    width: 20px;
    height: 20px;
  }

  &Shape3 {
    top: 94.42%;
    left: 31.83%;
    width: 23px;
    height: 24px;
  }

  &Shape4 {
    top: 0;
    left: 33.24%;
    width: 44.83px;
    height: 48.21px;
  }

  &Shape5 {
    top: 4.7%;
    left: 59.2%;
    width: 25px;
    height: 27px;
  }

  &Shape6 {
    top: 61.77%;
    left: 70.69%;
    width: 60.28px;
    height: 77.32px;
  }

  &Shape7 {
    top: 7.5%;
    left: 48.11%;
    width: 228.04px;
    height: 293.49px;
  }

  &Shape8 {
    top: 29.09%;
    left: 45.85%;
    width: 56px;
    height: 59px;
  }

  &Shape9 {
    top: 49.59%;
    left: -0.39%;
    width: 84.55px;
    height: 88.45px;
  }
}

@media (max-width: 980px) {
  .floatingDetails {
    top: 90px;
    right: 0;
    width: 50%;
    height: 170px;
  }
}
