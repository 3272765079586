.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-time-line-step__time-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.acquiring-time-line-step__circle {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
}
.acquiring-time-line-step__circle:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ffffff;
  opacity: 0.4;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: -2px;
}
.acquiring-time-line-step__line {
  height: 4px;
  width: calc(100% - 16px);
}
.acquiring-time-line-step__text-wrap {
  width: 130px;
  margin-left: -65px;
  text-align: center;
}
.acquiring-time-line-step_is-last .acquiring-time-line-step__text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: auto;
  margin-left: 0;
}
.acquiring-time-line-step:first-child .acquiring-time-line-step__text-wrap {
  width: auto;
  text-align: left;
  margin-left: 0;
}
.acquiring-time-line-step__title {
  color: var(--grey-blue);
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 5px;
}
.acquiring-time-line-step_is-last .acquiring-time-line-step__title {
  white-space: nowrap;
}
.acquiring-time-line-step__title-amount {
  display: inline-block;
}
.acquiring-time-line-step__date {
  font-size: 12px;
  color: var(--light-grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-time-line-step_is-last .acquiring-time-line-step__date {
  white-space: nowrap;
}

@media screen and (max-width: 880px) {
  .acquiring-time-line-step {
    display: flex;
  }
  .acquiring-time-line-step__time-line {
    flex-direction: column;
    margin-bottom: 0;
    margin-right: 12px;
  }
  .acquiring-time-line-step__line {
    height: 38px;
    width: 2px;
  }
  .acquiring-time-line-step__text-wrap {
    width: auto;
    margin-left: 0;
    text-align: left;
  }
  .acquiring-time-line-step_is-last .acquiring-time-line-step__text-wrap {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .acquiring-time-line-step:first-child .acquiring-time-line-step__text-wrap {
    width: auto;
    text-align: left;
    margin-left: 0;
  }
  .acquiring-time-line-step__title {
    display: flex;
  }
  .acquiring-time-line-step__title .taxes-amount {
    margin-left: 4px;
  }
}