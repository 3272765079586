.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint-notifications__main {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  width: 50%;
  z-index: 2000;
  opacity: 0;
  left: 100%;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.hint-notifications__main-enter {
  opacity: 0;
  left: 100%;
}
.hint-notifications__main-enter-active {
  opacity: 1;
  left: 50%;
}
.hint-notifications__main-enter-done {
  opacity: 1;
  left: 50%;
}
.hint-notifications__main-exit {
  opacity: 1;
  left: 50%;
  transition-duration: 0.4s;
}
.hint-notifications__main-exit-active {
  opacity: 0;
  left: 100%;
  transition-duration: 0.4s;
}
.hint-notifications__top {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  height: 158px;
}
.hint-notifications__logo-wrap {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: white;
  position: absolute;
  left: 65px;
  top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 0 rgba(100, 101, 103, 0.3215686275);
  overflow: hidden;
}
.hint-notifications__logo {
  max-width: 100%;
  max-height: 100%;
}
.hint-notifications__inner-wrap {
  position: relative;
  bottom: 0;
  top: 0;
  right: -30px;
  height: calc(100% - 158px);
  padding-top: 93px;
  padding-left: 65px;
}
.hint-notifications__inner {
  max-width: 588px;
  position: relative;
  overflow-y: auto;
  bottom: 0;
  top: 0;
  right: 0;
  height: 100%;
  padding-right: 45px;
  padding-bottom: 40px;
}
.hint-notifications__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
.hint-notifications__text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-notifications__text p {
  font-size: inherit;
  line-height: inherit;
  color: indianred;
  font-family: inherit;
}
.hint-notifications__text-link {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-left: 5px;
  margin-right: 5px;
}
.hint-notifications__text-link:hover {
  text-decoration: none;
}
.hint-notifications__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 38px;
  right: 58px;
}
.hint-notifications__close-icon path {
  stroke: white;
}

@media screen and (max-width: 1000px) {
  .hint-notifications__main {
    width: 100%;
    overflow-y: auto;
  }
  .hint-notifications__main-enter-active {
    left: 0;
  }
  .hint-notifications__main-enter-done {
    left: 0;
  }
  .hint-notifications__main-exit {
    left: 0;
  }
  .hint-notifications__top {
    height: 200px;
  }
  .hint-notifications__logo-wrap {
    left: 50%;
    margin-left: -45px;
  }
  .hint-notifications__inner-wrap {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    right: 0;
  }
  .hint-notifications__inner {
    max-width: 100%;
    height: auto;
    padding-right: 0;
  }
  .hint-notifications__title {
    font-size: 36px;
    line-height: 1.11;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    text-align: center;
    margin-bottom: 40px;
  }
  .hint-notifications__text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    width: 100%;
  }
  .hint-notifications__text-link {
    font-size: 16px;
    line-height: 1.5;
  }
}