.money-transfer-progress-line {
  position: relative;
  height: 4px;
  margin-top: 10px;
}
.money-transfer-progress-line__wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  border-bottom: 1px solid #b0b5c4;
}
.money-transfer-progress-line__value {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-image: linear-gradient(90deg, #6c5ce7 0%, #5ac8fa 123.47%);
  transition: width 0.3s;
}

@media (max-width: 980px) {
  .money-transfer-progress-line {
    margin-top: 12px;
  }
  .money-transfer-progress-line__wrap {
    left: -20px;
    right: -20px;
  }
}