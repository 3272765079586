@font-face {
  font-display: swap;
  font-family: 'Circe-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('static/fonts/Circe-Regular.eot');
  src: local('☺'), url('static/fonts/Circe-Regular.woff') format('woff'),
    url('static/fonts/Circe-Regular.ttf') format('truetype'),
    url('static/fonts/Circe-Regular.svg') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Circe-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('static/fonts/Circe-Bold.eot');
  src: local('☺'), url('static/fonts/Circe-Bold.woff') format('woff'),
    url('static/fonts/Circe-Bold.ttf') format('truetype'),
    url('static/fonts/Circe-Bold.svg') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Circe-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: url('static/fonts/Circe-ExtraBold.eot');
  src: local('☺'), url('static/fonts/Circe-ExtraBold.woff') format('woff'),
    url('static/fonts/Circe-ExtraBold.ttf') format('truetype'),
    url('static/fonts/Circe-ExtraBold.svg') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

//@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap&subset=cyrillic');
//@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono|Rubik:400,500&display=swap&subset=cyrillic');
