.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.company-name-input-with-dropdown {
  position: relative;
  height: 60px;
  z-index: 30;
}
.company-name-input-with-dropdown_dropdown-open {
  z-index: 50;
  box-shadow: 0px 4px 27px #e8ecf2;
  border-radius: 8px 8px 0 0;
}
.company-name-input-with-dropdown__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
  padding: 10px 40px 0 20px;
}
.company-name-input-with-dropdown__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.company-name-input-with-dropdown__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.company-name-input-with-dropdown__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.company-name-input-with-dropdown_focused .company-name-input-with-dropdown__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.company-name-input-with-dropdown_focused.company-name-input-with-dropdown_dropdown-open .company-name-input-with-dropdown__input {
  border-radius: 8px 8px 0px 0px;
  border-color: white;
  background: white;
}
.company-name-input-with-dropdown_std-styles .company-name-input-with-dropdown__input {
  background: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  box-shadow: none;
  border: 1px solid rgba(228, 232, 238, 0.2);
}
.company-name-input-with-dropdown__inner {
  box-shadow: 0px 4px 27px #e8ecf2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}
.company-name-input-with-dropdown_std-styles .company-name-input-with-dropdown__inner {
  box-shadow: none;
}
.company-name-input-with-dropdown__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: scale(1);
  transform-origin: 0 0;
}
.company-name-input-with-dropdown:focus-within .company-name-input-with-dropdown__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.company-name-input-with-dropdown_focused .company-name-input-with-dropdown__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.company-name-input-with-dropdown_std-styles .company-name-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  transform: scale(0.45);
}
.company-name-input-with-dropdown_focused.company-name-input-with-dropdown:focus-within .company-name-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  transform: scale(0.45);
}
.company-name-input-with-dropdown_focused.company-name-input-with-dropdown_focused .company-name-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  transform: scale(0.45);
}
.company-name-input-with-dropdown__dropdown {
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  z-index: 20;
  box-shadow: 0px 4px 27px #e8ecf2;
}
.company-name-input-with-dropdown__dropdown-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  min-height: 60px;
  padding: 10px 20px;
  border-bottom: 1px solid #e4e8ee;
  text-align: left;
  display: flex;
  flex-direction: column;
  transition: background 0.3s ease-in-out;
}
.company-name-input-with-dropdown__dropdown-item:last-child {
  border-bottom: none;
}
.company-name-input-with-dropdown__dropdown-item:first-child {
  border-top: 1px solid #e4e8ee;
}
.company-name-input-with-dropdown__dropdown-item:hover {
  background: #f6f8fb;
}
.company-name-input-with-dropdown__dropdown-item-inn {
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #b0b5c4;
  transition: color 0.3s ease-in-out;
}
.company-name-input-with-dropdown__dropdown-item:hover .company-name-input-with-dropdown__dropdown-item-inn {
  color: var(--dark-slate-blue);
}
.company-name-input-with-dropdown__dropdown-item-company-name {
  font-size: 12px;
  color: #b0b5c4;
  font-family: "Circe-Regular", sans-serif;
  transition: color 0.3s ease-in-out;
}
.company-name-input-with-dropdown__dropdown-item:hover .company-name-input-with-dropdown__dropdown-item-company-name {
  color: #616a88;
}
.company-name-input-with-dropdown__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 4px;
}
.company-name-input-with-dropdown__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--grey-blue);
  opacity: 0.6;
  position: absolute;
}
.company-name-input-with-dropdown__check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.company-name-input-with-dropdown__check path {
  stroke: var(--dark-slate-blue);
}