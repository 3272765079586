.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint-auth {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  width: 50%;
  display: flex;
  align-items: center;
  z-index: 200;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.hint-auth-enter {
  opacity: 0;
  left: 100%;
}
.hint-auth-enter-active {
  opacity: 1;
  left: 50%;
}
.hint-auth-enter-done {
  opacity: 1;
  left: 50%;
}
.hint-auth-exit {
  opacity: 1;
  left: 50%;
}
.hint-auth-exit-active {
  opacity: 0;
  left: 100%;
}
@media screen and (max-width: 1000px) {
  .hint-auth-enter-active {
    left: 0;
  }
  .hint-auth-enter-done {
    left: 0;
  }
  .hint-auth-exit {
    left: 0;
  }
}
.hint-auth_self-employed {
  align-items: flex-start !important;
}
.hint-auth__inner {
  position: relative;
  bottom: 0;
  top: 0;
  right: -30px;
  height: calc(100% - 158px);
  padding-top: 40px;
  padding-left: 45px;
}
.hint-auth_self-employed .hint-auth__inner {
  padding-top: 300px;
}
.hint-auth__content {
  max-width: 588px;
  position: relative;
  overflow-y: auto;
  bottom: 0;
  top: 0;
  right: 0;
  height: 100%;
  padding-right: 45px;
  padding-bottom: 40px;
}
.hint-auth__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
.hint-auth__subtitle {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 1.33;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 20px;
  width: 90%;
}
.hint-auth__content-text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-auth__list__middle {
  width: 80%;
}
.hint-auth__list-item {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.35;
  color: var(--grey-blue);
}
.hint-auth__tab-content .hint-auth__list-item {
  font-size: 15px;
}
.hint-auth_no-access-rights .hint-auth__list-item {
  font-size: 15px;
  margin-bottom: 4px;
}
.hint-auth_no-access-rights .hint-auth__list-item:last-child {
  margin-bottom: 0;
}
.hint-auth__list-item-link {
  color: var(--dusk-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.33;
  margin-left: 3px;
  margin-right: 3px;
}
.hint-auth__tab-content .hint-auth__list-item-link {
  font-size: 15px;
}
.hint-auth_no-access-rights .hint-auth__list-item-link {
  font-size: 15px;
}
.hint-auth__link-item {
  margin-bottom: 25px;
}
.hint-auth__link-item:last-child {
  margin-bottom: 0;
}
.hint-auth__text-block {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-auth__text-block:last-child {
  margin-bottom: 0;
}
.hint-auth__text-block-title {
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
}
.hint-auth__tab {
  margin-bottom: 30px;
}
.hint-auth__tab:last-child {
  margin-bottom: 0;
}
.hint-auth__tab-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0 0 3px 0;
  text-align: left;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.hint-auth__tab-item-text {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  border-bottom: 2px dotted var(--dark-slate-blue);
}
.hint-auth__tab-item_active .hint-auth__tab-item-text {
  color: var(--grey-blue);
  border-bottom-color: transparent;
}
.hint-auth__tab-item:hover .hint-auth__tab-item-text {
  border-bottom-color: transparent;
}
.hint-auth__tab-content {
  padding-top: 20px;
}
.hint-auth__close {
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 38px;
  right: 58px;
  border: none;
}
.hint-auth__close path {
  transition: stroke 0.2s ease-in-out;
}
.hint-auth__close:hover path {
  stroke: var(--reddish-pink);
}
.hint-auth__app-links {
  display: flex;
  margin-top: 47px;
}
.hint-auth__app-links-item {
  margin-right: 39px;
}
.hint-auth__app-links-item:last-child {
  margin-right: 0;
}
.hint-auth__app-links-item-img_app-store {
  width: 111px;
  height: 30px;
}
.hint-auth__app-links-item-img_google-play {
  width: 117px;
  height: 29px;
}
.hint-auth__app-links-item:hover .hint-auth__app-links-item-img path {
  fill: var(--dusk-blue);
}

@media screen and (max-width: 1000px) {
  .hint-auth {
    width: 100%;
    overflow-y: auto;
  }
  .hint-auth__inner {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    right: 0;
  }
  .hint-auth__content {
    max-width: 100%;
    height: auto;
    padding-right: 0;
  }
}
@media screen and (max-width: 800px) {
  .hint-auth {
    width: 100%;
    align-items: flex-start;
    padding-top: 141px;
  }
  .hint-auth__inner {
    padding-bottom: 70px;
    padding-top: 0;
  }
  .hint-auth_self-employed .hint-auth__inner {
    padding-top: 0;
  }
  .hint-auth__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
  .hint-auth__tab-content .hint-auth__list-item {
    margin-bottom: 15px;
  }
  .hint-auth__tab-content .hint-auth__list-item:last-child {
    margin-bottom: 0;
  }
  .hint-auth_no-access-rights .hint-auth__list-item {
    margin-bottom: 15px;
  }
  .hint-auth_no-access-rights .hint-auth__list-item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .hint-auth__close {
    right: 20px;
    top: 25px;
  }
}