.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.22);
}
.loader_main {
  background: white;
  z-index: 1000;
}
.loader__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 11px;
  margin-left: -32px;
  margin-top: -5px;
}
.loader__loading-inner {
  position: absolute;
  top: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: white;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader_inverted .loader__loading-inner {
  background: var(--dark-slate-blue);
}
.loader__loading-inner:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.loader__loading-inner:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loader__loading-inner:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loader__loading-inner:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}