.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-preview {
  width: 100%;
  margin-top: -100px;
}
.show-case-preview_logged-in {
  margin-top: 0;
}
.show-case-preview_preview {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 0;
}
.show-case-preview_preview .input-with-label-animation__input,
.show-case-preview_preview .sum-input__input {
  height: 28px;
  padding: 0 8px;
  border-radius: 3.8px;
  font-size: 7.6px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.show-case-preview_preview .textarea-with-label-animation__textarea {
  height: 62px;
  border-radius: 3.8px;
  padding: 0;
}
.show-case-preview_preview .input-with-label-animation__label,
.show-case-preview_preview .textarea-with-label-animation__label,
.show-case-preview_preview .sum-input__label {
  font-size: 8.6px;
  top: 9px;
  left: 9px;
}
.show-case-preview_preview .sum-input_focused .sum-input__label,
.show-case-preview_preview .textarea-with-label-animation_focused .textarea-with-label-animation__label,
.show-case-preview_preview .input-with-label-animation_focused .input-with-label-animation__label {
  top: 4px;
  font-size: 3.8px;
}
.show-case-preview_preview .standard-button {
  height: 29px;
  font-size: 7.6px;
  border-radius: 3.8px;
}
.show-case-preview__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  height: 30px;
  justify-content: flex-start;
  transform: rotate(180deg);
  flex-direction: row-reverse;
  gap: 10px;
}
.show-case-preview__back-button-wrapper {
  height: 30px;
}
.show-case-preview__back-button-text {
  transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
.show-case-preview__top {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
  padding-top: 125px;
}
.show-case-preview__top_logged-in {
  padding-top: 40px;
}
.show-case-preview_preview .show-case-preview__top {
  padding-top: 64px;
  padding-bottom: 37px;
  min-height: 160px;
}
.show-case-preview__top-logo {
  position: absolute;
  top: 23px;
  left: 23px;
  width: 61.8px;
  height: 21.2px;
}
.show-case-preview__top-burger {
  position: absolute;
  top: 31px;
  right: 23px;
  width: 14.3px;
  height: 9.1px;
  z-index: 20;
}
.show-case-preview__top-burger rect {
  fill: white;
}
.show-case-preview__top-container {
  position: relative;
  z-index: 20;
}
.show-case-preview_preview .show-case-preview__top-container {
  padding-left: 57px;
  padding-right: 57px;
}
.show-case-preview__title-wrap {
  margin-bottom: 40px;
  padding-top: 20px;
}
.show-case-preview__pre-title {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 15px;
}
.show-case-preview_preview .show-case-preview__pre-title {
  font-size: 6.7px;
  margin-bottom: 5px;
}
.show-case-preview__title {
  font-size: 40px;
  color: white;
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 20px;
  max-width: 50%;
  word-break: break-word;
}
.show-case-preview_preview .show-case-preview__title {
  font-size: 19px;
  margin-bottom: 9px;
}
.show-case-preview__person-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 70%;
  position: relative;
  z-index: 20;
  margin-bottom: 30px;
}
.show-case-preview__person-info-block {
  margin-right: 40px;
  margin-bottom: 20px;
}
.show-case-preview_preview .show-case-preview__person-info-block {
  margin-right: 18px;
  margin-bottom: 10px;
}
.show-case-preview__person-info-block:last-child {
  margin-right: 0;
}
.show-case-preview__person-info-block-title {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 12px;
}
.show-case-preview_preview .show-case-preview__person-info-block-title {
  font-size: 6.7px;
  margin-bottom: 6px;
}
.show-case-preview__person-info-block-text {
  font-size: 18px;
  color: white;
  font-family: "Circe-Regular", sans-serif;
  text-transform: lowercase;
}
.show-case-preview__person-info-block-text_name {
  text-transform: capitalize;
}
.show-case-preview_preview .show-case-preview__person-info-block-text {
  font-size: 8.6px;
}
.show-case-preview__top-bg {
  width: 773px;
  height: 943px;
  position: absolute;
  right: -275px;
  bottom: 0;
  z-index: 10;
}
.show-case-preview_preview .show-case-preview__top-bg {
  width: 341px;
  height: 390px;
  margin-top: -261px;
  right: -115px;
}
.show-case-preview__content {
  background: white;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 400px;
  flex-shrink: 0;
}
.show-case-preview_preview .show-case-preview__content {
  min-height: 220px;
  padding-top: 19px;
}
.show-case-preview__content-container {
  display: flex;
  align-items: flex-start;
}
.show-case-preview_preview .show-case-preview__content-container {
  padding-left: 57px;
  padding-right: 57px;
}
.show-case-preview__form {
  width: 400px;
  min-width: 400px;
  margin-right: 100px;
}
.show-case-preview_preview .show-case-preview__form {
  width: 191px;
  min-width: 191px;
  margin-right: 48px;
}
.show-case-preview__balloon {
  filter: drop-shadow(0 4px 27px #e8ecf2);
  background: white;
  background-size: cover;
  padding: 24px 39px;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  position: relative;
}
.show-case-preview_preview .show-case-preview__balloon {
  max-width: 179px;
  padding: 9px 9px 9px 19px;
  border-radius: 10px;
}
.show-case-preview_preview .show-case-preview__balloon:after {
  bottom: -13px;
  left: 12px;
  width: 19px;
  height: 13px;
}
.show-case-preview__balloon-triangle {
  position: absolute;
  bottom: -23px;
  left: 22px;
  width: 29px;
  height: 23px;
}
.show-case-preview_preview .show-case-preview__balloon-triangle {
  bottom: -13px;
  left: 12px;
  width: 19px;
  height: 13px;
}
.show-case-preview__balloon-text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.show-case-preview_preview .show-case-preview__balloon-text {
  font-size: 7.6px;
}
.show-case-preview__payment-ways {
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-case-preview__payment-ways-item_visa {
  width: 45px;
  height: 20px;
  margin-right: 30px;
}
.show-case-preview__payment-ways-item_msc {
  width: 31px;
  height: 19px;
  margin-right: 24px;
}
.show-case-preview__payment-ways-item_mir {
  width: 46px;
  height: 19px;
  margin-right: 19px;
}
.show-case-preview__payment-ways-item_pci {
  width: 44px;
  height: 18px;
}
.show-case-preview__payment {
  max-width: 400px;
  width: 100%;
}
.show-case-preview__payment_with-check {
  margin-right: 100px;
}
.show-case-preview__payment-card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 12px;
  padding-bottom: 30px;
}
.show-case-preview__payment-card_success {
  margin-bottom: 20px;
}
.show-case-preview__payment-fail-reason {
  margin-bottom: 20px;
  text-align: center;
  margin-top: 16px;
}
.show-case-preview__payment-fail-reason-key {
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.show-case-preview__payment-fail-reason-value {
  font-size: 14px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.show-case-preview__payment-no-check-note {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
}
.show-case-preview__payment-no-check-note-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.show-case-preview__payment-no-check-note-text {
  font-size: 14px;
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  margin-top: 3px;
}
.show-case-preview__payment-button-list {
  display: flex;
}
.show-case-preview__payment-button {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.show-case-preview__payment-button:last-child {
  margin-right: 0;
}
.show-case-preview__payment-button_wide {
  width: 100%;
}
.show-case-preview__check {
  max-width: 291px;
  width: 100%;
}
.show-case-preview__blocked-user {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.show-case-preview__blocked-user-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

@media screen and (max-width: 1194px) {
  .show-case-preview {
    width: 100%;
    margin-top: -90px;
  }
  .show-case-preview_logged-in {
    margin-top: 0;
  }
  .show-case-preview_preview {
    margin-top: 0;
  }
  .show-case-preview_preview .show-case-preview__top-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .show-case-preview_preview .show-case-preview__content-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 980px) {
  .show-case-preview {
    margin-top: -90px;
  }
  .show-case-preview__top {
    padding-top: 130px;
  }
  .show-case-preview__back-button {
    top: -30px;
  }
  .show-case-preview__content-container {
    flex-direction: column-reverse;
  }
  .show-case-preview__content-container_success-payment {
    flex-direction: row;
  }
  .show-case-preview__top-bg {
    width: 333px;
    height: 332px;
    right: -30px;
    top: auto;
    bottom: 0;
    margin-top: 0;
  }
  .show-case-preview_preview .show-case-preview__top-bg {
    width: 341px;
    height: 390px;
    right: -115px;
  }
  .show-case-preview__balloon {
    margin-bottom: 40px;
  }
  .show-case-preview__person-info {
    display: block;
    width: 100%;
  }
  .show-case-preview__person-info-block {
    width: 100%;
  }
  .show-case-preview__form {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .show-case-preview_preview .show-case-preview__form {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .show-case-preview__payment_with-check {
    margin-right: 50px;
  }
}
@media screen and (max-width: 800px) {
  .show-case-preview {
    margin-top: -70px;
  }
  .show-case-preview__payment_with-check {
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .show-case-preview__top {
    padding-top: 100px;
    padding-bottom: 20px;
  }
  .show-case-preview__top-bg {
    width: 243px;
    height: 282px;
  }
  .show-case-preview__pre-title {
    margin-bottom: 10px;
  }
  .show-case-preview__title-wrap {
    margin-bottom: 24px;
  }
  .show-case-preview__title {
    font-size: 28px;
    max-width: 90%;
    line-height: 1.2;
  }
  .show-case-preview__balloon {
    padding: 18px 19px 18px 19px;
    margin-bottom: 58px;
  }
  .show-case-preview__person-info-block-text {
    font-size: 16px;
  }
  .show-case-preview__payment-button-list {
    flex-direction: column-reverse;
  }
  .show-case-preview__payment-button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .show-case-preview__payment-button:first-child {
    margin-bottom: 0;
  }
}