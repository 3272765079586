@charset "UTF-8";
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-hold-widget {
  padding-left: 110px;
}
.tax-hold-widget__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 43px;
  margin-top: 16px;
}
.tax-hold-widget__content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.tax-hold-widget__head {
  display: flex;
  align-items: center;
  gap: 22px;
}
.tax-hold-widget__head-block {
  box-shadow: 0px 4px 27px 0px rgb(232, 236, 242);
  width: 290px;
  height: 120px;
  display: flex;
  gap: 9px;
  flex-direction: column;
  padding: 20px;
  font-family: "Circe-Regular", sans-serif;
}
.tax-hold-widget__head-block-desc {
  color: var(--grey-blue);
  font-size: 14px;
}
.tax-hold-widget__head-block-value {
  color: var(--dark-slate-blue);
  font-size: 36px;
  line-height: 48px;
  display: flex;
}
.tax-hold-widget__head-block-value-rubles {
  font-family: "Circe-Bold", sans-serif;
}
.tax-hold-widget__head-block-value-penny {
  font-family: "Circe-Regular", sans-serif;
}
.tax-hold-widget__head-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-slate-blue);
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition-property: borderColor;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.tax-hold-widget__content-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-slate-blue);
}
.tax-hold-widget__table {
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0px 16px 60px 0px rgba(0, 22, 98, 0.0705882353);
  border-radius: 12px;
  margin-top: 20px;
  padding-bottom: 10px;
  background: var(--white);
}
.tax-hold-widget__table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  transition: all 0.15s ease-in-out;
  padding: 10px 20px;
  gap: 20px;
  background: var(--white);
}
.tax-hold-widget__table-row-date {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--light-grey-blue);
  width: 130px;
}
.tax-hold-widget__table-row-info {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--dark-slate-blue);
  width: 100%;
}
.tax-hold-widget__table-row-hold {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--light-grey-blue);
  width: 130px;
}
.tax-hold-widget__table-row-sum {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--dark-slate-blue);
  width: 180px;
}
.tax-hold-widget__table-row.head {
  color: var(--light-grey-blue);
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--pale-grey);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.tax-hold-widget__table-row.head div {
  color: var(--light-grey-blue);
}
.tax-hold-widget__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  gap: 20px;
}
.tax-hold-widget__empty-icon {
  height: 80px;
  width: 80px;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.0705882353);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.tax-hold-widget__empty-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #b0b5c4;
  text-align: center;
  max-width: 390px;
  padding: 0 20px;
}

@media screen and (max-width: 1100px) {
  .tax-hold-widget {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .tax-hold-widget {
    padding-top: 10px;
  }
  .tax-hold-widget__head {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .tax-hold-widget__head-block {
    width: 100%;
  }
  .tax-hold-widget__table-row {
    flex-flow: row wrap;
    gap: 6px;
    border-bottom: 1px solid var(--pale-grey);
  }
  .tax-hold-widget__table-row-hold, .tax-hold-widget__table-row-sum {
    justify-content: flex-end;
    width: 100%;
    color: var(--dark-slate-blue);
  }
  .tax-hold-widget__table-row-hold:before {
    color: var(--light-grey-blue);
    display: contents;
    content: "Удержано: ";
  }
  .tax-hold-widget__table-row-sum:before {
    color: var(--light-grey-blue);
    display: contents;
    content: "Сумма выплаты: ";
  }
  .tax-hold-widget__table-row.head {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .tax-hold-widget {
    padding-bottom: 69px;
  }
}
@media screen and (max-width: 710px) {
  .tax-hold-widget {
    padding-bottom: 69px;
  }
}