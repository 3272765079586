.show-case {
  display: flex;
  flex-grow: 200;

  &__loader {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    //display: flex;
    //flex-grow: 200;
    //padding: 50px 50px 100px 50px;
    //max-width: 1300px;
    //box-sizing: border-box;
    //width: 100%;
    //margin-left: auto;
    //margin-right: auto;
  }
}
