.money-transfer-card-header-umoney {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 34px 26px 31px;
  border-radius: 12px 12px 0 0;
  background-color: #9f48fb;
}
.money-transfer-card-header-umoney__logo {
  width: 120px;
  height: 25px;
}
.money-transfer-card-header-umoney__wallet {
  position: absolute;
  top: 17px;
  right: 23px;
  width: 68px;
  height: 78px;
}