@import 'static/styles/_mixins.scss';

.devWidget {
  padding-top: 60px;
  padding-bottom: 60px;
}

.devWidgetItem {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  font-size: 14px;
  margin-right: 40px;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  width: 170px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
