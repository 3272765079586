.lk {
  position: relative;
  z-index: 10;
  flex-grow: 100;
}
.lk_menu-open {
  z-index: 10;
}
.lk_low-zindex {
  z-index: 10;
}
.lk__container {
  max-width: 1300px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 600px) {
  .lk__container {
    padding-left: 20px;
    padding-right: 20px;
  }
}