.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.get-sz-inn-input {
  position: relative;
}
.get-sz-inn-input__input {
  height: 60px;
  border-radius: 30px;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
}
.get-sz-inn-input__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input::-ms-clear {
  display: none;
}
.get-sz-inn-input__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.get-sz-inn-input_success .get-sz-inn-input__input {
  color: var(--turquoise-blue);
  -webkit-text-fill-color: var(--turquoise-blue);
  font-size: 18px;
}
.get-sz-inn-input_fail .get-sz-inn-input__input {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  -webkit-text-fill-color: var(--reddish-pink);
  font-size: 18px;
  background: white;
}
.get-sz-inn-input__input:disabled {
  opacity: 1;
}
.get-sz-inn-input__edit {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  padding: 0;
  margin: -10px 0 0 0;
}
.get-sz-inn-input_fail .get-sz-inn-input__edit {
  right: 53px;
}
.get-sz-inn-input__lock {
  right: 22px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
}
.get-sz-inn-input_fail .get-sz-inn-input__lock path {
  fill: var(--reddish-pink);
}
.get-sz-inn-input__check {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -6px;
}
.get-sz-inn-input__check path {
  stroke: var(--dark-slate-blue);
}
.get-sz-inn-input__loader-wrap {
  position: absolute;
  right: -70px;
  top: 0;
}
.get-sz-inn-input__loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 15px;
  margin-top: -10px;
  margin-left: -6px;
}
.get-sz-inn-input__loader-icon path {
  fill: white;
}
@media screen and (max-width: 980px) {
  .get-sz-inn-input__input {
    box-shadow: 0 4px 27px 0 #e8ecf2;
  }
  .get-sz-inn-input__input:disabled {
    background-color: rgba(228, 232, 238, 0.2);
    box-shadow: none;
    border: solid 1px #9da6ba;
  }
  .get-sz-inn-input_success .get-sz-inn-input__input:disabled {
    border: solid 1px var(--turquoise-blue);
    background: white;
  }
  .get-sz-inn-input__edit {
    right: 50px;
  }
  .get-sz-inn-input_fail .get-sz-inn-input__edit {
    right: 20px;
  }
  .get-sz-inn-input__loader-wrap {
    right: 20px;
    top: 22px;
  }
}