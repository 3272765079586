.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.add-new-card {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.add-new-card__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.add-new-card__header {
  margin-bottom: 50px;
}
.add-new-card__header-logo-wrap {
  text-decoration: none;
  width: 38px;
  height: 33px;
  max-width: 38px;
  max-height: 33px;
}
.add-new-card__header-logo {
  display: none;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.add-new-card__title-wrap {
  margin-bottom: 39px;
}
.add-new-card__title {
  font-size: 36px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 10px;
}
.add-new-card__subtitle {
  font-size: 18px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
  padding-left: 50px;
  padding-right: 50px;
}
.add-new-card__content-inner {
  max-width: 400px;
}
.add-new-card__card {
  margin-bottom: 20px;
}
.add-new-card__input {
  margin-bottom: 20px;
}
.add-new-card__default-block {
  margin-bottom: 30px;
  display: flex;
}
.add-new-card__checkbox-wrap {
  display: flex;
  align-items: center;
}
.add-new-card__checkbox-itself {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 54px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #e4e8ee;
  background: var(--pale-grey);
  margin-right: 12px;
  position: relative;
  transition: background, border-color 0.3s ease-in-out;
}
.add-new-card__checkbox-itself:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 50%;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--bluey-grey);
  transition: background, left 0.3s ease-in-out;
}
.add-new-card__checkbox-itself[aria-checked=true] {
  border-color: rgba(29, 46, 105, 0.5);
  background: transparent;
}
.add-new-card__checkbox-itself[aria-checked=true]:after {
  background: rgba(29, 46, 105, 0.5);
  left: 33px;
}
.add-new-card__checkbox-title {
  font-size: 18px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.add-new-card__buttons {
  display: flex;
  width: 100%;
}
.add-new-card__buttons-item {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.add-new-card__buttons-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 980px) {
  .add-new-card__container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .add-new-card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .add-new-card__close {
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .add-new-card__title {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 20px;
  }
  .add-new-card__content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .add-new-card__title {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 28px;
  }
  .add-new-card__subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }
  .add-new-card__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .add-new-card__buttons {
    flex-direction: column-reverse;
  }
  .add-new-card__buttons-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .add-new-card__buttons-item:first-child {
    margin-bottom: 0;
  }
}