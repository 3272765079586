.lk {
  position: relative;
  z-index: 10;
  flex-grow: 100;

  &_menu-open {
    z-index: 10;
  }

  &_low-zindex {
    z-index: 10;
  }

  &__container {
    max-width: 1200px + 100px;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 600px) {
  .lk {
    &__container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
