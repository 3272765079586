.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-success-header {
  display: flex;
  margin-bottom: 10px;
  padding: 32px 20px 0 20px;
}
.payment-success-header__success-icon-wrap {
  width: 44px;
  min-width: 44px;
  height: 44px;
  margin-right: 27px;
  position: relative;
}
.payment-success-header__success-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 10px;
  margin-top: -5px;
  margin-left: -7px;
}
.payment-success-header__success-icon path {
  stroke: var(--turquoise-blue);
}
.payment-success-header__in-process-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--turquoise-blue);
  text-align: center;
  line-height: 44px;
}
.payment-success-header__success-text-title {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
}
.payment-success-header__success-text-result {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
}