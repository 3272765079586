.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment-card__top {
  padding-bottom: 13px;
  margin-bottom: 40px;
  position: relative;
}
.tax-payment-card__top-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--bluey-grey);
}
.tax-payment-card__top-progress-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  transition: all 0.3s ease;
}
.tax-payment-card__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 28px;
  margin-bottom: 20px;
}
.tax-payment-card__subtitle {
  font-size: 18px;
  line-height: 1.33;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.tax-payment-card__card {
  border-radius: 12px;
  margin-bottom: 20px;
}
.tax-payment-card__card_with-shadow {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.tax-payment-card__header {
  height: 92px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-image: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(90, 200, 250) 100%);
  padding: 36px 36px 20px 36px;
  position: relative;
}
.tax-payment-card__header:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
.tax-payment-card__header-logo {
  object-fit: contain;
  width: 110px;
  height: 38px;
  position: relative;
  z-index: 20;
}
.tax-payment-card__header-bg {
  width: 284px;
  position: absolute;
  right: -60px;
  top: -176px;
  z-index: 20;
}
.tax-payment-card__success {
  display: flex;
  margin-bottom: 10px;
  padding: 32px 20px 0 20px;
}
.tax-payment-card__success-icon-wrap {
  width: 44px;
  min-width: 44px;
  height: 44px;
  margin-right: 27px;
  position: relative;
}
.tax-payment-card__success-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 10px;
  margin-top: -5px;
  margin-left: -7px;
}
.tax-payment-card__success-icon path {
  stroke: var(--turquoise-blue);
}
.tax-payment-card__in-process-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--turquoise-blue);
  text-align: center;
  line-height: 44px;
}
.tax-payment-card__success-text-title {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
}
.tax-payment-card__success-text-result {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
}
.tax-payment-card__iframe-wrap {
  height: 370px;
  position: relative;
}
.tax-payment-card__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.tax-payment-card__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 980px) {
  .tax-payment-card__responsive-header {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
  }
  .tax-payment-card__title {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment-card__subtitle {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment-card__card {
    max-width: 420px;
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment-card__responsive-header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-card__title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-card__subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-card__card {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 330px) {
  .tax-payment-card__card {
    max-width: calc(100% + 30px);
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }
}