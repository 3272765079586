.tip {
  position: absolute;
  pointer-events: none;
}
.tip::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #616a88;
}
.tipToTop {
  bottom: calc(100% + 8px + 8px);
}
.tipToTop::before {
  top: 100%;
  left: calc(50% - 8px);
}