@import 'static/styles/_mixins.scss';
@import 'static/styles/datepicker-override.scss';
@import '~react-datepicker/dist/react-datepicker.css';

.auth-status-container {
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 200;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.auth-status {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  &_choose-way {
    min-height: 800px;
    padding-bottom: 57px;
  }

  &__nav {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__nav-back {
    @include reset-button-styles();
    transform: rotate(180deg);
  }

  &__nav-back-icon {
    height: 30px;
    width: 15px;
  }

  &__nav-close {
    align-items: center;
    background: white;
    border-radius: 50%;
    box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
    display: flex;
    height: 48px;
    justify-content: center;
    text-decoration: none;
    width: 48px;
    z-index: 20;
  }

  &__header-close {
    align-items: center;
    background: white;
    border-radius: 50%;
    box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
    display: flex;
    height: 48px;
    justify-content: center;
    position: absolute;
    right: 56px;
    text-decoration: none;
    top: 48px;
    width: 48px;
    z-index: 20;
  }

  &__header-close-icon {
    path {
      transition: all 0.3s ease;
    }

    .auth-status__header-close:hover & {
      path {
        stroke: var(--reddish-pink);
      }
    }
  }

  &__header {
    background: linear-gradient(to right, rgba(108, 92, 231, 1) 0%, rgba(90, 200, 250, 1) 100%);
    min-height: 320px;
    overflow: hidden;
    padding-bottom: 155px;
    padding-top: 102px;
    position: relative;
    will-change: transform;

    .auth-status_enter-passport-data & {
      padding-bottom: 65px;
      padding-top: 62px;
    }

    .auth-status_choose-way & {
      min-height: 400px;
    }

    .auth-status_success & {
      height: 320px;
      padding: 0;
    }

    .auth-status_waiting & {
      height: 320px;
      padding: 0;
    }

    .auth-status_fail & {
      height: 320px;
      padding: 0;
    }
  }

  &__header-container {
    position: relative;
    z-index: 20;
  }

  &__header-bg {
    height: 943px;
    margin-top: -534px;
    position: absolute;
    right: -125px;
    top: 50%;
    width: 773px;
    z-index: 10;

    .auth-status_waiting & {
      margin-top: -464px;
    }

    .auth-status_success & {
      margin-top: -464px;
    }

    .auth-status_fail & {
      margin-top: -464px;
    }
  }

  &__header-content {
    .auth-status_choose-way & {
      max-width: 440px;
    }

    .auth-status_enter-passport-data & {
      max-width: 440px;
    }

    .auth-status_success & {
      max-width: 440px;
    }

    .auth-status_gosuslugi-fail & {
      max-width: 404px;
    }

    .auth-status_already-registered & {
      max-width: 424px;
    }
  }

  &__header-title {
    @include bold();
    color: white;
    font-size: 36px;
    line-height: 1.33;
    margin-bottom: 10px;
  }

  &__header-desc {
    @include rubik-regular();
    color: white;
    font-size: 16px;
    line-height: 1.5;
    position: relative;

    .auth-status_enter-passport-data & {
      margin-bottom: 30px;
    }

    .auth-status_success & {
      margin-bottom: 50px;
    }

    .profile__card & {
      color: var(--grey-blue);
      line-height: 1.75;
      margin-bottom: 56px;
      text-align: center;
    }
  }

  &__header-desc-warning {
    height: 20px;
    left: -30px;
    position: absolute;
    top: 3px;
    width: 20px;
  }

  &__passport-scan-btn {
    @include reset-button-styles;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    box-shadow: 0 4px 27px 0 rgba(232, 236, 242, 0.1);
    display: flex;
    height: 60px;
    justify-content: center;
    padding: 0 20px;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }

    &_upload {
      border: 1px dashed white;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__passport-scan-btn-icon {
    margin-right: 10px;
  }

  &__passport-scan-btn-text {
    @include bold();
    color: white;
    font-size: 16px;
  }

  &__header-btn-wrap {
    display: flex;
  }

  &__header-btn {
    @include reset-button-styles;
    @include regular();
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    box-shadow: 0 4px 27px 0 rgba(232, 236, 242, 0.1);
    color: white;
    display: flex;

    font-size: 16px;
    height: 60px;
    justify-content: center;
    padding: 0 20px;
    text-decoration: none;
    transition: all 0.3s ease;
    width: 265px;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  &__header-logo-wrap {
    left: calc(50% + 75px);
    margin-top: -70px;
    position: absolute;
    top: 50%;
    z-index: 20;
  }

  &__header-logo-container {
    border: 2px solid white;
    border-radius: 50%;
    box-shadow: 0 2px 32px 0 var(--dusk-blue);
    height: 140px;
    overflow: hidden;
    width: 140px;
  }

  &__header-logo {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__header-logo-status {
    align-items: center;
    background-color: var(--deep-sky-blue);
    border-radius: 50%;
    display: flex;
    height: 50px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 50px;
  }

  &__header-logo-status-icon {
    height: 28px;
    width: 30px;
  }

  &__shape {
    position: absolute;
    z-index: 20;

    &_1 {
      bottom: 26px;
      height: 52px;
      left: calc(50% + 6px);
      width: 51px;
    }

    &_2 {
      height: 20px;
      left: calc(70% + 18px);
      top: 120px;
      width: 20px;
    }

    &_3 {
      height: 89px;
      left: calc(43% + 9px);
      top: 43px;
      width: 76px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 20;
    justify-content: space-between;
    position: relative;

    .auth-status_enter-passport-data & {
      flex-direction: row;
      flex-shrink: 0;
      justify-content: flex-start;
      min-height: 483px;
      padding-bottom: 160px;
      padding-top: 60px;
    }

    .auth-status_choose-way & {
      flex-shrink: 0;
      min-height: 343px;
    }
  }

  &__ways-list {
    display: flex;
    position: relative;
    z-index: 30;

    .auth-status_choose-way & {
      margin-bottom: 120px;
      margin-top: -90px;
    }

    .auth-status_gosuslugi-fail & {
      margin-bottom: 120px;
      margin-top: -90px;
    }

    .auth-status_already-registered & {
      margin-bottom: 120px;
      margin-top: -90px;
    }
  }

  &__ways-item {
    @include reset-button-styles;
    align-items: flex-start;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    height: 200px;

    justify-content: flex-end;
    margin-right: 30px;
    padding: 30px 30px 45px;
    position: relative;
    transition: all 0.3s ease;
    width: 380px;

    &:hover {
      box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__ways-item-title {
    @include bold();
    color: var(--dark-slate-blue);
    font-size: 22px;
    margin-bottom: 10px;
    text-align: left;
  }

  &__ways-item-desc {
    @include regular();
    color: var(--dark-slate-blue);
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }

  &__ways-item-img {
    position: absolute;

    &_gosuslugi {
      right: 40px;
      top: 40px;
    }

    &_pen {
      height: 30px;
      right: 30px;
      top: 30px;
      width: 24px;
    }
  }

  &__form {
    margin-right: 60px;
    max-width: 650px;
    width: 100%;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  &__btn-wrap {
    display: flex;
  }

  &__btn {
    @include reset-button-styles;
    @include bold();
    align-items: center;
    background: var(--dusk-blue);
    border-radius: 8px;
    color: white;

    display: flex;

    font-size: 18px;
    height: 60px;
    justify-content: center;
    width: 190px;

    &_disabled {
      background-color: var(--pale-grey);
      color: var(--bluey-grey);
      cursor: default;
    }
  }

  &__loader-wrap {
    margin-left: 20px;
    position: relative;
  }

  &__password-secure-lock {
    left: 50%;
    margin-left: -6px;
    margin-top: -8px;
    position: absolute;
    top: 50%;

    &_grey {
      path {
        fill: var(--bluey-grey);
      }
    }

    &_green {
      path {
        fill: var(--turquoise-blue);
      }
    }

    &_red {
      path {
        fill: var(--reddish-pink);
      }
    }
  }

  &__personal-info {
    margin-bottom: 19px;
    position: relative;
    z-index: 30;
  }

  &__personal-info-tabs {
    display: flex;
  }

  &__personal-info-tabs-item {
    @include reset-button-styles;
    @include rubik-medium();
    color: var(--grey-blue);
    cursor: default;
    font-size: 16px;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    &_active {
      color: var(--dark-slate-blue);
    }
  }

  &__personal-info-bar {
    background: rgba(176, 181, 196, 0.2);
    bottom: -6px;
    height: 2px;
    left: 0;
    position: absolute;
  }

  &__personal-info-bar-active {
    background-color: var(--dusk-blue);
    border-radius: 1px;
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease;
  }

  &__personal-info-content {
    position: relative;
    z-index: 30;
  }

  &__hint-block {
    position: relative;
    z-index: 10;

    .auth-status_enter-passport-data & {
      padding-top: 40px;
    }
  }

  &__hint-title {
    @include rubik-regular();
    color: var(--bluey-grey);
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__hint-item-wrap {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }

  &__hint-item {
    @include reset-button-styles;
    @include rubik-medium();
    @include std-transition();
    border-bottom: 2px dotted var(--dark-slate-blue);
    color: var(--dark-slate-blue);
    font-size: 16px;
    padding-bottom: 4px;
    text-decoration: none;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  &__hint-item-img {
    height: 14px;
    margin-left: 4px;
    vertical-align: middle;
    width: 86px;
  }

  &__success {
    .auth-status-result__desc {
      margin-bottom: 60px;
    }
  }

  &__fail {
    .auth-status-result__desc {
      margin-bottom: 60px;
    }

    &_another-inn {
      &.auth-status-result {
        max-width: 677px + 40px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .auth-status {
    &__content {
      .auth-status_enter-passport-data & {
        flex-direction: column;
        min-height: 605px;
      }
    }

    &__form {
      .auth-status_enter-passport-data & {
        margin-bottom: 50px;
        margin-right: 0;
      }
    }

    &__hint-block {
      .auth-status_enter-passport-data & {
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .auth-status {
    &_choose-way {
      padding-bottom: 57px;
    }

    &__nav {
      margin-bottom: 30px;
    }

    &__header {
      .auth-status_choose-way & {
        min-height: auto;
        padding-bottom: 115px;
        padding-top: 70px;
      }

      .auth-status_enter-passport-data & {
        padding-top: 32px;
      }

      .auth-status_gosuslugi-fail & {
        padding-bottom: 94px;
        padding-top: 68px;
      }

      .auth-status_already-registered & {
        padding-bottom: 94px;
        padding-top: 68px;
      }
    }

    &__header-title {
      .auth-status_gosuslugi-fail & {
        margin-bottom: 20px;
      }

      .auth-status_already-registered & {
        margin-bottom: 20px;
      }
    }

    &__header-desc {
      .auth-status_gosuslugi-fail & {
        max-width: 265px;
      }

      .auth-status_already-registered & {
        max-width: 265px;
      }
    }

    &__header-bg {
      height: 661px;
      margin-top: -320px;
      width: 513px;
    }

    &__content {
      .auth-status_choose-way & {
        padding-top: 30px;
      }

      .auth-status_enter-passport-data & {
        min-height: 770px;
      }
    }

    &__ways-list {
      display: block;
      margin-bottom: 60px;

      .auth-status_choose-way & {
        margin-top: 0;
      }

      .auth-status_gosuslugi-fail & {
        margin-top: 94px;
      }

      .auth-status_already-registered & {
        margin-top: 94px;
      }
    }

    &__ways-item {
      height: 150px;
      justify-content: flex-start;
      margin-bottom: 10px;
      padding: 20px 30px 15px 26px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__ways-item-title {
      margin-bottom: 5px;
    }

    &__ways-item-desc {
      max-width: 80%;
    }

    &__ways-item-img {
      position: absolute;

      &_gosuslugi {
        height: 40px;
        margin-top: -20px;
        right: 20px;
        top: 50%;
        width: 36px;
      }

      &_pen {
        height: 23px;
        margin-top: -11px;
        right: 22px;
        top: 50%;
        width: 23px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .auth-status {
    &__content {
      min-height: 746px;

      .auth-status_choose-way & {
        min-height: 460px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .auth-status {
    &__header {
      .auth-status_enter-passport-data & {
        min-height: 304px;
      }

      .auth-status_success & {
        height: 200px;
        min-height: 200px;
      }

      .auth-status_waiting & {
        height: 200px;
        min-height: 200px;
      }

      .auth-status_fail & {
        height: 200px;
        min-height: 200px;
      }
    }

    &__header-content {
      .auth-status_choose-way & {
        max-width: 280px;
      }

      .auth-status_success & {
        max-width: 280px;
      }
    }

    &__header-title {
      font-size: 28px;
    }

    &__header-close {
      //left: 16px;
      right: 20px;
      top: 20px;
    }

    &__header-bg {
      height: 276px;
      margin-top: 0;
      right: -151px;
      top: 33px;
      width: 271px;

      .auth-status_waiting & {
        margin-top: 0;
        right: -142px;
        top: 22px;
      }

      .auth-status_success & {
        margin-top: 0;
        right: -142px;
        top: 22px;
      }

      .auth-status_fail & {
        margin-top: 0;
        right: -142px;
        top: 22px;
      }
    }

    &__ways-item {
      width: 100%;
      height: auto;
    }

    &__success {
      .auth-status-result__desc {
        margin-bottom: 40px;
      }
    }

    &__fail {
      .auth-status-result__desc {
        margin-bottom: 40px;
      }
    }
  }
}
