.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.service-card-content {
  position: relative;
  height: 100%;
  color: white;
  max-width: 70%;
}
.service-card-content_white-bg {
  color: var(--dark-slate-blue);
}
.service_big .service-card-content {
  max-width: 100%;
}
.service_special .service-card-content {
  max-width: 100%;
}
.profile-settings-sz-tab__service .service-card-content {
  max-width: 80%;
}
.service_company .service-card-content {
  max-width: 100%;
}
.service-card-content__title {
  font-size: 22px;
  color: inherit;
  font-family: "Circe-Bold", sans-serif;
  text-align: left;
  margin-bottom: 11px;
}
.service-card-content__desc {
  font-size: 16px;
  color: inherit;
  line-height: 1.5;
  text-align: left;
  font-family: "Circe-Regular", sans-serif;
  min-height: 48px;
}

@media screen and (max-width: 1194px) {
  .service-card-content {
    max-width: 75%;
  }
  .service_big .service-card-content {
    max-width: 75%;
  }
}
@media screen and (max-width: 600px) {
  .service_special .service-card-content {
    max-width: 80%;
  }
  .service-card-content__title {
    font-size: 18px;
  }
}