.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.operations-tab_calendar-open {
  min-height: 1000px;
}
.operations-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 21px;
}
.operations-tab__filters {
  margin-bottom: 22px;
}
@media (max-width: 480px) {
  .operations-tab__filters {
    box-shadow: initial;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.operations-tab__content-wrap {
  position: relative;
  overflow: hidden;
  height: 480px;
}
@media (min-width: 481px) {
  .operations-tab__content-wrap {
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
    background: white;
    border-radius: 12px;
  }
}
.operations-tab__loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.operations-tab__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 3px;
}
.operations-tab__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--pale-grey);
  position: absolute;
}
.operations-tab__no-operations-message {
  padding: 100px 0;
}

@media screen and (max-width: 480px) {
  .operations-tab__list {
    box-shadow: none;
    border-radius: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
  .operations-tab__no-operations-message {
    padding: 50px 0;
  }
}