.cards-floating-details {
  bottom: 100px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 90px;

  &_non-visible {
    visibility: hidden;
  }

  &__shape {
    pointer-events: none;
    position: absolute;
    z-index: 60;

    &_1 {
      height: 40px;
      width: 40px;
    }

    &_2 {
      height: 94px;
      width: 94px;
    }

    &_3 {
      height: 30px;
      width: 30px;
    }

    &_4 {
      height: 20px;
      width: 20px;
    }

    &_5 {
      height: 90px;
      width: 79px;
    }
  }

  &__laptop {
    height: 394px;
    position: absolute;
    width: 417px;
    z-index: 61;
    //&_small {
    //  width: 297px;
    //  height: 294px;
    //}
  }
}

@media screen and (max-width: 875px) {
  .cards-floating-details {
    &__shape {
      &_3 {
        height: 24px;
        width: 24px;
      }
    }
  }
}
