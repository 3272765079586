.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.manual-page {
  background: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 200;
  width: 100%;
  position: relative;
}
.manual-page__top-wrap {
  position: relative;
  z-index: 5;
}
.manual-page__container {
  padding: 0 50px 0 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.manual-page_start .manual-page__container {
  min-height: 500px;
}
.manual-page_check-citizenship .manual-page__container {
  min-height: 500px;
}
.manual-page__header-wrap {
  margin-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.manual-page__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.manual-page_start .manual-page__header-wrap {
  margin-bottom: 205px;
}
.manual-page__header-left {
  display: flex;
  align-items: center;
}
.manual-page__header-logo {
  height: 39px;
}
.manual-page__dropdown-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.manual-page__dropdown-button-text {
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-decoration: underline;
  margin-right: 8px;
}
.manual-page__dropdown-button-caret {
  width: 11px;
  height: 6px;
  fill: #616a88;
}
.manual-page__content-wrap {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 5;
}
.manual-page__details-container {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}
.manual-page__col {
  width: 570px;
  min-width: 570px;
  margin-right: 60px;
  position: relative;
}
.manual-page_web-version .manual-page__col:first-child {
  height: 600px;
}
.manual-page__col:nth-child(2) {
  width: calc(100% - 570px - 60px);
  min-width: calc(100% - 570px - 60px);
  margin-right: 0;
  padding-bottom: 100px;
}
.manual-page_registration .manual-page__col:nth-child(2) {
  height: 554px;
}
.manual-page_my-tax-1 .manual-page__col:nth-child(2) {
  height: 580px;
}
.manual-page_my-tax-lk .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_my-tax-gosuslugi .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_web-lk .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_web-gosuslugi .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_sz-status-confirmation-3 .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_cooperation-1 .manual-page__col:nth-child(2) {
  height: 550px;
}
.manual-page_cooperation-2 .manual-page__col:nth-child(2) {
  height: 550px;
}
.manual-page__return-link {
  margin-bottom: 24px;
}
.manual-page__title {
  font-size: 45px;
  color: var(--dark-slate-blue);
  line-height: 60px;
  font-family: "Circe-Bold", sans-serif;
}
.manual-page_start .manual-page__title {
  margin-bottom: 50px;
}
.manual-page_check-citizenship .manual-page__title {
  margin-bottom: 50px;
}
.manual-page_registration .manual-page__title {
  max-width: 60%;
  line-height: 55px;
}
.manual-page_my-tax-1 .manual-page__title {
  margin-bottom: 29px;
  max-width: 80%;
}
.manual-page_my-tax-passport .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_my-tax-lk .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_my-tax-gosuslugi .manual-page__title {
  white-space: nowrap;
  margin-bottom: 28px;
}
.manual-page_web-lk .manual-page__title {
  margin-bottom: 27px;
}
.manual-page_web-gosuslugi .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_wallet .manual-page__title {
  margin-bottom: 39px;
}
.manual-page_sz-status-confirmation-1 .manual-page__title {
  margin-bottom: 28px;
}
.manual-page_cooperation-1 .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_my-tax-2 .manual-page__text-list {
  margin-bottom: 60px;
  margin-top: 68px;
}
.manual-page_my-tax-passport .manual-page__text-list {
  margin-bottom: 40px;
  max-width: 70%;
}
.manual-page_my-tax-lk .manual-page__text-list {
  max-width: 90%;
  margin-bottom: 40px;
}
.manual-page_my-tax-gosuslugi .manual-page__text-list {
  margin-bottom: 43px;
  max-width: 90%;
}
.manual-page_web-lk .manual-page__text-list {
  margin-bottom: 30px;
  padding-left: 6px;
  max-width: 80%;
}
.manual-page_web-gosuslugi .manual-page__text-list {
  max-width: 80%;
  margin-bottom: 40px;
}
.manual-page_wallet .manual-page__text-list {
  max-width: 75%;
  margin-bottom: 43px;
}
.manual-page_sz-status-confirmation-1 .manual-page__text-list {
  max-width: 80%;
  margin-bottom: 40px;
}
.manual-page_sz-status-confirmation-2 .manual-page__text-list {
  max-width: 78%;
  margin-bottom: 43px;
}
.manual-page_sz-status-confirmation-3 .manual-page__text-list {
  max-width: 78%;
  margin-bottom: 40px;
}
.manual-page_cooperation-2 .manual-page__text-list {
  margin-bottom: 41px;
}
.manual-page__text {
  font-size: 18px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 30px;
}
.manual-page_registration .manual-page__text {
  margin-bottom: 40px;
  margin-top: 60px;
}
.manual-page_my-tax-1 .manual-page__text {
  margin-bottom: 40px;
  max-width: 90%;
}
.manual-page__text-list .manual-page__text {
  margin-bottom: 16px;
}
.manual-page__text-list .manual-page__text:last-child {
  margin-bottom: 0;
}
.manual-page_my-tax-gosuslugi .manual-page__text {
  margin-bottom: 20px;
  max-width: 86%;
}
.manual-page_web-version .manual-page__text {
  padding-top: 66px;
  margin-bottom: 60px;
}
.manual-page_web-lk .manual-page__text {
  color: #9da6ba;
  margin-bottom: 39px;
  max-width: 80%;
  padding-left: 5px;
  line-height: 32px;
}
.manual-page_web-gosuslugi .manual-page__text:first-child {
  margin-bottom: 20px;
}
.manual-page_web-gosuslugi .manual-page__text:last-child {
  color: #9da6ba;
}
.manual-page_sz-status-confirmation-1 .manual-page__text {
  max-width: 80%;
  margin-bottom: 20px;
}
.manual-page_sz-status-confirmation-2 .manual-page__text {
  max-width: 80%;
  margin-bottom: 20px;
}
.manual-page_sz-status-confirmation-3 .manual-page__text {
  margin-bottom: 20px;
}
.manual-page_cooperation-1 .manual-page__text {
  max-width: 80%;
  margin-bottom: 50px;
}
.manual-page_cooperation-2 .manual-page__text {
  max-width: 74%;
  margin-bottom: 33px;
}
.manual-page_cooperation-2 .manual-page__text:last-child {
  margin-bottom: 0;
}
.manual-page__text-link {
  font-size: 18px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 30px;
}
.manual-page__text-link:hover {
  text-decoration: none;
}
.manual-page__button-item {
  width: 320px;
  margin-bottom: 12px;
}
.manual-page__button-item:last-child {
  margin-bottom: 0;
}
.manual-page_my-tax-1 .manual-page__button-item {
  width: 340px;
}
.manual-page__list-item {
  margin-bottom: 16px;
  display: flex;
}
.manual-page__list-item:last-child {
  margin-bottom: 0;
}
.manual-page__list-item-number {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 30px;
  font-family: "Circe-Regular", sans-serif;
  min-width: 39px;
}
.manual-page__list-item-text {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.manual-page__list-item-text b {
  font-family: "Circe-Bold", sans-serif;
}
.manual-page__list-item-text a {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.manual-page__check {
  width: 290px;
  height: 364px;
  position: absolute;
}
.manual-page_start .manual-page__check {
  left: 124px;
  top: 149px;
}
.manual-page_check-citizenship .manual-page__check {
  left: 124px;
  top: 149px;
}
.manual-page_registration .manual-page__check {
  transform: rotate(5deg);
  top: 452px;
  left: -13px;
}
.manual-page__status {
  position: absolute;
}
.manual-page_start .manual-page__status {
  top: 6px;
  left: 271px;
}
.manual-page_check-citizenship .manual-page__status {
  top: 6px;
  left: 271px;
}
.manual-page_registration .manual-page__status {
  transform: rotate(-5deg);
  top: 224px;
  right: 105px;
}
.manual-page__nalog1 {
  position: absolute;
  width: 380px;
  height: 280px;
  transform: rotate(-10deg);
  top: 200px;
  left: -20px;
  z-index: 1;
  box-shadow: -10px 14px 60px rgba(232, 236, 242, 0.6);
}
.manual-page__nalog2 {
  width: 370px;
  height: 105px;
  position: absolute;
  transform: rotate(6deg);
  top: 292px;
  left: 223px;
  z-index: 5;
  box-shadow: -4px 14px 30px #e8ecf2;
}
.manual-page__shape {
  position: absolute;
}
.manual-page__shape_1 {
  width: 20px;
  height: 20px;
}
.manual-page_start .manual-page__shape_1 {
  top: -111px;
  left: 510px;
}
.manual-page_check-citizenship .manual-page__shape_1 {
  top: -111px;
  left: 510px;
}
.manual-page_registration .manual-page__shape_1 {
  top: 75px;
  right: 83px;
}
.manual-page_my-tax-2 .manual-page__shape_1 {
  top: 357px;
  right: 69px;
}
.manual-page_web-version .manual-page__shape_1 {
  top: 341px;
  right: 70px;
}
.manual-page_cooperation-1 .manual-page__shape_1 {
  top: -47px;
  right: 33px;
}
.manual-page__shape_2 {
  width: 30px;
  height: 30px;
}
.manual-page_start .manual-page__shape_2 {
  top: -70px;
  left: 200px;
}
.manual-page_check-citizenship .manual-page__shape_2 {
  top: -70px;
  left: 200px;
}
.manual-page_registration .manual-page__shape_2 {
  top: 445px;
  right: auto;
  left: -50px;
}
.manual-page_my-tax-2 .manual-page__shape_2 {
  top: 537px;
  right: 39px;
}
.manual-page_web-version .manual-page__shape_2 {
  top: 492px;
  right: 40px;
}
.manual-page_cooperation-1 .manual-page__shape_2 {
  top: -37px;
  right: 430px;
}
.manual-page__shape_3 {
  width: 60px;
  height: 60px;
}
.manual-page_start .manual-page__shape_3 {
  top: 340px;
  left: 131px;
}
.manual-page_check-citizenship .manual-page__shape_3 {
  top: 340px;
  left: 131px;
}
.manual-page_registration .manual-page__shape_3 {
  top: 274px;
  right: 378px;
}
.manual-page_my-tax-2 .manual-page__shape_3 {
  top: 583px;
  right: 260px;
  z-index: 10;
}
.manual-page_web-version .manual-page__shape_3 {
  top: 508px;
  right: 262px;
  z-index: 10;
}
.manual-page_cooperation-1 .manual-page__shape_3 {
  bottom: 55px;
  left: -35px;
  z-index: 10;
}
.manual-page__shape_4 {
  width: 30px;
  height: 30px;
}
.manual-page_start .manual-page__shape_4 {
  top: 440px;
  left: -54px;
}
.manual-page_check-citizenship .manual-page__shape_4 {
  top: 440px;
  left: -54px;
}
.manual-page_registration .manual-page__shape_4 {
  top: 417px;
  right: 136px;
}
.manual-page_my-tax-2 .manual-page__shape_4 {
  top: 738px;
  right: 570px;
}
.manual-page_web-version .manual-page__shape_4 {
  top: 628px;
  right: 569px;
}
.manual-page_cooperation-1 .manual-page__shape_4 {
  bottom: -141px;
  right: 746px;
  z-index: 10;
}
.manual-page__ruble {
  position: absolute;
}
.manual-page__ruble_1 {
  width: 80px;
  height: 90px;
}
.manual-page_start .manual-page__ruble_1 {
  left: 530px;
  top: 274px;
}
.manual-page_check-citizenship .manual-page__ruble_1 {
  left: 530px;
  top: 274px;
}
.manual-page_registration .manual-page__ruble_1 {
  right: -10px;
  top: 564px;
  width: 99px;
  height: 99px;
}
.manual-page_my-tax-2 .manual-page__ruble_1 {
  right: 230px;
  top: 314px;
  width: 65px;
  height: 73px;
  transform: rotate(-11deg);
}
.manual-page_web-version .manual-page__ruble_1 {
  right: 199px;
  top: 264px;
  width: 68px;
  height: 75px;
  transform: rotate(-15deg);
}
.manual-page_cooperation-1 .manual-page__ruble_1 {
  top: 48px;
  right: 53px;
  width: 122px;
  height: 142px;
  z-index: 10;
}
.manual-page__ruble_2 {
  width: 96px;
  height: 89px;
}
.manual-page_start .manual-page__ruble_2 {
  left: 47px;
  top: -27px;
}
.manual-page_check-citizenship .manual-page__ruble_2 {
  left: 47px;
  top: -27px;
}
.manual-page_registration .manual-page__ruble_2 {
  right: 276px;
  top: 472px;
}
.manual-page_my-tax-2 .manual-page__ruble_2 {
  right: 617px;
  top: 346px;
  width: 104px;
  height: 93px;
  transform: rotate(-10deg);
}
.manual-page_web-version .manual-page__ruble_2 {
  right: 581px;
  top: 303px;
  width: 106px;
  height: 99px;
  transform: rotate(10deg);
}
.manual-page_cooperation-1 .manual-page__ruble_2 {
  top: 23px;
  right: 560px;
}
.manual-page_registration .manual-page__ruble_3 {
  left: -80px;
  top: 210px;
  width: 80px;
  height: 90px;
}
.manual-page_my-tax-2 .manual-page__ruble_3 {
  top: 747px;
  right: 126px;
  width: 126px;
  height: 142px;
}
.manual-page_web-version .manual-page__ruble_3 {
  right: 133px;
  top: 615px;
}
.manual-page__chat-button-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 50px;
  padding-right: 50px;
}
.manual-page__chat-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: var(--light-grey-blue);
  text-decoration: underline;
  font-family: "Circe-Regular", sans-serif;
}
.manual-page__chat-button:hover {
  text-decoration: none;
}
.manual-page__chat-button-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  transform: rotate(0);
}
.manual-page__chat-button:hover .manual-page__chat-button-image {
  animation: chat-animation 0.2s ease-in-out forwards;
  animation-iteration-count: 3;
}
.manual-page__chat-button-icon {
  width: 21px;
  height: 21px;
}
.manual-page__phone {
  position: absolute;
}
.manual-page__phone_1 {
  top: -56px;
  left: -80px;
  width: 502px;
  height: 690px;
  z-index: 10;
}
.manual-page__phone_2 {
  top: -36px;
  left: 134px;
  width: 430px;
  height: 592px;
  z-index: 5;
}
.manual-page_my-tax-2 .manual-page__phone_2 {
  top: 391px;
  left: 40px;
  width: 230px;
  height: 322px;
  transform: rotate(-45deg);
}
.manual-page__phone_3 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  left: -36px;
}
.manual-page__phone_4 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  left: 278px;
}
.manual-page__phone_5 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  right: 100px;
}
.manual-page__phone_6 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  right: 100px;
}
.manual-page__phone_7 {
  width: 321px;
  height: 337px;
  top: 322px;
  right: 274px;
  transform: rotate(-1deg);
}
.manual-page__phone_8 {
  max-width: 240px;
  max-height: 413px;
  top: 140px;
  left: -80px;
  z-index: 5;
}
.manual-page__phone_9 {
  max-width: 240px;
  max-height: 413px;
  top: 140px;
  left: -80px;
  z-index: 5;
}
.manual-page__phone_10 {
  max-width: 240px;
  max-height: 413px;
  top: 130px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_11 {
  max-width: 240px;
  max-height: 413px;
  top: 123px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_12 {
  max-width: 240px;
  max-height: 413px;
  top: 123px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_13 {
  max-width: 240px;
  max-height: 413px;
  top: 123px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_14 {
  max-width: 267px;
  max-height: 460px;
  top: 153px;
  left: -54px;
  z-index: 5;
}
.manual-page__screen {
  position: absolute;
}
.manual-page__screen_1 {
  max-width: 611px;
  max-height: 365px;
  top: 50px;
  left: 30px;
  z-index: 1;
}
.manual-page__screen_2 {
  max-width: 611px;
  max-height: 365px;
  top: 50px;
  left: 30px;
  z-index: 1;
}
.manual-page__screen_3 {
  max-width: 658px;
  max-height: 365px;
  top: 44px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_4 {
  max-width: 658px;
  max-height: 365px;
  top: 32px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_5 {
  max-width: 658px;
  max-height: 365px;
  top: 32px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_6 {
  max-width: 658px;
  max-height: 365px;
  top: 32px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_7 {
  max-width: 746px;
  max-height: 500px;
  top: 52px;
  left: -93px;
  z-index: 1;
}
.manual-page__app-buttons-list {
  display: flex;
  margin-bottom: 11px;
}
.manual-page__app-buttons-item {
  margin-right: 12px;
  text-decoration: none;
  width: 164px;
}
.manual-page__app-buttons-item:nth-child(2) {
  margin-right: 0;
}
.manual-page__app-buttons-item-img {
  max-width: 100%;
}

@media screen and (max-width: 1149px) {
  .manual-page__header-wrap {
    margin-bottom: 115px;
  }
  .manual-page_start .manual-page__header-wrap {
    margin-bottom: 115px;
  }
  .manual-page_check-citizenship .manual-page__title {
    font-size: 40px;
  }
  .manual-page_registration .manual-page__title {
    max-width: 80%;
  }
  .manual-page_my-tax-passport .manual-page__text-list {
    max-width: 95%;
  }
  .manual-page__col {
    width: 460px;
    min-width: 460px;
  }
  .manual-page__col:nth-child(2) {
    width: calc(100% - 460px - 60px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_start .manual-page__check {
    left: -6px;
    top: 149px;
  }
  .manual-page_check-citizenship .manual-page__check {
    left: -6px;
    top: 149px;
  }
  .manual-page_start .manual-page__status {
    top: -24px;
    left: 141px;
  }
  .manual-page_check-citizenship .manual-page__status {
    top: -24px;
    left: 141px;
  }
  .manual-page_registration .manual-page__shape_1 {
    left: 424px;
    top: 400px;
  }
  .manual-page_start .manual-page__shape_3 {
    left: 0;
  }
  .manual-page_check-citizenship .manual-page__shape_3 {
    left: 0;
  }
  .manual-page_start .manual-page__shape_4 {
    top: 500px;
    left: -164px;
  }
  .manual-page_check-citizenship .manual-page__shape_4 {
    top: 500px;
    left: -164px;
  }
  .manual-page_start .manual-page__ruble_2 {
    left: 390px;
  }
  .manual-page_check-citizenship .manual-page__ruble_2 {
    left: 390px;
  }
  .manual-page_registration .manual-page__ruble_2 {
    right: 176px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_2 {
    right: 410px;
  }
  .manual-page__phone_1 {
    left: -150px;
  }
  .manual-page__phone_2 {
    left: 64px;
  }
  .manual-page_my-tax-2 .manual-page__phone_2 {
    left: -60px;
  }
  .manual-page__phone_3 {
    max-width: 302px;
    max-height: 582px;
  }
  .manual-page__phone_4 {
    max-width: 302px;
    max-height: 582px;
    left: 238px;
  }
  .manual-page__phone_6 {
    right: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .manual-page__header-logo {
    margin-right: 32px;
  }
}
@media screen and (max-width: 980px) {
  .manual-page__header-wrap {
    padding-top: 23px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .manual-page_registration .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_registration .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-2 .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_my-tax-2 .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-passport .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_my-tax-passport .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-lk .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_my-tax-lk .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_web-version .manual-page__col {
    width: 360px;
    min-width: 360px;
    margin-right: 20px;
  }
  .manual-page_web-version .manual-page__col:nth-child(2) {
    width: calc(100% - 360px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_web-lk .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 20px;
  }
  .manual-page_web-lk .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_web-gosuslugi .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 20px;
  }
  .manual-page_web-gosuslugi .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_wallet .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 20px;
  }
  .manual-page_wallet .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_cooperation-2 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_cooperation-2 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-gosuslugi .manual-page__title {
    white-space: normal;
  }
  .manual-page_wallet .manual-page__text-list {
    max-width: 95%;
  }
  .manual-page_start .manual-page__check {
    top: 169px;
    left: -40px;
  }
  .manual-page_check-citizenship .manual-page__check {
    top: 169px;
    left: -40px;
  }
  .manual-page_start .manual-page__status {
    left: 41px;
    top: 6px;
  }
  .manual-page_check-citizenship .manual-page__status {
    left: 41px;
    top: 6px;
  }
  .manual-page_registration .manual-page__status {
    left: 79px;
  }
  .manual-page_start .manual-page__shape_1 {
    top: -81px;
    left: 320px;
  }
  .manual-page_check-citizenship .manual-page__shape_1 {
    top: -81px;
    left: 320px;
  }
  .manual-page_registration_2 .manual-page__shape_1 {
    top: 15px;
    right: 33px;
  }
  .manual-page_start .manual-page__shape_2 {
    left: 170px;
  }
  .manual-page_check-citizenship .manual-page__shape_2 {
    left: 170px;
  }
  .manual-page_registration_2 .manual-page__shape_2 {
    top: 525px;
    left: -60px;
  }
  .manual-page_start .manual-page__shape_3 {
    left: -30px;
    top: 360px;
  }
  .manual-page_check-citizenship .manual-page__shape_3 {
    left: -30px;
    top: 360px;
  }
  .manual-page_my-tax-2 .manual-page__shape_3 {
    right: 150px;
  }
  .manual-page_registration .manual-page__shape_3 {
    left: 102px;
  }
  .manual-page_start .manual-page__shape_4 {
    left: -194px;
  }
  .manual-page_check-citizenship .manual-page__shape_4 {
    left: -194px;
  }
  .manual-page_start .manual-page__ruble_1 {
    left: 247px;
  }
  .manual-page_check-citizenship .manual-page__ruble_1 {
    left: 247px;
  }
  .manual-page_registration_2 .manual-page__ruble_1 {
    right: -60px;
    top: 614px;
  }
  .manual-page_my-tax-2 .manual-page__ruble_1 {
    display: none;
  }
  .manual-page_start .manual-page__ruble_2 {
    top: -117px;
    left: -60px;
  }
  .manual-page_check-citizenship .manual-page__ruble_2 {
    top: -117px;
    left: -60px;
  }
  .manual-page_registration_2 .manual-page__ruble_2 {
    right: 56px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_2 {
    right: 270px;
  }
  .manual-page_registration_2 .manual-page__ruble_3 {
    display: none;
  }
  .manual-page__phone_1 {
    width: 382px;
    height: 550px;
  }
  .manual-page__phone_2 {
    width: 334px;
    height: 492px;
  }
  .manual-page__phone_3 {
    max-width: 282px;
    max-height: 542px;
  }
  .manual-page__phone_4 {
    max-width: 282px;
    max-height: 542px;
    left: 218px;
  }
  .manual-page__phone_5 {
    right: -60px;
  }
  .manual-page__phone_10 {
    right: -100px;
  }
  .manual-page__phone_14 {
    top: 103px;
    left: -84px;
  }
  .manual-page__screen_3 {
    left: -30px;
  }
  .manual-page__screen_7 {
    max-width: 616px;
    max-height: 420px;
    top: 30px;
  }
}
@media screen and (max-width: 760px) {
  .manual-page__header-wrap {
    margin-bottom: 79px;
  }
  .manual-page_start .manual-page__header-wrap {
    margin-bottom: 0;
  }
  .manual-page_check-citizenship .manual-page__header-wrap {
    margin-bottom: 0;
  }
  .manual-page_registration .manual-page__header-wrap {
    margin-bottom: 90px;
  }
  .manual-page_my-tax-1 .manual-page__header-wrap {
    margin-bottom: 80px;
  }
  .manual-page__top-wrap {
    margin-bottom: 77px;
  }
  .manual-page__return-link {
    margin-bottom: 19px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__return-link {
    margin-bottom: 39px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__return-link {
    margin-bottom: 39px;
  }
  .manual-page_cooperation-2 .manual-page__return-link {
    margin-bottom: 39px;
  }
  .manual-page__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .manual-page__content-wrap {
    width: 100%;
  }
  .manual-page_start .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_check-citizenship .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_registration .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_my-tax-1 .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_my-tax-2 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_my-tax-passport .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_my-tax-lk .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_my-tax-gosuslugi .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_web-version .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_web-lk .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_web-gosuslugi .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_cooperation-1 .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_cooperation-2 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_web-version .manual-page__col:first-child {
    height: auto;
  }
  .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 100px;
  }
  .manual-page_start .manual-page__col:nth-child(2) {
    height: 329px;
    padding-bottom: 0;
  }
  .manual-page_check-citizenship .manual-page__col:nth-child(2) {
    height: 310px;
    padding-bottom: 0;
  }
  .manual-page_my-tax-1 .manual-page__col:nth-child(2) {
    height: 536px;
    padding-bottom: 0;
  }
  .manual-page_registration .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_registration .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_registration_2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_registration_2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_my-tax-2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_my-tax-2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_my-tax-passport .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_my-tax-passport .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    height: 508px;
  }
  .manual-page_my-tax-lk .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_my-tax-gosuslugi .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-version .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-version .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_web-lk .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-lk .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_web-gosuslugi .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-gosuslugi .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_wallet .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_wallet .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_cooperation-1 .manual-page__col:nth-child(2) {
    height: 498px;
  }
  .manual-page_cooperation-2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_cooperation-2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page__images-container {
    height: 312px;
    position: relative;
    margin-bottom: 15px;
  }
  .manual-page_wallet .manual-page__images-container {
    margin-bottom: 6px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__images-container {
    margin-bottom: 4px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__images-container {
    margin-bottom: 35px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__images-container {
    margin-bottom: 33px;
  }
  .manual-page_cooperation-2 .manual-page__images-container {
    margin-bottom: 92px;
  }
  .manual-page__title {
    font-size: 28px;
    line-height: 40px;
  }
  .manual-page_start .manual-page__title {
    margin-bottom: 30px;
  }
  .manual-page_check-citizenship .manual-page__title {
    margin-bottom: 30px;
    font-size: 28px;
  }
  .manual-page_registration .manual-page__title {
    margin-bottom: 20px;
  }
  .manual-page_my-tax-2 .manual-page__title {
    margin-bottom: 24px;
  }
  .manual-page_web-version .manual-page__title {
    margin-bottom: 22px;
  }
  .manual-page_web-gosuslugi .manual-page__title {
    margin-bottom: 28px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__title {
    margin-bottom: 38px;
  }
  .manual-page_my-tax-2 .manual-page__text-list {
    margin-top: 0;
    margin-bottom: 74px;
  }
  .manual-page_my-tax-passport .manual-page__text-list {
    margin-bottom: 73px;
  }
  .manual-page_my-tax-lk .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 72px;
  }
  .manual-page_my-tax-gosuslugi .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 73px;
  }
  .manual-page_web-lk .manual-page__text-list {
    max-width: 100%;
    padding-left: 0;
  }
  .manual-page_web-gosuslugi .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 71px;
  }
  .manual-page_wallet .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 71px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 73px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 28px;
  }
  .manual-page_cooperation-2 .manual-page__text-list {
    margin-bottom: 30px;
  }
  .manual-page_registration .manual-page__text {
    margin-top: 0;
  }
  .manual-page_my-tax-1 .manual-page__text {
    margin-bottom: 72px;
  }
  .manual-page_my-tax-gosuslugi .manual-page__text {
    max-width: 100%;
    margin-bottom: 33px;
  }
  .manual-page_web-version .manual-page__text {
    padding-top: 0;
    margin-bottom: 72px;
  }
  .manual-page_web-lk .manual-page__text {
    max-width: 100%;
    margin-bottom: 67px;
  }
  .manual-page_web-gosuslugi .manual-page__text:first-child {
    margin-bottom: 31px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__text {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__text {
    max-width: 100%;
    margin-bottom: 33px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__text {
    max-width: 100%;
    margin-bottom: 24px;
  }
  .manual-page_cooperation-1 .manual-page__text {
    max-width: 100%;
    margin-bottom: 72px;
  }
  .manual-page_cooperation-2 .manual-page__text {
    max-width: 100%;
  }
  .manual-page__button-item {
    width: 100%;
    max-width: 335px;
  }
  .manual-page__check {
    width: 215px;
    height: 248px;
  }
  .manual-page_start .manual-page__check {
    top: 64px;
    left: 50px;
  }
  .manual-page_check-citizenship .manual-page__check {
    top: 64px;
    left: 50px;
  }
  .manual-page_registration .manual-page__check {
    display: none;
  }
  .manual-page__status {
    width: 228px;
    height: 144px;
  }
  .manual-page_start .manual-page__status {
    top: -12px;
    left: 155px;
  }
  .manual-page_check-citizenship .manual-page__status {
    top: -12px;
    left: 155px;
  }
  .manual-page_registration .manual-page__status {
    display: none;
  }
  .manual-page__nalog1 {
    width: 238.54px;
    height: 175.77px;
    top: 237px;
    left: 21px;
  }
  .manual-page__nalog2 {
    width: 232.27px;
    height: 65.91px;
    top: 298px;
    left: 173px;
  }
  .manual-page__shape_1 {
    width: 13px;
    height: 13px;
  }
  .manual-page_start .manual-page__shape_1 {
    top: 137px;
    left: 316px;
  }
  .manual-page_check-citizenship .manual-page__shape_1 {
    top: 137px;
    left: 316px;
  }
  .manual-page_cooperation-1 .manual-page__shape_1 {
    top: 125px;
    right: 95px;
  }
  .manual-page__shape_2 {
    width: 20px;
    height: 20px;
  }
  .manual-page_start .manual-page__shape_2 {
    top: 31px;
    left: 100px;
  }
  .manual-page_check-citizenship .manual-page__shape_2 {
    top: 31px;
    left: 100px;
  }
  .manual-page_cooperation-1 .manual-page__shape_2 {
    top: 31px;
    right: 54px;
  }
  .manual-page__shape_3 {
    width: 40px;
    height: 40px;
  }
  .manual-page_start .manual-page__shape_3 {
    top: 200px;
    left: 70px;
  }
  .manual-page_check-citizenship .manual-page__shape_3 {
    top: 200px;
    left: 70px;
  }
  .manual-page_cooperation-1 .manual-page__shape_3 {
    bottom: 74px;
    left: 9px;
  }
  .manual-page__shape_4 {
    width: 20px;
    height: 20px;
  }
  .manual-page_start .manual-page__shape_4 {
    left: -30px;
    top: 210px;
  }
  .manual-page_check-citizenship .manual-page__shape_4 {
    left: -30px;
    top: 210px;
  }
  .manual-page_cooperation-1 .manual-page__shape_4 {
    bottom: 39px;
    right: 13px;
  }
  .manual-page_registration .manual-page__shape {
    display: none;
  }
  .manual-page_start .manual-page__ruble_1 {
    top: 231px;
    width: 52px;
    height: 60px;
    transform: rotate(-21deg);
    left: 264px;
  }
  .manual-page_check-citizenship .manual-page__ruble_1 {
    top: 231px;
    width: 52px;
    height: 60px;
    transform: rotate(-21deg);
    left: 264px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_1 {
    width: 78px;
    height: 89.67px;
    top: 123px;
    right: -32px;
  }
  .manual-page_start .manual-page__ruble_2 {
    left: -18px;
    top: 88px;
    width: 69px;
    height: 63px;
  }
  .manual-page_check-citizenship .manual-page__ruble_2 {
    left: -18px;
    top: 88px;
    width: 69px;
    height: 63px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_2 {
    width: 66px;
    height: 62px;
    right: auto;
    left: -25px;
    transform: rotate(-15deg);
    top: 145px;
  }
  .manual-page_registration .manual-page__ruble {
    display: none;
  }
  .manual-page__phone_1 {
    width: 296px;
    height: 416px;
    left: -75px;
    top: 139px;
    transform: rotate(15deg);
  }
  .manual-page__phone_2 {
    width: 263px;
    height: 363px;
    left: 68px;
    top: 147px;
  }
  .manual-page__phone_3 {
    width: 192px;
    height: 366px;
    top: 120px;
    left: -16px;
  }
  .manual-page__phone_4 {
    width: 192px;
    height: 366px;
    top: 120px;
    left: 158px;
  }
  .manual-page__phone_5 {
    position: relative;
    left: -17px;
    top: auto;
    right: auto;
    width: 192px;
    height: 366px;
    margin-bottom: 20px;
  }
  .manual-page__phone_6 {
    position: relative;
    left: -17px;
    top: auto;
    right: auto;
    width: 192px;
    height: 366px;
    margin-bottom: 20px;
  }
  .manual-page__phone_8 {
    width: 182px;
    height: 312px;
    top: 5px;
    left: -30px;
  }
  .manual-page__phone_9 {
    width: 182px;
    height: 312px;
    top: 5px;
    left: -30px;
  }
  .manual-page__phone_10 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -10px;
  }
  .manual-page__phone_11 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -10px;
  }
  .manual-page__phone_12 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -6px;
  }
  .manual-page__phone_13 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -6px;
  }
  .manual-page__phone_14 {
    width: 182px;
    height: 312px;
    left: -13px;
    top: 52px;
  }
  .manual-page__screen_1 {
    width: 292px;
    height: 176px;
    left: 54px;
  }
  .manual-page__screen_2 {
    width: 292px;
    height: 176px;
    left: 54px;
  }
  .manual-page__screen_3 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_4 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_5 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_6 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_7 {
    width: 375px;
    height: 252px;
    top: 0;
    left: -20px;
  }
  .manual-page__chat-button-wrap {
    padding-bottom: 30px;
    padding-right: 20px;
  }
}
@keyframes chat-animation {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0);
  }
}