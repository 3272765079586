.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.change-email__input-block {
  display: flex;
  align-items: center;
}
.change-email__input-holder {
  margin-bottom: 20px;
  position: relative;
}
.hint_change-phone .change-email__input-holder:before {
  content: "+7 (";
  position: absolute;
  left: 0;
  top: 16px;
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Bold", sans-serif;
}
.hint_change-phone .change-email__input-holder:focus-within:before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.hint_change-phone .change-email__input-holder_filled:before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.change-email__input-holder_email {
  max-width: 355px;
}
.change-email__input {
  border: none;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Bold", sans-serif;
  line-height: 60px;
  padding: 0 20px 0 0;
}
.hint_change-phone .change-email__input {
  padding: 0 20px 0 46px;
}
.hint_change-phone .change-email__input:focus {
  padding: 0 20px 0 0;
}
.hint_change-phone .hint__input-holder_filled .change-email__input {
  padding: 0 20px 0 0;
}
.change-email__input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input::-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input:-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input:-ms-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input-loader-wrap {
  position: relative;
  margin-top: -10px;
}
.hint_change-phone .change-email__input-loader-wrap {
  margin-top: 0;
  margin-left: 15px;
}
.change-email__input-loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
}
.change-email__input-loader-icon_loading {
  margin-top: -8px;
  margin-left: -6px;
}
.change-email__input-loader-icon_loading path {
  fill: var(--grey-blue);
}
.change-email__input-loader-icon_check {
  margin-top: -6px;
  margin-left: -7px;
}
.change-email__input-loader-icon_fail {
  margin-top: -8px;
  margin-left: -6px;
}
.change-email__input-loader-icon_fail path {
  fill: var(--reddish-pink);
}
.change-email__btn-wrap {
  display: flex;
}
.change-email__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  text-decoration: none;
  height: 60px;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  transition: all 0.2s ease-in-out;
}
.change-email__btn:hover:not(:disabled) {
  background: var(--dusk-blue);
  transition: all 0.2s ease-in-out;
}
.change-email__btn:disabled {
  cursor: not-allowed;
  opacity: 0.2;
  background: var(--light-grey-blue);
}
.change-email__btn_disabled {
  cursor: not-allowed;
  opacity: 0.2;
  background: var(--light-grey-blue);
}
.change-email__btn_disabled:hover {
  background: var(--light-grey-blue);
}
.change-email__tip {
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  max-width: 348px;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 20px;
}
.change-email__tip_error {
  color: var(--reddish-pink);
}