.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.bill-creation {
  max-width: 1300px;
  padding: 50px 50px 130px 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.bill-creation__top {
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 39px;
}
.bill-creation__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.bill-creation__content {
  display: flex;
}
.bill-creation__card-wrap {
  width: 650px;
  min-width: 650px;
  margin-right: 60px;
  position: relative;
  z-index: 20;
}
.bill-creation__card-link-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.bill-creation__card-link {
  width: 252px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.bill-creation__card-link:hover {
  background: var(--dusk-blue);
}
.bill-creation__useful-links {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 1200px) {
  .bill-creation__content {
    display: block;
  }
  .bill-creation__card-wrap {
    margin-right: 0;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 980px) {
  .bill-creation {
    padding: 0 0 100px 0;
    margin-top: -90px;
  }
  .bill-creation__header {
    background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
    min-height: 220px;
    margin-bottom: 36px;
    position: relative;
    overflow: hidden;
    padding: 90px 50px 35px 50px;
  }
  .bill-creation__header_fail {
    margin-bottom: 0;
  }
  .bill-creation__header-icon-wrap {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .bill-creation__header-icon {
    width: 29px;
    height: 26px;
    fill: var(--deep-sky-blue);
  }
  .bill-creation__header-bg {
    position: absolute;
    right: -80px;
    bottom: -30px;
    width: 260px;
    height: 320px;
    object-fit: contain;
    z-index: 10;
  }
  .bill-creation__header-title {
    font-size: 28px;
    line-height: 1.29;
    font-family: "Circe-Bold", sans-serif;
    color: white;
    position: relative;
    z-index: 20;
  }
  .bill-creation__content {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    position: relative;
    z-index: 20;
  }
  .bill-creation__card-link-wrap {
    display: flex;
    justify-content: center;
  }
  .bill-creation__card-link {
    border-radius: 30px;
    max-width: 375px;
    width: 100%;
  }
  .bill-creation__card-wrap {
    width: 100%;
    min-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .bill-creation {
    margin-top: -70px;
  }
  .bill-creation__header {
    padding: 70px 50px 35px 50px;
  }
}
@media screen and (max-width: 600px) {
  .bill-creation__header {
    padding: 70px 20px 35px 20px;
  }
}
@media screen and (max-width: 350px) {
  .bill-creation__header {
    padding: 70px 14px 35px 14px;
  }
  .bill-creation__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}