.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.form-input-wrap {
  width: calc((100% - 10px) / 2);
  position: relative;
}

.auth-status__first-tab-content {
  margin-top: 35px;
  margin-bottom: 30px;
  display: grid;
  grid-template: "surname name" "patronymic birthDay";
  grid-gap: 20px 10px;
}
.auth-status__first-tab-content > * {
  width: 100%;
}
.auth-status__first-tab-content__surname {
  grid-area: surname;
}
.auth-status__first-tab-content__name {
  grid-area: name;
}
.auth-status__first-tab-content__patronymic {
  grid-area: patronymic;
}
.auth-status__first-tab-content__birthDay {
  grid-area: birthDay;
}
.auth-status__second-tab-content {
  margin-top: 35px;
  margin-bottom: 30px;
  display: grid;
  grid-template: "passport-serie password-number" "date passportIssuer" "passportIssuedBy passportIssuedBy" "birthPlace birthPlace" "inn .";
  grid-gap: 20px 10px;
}
.auth-status__second-tab-content__passport {
  grid-area: passport;
}
.auth-status__second-tab-content__date {
  grid-area: date;
}
.auth-status__second-tab-content__passportIssuer {
  grid-area: passportIssuer;
}
.auth-status__second-tab-content__passportIssuedBy {
  grid-area: passportIssuedBy;
}
.auth-status__second-tab-content__birthPlace {
  grid-area: birthPlace;
}
.auth-status__second-tab-content__inn {
  grid-area: inn;
}
@media (max-width: 600px) {
  .auth-status__second-tab-content {
    grid-template: "passport-serie" "password-number" "date" "passportIssuer" "passportIssuedBy" "birthPlace" "inn";
  }
}
.auth-status__nav {
  cursor: pointer;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
}

.form-input {
  position: relative;
}
.form-input_2-col {
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
}
.form-input_2-col:nth-child(2n) {
  margin-right: 0;
}
.form-input:nth-last-child(-n+2) {
  margin-bottom: 0;
}
.profile-settings .form-input:nth-last-child(-n+2) {
  margin-bottom: 24px;
}
.profile-settings .form-input:last-child {
  margin-bottom: 0;
}
.profile-settings__input-list .form-input {
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
}
.profile-settings__input-list .form-input:last-child {
  margin-right: 0;
}
.form-input input {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  -webkit-appearance: none;
  outline: none;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
}
.form-input input.form-input__itself_error {
  border-color: var(--reddish-pink);
}
.form-input input.form-input__itself_error:focus {
  border-color: var(--reddish-pink);
}
.form-input input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-color: white;
}
.form-input input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input::-ms-clear {
  display: none;
}
.form-input_focused input {
  background-color: white;
}
.form-input__itself {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
  text-transform: capitalize;
}
.form-input__itself:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.form-input__itself:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.form-input__itself_disabled:hover {
  box-shadow: none;
  background-color: rgba(228, 232, 238, 0.2);
}
.profile-settings .form-input__itself:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  border-color: rgba(228, 232, 238, 0.1);
}
.form-input__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
}
.form-input:focus-within .form-input__label {
  top: 7px;
  font-size: 12px;
  color: #6b778d;
}
.form-input_focused .form-input__label {
  top: 7px;
  font-size: 12px;
  color: #6b778d;
}
.form-input__calendar-icon {
  position: absolute;
  right: 18px;
  width: 20px;
  height: 20px;
  top: 20px;
}
.form-input__input-check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.form-input__input-check path {
  stroke: var(--dark-slate-blue);
}
.form-input__input-error {
  position: absolute;
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
  font-size: 14px;
  top: 100%;
  padding: 5px 5px 0;
  left: 0;
}

@media screen and (max-width: 800px) {
  .form-input_2-col {
    width: 100%;
    margin-right: 0;
  }
  .form-input:nth-last-child(-n+2) {
    margin-bottom: 20px;
  }
  .form-input:last-child {
    margin-bottom: 0;
  }
  .form-input input {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: solid 1px rgba(157, 166, 186, 0.2);
    background-color: rgba(228, 232, 238, 0.2);
    color: var(--dark-slate-blue);
    font-size: 16px;
    font-family: "Circe-Regular", sans-serif;
    -webkit-appearance: none;
    outline: none;
    padding: 29px 20px 16px 20px;
    transition: all 0.3s ease;
  }
  .form-input__itself {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    font-family: "Circe-Regular", sans-serif;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: solid 1px rgba(157, 166, 186, 0.2);
    background-color: rgba(228, 232, 238, 0.2);
    color: var(--dark-slate-blue);
    font-size: 16px;
    padding: 29px 20px 16px 20px;
    transition: all 0.3s ease;
  }
  .form-input__itself:focus {
    border: solid 1px var(--dark-slate-blue);
    box-shadow: none;
    background: white;
  }
  .form-input__itself:hover:not(:disabled) {
    box-shadow: 0 4px 27px 0 #e8ecf2;
    background: white;
  }
  .form-input__label {
    font-size: 18px;
    color: #9da6ba;
    position: absolute;
    left: 24px;
    top: 24px;
    font-family: "Circe-Bold", sans-serif;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  .form-input:focus-within .form-input__label {
    top: 7px;
    font-size: 12px;
    color: #6b778d;
    text-transform: uppercase;
  }
  .form-input_focused .form-input__label {
    top: 7px;
    font-size: 12px;
    color: #6b778d;
  }
  .form-input__calendar-icon {
    position: absolute;
    right: 18px;
    width: 20px;
    height: 20px;
    top: 20px;
  }
}