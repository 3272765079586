.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-settings-step-2__title {
  margin-bottom: 22px;
}
.acquiring-settings-step-2__content {
  margin-bottom: 64px;
}
.acquiring-settings-step-2__block {
  margin-bottom: 33px;
}
.acquiring-settings-step-2__block:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-2__text {
  font-size: 18px;
  line-height: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 26px;
  max-width: 93%;
  width: 100%;
}
.acquiring-settings-step-2__text:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-2__block-title {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 19px;
}
.acquiring-settings-step-2__block-list-item {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  padding-left: 16px;
  position: relative;
}
.acquiring-settings-step-2__block-list-item:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-2__block-list-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--dark-slate-blue);
}
.acquiring-settings-step-2__block-link-wrap {
  margin-right: 5px;
}
.acquiring-settings-step-2__block-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--deep-sky-blue);
  word-break: break-word;
}
.acquiring-settings-step-2__block-link:hover {
  text-decoration: none;
}
.acquiring-settings-step-2__loader {
  display: inline-block;
  margin-left: 5px;
}
.acquiring-settings-step-2__code-wrap {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  text-align: left;
  position: relative;
}
.acquiring-settings-step-2__code {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 13px;
  word-break: break-all;
}
.acquiring-settings-step-2__code-copy-icon {
  margin-top: 2px;
}
.acquiring-settings-step-2__code-copy-icon path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-2__error-message {
  margin-bottom: 40px;
  border-radius: 8px;
  max-width: 540px;
  width: 100%;
  margin-top: -24px;
}
.acquiring-settings-step-2__buttons-list {
  display: flex;
}
.acquiring-settings-step-2__buttons-item {
  width: 165px;
  margin-right: 20px;
}
.acquiring-settings-step-2__buttons-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 480px) {
  .acquiring-settings-step-2__buttons-list {
    flex-direction: column-reverse;
  }
  .acquiring-settings-step-2__buttons-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .acquiring-settings-step-2__buttons-item:first-child {
    margin-bottom: 0;
  }
}