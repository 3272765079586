.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-form {
  width: 100%;
}
.save-money-form__section {
  margin-bottom: 40px;
}
.save-money-form__section_colors {
  margin-bottom: 20px;
}
.save-money-form__section_customer-info {
  margin-bottom: 20px;
}
.save-money-form__tabs {
  margin-bottom: 40px;
}
.save-money-form__block {
  margin-bottom: 22px;
}
.save-money-form__section .save-money-form__block:last-child {
  margin-bottom: 0;
}
.save-money-form__block-title {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 22px;
}
.save-money-form__block-descr {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--bluey-grey);
}
.save-money-form__input-holder {
  position: relative;
}
.save-money-form__input-holder_date {
  position: relative;
  margin-bottom: 8px;
}
.save-money-form__input-holder:focus-within .date-picker-custom-input__itself {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__input-holder_focused .date-picker-custom-input__itself {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
}
.save-money-form__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.save-money-form__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
}
.save-money-form__input-holder_focused .save-money-form__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: scale(1);
  transform-origin: 0 0;
}
.save-money-form__input-holder:focus-within .save-money-form__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  transform: scale(0.6);
  white-space: nowrap;
}
.save-money-form__input-holder_focused .save-money-form__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  transform: scale(0.6);
  white-space: nowrap;
}
.save-money-form__textarea {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 130px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  vertical-align: top;
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
}
.save-money-form__textarea:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__textarea:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.save-money-form__textarea_disabled:hover {
  box-shadow: none;
  background-color: rgba(228, 232, 238, 0.2);
}
.save-money-form__input-holder_focused .save-money-form__textarea {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__checkbox-list {
  display: flex;
  flex-wrap: wrap;
}
.save-money-form__checkbox-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.save-money-form__checkbox-list .save-money-form__checkbox-wrap {
  width: auto;
  margin-right: 40px;
  margin-bottom: 20px;
}
.save-money-form__checkbox-list .save-money-form__checkbox-wrap:last-child {
  margin-right: 0;
}
.save-money-form__checkbox {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 12px;
  border-radius: 4px;
  border: 1px solid #e4e8ee;
  background-color: var(--pale-grey);
  position: relative;
}
.save-money-form__checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--dark-slate-blue);
  opacity: 0;
  pointer-events: none;
}
.save-money-form__checkbox[aria-checked=true]:after {
  opacity: 1;
}
.save-money-form__checkbox-text {
  font-size: 16px;
  line-height: 1.2;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.save-money-form__color-radio {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--pale-grey);
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}
.save-money-form__color-radio_theme-1 {
  background-image: var(--save-money-color-theme-1);
}
.save-money-form__color-radio_theme-2 {
  background-image: var(--save-money-color-theme-2);
}
.save-money-form__color-radio_theme-3 {
  background-image: var(--save-money-color-theme-3);
}
.save-money-form__color-radio_theme-4 {
  background-image: var(--save-money-color-theme-4);
}
.save-money-form__color-radio_theme-5 {
  background-image: var(--save-money-color-theme-5);
}
.save-money-form__color-radio_theme-6 {
  background-image: var(--save-money-color-theme-6);
}
.save-money-form__color-radio:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  opacity: 0;
  pointer-events: none;
}
.save-money-form__color-radio[aria-checked=true]:after {
  opacity: 1;
}
.save-money-form__sum-type {
  display: flex;
  width: 100%;
}
.save-money-form__sum-type-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 50%;
  min-width: 50%;
  height: 40px;
  line-height: 39px;
  border: 1px solid #e4e8ee;
  background-color: var(--pale-grey);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #616a88;
  text-align: center;
}
.save-money-form__sum-type-btn:first-child {
  border-radius: 8px 0 0 8px;
}
.save-money-form__sum-type-btn:last-child {
  border-radius: 0 8px 8px 0;
}
.save-money-form__sum-type-btn_active {
  background-color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
  color: white;
}
.save-money-form__button-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.save-money-form__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 1px solid var(--dark-slate-blue);
  background-color: var(--dark-slate-blue);
  transition: all 0.3s ease;
  font-size: 16px;
  border-color: var(--dark-slate-blue);
  color: white;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.save-money-form__button:hover {
  background: var(--dusk-blue);
}
.save-money-form__button[disabled] {
  cursor: not-allowed;
  color: #9da6ba;
  background-color: var(--pale-grey);
  border-color: var(--pale-grey);
}
.save-money-form__link-base {
  position: absolute;
  left: 20px;
  top: 28px;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.save-money-form__link-base_hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.save-money-form__input-holder_error .save-money-form__link-base {
  color: var(--reddish-pink);
}
.save-money-form__input-holder_disabled .save-money-form__link-base {
  color: var(--grey-blue);
}
.save-money-form__copy-button {
  right: 20px;
  top: 24px;
  position: absolute;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 12px;
  height: 14px;
}
.save-money-form__copy-button:disabled {
  cursor: not-allowed;
}
.save-money-form__copy-icon {
  width: 12px;
  height: 14px;
}
.save-money-form__copy-icon path {
  transition: all 0.3s ease;
}
.save-money-form__copy-button:hover:not(:disabled) .save-money-form__copy-icon path {
  fill: var(--deep-sky-blue);
}
.save-money-form__copy-button:disabled .save-money-form__copy-icon path {
  fill: var(--light-grey-blue);
}
.save-money-form__copy-done-icon {
  right: 20px;
  top: 50%;
  margin-top: -7px;
  position: absolute;
}
.save-money-form__url-input-loader {
  position: absolute;
  right: -25px;
  top: 50%;
  margin-top: -7px;
}
.save-money-form__error-message {
  font-size: 14px;
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.4;
}
.save-money-form__calendar-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  pointer-events: none;
}
.save-money-form__calendar-icon g {
  transition: fill 0.3s ease;
}
.save-money-form__input-holder:focus-within .save-money-form__calendar-icon g {
  fill: var(--dark-slate-blue);
}
.save-money-form__input-holder_focused .save-money-form__calendar-icon g {
  fill: var(--dark-slate-blue);
}

@media screen and (max-width: 980px) {
  .save-money-form__button-wrap {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 600px) {
  .save-money-form__section_colors {
    margin-bottom: 25px;
  }
  .save-money-form__color-radio {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}