.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-input-and-payment-button {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.money-input-and-payment-button__input-wrap, .money-input-and-payment-button__button-wrap {
  display: flex;
  flex-direction: column;
}
.money-input-and-payment-button__input, .money-input-and-payment-button__button {
  width: 190px;
  height: 60px;
}
.money-input-and-payment-button__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  background-color: var(--dusk-blue);
  font-size: 16px;
  color: #fff;
  transition: all 0.3s ease;
}
.money-input-and-payment-button__button:hover {
  background-color: var(--dark-slate-blue);
}
.money-input-and-payment-button__button:disabled {
  background-color: rgba(40, 65, 149, 0.1);
  cursor: not-allowed;
}
.money-input-and-payment-button__error-message {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 8px;
  line-height: 20px;
  font-size: 14px;
  color: #ff2d55;
}
.money-input-and-payment-button__commission-message {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 8px;
  line-height: 16px;
  font-size: 12px;
  color: #616a88;
}
.money-input-and-payment-button__transfer-all {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 4px;
  line-height: 16px;
  font-size: 12px;
  color: var(--dark-slate-blue);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 980px) {
  .money-input-and-payment-button {
    flex-direction: column;
  }
  .money-input-and-payment-button__input, .money-input-and-payment-button__button {
    width: 100%;
  }
  .money-input-and-payment-button__button {
    margin-top: 18px;
  }
  .money-input-and-payment-button__error-message {
    margin-top: 10px;
    text-align: center;
    line-heigght: 16px;
    font-size: 12px;
  }
  .money-input-and-payment-button__commission-message {
    margin-top: 10px;
    text-align: center;
  }
  .money-input-and-payment-button__transfer-all {
    text-align: center;
  }
}