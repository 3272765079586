.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.notifications {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
  box-sizing: border-box;
}
.notifications__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications__loading-icon {
  height: 40px;
  width: 40px;
}
.notifications__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(176, 181, 196, 0.5);
  margin-bottom: 27px;
  padding-bottom: 10px;
}
.notifications__main {
  display: flex;
  align-items: center;
}
.notifications__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
  margin-right: 30px;
  line-height: 1.33;
}
.notifications__info {
  display: flex;
  align-items: center;
}
.notifications__info-item {
  min-width: 150px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  height: 30px;
  border-radius: 15px;
  border: solid 1px var(--grey-blue);
  margin-right: 10px;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  background: transparent;
  cursor: pointer;
}
.notifications__info-item_active {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  border: 1px solid transparent;
  color: white;
  cursor: default;
}
.notifications__info-item:last-child {
  margin-right: 0;
}
.notifications__info-item:hover:not(.notifications__info-item_active):not(:disabled) {
  box-shadow: 0px 1px 11px #c6c8cc;
}
.notifications__info-item:disabled {
  cursor: not-allowed;
}
.notifications__mark-all-as-read {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.notifications__mark-all-as-read-icon {
  width: 8px;
  height: 8px;
  border: solid 1px var(--grey-blue);
  margin-right: 6px;
  border-radius: 50%;
  margin-top: 2px;
}
.notifications__mark-all-as-read-text {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
.notifications__mark-all-as-read:hover .notifications__mark-all-as-read-text {
  text-decoration: none;
}
@media screen and (max-width: 850px) {
  .notifications__top {
    flex-direction: column;
    align-items: flex-start;
  }
  .notifications__main {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .notifications__title {
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 600px) {
  .notifications {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 350px) {
  .notifications {
    padding-left: 14px;
    padding-right: 14px;
  }
}