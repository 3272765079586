@import '~react-datepicker/dist/react-datepicker.css';
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 30;
  width: 320px;
}

.react-datepicker {
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border: none;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__day {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  width: 35px;
  height: 23px;
  line-height: 23px;
  border-radius: 2px;
}
.react-datepicker__day:nth-child(6) {
  color: var(--bluey-grey);
}
.react-datepicker__day:nth-child(7) {
  color: #ff5353;
}
.react-datepicker__day:hover {
  border-radius: 2px;
}
.react-datepicker__day--today {
  color: white;
  background: var(--grey-blue);
}
.react-datepicker__day--selected {
  background-color: var(--deep-sky-blue);
  color: white;
  border-radius: 2px;
}
.react-datepicker__day--selected:hover {
  color: white;
  background: var(--dusk-blue);
}
.react-datepicker__day--keyboard-selected {
  background-color: var(--deep-sky-blue);
  color: white;
  border-radius: 2px;
}
.react-datepicker__day--keyboard-selected:hover {
  color: white;
  background: var(--dusk-blue);
}
.react-datepicker__day--disabled {
  color: #ccc;
}
.react-datepicker__day--disabled.react-datepicker__day--today {
  color: white;
}
.react-datepicker__day--disabled.react-datepicker__day--today:hover {
  color: #ccc;
  background: #f0f0f0;
}
.react-datepicker__day-name {
  font-size: 11px;
  color: rgba(178, 178, 178, 0.5);
  font-family: "Circe-Bold", sans-serif;
}
.react-datepicker__header {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
}
.react-datepicker__current-month {
  width: 60%;
  margin-right: 10px;
  height: 30px;
  line-height: 30px;
  color: var(--grey-blue);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border-radius: 4px;
  text-transform: capitalize;
  border: solid 1px rgba(176, 181, 196, 0.1);
  position: relative;
  margin-bottom: 10px;
}
.react-datepicker__navigation {
  top: 16px;
}
.react-datepicker__year-option .react-datepicker__navigation {
  top: -3px;
}
.react-datepicker__navigation--previous {
  left: 18px;
}
.react-datepicker__year-option .react-datepicker__navigation--previous {
  top: 0;
}
.react-datepicker__navigation--next {
  left: 176px;
  right: auto;
}
.react-datepicker__year-option .react-datepicker__navigation--next {
  bottom: 0;
}
.react-datepicker__day-names {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.react-datepicker__header__dropdown {
  width: calc(40% - 10px);
  height: 30px;
  border-radius: 4px;
  border: solid 1px rgba(176, 181, 196, 0.1);
  margin-bottom: 10px;
}
.react-datepicker__year-dropdown-container {
  width: 100%;
  line-height: 30px;
  color: var(--bluey-grey);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.react-datepicker__year-dropdown {
  top: 50px;
  left: auto;
  right: 8px;
  background-color: white;
  border: none;
  box-shadow: 0 0 27px 0 #bbc1c9;
}
.react-datepicker__year-read-view {
  position: relative;
  visibility: visible !important;
  text-align: left;
  padding-left: 7px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.react-datepicker__year-read-view--down-arrow {
  position: absolute;
  float: none;
  right: 10px;
  top: 11px;
}
.react-datepicker__year-option {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
}
.react-datepicker__year-option .react-datepicker__navigation--years-previous {
  top: 4px;
}

@media screen and (max-width: 350px) {
  .react-datepicker-popper {
    width: 290px;
  }
  .react-datepicker__current-month {
    width: 130px;
  }
  .react-datepicker__header__dropdown {
    width: 110px;
  }
}
.auth-status-container {
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 200;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.auth-status {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.auth-status_choose-way {
  min-height: 800px;
  padding-bottom: 57px;
}
.auth-status__nav {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.auth-status__nav-back {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  transform: rotate(180deg);
}
.auth-status__nav-back-icon {
  height: 30px;
  width: 15px;
}
.auth-status__nav-close {
  align-items: center;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  text-decoration: none;
  width: 48px;
  z-index: 20;
}
.auth-status__header-close {
  align-items: center;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 56px;
  text-decoration: none;
  top: 48px;
  width: 48px;
  z-index: 20;
}
.auth-status__header-close-icon path {
  transition: all 0.3s ease;
}
.auth-status__header-close:hover .auth-status__header-close-icon path {
  stroke: var(--reddish-pink);
}
.auth-status__header {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  min-height: 320px;
  overflow: hidden;
  padding-bottom: 155px;
  padding-top: 102px;
  position: relative;
  will-change: transform;
}
.auth-status_enter-passport-data .auth-status__header {
  padding-bottom: 65px;
  padding-top: 62px;
}
.auth-status_choose-way .auth-status__header {
  min-height: 400px;
}
.auth-status_success .auth-status__header {
  height: 320px;
  padding: 0;
}
.auth-status_waiting .auth-status__header {
  height: 320px;
  padding: 0;
}
.auth-status_fail .auth-status__header {
  height: 320px;
  padding: 0;
}
.auth-status__header-container {
  position: relative;
  z-index: 20;
}
.auth-status__header-bg {
  height: 943px;
  margin-top: -534px;
  position: absolute;
  right: -125px;
  top: 50%;
  width: 773px;
  z-index: 10;
}
.auth-status_waiting .auth-status__header-bg {
  margin-top: -464px;
}
.auth-status_success .auth-status__header-bg {
  margin-top: -464px;
}
.auth-status_fail .auth-status__header-bg {
  margin-top: -464px;
}
.auth-status_choose-way .auth-status__header-content {
  max-width: 440px;
}
.auth-status_enter-passport-data .auth-status__header-content {
  max-width: 440px;
}
.auth-status_success .auth-status__header-content {
  max-width: 440px;
}
.auth-status_gosuslugi-fail .auth-status__header-content {
  max-width: 404px;
}
.auth-status_already-registered .auth-status__header-content {
  max-width: 424px;
}
.auth-status__header-title {
  font-family: "Circe-Bold", sans-serif;
  color: white;
  font-size: 36px;
  line-height: 1.33;
  margin-bottom: 10px;
}
.auth-status__header-desc {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
}
.auth-status_enter-passport-data .auth-status__header-desc {
  margin-bottom: 30px;
}
.auth-status_success .auth-status__header-desc {
  margin-bottom: 50px;
}
.profile__card .auth-status__header-desc {
  color: var(--grey-blue);
  line-height: 1.75;
  margin-bottom: 56px;
  text-align: center;
}
.auth-status__header-desc-warning {
  height: 20px;
  left: -30px;
  position: absolute;
  top: 3px;
  width: 20px;
}
.auth-status__passport-scan-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 rgba(232, 236, 242, 0.1);
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0 20px;
  transition: all 0.3s ease;
}
.auth-status__passport-scan-btn:hover {
  background: rgba(255, 255, 255, 0.3);
}
.auth-status__passport-scan-btn_upload {
  border: 1px dashed white;
}
.auth-status__passport-scan-btn_upload:hover {
  background: rgba(255, 255, 255, 0.1);
}
.auth-status__passport-scan-btn-icon {
  margin-right: 10px;
}
.auth-status__passport-scan-btn-text {
  font-family: "Circe-Bold", sans-serif;
  color: white;
  font-size: 16px;
}
.auth-status__header-btn-wrap {
  display: flex;
}
.auth-status__header-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 rgba(232, 236, 242, 0.1);
  color: white;
  display: flex;
  font-size: 16px;
  height: 60px;
  justify-content: center;
  padding: 0 20px;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 265px;
}
.auth-status__header-btn:hover {
  background: rgba(255, 255, 255, 0.3);
}
.auth-status__header-logo-wrap {
  left: calc(50% + 75px);
  margin-top: -70px;
  position: absolute;
  top: 50%;
  z-index: 20;
}
.auth-status__header-logo-container {
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 32px 0 var(--dusk-blue);
  height: 140px;
  overflow: hidden;
  width: 140px;
}
.auth-status__header-logo {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.auth-status__header-logo-status {
  align-items: center;
  background-color: var(--deep-sky-blue);
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 50px;
}
.auth-status__header-logo-status-icon {
  height: 28px;
  width: 30px;
}
.auth-status__shape {
  position: absolute;
  z-index: 20;
}
.auth-status__shape_1 {
  bottom: 26px;
  height: 52px;
  left: calc(50% + 6px);
  width: 51px;
}
.auth-status__shape_2 {
  height: 20px;
  left: calc(70% + 18px);
  top: 120px;
  width: 20px;
}
.auth-status__shape_3 {
  height: 89px;
  left: calc(43% + 9px);
  top: 43px;
  width: 76px;
}
.auth-status__content {
  display: flex;
  flex-direction: column;
  flex-grow: 20;
  justify-content: space-between;
  position: relative;
}
.auth-status_enter-passport-data .auth-status__content {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  min-height: 483px;
  padding-bottom: 160px;
  padding-top: 60px;
}
.auth-status_choose-way .auth-status__content {
  flex-shrink: 0;
  min-height: 343px;
}
.auth-status__ways-list {
  display: flex;
  position: relative;
  z-index: 30;
}
.auth-status_choose-way .auth-status__ways-list {
  margin-bottom: 120px;
  margin-top: -90px;
}
.auth-status_gosuslugi-fail .auth-status__ways-list {
  margin-bottom: 120px;
  margin-top: -90px;
}
.auth-status_already-registered .auth-status__ways-list {
  margin-bottom: 120px;
  margin-top: -90px;
}
.auth-status__ways-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: flex-start;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: flex-end;
  margin-right: 30px;
  padding: 30px 30px 45px;
  position: relative;
  transition: all 0.3s ease;
  width: 380px;
}
.auth-status__ways-item:hover {
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
}
.auth-status__ways-item:last-child {
  margin-right: 0;
}
.auth-status__ways-item-title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
  margin-bottom: 10px;
  text-align: left;
}
.auth-status__ways-item-desc {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}
.auth-status__ways-item-img {
  position: absolute;
}
.auth-status__ways-item-img_gosuslugi {
  right: 40px;
  top: 40px;
}
.auth-status__ways-item-img_pen {
  height: 30px;
  right: 30px;
  top: 30px;
  width: 24px;
}
.auth-status__form {
  margin-right: 60px;
  max-width: 650px;
  width: 100%;
}
.auth-status__inputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.auth-status__btn-wrap {
  display: flex;
}
.auth-status__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background: var(--dusk-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  width: 190px;
}
.auth-status__btn_disabled {
  background-color: var(--pale-grey);
  color: var(--bluey-grey);
  cursor: default;
}
.auth-status__loader-wrap {
  margin-left: 20px;
  position: relative;
}
.auth-status__password-secure-lock {
  left: 50%;
  margin-left: -6px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
}
.auth-status__password-secure-lock_grey path {
  fill: var(--bluey-grey);
}
.auth-status__password-secure-lock_green path {
  fill: var(--turquoise-blue);
}
.auth-status__password-secure-lock_red path {
  fill: var(--reddish-pink);
}
.auth-status__personal-info {
  margin-bottom: 19px;
  position: relative;
  z-index: 30;
}
.auth-status__personal-info-tabs {
  display: flex;
}
.auth-status__personal-info-tabs-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  cursor: default;
  font-size: 16px;
  margin-right: 30px;
}
.auth-status__personal-info-tabs-item:last-child {
  margin-right: 0;
}
.auth-status__personal-info-tabs-item_active {
  color: var(--dark-slate-blue);
}
.auth-status__personal-info-bar {
  background: rgba(176, 181, 196, 0.2);
  bottom: -6px;
  height: 2px;
  left: 0;
  position: absolute;
}
.auth-status__personal-info-bar-active {
  background-color: var(--dusk-blue);
  border-radius: 1px;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease;
}
.auth-status__personal-info-content {
  position: relative;
  z-index: 30;
}
.auth-status__hint-block {
  position: relative;
  z-index: 10;
}
.auth-status_enter-passport-data .auth-status__hint-block {
  padding-top: 40px;
}
.auth-status__hint-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 16px;
  margin-bottom: 16px;
}
.auth-status__hint-item-wrap {
  margin-bottom: 20px;
}
.auth-status__hint-item-wrap:last-child {
  margin-bottom: 0;
}
.auth-status__hint-item-wrap:only-child {
  margin-bottom: 0;
}
.auth-status__hint-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  border-bottom: 2px dotted var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding-bottom: 4px;
  text-decoration: none;
}
.auth-status__hint-item:hover {
  border-bottom-color: transparent;
}
.auth-status__hint-item-img {
  height: 14px;
  margin-left: 4px;
  vertical-align: middle;
  width: 86px;
}
.auth-status__success .auth-status-result__desc {
  margin-bottom: 60px;
}
.auth-status__fail .auth-status-result__desc {
  margin-bottom: 60px;
}
.auth-status__fail_another-inn.auth-status-result {
  max-width: 717px;
}

@media screen and (max-width: 1100px) {
  .auth-status_enter-passport-data .auth-status__content {
    flex-direction: column;
    min-height: 605px;
  }
  .auth-status_enter-passport-data .auth-status__form {
    margin-bottom: 50px;
    margin-right: 0;
  }
  .auth-status_enter-passport-data .auth-status__hint-block {
    padding-top: 0;
  }
}
@media screen and (max-width: 980px) {
  .auth-status_choose-way {
    padding-bottom: 57px;
  }
  .auth-status__nav {
    margin-bottom: 30px;
  }
  .auth-status_choose-way .auth-status__header {
    min-height: auto;
    padding-bottom: 115px;
    padding-top: 70px;
  }
  .auth-status_enter-passport-data .auth-status__header {
    padding-top: 32px;
  }
  .auth-status_gosuslugi-fail .auth-status__header {
    padding-bottom: 94px;
    padding-top: 68px;
  }
  .auth-status_already-registered .auth-status__header {
    padding-bottom: 94px;
    padding-top: 68px;
  }
  .auth-status_gosuslugi-fail .auth-status__header-title {
    margin-bottom: 20px;
  }
  .auth-status_already-registered .auth-status__header-title {
    margin-bottom: 20px;
  }
  .auth-status_gosuslugi-fail .auth-status__header-desc {
    max-width: 265px;
  }
  .auth-status_already-registered .auth-status__header-desc {
    max-width: 265px;
  }
  .auth-status__header-bg {
    height: 661px;
    margin-top: -320px;
    width: 513px;
  }
  .auth-status_choose-way .auth-status__content {
    padding-top: 30px;
  }
  .auth-status_enter-passport-data .auth-status__content {
    min-height: 770px;
  }
  .auth-status__ways-list {
    display: block;
    margin-bottom: 60px;
  }
  .auth-status_choose-way .auth-status__ways-list {
    margin-top: 0;
  }
  .auth-status_gosuslugi-fail .auth-status__ways-list {
    margin-top: 94px;
  }
  .auth-status_already-registered .auth-status__ways-list {
    margin-top: 94px;
  }
  .auth-status__ways-item {
    height: 150px;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 20px 30px 15px 26px;
  }
  .auth-status__ways-item:last-child {
    margin-bottom: 0;
  }
  .auth-status__ways-item-title {
    margin-bottom: 5px;
  }
  .auth-status__ways-item-desc {
    max-width: 80%;
  }
  .auth-status__ways-item-img {
    position: absolute;
  }
  .auth-status__ways-item-img_gosuslugi {
    height: 40px;
    margin-top: -20px;
    right: 20px;
    top: 50%;
    width: 36px;
  }
  .auth-status__ways-item-img_pen {
    height: 23px;
    margin-top: -11px;
    right: 22px;
    top: 50%;
    width: 23px;
  }
}
@media screen and (max-width: 800px) {
  .auth-status__content {
    min-height: 746px;
  }
  .auth-status_choose-way .auth-status__content {
    min-height: 460px;
  }
}
@media screen and (max-width: 600px) {
  .auth-status_enter-passport-data .auth-status__header {
    min-height: 304px;
  }
  .auth-status_success .auth-status__header {
    height: 200px;
    min-height: 200px;
  }
  .auth-status_waiting .auth-status__header {
    height: 200px;
    min-height: 200px;
  }
  .auth-status_fail .auth-status__header {
    height: 200px;
    min-height: 200px;
  }
  .auth-status_choose-way .auth-status__header-content {
    max-width: 280px;
  }
  .auth-status_success .auth-status__header-content {
    max-width: 280px;
  }
  .auth-status__header-title {
    font-size: 28px;
  }
  .auth-status__header-close {
    right: 20px;
    top: 20px;
  }
  .auth-status__header-bg {
    height: 276px;
    margin-top: 0;
    right: -151px;
    top: 33px;
    width: 271px;
  }
  .auth-status_waiting .auth-status__header-bg {
    margin-top: 0;
    right: -142px;
    top: 22px;
  }
  .auth-status_success .auth-status__header-bg {
    margin-top: 0;
    right: -142px;
    top: 22px;
  }
  .auth-status_fail .auth-status__header-bg {
    margin-top: 0;
    right: -142px;
    top: 22px;
  }
  .auth-status__ways-item {
    width: 100%;
    height: auto;
  }
  .auth-status__success .auth-status-result__desc {
    margin-bottom: 40px;
  }
  .auth-status__fail .auth-status-result__desc {
    margin-bottom: 40px;
  }
}