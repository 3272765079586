.form-select__value-container {
  padding-left: 20px !important;
}
.form-select__input {
  font-family: "Circe-Regular", sans-serif !important;
  font-weight: normal !important;
  color: var(--dark-slate-blue) !important;
  font-size: 18px !important;
  line-height: 24px !important;
}
.form-select__value-container {
  grid-template-columns: 0 auto !important;
}
.form-select__input-container {
  grid-template-columns: 0 auto !important;
}
.form-select__menu {
  z-index: 99999;
}
.form-select__menu-notice {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
}
.form-select__placeholder {
  font-family: "Circe-Regular", sans-serif !important;
  font-weight: normal !important;
  color: #616a89 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}
.form-select__dropdown-indicator {
  color: #616a89 !important;
}
.form-select__dropdown-indicator:hover {
  color: var(--dark-slate-blue) !important;
}
.form-select__option {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
}
.form-select__option--is-focused {
  background-color: var(--white) !important;
  color: var(--dark-slate-blue) !important;
  cursor: pointer !important;
}
.form-select__option--is-selected {
  background-color: var(--pale-grey) !important;
  color: var(--dark-slate-blue) !important;
}
.form-select__control {
  height: 60px;
  border-radius: 8px !important;
  border: solid 1px rgba(157, 166, 186, 0.2) !important;
  background-color: rgba(228, 232, 238, 0.2) !important;
}
.form-select__control--is-focused {
  border-color: var(--dark-slate-blue) !important;
  background: var(--white) !important;
}
.form-select__control--is-disabled .form-select__placeholder {
  color: #9da6ba !important;
}
.form-select__control--is-disabled .form-select__dropdown-indicator {
  display: none !important;
}