.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.check {
  position: relative;
  width: 100%;
}
.checkContainer {
  position: relative;
  padding: 19px;
  border-radius: 6px 6px 1px 1px;
  box-shadow: 0 4px 27px #e8ecf2;
  background-color: #fff;
}
.checkSection {
  padding: 12px 0;
  border-bottom: 1px solid #f6f8fb;
}
.checkTitle {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.checkDateTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
}
.checkDate, .checkTime {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  color: #b0b5c4;
}
.checkPayer, .checkInn, .checkRegime {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
}
.checkPayer {
  color: #1d2e69;
}
.checkInn, .checkRegime {
  color: #b0b5c4;
}
.checkServiceListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkServiceListHeader > span {
  font-family: "Circe-Bold", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.checkServiceListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.checkServiceListItem > span {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.checkTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkTotal > span {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.checkQrCode {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.checkQrCodeCanvas {
  width: 72px;
  height: 72px;
}
.checkWave {
  position: relative;
  width: 100%;
  height: 30px;
  background-image: url("../../../../static/assets/check-triangle.svg");
  background-repeat: repeat-x;
  background-size: 5%;
}

@media (max-width: 600px) {
  .checkWave {
    background-size: 4%;
  }
}