.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment-action-buttons__wrap {
  display: flex;
  justify-content: flex-end;
}
.tax-payment-action-buttons__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: calc(50% - 10px);
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  background: var(--dusk-blue);
  transition: all 0.3s ease;
  margin-right: 20px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-payment-action-buttons__btn:hover {
  background: var(--dark-slate-blue);
}
.tax-payment-action-buttons__btn:disabled {
  box-shadow: none;
  color: #9da6ba;
  background: var(--pale-grey);
  cursor: not-allowed;
}
.tax-payment-action-buttons__btn_back {
  color: var(--grey-blue);
  border: 1px solid var(--grey-blue);
  background: white;
}
.tax-payment-action-buttons__btn_back:hover {
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  background: white;
}
.tax-payment-action-buttons__btn_to-accounting {
  flex: 0 1 215px;
}
.tax-payment-action-buttons__btn:last-child {
  margin-right: 0;
}

@media screen and (max-width: 980px) {
  .tax-payment-action-buttons {
    margin-left: 50px;
    margin-right: 50px;
  }
  .tax-payment-action-buttons__wrap {
    justify-content: flex-start;
    max-width: 420px;
    width: 100%;
    flex-direction: column-reverse;
  }
  .tax-payment-action-buttons__btn {
    border-radius: 30px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .tax-payment-action-buttons__btn_to-accounting {
    flex: 0 1 100%;
    margin-bottom: 0;
  }
  .tax-payment-action-buttons__btn:only-child {
    margin-bottom: 0;
  }
  .tax-payment-action-buttons__btn_back {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment-action-buttons {
    margin-left: 20px;
    margin-right: 20px;
  }
}