.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.user-balance-card {
  width: 100%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  height: 229px;
  padding: 30px 20px 20px 20px;
  border-radius: 6px;
  position: relative;
}
.user-balance-card__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.user-balance-card__top-main {
  display: flex;
  align-items: center;
}
.user-balance-card__avatar-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.user-balance-card__avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-balance-card__profile-status-wrap {
  display: flex;
  align-items: center;
}
.user-balance-card__profile-status-icon {
  margin-right: 10px;
}
.user-balance-card__profile-status-text {
  font-size: 12px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.user-balance-card__status {
  width: 35px;
  height: 35px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-balance-card__status-icon {
  width: 17px;
  height: 15px;
  fill: var(--deep-sky-blue);
}
.user-balance-card__status-icon path {
  fill: var(--deep-sky-blue);
}
.user-balance-card__balance-wrap {
  margin-bottom: 20px;
  padding-left: 11px;
}
.user-balance-card__balance-text {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}
.user-balance-card__balance {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.user-balance-card__balance-rubles {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.user-balance-card__balance-penny {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.user-balance-card__replenish-link-wrap {
  padding-left: 11px;
}
.user-balance-card__replenish-link {
  text-decoration: none;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
}