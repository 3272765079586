.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.check {
  background: white;
  filter: drop-shadow(0 4px 27px #e8ecf2);
  padding: 35px 20px 18px 20px;
  position: relative;
}
.check_canceled {
  text-decoration: line-through;
}
.check * {
  text-decoration: inherit;
}
.check__block {
  border-top: 1px solid var(--pale-grey);
  border-bottom: 1px solid var(--pale-grey);
}
.check__block_top {
  padding-top: 13px;
  padding-bottom: 10px;
}
.check__block_personal-info {
  padding-top: 13px;
  padding-bottom: 10px;
}
.check__block_main {
  padding-top: 15px;
  padding-bottom: 14px;
}
.check__block_sum {
  padding-top: 17px;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check__block_customer {
  padding-top: 15px;
  padding-bottom: 14px;
}
.check__block:first-child {
  border-top: none;
}
.check__sz-block {
  margin: 12px 0;
}
.check__number {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 17px;
}
.check_canceled .check__number {
  color: var(--bluey-grey);
}
.check__date-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check__date {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.check__time {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.check__name {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 7px;
}
.check_canceled .check__name {
  color: var(--bluey-grey);
}
.check__inn {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 7px;
}
.check__sz-block .check__inn:last-child {
  margin-bottom: 0;
}
.check__block_customer .check__inn:last-child {
  margin-bottom: 0;
}
.check__regime {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.check__block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  width: 100%;
}
.check__block_customer .check__block-header {
  margin-bottom: 12px;
}
.check__block-header-title {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.check_canceled .check__block-header-title {
  color: var(--bluey-grey);
}
.check__block-content-item {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.check__block-content-item-title {
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  width: 60%;
  min-width: 60%;
  line-height: 1.2;
  word-break: break-all;
}
.check_canceled .check__block-content-item-title {
  color: var(--bluey-grey);
}
.check__block-content-item-sum {
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  width: 40%;
  min-width: 40%;
  text-align: right;
}
.check_canceled .check__block-content-item-sum {
  color: var(--bluey-grey);
}
.check__sum-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.check__sum-text_sum-title {
  font-weight: 700;
}
.check_canceled .check__sum-text {
  color: var(--bluey-grey);
}
.check__customer-name {
  display: block;
  padding-top: 7px;
}
.check__qr {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  background: var(--dark-slate-blue);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.check__qr-inner {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}
.check__qr-inner canvas {
  max-width: 100% !important;
  max-height: 100% !important;
}
.check__bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: -10px;
  height: 28px;
}
.check__bg-square {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: white;
  transform: rotate(45deg);
}
.check__close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.check__close-icon {
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 800px) {
  .check {
    padding-top: 65px;
  }
}