.money-transfer {
  position: fixed;
  display: flex;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.money-transfer__wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 50px 100px;
  height: 100%;
}
.money-transfer__loader {
  width: 100%;
  height: 100%;
}
.money-transfer__error {
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 52px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-slate-blue);
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe", sans-serif;
  font-weight: 400;
}
.money-transfer__error-img {
  width: 40px;
  height: 46px;
}
.money-transfer__error-text {
  max-width: 330px;
}
.money-transfer__error-text-support {
  text-decoration: underline;
  text-underline-offset: 4px;
}

@media (max-width: 980px) {
  .money-transfer__wrapper {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}