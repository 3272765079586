.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-status-error-card__card {
  box-shadow: 0 4px 27px #e8ecf2;
}
.money-transfer-status-error-card__content {
  padding: 30px 20px 32px;
}
.money-transfer-status-error-card__content-loader {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 auto 16px;
}
.money-transfer-status-error-card__content-loader-circle {
  width: 60px;
  height: 60px;
}
.money-transfer-status-error-card__content-loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 27px;
  height: 22px;
  margin-top: -11px;
  margin-left: -13px;
}
.money-transfer-status-error-card__content-loader-icon path {
  fill: var(--reddish-pink);
}
.money-transfer-status-error-card__content-message {
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-status-error-card__nav {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.money-transfer-status-error-card__nav-lk-button, .money-transfer-status-error-card__nav-retry-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  transition: all 0.3s ease;
  font-size: 16px;
  color: #fff;
}
.money-transfer-status-error-card__nav-lk-button {
  border: 1px solid var(--grey-blue);
  background-color: #fff;
  color: var(--grey-blue);
}
.money-transfer-status-error-card__nav-lk-button:hover {
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.money-transfer-status-error-card__nav-retry-button {
  background-color: var(--dusk-blue);
}
.money-transfer-status-error-card__nav-retry-button:hover {
  background-color: var(--dark-slate-blue);
}
.money-transfer-status-error-card__nav-retry-button:disabled {
  background-color: rgba(40, 65, 149, 0.1);
  cursor: not-allowed;
}

@media (max-width: 980px) {
  .money-transfer-status-error-card__nav {
    flex-direction: column-reverse;
  }
  .money-transfer-status-error-card__nav-lk-button, .money-transfer-status-error-card__nav-retry-button {
    width: 100%;
    border-radius: 30px;
  }
  .money-transfer-status-error-card__nav-lk-button {
    margin-top: 20px;
  }
}