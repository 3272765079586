.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-form {
  width: 100%;
}
.show-case-form__tabs {
  margin-bottom: 40px;
}
.show-case-form__block {
  margin-bottom: 42px;
}
.show-case-form__block_info {
  margin-bottom: 40px;
}
.show-case-form__form-nav {
  margin-bottom: 20px;
}
.show-case-form__block-title {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 14px;
}
.show-case-form__block-text {
  font-size: 16px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.2;
  margin-bottom: 20px;
}
.show-case-form__checkbox-list {
  display: flex;
  flex-wrap: wrap;
}
.show-case-form__checkbox-wrap {
  margin-top: 12px;
}
.show-case-form__checkbox-list .show-case-form__checkbox-wrap {
  width: auto;
  margin-right: 33px;
}
.show-case-form__checkbox-list .show-case-form__checkbox-wrap:last-child {
  margin-right: 0;
}
.show-case-form__button-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.show-case-form__button {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .show-case-form__checkbox-list .show-case-form__checkbox-wrap {
    margin-right: 35px;
  }
}