.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-list-container {
  display: flex;
  flex-grow: 20;
}
.tax-list-container__left-part {
  width: 370px;
  min-width: 370px;
  margin-right: 30px;
  padding-top: 0;
  transition: padding 0.3s ease-in;
  position: relative;
}
.tax-list-container__left-part_notice-message {
  padding-top: 140px;
}
.tax-list-container__warning {
  margin-bottom: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.tax-list-container__total {
  margin-bottom: 40px;
  position: relative;
  z-index: 20;
}
.tax-list-container__months {
  position: relative;
  z-index: 10;
}
.tax-list-container__months-title {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 20px;
}
.tax-list-container__months-list {
  padding-right: 5px;
}
.tax-list-container__months-item {
  margin-bottom: 20px;
}
.tax-list-container__months-item:last-child {
  margin-bottom: 0;
}
.tax-list-container__months-item_inner-page {
  cursor: default;
}
.tax-list-container__months-item_inner-page:last-child {
  margin-bottom: 30px;
}
.tax-list-container__table-wrap {
  width: calc(100% - 370px - 30px);
}
.tax-list-container__table {
  margin-bottom: 20px;
}
.tax-list-container__table-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.tax-list-container__table-bottom-notification {
  font-size: 14px;
  line-height: 20px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 70px;
}
.tax-list-container__table-bottom-button {
  width: 270px;
  min-width: 270px;
}
.tax-list-container__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: -7px;
}
.tax-list-container__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--pale-grey);
  position: absolute;
}
.tax-list-container__button {
  width: 100%;
  margin-bottom: 20px;
}
.tax-list-container__button:last-child {
  margin-bottom: 0;
}
.tax-list-container__button:only-child {
  margin-bottom: 0;
}
.tax-list-container__no-taxes {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1149px) {
  .tax-list-container__left-part {
    width: 300px;
    min-width: 300px;
  }
  .tax-list-container__left-part_notice-message {
    padding-top: 160px;
  }
  .tax-list-container__table-wrap {
    width: calc(100% - 300px - 30px);
  }
}
@media screen and (max-width: 980px) {
  .tax-list-container {
    flex-direction: column;
  }
  .tax-list-container__left-part {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .tax-list-container__table-wrap {
    width: 100%;
    margin-bottom: 40px;
  }
  .tax-list-container__table-bottom {
    display: block;
    margin-top: 20px;
  }
  .tax-list-container__table-bottom-notification {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .tax-list-container__button-list .tax-list-container__table-bottom-button {
    width: 100%;
    margin-bottom: 20px;
  }
  .tax-list-container__no-taxes {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 200;
    position: relative;
  }
  .tax-list-container__no-taxes-return-button {
    max-width: 335px;
    width: 100%;
  }
  .tax-list-container__message {
    display: none;
  }
}