.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-registration-card {
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  background-color: white;
  min-height: 390px;
  display: flex;
  flex-direction: column;
}
.income-registration-card__header {
  border-radius: 14px 14px 0 0;
  padding: 29px 30px;
  min-height: 118px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
}
.income-registration-card__header-icon-wrap {
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.income-registration-card__header-icon {
  fill: var(--deep-sky-blue);
  width: 29px;
  height: 26px;
}
.income-registration-card__header-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: white;
  margin-left: 30px;
}
.income-registration-card__content {
  padding: 30px 30px 30px 30px;
  display: flex;
  flex-grow: 20;
}
.income-registration-card__content_error {
  flex-direction: column;
  padding-top: 0;
}
.income-registration-card__left-part {
  width: 52%;
  min-width: 52%;
  margin-right: 20px;
  flex-grow: 20;
}
.income-registration-card__input-holder {
  margin-bottom: 22px;
  position: relative;
}
.income-registration-card__input-holder:last-child {
  margin-bottom: 0;
}
.income-registration-card__input-holder_hide-long-text:after {
  content: "";
  position: absolute;
  right: 34px;
  top: 1px;
  bottom: 1px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(250, 251, 252) 100%);
  width: 70px;
  z-index: 10;
  pointer-events: none;
  transition: all 0.3s ease;
}
.income-registration-card__input-holder_hide-long-text:focus-within:after {
  opacity: 0;
}
.income-registration-card__input {
  height: 60px;
  border-radius: 8px;
  background-color: rgba(228, 232, 238, 0.2);
  width: 100%;
  border: 1px solid rgba(228, 232, 238, 0.2);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 10px 34px 0 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.income-registration-card__input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input::-ms-clear {
  display: none;
}
.income-registration-card__input:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.income-registration-card__input_error {
  border-color: var(--reddish-pink);
}
.income-registration-card__input_error:focus {
  border-color: var(--reddish-pink);
}
.income-registration-card__input[type=number]::-webkit-outer-spin-button, .income-registration-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.income-registration-card__input[type=number] {
  -moz-appearance: textfield;
}
.income-registration-card__input-label {
  font-size: 8px;
  text-transform: uppercase;
  color: #6b778d;
  position: absolute;
  left: 20px;
  top: 7px;
  font-family: "Circe-Bold", sans-serif;
}
.income-registration-card__input-check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.income-registration-card__input-check path {
  stroke: var(--dark-slate-blue);
}
.income-registration-card__input-error {
  position: absolute;
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
  font-size: 12px;
  top: 100%;
  padding: 5px 5px 0;
  left: 0;
}
.income-registration-card__textarea {
  height: 117px;
  border-radius: 8px;
  background-color: rgba(228, 232, 238, 0.2);
  width: 100%;
  border: 1px solid rgba(228, 232, 238, 0.2);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 29px 20px 10px 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.income-registration-card__textarea::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea::-ms-clear {
  display: none;
}
.income-registration-card__textarea:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.income-registration-card__calendar-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  pointer-events: none;
}
.income-registration-card__right-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 20;
  width: 100%;
}
.income-registration-card__select {
  margin-bottom: 20px;
}
.income-registration-card__tip {
  line-height: 1.71;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
}
.income-registration-card__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
}
.income-registration-card__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.income-registration-card__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.income-registration-card__button_again {
  width: auto;
  padding: 0 100px;
}
.income-registration-card__fail {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.income-registration-card__fail-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: var(--dark-slate-blue);
  margin-bottom: 30px;
  max-width: 408px;
  margin: 0 auto 30px;
}
.income-registration-card__fail-text button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  color: var(--dark-slate-blue);
  text-decoration: underline;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.income-registration-card__fail-text button:hover {
  text-decoration: none;
}
.income-registration-card__fail-icon-wrap {
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  position: relative;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 50%;
  background: white;
}
.income-registration-card__fail-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
}
.income-registration-card__fail-icon {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -7px;
}
.income-registration-card__fail-icon path {
  fill: var(--reddish-pink);
}
.income-registration-card__check-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #616a88;
  margin-bottom: 40px;
}

@media screen and (max-width: 980px) {
  .income-registration-card {
    border-radius: 0;
    box-shadow: none;
    min-height: auto;
    background: transparent;
    overflow: visible;
  }
  .income-registration-card__content {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
  .income-registration-card__content_error {
    flex-direction: column;
    padding-top: 0;
    margin-top: -45px;
  }
  .income-registration-card__left-part {
    order: 20;
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .income-registration-card__right-part {
    order: 10;
  }
  .income-registration-card__input-holder {
    margin-bottom: 20px;
  }
  .income-registration-card__textarea {
    height: 60px;
    padding-top: 24px;
  }
  .income-registration-card__right-part {
    justify-content: flex-start;
    margin-bottom: 36px;
  }
  .income-registration-card__button {
    border-radius: 30px;
    box-shadow: 0 4px 27px 0 #e8ecf2;
    font-size: 16px;
    font-family: "Circe-Bold", sans-serif;
  }
}
@media screen and (max-width: 400px) {
  .income-registration-card__button_again {
    width: 100%;
    padding: 0;
  }
}