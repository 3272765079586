.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.rdrDateRangeWrapper {
  width: 100%;
}

.rdrMonthAndYearWrapper {
  display: none !important;
}

.rdrMonthName {
  display: none !important;
}

.rdrMonth {
  width: 50% !important;
  padding: 0 !important;
}

.rdrWeekDay {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  height: 40px !important;
  text-transform: capitalize;
  font-size: 12px;
  color: #b0b5c4;
}
.rdrWeekDay:nth-child(6) {
  color: rgba(255, 45, 85, 0.5);
}
.rdrWeekDay:last-child {
  color: rgba(255, 45, 85, 0.5);
}

.rdrDay {
  height: 40px !important;
}

.rdrDayNumber {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rdrDayNumber span {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: rgba(97, 106, 136, 0.8);
}

.rdrInRange {
  background: rgba(0, 122, 255, 0.05) !important;
  top: 0 !important;
  bottom: 0 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #1d2e69 !important;
}

.rdrDayEndOfWeek .rdrInRange,
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfWeek .rdrInRange {
  border-radius: 0 !important;
}

.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-radius: 0 !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-radius: 0 !important;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfWeek .rdrInRange {
  border-radius: 0 !important;
}

.rdrDayEndOfWeek .rdrInRange {
  border-radius: 0 !important;
}

.rdrDayStartPreview {
  border-radius: 0 !important;
}

.rdrDayEndPreview {
  border-radius: 0 !important;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  display: none;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: rgba(0, 122, 255, 0.05);
}

.rdrStartEdge,
.rdrEndEdge {
  border-radius: 4px !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.rdrStartEdge ~ .rdrDayStartPreview,
.rdrEndEdge ~ .rdrDayEndPreview {
  display: none;
}

@media (max-width: 600px) {
  .rdrMonth {
    width: 100% !important;
  }
}