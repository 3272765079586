.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes-income-card {
  width: 100%;
  padding: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 280px;
}
.taxes-income-card__title {
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 16px;
}
.taxes-income-card__month-income {
  font-size: 40px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.taxes-income-card__list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.taxes-income-card__item {
  width: 50%;
  min-width: 50%;
}
.taxes-income-card__item:nth-child(2n) {
  text-align: right;
}
.taxes-income-card__item-title {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.taxes-income-card__item-sum {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.taxes-income-card__item_limit .taxes-income-card__item-sum {
  color: var(--turquoise-blue);
}
.taxes-income-card__item_limit-is-near .taxes-income-card__item-sum {
  color: var(--reddish-pink);
}
.taxes-income-card__item:nth-child(2) .taxes-income-card__item-sum {
  display: flex;
  justify-content: flex-end;
}
.taxes-income-card__limit-wrap {
  display: flex;
  flex-wrap: wrap;
}
.taxes-income-card__limit-title {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 2px;
}
.taxes-income-card__limit-sum {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
}
.taxes-income-card__error-message {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--reddish-pink);
}

@media screen and (max-width: 340px) {
  .taxes-income-card {
    padding: 30px 15px;
  }
}