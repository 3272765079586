.apple-pay-button {
  height: 100%;
  width: 100%;
  position: relative;
}
.apple-pay-button:disabled:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    display: inline-block;
    -apple-pay-button-style: black;
    position: relative;
  }
  .apple-pay-button:disabled {
    cursor: not-allowed;
  }
  .apple-pay-button:disabled:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    background-color: black;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 60%;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    padding: 0;
  }
  .apple-pay-button:disabled {
    background-color: rgba(29, 46, 105, 0.1);
    cursor: not-allowed;
  }
}