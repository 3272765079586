.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-header {
  margin-bottom: 50px;
}
.money-transfer-header_desktop, .money-transfer-header_mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.money-transfer-header_mobile {
  display: none;
  height: 90px;
}
.money-transfer-header .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.money-transfer-header__link {
  display: flex;
  width: 39px;
  height: 45px;
  text-decoration: none;
}
.money-transfer-header__logo {
  width: 130px;
  height: 45px;
}
.money-transfer-header__burger-menu-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
}
.money-transfer-header__burger-menu-button__inner {
  position: absolute;
  top: 10px;
  right: 5px;
  bottom: 10px;
  left: 5px;
}
.money-transfer-header__burger-menu-button__line {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3px;
  transition: all 0.3s ease;
}
.money-transfer-header__burger-menu-button__line path {
  transition: all 0.3s ease;
}
.money-transfer-header__burger-menu-button__line:first-child {
  animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-transfer-header__burger-menu-button__line:nth-child(2) {
  animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.money-transfer-header__burger-menu-button__line:nth-child(3) {
  animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-transfer-header__burger-menu-button_pressed .money-transfer-header__burger-menu-button__line:first-child {
  animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-transfer-header__burger-menu-button_pressed .money-transfer-header__burger-menu-button__line:nth-child(2) {
  animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-transfer-header__burger-menu-button_pressed .money-transfer-header__burger-menu-button__line:nth-child(3) {
  animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}

@media (max-width: 980px) {
  .money-transfer-header {
    margin-bottom: 17px;
  }
  .money-transfer-header_desktop {
    display: none;
  }
  .money-transfer-header_mobile {
    display: flex;
  }
}