.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.history-requests-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  padding: 6px 25px 6px 30px;
  transition: all 0.3s ease;
  position: relative;
}
.history-requests-item:hover {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.history-requests-item_open {
  background: rgba(246, 248, 251, 0.56);
}
.history-requests-item__top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history-requests-item__title-wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 460px);
}
.history-requests-item_open .history-requests-item__title-wrap {
  align-items: flex-start;
}
.history-requests-item__icon {
  margin-right: 21px;
}
.history-requests-item__icon_coins {
  width: 16px;
  height: 16px;
}
.history-requests-item__icon_coins path {
  fill: var(--bluey-grey);
}
.history-requests-item__icon_bill path {
  fill: var(--bluey-grey);
}
.history-requests-item_open .history-requests-item__icon path {
  fill: var(--dark-slate-blue);
}
.history-requests-item__title {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: left;
  transition: all 0.3s ease-in-out;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.history-requests-item:hover .history-requests-item__title {
  color: var(--dark-slate-blue);
}
.history-requests-item_open .history-requests-item__title {
  color: var(--dark-slate-blue);
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
  word-break: break-all;
}
.history-requests-item__payment-status {
  width: 100px;
  min-width: 100px;
  height: 20px;
  line-height: 18px;
  border-radius: 10px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: center;
  border: 1px solid var(--bluey-grey);
  color: var(--bluey-grey);
}
.history-requests-item_payed .history-requests-item__payment-status {
  border-color: rgba(0, 173, 181, 0.1);
  background: rgba(0, 173, 181, 0.1);
  color: var(--turquoise-blue);
}
.history-requests-item__date {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 150px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.history-requests-item:hover .history-requests-item__date {
  color: var(--dark-slate-blue);
}
.history-requests-item_open .history-requests-item__date {
  color: var(--dark-slate-blue);
}
.history-requests-item__sum {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 80px;
  width: 100%;
  text-align: right;
  color: var(--bluey-grey);
  white-space: nowrap;
}
.history-requests-item_payed .history-requests-item__sum {
  color: var(--turquoise-blue);
}
.history-requests-item__bottom {
  display: flex;
  justify-content: space-between;
  padding-left: 36px;
  padding-top: 10px;
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.history-requests-item_open .history-requests-item__bottom {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.history-requests-item__message-wrap {
  text-align: left;
}
.history-requests-item__message-title {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--bluey-grey);
  margin-right: 5px;
}
.history-requests-item__message-text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--grey-blue);
}
.history-requests-item__message-text_url {
  text-decoration: none;
}
.history-requests-item__message-text_url:hover {
  text-decoration: underline;
}
.history-requests-item__download-bill-link {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.58;
}
.history-requests-item__download-bill-link:hover {
  text-decoration: none;
}
.history-requests-item__copy-success {
  text-align: center;
  background: rgba(0, 173, 181, 0.1);
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 3px;
  border: 1px solid rgba(0, 173, 181, 0.1);
  margin-left: 4px;
  font-size: 10px;
  color: var(--turquoise-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 980px) {
  .history-requests-item__title-wrap {
    width: 70%;
    min-width: 70%;
    padding-right: 10px;
    flex-wrap: wrap;
  }
  .history-requests-item__title {
    font-size: 16px;
    width: calc(100% - 16px - 20px);
  }
  .history-requests-item__right-part {
    width: 30%;
    min-width: 30%;
    text-align: right;
  }
  .history-requests-item__sum {
    max-width: 100%;
    font-size: 20px;
  }
  .history-requests-item__date {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    padding-left: 36px;
  }
  .history-requests-item_open .history-requests-item__date {
    color: var(--bluey-grey);
  }
  .history-requests-item:hover .history-requests-item__date {
    color: var(--bluey-grey);
  }
  .history-requests-item__payment-status {
    border-color: transparent;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
    width: auto;
    text-align: right;
    background: transparent;
  }
  .history-requests-item_open .history-requests-item__payment-status {
    width: 100px;
    border-color: var(--bluey-grey);
    text-align: center;
  }
  .history-requests-item_payed .history-requests-item__payment-status {
    background: transparent;
    border-color: transparent;
  }
  .history-requests-item_payed.history-requests-item_open .history-requests-item__payment-status {
    border-color: rgba(0, 173, 181, 0.1);
    background: rgba(0, 173, 181, 0.1);
    text-align: center;
    width: 100px;
  }
  .history-requests-item__bottom {
    display: block;
    text-align: left;
  }
  .history-requests-item__additional-info {
    margin-bottom: 12px;
  }
  .history-requests-item__download-bill-link {
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .history-requests-item {
    padding: 10px 5px;
  }
  .history-requests-item__title-wrap {
    width: 60%;
    min-width: 60%;
  }
  .history-requests-item__icon {
    margin-right: 10px;
  }
  .history-requests-item__date {
    padding-left: 25px;
  }
  .history-requests-item__right-part {
    width: 40%;
    min-width: 40%;
  }
  .history-requests-item__bottom {
    padding-left: 25px;
  }
}