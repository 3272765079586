.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.shops-dropdown {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 8px;
  width: 100%;
  position: relative;
}
.shops-dropdown__main {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
}
.shops-dropdown__add-new {
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  background: white;
  transition: background 0.3s ease-in-out;
}
.shops-dropdown__add-new:hover {
  background: var(--pale-grey);
}
.shops-dropdown__add-new-icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 12px;
  border: 1px dashed #9da6ba;
  border-radius: 50%;
  position: relative;
}
.shops-dropdown__add-new-icon:before {
  content: "";
  width: 1px;
  height: 14px;
  position: absolute;
  background: #9da6ba;
  top: 50%;
  margin-top: -7px;
  left: 50%;
  margin-left: -1px;
}
.shops-dropdown__add-new-icon:after {
  content: "";
  width: 14px;
  height: 1px;
  position: absolute;
  background: #9da6ba;
  top: 50%;
  left: 50%;
  margin-left: -7px;
}
.shops-dropdown__add-new-text {
  color: #9da6ba;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.shops-dropdown__add-new-arrow {
  height: 12px;
  width: 6px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -6px;
}
.shops-dropdown__add-new-arrow path {
  stroke: var(--light-grey-blue);
}
.shops-dropdown__list {
  border-radius: 0 0 8px 8px;
}

@media screen and (max-width: 380px) {
  .shops-dropdown__add-new {
    padding-left: 10px;
    padding-right: 10px;
  }
}