.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.menu {
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 60;
}
.menu__header {
  flex-shrink: 0;
  margin-bottom: 95px;
}
.menu__container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
}
.menu__container_app {
  margin-top: auto;
  padding-bottom: 100px;
  padding-top: 20px;
}
.menu__content {
  flex-grow: 100;
  padding-left: 70px;
}
.menu__main-nav {
  margin-bottom: 80px;
}
.menu__main-nav-item {
  margin-bottom: 20px;
}
.menu__main-nav-item:last-child {
  margin-bottom: 0;
}
.menu__main-nav-item-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  color: var(--dark-slate-blue);
  font-size: 28px;
  text-decoration: none;
}
.menu__main-nav-item-link:hover {
  color: var(--light-grey-blue);
}
.menu__main-nav-item-link::after {
  background-color: var(--reddish-pink);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 5px;
  margin-left: 5px;
  opacity: 0;
  position: relative;
  top: -14px;
  width: 5px;
}
.menu__main-nav-item-link_has-notifications::after {
  opacity: 1;
}
.menu__sub-nav-item {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  margin-bottom: 22px;
}
.menu__sub-nav-item:last-child {
  margin-bottom: 0;
}
.menu__sub-nav-item-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 18px;
  text-decoration: none;
}
.menu__sub-nav-item-link:hover {
  color: var(--dark-slate-blue);
}
.menu__user {
  align-items: center;
  background-color: var(--pale-grey);
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  min-height: 120px;
  padding: 30px 50px;
  text-decoration: none;
}
.menu__user-main {
  align-items: center;
  display: flex;
}
.menu__user-avatar-wrap {
  border-radius: 50%;
  height: 60px;
  margin-right: 20px;
  overflow: hidden;
  width: 60px;
}
.menu__user-avatar {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}
.menu__user-balance {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
  margin-bottom: 16px;
}
.menu__user-balance-rubles {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
}
.menu__user-balance-penny {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
}
.menu__user-profile-status {
  align-items: center;
  display: flex;
}
.menu__user-profile-status-icon {
  margin-right: 6px;
}
.menu__user-profile-status-icon_auth-status path {
  fill: var(--turquoise-blue);
}
.menu__user-profile-status-icon_test {
  margin-top: -2px;
}
.menu__user-profile-status-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 12px;
}
.menu__user-business-link-block {
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.menu__user-business-link-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 16px;
  margin-bottom: 3px;
}
.menu__user-business-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 12px;
  margin-bottom: 12px;
  text-decoration: underline;
}
.menu__user-status {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(176, 181, 196, 0.1);
  border-radius: 35px;
  color: var(--grey-blue);
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  min-width: 238px;
  text-align: center;
  text-decoration: none;
  width: 70%;
}
.menu__user-status_self-employed {
  background: rgba(0, 173, 181, 0.1);
  color: #21929a;
}
.menu__user-business-link-inner {
  align-items: center;
  display: flex;
  justify-content: center;
}
.menu__icon-copy {
  height: 14px;
  margin-right: 10px;
  width: 12px;
}
.menu__label-copy {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: #00adb5;
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  padding: 0 12px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -2px;
}
.menu__user-business-link-descr {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #9da6ba;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 1.33;
}
.menu__user-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.menu__user-btn-icon {
  transform: rotate(-90deg);
}

@media screen and (max-height: 600px) {
  .menu {
    height: auto;
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 50px;
    padding-top: 50px;
    position: absolute;
  }
  .menu__content {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 1194px) {
  .menu {
    overflow: scroll;
    overflow-x: hidden;
  }
  .menu__content {
    padding-left: 0;
  }
  .menu__app {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .menu__header {
    margin-bottom: 0;
  }
  .menu_non-auth .menu__header {
    margin-bottom: 30px;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
}
@media screen and (max-width: 800px) {
  .menu {
    height: auto;
    padding-top: 0;
  }
  .menu_responsive-in-profile {
    top: 0;
  }
  .menu_on-getting-auth {
    top: 0;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
}
@media screen and (max-width: 700px) {
  .menu {
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    right: 0;
    /*    &_responsive-in-profile {
      padding-top: 70px;
    }
    &_on-getting-auth {
      padding-top: 70px;
    }*/
  }
  .menu__container_app {
    padding-bottom: 50px;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
  .menu__content {
    padding-left: 0;
  }
  .menu_non-auth .menu__content {
    padding-top: 50px;
  }
  .menu__sub-nav-item_exit {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .menu__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
  .menu__user {
    padding: 30px 20px;
  }
}