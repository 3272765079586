.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-request-link {
  background: white;
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  position: relative;
  width: 600px;
}
.money-request-link__header {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  border-radius: 14px 14px 0 0;
  min-height: 121px;
  overflow: hidden;
  padding: 16px;
  position: relative;
}
.money-request-link__header_menu-open {
  background: white;
}
.money-request-link__header-bg {
  position: absolute;
  right: -52px;
  top: -120px;
  width: 254px;
  z-index: 20;
}
.money-request-link__header-logo {
  left: 50%;
  margin-left: -65px;
  position: absolute;
  top: 76px;
  z-index: 40;
}
.money-request-link__top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 80;
  height: 30px;
}
.money-request-link__top-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.money-request-link__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  transform: rotate(180deg);
  flex-direction: row-reverse;
  gap: 10px;
}
.money-request-link__back-button-text {
  transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
@media (min-width: 981px) {
  .money-request-link__whiteLogoWrap {
    display: none;
  }
}
.money-request-link__whiteLogoWrap svg {
  z-index: 100;
  height: 40px;
  width: 40px;
}
.money-request-link__back-button-icon {
  height: 23px;
  width: 13px;
}
.money-request-link__back-button-icon_menu-open path {
  stroke: var(--dark-slate-blue);
}
.money-request-link__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  margin-left: 26px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.money-request-link__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.money-request-link__burger-span path {
  fill: white;
  transition: all 0.3s ease;
}
.money-request-link__burger-span:first-child {
  animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-link__burger-span:nth-child(2) {
  animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.money-request-link__burger-span:nth-child(3) {
  animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:first-child {
  animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(2) {
  animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(3) {
  animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.money-request-link__content {
  padding-bottom: 25px;
  position: relative;
  z-index: 40;
}
.money-request-link__content-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
  margin-bottom: 14px;
  text-align: center;
}
.money-request-link__avatar-wrap {
  border: 2px solid white;
  border-radius: 50%;
  height: 90px;
  margin: -45px auto 20px auto;
  overflow: hidden;
  position: relative;
  width: 90px;
}
.money-request-link__avatar-wrap_money-request-link-to-pay {
  margin-bottom: 20px;
}
.money-request-link__avatar {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.money-request-link__card {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
  width: 100%;
}
.money-request-link__info-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-bottom: 11px;
  text-align: center;
}
.money-request-link__info-purpose {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: var(--pale-grey);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 14px;
  line-height: 33px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  padding: 0 15px;
  text-align: center;
  width: 100%;
  word-break: break-all;
}
.money-request-link__info-sum {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
}
.money-request-link__bill {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 291px;
}
.money-request-link__btn-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  width: 100%;
}
.money-request-link__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  transition: all 0.3s ease;
  width: 320px;
}
.money-request-link__btn:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.money-request-link__btn:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.money-request-link__attention-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  text-align: center;
}
.money-request-link__iframe-wrap {
  height: 370px;
  position: relative;
}
.money-request-link__iframe-wrap-sbp {
  height: 690px;
  position: relative;
  margin-bottom: 0px;
}
.money-request-link__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.money-request-link__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}
.money-request-link__translate-to-ru {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media screen and (max-width: 980px) {
  .money-request-link {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }
  .money-request-link__header {
    border-radius: 0;
    min-height: 98px;
    height: 98px;
    padding: 20px 50px;
  }
  .money-request-link__back-button {
    width: 30px;
  }
  .money-request-link__avatar-wrap {
    margin-bottom: 10px;
  }
  .money-request-link__content {
    padding-bottom: 0;
  }
  .money-request-link__btn-wrap {
    margin-bottom: 0;
  }
  .money-request-link__btn {
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 335px;
    width: 100%;
  }
  .money-request-link__attention-text {
    margin-bottom: 37px;
  }
  .money-request-link__translate-to-ru {
    position: static;
    margin-right: 10px;
  }
}
@media screen and (max-width: 600px) {
  .money-request-link__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .money-request-link__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .money-request-link__iframe-wrap-sbp {
    height: 710px;
  }
}
@media screen and (max-width: 350px) {
  .money-request-link__header {
    padding-left: 14px;
    padding-right: 14px;
  }
  .money-request-link__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}