.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.dialog {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 480px) {
  .dialog {
    display: block;
    background-color: #fff;
  }
}
.dialogBg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #0a1125;
}
@media (max-width: 480px) {
  .dialogBg {
    display: none;
  }
}
.dialogContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 480px) {
  .dialogContainer {
    flex-direction: column;
  }
}
.dialogWrapper {
  position: relative;
  width: 553px;
  margin: auto;
  padding: 0 75px 60px 75px;
  border-radius: 10px;
  box-shadow: 0 4px 27px rgba(125, 133, 148, 0.16);
  background-color: #fff;
}
@media (max-width: 480px) {
  .dialogWrapper {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 0;
    box-shadow: none;
  }
}
.dialogMobileHeader, .dialogMobileFooter {
  display: none;
}
@media (max-width: 480px) {
  .dialogMobileHeader {
    display: block;
  }
}
@media (max-width: 480px) {
  .dialogMobileFooter {
    display: flex;
  }
}
.dialogCloseButton {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
  width: 24px;
  height: 24px;
}
@media (max-width: 480px) {
  .dialogCloseButton {
    display: none;
  }
}
.dialogCloseButtonIcon {
  width: 100%;
  height: 100%;
}
.dialogCloseButtonIcon path {
  fill: var(--dark-slate-blue);
}