.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.password-recovery {
  display: flex;
  flex-grow: 100;
  position: relative;
  z-index: 20;
  padding-top: 20vh;
}
.password-recovery__content {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__title {
  font-size: 36px;
  text-align: center;
  color: var(--dark-slate-blue);
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 20px;
}
.password-recovery__form {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__info-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 400px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #b0b5c4;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__input-holder {
  margin-bottom: 30px;
  position: relative;
}
.password-recovery__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  text-align: center;
  color: var(--dark-slate-blue);
  font-size: 18px;
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
}
.password-recovery__input::-webkit-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input::-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input:-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input:-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input::-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input::-ms-clear {
  display: none;
}
.password-recovery__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.password-recovery__input_disabled {
  background: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  color: #b0b5c4;
  box-shadow: none;
}
.password-recovery__input_success {
  border: solid 1px var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.password-recovery__input_fail {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  box-shadow: none;
}
.password-recovery__error-wrap {
  text-align: center;
  margin-bottom: 20px;
}
.password-recovery__error-message {
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  display: inline;
}
.password-recovery__btn-wrap {
  display: flex;
  justify-content: center;
}
.password-recovery__button {
  width: 240px;
}
.password-recovery__code-block .code-block__title {
  text-align: center;
}
.password-recovery__code-block .code-block__block {
  justify-content: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__code-block .code-block__tip {
  text-align: center;
}
.password-recovery__code-block .code-block__error-wrap {
  text-align: center;
}
.password-recovery__code-block .code-block__small-loader {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -11px;
}
.password-recovery__code-block .code-block__input-check {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
}

@media screen and (max-height: 600px) {
  .password-recovery {
    padding-top: 13.5%;
    padding-bottom: 13.5%;
  }
}
@media screen and (max-width: 700px) {
  .password-recovery {
    padding-top: 50px;
    padding-bottom: 200px;
    align-items: flex-start;
  }
}