.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.partnerNav {
  display: flex;
  width: 100%;
  margin-top: 42px;
}
.partnerNavLink {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  border: 0 solid transparent;
  text-decoration: none;
  line-height: 24px;
  font-size: 16px;
  color: #b0b5c4;
}
.partnerNavLink:first-child {
  margin-left: 0;
}
.partnerNavLinkActive {
  border-bottom: 2px dotted #1d2e69;
  color: #1d2e69;
}

@media (max-width: 980px) {
  .partnerNav {
    width: calc(100% + 40px);
    height: 60px;
    margin-top: 12px;
    margin-left: -20px;
    border-bottom: 1px solid rgba(176, 181, 196, 0.5);
  }
  .partnerNavLink {
    width: 33.3333333333%;
    height: 100%;
    margin-left: 0;
  }
  .partnerNavLinkActive {
    border-bottom: 3px solid #1d2e69;
  }
}