.circle-progress {
  transform: rotate(-90deg);

  &__meter {
    fill: none;
    //stroke: #e6e6e6;
    opacity: 0.1;
  }

  &__value {
    fill: none;
    stroke: #f77a52;
    stroke-linecap: round;
  }
}
