.form-select {
  &__value-container {
    padding-left: 20px !important;
  }
  &__input {
    font-family: 'Circe-Regular', sans-serif !important;
    font-weight: normal !important;
    color: var(--dark-slate-blue) !important;
    font-size: 18px !important;
    line-height: 24px !important;
  }
  &__value-container {
    grid-template-columns: 0 auto !important;
  }
  &__input-container {
    grid-template-columns: 0 auto !important;
  }
  &__menu {
    z-index: 99999;
  }
  &__menu-notice {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Circe-Regular', sans-serif;
    color: var(--grey-blue);
  }
  &__placeholder {
    font-family: 'Circe-Regular', sans-serif !important;
    font-weight: normal !important;
    color: #616a89 !important;
    font-size: 18px !important;
    line-height: 24px !important;
  }
  &__dropdown-indicator {
    color: #616a89 !important;
    &:hover {
      color: var(--dark-slate-blue) !important;
    }
  }
  &__option {
    height: 60px !important;
    display: flex !important;
    align-items: center !important;
    &--is-focused {
      background-color: var(--white) !important;
      color: var(--dark-slate-blue) !important;
      cursor: pointer !important;
    }
    &--is-selected {
      background-color: var(--pale-grey) !important;
      color: var(--dark-slate-blue) !important;
    }
  }

  &__control {
    height: 60px;
    border-radius: 8px !important;
    border: solid 1px rgba(157, 166, 186, 0.2) !important;
    background-color: rgba(228, 232, 238, 0.2) !important;
    &--is-focused {
      border-color: var(--dark-slate-blue) !important;
      background: var(--white) !important;
    }
    &--is-disabled {
      & .form-select__placeholder {
        color: #9da6ba !important;
      }
      & .form-select__dropdown-indicator {
        display: none !important;
      }
    }
  }
}
