.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-partners-tab__requests {
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.profile-settings-partners-tab__requests__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px 16px;
  border-top: 1px solid #f6f8fb;
}
.profile-settings-partners-tab__requests__info {
  display: flex;
  flex-direction: column;
}
.profile-settings-partners-tab__requests__status {
  font-family: "Circe-Regular", sans-serif;
  line-height: 16px;
  font-size: 12px;
  color: #00ad85;
}
.profile-settings-partners-tab__requests__name {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 2px;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.profile-settings-partners-tab__requests__nav {
  display: flex;
  align-items: center;
}
.profile-settings-partners-tab__requests__confirm-button, .profile-settings-partners-tab__requests__decline-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  border-radius: 8px;
  line-height: 20px;
  font-size: 14px;
}
.profile-settings-partners-tab__requests__confirm-button {
  padding: 10px 16px;
  background-color: #f6f8fb;
  color: #616a88;
}
.profile-settings-partners-tab__requests__decline-button {
  margin-left: 16px;
  padding: 10px 0;
  background-color: transparent;
  color: #b0b5c4;
}

@media (max-width: 980px) {
  .profile-settings-partners-tab__requests {
    margin-top: 30px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .profile-settings-partners-tab__requests__item {
    flex-direction: column;
    align-items: initial;
    margin-top: 20px;
    padding: 20px;
    border-top: 0;
    border-radius: 6px;
    box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
    background-color: #fff;
  }
  .profile-settings-partners-tab__requests__item:first-child {
    margin-top: 0;
  }
  .profile-settings-partners-tab__requests__nav {
    margin-top: 20px;
  }
  .profile-settings-partners-tab__requests__status {
    display: none;
  }
  .profile-settings-partners-tab__requests__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .profile-settings-partners-tab__requests__nav {
    justify-content: flex-end;
  }
  .profile-settings-partners-tab__requests__confirm-button {
    order: 2;
    margin-left: 16px;
  }
  .profile-settings-partners-tab__requests__decline-button {
    order: 1;
    margin-left: 0;
  }
}