.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-dropdown {
  padding: 25px 30px 35px 30px;
  min-width: 298px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  z-index: 1070;
  border-radius: 0 0 6px 6px;
}
.profile-dropdown__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--pale-grey);
  margin-bottom: 15px;
}
.profile-dropdown__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.profile-dropdown__icon-close {
  width: 14px;
  height: 9px;
  transform: rotate(-180deg);
}
.profile-dropdown__user {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.profile-dropdown__user-avatar-wrap {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.profile-dropdown__user-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-dropdown__user-phone {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-decoration: none;
}
.profile-dropdown__user:hover .profile-dropdown__user-phone {
  text-decoration: underline;
}
.profile-dropdown__notifications {
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;
  box-shadow: none;
  width: 16px;
  height: 20px;
}
.profile-dropdown__notifications-icon {
  width: 16px;
  height: 20px;
}
.profile-dropdown__notifications-icon path {
  transition: fill 0.3s ease;
  fill: #b0b5c4;
}
.profile-dropdown__notifications:hover .profile-dropdown__notifications-icon path {
  fill: var(--dark-slate-blue);
}
.profile-dropdown__info {
  border-bottom: 1px solid var(--pale-grey);
  padding-bottom: 6px;
  margin-bottom: 14px;
}
.profile-dropdown__name {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.profile-dropdown__account-status {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.profile-dropdown__account-status-icon {
  margin-right: 6px;
}
.profile-dropdown__account-status-icon_auth-status path {
  fill: var(--turquoise-blue);
}
.profile-dropdown__account-status-icon_test {
  width: 10px;
  height: 12px;
  margin-top: -2px;
}
.profile-dropdown__account-status-text {
  font-size: 12px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-dropdown__balance-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--pale-grey);
  padding-bottom: 14px;
  margin-bottom: 10px;
}
.profile-dropdown__balance {
  font-size: 18px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.profile-dropdown__balance-rubles {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.profile-dropdown__balance-penny {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.profile-dropdown__balance-withdraw-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--dark-slate-blue);
  text-decoration: underline;
}
.profile-dropdown__balance-withdraw-link:hover {
  text-decoration: none;
}
.profile-dropdown__balance-withdraw-link.button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.profile-dropdown__status {
  width: 100%;
  height: 40px;
  border-radius: 35px;
  background-color: rgba(176, 181, 196, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-blue);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
  text-decoration: none;
}
.profile-dropdown_self-employed .profile-dropdown__status {
  background: rgba(0, 173, 181, 0.1);
  color: #21929a;
}
.profile-dropdown__user-business-link-block {
  margin-bottom: 20px;
  position: relative;
}
.profile-dropdown__user-business-link-text {
  font-size: 12px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 14px;
}
.profile-dropdown__user-business-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--dark-slate-blue);
  text-decoration: underline;
  margin-bottom: 12px;
}
.profile-dropdown__user-business-link:hover:not(:disabled) {
  text-decoration: none;
}
.profile-dropdown__user-business-link:disabled {
  cursor: not-allowed;
}
.profile-dropdown__user-business-link-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-dropdown__icon-copy {
  width: 12px;
  height: 14px;
  margin-right: 10px;
}
.profile-dropdown__label-copy {
  display: inline-block;
  height: 20px;
  border-radius: 10px;
  background-color: #00adb5;
  padding: 0 12px;
  position: absolute;
  bottom: calc(100% + 7px);
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-dropdown__user-business-link-descr {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #9da6ba;
  max-width: 250px;
}
.profile-dropdown__nav-item {
  margin-bottom: 14px;
}
.profile-dropdown__nav-item:last-child {
  margin-bottom: 0;
}
.profile-dropdown__nav-item-link {
  text-decoration: none;
  color: var(--dark-slate-blue);
  font-size: 14px;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  cursor: pointer;
}
.profile-dropdown__nav-item-link:hover {
  text-decoration: underline;
}