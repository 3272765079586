.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.cash-box {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cash-box__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.cash-box__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cash-box__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.cash-box__title-wrap {
  margin-bottom: 40px;
}
.cash-box__title-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.cash-box__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.cash-box__showcase-count-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5px;
}
.cash-box__showcase-count-text {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 5px;
}
.cash-box__showcase-count {
  font-size: 16px;
  color: #5f6a8b;
  font-family: "Circe-Regular", sans-serif;
}
.cash-box__content {
  display: flex;
}
.cash-box__loader {
  display: flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
}
.cash-box__main {
  width: 65.83%;
  min-width: 65.83%;
  margin-right: 30px;
}
.cash-box__list {
  padding-top: 30px;
  border-top: 1px solid rgba(228, 232, 238, 0.5);
}
.cash-box__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #ffffff;
  padding: 23px 20px 18px 20px;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease;
}
.cash-box__item:last-child {
  margin-bottom: 0;
}
.cash-box__item:hover {
  box-shadow: 0 4px 27px 0 #c8cace;
}
.cash-box__item:disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}
.cash-box__item-top {
  display: flex;
  align-items: flex-start;
}
.cash-box__item_open .cash-box__item-top {
  padding-bottom: 18px;
}
.cash-box__item-label {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.cash-box__item-title-wrap {
  max-width: 320px;
  min-width: 320px;
  width: 100%;
  margin-right: 80px;
  text-align: left;
}
.cash-box__item-title {
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  word-break: break-all;
  text-decoration: none;
}
.cash-box__item-top-right-part {
  width: calc(100% - 320px - 80px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cash-box__item-sum-wrap {
  text-align: left;
}
.cash-box__item-sum {
  font-size: 18px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.cash-box__item-controls {
  display: flex;
  align-items: center;
}
.cash-box__item-control {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.cash-box__item-control:last-child {
  margin-right: 0;
}
.cash-box__item-control-icon {
  pointer-events: none;
}
.cash-box__item-control-icon_copy path {
  fill: #b0b5c4;
  transition: all 0.3s ease;
}
.cash-box__item-control:hover .cash-box__item-control-icon_copy path {
  fill: var(--dark-slate-blue);
}
.cash-box__item-control-icon_edit {
  width: 14px;
  height: 15px;
}
.cash-box__item-control-icon_edit path {
  transition: all 0.3s ease;
}
.cash-box__item-control:hover .cash-box__item-control-icon_edit path {
  fill: var(--dark-slate-blue);
}
.cash-box__item-control-icon_basket path {
  transition: all 0.3s ease;
}
.cash-box__item-control:hover .cash-box__item-control-icon_basket path {
  fill: var(--reddish-pink);
}
.cash-box__item-bottom {
  display: none;
}
.cash-box__item_open .cash-box__item-bottom {
  display: flex;
  padding-top: 23px;
  border-top: 1px solid rgba(228, 232, 238, 0.5);
}
.cash-box__item-details {
  max-width: 320px;
  min-width: 320px;
  margin-right: 80px;
  width: 100%;
}
.cash-box__item-details-line {
  display: flex;
  flex-wrap: wrap;
}
.cash-box__item-details-line-key {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.67;
  margin-right: 5px;
}
.cash-box__item-details-line-desc {
  font-size: 12px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.67;
  margin-top: 1px;
}
.cash-box__item-total-income-wrap {
  width: calc(100% - 320px - 80px);
  text-align: left;
}
.cash-box__item-total-income {
  font-size: 36px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.cash-box__no-showcase .no-operations-made__icon {
  width: 42px;
  height: 42px;
}
.cash-box__no-showcase-message-container {
  position: relative;
}
.cash-box__no-showcase-line {
  position: absolute;
  width: 41px;
  height: 131px;
  top: 20px;
  left: 19%;
}
.cash-box__create-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 80px;
  border-radius: 8px;
  border: 1px dashed var(--bluey-grey);
  width: 100%;
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  transition: all 0.3s ease;
  margin-bottom: 30px;
}
.cash-box__create-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.cash-box__create-button:hover:not(:disabled) {
  color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
}
.cash-box__no-showcase-message-container {
  padding-top: 80px;
  padding-bottom: 108px;
}
.cash-box__no-showcase-message-container .no-operations-made__text {
  color: var(--dark-slate-blue);
  max-width: 408px;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 1194px) {
  .cash-box__content {
    display: block;
    padding-bottom: 67px;
  }
  .cash-box__main {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
  }
  .cash-box__no-showcase-message-container {
    padding-bottom: 0;
  }
  .cash-box__hints {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .cash-box__container {
    padding-top: 20px;
  }
  .cash-box__header {
    margin-bottom: 40px;
  }
  .cash-box__item_open {
    padding-bottom: 0;
  }
  .cash-box__item-bottom {
    position: relative;
  }
  .cash-box__item_open .cash-box__item-bottom {
    display: block;
    border-top: none;
  }
  .cash-box__item_open .cash-box__item-bottom:before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    height: 1px;
    background: rgba(228, 232, 238, 0.5);
  }
  .cash-box__item-sum-wrap {
    margin-bottom: 30px;
  }
  .cash-box__item-controls-wrap {
    position: relative;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    border-top: 1px solid rgba(228, 232, 238, 0.5);
  }
  .cash-box__item-control {
    width: 33.33%;
    height: 60px;
    margin-right: 0;
    border-right: 1px solid rgba(228, 232, 238, 0.5);
  }
  .cash-box__item-control:last-child {
    border-right: none;
  }
  .cash-box__copied {
    border-radius: 0 0 8px 8px;
    justify-content: center;
  }
  .cash-box__item-details {
    margin-bottom: 30px;
  }
  .cash-box__item-total-income-wrap {
    margin-bottom: 30px;
    width: 100%;
  }
  .cash-box__item-control-icon_copy path {
    fill: var(--dark-slate-blue);
  }
  .cash-box__item-control-icon_edit {
    width: 14px;
    height: 15px;
  }
  .cash-box__item-control-icon_edit path {
    fill: var(--dark-slate-blue);
  }
  .cash-box__item-control-icon_basket path {
    fill: var(--reddish-pink);
  }
}
@media screen and (max-width: 600px) {
  .cash-box__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cash-box__title-wrap {
    margin-bottom: 30px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
  .cash-box__title-container {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cash-box__title {
    margin-bottom: 25px;
    font-size: 28px;
  }
  .cash-box__item-title-wrap {
    max-width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .cash-box__item-details {
    max-width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .cash-box__showcase-count-wrap {
    justify-content: flex-start;
    padding-bottom: 0;
  }
  .cash-box__create-button {
    height: 120px;
  }
  .cash-box__no-showcase-line {
    left: 0;
    transform: rotate(16deg);
    width: 33px;
    height: 111px;
  }
}