@import url(https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono|Rubik:400,500&display=swap&subset=cyrillic);
@charset "UTF-8";
@font-face {
  font-display: swap;
  font-family: "Circe-Regular";
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Circe-Regular.f31036e3.eot);
  src: local("☺"), url(/static/media/Circe-Regular.4309e684.woff) format("woff"), url(/static/media/Circe-Regular.6b28c593.ttf) format("truetype"), url(/static/media/Circe-Regular.5b4e5913.svg) format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Circe-Bold";
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Circe-Bold.d0391255.eot);
  src: local("☺"), url(/static/media/Circe-Bold.d3f450e8.woff) format("woff"), url(/static/media/Circe-Bold.22f16521.ttf) format("truetype"), url(/static/media/Circe-Bold.2b700107.svg) format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Circe-ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Circe-ExtraBold.9964577d.eot);
  src: local("☺"), url(/static/media/Circe-ExtraBold.c0a4445f.woff) format("woff"), url(/static/media/Circe-ExtraBold.bbdee7fc.ttf) format("truetype"), url(/static/media/Circe-ExtraBold.8929e4c9.svg) format("svg");
}
@font-face {
  font-display: swap;
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

:root {
  --dark-slate-blue: #1d2e69;
  --dusk-blue: #284195;
  --grey-blue: #616a88;
  --normal-grey-blue: #9da6ba;
  --light-grey-blue: #b0b5c4;
  --light-blue: #f2f8ff;
  --pale-grey: #f6f8fb;
  --white: #ffffff;
  --bluey-grey: #b0b5c4;
  --reddish-pink: #ff2d55;
  --light-mustard: #ffd254;
  --turquoise-blue: #00adb5;
  --deep-sky-blue: #007aff;
  --marigold: #ffcc00;
  --robin-s-egg: #5ac8fa;
  --purpley: #6c5ce7;
  --dandelion: #fad961;
  --pumpkin: #f76b1c;
  --orange: #ff8246;
  --save-money-color-theme-1: linear-gradient(to left, var(--robin-s-egg), var(--deep-sky-blue));
  --save-money-color-theme-2: linear-gradient(
    to right,
    var(--reddish-pink) -31%,
    var(--light-mustard) 95%
  );
  --save-money-color-theme-3: linear-gradient(to right, var(--purpley), var(--robin-s-egg) 123%);
  --save-money-color-theme-4: linear-gradient(251deg, #a0ddfc, #7ab0ff);
  --save-money-color-theme-5: linear-gradient(to left, var(--deep-sky-blue), var(--dusk-blue));
  --save-money-color-theme-6: linear-gradient(60deg, var(--pumpkin) -16%, var(--dandelion) 80%);
}

/*RESET CSS*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  font: inherit;
}

input,
textarea {
  outline: none;
}

textarea {
  resize: none;
}

/*HTML5 display-role reset for older browsers*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  outline: none;
}

.fade {
  position: absolute;
  top: 0;
  transition: all 300ms ease-in-out;
}
.fade-enter {
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 0;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
}
.fade-enter-active {
  position: absolute !important;
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.fade-enter-done {
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.fade-exit {
  position: absolute !important;
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.fade-exit-active {
  top: 0;
  right: var(--scroll-bar-width);
  left: 0;
  height: auto;
  transition: all 300ms ease-in-out;
  opacity: 0;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
}

.js-help {
  cursor: pointer;
}

.special-next-btn {
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 100;
}

.animation-text-out {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: 0.6s opacity cubic-bezier(0.4, 0, 0.68, 1.94), 0.6s translateY cubic-bezier(0.4, 0, 0.68, 0.06);
}
.animation-text-out-enter {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-out-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-out-enter-done {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-out-exit {
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-out-exit-active {
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.animation-menu-open_user {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  transition-duration: 300ms;
}
.animation-menu-open_user-enter {
  opacity: 0;
}
.animation-menu-open_user-enter-active {
  opacity: 1;
}
.animation-menu-open_user-enter-done {
  opacity: 1;
}
.animation-menu-open_user-exit {
  opacity: 1;
}
.animation-menu-open_user-exit-active {
  opacity: 0;
}

.animation-menu-open_no-user {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  transition-duration: 500ms;
}
.animation-menu-open_no-user-enter {
  opacity: 0;
}
.animation-menu-open_no-user-enter-active {
  opacity: 1;
}
.animation-menu-open_no-user-enter-done {
  opacity: 1;
}
.animation-menu-open_no-user-exit {
  opacity: 1;
}
.animation-menu-open_no-user-exit-active {
  opacity: 0;
}

.animation-from-top-to-bottom-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-from-top-to-bottom-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-from-top-to-bottom-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-from-top-to-bottom-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.animation-from-top-to-bottom-normal-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-from-top-to-bottom-normal-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-from-top-to-bottom-normal-exit {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-from-top-to-bottom-normal-exit-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.animation-from-bottom-to-top-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-from-bottom-to-top-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-from-bottom-to-top-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-from-bottom-to-top-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}

.animation-from-bottom-to-top-normal-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-from-bottom-to-top-normal-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-from-bottom-to-top-normal-exit {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-from-bottom-to-top-normal-exit-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}

.animation-fade-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
}
.animation-fade-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
}
.animation-fade-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
}
.animation-fade-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
}
.animation-fade-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
}

.animation-manual-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-manual-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-manual-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-manual-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}

.container {
  max-width: 1440px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.container_small {
  max-width: 1300px;
}

@media screen and (max-width: 980px) {
  .fade-enter {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .fade-enter-active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .fade-enter-done {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .fade-exit {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .fade-exit-active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@media screen and (max-width: 600px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 350px) {
  .container {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.dotted-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #1d2e69;
  color: var(--dark-slate-blue);
  text-decoration: none;
  padding-bottom: 3px;
  border-bottom: 2px dotted #1d2e69;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.dotted-link:hover {
  border-bottom-color: transparent;
}

::-webkit-scrollbar {
  width: 14px;
  border-radius: 15px;
  background-color: #ffffff;
  background-color: var(--white);
  cursor: default;
}

::-webkit-scrollbar-thumb {
  background-color: #b0b5c4;
  background-color: var(--light-grey-blue);
  border-radius: 30px;
  border: 4px solid #ffffff;
  border: 4px solid var(--white);
  cursor: default;
}

::-webkit-scrollbar-button {
  display: none;
}
.circle-loader {
  position: relative;
}
.circle-loader__main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
}
.circle-loader__spinning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
  border-image: none;
  border-image: initial;
  -webkit-animation: animation 1s linear infinite forwards running;
          animation: animation 1s linear infinite forwards running;
}

@-webkit-keyframes animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-dropdown {
  padding: 25px 30px 35px 30px;
  min-width: 298px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  z-index: 1070;
  border-radius: 0 0 6px 6px;
}
.profile-dropdown__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--pale-grey);
  margin-bottom: 15px;
}
.profile-dropdown__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.profile-dropdown__icon-close {
  width: 14px;
  height: 9px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.profile-dropdown__user {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.profile-dropdown__user-avatar-wrap {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.profile-dropdown__user-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-dropdown__user-phone {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-decoration: none;
}
.profile-dropdown__user:hover .profile-dropdown__user-phone {
  text-decoration: underline;
}
.profile-dropdown__notifications {
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;
  box-shadow: none;
  width: 16px;
  height: 20px;
}
.profile-dropdown__notifications-icon {
  width: 16px;
  height: 20px;
}
.profile-dropdown__notifications-icon path {
  transition: fill 0.3s ease;
  fill: #b0b5c4;
}
.profile-dropdown__notifications:hover .profile-dropdown__notifications-icon path {
  fill: var(--dark-slate-blue);
}
.profile-dropdown__info {
  border-bottom: 1px solid var(--pale-grey);
  padding-bottom: 6px;
  margin-bottom: 14px;
}
.profile-dropdown__name {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.profile-dropdown__account-status {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.profile-dropdown__account-status-icon {
  margin-right: 6px;
}
.profile-dropdown__account-status-icon_auth-status path {
  fill: var(--turquoise-blue);
}
.profile-dropdown__account-status-icon_test {
  width: 10px;
  height: 12px;
  margin-top: -2px;
}
.profile-dropdown__account-status-text {
  font-size: 12px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-dropdown__balance-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--pale-grey);
  padding-bottom: 14px;
  margin-bottom: 10px;
}
.profile-dropdown__balance {
  font-size: 18px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.profile-dropdown__balance-rubles {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.profile-dropdown__balance-penny {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.profile-dropdown__balance-withdraw-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--dark-slate-blue);
  text-decoration: underline;
}
.profile-dropdown__balance-withdraw-link:hover {
  text-decoration: none;
}
.profile-dropdown__balance-withdraw-link.button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.profile-dropdown__status {
  width: 100%;
  height: 40px;
  border-radius: 35px;
  background-color: rgba(176, 181, 196, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-blue);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
  text-decoration: none;
}
.profile-dropdown_self-employed .profile-dropdown__status {
  background: rgba(0, 173, 181, 0.1);
  color: #21929a;
}
.profile-dropdown__user-business-link-block {
  margin-bottom: 20px;
  position: relative;
}
.profile-dropdown__user-business-link-text {
  font-size: 12px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 14px;
}
.profile-dropdown__user-business-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--dark-slate-blue);
  text-decoration: underline;
  margin-bottom: 12px;
}
.profile-dropdown__user-business-link:hover:not(:disabled) {
  text-decoration: none;
}
.profile-dropdown__user-business-link:disabled {
  cursor: not-allowed;
}
.profile-dropdown__user-business-link-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-dropdown__icon-copy {
  width: 12px;
  height: 14px;
  margin-right: 10px;
}
.profile-dropdown__label-copy {
  display: inline-block;
  height: 20px;
  border-radius: 10px;
  background-color: #00adb5;
  padding: 0 12px;
  position: absolute;
  bottom: calc(100% + 7px);
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-dropdown__user-business-link-descr {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #9da6ba;
  max-width: 250px;
}
.profile-dropdown__nav-item {
  margin-bottom: 14px;
}
.profile-dropdown__nav-item:last-child {
  margin-bottom: 0;
}
.profile-dropdown__nav-item-link {
  text-decoration: none;
  color: var(--dark-slate-blue);
  font-size: 14px;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  cursor: pointer;
}
.profile-dropdown__nav-item-link:hover {
  text-decoration: underline;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.22);
}
.loader_main {
  background: white;
  z-index: 1000;
}
.loader__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 11px;
  margin-left: -32px;
  margin-top: -5px;
}
.loader__loading-inner {
  position: absolute;
  top: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: white;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader_inverted .loader__loading-inner {
  background: var(--dark-slate-blue);
}
.loader__loading-inner:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.loader__loading-inner:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.loader__loading-inner:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.loader__loading-inner:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}
.circle-progress {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.circle-progress__meter {
  fill: none;
  opacity: 0.1;
}
.circle-progress__value {
  fill: none;
  stroke: #f77a52;
  stroke-linecap: round;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.transfer-result {
  padding-bottom: 53px;
}
.transfer-result_has-invoice-inside {
  padding-bottom: 20px;
}
.transfer-result__text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-slate-blue);
  text-align: center;
  line-height: 1.2;
  margin-bottom: 30px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.transfer-result__text-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-slate-blue);
  text-align: center;
  line-height: 1.2;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  -webkit-appearance: none;
  box-shadow: none;
}
.transfer-result__text-link:hover {
  text-decoration: none;
}
.transfer-result__icon-wrap {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-result__icon {
  position: absolute;
  top: 50%;
  left: 50%;
}
.transfer-result__icon_loading {
  width: 27px;
  height: 21px;
  margin-top: -10px;
  margin-left: -13px;
}
.transfer-result__icon_check {
  width: 14px;
  height: 12px;
  margin-top: -5px;
  margin-left: -6px;
}
.transfer-result__icon_fail {
  width: 12px;
  height: 16px;
  margin-top: -8px;
  margin-left: -6px;
}
.transfer-result__icon_fail path {
  fill: var(--reddish-pink);
}
.transfer-result__button-wrap {
  display: flex;
  justify-content: center;
}
.transfer-result__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 320px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
  text-decoration: none;
}
.transfer-result__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.transfer-result__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.blocked-user-text {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
}
.blocked-user-text__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-ExtraBold", sans-serif;
  text-align: center;
  margin-bottom: 30px;
}
.blocked-user-text__desc {
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.blocked-user-text__link {
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}

@media screen and (max-width: 600px) {
  .blocked-user-text__title {
    font-size: 30px;
  }
}
.circle-with-emoji {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-with-emoji__image {
  width: 40px;
  height: 40px;
}
.apple-pay-button {
  height: 100%;
  width: 100%;
  position: relative;
}
.apple-pay-button:disabled:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    display: inline-block;
    -apple-pay-button-style: black;
    position: relative;
  }
  .apple-pay-button:disabled {
    cursor: not-allowed;
  }
  .apple-pay-button:disabled:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    background-color: black;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 60%;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    padding: 0;
  }
  .apple-pay-button:disabled {
    background-color: rgba(29, 46, 105, 0.1);
    cursor: not-allowed;
  }
}
.apple-pay-set-up-button {
  height: 100%;
  width: 100%;
  position: relative;
}
.apple-pay-set-up-button:disabled {
  cursor: not-allowed;
}
.apple-pay-set-up-button:disabled:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-set-up-button {
    -webkit-appearance: -apple-pay-button;
    display: inline-block;
    -apple-pay-button-style: black;
    -apple-pay-button-type: set-up;
    position: relative;
  }
  .apple-pay-set-up-button:disabled {
    cursor: not-allowed;
  }
  .apple-pay-set-up-button:disabled:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-set-up-button {
    background-color: black;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 60%;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    max-height: 64px;
    min-height: 32px;
    min-width: 200px;
    padding: 0;
  }
  .apple-pay-set-up-button:disabled {
    background-color: rgba(29, 46, 105, 0.1);
    cursor: not-allowed;
  }
}
.apple-pay-box {
  height: 100%;
  width: 100%;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.samsung-pay-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background-color: #000;
  border-radius: 8px;
  color: white;
  display: flex;
  height: 60px;
  justify-content: center;
  text-decoration: none;
  width: 100%;
}
.samsung-pay-button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.samsung-pay-button__logo {
  margin-top: 2px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.standard-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 60px;
  border-radius: 8px;
  font-size: 16px;
  color: white;
  font-family: "Circe-Regular", sans-serif;
  transition: all 0.3s ease;
}
.standard-button:disabled {
  cursor: default;
}
.standard-button_blue {
  background: var(--dark-slate-blue);
}
.standard-button_blue:disabled {
  background-color: var(--pale-grey);
  color: #9da6ba;
}
.standard-button_blue:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.standard-button_grey-border {
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  border: 1px solid #616a88;
  color: #616a88;
}
.standard-button_grey-border:hover:not(:disabled) {
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.standard-button_blue-border {
  border: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.standard-button_blue-border:disabled {
  background-color: var(--pale-grey);
  border-color: var(--pale-grey);
  color: #9da6ba;
}
.standard-button_blue-border:hover:not(:disabled) {
  border-color: var(--dark-slate-blue);
  background: var(--dark-slate-blue);
  color: white;
}
.standard-button_light-blue {
  background: var(--deep-sky-blue);
}
.standard-button_light-blue:disabled {
  background-color: var(--pale-grey);
  color: #9da6ba;
}
.standard-button_light-blue:hover:not(:disabled) {
  background: var(--turquoise-blue);
}
.standard-button_violet {
  background: #6c5ce7;
}
.standard-button_violet:disabled {
  background-color: #f6f8fb;
  color: #9da6ba;
}
.standard-button_violet:hover:not(:disabled) {
  background: #988dee;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.payment-box__column {
  width: calc(50% - 10px);
}
.payment-box__column.mod-one-column {
  width: 100%;
}
.payment-box.mod-one-column {
  flex-direction: column;
}
.payment-box.mod-one-column .payment-box__column {
  height: 60px;
  width: 100%;
}
.payment-box.mod-one-column .payment-box__column:not(:last-child) {
  margin-bottom: 20px;
}
.payment-box__button {
  width: 100%;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .payment-box {
    flex-direction: column;
  }
  .payment-box__column {
    height: 60px;
    width: 100%;
  }
  .payment-box__column:not(:last-child) {
    margin-bottom: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.sbp-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 60px;
  border-radius: 8px;
  font-size: 16px;
  background-color: var(--pale-grey);
  color: #1D2E69;
  width: 100%;
  font-family: "Circe-Regular", sans-serif;
  transition: all 0.3s ease;
}
.sbp-button:disabled {
  background-color: var(--pale-grey);
  color: #9da6ba;
}
.sbp-button:hover:not(:disabled) {
  -webkit-filter: brightness(0.99);
          filter: brightness(0.99);
}
.sbp-button__logo {
  margin-top: 2px;
  margin-left: 8px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.business-card {
  background: white;
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  position: relative;
  width: 600px;
}
.business-card__header {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  border-radius: 14px 14px 0 0;
  min-height: 121px;
  overflow: hidden;
  padding: 16px;
  position: relative;
}
.business-card__header_menu-open {
  background: white;
}
.business-card__header_another {
  padding: 16px;
}
@media (min-width: 981px) {
  .business-card__header__whiteLogoWrap {
    display: none;
  }
}
.business-card__header__whiteLogoWrap svg {
  z-index: 100;
  height: 40px;
  width: 40px;
}
.business-card__header-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 22px;
  position: relative;
  z-index: 30;
}
.business-card__header-subtitle {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 22px;
  position: relative;
  z-index: 30;
}
.business-card__header-link-block {
  align-items: center;
  display: flex;
  margin-bottom: 14px;
  position: relative;
  z-index: 30;
}
.business-card__header-link-block-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 14px;
  margin-right: 10px;
  width: 12px;
}
.business-card__header-link-block-icon {
  height: 14px;
  width: 12px;
}
.business-card__header-link-block-icon path {
  fill: white;
}
.business-card__header-link-block-url {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 14px;
  margin-right: 10px;
  text-decoration: underline;
  word-break: break-all;
}
.business-card__header-link-block-copied {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: #00adb5;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  padding: 0 12px;
}
.business-card__header-bg {
  position: absolute;
  right: -52px;
  top: -120px;
  width: 254px;
  z-index: 20;
}
.business-card__top-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.business-card__top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 80;
  height: 30px;
  margin-bottom: 16px;
}
.business-card__top-nav_another {
  height: 30px;
}
.business-card__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  flex-direction: row-reverse;
  grid-gap: 10px;
  gap: 10px;
}
.business-card__back-button-text {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
.business-card__back-button-icon {
  height: 23px;
  width: 13px;
}
.business-card__back-button-icon_menu-open path {
  stroke: var(--dark-slate-blue);
}
.business-card__responsive-logo-icon path {
  fill: white;
}
.business-card__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.business-card__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.business-card__burger-span path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__burger-span:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.business-card__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card__burger_menu-open .business-card__burger-span:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card__burger_menu-open .business-card__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.business-card__close-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.business-card__share-block {
  align-items: center;
  display: flex;
  padding-left: 22px;
  position: relative;
  z-index: 30;
}
.business-card__share-item {
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin-right: 10px;
  padding: 0;
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}
.business-card__share-item-icon {
  height: 15px;
  width: 20px;
  pointer-events: none;
}
.business-card__share-item-icon_envelope {
  height: 14px;
}
.business-card__share-item-icon_envelope g {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_envelope g {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_vk path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_vk path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_ok path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_ok path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_wa path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_wa path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_fb path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_fb path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_tg path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_tg path {
  fill: var(--dark-slate-blue);
}
.business-card__content {
  padding-bottom: 18px;
  position: relative;
}
.business-card__content-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
  margin-bottom: 14px;
  text-align: center;
}
.business-card__content-text-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  font-size: 18px;
  margin-bottom: 8px;
  text-align: center;
}
.business-card__content-text_error {
  color: var(--reddish-pink);
}
.business-card__avatar-wrap {
  border-radius: 50%;
  height: 90px;
  margin: -45px auto 20px auto;
  z-index: 40;
  position: relative;
  width: 90px;
}
.business-card__avatar-wrap_business-card-to-pay {
  margin-bottom: 20px;
}
.business-card__avatar-wrap_avatar {
  border-radius: 50%;
  background: #f6f8fb;
  border: 2px solid white;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.business-card__avatar-wrap_icon-payment {
  padding: 23px;
  border-radius: 50%;
  background: #f6f8fb;
  border: 2px solid white;
}
.business-card__avatar-wrap_error {
  border-color: var(--reddish-pink);
  margin-bottom: 0;
}
.business-card__avatar {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.business-card__avatar-error {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--reddish-pink);
  font-size: 10px;
  margin: 10px 0 20px;
  text-align: center;
}
.business-card__card {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
  width: 100%;
}
.business-card__iframe-wrap {
  height: 421px;
  position: relative;
}
.business-card__iframe-wrap-sbp {
  height: 690px;
  position: relative;
  margin-bottom: 0px;
}
.business-card__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.business-card__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}
.business-card__block-list {
  margin-bottom: 30px;
}
.business-card__block {
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
  width: 100%;
}
.business-card__block:last-child {
  margin-bottom: 0;
}
.business-card__input-holder {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}
.business-card__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 60px;
  padding: 10px 20px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.business-card__input-holder_error .business-card__input {
  color: var(--reddish-pink);
}
.business-card__input:focus {
  border-color: var(--dark-slate-blue);
}
.business-card__input::-webkit-input-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input::-moz-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input:-moz-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input:-ms-input-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input[type=number]::-webkit-outer-spin-button, .business-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.business-card__input[type=number] {
  -moz-appearance: textfield;
}
.business-card__input_message {
  font-size: 14px;
}
.business-card__input:disabled {
  cursor: default;
}
.business-card__label {
  font-family: "Circe-Regular", sans-serif;
  color: #9da6ba;
  font-size: 18px;
  left: 24px;
  pointer-events: none;
  position: absolute;
  top: 22px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: all 0.3s ease;
}
.business-card__input-holder:focus-within .business-card__label {
  color: #616a88;
  left: 20px;
  top: 10px;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.business-card__input-holder_focused .business-card__label {
  color: #616a88;
  left: 20px;
  top: 10px;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.business-card__input-holder_focused.business-card__input-holder_error .business-card__label {
  color: var(--reddish-pink);
}
.business-card__input-holder_error .business-card__label {
  color: var(--reddish-pink);
}
.business-card__input-ruble {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 22px;
}
.business-card__block-text {
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
  font-size: 12px;
  line-height: 1.17;
}
.business-card__block-text_error {
  color: var(--reddish-pink);
}
.business-card__btn-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
}
.business-card__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  transition: all 0.3s ease;
  width: 320px;
}
.business-card__btn:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.business-card__btn:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.business-card__payment-ways {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.business-card__payment-ways-item_visa {
  height: 20px;
  margin-right: 30px;
  width: 45px;
}
.business-card__payment-ways-item_msc {
  height: 19px;
  margin-right: 24px;
  width: 31px;
}
.business-card__payment-ways-item_mir {
  height: 19px;
  margin-right: 19px;
  width: 46px;
}
.business-card__payment-ways-item_pci {
  height: 18px;
  width: 44px;
}
.business-card__info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
  padding-left: 23px;
  padding-right: 23px;
  text-align: center;
  max-width: 520px;
  margin: 0 auto;
}
.business-card__info-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.business-card__info-link:hover {
  text-decoration: none;
}
.business-card__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: -58px;
  top: 0;
  width: 48px;
}
.business-card__close-icon path {
  transition: stroke 0.2s ease-in;
}
.business-card__close:hover .business-card__close-icon path {
  stroke: var(--reddish-pink);
}
.business-card__translate-to-ru {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media screen and (max-width: 980px) {
  .business-card {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }
  .business-card__header {
    border-radius: 0;
    min-height: 150px;
    height: 150px;
    padding: 20px 50px;
  }
  .business-card__back-button {
    width: 30px;
  }
  .business-card__header-title {
    padding-left: 0;
    text-align: center;
    font-size: 12px;
    margin-bottom: 4px;
    z-index: 21;
  }
  .business-card__header-subtitle {
    padding-left: 0;
    text-align: center;
    font-size: 12px;
    margin-bottom: 4px;
    z-index: 21;
  }
  .business-card__close-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 999;
  }
  .business-card__header-link-block {
    justify-content: center;
    margin-bottom: 10px;
  }
  .business-card__header-link-block-icon path {
    fill: var(--bluey-grey);
  }
  .business-card__header-link-block-url {
    color: var(--bluey-grey);
    margin-right: 0;
  }
  .business-card__header-link-block-copied {
    align-self: center;
    background-color: rgba(0, 173, 181, 0.1);
    color: var(--turquoise-blue);
    display: flex;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }
  .business-card__block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 382px;
    width: 100%;
  }
  .business-card__input-holder {
    margin-bottom: 10px;
    width: 100%;
  }
  .business-card__block-text {
    text-align: center;
  }
  .business-card__avatar-wrap {
    margin-bottom: 10px;
  }
  .business-card__avatar-wrap_error {
    margin-bottom: 0;
  }
  .business-card__share-block {
    justify-content: center;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .business-card__share-item-icon_envelope {
    height: 14px;
  }
  .business-card__share-item-icon_envelope g {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_share {
    height: 18px;
    width: 18px;
  }
  .business-card__share-item-icon_share path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_vk path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_ok path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_wa path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_fb path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_tg path {
    fill: var(--grey-blue);
  }
  .business-card__btn {
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
    width: 100%;
  }
  .business-card__close {
    bottom: 0;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    z-index: 30;
  }
  .business-card__translate-to-ru {
    position: static;
    margin-right: 10px;
  }
}
@media screen and (max-width: 700px) {
  .business-card__block-list {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .business-card__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .business-card__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .business-card__avatar-error {
    font-size: 14px;
    line-height: 1.42;
  }
  .business-card__iframe-wrap-sbp {
    height: 710px;
  }
}
@media screen and (max-width: 350px) {
  .business-card__header {
    padding-left: 14px;
    padding-right: 14px;
  }
  .business-card__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.business-card-popup-container {
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.business-card-popup-container_open {
  opacity: 1;
  visibility: visible;
}
.business-card-popup-container__inner {
  padding-top: 30px;
  padding-bottom: 30px;
}
.business-card-popup-container__inner .transfer-result {
  padding-top: 60px;
}
.business-card-popup-container__error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 200;
}
.business-card-popup-container__error-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: var(--grey-blue);
  text-align: center;
}

@media screen and (max-width: 980px) {
  .business-card-popup-container {
    background: white;
    top: 0;
  }
  .business-card-popup-container__inner {
    width: 100%;
    padding-top: 0;
    padding-bottom: 57px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.notifications-dropdown {
  width: 298px;
  min-height: 375px;
  height: auto;
  padding: 15px 0 40px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  position: absolute;
  top: 77px;
  right: 17px;
  border-radius: 8px;
  z-index: 1070;
  margin-right: -136px;
}
.notifications-dropdown:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -9px;
  margin-left: -5px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}
.notifications-dropdown__mark-all-as-read-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.notifications-dropdown__mark-all-as-read-icon {
  width: 8px;
  height: 8px;
  border: solid 1px var(--grey-blue);
  margin-right: 6px;
  border-radius: 50%;
}
.notifications-dropdown__mark-all-as-read-text {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
.notifications-dropdown__mark-all-as-read-btn:hover .notifications-dropdown__mark-all-as-read-text {
  text-decoration: none;
}
.notifications-dropdown__no-messages-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications-dropdown__no-messages {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--grey-blue);
  text-align: center;
}
.notifications-dropdown__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 5px 20px 15px 30px;
  text-align: left;
  transition: all 0.3s ease;
  background: white;
}
.notifications-dropdown__item:hover {
  background-color: var(--pale-grey);
}
.notifications-dropdown__item-date {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}
.notifications-dropdown__item-title {
  font-size: 12px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  position: relative;
}
.notifications-dropdown__item-title:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 2px;
  background-color: var(--turquoise-blue);
}
.notifications-dropdown__item_unread .notifications-dropdown__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.notifications-dropdown__item_unread .notifications-dropdown__item-title:before {
  background-color: var(--reddish-pink);
}
.notifications-dropdown__item-type {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.notifications-dropdown__show-more {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--grey-blue);
  text-decoration: underline;
}
.notifications-dropdown__show-more:hover {
  text-decoration: none;
}
.notifications-dropdown__loader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint-notifications__main {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  width: 50%;
  z-index: 2000;
  opacity: 0;
  left: 100%;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.hint-notifications__main-enter {
  opacity: 0;
  left: 100%;
}
.hint-notifications__main-enter-active {
  opacity: 1;
  left: 50%;
}
.hint-notifications__main-enter-done {
  opacity: 1;
  left: 50%;
}
.hint-notifications__main-exit {
  opacity: 1;
  left: 50%;
  transition-duration: 0.4s;
}
.hint-notifications__main-exit-active {
  opacity: 0;
  left: 100%;
  transition-duration: 0.4s;
}
.hint-notifications__top {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  height: 158px;
}
.hint-notifications__logo-wrap {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: white;
  position: absolute;
  left: 65px;
  top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 0 rgba(100, 101, 103, 0.3215686275);
  overflow: hidden;
}
.hint-notifications__logo {
  max-width: 100%;
  max-height: 100%;
}
.hint-notifications__inner-wrap {
  position: relative;
  bottom: 0;
  top: 0;
  right: -30px;
  height: calc(100% - 158px);
  padding-top: 93px;
  padding-left: 65px;
}
.hint-notifications__inner {
  max-width: 588px;
  position: relative;
  overflow-y: auto;
  bottom: 0;
  top: 0;
  right: 0;
  height: 100%;
  padding-right: 45px;
  padding-bottom: 40px;
}
.hint-notifications__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
.hint-notifications__text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-notifications__text p {
  font-size: inherit;
  line-height: inherit;
  color: indianred;
  font-family: inherit;
}
.hint-notifications__text-link {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-left: 5px;
  margin-right: 5px;
}
.hint-notifications__text-link:hover {
  text-decoration: none;
}
.hint-notifications__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 38px;
  right: 58px;
}
.hint-notifications__close-icon path {
  stroke: white;
}

@media screen and (max-width: 1000px) {
  .hint-notifications__main {
    width: 100%;
    overflow-y: auto;
  }
  .hint-notifications__main-enter-active {
    left: 0;
  }
  .hint-notifications__main-enter-done {
    left: 0;
  }
  .hint-notifications__main-exit {
    left: 0;
  }
  .hint-notifications__top {
    height: 200px;
  }
  .hint-notifications__logo-wrap {
    left: 50%;
    margin-left: -45px;
  }
  .hint-notifications__inner-wrap {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    right: 0;
  }
  .hint-notifications__inner {
    max-width: 100%;
    height: auto;
    padding-right: 0;
  }
  .hint-notifications__title {
    font-size: 36px;
    line-height: 1.11;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    text-align: center;
    margin-bottom: 40px;
  }
  .hint-notifications__text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    width: 100%;
  }
  .hint-notifications__text-link {
    font-size: 16px;
    line-height: 1.5;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.header {
  height: 90px;
  position: relative;
  width: 100%;
  z-index: 62;
}
.header.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--white);
}
.header.sticky.shadow {
  box-shadow: 0px 0px 20px 0 #e8ecf2;
}
.header_non-logged-in {
  box-shadow: none;
  height: 100px;
  padding-top: 50px;
}
.header_responsive-white-elements {
  box-shadow: none;
}
.header_non-visible {
  opacity: 0;
  visibility: hidden;
  z-index: -5;
}
.header_no-shadow {
  box-shadow: none;
}
.header__container {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: space-between;
  padding: 20px 50px;
}
@media (min-width: 1195px) {
  .header__container {
    height: 100%;
  }
}
.header__left-part {
  align-items: center;
  display: flex;
}
.header__logo-wrap {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
@media (min-width: 1195px) {
  .header__logo-wrap {
    margin-bottom: 5px;
  }
}
.header__logo {
  height: 40px;
  width: 206px;
}
.header_white-elements .header__logo path {
  fill: white;
}
.header__back {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.header__back path {
  transition: all 0.3s ease;
}
.header__back:hover path {
  fill: var(--grey-blue);
}
.header__nav {
  align-items: center;
  display: flex;
  margin-left: 60px;
}
.header__nav-item {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--light-grey-blue);
  font-size: 14px;
  margin-right: 40px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.header__nav-item:last-child {
  margin-right: 0;
}
.header__nav-item_active {
  color: var(--dark-slate-blue);
}
.header__nav-item:hover {
  color: var(--dark-slate-blue);
}
.header__right-part {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.header__user {
  align-items: center;
  display: flex;
}
.header__user-balance-block {
  align-items: center;
  display: flex;
  margin-right: 9px;
}
.header__user-balance-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--light-grey-blue);
  font-size: 12px;
  margin-right: 11px;
}
.header__user-balance {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 16px;
}
.header__user-balance-rubles {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 16px;
}
.header__user-balance-penny {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 16px;
}
.header__user-notification {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 24px;
  margin-right: 10px;
  position: relative;
}
.header__user-notification::after {
  background-color: var(--reddish-pink);
  border-radius: 50%;
  content: "";
  height: 5px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
}
.header__user-notification_has-notifications::after {
  opacity: 1;
}
.header__user-notification-icon {
  height: 20px;
  width: 16px;
}
.header__user-notification-icon:hover path {
  fill: var(--dark-slate-blue);
  transition: fill 0.3s ease;
}
.header__user-avatar-wrap {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  height: 40px;
  margin-right: 10px;
  overflow: hidden;
  width: 40px;
}
.header__user-avatar {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}
.header__user-phone {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 12px;
  margin-right: 5px;
  transition: all 0.3s ease;
}
.header__user-phone:hover {
  color: var(--dark-slate-blue);
}
.header__user-caret {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  transition: all 0.3s ease;
  width: 20px;
}
.header__user-caret-icon {
  height: 5px;
  min-height: 5px;
  min-width: 11px;
  width: 11px;
}
.header__user-caret-icon use {
  fill: #8e97b4;
  transition: all 0.3s ease;
}
.header__user-caret:hover .header__user-caret-icon use {
  fill: var(--dark-slate-blue);
}
.header__burger-wrap {
  height: 20px;
  position: relative;
  width: 30px;
}
.header__burger-btn {
  -webkit-appearance: none;
  background: rgba(0, 0, 0, 0.03);
  border: none;
  border-radius: 0;
  bottom: 0;
  box-shadow: none;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 30;
}
.header__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  margin-left: 16px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 40px;
  z-index: 20;
}
.header__burger-inner {
  bottom: 10px;
  left: 5px;
  position: absolute;
  right: 0;
  top: 10px;
}
.header__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  transition: all 0.3s ease;
  width: 100%;
}
.header__burger-span path {
  transition: all 0.3s ease;
}
.header__burger-span:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.header__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.header__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.header__burger_menu-open .header__burger-span:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.header__burger_menu-open .header__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.header__burger_menu-open .header__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.header_white-elements .header__burger-span path {
  fill: white;
}
.header__profile-dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 1194px) {
  .header {
    padding-top: 0;
    transition: box-shadow 0.6s ease-in-out;
  }
  .header_responsive-white-elements {
    transition: none 0s ease;
  }
  .header_non-logged-in {
    height: 90px;
    padding-top: 0;
  }
  .header__logo {
    height: 40px;
    width: 40px;
  }
}
@media screen and (max-width: 980px) {
  .header_responsive-white-elements .header__logo path {
    fill: white;
  }
  .header_responsive-white-elements .header__back path {
    fill: white;
  }
  .header_responsive-white-elements .header__burger-span path {
    fill: white;
  }
  .header_responsive-white-elements .header__burger:hover .header__burger-span path {
    fill: white;
  }
  .header_responsive-white-elements .header__burger_menu-open .header__burger-span path {
    fill: var(--dark-slate-blue);
  }
  .header_responsive-white-elements .header__burger_menu-open:hover .header__burger-span path {
    fill: var(--deep-sky-blue);
  }
  .header_responsive-white-elements .header__burger_menu-open:hover .header__burger-span {
    background: var(--deep-sky-blue);
  }
  .header__user-caret-icon use {
    fill: rgba(29, 46, 105, 0.5);
  }
  .header__user-caret:hover .header__user-caret-icon use {
    fill: var(--dark-slate-blue);
  }
}
@media screen and (max-width: 800px) {
  .header {
    height: 70px;
  }
}
@media screen and (max-width: 600px) {
  .header__container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@-webkit-keyframes burger-animation-line-1 {
  0% {
    top: 0;
  }
  50% {
    top: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    top: 7px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
@keyframes burger-animation-line-1 {
  0% {
    top: 0;
  }
  50% {
    top: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    top: 7px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
@-webkit-keyframes burger-animation-line-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes burger-animation-line-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes burger-animation-line-3 {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}
@keyframes burger-animation-line-3 {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}
@-webkit-keyframes burger-animation-line-1-out {
  0% {
    top: 7px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  50% {
    top: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    top: 0;
  }
}
@keyframes burger-animation-line-1-out {
  0% {
    top: 7px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  50% {
    top: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes burger-animation-line-2-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes burger-animation-line-2-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes burger-animation-line-3-out {
  0% {
    bottom: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  50% {
    bottom: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 0;
  }
}
@keyframes burger-animation-line-3-out {
  0% {
    bottom: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  50% {
    bottom: 9px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.footer-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.footer-items .footer-app-links {
  display: flex;
  align-items: center;
  position: relative;
}
.footer-items .footer-app-links__text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: #b0b5c4;
  margin-right: 28px;
}
.footer-items .footer-app-links__list {
  display: flex;
  align-items: center;
}
.footer-items .footer-app-links__link {
  margin-right: 28px;
}
.footer-items .footer-app-links__link:last-child {
  margin-right: 0;
}
.footer-items .footer-app-links__icon_app-store {
  width: 18px;
  height: 22px;
}
.footer-items .footer-app-links__icon_play-store {
  width: 16px;
  height: 18px;
  margin-top: 4px;
}
.footer-items .footer-app-links__icon path {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.footer__app-item:hover .footer-items .footer-app-links__icon path {
  fill: var(--dark-slate-blue);
}
.footer-items .footer-privacy {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: var(--dusk-blue);
  margin-right: 28px;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .footer-items {
    flex-direction: column;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.menu {
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 60;
}
.menu__header {
  flex-shrink: 0;
  margin-bottom: 95px;
}
.menu__container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
}
.menu__container_app {
  margin-top: auto;
  padding-bottom: 100px;
  padding-top: 20px;
}
.menu__content {
  flex-grow: 100;
  padding-left: 70px;
}
.menu__main-nav {
  margin-bottom: 80px;
}
.menu__main-nav-item {
  margin-bottom: 20px;
}
.menu__main-nav-item:last-child {
  margin-bottom: 0;
}
.menu__main-nav-item-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  color: var(--dark-slate-blue);
  font-size: 28px;
  text-decoration: none;
}
.menu__main-nav-item-link:hover {
  color: var(--light-grey-blue);
}
.menu__main-nav-item-link::after {
  background-color: var(--reddish-pink);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 5px;
  margin-left: 5px;
  opacity: 0;
  position: relative;
  top: -14px;
  width: 5px;
}
.menu__main-nav-item-link_has-notifications::after {
  opacity: 1;
}
.menu__sub-nav-item {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  margin-bottom: 22px;
}
.menu__sub-nav-item:last-child {
  margin-bottom: 0;
}
.menu__sub-nav-item-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 18px;
  text-decoration: none;
}
.menu__sub-nav-item-link:hover {
  color: var(--dark-slate-blue);
}
.menu__user {
  align-items: center;
  background-color: var(--pale-grey);
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  min-height: 120px;
  padding: 30px 50px;
  text-decoration: none;
}
.menu__user-main {
  align-items: center;
  display: flex;
}
.menu__user-avatar-wrap {
  border-radius: 50%;
  height: 60px;
  margin-right: 20px;
  overflow: hidden;
  width: 60px;
}
.menu__user-avatar {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}
.menu__user-balance {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
  margin-bottom: 16px;
}
.menu__user-balance-rubles {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
}
.menu__user-balance-penny {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
}
.menu__user-profile-status {
  align-items: center;
  display: flex;
}
.menu__user-profile-status-icon {
  margin-right: 6px;
}
.menu__user-profile-status-icon_auth-status path {
  fill: var(--turquoise-blue);
}
.menu__user-profile-status-icon_test {
  margin-top: -2px;
}
.menu__user-profile-status-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 12px;
}
.menu__user-business-link-block {
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.menu__user-business-link-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 16px;
  margin-bottom: 3px;
}
.menu__user-business-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 12px;
  margin-bottom: 12px;
  text-decoration: underline;
}
.menu__user-status {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(176, 181, 196, 0.1);
  border-radius: 35px;
  color: var(--grey-blue);
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  min-width: 238px;
  text-align: center;
  text-decoration: none;
  width: 70%;
}
.menu__user-status_self-employed {
  background: rgba(0, 173, 181, 0.1);
  color: #21929a;
}
.menu__user-business-link-inner {
  align-items: center;
  display: flex;
  justify-content: center;
}
.menu__icon-copy {
  height: 14px;
  margin-right: 10px;
  width: 12px;
}
.menu__label-copy {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: #00adb5;
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  padding: 0 12px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -2px;
}
.menu__user-business-link-descr {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #9da6ba;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 1.33;
}
.menu__user-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.menu__user-btn-icon {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

@media screen and (max-height: 600px) {
  .menu {
    height: auto;
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 50px;
    padding-top: 50px;
    position: absolute;
  }
  .menu__content {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 1194px) {
  .menu {
    overflow: scroll;
    overflow-x: hidden;
  }
  .menu__content {
    padding-left: 0;
  }
  .menu__app {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .menu__header {
    margin-bottom: 0;
  }
  .menu_non-auth .menu__header {
    margin-bottom: 30px;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
}
@media screen and (max-width: 800px) {
  .menu {
    height: auto;
    padding-top: 0;
  }
  .menu_responsive-in-profile {
    top: 0;
  }
  .menu_on-getting-auth {
    top: 0;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
}
@media screen and (max-width: 700px) {
  .menu {
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    right: 0;
    /*    &_responsive-in-profile {
      padding-top: 70px;
    }
    &_on-getting-auth {
      padding-top: 70px;
    }*/
  }
  .menu__container_app {
    padding-bottom: 50px;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
  .menu__content {
    padding-left: 0;
  }
  .menu_non-auth .menu__content {
    padding-top: 50px;
  }
  .menu__sub-nav-item_exit {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .menu__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .menu__container_app-offset {
    padding-left: 0;
  }
  .menu__user {
    padding: 30px 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint-auth {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  width: 50%;
  display: flex;
  align-items: center;
  z-index: 200;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.hint-auth-enter {
  opacity: 0;
  left: 100%;
}
.hint-auth-enter-active {
  opacity: 1;
  left: 50%;
}
.hint-auth-enter-done {
  opacity: 1;
  left: 50%;
}
.hint-auth-exit {
  opacity: 1;
  left: 50%;
}
.hint-auth-exit-active {
  opacity: 0;
  left: 100%;
}
@media screen and (max-width: 1000px) {
  .hint-auth-enter-active {
    left: 0;
  }
  .hint-auth-enter-done {
    left: 0;
  }
  .hint-auth-exit {
    left: 0;
  }
}
.hint-auth_self-employed {
  align-items: flex-start !important;
}
.hint-auth__inner {
  position: relative;
  bottom: 0;
  top: 0;
  right: -30px;
  height: calc(100% - 158px);
  padding-top: 40px;
  padding-left: 45px;
}
.hint-auth_self-employed .hint-auth__inner {
  padding-top: 300px;
}
.hint-auth__content {
  max-width: 588px;
  position: relative;
  overflow-y: auto;
  bottom: 0;
  top: 0;
  right: 0;
  height: 100%;
  padding-right: 45px;
  padding-bottom: 40px;
}
.hint-auth__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
.hint-auth__subtitle {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 1.33;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 20px;
  width: 90%;
}
.hint-auth__content-text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-auth__list__middle {
  width: 80%;
}
.hint-auth__list-item {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.35;
  color: var(--grey-blue);
}
.hint-auth__tab-content .hint-auth__list-item {
  font-size: 15px;
}
.hint-auth_no-access-rights .hint-auth__list-item {
  font-size: 15px;
  margin-bottom: 4px;
}
.hint-auth_no-access-rights .hint-auth__list-item:last-child {
  margin-bottom: 0;
}
.hint-auth__list-item-link {
  color: var(--dusk-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.33;
  margin-left: 3px;
  margin-right: 3px;
}
.hint-auth__tab-content .hint-auth__list-item-link {
  font-size: 15px;
}
.hint-auth_no-access-rights .hint-auth__list-item-link {
  font-size: 15px;
}
.hint-auth__link-item {
  margin-bottom: 25px;
}
.hint-auth__link-item:last-child {
  margin-bottom: 0;
}
.hint-auth__text-block {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-auth__text-block:last-child {
  margin-bottom: 0;
}
.hint-auth__text-block-title {
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
}
.hint-auth__tab {
  margin-bottom: 30px;
}
.hint-auth__tab:last-child {
  margin-bottom: 0;
}
.hint-auth__tab-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0 0 3px 0;
  text-align: left;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.hint-auth__tab-item-text {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  border-bottom: 2px dotted var(--dark-slate-blue);
}
.hint-auth__tab-item_active .hint-auth__tab-item-text {
  color: var(--grey-blue);
  border-bottom-color: transparent;
}
.hint-auth__tab-item:hover .hint-auth__tab-item-text {
  border-bottom-color: transparent;
}
.hint-auth__tab-content {
  padding-top: 20px;
}
.hint-auth__close {
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 38px;
  right: 58px;
  border: none;
}
.hint-auth__close path {
  transition: stroke 0.2s ease-in-out;
}
.hint-auth__close:hover path {
  stroke: var(--reddish-pink);
}
.hint-auth__app-links {
  display: flex;
  margin-top: 47px;
}
.hint-auth__app-links-item {
  margin-right: 39px;
}
.hint-auth__app-links-item:last-child {
  margin-right: 0;
}
.hint-auth__app-links-item-img_app-store {
  width: 111px;
  height: 30px;
}
.hint-auth__app-links-item-img_google-play {
  width: 117px;
  height: 29px;
}
.hint-auth__app-links-item:hover .hint-auth__app-links-item-img path {
  fill: var(--dusk-blue);
}

@media screen and (max-width: 1000px) {
  .hint-auth {
    width: 100%;
    overflow-y: auto;
  }
  .hint-auth__inner {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    right: 0;
  }
  .hint-auth__content {
    max-width: 100%;
    height: auto;
    padding-right: 0;
  }
}
@media screen and (max-width: 800px) {
  .hint-auth {
    width: 100%;
    align-items: flex-start;
    padding-top: 141px;
  }
  .hint-auth__inner {
    padding-bottom: 70px;
    padding-top: 0;
  }
  .hint-auth_self-employed .hint-auth__inner {
    padding-top: 0;
  }
  .hint-auth__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
  .hint-auth__tab-content .hint-auth__list-item {
    margin-bottom: 15px;
  }
  .hint-auth__tab-content .hint-auth__list-item:last-child {
    margin-bottom: 0;
  }
  .hint-auth_no-access-rights .hint-auth__list-item {
    margin-bottom: 15px;
  }
  .hint-auth_no-access-rights .hint-auth__list-item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .hint-auth__close {
    right: 20px;
    top: 25px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hints-list__title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 24px;
}
.hints-list__item-wrap {
  margin-bottom: 19px;
  display: flex;
}
.hints-list__item-wrap:last-child {
  margin-bottom: 0;
}
.hints-list__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
}
.hints-list__item-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
}
.hints-list__item-text:hover {
  border-bottom-color: transparent;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.standard-hover-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  height: 60px;
  background: #ffffff;
  border: 1px solid var(--dark-slate-blue);
  border-radius: 8px;
  position: relative;
  display: block;
}
.standard-hover-button:disabled {
  cursor: not-allowed;
  border-color: var(--bluey-grey);
}
.standard-hover-button a {
  text-decoration: none;
}
.standard-hover-button__main {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
.standard-hover-button__icon {
  margin-right: 9px;
  width: 17px;
  height: 15px;
}
.standard-hover-button__icon path {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}
.standard-hover-button:hover:not(:disabled) .standard-hover-button__icon path {
  fill: white;
  stroke: white;
}
.standard-hover-button:disabled .standard-hover-button__icon path {
  fill: var(--bluey-grey);
  stroke: var(--bluey-grey);
}
.standard-hover-button__text {
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  transition: color 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}
.standard-hover-button:hover:not(:disabled) .standard-hover-button__text {
  color: white;
}
.standard-hover-button:disabled .standard-hover-button__text {
  color: var(--bluey-grey);
}
.standard-hover-button__hover {
  background: var(--dusk-blue);
  position: absolute;
  width: 240px;
  height: 60px;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  border-radius: 8px;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -120px;
  -webkit-transform-origin: center;
          transform-origin: center;
  z-index: 10;
}
.standard-hover-button:hover:not(:disabled) .standard-hover-button__hover {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.se-registration-container {
  display: flex;
  flex-grow: 1000;
  position: relative;
}

.se-register {
  width: 100%;
  display: flex;
  flex-grow: 100;
  position: relative;
  z-index: 10;
}
.se-register__container {
  display: flex;
  padding-top: 20vh;
  align-items: flex-start;
  justify-content: space-between;
}
.se-register__step-5 .se-register__container {
  justify-content: flex-start;
}
.se-register__content {
  width: 100%;
  min-width: auto;
  max-width: 560px;
  padding-left: 70px;
  margin-top: 0;
}
.se-register__step-3 .se-register__content {
  max-width: 570px;
}
.se-register__title {
  color: var(--dark-slate-blue);
  font-size: 36px;
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 20px;
  padding-right: 70px;
  line-height: 1.4;
}
.se-register__step-1 .se-register__title {
  margin-bottom: 17px;
  line-height: 1.2;
}
.se-register__step-2 .se-register__title {
  margin-bottom: 23px;
  width: 90%;
}
.se-register__step-4 .se-register__title {
  margin-bottom: 24px;
  padding-right: 20px;
}
.se-register__step-5 .se-register__title {
  margin-bottom: 17px;
}
.se-register__step-no-sz .se-register__title {
  margin-bottom: 24px;
  padding-right: 20px;
}
.se-register__desc-wrap {
  margin-bottom: 30px;
  width: 90%;
}
.se-register__desc-wrap .hints-list__title {
  margin-bottom: 10px;
}
.se-register__desc {
  font-size: 18px;
  line-height: 1.33;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 10px;
}
.se-register__step-4 .se-register__desc {
  font-size: 16px;
}
.se-register__step-no-sz .se-register__desc {
  font-size: 16px;
}
.se-register__desc-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
}
.se-register__desc-link-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
}
.se-register__desc-link-text:hover {
  border-bottom-color: transparent;
}
.se-register__warning-wrap {
  width: 90%;
  margin-bottom: 30px;
}
.se-register__warning {
  position: relative;
  color: var(--grey-blue);
  line-height: 1.5;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.se-register__warning-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 2px;
}
.se-register__warning-link {
  color: var(--dusk-blue);
  line-height: 1.5;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  margin-left: 5px;
  margin-right: 3px;
}
.se-register__warning-link:hover {
  text-decoration: none;
}
.se-register__form {
  margin-bottom: 40px;
}
.se-register__step-3 .se-register__form {
  width: calc(100% - 10px);
}
.se-register__form-block {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}
.se-register__input-holder {
  position: relative;
  max-width: 420px;
  margin-right: 10px;
  width: 100%;
}
.se-register__input-check {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -6px;
}
.se-register__input-check path {
  stroke: var(--dark-slate-blue);
}
.se-register__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  font-size: 18px;
}
.se-register__input::-webkit-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input::-moz-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input:-moz-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input:-ms-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input::-ms-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.se-register__input::-ms-clear {
  display: none;
}
.se-register__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.se-register__next-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.se-register__next-button:hover {
  background: var(--dusk-blue);
}
.se-register__next-button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.se-register__next-button-icon {
  margin-left: 5px;
}
.se-register__tip {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.se-register__tip-link {
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.se-register__btn-list {
  display: flex;
  align-items: center;
  width: 83%;
}
.se-register__btn {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.se-register__btn:last-child {
  margin-right: 0;
}
.se-register__send-button {
  max-width: 240px;
  width: 100%;
}
.se-register__hint-block {
  width: 50%;
  display: flex;
  justify-content: center;
}
.se-register__step-2 .se-register__hint-block {
  padding-left: 60px;
  padding-top: 50px;
  justify-content: flex-start;
}
.se-register__step-3 .se-register__hint-block {
  justify-content: flex-start;
  padding-left: 60px;
  padding-top: 70px;
}
.se-register__step-4 .se-register__hint-block {
  padding-left: 60px;
  padding-top: 36px;
  justify-content: flex-start;
  flex-direction: column;
}
.se-register__step-no-sz .se-register__hint-block {
  padding-left: 60px;
  padding-top: 36px;
  justify-content: flex-start;
  flex-direction: column;
}
.se-register__step-5 .se-register__hint-block {
  padding-left: 30px;
  padding-top: 61px;
}
.se-register__hint {
  -webkit-filter: drop-shadow(0 4px 27px #e8ecf2);
          filter: drop-shadow(0 4px 27px #e8ecf2);
  background: white;
  background-size: cover;
  padding: 39px;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  position: relative;
}
.se-register__step-3 .se-register__hint {
  padding: 29px 39px 49px 39px;
}
.se-register__step-4 .se-register__hint {
  padding: 27px 39px 30px 39px;
}
.se-register__step-no-sz .se-register__hint {
  padding: 27px 70px 30px 39px;
}
.se-register__hint-triangle {
  position: absolute;
  bottom: -23px;
  left: 22px;
  width: 29px;
  height: 23px;
}
.se-register__hint-text {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-right: 10px;
}
.se-register__hint-icon_wink {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-register__hint-icon_rock {
  width: 18px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-register__hint-icon_sad {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
  margin-right: 5px;
}
.se-register__hint-icon_man {
  width: 20px;
  height: 19px;
  display: inline-block;
  vertical-align: -3px;
}
.se-register__hint-phone {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-right: 10px;
}
.se-register__hint-phone a {
  text-decoration: none;
  color: var(--dark-slate-blue);
}
.se-register__hint-phone-change {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: inline;
}
.se-register__error-list-wrap {
  margin-top: 7.5vh;
}
.se-register__step-4 .se-register__error-list-wrap {
  margin-top: 9.5vh;
}
.se-register__step-no-sz .se-register__error-list-wrap {
  margin-top: 9.5vh;
}
.se-register__error-list-wrap .hints-list__title {
  margin-bottom: 10px;
}
.se-register__image {
  width: 385px;
  height: 279px;
  position: absolute;
  right: 17%;
  top: 26%;
}

@media screen and (max-height: 750px) {
  .se-register__container {
    padding-top: 10vh;
  }
}
@media screen and (max-width: 1194px) {
  .se-register__container {
    padding-top: 10vh;
  }
  .se-register__content {
    padding-left: 0;
    margin-top: 0;
  }
  .se-register__hint-block {
    width: 40%;
  }
  .se-register__step-2 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-3 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-4 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-no-sz .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__step-5 .se-register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .se-register__hint {
    padding: 25px;
  }
  .se-register__image {
    right: 7%;
  }
}
@media screen and (max-height: 600px) {
  .se-register {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1100px) {
  .se-register {
    padding-top: 50px;
    padding-bottom: 200px;
  }
  .se-register__container {
    display: block;
    padding-top: 0;
  }
  .se-register__content {
    width: 100%;
  }
  .se-register__title {
    padding-right: 20px;
  }
  .se-register__step-1 .se-register__title {
    margin-bottom: 27px;
  }
  .se-register__step-2 .se-register__title {
    margin-bottom: 29px;
  }
  .se-register__step-4 .se-register__title {
    margin-bottom: 29px;
  }
  .se-register__step-no-sz .se-register__title {
    margin-bottom: 29px;
  }
  .se-register__step-1 .se-register__desc-wrap {
    width: 90%;
  }
  .se-register__step-4 .se-register__desc-wrap {
    max-width: 100%;
    width: 100%;
  }
  .se-register__step-no-sz .se-register__desc-wrap {
    max-width: 100%;
    width: 100%;
  }
  .se-register__desc {
    color: var(--bluey-grey);
  }
  .se-register__hint-block {
    width: 100%;
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .se-register__step-5 .se-register__hint-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .se-register__hint {
    -webkit-filter: none;
            filter: none;
    padding: 0;
    max-width: 376px;
    width: 100%;
    border-radius: 0;
  }
  .se-register__hint:after {
    display: none;
  }
  .se-register__step-4 .se-register__hint {
    max-width: 400px;
    padding: 0;
    margin-bottom: 30px;
  }
  .se-register__step-no-sz .se-register__hint {
    max-width: 400px;
    padding: 0;
    margin-bottom: 30px;
  }
  .se-register__step-3 .se-register__hint {
    padding: 0;
  }
  .se-register__btn-list {
    width: 100%;
    flex-direction: column;
  }
  .se-register__btn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .se-register__btn:last-child {
    margin-bottom: 0;
  }
  .se-register__step-3 .se-register__btn {
    max-width: 240px;
  }
  .se-register__step-no-sz .se-register__btn {
    max-width: 420px;
  }
  .se-register__step-4 .se-register__error-list-wrap {
    margin-top: 0;
  }
  .se-register__step-no-sz .se-register__error-list-wrap {
    margin-top: 0;
  }
  .se-register__image {
    right: 1%;
    top: 52%;
  }
}
@media screen and (max-width: 700px) {
  .se-register__step-4 .se-register__title {
    line-height: 1.15;
  }
  .se-register__step-no-sz .se-register__title {
    line-height: 1.15;
  }
  .se-register__step-2 .se-register__warning-wrap {
    width: 100%;
  }
  .se-register__warning-icon {
    position: static;
    display: inline-block;
    vertical-align: -4px;
    margin-right: 5px;
  }
  .se-register__input-holder {
    margin-right: 0;
  }
  .se-register__step-4 .se-register__hint-block {
    margin-top: 0;
    margin-bottom: 53px;
  }
  .se-register__step-no-sz .se-register__hint-block {
    margin-top: 0;
    margin-bottom: 53px;
  }
  .se-register__error-list-wrap {
    margin-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .se-register__image {
    right: auto;
    top: 70%;
    left: -20%;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
    z-index: 10;
  }
}
@media screen and (max-width: 360px) {
  .register-container {
    z-index: 70;
  }
  .register-container_menu-open {
    z-index: 50;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.return-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  background: #f6f8fb;
  border-radius: 15px;
  transition: background 0.3s ease-in-out;
}
.return-button:hover {
  background: #e5e7ea;
}
.return-button__text {
  color: #616a88;
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 8px;
}
.return-button__icon {
  width: 10px;
  height: 10px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.manual-progress-steps {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 47px;
  left: 50%;
  margin-left: -320px;
}
.manual-progress-steps__item {
  width: 160px;
  min-width: 160px;
  position: relative;
  z-index: 20;
}
.manual-progress-steps__item-number {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--dark-slate-blue);
  font-size: 18px;
  color: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  position: relative;
  transition: all 0.3s ease;
}
.manual-progress-steps__item-number:after {
  content: "";
  position: absolute;
  top: 25px;
  height: 1px;
  background: #f6f8fb;
  left: 50px;
  width: 159px;
}
.manual-progress-steps__item:nth-child(4) .manual-progress-steps__item-number:after {
  display: none;
}
.manual-progress-steps__item_active .manual-progress-steps__item-number {
  background: var(--dark-slate-blue);
  color: white;
}
.manual-progress-steps__item:hover .manual-progress-steps__item-number {
  background: var(--dark-slate-blue);
  color: white;
}
.manual-progress-steps__item_passed .manual-progress-steps__item-number {
  background: #f6f8fb;
  border-color: #f6f8fb;
  color: #b0b5c4;
}
.manual-progress-steps__item_passed .manual-progress-steps__item-number:after {
  background: #9da6ba;
}
.manual-progress-steps__item-title {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.manual-progress-steps__item_passed .manual-progress-steps__item-title {
  color: #b0b5c4;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.popup-close-with-text {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50px;
  right: 50px;
  padding: 0 12px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  text-decoration: none;
}
.popup-close-with-text:hover {
  width: 130px;
  border-radius: 24px;
}
.popup-close-with-text__close-text {
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 14px;
}
.popup-close-with-text:hover .popup-close-with-text__close-text {
  opacity: 1;
  visibility: visible;
  width: 60px;
  margin-right: 10px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.manual-dropdown {
  background: #ffffff;
  box-shadow: 10px 30px 40px rgba(45, 61, 89, 0.1);
  border-radius: 6px;
  width: 249px;
  padding: 24px 16px;
  position: absolute;
  top: 60px;
  left: 74px;
  z-index: 100;
}
.manual-dropdown__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  color: #616a88;
  text-align: left;
  width: 100%;
}
.manual-dropdown__item:last-child {
  margin-bottom: 0;
}
.manual-dropdown__item_active {
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  font-size: 15px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.manual-page {
  background: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 200;
  width: 100%;
  position: relative;
}
.manual-page__top-wrap {
  position: relative;
  z-index: 5;
}
.manual-page__container {
  padding: 0 50px 0 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.manual-page_start .manual-page__container {
  min-height: 500px;
}
.manual-page_check-citizenship .manual-page__container {
  min-height: 500px;
}
.manual-page__header-wrap {
  margin-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.manual-page__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.manual-page_start .manual-page__header-wrap {
  margin-bottom: 205px;
}
.manual-page__header-left {
  display: flex;
  align-items: center;
}
.manual-page__header-logo {
  height: 39px;
}
.manual-page__dropdown-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.manual-page__dropdown-button-text {
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-decoration: underline;
  margin-right: 8px;
}
.manual-page__dropdown-button-caret {
  width: 11px;
  height: 6px;
  fill: #616a88;
}
.manual-page__content-wrap {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 5;
}
.manual-page__details-container {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}
.manual-page__col {
  width: 570px;
  min-width: 570px;
  margin-right: 60px;
  position: relative;
}
.manual-page_web-version .manual-page__col:first-child {
  height: 600px;
}
.manual-page__col:nth-child(2) {
  width: calc(100% - 570px - 60px);
  min-width: calc(100% - 570px - 60px);
  margin-right: 0;
  padding-bottom: 100px;
}
.manual-page_registration .manual-page__col:nth-child(2) {
  height: 554px;
}
.manual-page_my-tax-1 .manual-page__col:nth-child(2) {
  height: 580px;
}
.manual-page_my-tax-lk .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_my-tax-gosuslugi .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_web-lk .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_web-gosuslugi .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_sz-status-confirmation-3 .manual-page__col:nth-child(2) {
  height: 660px;
}
.manual-page_cooperation-1 .manual-page__col:nth-child(2) {
  height: 550px;
}
.manual-page_cooperation-2 .manual-page__col:nth-child(2) {
  height: 550px;
}
.manual-page__return-link {
  margin-bottom: 24px;
}
.manual-page__title {
  font-size: 45px;
  color: var(--dark-slate-blue);
  line-height: 60px;
  font-family: "Circe-Bold", sans-serif;
}
.manual-page_start .manual-page__title {
  margin-bottom: 50px;
}
.manual-page_check-citizenship .manual-page__title {
  margin-bottom: 50px;
}
.manual-page_registration .manual-page__title {
  max-width: 60%;
  line-height: 55px;
}
.manual-page_my-tax-1 .manual-page__title {
  margin-bottom: 29px;
  max-width: 80%;
}
.manual-page_my-tax-passport .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_my-tax-lk .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_my-tax-gosuslugi .manual-page__title {
  white-space: nowrap;
  margin-bottom: 28px;
}
.manual-page_web-lk .manual-page__title {
  margin-bottom: 27px;
}
.manual-page_web-gosuslugi .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_wallet .manual-page__title {
  margin-bottom: 39px;
}
.manual-page_sz-status-confirmation-1 .manual-page__title {
  margin-bottom: 28px;
}
.manual-page_cooperation-1 .manual-page__title {
  margin-bottom: 30px;
}
.manual-page_my-tax-2 .manual-page__text-list {
  margin-bottom: 60px;
  margin-top: 68px;
}
.manual-page_my-tax-passport .manual-page__text-list {
  margin-bottom: 40px;
  max-width: 70%;
}
.manual-page_my-tax-lk .manual-page__text-list {
  max-width: 90%;
  margin-bottom: 40px;
}
.manual-page_my-tax-gosuslugi .manual-page__text-list {
  margin-bottom: 43px;
  max-width: 90%;
}
.manual-page_web-lk .manual-page__text-list {
  margin-bottom: 30px;
  padding-left: 6px;
  max-width: 80%;
}
.manual-page_web-gosuslugi .manual-page__text-list {
  max-width: 80%;
  margin-bottom: 40px;
}
.manual-page_wallet .manual-page__text-list {
  max-width: 75%;
  margin-bottom: 43px;
}
.manual-page_sz-status-confirmation-1 .manual-page__text-list {
  max-width: 80%;
  margin-bottom: 40px;
}
.manual-page_sz-status-confirmation-2 .manual-page__text-list {
  max-width: 78%;
  margin-bottom: 43px;
}
.manual-page_sz-status-confirmation-3 .manual-page__text-list {
  max-width: 78%;
  margin-bottom: 40px;
}
.manual-page_cooperation-2 .manual-page__text-list {
  margin-bottom: 41px;
}
.manual-page__text {
  font-size: 18px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 30px;
}
.manual-page_registration .manual-page__text {
  margin-bottom: 40px;
  margin-top: 60px;
}
.manual-page_my-tax-1 .manual-page__text {
  margin-bottom: 40px;
  max-width: 90%;
}
.manual-page__text-list .manual-page__text {
  margin-bottom: 16px;
}
.manual-page__text-list .manual-page__text:last-child {
  margin-bottom: 0;
}
.manual-page_my-tax-gosuslugi .manual-page__text {
  margin-bottom: 20px;
  max-width: 86%;
}
.manual-page_web-version .manual-page__text {
  padding-top: 66px;
  margin-bottom: 60px;
}
.manual-page_web-lk .manual-page__text {
  color: #9da6ba;
  margin-bottom: 39px;
  max-width: 80%;
  padding-left: 5px;
  line-height: 32px;
}
.manual-page_web-gosuslugi .manual-page__text:first-child {
  margin-bottom: 20px;
}
.manual-page_web-gosuslugi .manual-page__text:last-child {
  color: #9da6ba;
}
.manual-page_sz-status-confirmation-1 .manual-page__text {
  max-width: 80%;
  margin-bottom: 20px;
}
.manual-page_sz-status-confirmation-2 .manual-page__text {
  max-width: 80%;
  margin-bottom: 20px;
}
.manual-page_sz-status-confirmation-3 .manual-page__text {
  margin-bottom: 20px;
}
.manual-page_cooperation-1 .manual-page__text {
  max-width: 80%;
  margin-bottom: 50px;
}
.manual-page_cooperation-2 .manual-page__text {
  max-width: 74%;
  margin-bottom: 33px;
}
.manual-page_cooperation-2 .manual-page__text:last-child {
  margin-bottom: 0;
}
.manual-page__text-link {
  font-size: 18px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 30px;
}
.manual-page__text-link:hover {
  text-decoration: none;
}
.manual-page__button-item {
  width: 320px;
  margin-bottom: 12px;
}
.manual-page__button-item:last-child {
  margin-bottom: 0;
}
.manual-page_my-tax-1 .manual-page__button-item {
  width: 340px;
}
.manual-page__list-item {
  margin-bottom: 16px;
  display: flex;
}
.manual-page__list-item:last-child {
  margin-bottom: 0;
}
.manual-page__list-item-number {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 30px;
  font-family: "Circe-Regular", sans-serif;
  min-width: 39px;
}
.manual-page__list-item-text {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.manual-page__list-item-text b {
  font-family: "Circe-Bold", sans-serif;
}
.manual-page__list-item-text a {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.manual-page__check {
  width: 290px;
  height: 364px;
  position: absolute;
}
.manual-page_start .manual-page__check {
  left: 124px;
  top: 149px;
}
.manual-page_check-citizenship .manual-page__check {
  left: 124px;
  top: 149px;
}
.manual-page_registration .manual-page__check {
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
  top: 452px;
  left: -13px;
}
.manual-page__status {
  position: absolute;
}
.manual-page_start .manual-page__status {
  top: 6px;
  left: 271px;
}
.manual-page_check-citizenship .manual-page__status {
  top: 6px;
  left: 271px;
}
.manual-page_registration .manual-page__status {
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
  top: 224px;
  right: 105px;
}
.manual-page__nalog1 {
  position: absolute;
  width: 380px;
  height: 280px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  top: 200px;
  left: -20px;
  z-index: 1;
  box-shadow: -10px 14px 60px rgba(232, 236, 242, 0.6);
}
.manual-page__nalog2 {
  width: 370px;
  height: 105px;
  position: absolute;
  -webkit-transform: rotate(6deg);
          transform: rotate(6deg);
  top: 292px;
  left: 223px;
  z-index: 5;
  box-shadow: -4px 14px 30px #e8ecf2;
}
.manual-page__shape {
  position: absolute;
}
.manual-page__shape_1 {
  width: 20px;
  height: 20px;
}
.manual-page_start .manual-page__shape_1 {
  top: -111px;
  left: 510px;
}
.manual-page_check-citizenship .manual-page__shape_1 {
  top: -111px;
  left: 510px;
}
.manual-page_registration .manual-page__shape_1 {
  top: 75px;
  right: 83px;
}
.manual-page_my-tax-2 .manual-page__shape_1 {
  top: 357px;
  right: 69px;
}
.manual-page_web-version .manual-page__shape_1 {
  top: 341px;
  right: 70px;
}
.manual-page_cooperation-1 .manual-page__shape_1 {
  top: -47px;
  right: 33px;
}
.manual-page__shape_2 {
  width: 30px;
  height: 30px;
}
.manual-page_start .manual-page__shape_2 {
  top: -70px;
  left: 200px;
}
.manual-page_check-citizenship .manual-page__shape_2 {
  top: -70px;
  left: 200px;
}
.manual-page_registration .manual-page__shape_2 {
  top: 445px;
  right: auto;
  left: -50px;
}
.manual-page_my-tax-2 .manual-page__shape_2 {
  top: 537px;
  right: 39px;
}
.manual-page_web-version .manual-page__shape_2 {
  top: 492px;
  right: 40px;
}
.manual-page_cooperation-1 .manual-page__shape_2 {
  top: -37px;
  right: 430px;
}
.manual-page__shape_3 {
  width: 60px;
  height: 60px;
}
.manual-page_start .manual-page__shape_3 {
  top: 340px;
  left: 131px;
}
.manual-page_check-citizenship .manual-page__shape_3 {
  top: 340px;
  left: 131px;
}
.manual-page_registration .manual-page__shape_3 {
  top: 274px;
  right: 378px;
}
.manual-page_my-tax-2 .manual-page__shape_3 {
  top: 583px;
  right: 260px;
  z-index: 10;
}
.manual-page_web-version .manual-page__shape_3 {
  top: 508px;
  right: 262px;
  z-index: 10;
}
.manual-page_cooperation-1 .manual-page__shape_3 {
  bottom: 55px;
  left: -35px;
  z-index: 10;
}
.manual-page__shape_4 {
  width: 30px;
  height: 30px;
}
.manual-page_start .manual-page__shape_4 {
  top: 440px;
  left: -54px;
}
.manual-page_check-citizenship .manual-page__shape_4 {
  top: 440px;
  left: -54px;
}
.manual-page_registration .manual-page__shape_4 {
  top: 417px;
  right: 136px;
}
.manual-page_my-tax-2 .manual-page__shape_4 {
  top: 738px;
  right: 570px;
}
.manual-page_web-version .manual-page__shape_4 {
  top: 628px;
  right: 569px;
}
.manual-page_cooperation-1 .manual-page__shape_4 {
  bottom: -141px;
  right: 746px;
  z-index: 10;
}
.manual-page__ruble {
  position: absolute;
}
.manual-page__ruble_1 {
  width: 80px;
  height: 90px;
}
.manual-page_start .manual-page__ruble_1 {
  left: 530px;
  top: 274px;
}
.manual-page_check-citizenship .manual-page__ruble_1 {
  left: 530px;
  top: 274px;
}
.manual-page_registration .manual-page__ruble_1 {
  right: -10px;
  top: 564px;
  width: 99px;
  height: 99px;
}
.manual-page_my-tax-2 .manual-page__ruble_1 {
  right: 230px;
  top: 314px;
  width: 65px;
  height: 73px;
  -webkit-transform: rotate(-11deg);
          transform: rotate(-11deg);
}
.manual-page_web-version .manual-page__ruble_1 {
  right: 199px;
  top: 264px;
  width: 68px;
  height: 75px;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
}
.manual-page_cooperation-1 .manual-page__ruble_1 {
  top: 48px;
  right: 53px;
  width: 122px;
  height: 142px;
  z-index: 10;
}
.manual-page__ruble_2 {
  width: 96px;
  height: 89px;
}
.manual-page_start .manual-page__ruble_2 {
  left: 47px;
  top: -27px;
}
.manual-page_check-citizenship .manual-page__ruble_2 {
  left: 47px;
  top: -27px;
}
.manual-page_registration .manual-page__ruble_2 {
  right: 276px;
  top: 472px;
}
.manual-page_my-tax-2 .manual-page__ruble_2 {
  right: 617px;
  top: 346px;
  width: 104px;
  height: 93px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
.manual-page_web-version .manual-page__ruble_2 {
  right: 581px;
  top: 303px;
  width: 106px;
  height: 99px;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}
.manual-page_cooperation-1 .manual-page__ruble_2 {
  top: 23px;
  right: 560px;
}
.manual-page_registration .manual-page__ruble_3 {
  left: -80px;
  top: 210px;
  width: 80px;
  height: 90px;
}
.manual-page_my-tax-2 .manual-page__ruble_3 {
  top: 747px;
  right: 126px;
  width: 126px;
  height: 142px;
}
.manual-page_web-version .manual-page__ruble_3 {
  right: 133px;
  top: 615px;
}
.manual-page__chat-button-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 50px;
  padding-right: 50px;
}
.manual-page__chat-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: var(--light-grey-blue);
  text-decoration: underline;
  font-family: "Circe-Regular", sans-serif;
}
.manual-page__chat-button:hover {
  text-decoration: none;
}
.manual-page__chat-button-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.manual-page__chat-button:hover .manual-page__chat-button-image {
  -webkit-animation: chat-animation 0.2s ease-in-out forwards;
          animation: chat-animation 0.2s ease-in-out forwards;
  -webkit-animation-iteration-count: 3;
          animation-iteration-count: 3;
}
.manual-page__chat-button-icon {
  width: 21px;
  height: 21px;
}
.manual-page__phone {
  position: absolute;
}
.manual-page__phone_1 {
  top: -56px;
  left: -80px;
  width: 502px;
  height: 690px;
  z-index: 10;
}
.manual-page__phone_2 {
  top: -36px;
  left: 134px;
  width: 430px;
  height: 592px;
  z-index: 5;
}
.manual-page_my-tax-2 .manual-page__phone_2 {
  top: 391px;
  left: 40px;
  width: 230px;
  height: 322px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.manual-page__phone_3 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  left: -36px;
}
.manual-page__phone_4 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  left: 278px;
}
.manual-page__phone_5 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  right: 100px;
}
.manual-page__phone_6 {
  max-width: 332px;
  max-height: 632px;
  top: 10px;
  right: 100px;
}
.manual-page__phone_7 {
  width: 321px;
  height: 337px;
  top: 322px;
  right: 274px;
  -webkit-transform: rotate(-1deg);
          transform: rotate(-1deg);
}
.manual-page__phone_8 {
  max-width: 240px;
  max-height: 413px;
  top: 140px;
  left: -80px;
  z-index: 5;
}
.manual-page__phone_9 {
  max-width: 240px;
  max-height: 413px;
  top: 140px;
  left: -80px;
  z-index: 5;
}
.manual-page__phone_10 {
  max-width: 240px;
  max-height: 413px;
  top: 130px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_11 {
  max-width: 240px;
  max-height: 413px;
  top: 123px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_12 {
  max-width: 240px;
  max-height: 413px;
  top: 123px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_13 {
  max-width: 240px;
  max-height: 413px;
  top: 123px;
  right: -60px;
  z-index: 5;
}
.manual-page__phone_14 {
  max-width: 267px;
  max-height: 460px;
  top: 153px;
  left: -54px;
  z-index: 5;
}
.manual-page__screen {
  position: absolute;
}
.manual-page__screen_1 {
  max-width: 611px;
  max-height: 365px;
  top: 50px;
  left: 30px;
  z-index: 1;
}
.manual-page__screen_2 {
  max-width: 611px;
  max-height: 365px;
  top: 50px;
  left: 30px;
  z-index: 1;
}
.manual-page__screen_3 {
  max-width: 658px;
  max-height: 365px;
  top: 44px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_4 {
  max-width: 658px;
  max-height: 365px;
  top: 32px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_5 {
  max-width: 658px;
  max-height: 365px;
  top: 32px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_6 {
  max-width: 658px;
  max-height: 365px;
  top: 32px;
  left: -90px;
  z-index: 1;
}
.manual-page__screen_7 {
  max-width: 746px;
  max-height: 500px;
  top: 52px;
  left: -93px;
  z-index: 1;
}
.manual-page__app-buttons-list {
  display: flex;
  margin-bottom: 11px;
}
.manual-page__app-buttons-item {
  margin-right: 12px;
  text-decoration: none;
  width: 164px;
}
.manual-page__app-buttons-item:nth-child(2) {
  margin-right: 0;
}
.manual-page__app-buttons-item-img {
  max-width: 100%;
}

@media screen and (max-width: 1149px) {
  .manual-page__header-wrap {
    margin-bottom: 115px;
  }
  .manual-page_start .manual-page__header-wrap {
    margin-bottom: 115px;
  }
  .manual-page_check-citizenship .manual-page__title {
    font-size: 40px;
  }
  .manual-page_registration .manual-page__title {
    max-width: 80%;
  }
  .manual-page_my-tax-passport .manual-page__text-list {
    max-width: 95%;
  }
  .manual-page__col {
    width: 460px;
    min-width: 460px;
  }
  .manual-page__col:nth-child(2) {
    width: calc(100% - 460px - 60px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_start .manual-page__check {
    left: -6px;
    top: 149px;
  }
  .manual-page_check-citizenship .manual-page__check {
    left: -6px;
    top: 149px;
  }
  .manual-page_start .manual-page__status {
    top: -24px;
    left: 141px;
  }
  .manual-page_check-citizenship .manual-page__status {
    top: -24px;
    left: 141px;
  }
  .manual-page_registration .manual-page__shape_1 {
    left: 424px;
    top: 400px;
  }
  .manual-page_start .manual-page__shape_3 {
    left: 0;
  }
  .manual-page_check-citizenship .manual-page__shape_3 {
    left: 0;
  }
  .manual-page_start .manual-page__shape_4 {
    top: 500px;
    left: -164px;
  }
  .manual-page_check-citizenship .manual-page__shape_4 {
    top: 500px;
    left: -164px;
  }
  .manual-page_start .manual-page__ruble_2 {
    left: 390px;
  }
  .manual-page_check-citizenship .manual-page__ruble_2 {
    left: 390px;
  }
  .manual-page_registration .manual-page__ruble_2 {
    right: 176px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_2 {
    right: 410px;
  }
  .manual-page__phone_1 {
    left: -150px;
  }
  .manual-page__phone_2 {
    left: 64px;
  }
  .manual-page_my-tax-2 .manual-page__phone_2 {
    left: -60px;
  }
  .manual-page__phone_3 {
    max-width: 302px;
    max-height: 582px;
  }
  .manual-page__phone_4 {
    max-width: 302px;
    max-height: 582px;
    left: 238px;
  }
  .manual-page__phone_6 {
    right: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .manual-page__header-logo {
    margin-right: 32px;
  }
}
@media screen and (max-width: 980px) {
  .manual-page__header-wrap {
    padding-top: 23px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .manual-page_registration .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_registration .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-2 .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_my-tax-2 .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-passport .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_my-tax-passport .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-lk .manual-page__col {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }
  .manual-page_my-tax-lk .manual-page__col:nth-child(2) {
    width: calc(100% - 340px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_web-version .manual-page__col {
    width: 360px;
    min-width: 360px;
    margin-right: 20px;
  }
  .manual-page_web-version .manual-page__col:nth-child(2) {
    width: calc(100% - 360px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_web-lk .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 20px;
  }
  .manual-page_web-lk .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_web-gosuslugi .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 20px;
  }
  .manual-page_web-gosuslugi .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_wallet .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 20px;
  }
  .manual-page_wallet .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 20px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_cooperation-2 .manual-page__col {
    width: 390px;
    min-width: 390px;
    margin-right: 40px;
  }
  .manual-page_cooperation-2 .manual-page__col:nth-child(2) {
    width: calc(100% - 390px - 40px);
    min-width: auto;
    margin-right: 0;
  }
  .manual-page_my-tax-gosuslugi .manual-page__title {
    white-space: normal;
  }
  .manual-page_wallet .manual-page__text-list {
    max-width: 95%;
  }
  .manual-page_start .manual-page__check {
    top: 169px;
    left: -40px;
  }
  .manual-page_check-citizenship .manual-page__check {
    top: 169px;
    left: -40px;
  }
  .manual-page_start .manual-page__status {
    left: 41px;
    top: 6px;
  }
  .manual-page_check-citizenship .manual-page__status {
    left: 41px;
    top: 6px;
  }
  .manual-page_registration .manual-page__status {
    left: 79px;
  }
  .manual-page_start .manual-page__shape_1 {
    top: -81px;
    left: 320px;
  }
  .manual-page_check-citizenship .manual-page__shape_1 {
    top: -81px;
    left: 320px;
  }
  .manual-page_registration_2 .manual-page__shape_1 {
    top: 15px;
    right: 33px;
  }
  .manual-page_start .manual-page__shape_2 {
    left: 170px;
  }
  .manual-page_check-citizenship .manual-page__shape_2 {
    left: 170px;
  }
  .manual-page_registration_2 .manual-page__shape_2 {
    top: 525px;
    left: -60px;
  }
  .manual-page_start .manual-page__shape_3 {
    left: -30px;
    top: 360px;
  }
  .manual-page_check-citizenship .manual-page__shape_3 {
    left: -30px;
    top: 360px;
  }
  .manual-page_my-tax-2 .manual-page__shape_3 {
    right: 150px;
  }
  .manual-page_registration .manual-page__shape_3 {
    left: 102px;
  }
  .manual-page_start .manual-page__shape_4 {
    left: -194px;
  }
  .manual-page_check-citizenship .manual-page__shape_4 {
    left: -194px;
  }
  .manual-page_start .manual-page__ruble_1 {
    left: 247px;
  }
  .manual-page_check-citizenship .manual-page__ruble_1 {
    left: 247px;
  }
  .manual-page_registration_2 .manual-page__ruble_1 {
    right: -60px;
    top: 614px;
  }
  .manual-page_my-tax-2 .manual-page__ruble_1 {
    display: none;
  }
  .manual-page_start .manual-page__ruble_2 {
    top: -117px;
    left: -60px;
  }
  .manual-page_check-citizenship .manual-page__ruble_2 {
    top: -117px;
    left: -60px;
  }
  .manual-page_registration_2 .manual-page__ruble_2 {
    right: 56px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_2 {
    right: 270px;
  }
  .manual-page_registration_2 .manual-page__ruble_3 {
    display: none;
  }
  .manual-page__phone_1 {
    width: 382px;
    height: 550px;
  }
  .manual-page__phone_2 {
    width: 334px;
    height: 492px;
  }
  .manual-page__phone_3 {
    max-width: 282px;
    max-height: 542px;
  }
  .manual-page__phone_4 {
    max-width: 282px;
    max-height: 542px;
    left: 218px;
  }
  .manual-page__phone_5 {
    right: -60px;
  }
  .manual-page__phone_10 {
    right: -100px;
  }
  .manual-page__phone_14 {
    top: 103px;
    left: -84px;
  }
  .manual-page__screen_3 {
    left: -30px;
  }
  .manual-page__screen_7 {
    max-width: 616px;
    max-height: 420px;
    top: 30px;
  }
}
@media screen and (max-width: 760px) {
  .manual-page__header-wrap {
    margin-bottom: 79px;
  }
  .manual-page_start .manual-page__header-wrap {
    margin-bottom: 0;
  }
  .manual-page_check-citizenship .manual-page__header-wrap {
    margin-bottom: 0;
  }
  .manual-page_registration .manual-page__header-wrap {
    margin-bottom: 90px;
  }
  .manual-page_my-tax-1 .manual-page__header-wrap {
    margin-bottom: 80px;
  }
  .manual-page__top-wrap {
    margin-bottom: 77px;
  }
  .manual-page__return-link {
    margin-bottom: 19px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__return-link {
    margin-bottom: 39px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__return-link {
    margin-bottom: 39px;
  }
  .manual-page_cooperation-2 .manual-page__return-link {
    margin-bottom: 39px;
  }
  .manual-page__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .manual-page__content-wrap {
    width: 100%;
  }
  .manual-page_start .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_check-citizenship .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_registration .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_my-tax-1 .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_my-tax-2 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_my-tax-passport .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_my-tax-lk .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_my-tax-gosuslugi .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_web-version .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_web-lk .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_web-gosuslugi .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page_cooperation-1 .manual-page__content-wrap {
    flex-direction: column-reverse;
  }
  .manual-page_cooperation-2 .manual-page__content-wrap {
    flex-direction: column;
  }
  .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_web-version .manual-page__col:first-child {
    height: auto;
  }
  .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 100px;
  }
  .manual-page_start .manual-page__col:nth-child(2) {
    height: 329px;
    padding-bottom: 0;
  }
  .manual-page_check-citizenship .manual-page__col:nth-child(2) {
    height: 310px;
    padding-bottom: 0;
  }
  .manual-page_my-tax-1 .manual-page__col:nth-child(2) {
    height: 536px;
    padding-bottom: 0;
  }
  .manual-page_registration .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_registration .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_registration_2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_registration_2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_my-tax-2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_my-tax-2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_my-tax-passport .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_my-tax-passport .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    height: 508px;
  }
  .manual-page_my-tax-lk .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_my-tax-gosuslugi .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-version .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-version .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_web-lk .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-lk .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_web-gosuslugi .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_web-gosuslugi .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_wallet .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_wallet .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page_cooperation-1 .manual-page__col:nth-child(2) {
    height: 498px;
  }
  .manual-page_cooperation-2 .manual-page__col {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    padding-bottom: 0;
  }
  .manual-page_cooperation-2 .manual-page__col:nth-child(2) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .manual-page__images-container {
    height: 312px;
    position: relative;
    margin-bottom: 15px;
  }
  .manual-page_wallet .manual-page__images-container {
    margin-bottom: 6px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__images-container {
    margin-bottom: 4px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__images-container {
    margin-bottom: 35px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__images-container {
    margin-bottom: 33px;
  }
  .manual-page_cooperation-2 .manual-page__images-container {
    margin-bottom: 92px;
  }
  .manual-page__title {
    font-size: 28px;
    line-height: 40px;
  }
  .manual-page_start .manual-page__title {
    margin-bottom: 30px;
  }
  .manual-page_check-citizenship .manual-page__title {
    margin-bottom: 30px;
    font-size: 28px;
  }
  .manual-page_registration .manual-page__title {
    margin-bottom: 20px;
  }
  .manual-page_my-tax-2 .manual-page__title {
    margin-bottom: 24px;
  }
  .manual-page_web-version .manual-page__title {
    margin-bottom: 22px;
  }
  .manual-page_web-gosuslugi .manual-page__title {
    margin-bottom: 28px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__title {
    margin-bottom: 38px;
  }
  .manual-page_my-tax-2 .manual-page__text-list {
    margin-top: 0;
    margin-bottom: 74px;
  }
  .manual-page_my-tax-passport .manual-page__text-list {
    margin-bottom: 73px;
  }
  .manual-page_my-tax-lk .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 72px;
  }
  .manual-page_my-tax-gosuslugi .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 73px;
  }
  .manual-page_web-lk .manual-page__text-list {
    max-width: 100%;
    padding-left: 0;
  }
  .manual-page_web-gosuslugi .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 71px;
  }
  .manual-page_wallet .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 71px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 73px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__text-list {
    max-width: 100%;
    margin-bottom: 28px;
  }
  .manual-page_cooperation-2 .manual-page__text-list {
    margin-bottom: 30px;
  }
  .manual-page_registration .manual-page__text {
    margin-top: 0;
  }
  .manual-page_my-tax-1 .manual-page__text {
    margin-bottom: 72px;
  }
  .manual-page_my-tax-gosuslugi .manual-page__text {
    max-width: 100%;
    margin-bottom: 33px;
  }
  .manual-page_web-version .manual-page__text {
    padding-top: 0;
    margin-bottom: 72px;
  }
  .manual-page_web-lk .manual-page__text {
    max-width: 100%;
    margin-bottom: 67px;
  }
  .manual-page_web-gosuslugi .manual-page__text:first-child {
    margin-bottom: 31px;
  }
  .manual-page_sz-status-confirmation-1 .manual-page__text {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .manual-page_sz-status-confirmation-2 .manual-page__text {
    max-width: 100%;
    margin-bottom: 33px;
  }
  .manual-page_sz-status-confirmation-3 .manual-page__text {
    max-width: 100%;
    margin-bottom: 24px;
  }
  .manual-page_cooperation-1 .manual-page__text {
    max-width: 100%;
    margin-bottom: 72px;
  }
  .manual-page_cooperation-2 .manual-page__text {
    max-width: 100%;
  }
  .manual-page__button-item {
    width: 100%;
    max-width: 335px;
  }
  .manual-page__check {
    width: 215px;
    height: 248px;
  }
  .manual-page_start .manual-page__check {
    top: 64px;
    left: 50px;
  }
  .manual-page_check-citizenship .manual-page__check {
    top: 64px;
    left: 50px;
  }
  .manual-page_registration .manual-page__check {
    display: none;
  }
  .manual-page__status {
    width: 228px;
    height: 144px;
  }
  .manual-page_start .manual-page__status {
    top: -12px;
    left: 155px;
  }
  .manual-page_check-citizenship .manual-page__status {
    top: -12px;
    left: 155px;
  }
  .manual-page_registration .manual-page__status {
    display: none;
  }
  .manual-page__nalog1 {
    width: 238.54px;
    height: 175.77px;
    top: 237px;
    left: 21px;
  }
  .manual-page__nalog2 {
    width: 232.27px;
    height: 65.91px;
    top: 298px;
    left: 173px;
  }
  .manual-page__shape_1 {
    width: 13px;
    height: 13px;
  }
  .manual-page_start .manual-page__shape_1 {
    top: 137px;
    left: 316px;
  }
  .manual-page_check-citizenship .manual-page__shape_1 {
    top: 137px;
    left: 316px;
  }
  .manual-page_cooperation-1 .manual-page__shape_1 {
    top: 125px;
    right: 95px;
  }
  .manual-page__shape_2 {
    width: 20px;
    height: 20px;
  }
  .manual-page_start .manual-page__shape_2 {
    top: 31px;
    left: 100px;
  }
  .manual-page_check-citizenship .manual-page__shape_2 {
    top: 31px;
    left: 100px;
  }
  .manual-page_cooperation-1 .manual-page__shape_2 {
    top: 31px;
    right: 54px;
  }
  .manual-page__shape_3 {
    width: 40px;
    height: 40px;
  }
  .manual-page_start .manual-page__shape_3 {
    top: 200px;
    left: 70px;
  }
  .manual-page_check-citizenship .manual-page__shape_3 {
    top: 200px;
    left: 70px;
  }
  .manual-page_cooperation-1 .manual-page__shape_3 {
    bottom: 74px;
    left: 9px;
  }
  .manual-page__shape_4 {
    width: 20px;
    height: 20px;
  }
  .manual-page_start .manual-page__shape_4 {
    left: -30px;
    top: 210px;
  }
  .manual-page_check-citizenship .manual-page__shape_4 {
    left: -30px;
    top: 210px;
  }
  .manual-page_cooperation-1 .manual-page__shape_4 {
    bottom: 39px;
    right: 13px;
  }
  .manual-page_registration .manual-page__shape {
    display: none;
  }
  .manual-page_start .manual-page__ruble_1 {
    top: 231px;
    width: 52px;
    height: 60px;
    -webkit-transform: rotate(-21deg);
            transform: rotate(-21deg);
    left: 264px;
  }
  .manual-page_check-citizenship .manual-page__ruble_1 {
    top: 231px;
    width: 52px;
    height: 60px;
    -webkit-transform: rotate(-21deg);
            transform: rotate(-21deg);
    left: 264px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_1 {
    width: 78px;
    height: 89.67px;
    top: 123px;
    right: -32px;
  }
  .manual-page_start .manual-page__ruble_2 {
    left: -18px;
    top: 88px;
    width: 69px;
    height: 63px;
  }
  .manual-page_check-citizenship .manual-page__ruble_2 {
    left: -18px;
    top: 88px;
    width: 69px;
    height: 63px;
  }
  .manual-page_cooperation-1 .manual-page__ruble_2 {
    width: 66px;
    height: 62px;
    right: auto;
    left: -25px;
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
    top: 145px;
  }
  .manual-page_registration .manual-page__ruble {
    display: none;
  }
  .manual-page__phone_1 {
    width: 296px;
    height: 416px;
    left: -75px;
    top: 139px;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  .manual-page__phone_2 {
    width: 263px;
    height: 363px;
    left: 68px;
    top: 147px;
  }
  .manual-page__phone_3 {
    width: 192px;
    height: 366px;
    top: 120px;
    left: -16px;
  }
  .manual-page__phone_4 {
    width: 192px;
    height: 366px;
    top: 120px;
    left: 158px;
  }
  .manual-page__phone_5 {
    position: relative;
    left: -17px;
    top: auto;
    right: auto;
    width: 192px;
    height: 366px;
    margin-bottom: 20px;
  }
  .manual-page__phone_6 {
    position: relative;
    left: -17px;
    top: auto;
    right: auto;
    width: 192px;
    height: 366px;
    margin-bottom: 20px;
  }
  .manual-page__phone_8 {
    width: 182px;
    height: 312px;
    top: 5px;
    left: -30px;
  }
  .manual-page__phone_9 {
    width: 182px;
    height: 312px;
    top: 5px;
    left: -30px;
  }
  .manual-page__phone_10 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -10px;
  }
  .manual-page__phone_11 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -10px;
  }
  .manual-page__phone_12 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -6px;
  }
  .manual-page__phone_13 {
    width: 182px;
    height: 312px;
    right: auto;
    left: 182px;
    top: -6px;
  }
  .manual-page__phone_14 {
    width: 182px;
    height: 312px;
    left: -13px;
    top: 52px;
  }
  .manual-page__screen_1 {
    width: 292px;
    height: 176px;
    left: 54px;
  }
  .manual-page__screen_2 {
    width: 292px;
    height: 176px;
    left: 54px;
  }
  .manual-page__screen_3 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_4 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_5 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_6 {
    width: 318px;
    height: 176px;
    top: 40px;
    left: -10px;
  }
  .manual-page__screen_7 {
    width: 375px;
    height: 252px;
    top: 0;
    left: -20px;
  }
  .manual-page__chat-button-wrap {
    padding-bottom: 30px;
    padding-right: 20px;
  }
}
@-webkit-keyframes chat-animation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes chat-animation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.se-steps__item {
  font-size: 15px;
  line-height: 1.6;
  color: white;
  font-family: "Circe-Regular", sans-serif;
}
.se-steps__item_current {
  margin-right: 2px;
  font-family: "Circe-Bold", sans-serif;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.get-sz-inn-input {
  position: relative;
}
.get-sz-inn-input__input {
  height: 60px;
  border-radius: 30px;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
}
.get-sz-inn-input__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.get-sz-inn-input__input::-ms-clear {
  display: none;
}
.get-sz-inn-input__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.get-sz-inn-input_success .get-sz-inn-input__input {
  color: var(--turquoise-blue);
  -webkit-text-fill-color: var(--turquoise-blue);
  font-size: 18px;
}
.get-sz-inn-input_fail .get-sz-inn-input__input {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  -webkit-text-fill-color: var(--reddish-pink);
  font-size: 18px;
  background: white;
}
.get-sz-inn-input__input:disabled {
  opacity: 1;
}
.get-sz-inn-input__edit {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  padding: 0;
  margin: -10px 0 0 0;
}
.get-sz-inn-input_fail .get-sz-inn-input__edit {
  right: 53px;
}
.get-sz-inn-input__lock {
  right: 22px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
}
.get-sz-inn-input_fail .get-sz-inn-input__lock path {
  fill: var(--reddish-pink);
}
.get-sz-inn-input__check {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -6px;
}
.get-sz-inn-input__check path {
  stroke: var(--dark-slate-blue);
}
.get-sz-inn-input__loader-wrap {
  position: absolute;
  right: -70px;
  top: 0;
}
.get-sz-inn-input__loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 15px;
  margin-top: -10px;
  margin-left: -6px;
}
.get-sz-inn-input__loader-icon path {
  fill: white;
}
@media screen and (max-width: 980px) {
  .get-sz-inn-input__input {
    box-shadow: 0 4px 27px 0 #e8ecf2;
  }
  .get-sz-inn-input__input:disabled {
    background-color: rgba(228, 232, 238, 0.2);
    box-shadow: none;
    border: solid 1px #9da6ba;
  }
  .get-sz-inn-input_success .get-sz-inn-input__input:disabled {
    border: solid 1px var(--turquoise-blue);
    background: white;
  }
  .get-sz-inn-input__edit {
    right: 50px;
  }
  .get-sz-inn-input_fail .get-sz-inn-input__edit {
    right: 20px;
  }
  .get-sz-inn-input__loader-wrap {
    right: 20px;
    top: 22px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint-cancel-request-sz-status__title {
  font-size: 28px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 30px;
}
.hint-cancel-request-sz-status__text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  margin-bottom: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.hint-cancel-request-sz-status__text-link {
  font-size: 15px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-cancel-request-sz-status__text-link:hover {
  text-decoration: none;
}
.hint-cancel-request-sz-status__button-wrap {
  display: flex;
  margin-bottom: 20px;
}
.hint-cancel-request-sz-status__button {
  min-width: 189px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  height: 60px;
  padding: 0 37px;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
  line-height: 60px;
  text-decoration: none;
}
.hint-cancel-request-sz-status__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.hint-cancel-request-sz-status__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.hint-cancel-request-sz-status__continue-button-wrap {
  display: flex;
}
.hint-cancel-request-sz-status__continue-button {
  font-size: 16px;
  color: var(--dark-slate-blue);
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding-bottom: 6px;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.hint-cancel-request-sz-status__continue-button:hover {
  border-bottom-color: transparent;
}

@media screen and (max-width: 980px) {
  .hint-cancel-request-sz-status__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.balloon {
  background: white;
  padding: 29px 39px 50px 39px;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
}
.balloon__triangle {
  position: absolute;
  bottom: -21px;
  left: 22px;
  width: 29px;
  height: 23px;
}
.balloon__text {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-right: 10px;
}
.balloon__link {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-left: 5px;
  margin-right: 5px;
}
.balloon__link:hover {
  text-decoration: none;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.warning {
  background: rgba(255, 45, 85, 0.05);
  padding: 16px 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
}
.warning_static {
  position: static;
}
.warning_position_absolute {
  position: absolute;
}
.warning__container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}
.warning__unconfirmed-e-mail .warning__container {
  flex-wrap: wrap;
}
.warning__icon {
  margin-right: 10px;
  min-width: 20px;
}
.warning__text {
  flex-grow: 20;
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.warning__unconfirmed-e-mail .warning__text {
  width: calc(100% - 30px);
  min-width: calc(100% - 30px);
}
.warning__text_error {
  color: var(--reddish-pink);
}
.warning__unconfirmed-e-mail .warning__text_error {
  display: inline;
}
.warning__link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: var(--grey-blue);
  text-decoration: underline;
}
.warning__link:hover {
  text-decoration: none;
}
.warning__link_confirm {
  margin-left: 0;
}

@media screen and (max-width: 600px) {
  .warning__container {
    padding-left: 20px;
    padding-right: 10px;
    align-items: flex-start;
  }
  .warning_text .warning__container {
    align-items: center;
  }
  .warning_text .warning__icon {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
  .warning__text {
    line-height: 1.2;
  }
  .warning__link {
    line-height: 1.2;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.footer {
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  opacity: 1;
  z-index: 1;
}
.footer-enter {
  opacity: 0;
  visibility: hidden;
}
.footer-enter-done {
  opacity: 1;
  visibility: visible;
}
.footer-exit {
  opacity: 0;
  visibility: visible;
}
.footer-exit-active {
  opacity: 0;
}
.footer_menu-open {
  z-index: 10;
}
.footer_grey {
  background: var(--pale-grey);
}
.footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
}
.footer__already-registered-wrap {
  margin-top: -3px;
}
.footer__already-registered {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  z-index: 65;
}
.footer__already-registered:hover {
  text-decoration: none;
}
.footer__chat-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: var(--light-grey-blue);
  margin: 0 16px 0 0;
  text-decoration: underline;
  font-family: "Circe-Regular", sans-serif;
  position: relative;
  z-index: 65;
}
.footer__chat-button:hover {
  text-decoration: none;
}
.footer__chat-button-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.footer__chat-button:hover .footer__chat-button-image {
  -webkit-animation: chat-animation 0.2s ease-in-out forwards;
          animation: chat-animation 0.2s ease-in-out forwards;
  -webkit-animation-iteration-count: 3;
          animation-iteration-count: 3;
}
.footer__chat-button-icon {
  width: 21px;
  height: 21px;
}

@media screen and (max-width: 600px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 50px;
    min-height: 134px;
  }
  .footer_login {
    padding-top: 60px;
    background: var(--pale-grey);
  }
  .footer__container {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer__already-registered-wrap {
    margin-bottom: 22px;
    margin-top: 0;
  }
}
@-webkit-keyframes chat-animation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes chat-animation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.se-from-lk-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.se-from-lk {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
}
.se-from-lk__header {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  padding-top: 102px;
  padding-bottom: 155px;
  overflow: hidden;
  position: relative;
  will-change: transform;
}
.se-from-lk_enter-inn .se-from-lk__header {
  padding-top: 86px;
  padding-bottom: 151px;
}
.se-from-lk_waiting .se-from-lk__header {
  padding-top: 88px;
  padding-bottom: 110px;
}
.se-from-lk_success .se-from-lk__header {
  padding-top: 87px;
  padding-bottom: 117px;
}
.se-from-lk_fail .se-from-lk__header {
  padding-top: 86px;
  padding-bottom: 115px;
}
.se-from-lk__header-container {
  position: relative;
  z-index: 20;
}
.se-from-lk__header-bg {
  width: 773px;
  height: 943px;
  position: absolute;
  right: -125px;
  top: 50%;
  margin-top: -534px;
  z-index: 10;
}
.se-from-lk_enter-inn .se-from-lk__header-bg {
  right: -275px;
  margin-top: -554px;
}
.se-from-lk_fail .se-from-lk__header-bg {
  right: -277px;
  top: 50%;
  margin-top: -554px;
}
.se-from-lk__header-close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 50px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  background: white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.se-from-lk__header-close-icon path {
  transition: all 0.3s ease;
}
.se-from-lk__header-close:hover .se-from-lk__header-close-icon path {
  stroke: var(--reddish-pink);
}
.se-from-lk_waiting .se-from-lk__header-close-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.se-from-lk_waiting .se-from-lk__header-close-icon path {
  stroke: var(--dark-slate-blue);
}
.se-from-lk__header-step {
  margin-bottom: 10px;
}
.se-from-lk_enter-inn .se-from-lk__header-step {
  margin-bottom: 15px;
}
.se-from-lk_waiting .se-from-lk__header-step {
  margin-bottom: 13px;
}
.se-from-lk_success .se-from-lk__header-step {
  margin-bottom: 13px;
}
.se-from-lk__header-title {
  font-size: 36px;
  line-height: 1.33;
  color: white;
  margin-bottom: 10px;
  font-family: "Circe-ExtraBold", sans-serif;
}
.se-from-lk_waiting .se-from-lk__header-title {
  max-width: 450px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.se-from-lk_success .se-from-lk__header-title {
  max-width: 336px;
  line-height: 1.46;
  margin-bottom: 20px;
}
.se-from-lk_fail .se-from-lk__header-title {
  max-width: 442px;
  line-height: 1.46;
  margin-bottom: 20px;
}
.se-from-lk__header-desc {
  font-size: 18px;
  line-height: 1.5;
  color: white;
  font-family: "Circe-Regular", sans-serif;
  position: relative;
  max-width: 470px;
  width: 100%;
}
.se-from-lk_enter-inn .se-from-lk__header-desc {
  margin-bottom: 60px;
}
.se-from-lk_waiting .se-from-lk__header-desc {
  margin-bottom: 30px;
}
.se-from-lk_success .se-from-lk__header-desc {
  max-width: 531px;
  margin-bottom: 27px;
}
.se-from-lk_fail .se-from-lk__header-desc {
  max-width: 531px;
  margin-bottom: 33px;
}
.se-from-lk__content .se-from-lk__header-desc {
  color: var(--bluey-grey);
  line-height: 1.33;
}
.se-from-lk__header-desc-link {
  color: var(--dark-slate-blue);
  font-size: 16px;
  line-height: 1.5;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
}
.se-from-lk__header-desc-link:hover {
  text-decoration: none;
}
.se-from-lk__header-desc-warning-icon {
  position: absolute;
  left: -30px;
  top: 2px;
}
.se-from-lk__phone {
  width: 405px;
  height: 354px;
  object-fit: contain;
  position: absolute;
  bottom: 70px;
  right: 19%;
  z-index: 40;
  pointer-events: none;
}
.se-from-lk__form {
  display: flex;
  max-width: 490px;
  width: 100%;
}
.se-from-lk__form-input-holder {
  width: calc(100% - 70px);
  margin-right: 10px;
}
.se-from-lk__next-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--dusk-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.se-from-lk__next-btn:hover:not(:disabled) {
  background: var(--dark-slate-blue);
}
.se-from-lk__next-btn:disabled {
  background: #f6f8fb;
  cursor: not-allowed;
}
.se-from-lk__next-btn-icon {
  margin-left: 5px;
}
.se-from-lk__next-btn-icon use {
  fill: white;
}
.se-from-lk__next-btn:disabled .se-from-lk__next-btn-icon path {
  stroke: #b0b5c4;
}
.se-from-lk__content {
  flex-grow: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: 60px;
  padding-bottom: 180px;
}
.se-from-lk_waiting .se-from-lk__content {
  justify-content: flex-start;
}
.se-from-lk_fail .se-from-lk__content {
  padding-top: 63px;
  flex-direction: column-reverse;
}
.se-from-lk__wallet-btn-wrap {
  margin-bottom: 50px;
  max-width: 335px;
}
.se-from-lk__hint-block .hints-list__title {
  margin-bottom: 16px;
}
.se-from-lk__loader {
  position: relative;
}
.se-from-lk__loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 15px;
  margin-top: -10px;
  margin-left: -6px;
}
.se-from-lk__loader-icon path {
  fill: white;
}
.se-from-lk__info {
  position: absolute;
  left: calc(55% - 7px);
  top: 41%;
}
.se-from-lk_fail .se-from-lk__info {
  padding: 29px 49px 29px 39px;
}
.se-from-lk__info-icon_wink {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-from-lk__info-icon_rock {
  width: 18px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.se-from-lk__info-icon_sad {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
  margin-right: 5px;
}
.se-from-lk__info-icon_man {
  width: 20px;
  height: 19px;
  display: inline-block;
  vertical-align: -3px;
}
.se-from-lk__loader-wrap {
  position: relative;
}
.se-from-lk__loader {
  position: relative;
}
.se-from-lk__btn-wrap {
  display: flex;
  max-width: 420px;
  width: 100%;
}
.se-from-lk__btn-wrap_help {
  margin-top: 20px;
}

@media screen and (max-width: 1100px) {
  .se-from-lk__info {
    left: 60%;
  }
  .se-from-lk__phone {
    right: 6%;
  }
}
@media screen and (max-width: 980px) {
  .se-from-lk__header {
    padding-top: 68px;
    padding-bottom: 79px;
  }
  .se-from-lk_enter-inn .se-from-lk__header {
    padding-bottom: 79px;
  }
  .se-from-lk__header-title {
    max-width: 227px;
  }
  .se-from-lk_waiting .se-from-lk__header-title {
    font-size: 28px;
  }
  .se-from-lk_success .se-from-lk__header-title {
    margin-bottom: 0;
  }
  .se-from-lk_fail .se-from-lk__header-title {
    margin-bottom: 0;
  }
  .se-from-lk_enter-inn .se-from-lk__content .se-from-lk__header-desc {
    margin-bottom: 30px;
    color: var(--bluey-grey);
  }
  .se-from-lk_waiting .se-from-lk__content .se-from-lk__header-desc {
    color: var(--bluey-grey);
  }
  .se-from-lk_success .se-from-lk__content .se-from-lk__header-desc {
    color: var(--grey-blue);
    font-size: 18px;
    margin-bottom: 30px;
  }
  .se-from-lk_waiting .se-from-lk__content .se-from-lk__header-desc-warning-icon {
    position: relative;
    top: 2px;
    left: auto;
    display: inline;
    margin-right: 10px;
  }
  .se-from-lk__header-bg {
    width: 331px;
    height: 336px;
    margin-top: 0;
    right: -111px;
    top: -37px;
  }
  .se-from-lk_enter-inn .se-from-lk__header-bg {
    right: -111px;
    margin-top: 0;
    top: auto;
    bottom: 0;
  }
  .se-from-lk_success .se-from-lk__header-bg {
    top: -17px;
  }
  .se-from-lk_fail .se-from-lk__header-bg {
    top: -17px;
    margin-top: 0;
    right: -111px;
  }
  .se-from-lk__header-step {
    margin-bottom: 0;
    position: absolute;
    right: 26px;
    top: 24px;
    z-index: 40;
  }
  .se-from-lk__header-close {
    top: 30px;
    right: auto;
    left: 46px;
    box-shadow: none;
    background: transparent;
    width: 20px;
    height: 20px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .se-from-lk_waiting .se-from-lk__header-close {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .se-from-lk__header-close:hover .se-from-lk__header-close-icon path {
    stroke: var(--reddish-pink);
  }
  .se-from-lk_waiting .se-from-lk__header-close-icon path {
    stroke: white;
  }
  .se-from-lk__form {
    flex-direction: column;
    max-width: 420px;
  }
  .se-from-lk_enter-inn .se-from-lk__form {
    margin-bottom: 33px;
  }
  .se-from-lk_waiting .se-from-lk__form {
    flex-direction: row;
  }
  .se-from-lk_fail .se-from-lk__form {
    margin-bottom: 33px;
  }
  .se-from-lk__form-input-holder {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .se-from-lk__small-loader {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .se-from-lk__content {
    padding-bottom: 60px;
  }
  .se-from-lk_success .se-from-lk__content {
    justify-content: flex-start;
  }
  .se-from-lk_fail .se-from-lk__content {
    justify-content: flex-start;
    flex-direction: column;
  }
  .se-from-lk__info {
    position: relative;
    left: auto;
    top: auto;
    padding: 0;
    max-width: 376px;
    margin-bottom: 30px;
  }
  .se-from-lk__info:after {
    display: none;
  }
  .se-from-lk__hint-block {
    margin-bottom: 50px;
  }
  .se-from-lk_enter-inn .se-from-lk__hint-block {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .se-from-lk_enter-inn .se-from-lk__header {
    padding-bottom: 37px;
    padding-top: 67px;
  }
  .se-from-lk_fail .se-from-lk__header {
    padding-top: 68px;
    padding-bottom: 80px;
  }
  .se-from-lk_waiting .se-from-lk__header {
    padding-bottom: 90px;
  }
  .se-from-lk__header-title {
    font-size: 28px;
    font-family: "Circe-Bold", sans-serif;
  }
  .se-from-lk_waiting .se-from-lk__header-title {
    max-width: 300px;
    margin-bottom: 0;
  }
  .se-from-lk_fail .se-from-lk__header-title {
    line-height: 1.3;
    max-width: 330px;
  }
  .se-from-lk_fail .se-from-lk__header-bg {
    top: -51px;
    margin-top: 0;
    right: -46px;
    width: 231px;
    height: 276px;
  }
  .se-from-lk__header-close {
    left: 20px;
  }
  .se-from-lk_fail .se-from-lk__form-input-holder {
    margin-bottom: 0;
  }
  .se-from-lk__content {
    padding-top: 40px;
  }
  .se-from-lk_fail .se-from-lk__content {
    padding-top: 50px;
  }
}
@media screen and (max-width: 350px) {
  .se-from-lk_fail .se-from-lk__header {
    padding-bottom: 48px;
  }
}
.small-spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  width: 20px;
  height: 20px;
}
.small-spinner .path {
  stroke: var(--bluey-grey);
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}
.small-spinner .total {
  stroke: var(--dusk-blue);
  opacity: 0.1;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.code-block__title {
  color: var(--dark-slate-blue);
  font-size: 36px;
  font-family: "Circe-ExtraBold", sans-serif;
  font-size: 36px;
  line-height: 53px;
  margin-bottom: 8px;
}
.code-block_sidebar .code-block__title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 28px;
}
.code-block__info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 40px;
  text-align: center;
  color: var(--light-grey-blue);
}
.code-block__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code-block__subtitle {
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  font-size: 18px;
  margin-bottom: 30px;
}
.code-block__block {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}
.code-block__input-holder {
  width: 48px;
  margin-right: 16px;
  position: relative;
}
.code-block__input-holder_filled:after {
  opacity: 0;
}
.code-block__input-holder_blur {
  position: relative;
}
.code-block__input-holder_blur:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: black;
  pointer-events: none;
}
.code-block__input-holder_error:after {
  background: var(--reddish-pink);
}
.code-block__input {
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  text-align: center;
  color: var(--dark-slate-blue);
  font-size: 16px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  height: 56px;
  border-radius: 9px;
  padding: 0;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  margin-right: 16px;
  pointer-events: auto;
}
.code-block__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input::-ms-clear {
  display: none;
}
.code-block__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.code-block__input:focus:after {
  opacity: 0;
}
.code-block__input_fail {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  box-shadow: none;
}
.code-block__input[type=number]::-webkit-outer-spin-button, .code-block__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code-block__input[type=number] {
  -moz-appearance: textfield;
}
.code-block__input-check {
  position: relative;
  right: auto;
  top: 0;
}
.code-block__error-wrap {
  margin-bottom: 20px;
}
.code-block__error-message {
  color: var(--reddish-pink);
  text-align: center;
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  width: 100%;
}
.code-block__tip {
  padding-top: 50px;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.code-block__tip-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  font-size: 14px;
  margin-left: 3px;
  text-decoration: none;
  cursor: pointer;
  color: var(--light-grey-blue);
}
.code-block__tip-link__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
}
.code-block__hint {
  padding-bottom: 50px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ff2d55;
}
.code-block__help-block {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: center;
  color: var(--light-grey-blue);
  font-size: 14px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.password-visibility-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.password-visibility-button__icon {
  pointer-events: none;
}
.password-visibility-button__icon_closed {
  margin-top: 1px;
}
.password-visibility-button__icon_opened {
  margin-top: 1px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.password-block__item {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.password-block_password-recovery .password-block__item {
  margin-bottom: 10px;
}
.password-block_password-recovery .password-block__item:only-child {
  margin-bottom: 30px;
}
.password-block__input-holder {
  margin-bottom: 11px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-right: 10px;
}
.password-block_password-recovery .password-block__input-holder {
  margin-right: 0;
}
.password-block__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  text-align: left;
  color: var(--dark-slate-blue);
  font-size: 16px;
  transition: border-color 0.3s ease;
  font-family: "Circe-Bold", sans-serif;
  letter-spacing: 16px;
  padding: 5px 24px 5px 24px;
  line-height: 50px;
}
.password-block__input__validation {
  padding-right: 94px;
}
.password-block__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-block__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.password-block__input::-ms-clear {
  display: none;
}
.password-block__input::-webkit-textfield-decoration-container {
  letter-spacing: normal;
}
.form_register .password-block__input {
  text-align: left;
  font-size: 18px;
}
.form_register .password-block__input::-webkit-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input::-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input:-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input:-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input::-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
}
.form_register .password-block__input::-ms-clear {
  display: none;
}
.password-block__input_red {
  border-color: var(--reddish-pink);
  color: var(--reddish-pink);
}
.password-block__input_red:focus {
  border-color: var(--reddish-pink);
}
.password-block__input_yellow {
  border-color: var(--light-mustard);
  color: var(--light-mustard);
}
.password-block__input_yellow:focus {
  border-color: var(--light-mustard);
}
.password-block__input_green {
  border-color: var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.password-block__input_green:focus {
  border-color: var(--turquoise-blue);
}
.password-block__input_password-visible {
  letter-spacing: 0;
}
.password-block__input_disabled {
  background: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  color: #b0b5c4;
  box-shadow: none;
}
.password-block__input_success {
  border: solid 1px var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.password-block__input_fail {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  box-shadow: none;
}
.form__block_code .password-block__input_fail {
  box-shadow: none;
}
.password-block__password-controls {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.password-block__password-visibility {
  margin-right: 4px;
}
.password-block__password-secure-wrap {
  position: relative;
}
.password-block__password-secure-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -8px;
}
.password-block__password-secure-lock_yellow path {
  fill: var(--light-mustard);
}
.password-block__password-secure-lock_grey path {
  fill: var(--bluey-grey);
}
.password-block__password-secure-lock_green path {
  fill: var(--turquoise-blue);
}
.password-block__password-secure-lock_red path {
  fill: var(--reddish-pink);
}
.password-block__error-wrap {
  margin-bottom: 20px;
}
.password-block__error-message {
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  width: 70%;
}
.password-recovery .password-block__error-message {
  font-size: 16px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.password-block__tip {
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 30px;
  max-width: 400px;
}
.password-block__tip_error {
  color: var(--reddish-pink);
}
.password-block_password-recovery .password-block__tip {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.password-block__next-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--pale-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
}
.password-block__next-btn_visible {
  opacity: 1;
}
.password-block__next-btn_active {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: var(--dusk-blue);
  cursor: pointer;
}
.password-block__next-btn_active:hover {
  background: var(--dark-slate-blue);
}
.password-block__next-btn-icon {
  margin-left: 5px;
}
.password-block__btn-wrap {
  display: flex;
  justify-content: center;
}
.password-block__btn {
  width: 100%;
  margin-bottom: 30px;
}
.password-block_password-recovery .password-block__btn {
  max-width: 240px;
  margin-bottom: 0;
}

@media screen and (max-width: 1100px) {
  .password-block_hint .password-block__input-holder {
    margin-right: 0;
  }
}
@media screen and (max-width: 700px) {
  .form_register .password-block__input-holder {
    margin-right: 0;
    margin-bottom: 31px;
  }
  .password-block_register .password-block__tip {
    width: 90%;
    font-size: 14px;
    line-height: 1.4;
  }
}
@media screen and (max-width: 500px) {
  .password-block__input {
    padding: 0 35px 0 24px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.password-recovery {
  display: flex;
  flex-grow: 100;
  position: relative;
  z-index: 20;
  padding-top: 20vh;
}
.password-recovery__content {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__title {
  font-size: 36px;
  text-align: center;
  color: var(--dark-slate-blue);
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 20px;
}
.password-recovery__form {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__info-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 400px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #b0b5c4;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__input-holder {
  margin-bottom: 30px;
  position: relative;
}
.password-recovery__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  text-align: center;
  color: var(--dark-slate-blue);
  font-size: 18px;
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
}
.password-recovery__input::-webkit-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input::-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input:-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input:-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input::-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.password-recovery__input::-ms-clear {
  display: none;
}
.password-recovery__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.password-recovery__input_disabled {
  background: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  color: #b0b5c4;
  box-shadow: none;
}
.password-recovery__input_success {
  border: solid 1px var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.password-recovery__input_fail {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  box-shadow: none;
}
.password-recovery__error-wrap {
  text-align: center;
  margin-bottom: 20px;
}
.password-recovery__error-message {
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  display: inline;
}
.password-recovery__btn-wrap {
  display: flex;
  justify-content: center;
}
.password-recovery__button {
  width: 240px;
}
.password-recovery__code-block .code-block__title {
  text-align: center;
}
.password-recovery__code-block .code-block__block {
  justify-content: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.password-recovery__code-block .code-block__tip {
  text-align: center;
}
.password-recovery__code-block .code-block__error-wrap {
  text-align: center;
}
.password-recovery__code-block .code-block__small-loader {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -11px;
}
.password-recovery__code-block .code-block__input-check {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
}

@media screen and (max-height: 600px) {
  .password-recovery {
    padding-top: 13.5%;
    padding-bottom: 13.5%;
  }
}
@media screen and (max-width: 700px) {
  .password-recovery {
    padding-top: 50px;
    padding-bottom: 200px;
    align-items: flex-start;
  }
}
@charset "UTF-8";
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.registration-phone-input {
  flex-grow: 20;
  width: 100%;
  margin-bottom: 20px;
}
.registration-phone-input__itself {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 0 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  line-height: 60px;
}
.registration-phone-input__itself::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself::-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself:-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself:-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself::-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.registration-phone-input__itself::-ms-clear {
  display: none;
}
.registration-phone-input__itself:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.registration-phone-input__check {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -6px;
}

.register__form__agreement {
  margin-bottom: 0;
}
.register__form__agreement span,
.register__form__agreement a {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
}
.register__form__agreement a {
  color: var(--dusk-blue);
  text-decoration: none;
}
.register__form__agreement span.i-agree {
  font-size: 12px;
}
.register__form__agreement ul li {
  -webkit-padding-start: 12px;
          padding-inline-start: 12px;
}
.register__form__agreement ul li::before {
  content: "•";
  color: var(--bluey-grey);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  height: 11px;
  font-size: 12px;
}

.register__form__open-login {
  margin-top: 50px;
}
.register__form__open-login span,
.register__form__open-login a {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--bluey-grey);
}
.register__form__open-login a {
  color: var(--dusk-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.registration-container {
  display: flex;
  flex-grow: 1000;
  position: relative;
}

.register {
  width: 100%;
  display: flex;
  flex-grow: 100;
  position: relative;
  z-index: 10;
}
.register__form__linkSkipEmail {
  text-align: center;
  cursor: pointer;
}
.register__form__linkSkipEmail__btn {
  text-decoration: none;
}
.register__container {
  display: flex;
  padding-top: 20vh;
  align-items: flex-start;
  justify-content: center;
}
.register__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  min-width: auto;
}
.register__title {
  color: var(--dark-slate-blue);
  font-size: 36px;
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 20px;
  line-height: 1.2;
  text-align: center;
}
.register__step-1 .register__title {
  margin-bottom: 17px;
}
.register__step-2 .register__title {
  margin-bottom: 34px;
  padding-right: 0;
}
.register__step-3 .register__title {
  margin-bottom: 36px;
}
.register__step-4 .register__title {
  margin-bottom: 16px;
}
.register__step-5 .register__title {
  margin-bottom: 17px;
}
.register__desc-wrap {
  margin-bottom: 40px;
  text-align: center;
}
.register__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register__desc {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--light-grey-blue);
  font-size: 18px;
  line-height: 24px;
}
.register__desc-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dusk-blue);
  text-decoration: underline;
}
.register__desc-link:hover {
  text-decoration: none;
}
.register__help-block {
  margin-top: 50px;
}
.register__help-title {
  color: var(--bluey-grey);
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.register__help-link-wrap {
  margin-bottom: 15px;
}
.register__help-link-wrap:last-child {
  margin-bottom: 0;
}
.register__btn-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__btn {
  width: 240px;
}
@media (max-width: 450px) {
  .register__btn {
    width: 100%;
  }
}
.register__btn:last-child {
  margin-right: 0;
}
.register__hover-button {
  width: 100%;
  margin: 28px auto;
}
@media (min-width: 440px) {
  .register__hover-button {
    max-width: 240px;
  }
}
.register__step-1 .register__hover-button {
  margin-bottom: 20px;
}
.register__hint-block {
  width: 50%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-top: 20px;
}
.register__hint {
  -webkit-filter: drop-shadow(0 4px 27px #e8ecf2);
          filter: drop-shadow(0 4px 27px #e8ecf2);
  background: white;
  background-size: cover;
  padding: 39px 28px 39px 39px;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  position: relative;
}
.register__step-2 .register__hint {
  max-width: 396px;
}
.register__hint-triangle {
  position: absolute;
  bottom: -23px;
  left: 22px;
  width: 29px;
  height: 23px;
}
.register__hint-text {
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.5;
  margin-right: 10px;
}
.register__hint-icon_smile {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}
.register__hint-phone {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  display: inline;
  font-size: 16px;
  color: var(--dark-slate-blue);
  line-height: 1.5;
  margin-right: 10px;
}
.register__hint-phone a {
  text-decoration: none;
  color: var(--dark-slate-blue);
}
.register__hint-phone-change {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: inline;
}
.register__hint-phone-change path {
  transition: all 0.3s ease;
}
.register__hint-phone-change:hover path {
  fill: var(--dark-slate-blue);
}
.register__image-block {
  display: flex;
  justify-content: center;
  width: 50%;
  min-width: 50%;
}
.register__image-wrap {
  width: 385px;
  height: 279px;
  position: relative;
  top: -8px;
  left: -50px;
}
.register__image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.register__shape {
  position: absolute;
  z-index: 61;
}
.register__shape_1 {
  width: 60px;
  height: 60px;
  top: 7%;
  left: 43.5%;
}
.register__shape_2 {
  width: 31px;
  height: 40px;
  top: 54%;
  left: 49%;
}
.register__shape_3 {
  width: 30px;
  height: 30px;
  top: 87%;
  left: 59.7%;
}
.register__shape_4 {
  width: 20px;
  height: 20px;
  top: 70%;
  left: 89.4%;
}
.register__shape_5 {
  width: 88px;
  height: 102px;
  top: 27.5%;
  left: 84.5%;
}
.register__laptop {
  width: 407px;
  height: 384px;
  position: absolute;
  top: 25%;
  right: 20%;
  z-index: 61;
}
.register__input-holder {
  max-width: 400px;
  margin-right: 10px;
  width: 100%;
}
.register__input {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 5px 24px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  line-height: 50px;
}
.register__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.register__input::-ms-clear {
  display: none;
}
.register__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}

@media screen and (max-width: 1194px) {
  .register__content {
    padding-left: 0;
    margin-top: 0;
    max-width: 400px;
  }
  .register__hint-block {
    width: 40%;
  }
  .register__step-2 .register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .register__step-3 .register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .register__step-4 .register__hint-block {
    padding-left: 0;
    padding-top: 0;
  }
  .register__hint {
    padding: 25px;
  }
  .register__step-5 .register__btn-list {
    width: 100%;
  }
}
@media screen and (max-height: 600px) {
  .register {
    padding-bottom: 200px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.form {
  width: 100%;
}
.form__block-list {
  margin-bottom: 25px;
}
.form__block {
  align-items: center;
  display: flex;
  margin-bottom: 25px;
  position: relative;
}
.register__step-1 .form__block {
  margin-bottom: 21px;
}
.form__block-list .form__block {
  margin-bottom: 10px;
}
.form__block-list .form__block:only-child {
  margin-bottom: 0;
}
.form__block-list .form__block:last-child {
  margin-bottom: 0;
}
.form__input-holder {
  margin-bottom: 11px;
  position: relative;
}
.form_register .form__input-holder {
  flex-grow: 20;
  margin-bottom: 0;
  margin-right: 10px;
  max-width: 420px;
}
.form__block_code .form__input-holder {
  flex-grow: unset;
  margin-right: 16px;
  position: relative;
  width: 48px;
}
.form__block_code .form__input-holder_filled::after {
  opacity: 0;
}
.form__input-holder_blur {
  position: relative;
}
.form__input-holder_blur::after {
  background: black;
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 8px;
}
.form__block_code .form__input-holder_error::after {
  background: var(--reddish-pink);
}
.form__input-check {
  margin-top: -6px;
  position: absolute;
  right: 24px;
  top: 50%;
}
.form__block_code .form__input-check {
  margin-top: 0;
  position: relative;
  right: auto;
  top: 0;
}
.form__input {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  -webkit-appearance: none;
  background: white;
  border: 1px solid white;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 60px;
  padding: 0 24px;
  text-align: center;
  width: 100%;
}
.form__input::-webkit-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input::-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input:-moz-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input:-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input::-ms-input-placeholder {
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
  letter-spacing: 0;
  opacity: 1;
}
.form__input::-ms-clear {
  display: none;
}
.form__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.form__input:disabled {
  opacity: 1;
}
.form_register .form__input {
  font-size: 18px;
  text-align: left;
}
.form_register .form__input::-webkit-input-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input::-moz-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input:-moz-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input:-ms-input-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.form_register .form__input::-ms-input-placeholder {
  font-family: "Circe-Bold", sans-serif;
}
.password-recovery .form__input {
  text-align: left;
}
.form__input.red {
  border-color: var(--reddish-pink);
  color: var(--reddish-pink);
}
.form__input.yellow {
  border-color: var(--light-mustard);
  color: var(--light-mustard);
}
.form__input.green {
  border-color: var(--turquoise-blue);
  color: var(--turquoise-blue);
}
.form__input.password-visible {
  letter-spacing: 0;
}
.form__input_disabled {
  background: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  box-shadow: none;
  color: #b0b5c4;
}
.form__input_success {
  border: solid 1px var(--turquoise-blue);
  color: var(--turquoise-blue);
  -webkit-text-fill-color: var(--turquoise-blue);
}
.form__input_fail {
  border: solid 1px var(--reddish-pink);
  box-shadow: none;
  color: var(--reddish-pink);
  -webkit-text-fill-color: var(--reddish-pink);
}
.form__block_code .form__input_fail {
  box-shadow: none;
}
.form__input[type=number]::-webkit-outer-spin-button, .form__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form__input[type=number] {
  -moz-appearance: textfield;
}
.form__input-edit {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: -10px 0 0;
  position: absolute;
  right: 30px;
  top: 50%;
}
.se-register__step-2 .form__input-edit {
  right: 40px;
}
.form__link-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.form__link {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 14px;
}
.form__link:hover {
  text-decoration: none;
}
.form__error-wrap .form__link {
  margin-left: 5px;
}
.form__btn-wrap {
  display: flex;
  justify-content: center;
}
.form__next-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background: var(--dusk-blue);
  border-radius: 50%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  height: 60px;
  justify-content: center;
  min-width: 60px;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
  width: 60px;
}
.form__next-btn.visible {
  opacity: 1;
  visibility: visible;
}
.form__next-btn:hover:not(:disabled) {
  background: var(--dark-slate-blue);
}
.form__next-btn:disabled {
  background: var(--pale-grey);
  cursor: not-allowed;
}
.form__next-btn-icon {
  margin-left: 5px;
}
.form__tip {
  font-family: "Circe-Regular", sans-serif;
  color: var(--light-grey-blue);
  font-size: 14px;
}
.register__step-3 .form__tip {
  width: 60%;
}
.form__tip.red {
  color: var(--reddish-pink);
}
.password-recovery .form__tip {
  margin-bottom: 20px;
  text-align: center;
}
.register__step-1 .form__tip {
  margin-bottom: 10px;
}
.form__tip-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--light-grey-blue);
  display: inline;
  font-size: 14px;
  margin-left: 3px;
  text-decoration: underline;
}
.form__tip-link:hover {
  text-decoration: none;
}
.form__agreement {
  align-items: center;
  display: flex;
}
.form__agreement-checkbox {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background-color: var(--pale-grey);
  border: solid 1px var(--bluey-grey);
  border-radius: 50%;
  height: 20px;
  margin: 0 12px 0 0;
  position: relative;
  width: 20px;
  min-width: 20px;
}
.form__agreement-checkbox::after {
  background-color: var(--dusk-blue);
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 8px;
}
.form__agreement-checkbox[aria-checked=true] {
  border: solid 1px var(--dark-slate-blue);
}
.form__agreement-checkbox[aria-checked=true]::after {
  opacity: 1;
}
.form__agreement-text {
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 14px;
}
.form__agreement-text.checked {
  color: var(--dusk-blue);
}
.form__agreement-text:hover {
  text-decoration: none;
}
.form__agreement-text-wrap .form__agreement-text:first-child {
  margin-right: 3px;
}
.form__error-wrap {
  margin-bottom: 20px;
}
.form__error-message {
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 5px;
}
.form__error-wrap .form__error-message {
  display: inline;
  margin-left: 0;
}
.password-recovery .form__error-message {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.form__loader-wrap {
  margin-left: 10px;
  position: relative;
}
.password-recovery .form__small-loader {
  margin-top: -11px;
  position: absolute;
  right: 50px;
  top: 50%;
}
.se-register__step-2 .form__small-loader {
  position: absolute;
  right: 12px;
  top: 20px;
}
.form__password-secure-wrap {
  position: relative;
}
.form__password-secure-lock {
  left: 50%;
  margin-left: -6px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
}
.form__password-secure-lock.red path {
  fill: var(--reddish-pink);
}
.form__password-secure-lock.yellow path {
  fill: var(--light-mustard);
}
.form__password-secure-lock_grey path {
  fill: var(--bluey-grey);
}
.form__password-secure-lock_green path {
  fill: var(--turquoise-blue);
}
.form__password-secure-lock_red path {
  fill: var(--reddish-pink);
}
.se-register .form__input-holder .form__password-secure-lock {
  left: auto;
  margin-left: 0;
  right: 25px;
}

@media screen and (max-width: 700px) {
  .form_responsive-code {
    display: flex;
    flex-direction: column-reverse;
  }
  .form__block {
    display: block;
  }
  .form_register .form__input-holder {
    margin-bottom: 31px;
    margin-right: 0;
  }
  .form_register .form__block_code .form__input-holder {
    margin-bottom: 0;
    margin-right: 16px;
  }
  .form__tip {
    font-size: 18px;
  }
  .register__step-2 .form__tip {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    margin-bottom: 33px;
  }
  .register__step-3 .form__tip {
    font-size: 14px;
    line-height: 1.4;
    width: 90%;
  }
  .register__step-4 .form__tip {
    font-size: 14px;
  }
  .form__tip-link {
    font-size: 18px;
  }
  .register__step-2 .form__tip-link {
    line-height: 1.2;
    text-align: left;
  }
  .register__step-4 .form__tip-link {
    font-size: 14px;
  }
  .form__agreement-wrap {
    margin-bottom: 15px;
  }
  .form__agreement-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 400px) {
  .password-recovery .form__small-loader {
    right: 5px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.date-picker-custom-input {
  position: relative;
}
.date-picker-custom-input__itself {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  -webkit-appearance: none;
  outline: none;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
}
.date-picker-custom-input__itself::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(159, 167, 179, 0.7);
}
.date-picker-custom-input__itself::-ms-clear {
  display: none;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.small-popup-with-emoji {
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-popup-with-emoji__autoheight .small-popup-with-emoji__popup {
  min-height: unset !important;
  height: auto;
}
.small-popup-with-emoji__popup {
  border-radius: 10px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 40px 60px;
  width: 553px;
  position: relative;
  min-height: 471px;
}
.small-popup-with-emoji__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-popup-with-emoji__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-ExtraBold", sans-serif;
  text-align: center;
  margin-bottom: 30px;
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
}
.small-popup-with-emoji__icon-wrap {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-popup-with-emoji__icon {
  width: 40px;
  height: 40px;
}
.small-popup-with-emoji__text {
  margin-bottom: 40px;
}
.small-popup-with-emoji__text p {
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.small-popup-with-emoji__text button,
.small-popup-with-emoji__text a {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  outline: none;
  text-decoration: underline;
  margin-left: 3px;
}
.small-popup-with-emoji__text button:hover,
.small-popup-with-emoji__text a:hover {
  text-decoration: none;
}
.small-popup-with-emoji__button-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.small-popup-with-emoji__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 60px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  margin-right: 20px;
}
.small-popup-with-emoji__button:last-child {
  margin-right: 0;
}
.small-popup-with-emoji__button_grey-border {
  border: 1px solid #616a88;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  color: #616a88;
}
.small-popup-with-emoji__button_grey-border:hover:not(:disabled) {
  background: #616a88;
  color: white;
}
.small-popup-with-emoji__button_red-border {
  border: 1px solid var(--reddish-pink);
  color: var(--reddish-pink);
}
.small-popup-with-emoji__button_red-border:hover:not(:disabled) {
  background: var(--reddish-pink);
  color: white;
}
.small-popup-with-emoji__button_blue-border {
  border: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.small-popup-with-emoji__button_blue-border:hover:not(:disabled) {
  background: var(--dark-slate-blue);
  color: white;
}
.small-popup-with-emoji__button_blue {
  background: var(--dark-slate-blue);
  color: white;
}
.small-popup-with-emoji__button_blue:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.small-popup-with-emoji__close-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
  height: 20px;
  width: 20px;
}
.small-popup-with-emoji__close-button-icon {
  height: 20px;
  width: 20px;
}
.small-popup-with-emoji__close-button-icon path {
  transition: all 0.3s ease;
}
.small-popup-with-emoji__close-button:hover .small-popup-with-emoji__close-button-icon path {
  stroke: var(--deep-sky-blue);
}

@media screen and (max-width: 600px) {
  .small-popup-with-emoji {
    display: block;
    padding: 50px 0;
  }
  .small-popup-with-emoji__popup {
    padding: 70px 15px 30px 15px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
  .small-popup-with-emoji__button {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .small-popup-with-emoji__button:last-child {
    margin-bottom: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.form-input-wrap {
  width: calc((100% - 10px) / 2);
  position: relative;
}

.auth-status__first-tab-content {
  margin-top: 35px;
  margin-bottom: 30px;
  display: grid;
  grid-template: "surname name" "patronymic birthDay";
  grid-gap: 20px 10px;
}
.auth-status__first-tab-content > * {
  width: 100%;
}
.auth-status__first-tab-content__surname {
  grid-area: surname;
}
.auth-status__first-tab-content__name {
  grid-area: name;
}
.auth-status__first-tab-content__patronymic {
  grid-area: patronymic;
}
.auth-status__first-tab-content__birthDay {
  grid-area: birthDay;
}
.auth-status__second-tab-content {
  margin-top: 35px;
  margin-bottom: 30px;
  display: grid;
  grid-template: "passport-serie password-number" "date passportIssuer" "passportIssuedBy passportIssuedBy" "birthPlace birthPlace" "inn .";
  grid-gap: 20px 10px;
}
.auth-status__second-tab-content__passport {
  grid-area: passport;
}
.auth-status__second-tab-content__date {
  grid-area: date;
}
.auth-status__second-tab-content__passportIssuer {
  grid-area: passportIssuer;
}
.auth-status__second-tab-content__passportIssuedBy {
  grid-area: passportIssuedBy;
}
.auth-status__second-tab-content__birthPlace {
  grid-area: birthPlace;
}
.auth-status__second-tab-content__inn {
  grid-area: inn;
}
@media (max-width: 600px) {
  .auth-status__second-tab-content {
    grid-template: "passport-serie" "password-number" "date" "passportIssuer" "passportIssuedBy" "birthPlace" "inn";
  }
}
.auth-status__nav {
  cursor: pointer;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
}

.form-input {
  position: relative;
}
.form-input_2-col {
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
}
.form-input_2-col:nth-child(2n) {
  margin-right: 0;
}
.form-input:nth-last-child(-n+2) {
  margin-bottom: 0;
}
.profile-settings .form-input:nth-last-child(-n+2) {
  margin-bottom: 24px;
}
.profile-settings .form-input:last-child {
  margin-bottom: 0;
}
.profile-settings__input-list .form-input {
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
}
.profile-settings__input-list .form-input:last-child {
  margin-right: 0;
}
.form-input input {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  -webkit-appearance: none;
  outline: none;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
}
.form-input input.form-input__itself_error {
  border-color: var(--reddish-pink);
}
.form-input input.form-input__itself_error:focus {
  border-color: var(--reddish-pink);
}
.form-input input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-color: white;
}
.form-input input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.form-input input::-ms-clear {
  display: none;
}
.form-input_focused input {
  background-color: white;
}
.form-input__itself {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
  text-transform: capitalize;
}
.form-input__itself:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.form-input__itself:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.form-input__itself_disabled:hover {
  box-shadow: none;
  background-color: rgba(228, 232, 238, 0.2);
}
.profile-settings .form-input__itself:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  border-color: rgba(228, 232, 238, 0.1);
}
.form-input__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
}
.form-input:focus-within .form-input__label {
  top: 7px;
  font-size: 12px;
  color: #6b778d;
}
.form-input_focused .form-input__label {
  top: 7px;
  font-size: 12px;
  color: #6b778d;
}
.form-input__calendar-icon {
  position: absolute;
  right: 18px;
  width: 20px;
  height: 20px;
  top: 20px;
}
.form-input__input-check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.form-input__input-check path {
  stroke: var(--dark-slate-blue);
}
.form-input__input-error {
  position: absolute;
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
  font-size: 14px;
  top: 100%;
  padding: 5px 5px 0;
  left: 0;
}

@media screen and (max-width: 800px) {
  .form-input_2-col {
    width: 100%;
    margin-right: 0;
  }
  .form-input:nth-last-child(-n+2) {
    margin-bottom: 20px;
  }
  .form-input:last-child {
    margin-bottom: 0;
  }
  .form-input input {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: solid 1px rgba(157, 166, 186, 0.2);
    background-color: rgba(228, 232, 238, 0.2);
    color: var(--dark-slate-blue);
    font-size: 16px;
    font-family: "Circe-Regular", sans-serif;
    -webkit-appearance: none;
    outline: none;
    padding: 29px 20px 16px 20px;
    transition: all 0.3s ease;
  }
  .form-input__itself {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    font-family: "Circe-Regular", sans-serif;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: solid 1px rgba(157, 166, 186, 0.2);
    background-color: rgba(228, 232, 238, 0.2);
    color: var(--dark-slate-blue);
    font-size: 16px;
    padding: 29px 20px 16px 20px;
    transition: all 0.3s ease;
  }
  .form-input__itself:focus {
    border: solid 1px var(--dark-slate-blue);
    box-shadow: none;
    background: white;
  }
  .form-input__itself:hover:not(:disabled) {
    box-shadow: 0 4px 27px 0 #e8ecf2;
    background: white;
  }
  .form-input__label {
    font-size: 18px;
    color: #9da6ba;
    position: absolute;
    left: 24px;
    top: 24px;
    font-family: "Circe-Bold", sans-serif;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  .form-input:focus-within .form-input__label {
    top: 7px;
    font-size: 12px;
    color: #6b778d;
    text-transform: uppercase;
  }
  .form-input_focused .form-input__label {
    top: 7px;
    font-size: 12px;
    color: #6b778d;
  }
  .form-input__calendar-icon {
    position: absolute;
    right: 18px;
    width: 20px;
    height: 20px;
    top: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.auth-status-result {
  max-width: 485px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: -45px;
  position: relative;
  z-index: 200;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
.auth-status-result__top {
  margin-bottom: 60px;
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-status-result__top-icon {
  width: 30px;
  height: 39px;
}
.auth-status-result__top-loader-icon {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 50%;
  color: rgba(40, 65, 149, 0.1);
  left: 50%;
  margin-top: -9px;
  margin-left: -6px;
}
.auth-status-result__top-loader-icon path {
  fill: var(--bluey-grey);
}
.auth-status-result__top-fail-progress-icon {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 50%;
  color: rgba(40, 65, 149, 0.1);
  left: 50%;
  margin-top: -9px;
  margin-left: -6px;
}
.auth-status-result__top-fail-progress-icon path {
  fill: var(--reddish-pink);
}
.auth-status-result__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  text-align: center;
  font-family: "Circe-Bold", sans-serif;
  line-height: 1.33;
  margin-bottom: 10px;
}
.auth-status-result__desc {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 36px;
  text-align: center;
}
.auth-status-result__link-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.auth-status-result__link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 190px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dusk-blue);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
}
.auth-status-result__link:hover {
  background: var(--dark-slate-blue);
}
.auth-status-result__tip {
  font-size: 15px;
  line-height: 1.6;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .auth-status-result__top {
    margin-bottom: 55px;
  }
  .auth-status-result__title {
    margin-bottom: 38px;
    font-family: "Circe-ExtraBold", sans-serif;
    line-height: 1.25;
  }
  .auth-status-result__desc {
    margin-left: auto;
    margin-right: auto;
    width: 89%;
    margin-bottom: 38px;
  }
  .auth-status-result__link-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .auth-status-result__link {
    width: 100%;
    max-width: 335px;
    height: 60px;
    border-radius: 30px;
    background-color: var(--dark-slate-blue);
    font-size: 16px;
  }
  .auth-status-result__tip {
    font-size: 15px;
    line-height: 1.6;
    color: var(--bluey-grey);
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 30;
  width: 320px;
}

.react-datepicker {
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border: none;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__day {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  width: 35px;
  height: 23px;
  line-height: 23px;
  border-radius: 2px;
}
.react-datepicker__day:nth-child(6) {
  color: var(--bluey-grey);
}
.react-datepicker__day:nth-child(7) {
  color: #ff5353;
}
.react-datepicker__day:hover {
  border-radius: 2px;
}
.react-datepicker__day--today {
  color: white;
  background: var(--grey-blue);
}
.react-datepicker__day--selected {
  background-color: var(--deep-sky-blue);
  color: white;
  border-radius: 2px;
}
.react-datepicker__day--selected:hover {
  color: white;
  background: var(--dusk-blue);
}
.react-datepicker__day--keyboard-selected {
  background-color: var(--deep-sky-blue);
  color: white;
  border-radius: 2px;
}
.react-datepicker__day--keyboard-selected:hover {
  color: white;
  background: var(--dusk-blue);
}
.react-datepicker__day--disabled {
  color: #ccc;
}
.react-datepicker__day--disabled.react-datepicker__day--today {
  color: white;
}
.react-datepicker__day--disabled.react-datepicker__day--today:hover {
  color: #ccc;
  background: #f0f0f0;
}
.react-datepicker__day-name {
  font-size: 11px;
  color: rgba(178, 178, 178, 0.5);
  font-family: "Circe-Bold", sans-serif;
}
.react-datepicker__header {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
}
.react-datepicker__current-month {
  width: 60%;
  margin-right: 10px;
  height: 30px;
  line-height: 30px;
  color: var(--grey-blue);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border-radius: 4px;
  text-transform: capitalize;
  border: solid 1px rgba(176, 181, 196, 0.1);
  position: relative;
  margin-bottom: 10px;
}
.react-datepicker__navigation {
  top: 16px;
}
.react-datepicker__year-option .react-datepicker__navigation {
  top: -3px;
}
.react-datepicker__navigation--previous {
  left: 18px;
}
.react-datepicker__year-option .react-datepicker__navigation--previous {
  top: 0;
}
.react-datepicker__navigation--next {
  left: 176px;
  right: auto;
}
.react-datepicker__year-option .react-datepicker__navigation--next {
  bottom: 0;
}
.react-datepicker__day-names {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.react-datepicker__header__dropdown {
  width: calc(40% - 10px);
  height: 30px;
  border-radius: 4px;
  border: solid 1px rgba(176, 181, 196, 0.1);
  margin-bottom: 10px;
}
.react-datepicker__year-dropdown-container {
  width: 100%;
  line-height: 30px;
  color: var(--bluey-grey);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.react-datepicker__year-dropdown {
  top: 50px;
  left: auto;
  right: 8px;
  background-color: white;
  border: none;
  box-shadow: 0 0 27px 0 #bbc1c9;
}
.react-datepicker__year-read-view {
  position: relative;
  visibility: visible !important;
  text-align: left;
  padding-left: 7px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.react-datepicker__year-read-view--down-arrow {
  position: absolute;
  float: none;
  right: 10px;
  top: 11px;
}
.react-datepicker__year-option {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
}
.react-datepicker__year-option .react-datepicker__navigation--years-previous {
  top: 4px;
}

@media screen and (max-width: 350px) {
  .react-datepicker-popper {
    width: 290px;
  }
  .react-datepicker__current-month {
    width: 130px;
  }
  .react-datepicker__header__dropdown {
    width: 110px;
  }
}
.auth-status-container {
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 200;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.auth-status {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.auth-status_choose-way {
  min-height: 800px;
  padding-bottom: 57px;
}
.auth-status__nav {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.auth-status__nav-back {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.auth-status__nav-back-icon {
  height: 30px;
  width: 15px;
}
.auth-status__nav-close {
  align-items: center;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  text-decoration: none;
  width: 48px;
  z-index: 20;
}
.auth-status__header-close {
  align-items: center;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 56px;
  text-decoration: none;
  top: 48px;
  width: 48px;
  z-index: 20;
}
.auth-status__header-close-icon path {
  transition: all 0.3s ease;
}
.auth-status__header-close:hover .auth-status__header-close-icon path {
  stroke: var(--reddish-pink);
}
.auth-status__header {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  min-height: 320px;
  overflow: hidden;
  padding-bottom: 155px;
  padding-top: 102px;
  position: relative;
  will-change: transform;
}
.auth-status_enter-passport-data .auth-status__header {
  padding-bottom: 65px;
  padding-top: 62px;
}
.auth-status_choose-way .auth-status__header {
  min-height: 400px;
}
.auth-status_success .auth-status__header {
  height: 320px;
  padding: 0;
}
.auth-status_waiting .auth-status__header {
  height: 320px;
  padding: 0;
}
.auth-status_fail .auth-status__header {
  height: 320px;
  padding: 0;
}
.auth-status__header-container {
  position: relative;
  z-index: 20;
}
.auth-status__header-bg {
  height: 943px;
  margin-top: -534px;
  position: absolute;
  right: -125px;
  top: 50%;
  width: 773px;
  z-index: 10;
}
.auth-status_waiting .auth-status__header-bg {
  margin-top: -464px;
}
.auth-status_success .auth-status__header-bg {
  margin-top: -464px;
}
.auth-status_fail .auth-status__header-bg {
  margin-top: -464px;
}
.auth-status_choose-way .auth-status__header-content {
  max-width: 440px;
}
.auth-status_enter-passport-data .auth-status__header-content {
  max-width: 440px;
}
.auth-status_success .auth-status__header-content {
  max-width: 440px;
}
.auth-status_gosuslugi-fail .auth-status__header-content {
  max-width: 404px;
}
.auth-status_already-registered .auth-status__header-content {
  max-width: 424px;
}
.auth-status__header-title {
  font-family: "Circe-Bold", sans-serif;
  color: white;
  font-size: 36px;
  line-height: 1.33;
  margin-bottom: 10px;
}
.auth-status__header-desc {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
}
.auth-status_enter-passport-data .auth-status__header-desc {
  margin-bottom: 30px;
}
.auth-status_success .auth-status__header-desc {
  margin-bottom: 50px;
}
.profile__card .auth-status__header-desc {
  color: var(--grey-blue);
  line-height: 1.75;
  margin-bottom: 56px;
  text-align: center;
}
.auth-status__header-desc-warning {
  height: 20px;
  left: -30px;
  position: absolute;
  top: 3px;
  width: 20px;
}
.auth-status__passport-scan-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 rgba(232, 236, 242, 0.1);
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0 20px;
  transition: all 0.3s ease;
}
.auth-status__passport-scan-btn:hover {
  background: rgba(255, 255, 255, 0.3);
}
.auth-status__passport-scan-btn_upload {
  border: 1px dashed white;
}
.auth-status__passport-scan-btn_upload:hover {
  background: rgba(255, 255, 255, 0.1);
}
.auth-status__passport-scan-btn-icon {
  margin-right: 10px;
}
.auth-status__passport-scan-btn-text {
  font-family: "Circe-Bold", sans-serif;
  color: white;
  font-size: 16px;
}
.auth-status__header-btn-wrap {
  display: flex;
}
.auth-status__header-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 rgba(232, 236, 242, 0.1);
  color: white;
  display: flex;
  font-size: 16px;
  height: 60px;
  justify-content: center;
  padding: 0 20px;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 265px;
}
.auth-status__header-btn:hover {
  background: rgba(255, 255, 255, 0.3);
}
.auth-status__header-logo-wrap {
  left: calc(50% + 75px);
  margin-top: -70px;
  position: absolute;
  top: 50%;
  z-index: 20;
}
.auth-status__header-logo-container {
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 32px 0 var(--dusk-blue);
  height: 140px;
  overflow: hidden;
  width: 140px;
}
.auth-status__header-logo {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.auth-status__header-logo-status {
  align-items: center;
  background-color: var(--deep-sky-blue);
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 50px;
}
.auth-status__header-logo-status-icon {
  height: 28px;
  width: 30px;
}
.auth-status__shape {
  position: absolute;
  z-index: 20;
}
.auth-status__shape_1 {
  bottom: 26px;
  height: 52px;
  left: calc(50% + 6px);
  width: 51px;
}
.auth-status__shape_2 {
  height: 20px;
  left: calc(70% + 18px);
  top: 120px;
  width: 20px;
}
.auth-status__shape_3 {
  height: 89px;
  left: calc(43% + 9px);
  top: 43px;
  width: 76px;
}
.auth-status__content {
  display: flex;
  flex-direction: column;
  flex-grow: 20;
  justify-content: space-between;
  position: relative;
}
.auth-status_enter-passport-data .auth-status__content {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  min-height: 483px;
  padding-bottom: 160px;
  padding-top: 60px;
}
.auth-status_choose-way .auth-status__content {
  flex-shrink: 0;
  min-height: 343px;
}
.auth-status__ways-list {
  display: flex;
  position: relative;
  z-index: 30;
}
.auth-status_choose-way .auth-status__ways-list {
  margin-bottom: 120px;
  margin-top: -90px;
}
.auth-status_gosuslugi-fail .auth-status__ways-list {
  margin-bottom: 120px;
  margin-top: -90px;
}
.auth-status_already-registered .auth-status__ways-list {
  margin-bottom: 120px;
  margin-top: -90px;
}
.auth-status__ways-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: flex-start;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: flex-end;
  margin-right: 30px;
  padding: 30px 30px 45px;
  position: relative;
  transition: all 0.3s ease;
  width: 380px;
}
.auth-status__ways-item:hover {
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
}
.auth-status__ways-item:last-child {
  margin-right: 0;
}
.auth-status__ways-item-title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 22px;
  margin-bottom: 10px;
  text-align: left;
}
.auth-status__ways-item-desc {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}
.auth-status__ways-item-img {
  position: absolute;
}
.auth-status__ways-item-img_gosuslugi {
  right: 40px;
  top: 40px;
}
.auth-status__ways-item-img_pen {
  height: 30px;
  right: 30px;
  top: 30px;
  width: 24px;
}
.auth-status__form {
  margin-right: 60px;
  max-width: 650px;
  width: 100%;
}
.auth-status__inputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.auth-status__btn-wrap {
  display: flex;
}
.auth-status__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background: var(--dusk-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  width: 190px;
}
.auth-status__btn_disabled {
  background-color: var(--pale-grey);
  color: var(--bluey-grey);
  cursor: default;
}
.auth-status__loader-wrap {
  margin-left: 20px;
  position: relative;
}
.auth-status__password-secure-lock {
  left: 50%;
  margin-left: -6px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
}
.auth-status__password-secure-lock_grey path {
  fill: var(--bluey-grey);
}
.auth-status__password-secure-lock_green path {
  fill: var(--turquoise-blue);
}
.auth-status__password-secure-lock_red path {
  fill: var(--reddish-pink);
}
.auth-status__personal-info {
  margin-bottom: 19px;
  position: relative;
  z-index: 30;
}
.auth-status__personal-info-tabs {
  display: flex;
}
.auth-status__personal-info-tabs-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  cursor: default;
  font-size: 16px;
  margin-right: 30px;
}
.auth-status__personal-info-tabs-item:last-child {
  margin-right: 0;
}
.auth-status__personal-info-tabs-item_active {
  color: var(--dark-slate-blue);
}
.auth-status__personal-info-bar {
  background: rgba(176, 181, 196, 0.2);
  bottom: -6px;
  height: 2px;
  left: 0;
  position: absolute;
}
.auth-status__personal-info-bar-active {
  background-color: var(--dusk-blue);
  border-radius: 1px;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease;
}
.auth-status__personal-info-content {
  position: relative;
  z-index: 30;
}
.auth-status__hint-block {
  position: relative;
  z-index: 10;
}
.auth-status_enter-passport-data .auth-status__hint-block {
  padding-top: 40px;
}
.auth-status__hint-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 16px;
  margin-bottom: 16px;
}
.auth-status__hint-item-wrap {
  margin-bottom: 20px;
}
.auth-status__hint-item-wrap:last-child {
  margin-bottom: 0;
}
.auth-status__hint-item-wrap:only-child {
  margin-bottom: 0;
}
.auth-status__hint-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  border-bottom: 2px dotted var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding-bottom: 4px;
  text-decoration: none;
}
.auth-status__hint-item:hover {
  border-bottom-color: transparent;
}
.auth-status__hint-item-img {
  height: 14px;
  margin-left: 4px;
  vertical-align: middle;
  width: 86px;
}
.auth-status__success .auth-status-result__desc {
  margin-bottom: 60px;
}
.auth-status__fail .auth-status-result__desc {
  margin-bottom: 60px;
}
.auth-status__fail_another-inn.auth-status-result {
  max-width: 717px;
}

@media screen and (max-width: 1100px) {
  .auth-status_enter-passport-data .auth-status__content {
    flex-direction: column;
    min-height: 605px;
  }
  .auth-status_enter-passport-data .auth-status__form {
    margin-bottom: 50px;
    margin-right: 0;
  }
  .auth-status_enter-passport-data .auth-status__hint-block {
    padding-top: 0;
  }
}
@media screen and (max-width: 980px) {
  .auth-status_choose-way {
    padding-bottom: 57px;
  }
  .auth-status__nav {
    margin-bottom: 30px;
  }
  .auth-status_choose-way .auth-status__header {
    min-height: auto;
    padding-bottom: 115px;
    padding-top: 70px;
  }
  .auth-status_enter-passport-data .auth-status__header {
    padding-top: 32px;
  }
  .auth-status_gosuslugi-fail .auth-status__header {
    padding-bottom: 94px;
    padding-top: 68px;
  }
  .auth-status_already-registered .auth-status__header {
    padding-bottom: 94px;
    padding-top: 68px;
  }
  .auth-status_gosuslugi-fail .auth-status__header-title {
    margin-bottom: 20px;
  }
  .auth-status_already-registered .auth-status__header-title {
    margin-bottom: 20px;
  }
  .auth-status_gosuslugi-fail .auth-status__header-desc {
    max-width: 265px;
  }
  .auth-status_already-registered .auth-status__header-desc {
    max-width: 265px;
  }
  .auth-status__header-bg {
    height: 661px;
    margin-top: -320px;
    width: 513px;
  }
  .auth-status_choose-way .auth-status__content {
    padding-top: 30px;
  }
  .auth-status_enter-passport-data .auth-status__content {
    min-height: 770px;
  }
  .auth-status__ways-list {
    display: block;
    margin-bottom: 60px;
  }
  .auth-status_choose-way .auth-status__ways-list {
    margin-top: 0;
  }
  .auth-status_gosuslugi-fail .auth-status__ways-list {
    margin-top: 94px;
  }
  .auth-status_already-registered .auth-status__ways-list {
    margin-top: 94px;
  }
  .auth-status__ways-item {
    height: 150px;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 20px 30px 15px 26px;
  }
  .auth-status__ways-item:last-child {
    margin-bottom: 0;
  }
  .auth-status__ways-item-title {
    margin-bottom: 5px;
  }
  .auth-status__ways-item-desc {
    max-width: 80%;
  }
  .auth-status__ways-item-img {
    position: absolute;
  }
  .auth-status__ways-item-img_gosuslugi {
    height: 40px;
    margin-top: -20px;
    right: 20px;
    top: 50%;
    width: 36px;
  }
  .auth-status__ways-item-img_pen {
    height: 23px;
    margin-top: -11px;
    right: 22px;
    top: 50%;
    width: 23px;
  }
}
@media screen and (max-width: 800px) {
  .auth-status__content {
    min-height: 746px;
  }
  .auth-status_choose-way .auth-status__content {
    min-height: 460px;
  }
}
@media screen and (max-width: 600px) {
  .auth-status_enter-passport-data .auth-status__header {
    min-height: 304px;
  }
  .auth-status_success .auth-status__header {
    height: 200px;
    min-height: 200px;
  }
  .auth-status_waiting .auth-status__header {
    height: 200px;
    min-height: 200px;
  }
  .auth-status_fail .auth-status__header {
    height: 200px;
    min-height: 200px;
  }
  .auth-status_choose-way .auth-status__header-content {
    max-width: 280px;
  }
  .auth-status_success .auth-status__header-content {
    max-width: 280px;
  }
  .auth-status__header-title {
    font-size: 28px;
  }
  .auth-status__header-close {
    right: 20px;
    top: 20px;
  }
  .auth-status__header-bg {
    height: 276px;
    margin-top: 0;
    right: -151px;
    top: 33px;
    width: 271px;
  }
  .auth-status_waiting .auth-status__header-bg {
    margin-top: 0;
    right: -142px;
    top: 22px;
  }
  .auth-status_success .auth-status__header-bg {
    margin-top: 0;
    right: -142px;
    top: 22px;
  }
  .auth-status_fail .auth-status__header-bg {
    margin-top: 0;
    right: -142px;
    top: 22px;
  }
  .auth-status__ways-item {
    width: 100%;
    height: auto;
  }
  .auth-status__success .auth-status-result__desc {
    margin-bottom: 40px;
  }
  .auth-status__fail .auth-status-result__desc {
    margin-bottom: 40px;
  }
}
.cards-floating-details {
  bottom: 100px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 90px;
}
.cards-floating-details_non-visible {
  visibility: hidden;
}
.cards-floating-details__shape {
  pointer-events: none;
  position: absolute;
  z-index: 60;
}
.cards-floating-details__shape_1 {
  height: 40px;
  width: 40px;
}
.cards-floating-details__shape_2 {
  height: 94px;
  width: 94px;
}
.cards-floating-details__shape_3 {
  height: 30px;
  width: 30px;
}
.cards-floating-details__shape_4 {
  height: 20px;
  width: 20px;
}
.cards-floating-details__shape_5 {
  height: 90px;
  width: 79px;
}
.cards-floating-details__laptop {
  height: 394px;
  position: absolute;
  width: 417px;
  z-index: 61;
}

@media screen and (max-width: 875px) {
  .cards-floating-details__shape_3 {
    height: 24px;
    width: 24px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.business-card-container {
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
.business-card-container_menu-open {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
.business-card-container__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  width: 100%;
  z-index: 110;
}
.business-card-container__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  margin-left: 26px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.business-card-container__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.business-card-container__burger-span path {
  transition: all 0.3s ease;
}
.business-card-container__burger-span:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card-container__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.business-card-container__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card-container__burger_menu-open .business-card-container__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.business-card-container__loader {
  flex-grow: 20;
  position: static;
  position: relative;
}
.business-card-container__error {
  align-items: center;
  display: flex;
  flex-grow: 200;
  justify-content: center;
}
.business-card-container__error-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 20px;
  text-align: center;
}
.business-card-container__link-wrap {
  align-items: flex-start;
  display: flex;
  flex: 4000 0 auto;
  justify-content: center;
  padding-top: 30px;
  width: 100%;
}
.business-card-container__link {
  margin-bottom: 40px;
  position: relative;
  z-index: 100;
}

@media screen and (max-width: 1194px) {
  .business-card-container__header {
    height: 90px;
    min-height: 90px;
    padding-top: 0;
  }
}
@media screen and (max-width: 980px) {
  .business-card-container__header {
    height: 65px;
    min-height: 65px;
    padding-top: 0;
  }
  .business-card-container__link-wrap {
    padding-top: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payed-invoice-text {
  min-height: 200px;
  text-align: center;
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.2;
  color: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 600px) {
  .payed-invoice-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-request-link {
  background: white;
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  position: relative;
  width: 600px;
}
.money-request-link__header {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  border-radius: 14px 14px 0 0;
  min-height: 121px;
  overflow: hidden;
  padding: 16px;
  position: relative;
}
.money-request-link__header_menu-open {
  background: white;
}
.money-request-link__header-bg {
  position: absolute;
  right: -52px;
  top: -120px;
  width: 254px;
  z-index: 20;
}
.money-request-link__header-logo {
  left: 50%;
  margin-left: -65px;
  position: absolute;
  top: 76px;
  z-index: 40;
}
.money-request-link__top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 80;
  height: 30px;
}
.money-request-link__top-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.money-request-link__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  flex-direction: row-reverse;
  grid-gap: 10px;
  gap: 10px;
}
.money-request-link__back-button-text {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
@media (min-width: 981px) {
  .money-request-link__whiteLogoWrap {
    display: none;
  }
}
.money-request-link__whiteLogoWrap svg {
  z-index: 100;
  height: 40px;
  width: 40px;
}
.money-request-link__back-button-icon {
  height: 23px;
  width: 13px;
}
.money-request-link__back-button-icon_menu-open path {
  stroke: var(--dark-slate-blue);
}
.money-request-link__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  margin-left: 26px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.money-request-link__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.money-request-link__burger-span path {
  fill: white;
  transition: all 0.3s ease;
}
.money-request-link__burger-span:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-link__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.money-request-link__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-request-link__burger_menu-open .money-request-link__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.money-request-link__content {
  padding-bottom: 25px;
  position: relative;
  z-index: 40;
}
.money-request-link__content-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
  margin-bottom: 14px;
  text-align: center;
}
.money-request-link__avatar-wrap {
  border: 2px solid white;
  border-radius: 50%;
  height: 90px;
  margin: -45px auto 20px auto;
  overflow: hidden;
  position: relative;
  width: 90px;
}
.money-request-link__avatar-wrap_money-request-link-to-pay {
  margin-bottom: 20px;
}
.money-request-link__avatar {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.money-request-link__card {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
  width: 100%;
}
.money-request-link__info-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-bottom: 11px;
  text-align: center;
}
.money-request-link__info-purpose {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: var(--pale-grey);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 14px;
  line-height: 33px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  padding: 0 15px;
  text-align: center;
  width: 100%;
  word-break: break-all;
}
.money-request-link__info-sum {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
}
.money-request-link__bill {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 291px;
}
.money-request-link__btn-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  width: 100%;
}
.money-request-link__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  transition: all 0.3s ease;
  width: 320px;
}
.money-request-link__btn:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.money-request-link__btn:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.money-request-link__attention-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  text-align: center;
}
.money-request-link__iframe-wrap {
  height: 370px;
  position: relative;
}
.money-request-link__iframe-wrap-sbp {
  height: 690px;
  position: relative;
  margin-bottom: 0px;
}
.money-request-link__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.money-request-link__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}
.money-request-link__translate-to-ru {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media screen and (max-width: 980px) {
  .money-request-link {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }
  .money-request-link__header {
    border-radius: 0;
    min-height: 98px;
    height: 98px;
    padding: 20px 50px;
  }
  .money-request-link__back-button {
    width: 30px;
  }
  .money-request-link__avatar-wrap {
    margin-bottom: 10px;
  }
  .money-request-link__content {
    padding-bottom: 0;
  }
  .money-request-link__btn-wrap {
    margin-bottom: 0;
  }
  .money-request-link__btn {
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 335px;
    width: 100%;
  }
  .money-request-link__attention-text {
    margin-bottom: 37px;
  }
  .money-request-link__translate-to-ru {
    position: static;
    margin-right: 10px;
  }
}
@media screen and (max-width: 600px) {
  .money-request-link__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .money-request-link__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .money-request-link__iframe-wrap-sbp {
    height: 710px;
  }
}
@media screen and (max-width: 350px) {
  .money-request-link__header {
    padding-left: 14px;
    padding-right: 14px;
  }
  .money-request-link__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-request-container {
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
.money-request-container_menu-open {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
.money-request-container__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  position: relative;
  width: 100%;
}
.money-request-container__header_menu-open {
  z-index: 110;
}
.money-request-container__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  margin-left: 26px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.money-request-container__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.money-request-container__burger-span path {
  transition: all 0.3s ease;
}
.money-request-container__burger-span:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-container__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.money-request-container__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-container__burger_menu-open .money-request-container__burger-span:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-request-container__burger_menu-open .money-request-container__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.money-request-container__burger_menu-open .money-request-container__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-request-container__burger_menu-open .money-request-container__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.money-request-container__burger_menu-open .money-request-container__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-request-container__burger_menu-open .money-request-container__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.money-request-container__loader {
  flex-grow: 20;
}
.money-request-container__error {
  align-items: center;
  display: flex;
  flex-grow: 200;
  justify-content: center;
  height: 100%;
}
.money-request-container__error-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 20px;
  text-align: center;
}
.money-request-container__link-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 4000;
  flex-shrink: 0;
  justify-content: center;
  padding-top: 30px;
  width: 100%;
}
.money-request-container__link {
  margin-bottom: 40px;
  position: relative;
  z-index: 61;
}
.money-request-container__payment-ways {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.money-request-container__payment-ways-item_visa {
  height: 20px;
  margin-right: 30px;
  width: 45px;
}
.money-request-container__payment-ways-item_msc {
  height: 19px;
  margin-right: 24px;
  width: 31px;
}
.money-request-container__payment-ways-item_mir {
  height: 19px;
  margin-right: 19px;
  width: 46px;
}
.money-request-container__payment-ways-item_pci {
  height: 18px;
  width: 44px;
}
.money-request-container__info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
  padding: 0 20px;
}
.money-request-container__info-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
}
.money-request-container__info-link:hover {
  text-decoration: none;
}

@media screen and (max-width: 1194px) {
  .money-request-container__header {
    height: 90px;
    min-height: 90px;
    padding-top: 0;
  }
}
@media screen and (max-width: 980px) {
  .money-request-container__header {
    height: 65px;
    min-height: 65px;
    padding-top: 0;
  }
  .money-request-container__link-wrap {
    justify-content: flex-start;
    padding-top: 0;
  }
  .money-request-container__info {
    margin-bottom: 70px;
    max-width: 370px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.check {
  background: white;
  -webkit-filter: drop-shadow(0 4px 27px #e8ecf2);
          filter: drop-shadow(0 4px 27px #e8ecf2);
  padding: 35px 20px 18px 20px;
  position: relative;
}
.check_canceled {
  text-decoration: line-through;
}
.check * {
  text-decoration: inherit;
}
.check__block {
  border-top: 1px solid var(--pale-grey);
  border-bottom: 1px solid var(--pale-grey);
}
.check__block_top {
  padding-top: 13px;
  padding-bottom: 10px;
}
.check__block_personal-info {
  padding-top: 13px;
  padding-bottom: 10px;
}
.check__block_main {
  padding-top: 15px;
  padding-bottom: 14px;
}
.check__block_sum {
  padding-top: 17px;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check__block_customer {
  padding-top: 15px;
  padding-bottom: 14px;
}
.check__block:first-child {
  border-top: none;
}
.check__sz-block {
  margin: 12px 0;
}
.check__number {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 17px;
}
.check_canceled .check__number {
  color: var(--bluey-grey);
}
.check__date-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check__date {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.check__time {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.check__name {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 7px;
}
.check_canceled .check__name {
  color: var(--bluey-grey);
}
.check__inn {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 7px;
}
.check__sz-block .check__inn:last-child {
  margin-bottom: 0;
}
.check__block_customer .check__inn:last-child {
  margin-bottom: 0;
}
.check__regime {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.check__block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  width: 100%;
}
.check__block_customer .check__block-header {
  margin-bottom: 12px;
}
.check__block-header-title {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.check_canceled .check__block-header-title {
  color: var(--bluey-grey);
}
.check__block-content-item {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.check__block-content-item-title {
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  width: 60%;
  min-width: 60%;
  line-height: 1.2;
  word-break: break-all;
}
.check_canceled .check__block-content-item-title {
  color: var(--bluey-grey);
}
.check__block-content-item-sum {
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  width: 40%;
  min-width: 40%;
  text-align: right;
}
.check_canceled .check__block-content-item-sum {
  color: var(--bluey-grey);
}
.check__sum-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.check__sum-text_sum-title {
  font-weight: 700;
}
.check_canceled .check__sum-text {
  color: var(--bluey-grey);
}
.check__customer-name {
  display: block;
  padding-top: 7px;
}
.check__qr {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  background: var(--dark-slate-blue);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.check__qr-inner {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}
.check__qr-inner canvas {
  max-width: 100% !important;
  max-height: 100% !important;
}
.check__bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: -10px;
  height: 28px;
}
.check__bg-square {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.check__close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.check__close-icon {
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 800px) {
  .check {
    padding-top: 65px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.invoice-payment-request-link {
  background: white;
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  position: relative;
  width: 600px;
}
.invoice-payment-request-link__header {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  border-radius: 14px 14px 0 0;
  min-height: 121px;
  overflow: hidden;
  padding: 16px;
  position: relative;
}
.invoice-payment-request-link__header_menu-open {
  background: white;
}
.invoice-payment-request-link__header-bg {
  position: absolute;
  right: -52px;
  top: -120px;
  width: 254px;
  z-index: 20;
}
.invoice-payment-request-link__header-logo {
  left: 50%;
  margin-left: -65px;
  position: absolute;
  top: 76px;
  z-index: 40;
}
.invoice-payment-request-link__top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 80;
  height: 30px;
}
.invoice-payment-request-link__top-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.invoice-payment-request-link__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  flex-direction: row-reverse;
  grid-gap: 10px;
  gap: 10px;
}
.invoice-payment-request-link__back-button-text {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
@media (min-width: 981px) {
  .invoice-payment-request-link__whiteLogoWrap {
    display: none;
  }
}
.invoice-payment-request-link__whiteLogoWrap svg {
  z-index: 100;
  height: 40px;
  width: 40px;
}
.invoice-payment-request-link__back-button-icon {
  height: 23px;
  width: 13px;
}
.invoice-payment-request-link__back-button-icon_menu-open path {
  stroke: var(--dark-slate-blue);
}
.invoice-payment-request-link__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  margin-left: 26px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.invoice-payment-request-link__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.invoice-payment-request-link__burger-span path {
  fill: white;
  transition: all 0.3s ease;
}
.invoice-payment-request-link__burger-span:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.invoice-payment-request-link__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.invoice-payment-request-link__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.invoice-payment-request-link__burger_menu-open .invoice-payment-request-link__burger-span:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.invoice-payment-request-link__burger_menu-open .invoice-payment-request-link__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.invoice-payment-request-link__burger_menu-open .invoice-payment-request-link__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.invoice-payment-request-link__burger_menu-open .invoice-payment-request-link__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.invoice-payment-request-link__burger_menu-open .invoice-payment-request-link__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.invoice-payment-request-link__burger_menu-open .invoice-payment-request-link__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.invoice-payment-request-link__menu {
  bottom: 0;
  height: auto;
  min-height: 100vh;
}
.invoice-payment-request-link__menu_non-auth {
  padding-top: 40px;
  top: 0;
}
.invoice-payment-request-link__content {
  padding-bottom: 25px;
  position: relative;
  z-index: 40;
}
.invoice-payment-request-link__content-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
  margin-bottom: 14px;
  text-align: center;
}
.invoice-payment-request-link__avatar-wrap {
  border: 2px solid white;
  border-radius: 50%;
  height: 90px;
  margin: -45px auto 20px auto;
  overflow: hidden;
  position: relative;
  width: 90px;
}
.invoice-payment-request-link__avatar-wrapinvoice-payment-request-link-to-pay {
  margin-bottom: 20px;
}
.invoice-payment-request-link__avatar {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.invoice-payment-request-link__card {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
  width: 100%;
}
.invoice-payment-request-link__info-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-bottom: 11px;
  text-align: center;
}
.invoice-payment-request-link__info-purpose {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: var(--pale-grey);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
  word-break: break-all;
}
.invoice-payment-request-link__email {
  display: flex;
  flex-direction: column;
  margin: 0 auto 32px;
  max-width: 380px;
  width: 100%;
}
.invoice-payment-request-link__email-input-holder {
  margin-bottom: 8px;
  position: relative;
  width: 100%;
}
.invoice-payment-request-link__email-input {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  -webkit-appearance: none;
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(228, 232, 238, 0.2);
  border-radius: 8px;
  box-shadow: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 60px;
  padding: 0 40px 0 20px;
  width: 100%;
}
.invoice-payment-request-link__email-input::-webkit-input-placeholder {
  color: var(--grey-blue);
  opacity: 1;
}
.invoice-payment-request-link__email-input::-moz-placeholder {
  color: var(--grey-blue);
  opacity: 1;
}
.invoice-payment-request-link__email-input:-moz-placeholder {
  color: var(--grey-blue);
  opacity: 1;
}
.invoice-payment-request-link__email-input:-ms-input-placeholder {
  color: var(--grey-blue);
  opacity: 1;
}
.invoice-payment-request-link__email-input::-ms-input-placeholder {
  color: var(--grey-blue);
  opacity: 1;
}
.invoice-payment-request-link__email-input::-ms-clear {
  display: none;
}
.invoice-payment-request-link__email-input:focus {
  background: white;
  border-color: var(--dark-slate-blue);
}
.invoice-payment-request-link__email-input[type=number]::-webkit-outer-spin-button, .invoice-payment-request-link__email-input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.invoice-payment-request-link__email-input[type=number] {
  -moz-appearance: textfield;
}
.invoice-payment-request-link__email-input-label {
  font-family: "Circe-Bold", sans-serif;
  color: #6b778d;
  font-size: 8px;
  left: 20px;
  position: absolute;
  text-transform: uppercase;
  top: 7px;
}
.invoice-payment-request-link__email-desc {
  font-family: "Circe-Regular", sans-serif;
  color: #616a88;
  font-size: 12px;
  line-height: 1.17;
}
.invoice-payment-request-link__info-sum {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
  margin-bottom: 32px;
  text-align: center;
}
.invoice-payment-request-link__bill {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 291px;
}
.invoice-payment-request-link__btn-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  width: 100%;
}
.invoice-payment-request-link__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 320px;
}
.invoice-payment-request-link__btn:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.invoice-payment-request-link__btn:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.invoice-payment-request-link__attention-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  text-align: center;
}
.invoice-payment-request-link__iframe-wrap {
  height: 370px;
  position: relative;
}
.invoice-payment-request-link__iframe-wrap-sbp {
  height: 690px;
  position: relative;
  margin-bottom: 0px;
}
.invoice-payment-request-link__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.invoice-payment-request-link__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}
.invoice-payment-request-link__translate-to-ru {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media screen and (max-width: 980px) {
  .invoice-payment-request-link {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }
  .invoice-payment-request-link__header {
    border-radius: 0;
    min-height: 98px;
    height: 98px;
    padding: 20px 50px;
  }
  .invoice-payment-request-link__back-button {
    width: 30px;
  }
  .invoice-payment-request-link__avatar-wrap {
    margin-bottom: 10px;
  }
  .invoice-payment-request-link__content {
    padding-bottom: 0;
  }
  .invoice-payment-request-link__btn-wrap {
    margin-bottom: 0;
  }
  .invoice-payment-request-link__btn {
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 335px;
    width: 100%;
  }
  .invoice-payment-request-link__attention-text {
    margin-bottom: 37px;
  }
  .invoice-payment-request-link__translate-to-ru {
    position: static;
    margin-right: 10px;
  }
}
@media screen and (max-width: 600px) {
  .invoice-payment-request-link__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .invoice-payment-request-link__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .invoice-payment-request-link__email {
    flex-direction: column;
  }
  .invoice-payment-request-link__email-input-holder {
    margin-bottom: 16px;
    width: 100%;
  }
  .invoice-payment-request-link__email-desc {
    padding-left: 0;
  }
  .invoice-payment-request-link__iframe-wrap-sbp {
    height: 710px;
  }
}
@media screen and (max-width: 350px) {
  .invoice-payment-request-link__header {
    padding-left: 14px;
    padding-right: 14px;
  }
  .invoice-payment-request-link__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.check-payment-request-container {
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
.check-payment-request-container_menu-open {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
.check-payment-request-container__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  position: relative;
  width: 100%;
}
.check-payment-request-container__header_menu-open {
  z-index: 110;
}
.check-payment-request-container__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  margin-left: 26px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.check-payment-request-container__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.check-payment-request-container__burger-span path {
  transition: all 0.3s ease;
}
.check-payment-request-container__burger-span:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.check-payment-request-container__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.check-payment-request-container__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.check-payment-request-container__burger_menu-open .check-payment-request-container__burger-span:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.check-payment-request-container__burger_menu-open .check-payment-request-container__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.check-payment-request-container__burger_menu-open .check-payment-request-container__burger-span:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.check-payment-request-container__burger_menu-open .check-payment-request-container__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.check-payment-request-container__burger_menu-open .check-payment-request-container__burger-span:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.check-payment-request-container__burger_menu-open .check-payment-request-container__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.check-payment-request-container__menu {
  bottom: 0;
  height: 100%;
  min-height: 100vh;
  z-index: 100;
}
.check-payment-request-container__menu_non-auth {
  padding-top: 300px;
  top: 0;
}
.check-payment-request-container__loader {
  flex-grow: 20;
}
.check-payment-request-container__error {
  align-items: center;
  display: flex;
  flex-grow: 200;
  justify-content: center;
}
.check-payment-request-container__error-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 20px;
  text-align: center;
}
.check-payment-request-container__link-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 4000;
  flex-shrink: 0;
  justify-content: center;
  padding-top: 30px;
  width: 100%;
}
.check-payment-request-container__link {
  margin-bottom: 40px;
  position: relative;
  z-index: 61;
}
.check-payment-request-container__payment-ways {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.check-payment-request-container__payment-ways-item_visa {
  height: 20px;
  margin-right: 30px;
  width: 45px;
}
.check-payment-request-container__payment-ways-item_msc {
  height: 19px;
  margin-right: 24px;
  width: 31px;
}
.check-payment-request-container__payment-ways-item_mir {
  height: 19px;
  margin-right: 19px;
  width: 46px;
}
.check-payment-request-container__payment-ways-item_pci {
  height: 18px;
  width: 44px;
}
.check-payment-request-container__info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
  margin-left: auto;
  margin-right: auto;
  max-width: 295px;
  text-align: center;
}
.check-payment-request-container__info-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
  margin-left: 5px;
  text-align: center;
}
.check-payment-request-container__info-link:hover {
  text-decoration: none;
}

@media screen and (max-width: 1194px) {
  .check-payment-request-container__header {
    height: 90px;
    min-height: 90px;
    padding-top: 0;
  }
}
@media screen and (max-width: 980px) {
  .check-payment-request-container__link-wrap {
    justify-content: flex-start;
    padding-top: 0;
  }
  .check-payment-request-container__info {
    margin-bottom: 70px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.email-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-confirm__card {
  max-width: 553px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  padding: 30px 30px 66px 30px;
  position: relative;
}
.email-confirm__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  text-align: center;
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 30px;
}
.email-confirm__icon-wrap {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.email-confirm__icon {
  width: 40px;
  height: 40px;
}
.email-confirm__desc {
  max-width: 324px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.email-confirm__close {
  position: absolute;
  top: 28px;
  right: 28px;
}

@media screen and (max-width: 600px) {
  .email-confirm__card {
    max-width: 90%;
    padding: 66px 30px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.email-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-confirm__card {
  max-width: 553px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  padding: 30px 30px 66px 30px;
  position: relative;
}
.email-confirm__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  text-align: center;
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 30px;
}
.email-confirm__icon-wrap {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.email-confirm__icon {
  width: 40px;
  height: 40px;
}
.email-confirm__desc {
  max-width: 324px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.email-confirm__close {
  position: absolute;
  top: 28px;
  right: 28px;
}

@media screen and (max-width: 600px) {
  .email-confirm__card {
    max-width: 90%;
    padding: 66px 30px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.input-with-label-animation {
  position: relative;
}
.input-with-label-animation__prev-text {
  position: absolute;
  left: 19px;
  top: 27px;
  opacity: 0;
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  pointer-events: none;
}
.input-with-label-animation:focus-within .input-with-label-animation__prev-text {
  opacity: 1;
}
.input-with-label-animation_focused .input-with-label-animation__prev-text {
  opacity: 1;
}
.input-with-label-animation__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
}
.input-with-label-animation__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.input-with-label-animation__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.input-with-label-animation__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.input-with-label-animation_focused:not(:disabled) .input-with-label-animation__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.input-with-label-animation_focused:not(:disabled) .input-with-label-animation__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.input-with-label-animation__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  pointer-events: none;
}
.input-with-label-animation:focus-within .input-with-label-animation__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.input-with-label-animation_focused .input-with-label-animation__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.sum-input {
  position: relative;
  width: 100%;
}
.sum-input__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
}
.sum-input__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.sum-input__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.sum-input__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.sum-input_focused .sum-input__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.sum-input_error .sum-input__input {
  color: var(--reddish-pink);
}
.sum-input__input[type=number]::-webkit-outer-spin-button, .sum-input__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sum-input__input[type=number] {
  -moz-appearance: textfield;
}
.sum-input__input::-webkit-input-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input::-webkit-input-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input::-webkit-input-placeholder {
  opacity: 1;
}
.sum-input__input::-moz-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input::-moz-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input::-moz-placeholder {
  opacity: 1;
}
.sum-input__input:-moz-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input:-moz-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input:-moz-placeholder {
  opacity: 1;
}
.sum-input__input:-ms-input-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input:-ms-input-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input:-ms-input-placeholder {
  opacity: 1;
}
.sum-input__input::-ms-input-placeholder {
  opacity: 0;
}
.sum-input_focused .sum-input__input::-ms-input-placeholder {
  opacity: 1;
}
.sum-input:focus-within .sum-input__input::-ms-input-placeholder {
  opacity: 1;
}
.sum-input__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.sum-input:focus-within .sum-input__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
  white-space: nowrap;
}
.sum-input_focused .sum-input__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
  white-space: nowrap;
}
.sum-input_focused.sum-input_error .sum-input__label {
  color: var(--reddish-pink);
}
.sum-input_error .sum-input__label {
  color: var(--reddish-pink);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.textarea-with-label-animation {
  position: relative;
}
.textarea-with-label-animation__textarea {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
  height: 84px;
  padding-top: 22px;
  line-height: 24px;
}
.textarea-with-label-animation__textarea:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.textarea-with-label-animation__textarea:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.textarea-with-label-animation__textarea:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.textarea-with-label-animation_focused:not(:disabled) .textarea-with-label-animation__textarea {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.textarea-with-label-animation_focused:not(:disabled) .textarea-with-label-animation__textarea:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.textarea-with-label-animation__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.textarea-with-label-animation:focus-within .textarea-with-label-animation__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.textarea-with-label-animation_focused .textarea-with-label-animation__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-preview-form__block {
  margin-bottom: 30px;
}
.show-case-preview-form__block_info {
  margin-bottom: 42px;
}
.show-case-preview_preview .show-case-preview-form__block {
  margin-bottom: 9px;
}
.show-case-preview-form__block-title {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 22px;
}
.show-case-preview-form__button-wrap {
  display: flex;
  margin-bottom: 10px;
}
.show-case-preview-form__button-wrap-preview {
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  display: flex;
  width: 100%;
}
.show-case-preview-form__button-wrap-preview .show-case-preview_preview.standard-button {
  font-weight: bold;
}
.show-case-preview-form__button-wrap-preview .sbp-button__logo {
  height: 14px;
  margin-left: 4px;
  margin-top: 1px;
}
.show-case-preview-form__button {
  width: 100%;
}
.show-case-preview-form__privacy-policy {
  text-align: center;
  padding: 0 33px;
  font-size: 14px;
  line-height: 1.43;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 20px;
}
.show-case-preview-form__privacy-policy-link {
  font-size: 14px;
  line-height: 1.43;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.show-case-preview-form__payment-ways {
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-case-preview-form__payment-ways-item_visa {
  width: 45px;
  height: 20px;
  margin-right: 30px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_visa {
  width: 22px;
  height: 10px;
  margin-right: 14px;
}
.show-case-preview-form__payment-ways-item_msc {
  width: 31px;
  height: 19px;
  margin-right: 24px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_msc {
  width: 15px;
  height: 10px;
  margin-right: 12px;
}
.show-case-preview-form__payment-ways-item_mir {
  width: 46px;
  height: 19px;
  margin-right: 19px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_mir {
  width: 22px;
  height: 9px;
  margin-right: 9px;
}
.show-case-preview-form__payment-ways-item_pci {
  width: 44px;
  height: 18px;
}
.show-case-preview_preview .show-case-preview-form__payment-ways-item_pci {
  width: 21px;
  height: 9px;
}
.show-case-preview-form__iframe-wrap {
  height: 450px;
  position: relative;
}
.show-case-preview-form__iframe-wrap-sbp {
  height: 720px;
  position: relative;
  margin-bottom: 0px;
}
.show-case-preview-form__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding-top: 100px;
  justify-content: center;
  z-index: 10;
}
.show-case-preview-form__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 600px) {
  .show-case-preview-form__block {
    margin-bottom: 20px;
  }
  .show-case-preview-form__iframe-wrap-sbp {
    height: 720px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-summary {
  padding: 16px 18px 16px 20px;
}
.payment-summary__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
}
.payment-summary__item:last-child {
  margin-bottom: 0;
}
.payment-summary__item:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border-bottom: 1px dashed var(--bluey-grey);
  z-index: 10;
}
.payment-summary__item-title {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background: white;
  position: relative;
  z-index: 20;
  padding-right: 9px;
}
.payment-summary__item-value {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  background: white;
  position: relative;
  z-index: 20;
  width: 35%;
  text-align: right;
  word-break: break-all;
  padding-left: 9px;
}

@media screen and (max-width: 600px) {
  .payment-summary {
    padding: 16px 10px 16px 10px;
  }
  .payment-summary__item-value {
    width: 40%;
    min-width: 40%;
  }
  .yandex-card__payment-summary .payment-summary__item-value {
    width: 44%;
    min-width: 44%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-message__icon-wrap {
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  position: relative;
}
.payment-message__icon {
  position: absolute;
  width: 27px;
  height: 22px;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -13px;
}
.payment-message__icon_fail path {
  fill: var(--reddish-pink);
}
.payment-message__icon_success {
  width: 10px;
  height: 8px;
  margin-left: -5px;
  margin-top: -4px;
}
.payment-message__text {
  font-size: 12px;
  color: var(--bluey-grey);
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.payment-message__text-link {
  font-size: 12px;
  color: var(--bluey-grey);
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.payment-message__text-link:hover {
  text-decoration: none;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-process-card {
  border-radius: 12px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  overflow: hidden;
}
.payment-process-card__header {
  height: 92px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-image: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(90, 200, 250) 100%);
  padding: 36px 36px 20px 36px;
  position: relative;
}
.payment-process-card__header:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
.payment-process-card__header-logo {
  object-fit: contain;
  width: 110px;
  height: 38px;
  position: relative;
  z-index: 20;
}
.payment-process-card__header-bg {
  width: 284px;
  position: absolute;
  right: -60px;
  top: -176px;
  z-index: 20;
}
.payment-process-card__payment-message {
  padding-top: 30px;
  padding-bottom: 30px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-success-header {
  display: flex;
  margin-bottom: 10px;
  padding: 32px 20px 0 20px;
}
.payment-success-header__success-icon-wrap {
  width: 44px;
  min-width: 44px;
  height: 44px;
  margin-right: 27px;
  position: relative;
}
.payment-success-header__success-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 10px;
  margin-top: -5px;
  margin-left: -7px;
}
.payment-success-header__success-icon path {
  stroke: var(--turquoise-blue);
}
.payment-success-header__in-process-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--turquoise-blue);
  text-align: center;
  line-height: 44px;
}
.payment-success-header__success-text-title {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
}
.payment-success-header__success-text-result {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-preview {
  width: 100%;
  margin-top: -100px;
}
.show-case-preview_logged-in {
  margin-top: 0;
}
.show-case-preview_preview {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 0;
}
.show-case-preview_preview .input-with-label-animation__input,
.show-case-preview_preview .sum-input__input {
  height: 28px;
  padding: 0 8px;
  border-radius: 3.8px;
  font-size: 7.6px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.show-case-preview_preview .textarea-with-label-animation__textarea {
  height: 62px;
  border-radius: 3.8px;
  padding: 0;
}
.show-case-preview_preview .input-with-label-animation__label,
.show-case-preview_preview .textarea-with-label-animation__label,
.show-case-preview_preview .sum-input__label {
  font-size: 8.6px;
  top: 9px;
  left: 9px;
}
.show-case-preview_preview .sum-input_focused .sum-input__label,
.show-case-preview_preview .textarea-with-label-animation_focused .textarea-with-label-animation__label,
.show-case-preview_preview .input-with-label-animation_focused .input-with-label-animation__label {
  top: 4px;
  font-size: 3.8px;
}
.show-case-preview_preview .standard-button {
  height: 29px;
  font-size: 7.6px;
  border-radius: 3.8px;
}
.show-case-preview__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  height: 30px;
  justify-content: flex-start;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  flex-direction: row-reverse;
  grid-gap: 10px;
  gap: 10px;
}
.show-case-preview__back-button-wrapper {
  height: 30px;
}
.show-case-preview__back-button-text {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
.show-case-preview__top {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
  padding-top: 125px;
}
.show-case-preview__top_logged-in {
  padding-top: 40px;
}
.show-case-preview_preview .show-case-preview__top {
  padding-top: 64px;
  padding-bottom: 37px;
  min-height: 160px;
}
.show-case-preview__top-logo {
  position: absolute;
  top: 23px;
  left: 23px;
  width: 61.8px;
  height: 21.2px;
}
.show-case-preview__top-burger {
  position: absolute;
  top: 31px;
  right: 23px;
  width: 14.3px;
  height: 9.1px;
  z-index: 20;
}
.show-case-preview__top-burger rect {
  fill: white;
}
.show-case-preview__top-container {
  position: relative;
  z-index: 20;
}
.show-case-preview_preview .show-case-preview__top-container {
  padding-left: 57px;
  padding-right: 57px;
}
.show-case-preview__title-wrap {
  margin-bottom: 40px;
  padding-top: 20px;
}
.show-case-preview__pre-title {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 15px;
}
.show-case-preview_preview .show-case-preview__pre-title {
  font-size: 6.7px;
  margin-bottom: 5px;
}
.show-case-preview__title {
  font-size: 40px;
  color: white;
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 20px;
  max-width: 50%;
  word-break: break-word;
}
.show-case-preview_preview .show-case-preview__title {
  font-size: 19px;
  margin-bottom: 9px;
}
.show-case-preview__person-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 70%;
  position: relative;
  z-index: 20;
  margin-bottom: 30px;
}
.show-case-preview__person-info-block {
  margin-right: 40px;
  margin-bottom: 20px;
}
.show-case-preview_preview .show-case-preview__person-info-block {
  margin-right: 18px;
  margin-bottom: 10px;
}
.show-case-preview__person-info-block:last-child {
  margin-right: 0;
}
.show-case-preview__person-info-block-title {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 12px;
}
.show-case-preview_preview .show-case-preview__person-info-block-title {
  font-size: 6.7px;
  margin-bottom: 6px;
}
.show-case-preview__person-info-block-text {
  font-size: 18px;
  color: white;
  font-family: "Circe-Regular", sans-serif;
  text-transform: lowercase;
}
.show-case-preview__person-info-block-text_name {
  text-transform: capitalize;
}
.show-case-preview_preview .show-case-preview__person-info-block-text {
  font-size: 8.6px;
}
.show-case-preview__top-bg {
  width: 773px;
  height: 943px;
  position: absolute;
  right: -275px;
  bottom: 0;
  z-index: 10;
}
.show-case-preview_preview .show-case-preview__top-bg {
  width: 341px;
  height: 390px;
  margin-top: -261px;
  right: -115px;
}
.show-case-preview__content {
  background: white;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 400px;
  flex-shrink: 0;
}
.show-case-preview_preview .show-case-preview__content {
  min-height: 220px;
  padding-top: 19px;
}
.show-case-preview__content-container {
  display: flex;
  align-items: flex-start;
}
.show-case-preview_preview .show-case-preview__content-container {
  padding-left: 57px;
  padding-right: 57px;
}
.show-case-preview__form {
  width: 400px;
  min-width: 400px;
  margin-right: 100px;
}
.show-case-preview_preview .show-case-preview__form {
  width: 191px;
  min-width: 191px;
  margin-right: 48px;
}
.show-case-preview__balloon {
  -webkit-filter: drop-shadow(0 4px 27px #e8ecf2);
          filter: drop-shadow(0 4px 27px #e8ecf2);
  background: white;
  background-size: cover;
  padding: 24px 39px;
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  position: relative;
}
.show-case-preview_preview .show-case-preview__balloon {
  max-width: 179px;
  padding: 9px 9px 9px 19px;
  border-radius: 10px;
}
.show-case-preview_preview .show-case-preview__balloon:after {
  bottom: -13px;
  left: 12px;
  width: 19px;
  height: 13px;
}
.show-case-preview__balloon-triangle {
  position: absolute;
  bottom: -23px;
  left: 22px;
  width: 29px;
  height: 23px;
}
.show-case-preview_preview .show-case-preview__balloon-triangle {
  bottom: -13px;
  left: 12px;
  width: 19px;
  height: 13px;
}
.show-case-preview__balloon-text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.show-case-preview_preview .show-case-preview__balloon-text {
  font-size: 7.6px;
}
.show-case-preview__payment-ways {
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-case-preview__payment-ways-item_visa {
  width: 45px;
  height: 20px;
  margin-right: 30px;
}
.show-case-preview__payment-ways-item_msc {
  width: 31px;
  height: 19px;
  margin-right: 24px;
}
.show-case-preview__payment-ways-item_mir {
  width: 46px;
  height: 19px;
  margin-right: 19px;
}
.show-case-preview__payment-ways-item_pci {
  width: 44px;
  height: 18px;
}
.show-case-preview__payment {
  max-width: 400px;
  width: 100%;
}
.show-case-preview__payment_with-check {
  margin-right: 100px;
}
.show-case-preview__payment-card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 12px;
  padding-bottom: 30px;
}
.show-case-preview__payment-card_success {
  margin-bottom: 20px;
}
.show-case-preview__payment-fail-reason {
  margin-bottom: 20px;
  text-align: center;
  margin-top: 16px;
}
.show-case-preview__payment-fail-reason-key {
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.show-case-preview__payment-fail-reason-value {
  font-size: 14px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.show-case-preview__payment-no-check-note {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
}
.show-case-preview__payment-no-check-note-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.show-case-preview__payment-no-check-note-text {
  font-size: 14px;
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  margin-top: 3px;
}
.show-case-preview__payment-button-list {
  display: flex;
}
.show-case-preview__payment-button {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.show-case-preview__payment-button:last-child {
  margin-right: 0;
}
.show-case-preview__payment-button_wide {
  width: 100%;
}
.show-case-preview__check {
  max-width: 291px;
  width: 100%;
}
.show-case-preview__blocked-user {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.show-case-preview__blocked-user-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

@media screen and (max-width: 1194px) {
  .show-case-preview {
    width: 100%;
    margin-top: -90px;
  }
  .show-case-preview_logged-in {
    margin-top: 0;
  }
  .show-case-preview_preview {
    margin-top: 0;
  }
  .show-case-preview_preview .show-case-preview__top-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .show-case-preview_preview .show-case-preview__content-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 980px) {
  .show-case-preview {
    margin-top: -90px;
  }
  .show-case-preview__top {
    padding-top: 130px;
  }
  .show-case-preview__back-button {
    top: -30px;
  }
  .show-case-preview__content-container {
    flex-direction: column-reverse;
  }
  .show-case-preview__content-container_success-payment {
    flex-direction: row;
  }
  .show-case-preview__top-bg {
    width: 333px;
    height: 332px;
    right: -30px;
    top: auto;
    bottom: 0;
    margin-top: 0;
  }
  .show-case-preview_preview .show-case-preview__top-bg {
    width: 341px;
    height: 390px;
    right: -115px;
  }
  .show-case-preview__balloon {
    margin-bottom: 40px;
  }
  .show-case-preview__person-info {
    display: block;
    width: 100%;
  }
  .show-case-preview__person-info-block {
    width: 100%;
  }
  .show-case-preview__form {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .show-case-preview_preview .show-case-preview__form {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .show-case-preview__payment_with-check {
    margin-right: 50px;
  }
}
@media screen and (max-width: 800px) {
  .show-case-preview {
    margin-top: -70px;
  }
  .show-case-preview__payment_with-check {
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .show-case-preview__top {
    padding-top: 100px;
    padding-bottom: 20px;
  }
  .show-case-preview__top-bg {
    width: 243px;
    height: 282px;
  }
  .show-case-preview__pre-title {
    margin-bottom: 10px;
  }
  .show-case-preview__title-wrap {
    margin-bottom: 24px;
  }
  .show-case-preview__title {
    font-size: 28px;
    max-width: 90%;
    line-height: 1.2;
  }
  .show-case-preview__balloon {
    padding: 18px 19px 18px 19px;
    margin-bottom: 58px;
  }
  .show-case-preview__person-info-block-text {
    font-size: 16px;
  }
  .show-case-preview__payment-button-list {
    flex-direction: column-reverse;
  }
  .show-case-preview__payment-button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .show-case-preview__payment-button:first-child {
    margin-bottom: 0;
  }
}
.show-case {
  display: flex;
  flex-grow: 200;
}
.show-case__loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-preview-form__block-title {
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
  color: #616a88;
  font-size: 25px;
  margin-bottom: 20px;
}
.save-money-preview-form__block {
  margin-bottom: 22px;
}
.save-money-preview_preview .save-money-preview-form__block {
  margin-bottom: 10px;
}
.save-money-preview-form__block:last-child {
  margin-bottom: 0;
}
.save-money-preview-form__input-holder {
  position: relative;
}
.save-money-preview-form__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 60px;
  padding: 10px 20px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.save-money-preview_preview .save-money-preview-form__input {
  border-radius: 4px;
  font-size: 8px;
  height: 30px;
  padding: 9px 9px 0;
}
.save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-webkit-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input::-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input::-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input:-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input:-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-moz-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-moz-placeholder {
  opacity: 1;
}
.save-money-preview-form__input:-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input:-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input:-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input::-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input::-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-ms-input-placeholder {
  opacity: 0;
}
.save-money-preview-form__input-holder:focus-within.save-money-preview-form__input-holder_sum .save-money-preview-form__input::-ms-input-placeholder {
  opacity: 1;
}
.save-money-preview-form__input::-ms-clear {
  display: none;
}
.save-money-preview-form__input:focus {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__input:disabled {
  cursor: default;
}
.save-money-preview-form__input:hover:not(:disabled) {
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__input {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__input-check {
  bottom: 17px;
  position: absolute;
  right: 20px;
}
.save-money-preview-form__input-check path {
  stroke: var(--dark-slate-blue);
}
.save-money-preview-form__textarea {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 130px;
  padding: 29px 20px 16px;
  transition: all 0.3s ease;
  vertical-align: top;
  width: 100%;
}
.save-money-preview_preview .save-money-preview-form__textarea {
  border-radius: 4px;
  font-size: 8px;
  height: 62px;
  padding: 14px 9px 9px;
}
.save-money-preview-form__textarea::-webkit-input-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea::-moz-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea:-moz-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea:-ms-input-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea::-ms-input-placeholder {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  letter-spacing: 0;
  opacity: 1;
}
.save-money-preview-form__textarea::-ms-clear {
  display: none;
}
.save-money-preview-form__textarea:focus {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__textarea:disabled {
  cursor: default;
}
.save-money-preview-form__textarea:hover:not(:disabled) {
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__textarea {
  background: white;
  border-color: var(--dark-slate-blue);
  box-shadow: none;
}
.save-money-preview-form__label {
  font-family: "Circe-Regular", sans-serif;
  color: #9da6ba;
  font-size: 18px;
  left: 24px;
  pointer-events: none;
  position: absolute;
  top: 22px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: all 0.3s ease;
}
.save-money-preview_preview .save-money-preview-form__label {
  font-size: 9px;
  left: 10px;
  top: 10px;
}
.save-money-preview-form__input-holder:focus-within .save-money-preview-form__label {
  color: #616a88;
  left: 20px;
  text-transform: uppercase;
  top: 10px;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.save-money-preview_preview .save-money-preview-form__input-holder:focus-within .save-money-preview-form__label {
  left: 10px;
  top: 5px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.save-money-preview-form__input-holder_focused .save-money-preview-form__label {
  color: #616a88;
  left: 20px;
  text-transform: uppercase;
  top: 10px;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.save-money-preview_preview .save-money-preview-form__input-holder_focused .save-money-preview-form__label {
  left: 10px;
  top: 5px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.save-money-preview-form__button-wrap {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}
.save-money-preview-form__button-wrap-preview {
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  display: flex;
  width: 100%;
}
.save-money-preview-form__button-wrap-preview .save-money-preview-form__button.standard-button {
  font-weight: bold;
}
.save-money-preview-form__button-wrap-preview .sbp-button__logo {
  height: 14px;
  margin-left: 4px;
  margin-top: 1px;
}
.save-money-preview_preview .save-money-preview-form__button-wrap {
  margin-bottom: 7px;
}
.save-money-preview_preview .save-money-preview-form__button-wrap .payment-box__button {
  border-radius: 4px;
  font-size: 8px;
  height: 30px;
}
.save-money-preview-form__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border: 1px solid var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 16px;
  height: 60px;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 100%;
}
.save-money-preview_preview .save-money-preview-form__button {
  border-radius: 4px;
  font-size: 8px;
  height: 30px;
}
.save-money-preview-form__button:hover {
  background: var(--dusk-blue);
}
.save-money-preview-form__button[disabled] {
  background-color: var(--pale-grey);
  border-color: var(--pale-grey);
  color: #9da6ba;
  cursor: not-allowed;
}
.save-money-preview-form__privacy-policy {
  font-family: "Circe-Regular", sans-serif;
  color: #616a88;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.43;
  margin-bottom: 30px;
  padding: 0 30px;
  text-align: center;
}
.save-money-preview_preview .save-money-preview-form__privacy-policy {
  font-size: 7px;
  margin-bottom: 14px;
  padding: 0 15px;
}
.save-money-preview-form__privacy-policy-link {
  color: var(--dark-slate-blue);
}
.save-money-preview-form__privacy-policy-link:hover {
  text-decoration: none;
}
.save-money-preview-form__payments-type {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.save-money-preview-form__payments-type-item {
  margin: 0 12px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item {
  margin: 0 6px;
}
.save-money-preview-form__payments-type-item_visa {
  height: 20px;
  width: 45px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_visa {
  height: 10px;
  width: 22px;
}
.save-money-preview-form__payments-type-item_msc {
  height: 19px;
  width: 31px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_msc {
  height: 10px;
  width: 15px;
}
.save-money-preview-form__payments-type-item_mir {
  height: 19px;
  width: 46px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_mir {
  height: 10px;
  width: 22px;
}
.save-money-preview-form__payments-type-item_pci {
  height: 18px;
  width: 44px;
}
.save-money-preview_preview .save-money-preview-form__payments-type-item_pci {
  height: 10px;
  width: 21px;
}
.save-money-preview-form__iframe-wrap {
  height: 440px;
  position: relative;
}
.save-money-preview-form__iframe-wrap-sbp {
  height: 720px;
  position: relative;
  margin-bottom: 0px;
}
.save-money-preview-form__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 120px;
  z-index: 10;
}
.save-money-preview-form__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 600px) {
  .save-money-preview-form__iframe-wrap-sbp {
    height: 720px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-preview {
  width: 100%;
  padding: 180px 0 80px;
  margin-top: -100px;
}
.save-money-preview_real {
  min-height: 100vh;
}
.save-money-preview_logged-in {
  padding-top: 80px;
  margin-top: 0;
}
.save-money-preview_color-theme-1 {
  background-image: var(--save-money-color-theme-1);
}
.save-money-preview_color-theme-2 {
  background-image: var(--save-money-color-theme-2);
}
.save-money-preview_color-theme-3 {
  background-image: var(--save-money-color-theme-3);
}
.save-money-preview_color-theme-4 {
  background-image: var(--save-money-color-theme-4);
}
.save-money-preview_color-theme-5 {
  background-image: var(--save-money-color-theme-5);
}
.save-money-preview_color-theme-6 {
  background-image: var(--save-money-color-theme-6);
}
.save-money-preview_preview {
  padding: 0 0 50px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  pointer-events: none;
}
.save-money-preview_preview .save-money-preview__header {
  padding: 24px 0 40px;
}
.save-money-preview__header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-preview_preview .save-money-preview__header-top {
  padding: 0 24px;
}
.save-money-preview__header-logo {
  width: 62px;
  height: 21px;
}
.save-money-preview__header-burger {
  width: 14px;
  height: 9px;
}
.save-money-preview__header-burger rect {
  fill: white;
}
.save-money-preview__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  bottom: 35px;
  position: relative;
  display: inline-flex;
  height: 30px;
  justify-content: flex-start;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  flex-direction: row-reverse;
  grid-gap: 10px;
  gap: 10px;
}
.save-money-preview__back-button-wrapper {
  height: 30px;
}
.save-money-preview__back-button-text {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
.save-money-preview__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.save-money-preview_preview .save-money-preview__container {
  padding: 0 57px;
}
.save-money-preview__col_about {
  padding-right: 20px;
  width: 44%;
}
.save-money-preview__col_form {
  width: 56%;
}
.save-money-preview__author-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 40px;
}
.save-money-preview_preview .save-money-preview__author-block {
  margin-bottom: 20px;
}
.save-money-preview__author-block-avatar-wrap {
  margin-right: 15px;
}
.save-money-preview_preview .save-money-preview__author-block-avatar-wrap {
  margin-right: 7px;
}
.save-money-preview__author-block-avatar-holder {
  overflow: hidden;
  width: 60px;
  height: 60px;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}
.save-money-preview_preview .save-money-preview__author-block-avatar-holder {
  width: 29px;
  height: 29px;
  padding: 1px;
}
.save-money-preview__author-block-avatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.save-money-preview__author-block-info-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  width: 100%;
}
.save-money-preview_preview .save-money-preview__author-block-info-wrap {
  padding-top: 2px;
}
.save-money-preview__info-block {
  margin-bottom: 40px;
}
.save-money-preview_preview .save-money-preview__info-block {
  margin-bottom: 20px;
}
.save-money-preview__author-block .save-money-preview__info-block {
  padding-right: 10px;
  margin-bottom: 0;
  flex: 0 1 50%;
}
.save-money-preview__author-block .save-money-preview__info-block:last-child {
  margin-right: 0;
}
.save-money-preview__info-block:last-child {
  margin-bottom: 0;
}
.save-money-preview__info-block-title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 8px;
}
.save-money-preview_preview .save-money-preview__info-block-title {
  font-size: 6.7px;
  margin-bottom: 3px;
}
.save-money-preview__author-block .save-money-preview__info-block-title {
  margin-bottom: 4px;
}
.save-money-preview_preview .save-money-preview__author-block .save-money-preview__info-block-title {
  margin-bottom: 1px;
}
.save-money-preview__info-block-text {
  font-family: "Circe-Bold", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: white;
  word-break: break-word;
}
.save-money-preview_preview .save-money-preview__info-block-text {
  font-size: 9.6px;
}
.save-money-preview__info-block-text_purpose {
  font-size: 40px;
}
.save-money-preview_preview .save-money-preview__info-block-text_purpose {
  font-size: 19px;
  line-height: 1.2;
  word-break: break-word;
}
.save-money-preview__info-block-text_plain-text {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.33;
}
.save-money-preview_preview .save-money-preview__info-block-text_plain-text {
  font-size: 8.6px;
}
.save-money-preview__form-wrap {
  width: 100%;
  max-width: 460px;
  margin: 0 auto 20px;
  border-radius: 8px;
  background-color: white;
  padding: 40px 30px 30px;
  min-height: 489px;
}
.save-money-preview_preview .save-money-preview__form-wrap {
  max-width: 220px;
  margin-bottom: 10px;
  height: auto;
  min-height: auto;
  border-radius: 4px;
  padding: 19px 14px 14px;
}
.save-money-preview__payment-card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 12px;
  padding-bottom: 30px;
}
.save-money-preview__payment-card_success {
  margin-bottom: 20px;
}
.save-money-preview__payment-fail-reason {
  margin-bottom: 20px;
  text-align: center;
  margin-top: 16px;
}
.save-money-preview__payment-fail-reason-key {
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.save-money-preview__payment-fail-reason-value {
  font-size: 14px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.save-money-preview__payment-button {
  width: 100%;
}
.save-money-preview__share-nav {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.save-money-preview__share-nav-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.save-money-preview__share-nav-btn.disabled {
  cursor: default;
}
.save-money-preview__share-nav-btn:disabled {
  cursor: default;
}
.save-money-preview_preview .save-money-preview__share-nav-btn {
  width: 20px;
  height: 20px;
  margin: 0 3px;
}
.save-money-preview__share-nav-icon path {
  fill: rgba(255, 255, 255, 0.5);
  transition: fill 300ms ease;
}
.save-money-preview__share-nav-btn:hover .save-money-preview__share-nav-icon path {
  fill: rgb(255, 255, 255);
}
.save-money-preview__share-nav-btn.disabled:hover .save-money-preview__share-nav-icon path {
  fill: rgba(255, 255, 255, 0.5);
}
.save-money-preview__share-nav-btn:disabled:hover .save-money-preview__share-nav-icon path {
  fill: rgba(255, 255, 255, 0.5);
}
.save-money-preview__share-nav-icon_tw {
  width: 20px;
  height: 16px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_tw {
  width: 10px;
  height: 8px;
}
.save-money-preview__share-nav-icon_tg {
  width: 20px;
  height: 17px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_tg {
  width: 10px;
  height: 8px;
}
.save-money-preview__share-nav-icon_ok {
  width: 20px;
  height: 17px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_ok {
  width: 10px;
  height: 10px;
}
.save-money-preview__share-nav-icon_wa {
  width: 20px;
  height: 17px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_wa {
  width: 10px;
  height: 8px;
}
.save-money-preview__share-nav-icon_fb {
  width: 11px;
  height: 20px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_fb {
  width: 5px;
  height: 10px;
}
.save-money-preview__share-nav-icon_vk {
  width: 23px;
  height: 14px;
}
.save-money-preview_preview .save-money-preview__share-nav-icon_vk {
  width: 11px;
  height: 7px;
}
.save-money-preview__blocked-user {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.save-money-preview__blocked-user-img {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.save-money-preview__blocked-user-text .blocked-user-text__title {
  color: white;
}
.save-money-preview__blocked-user-text .blocked-user-text__desc {
  color: white;
}

@media screen and (max-width: 1194px) {
  .save-money-preview {
    margin-top: -90px;
    padding-top: 170px;
  }
  .save-money-preview_logged-in {
    padding-top: 80px;
    margin-top: 0;
  }
  .save-money-preview_preview {
    margin-top: 0;
    padding-top: 0;
  }
  .save-money-preview_preview .save-money-preview__container {
    padding: 0 24px;
  }
  .save-money-preview__col_about {
    width: 50%;
    padding-right: 0;
  }
  .save-money-preview_preview .save-money-preview__col_about {
    padding-right: 20px;
  }
  .save-money-preview__col_form {
    width: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .save-money-preview__container {
    flex-direction: column;
  }
  .save-money-preview__col_about {
    width: 100%;
    margin-bottom: 40px;
  }
  .save-money-preview__col_form {
    width: 100%;
  }
  .save-money-preview__form-wrap {
    max-width: 450px;
    margin-left: 0;
  }
  .save-money-preview_preview .save-money-preview__form-wrap {
    max-width: 100%;
  }
  .save-money-preview__share-nav {
    max-width: 450px;
    margin-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .save-money-preview_logged-in {
    margin-top: -90px;
    padding-top: 170px;
  }
  .save-money-preview__back-button {
    bottom: 15px;
  }
  .save-money-preview_preview {
    margin-top: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 800px) {
  .save-money-preview {
    margin-top: -70px;
    padding-top: 100px;
  }
  .save-money-preview_logged-in {
    margin-top: -70px;
    padding-top: 100px;
  }
  .save-money-preview_preview {
    margin-top: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .save-money-preview {
    padding-bottom: 0;
  }
  .save-money-preview_preview .save-money-preview__header {
    padding-bottom: 30px;
  }
  .save-money-preview_preview .save-money-preview__header-top {
    padding: 0 14px;
  }
  .save-money-preview__container {
    padding: 0;
  }
  .save-money-preview_preview .save-money-preview__container {
    padding: 0;
  }
  .save-money-preview__col_about {
    padding: 0 20px;
    margin-bottom: 30px;
  }
  .save-money-preview_preview .save-money-preview__col_about {
    padding: 0 14px;
    margin-bottom: 19px;
  }
  .save-money-preview__author-block {
    margin-bottom: 30px;
  }
  .save-money-preview__info-block {
    margin-bottom: 30px;
  }
  .save-money-preview__info-block-text {
    font-size: 16px;
    line-height: 1.5;
  }
  .save-money-preview__info-block-text_purpose {
    font-size: 28px;
    line-height: 1.3;
  }
  .save-money-preview__form-wrap {
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    padding: 40px 20px 30px;
  }
  .save-money-preview_preview .save-money-preview__form-wrap {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money {
  display: flex;
  flex-grow: 200;
}
.save-money__loader {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.error-page {
  flex-grow: 200;
}
.error-page__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px 100px 20px;
  max-width: 1240px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.error-page__text {
  max-width: 507px;
  width: 100%;
}
.error-page__title {
  font-size: 36px;
  line-height: 1.33;
  font-family: "Circe-ExtraBold", sans-serif;
  margin-bottom: 20px;
  color: var(--dark-slate-blue);
}
.error-page__desc {
  font-size: 18px;
  line-height: 1.33;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.error-page__desc-link {
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.error-page__desc-link:hover {
  text-decoration: none;
}
.error-page__icon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  position: relative;
  top: 4px;
}
.error-page__hints {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 20;
  padding-left: 93px;
}
.error-page__hints-item {
  -webkit-filter: drop-shadow(0 4px 27px #e8ecf2);
          filter: drop-shadow(0 4px 27px #e8ecf2);
  background: white;
  min-height: 133px;
  max-width: 376px;
  display: flex;
  align-items: center;
  padding: 23px 40px;
  position: relative;
  border-radius: 10px;
}
.error-page__hints-item:first-child {
  margin-bottom: 120px;
  left: 28%;
}
.error-page__hints-item:nth-child(2) {
  margin-bottom: 62px;
}
.error-page__hints-item:last-child {
  left: 46%;
}
.error-page__hints-item-triangle {
  width: 29px;
  height: 23px;
  position: absolute;
  bottom: -23px;
  left: 22px;
}
.error-page__hints-item-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.33;
}
.error-page__hints-item-link {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.33;
}
.error-page__hints-item-link:hover {
  text-decoration: none;
}

@media screen and (max-width: 1194px) {
  .error-page__text {
    max-width: 400px;
  }
  .error-page__hints-item:first-child {
    left: 10%;
  }
  .error-page__hints-item:last-child {
    left: 20%;
  }
}
@media screen and (max-width: 980px) {
  .error-page__container {
    display: block;
    padding-top: 100px;
  }
  .error-page__text {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .error-page__hints {
    padding-left: 0;
  }
  .error-page__hints-item {
    padding: 20px;
  }
  .error-page__hints-item:first-child {
    align-self: flex-start;
    left: 0;
    margin-bottom: 50px;
  }
  .error-page__hints-item:nth-child(2) {
    align-self: flex-end;
    margin-bottom: 50px;
  }
  .error-page__hints-item:last-child {
    align-self: flex-start;
    left: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.privacy-policy {
  line-height: 1.4;
  max-width: 800px;
  padding-bottom: 100px;
  padding-top: 100px;
}
.privacy-policy__error {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 20px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.info-box {
  color: var(--dark-slate-blue);
  font-size: 20px;
  line-height: 1.4;
  font-family: "Circe-Regular", sans-serif;
}
.info-box h1 {
  font-family: "Circe-Bold", sans-serif;
  font-size: 44px;
  margin-bottom: 120px;
  color: var(--dark-slate-blue);
  line-height: 1.27;
}
.info-box h3 {
  margin-bottom: 30px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-size: 24px;
  font-family: "Circe-Bold", sans-serif;
}
.info-box b, .info-box strong {
  font-family: "Circe-Bold", sans-serif;
}
.info-box a {
  text-decoration: underline;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 30px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.info-box a:hover {
  text-decoration: none;
}
.info-box p {
  color: var(--dark-slate-blue);
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.info-box ol {
  list-style: none;
}
.info-box ol > li {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.4;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.info-box ol > li > ul {
  list-style: none;
}
.info-box ol > li > ul > li {
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 1.4;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.accept-new-agreement {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 200;
  padding-top: 50px;
  padding-bottom: 70px;
}
.accept-new-agreement__header {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}
.accept-new-agreement__main {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.accept-new-agreement__main-content {
  max-width: 610px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 27px #e8ecf2;
  border-radius: 10px;
  width: 100%;
  padding: 50px 105px 58px 105px;
}
@media (max-width: 620px) {
  .accept-new-agreement__main-content {
    padding: 50px 20px 58px 20px;
    max-width: 100%;
    box-shadow: none;
  }
}
.accept-new-agreement__main-title {
  font-size: 32px;
  font-family: "Circe-ExtraBold", sans-serif;
  text-align: center;
  color: var(--dark-slate-blue);
  margin-bottom: 12px;
}
.accept-new-agreement__main-text {
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
.accept-new-agreement__main-text-link {
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.accept-new-agreement__main-text-link:hover {
  text-decoration: none;
}
.accept-new-agreement__main-buttons-list {
  display: flex;
  justify-content: center;
}
.accept-new-agreement__main-buttons-item {
  width: 160px;
  margin-right: 20px;
}
.accept-new-agreement__main-buttons-item:last-child {
  margin-right: 0;
}
.accept-new-agreement__close-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 19px;
  height: 19px;
}

@media screen and (max-width: 620px) {
  .accept-new-agreement__main {
    max-width: calc(100% - 40px);
  }
  .accept-new-agreement__main-buttons-list {
    display: block;
  }
  .accept-new-agreement__main-buttons-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .accept-new-agreement__main-buttons-item:last-child {
    margin-bottom: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.qr-code-slider {
  max-width: 100%;
  width: 100%;
}
.qr-code-slider__main {
  margin-bottom: 40px;
}
.qr-code-slider__slider-container {
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.qr-code-slider__item {
  align-items: center;
  background-color: white;
  display: flex;
  height: 412px;
  justify-content: center;
}
.qr-code-slider__item-content-circle {
  position: relative;
  width: 352px;
}
.qr-code-slider__item-qr-code-circle {
  left: 109px;
  position: absolute;
  top: 99px;
}
.qr-code-slider__item-qr-code {
  width: 182px;
}
.qr-code-slider__item-content-square {
  height: 352px;
  position: relative;
}
.qr-code-slider__item-img-square {
  height: 352px;
}
.qr-code-slider__item-qr-code-square {
  left: 57px;
  position: absolute;
  top: 85px;
}
.qr-code-slider__nav {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}
.qr-code-slider__nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 14px;
  margin-right: 30px;
  padding-left: 14px;
  position: relative;
}
.qr-code-slider__nav-item::before {
  background: var(--bluey-grey);
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 8px;
}
.qr-code-slider__nav-item:last-child {
  margin-right: 0;
}
.qr-code-slider__nav-item_active {
  color: var(--dark-slate-blue);
}
.qr-code-slider__nav-item_active::before {
  background: var(--dark-slate-blue);
}
.qr-code-slider__download-wrap {
  display: flex;
  justify-content: center;
}
.qr-code-slider__generating {
  font-family: "Circe-Regular", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.qr-code-slider__generating-text {
  color: #1d2e69;
  font-size: 16px;
  line-height: 24px;
  margin-left: 14px;
  margin-top: -2px;
}
.qr-code-slider__generating-desc {
  color: #b0b5c4;
  font-size: 14px;
  line-height: 20px;
  max-width: 297px;
  text-align: center;
}
.qr-code-slider__download {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 16px;
  text-decoration: underline;
}
.qr-code-slider__download:hover {
  text-decoration: none;
}

@media screen and (max-width: 980px) {
  .qr-code-slider__download {
    border: 1px solid var(--dark-slate-blue);
    border-radius: 8px;
    height: 60px;
    min-width: 335px;
    text-decoration: none;
    width: 335px;
  }
}
@media screen and (max-width: 600px) {
  .qr-code-slider__main {
    margin-bottom: 20px;
  }
  .qr-code-slider__nav {
    margin-bottom: 30px;
  }
  .qr-code-slider__nav-item {
    background: var(--bluey-grey);
    border-radius: 50%;
    height: 8px;
    margin-right: 8px;
    padding-left: 0;
    width: 8px;
  }
  .qr-code-slider__nav-item::before {
    display: none;
  }
  .qr-code-slider__nav-item_active {
    background: var(--dark-slate-blue);
  }
  .qr-code-slider__download {
    min-width: 100%;
    width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.create-link-input__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.create-link-input__input-holder:focus-within .create-link-input__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.create-link-input__input-holder_focused .create-link-input__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.create-link-input__input-holder_focused.create-link-input__input-holder_error .create-link-input__label {
  color: var(--reddish-pink);
}
.create-link-input__input-holder_error .create-link-input__label {
  color: var(--reddish-pink);
}
.create-link-input__input-holder {
  position: relative;
}
.create-link-input__input-holder_error {
  margin-bottom: 8px;
}
.create-link-input__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 40px 0 186px;
  text-transform: lowercase;
  transition: all 0.3s ease;
}
.create-link-input__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.create-link-input__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.create-link-input__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
}
.create-link-input__input_link-name-too-long {
  padding-left: 35px;
}
.create-link-input__input-holder_error .create-link-input__input {
  border-color: var(--reddish-pink);
  color: var(--reddish-pink);
}
.create-link-input__input-holder_focused.create-link-input__input-holder_error .create-link-input__input {
  border-color: var(--reddish-pink);
  color: var(--reddish-pink);
}
.create-link-input__input-holder_focused .create-link-input__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.create-link-input__link-base {
  position: absolute;
  left: 20px;
  top: 28px;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.create-link-input__link-base_hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.create-link-input__input-holder_error .create-link-input__link-base {
  color: var(--reddish-pink);
}
.create-link-input__input-holder_disabled .create-link-input__link-base {
  color: var(--grey-blue);
}
.create-link-input__copy-button {
  right: 20px;
  top: 24px;
  position: absolute;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 12px;
  height: 14px;
}
.create-link-input__copy-button:disabled {
  cursor: not-allowed;
}
.create-link-input__copy-icon {
  width: 12px;
  height: 14px;
}
.create-link-input__copy-icon path {
  transition: all 0.3s ease;
}
.create-link-input__copy-button:hover:not(:disabled) .create-link-input__copy-icon path {
  fill: var(--deep-sky-blue);
}
.create-link-input__copy-button:disabled .create-link-input__copy-icon path {
  fill: var(--light-grey-blue);
}
.create-link-input__copy-done-icon {
  right: 20px;
  top: 50%;
  margin-top: -7px;
  position: absolute;
}
.create-link-input__copy-done-icon path {
  stroke: var(--turquoise-blue);
}
.create-link-input__url-input-loader {
  position: absolute;
  right: -25px;
  top: 50%;
  margin-top: -7px;
}
.create-link-input__error-message {
  font-size: 14px;
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.4;
}

@media screen and (max-width: 980px) {
  .create-link-input__url-input-loader {
    right: -17px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tabs-with-scrollable-active-state {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.tabs-with-scrollable-active-state__nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--bluey-grey);
  font-size: 16px;
  padding: 0 0 4px 0;
  text-decoration: none;
  margin-right: 43px;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.tabs-with-scrollable-active-state__nav-item:last-child {
  margin-right: 0;
}
.tabs-with-scrollable-active-state__nav-item:hover {
  color: var(--dusk-blue);
}
.tabs-with-scrollable-active-state__nav-item_active {
  color: var(--dark-slate-blue);
}
.tabs-with-scrollable-active-state__tabs-bar {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
}
.tabs-with-scrollable-active-state__tabs-bar-active {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  transition: all 0.3s ease;
  border-bottom: 2px dotted var(--dark-slate-blue);
}

@media screen and (max-width: 600px) {
  .tabs-with-scrollable-active-state {
    width: calc(100% + 40px - 20px);
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  .tabs-with-scrollable-active-state__nav-item {
    margin-right: 0;
  }
  .tabs-with-scrollable-active-state__tabs-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--bluey-grey);
    width: 100%;
  }
  .tabs-with-scrollable-active-state__tabs-bar-active {
    position: absolute;
    bottom: -1px;
    top: auto;
    left: 0;
    height: 3px;
    transition: all 0.3s ease;
    border-bottom: 3px solid var(--dark-slate-blue);
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-form {
  width: 100%;
}
.save-money-form__section {
  margin-bottom: 40px;
}
.save-money-form__section_colors {
  margin-bottom: 20px;
}
.save-money-form__section_customer-info {
  margin-bottom: 20px;
}
.save-money-form__tabs {
  margin-bottom: 40px;
}
.save-money-form__block {
  margin-bottom: 22px;
}
.save-money-form__section .save-money-form__block:last-child {
  margin-bottom: 0;
}
.save-money-form__block-title {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 22px;
}
.save-money-form__block-descr {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--bluey-grey);
}
.save-money-form__input-holder {
  position: relative;
}
.save-money-form__input-holder_date {
  position: relative;
  margin-bottom: 8px;
}
.save-money-form__input-holder:focus-within .date-picker-custom-input__itself {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__input-holder_focused .date-picker-custom-input__itself {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
}
.save-money-form__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.save-money-form__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
}
.save-money-form__input-holder_focused .save-money-form__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.save-money-form__input-holder:focus-within .save-money-form__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.save-money-form__input-holder_focused .save-money-form__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  white-space: nowrap;
}
.save-money-form__textarea {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 130px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  vertical-align: top;
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
}
.save-money-form__textarea:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__textarea:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.save-money-form__textarea_disabled:hover {
  box-shadow: none;
  background-color: rgba(228, 232, 238, 0.2);
}
.save-money-form__input-holder_focused .save-money-form__textarea {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.save-money-form__checkbox-list {
  display: flex;
  flex-wrap: wrap;
}
.save-money-form__checkbox-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.save-money-form__checkbox-list .save-money-form__checkbox-wrap {
  width: auto;
  margin-right: 40px;
  margin-bottom: 20px;
}
.save-money-form__checkbox-list .save-money-form__checkbox-wrap:last-child {
  margin-right: 0;
}
.save-money-form__checkbox {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 12px;
  border-radius: 4px;
  border: 1px solid #e4e8ee;
  background-color: var(--pale-grey);
  position: relative;
}
.save-money-form__checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--dark-slate-blue);
  opacity: 0;
  pointer-events: none;
}
.save-money-form__checkbox[aria-checked=true]:after {
  opacity: 1;
}
.save-money-form__checkbox-text {
  font-size: 16px;
  line-height: 1.2;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.save-money-form__color-radio {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--pale-grey);
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}
.save-money-form__color-radio_theme-1 {
  background-image: var(--save-money-color-theme-1);
}
.save-money-form__color-radio_theme-2 {
  background-image: var(--save-money-color-theme-2);
}
.save-money-form__color-radio_theme-3 {
  background-image: var(--save-money-color-theme-3);
}
.save-money-form__color-radio_theme-4 {
  background-image: var(--save-money-color-theme-4);
}
.save-money-form__color-radio_theme-5 {
  background-image: var(--save-money-color-theme-5);
}
.save-money-form__color-radio_theme-6 {
  background-image: var(--save-money-color-theme-6);
}
.save-money-form__color-radio:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  opacity: 0;
  pointer-events: none;
}
.save-money-form__color-radio[aria-checked=true]:after {
  opacity: 1;
}
.save-money-form__sum-type {
  display: flex;
  width: 100%;
}
.save-money-form__sum-type-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 50%;
  min-width: 50%;
  height: 40px;
  line-height: 39px;
  border: 1px solid #e4e8ee;
  background-color: var(--pale-grey);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #616a88;
  text-align: center;
}
.save-money-form__sum-type-btn:first-child {
  border-radius: 8px 0 0 8px;
}
.save-money-form__sum-type-btn:last-child {
  border-radius: 0 8px 8px 0;
}
.save-money-form__sum-type-btn_active {
  background-color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
  color: white;
}
.save-money-form__button-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.save-money-form__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 1px solid var(--dark-slate-blue);
  background-color: var(--dark-slate-blue);
  transition: all 0.3s ease;
  font-size: 16px;
  border-color: var(--dark-slate-blue);
  color: white;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.save-money-form__button:hover {
  background: var(--dusk-blue);
}
.save-money-form__button[disabled] {
  cursor: not-allowed;
  color: #9da6ba;
  background-color: var(--pale-grey);
  border-color: var(--pale-grey);
}
.save-money-form__link-base {
  position: absolute;
  left: 20px;
  top: 28px;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.save-money-form__link-base_hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.save-money-form__input-holder_error .save-money-form__link-base {
  color: var(--reddish-pink);
}
.save-money-form__input-holder_disabled .save-money-form__link-base {
  color: var(--grey-blue);
}
.save-money-form__copy-button {
  right: 20px;
  top: 24px;
  position: absolute;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 12px;
  height: 14px;
}
.save-money-form__copy-button:disabled {
  cursor: not-allowed;
}
.save-money-form__copy-icon {
  width: 12px;
  height: 14px;
}
.save-money-form__copy-icon path {
  transition: all 0.3s ease;
}
.save-money-form__copy-button:hover:not(:disabled) .save-money-form__copy-icon path {
  fill: var(--deep-sky-blue);
}
.save-money-form__copy-button:disabled .save-money-form__copy-icon path {
  fill: var(--light-grey-blue);
}
.save-money-form__copy-done-icon {
  right: 20px;
  top: 50%;
  margin-top: -7px;
  position: absolute;
}
.save-money-form__url-input-loader {
  position: absolute;
  right: -25px;
  top: 50%;
  margin-top: -7px;
}
.save-money-form__error-message {
  font-size: 14px;
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.4;
}
.save-money-form__calendar-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  pointer-events: none;
}
.save-money-form__calendar-icon g {
  transition: fill 0.3s ease;
}
.save-money-form__input-holder:focus-within .save-money-form__calendar-icon g {
  fill: var(--dark-slate-blue);
}
.save-money-form__input-holder_focused .save-money-form__calendar-icon g {
  fill: var(--dark-slate-blue);
}

@media screen and (max-width: 980px) {
  .save-money-form__button-wrap {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 600px) {
  .save-money-form__section_colors {
    margin-bottom: 25px;
  }
  .save-money-form__color-radio {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-mobile-popup {
  padding: 20px;
}
.save-money-mobile-popup #react-aria-modal-dialog {
  width: 100%;
}
.save-money-mobile-popup__header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.save-money-mobile-popup__arrow-wrap {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.save-money-mobile-popup__arrow {
  width: 15px;
  height: 30px;
}
.save-money-mobile-popup__arrow path {
  stroke: var(--dark-slate-blue);
}
.save-money-mobile-popup__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  text-decoration: none;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.save-money-mobile-popup__title {
  font-size: 28px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 25px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.popup-header-with-balance__user {
  display: flex;
  align-items: center;
}
.popup-header-with-balance__user-balance-block {
  display: flex;
  align-items: center;
  margin-right: 9px;
}
.popup-header-with-balance__user-balance-text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--light-grey-blue);
  margin-right: 11px;
}
.popup-header-with-balance__user-balance {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.popup-header-with-balance__user-balance-rubles {
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.popup-header-with-balance__user-balance-penny {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.popup-header-with-balance__user-avatar-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  margin-right: 10px;
}
.popup-header-with-balance__user-avatar {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.popup-header-with-balance__user-phone {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--bluey-grey);
  transition: all 0.3s ease;
  margin-right: 5px;
}
.popup-header-with-balance__user-caret {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.popup-header-with-balance__user-caret-icon {
  width: 11px;
  min-width: 11px;
  height: 5px;
  min-height: 5px;
}
.popup-header-with-balance__user-caret-icon use {
  fill: #8e97b4;
  transition: all 0.3s ease;
}
.header__user-caret:hover .popup-header-with-balance__user-caret-icon use {
  fill: var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.title-with-progress-bar {
  padding-bottom: 17px;
  margin-bottom: 40px;
  position: relative;
}
.title-with-progress-bar__progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--bluey-grey);
}
.title-with-progress-bar__progress-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  transition: all 0.3s ease;
}
.title-with-progress-bar__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}

@media screen and (max-width: 980px) {
  .title-with-progress-bar__title {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .title-with-progress-bar__title {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 28px;
  }
}
@media screen and (max-width: 320px) {
  .title-with-progress-bar__title {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-creation {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.save-money-creation__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.save-money-creation__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.save-money-creation__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-creation__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.save-money-creation__header-logo-wrap {
  text-decoration: none;
  width: 38px;
  height: 33px;
  max-width: 38px;
  max-height: 33px;
}
.save-money-creation__header-logo {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.save-money-creation__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.save-money-creation__form-wrap {
  max-width: 380px;
  width: 100%;
}
.save-money-creation__result {
  display: flex;
  justify-content: flex-end;
  flex-grow: 10;
  width: calc(100% - 380px);
  padding-left: 22px;
}
.save-money-creation__result-inner-wrap {
  width: 100%;
  max-width: 688px;
}
.save-money-creation__result-nav {
  margin-bottom: 20px;
}
.save-money-creation__result-nav-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--bluey-grey);
  margin-bottom: 10px;
}
.save-money-creation__result-nav-item-wrap {
  margin-bottom: 10px;
}
.save-money-creation__result-nav-item-wrap:last-child {
  margin-bottom: 0;
}
.save-money-creation__result-nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  border-bottom: 2px dashed var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.save-money-creation__result-nav-item:hover {
  border-color: transparent;
}
.save-money-creation__preview-wrap {
  width: 100%;
}

@media screen and (max-width: 980px) {
  .save-money-creation__container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .save-money-creation__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
  }
  .save-money-creation__close {
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .save-money-creation__content {
    display: block;
  }
  .save-money-creation__form-wrap {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .save-money-creation__result-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
  .save-money-creation__result-nav-item-wrap {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .save-money-creation__container {
    padding-top: 20px;
  }
  .save-money-creation__header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.copied-url-hover {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--turquoise-blue);
  display: none;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s ease;
  opacity: 0;
  z-index: 20;
  padding: 20px;
}
.copied-url-hover_open {
  display: flex;
  opacity: 1;
  visibility: visible;
}
.copied-url-hover__icon {
  margin-right: 8px;
  margin-top: -4px;
}
.copied-url-hover__icon path {
  fill: white;
}
.copied-url-hover__text {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: white;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.no-operations-made__icon-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 31px;
}
.no-operations-made_save-money .no-operations-made__icon-wrap {
  margin-bottom: 40px;
}
.no-operations-made__icon {
  width: 62px;
  height: 62px;
}
.no-operations-made_save-money .no-operations-made__icon {
  width: 42px;
  height: 42px;
}
.no-operations-made__text {
  color: var(--bluey-grey);
  font-size: 16px;
  line-height: 1.5;
  font-family: "Circe-Regular", sans-serif;
  max-width: 420px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
.no-operations-made_save-money .no-operations-made__text {
  color: var(--dark-slate-blue);
  max-width: none;
}
.no-operations-made__text-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  color: var(--bluey-grey);
  font-size: 16px;
  line-height: 1.5;
  font-family: "Circe-Regular", sans-serif;
  text-decoration: underline;
  margin-left: 5px;
}
.no-operations-made__text-link:hover {
  text-decoration: none;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.progress-bar {
  padding-bottom: 17px;
  position: relative;
}
.progress-bar__progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--bluey-grey);
}
.progress-bar__progress-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  transition: all 0.3s ease;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.responsive-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.responsive-header__logo-wrap {
  text-decoration: none;
  width: 38px;
  height: 33px;
  max-width: 38px;
  max-height: 33px;
}
.responsive-header__logo {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.responsive-header__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 22px;
  height: 22px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-list {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.save-money-list__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.save-money-list__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-list__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.save-money-list__title-wrap {
  margin-bottom: 40px;
}
.save-money-list__title-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.save-money-list__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.save-money-list__count-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5px;
}
.save-money-list__count-text {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 5px;
}
.save-money-list__count {
  font-size: 16px;
  color: #5f6a8b;
  font-family: "Circe-Regular", sans-serif;
}
.save-money-list__content {
  display: flex;
}
.save-money-list__main {
  width: 65.83%;
  min-width: 65.83%;
  margin-right: 30px;
}
.save-money-list__create-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 80px;
  border-radius: 8px;
  border: 1px dashed var(--bluey-grey);
  width: 100%;
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  transition: all 0.3s ease;
  margin-bottom: 30px;
}
.save-money-list__create-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.save-money-list__create-button:hover:not(:disabled) {
  color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
}
.save-money-list__no-goals {
  padding-top: 55px;
}
.save-money-list__no-goals-container {
  position: relative;
  max-width: 440px;
  margin: 0 auto;
}
.save-money-list__no-goals-arrow-icon {
  width: 41px;
  height: 131px;
  position: absolute;
  top: -55px;
  right: calc(100% - 15px);
}
.save-money-list__list-wrap {
  padding-top: 30px;
  border-top: 1px solid rgba(228, 232, 238, 0.5);
}
.save-money-list__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #ffffff;
  padding: 23px 20px 18px 20px;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: default;
}
.save-money-list__item:last-child {
  margin-bottom: 0;
}
.save-money-list__item:disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}
.save-money-list__item-progress {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: 0;
}
.save-money-list__item-progress .progress-bar__progress {
  border-bottom: 0;
}
.save-money-list__item-top {
  display: flex;
  align-items: flex-start;
}
.save-money-list__item-title-wrap {
  max-width: 320px;
  width: 100%;
  margin-right: 40px;
  text-align: left;
  padding-bottom: 0;
}
.save-money-list__item-label {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.save-money-list__item-title {
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  word-break: break-all;
  text-decoration: none;
}
.save-money-list__item-top-right-part {
  flex-grow: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-list__item-sum-wrap {
  text-align: left;
  width: 125px;
  flex-shrink: 0;
  margin-right: 20px;
}
.save-money-list__item-deadline-wrap {
  text-align: left;
  width: 125px;
  flex-shrink: 0;
  margin-right: 20px;
}
.save-money-list__item-sum {
  font-size: 18px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.save-money-list__item-controls {
  display: flex;
  align-items: center;
}
.save-money-list__item-control {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.save-money-list__item-control:last-child {
  margin-right: 0;
}
.save-money-list__item-control:disabled {
  cursor: not-allowed;
}
.save-money-list__item-control-icon {
  pointer-events: none;
}
.save-money-list__item-control-icon_copy path {
  fill: #b0b5c4;
  transition: all 0.3s ease;
}
.save-money-list__item-control:hover:not(:disabled) .save-money-list__item-control-icon_copy path {
  fill: var(--dark-slate-blue);
}
.save-money-list__item-control-icon_edit {
  width: 14px;
  height: 15px;
}
.save-money-list__item-control-icon_edit path {
  transition: all 0.3s ease;
}
.save-money-list__item-control:hover .save-money-list__item-control-icon_edit path {
  fill: var(--dark-slate-blue);
}
.save-money-list__item-control-icon_basket path {
  transition: all 0.3s ease;
}
.save-money-list__item-control:hover .save-money-list__item-control-icon_basket path {
  fill: var(--reddish-pink);
}

@media screen and (max-width: 1194px) {
  .save-money-list__content {
    display: block;
    padding-bottom: 67px;
  }
  .save-money-list__main {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
  }
  .save-money-list__hints {
    width: 100%;
  }
  .save-money-list__item-title-wrap {
    max-width: 500px;
  }
}
@media screen and (max-width: 980px) {
  .save-money-list__container {
    padding-top: 20px;
  }
  .save-money-list__header {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 800px) {
  .save-money-list__item-top {
    flex-direction: column;
  }
  .save-money-list__item-title-wrap {
    margin-bottom: 20px;
  }
  .save-money-list__item-title-wrap {
    margin-right: 0;
    max-width: none;
  }
  .save-money-list__item-top-right-part {
    flex-grow: 0;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .save-money-list__item-sum-wrap {
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  .save-money-list__item-deadline-wrap {
    width: calc(50% - 10px);
    margin-right: 0;
  }
  .save-money-list__item-controls {
    border-top: solid 1px rgba(228, 232, 238, 0.5);
    width: calc(100% + 20px + 20px);
    margin: 20px -20px -18px;
  }
  .save-money-list__item-control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.3333333333%;
    margin-right: 0;
    height: 60px;
    border-right: solid 1px rgba(228, 232, 238, 0.5);
  }
  .save-money-list__item-control:last-child {
    border-right: 0;
  }
  .save-money-list__item-control-icon_copy {
    width: 18px;
    height: 21px;
  }
  .save-money-list__item-control-icon_copy path {
    fill: var(--dark-slate-blue);
  }
  .save-money-list__item-control-icon_edit {
    width: 19px;
    height: 19px;
  }
  .save-money-list__item-control-icon_edit path {
    fill: var(--dark-slate-blue);
  }
  .save-money-list__item-control-icon_basket {
    width: 30px;
    height: 30px;
  }
  .save-money-list__item-control-icon_basket path {
    fill: var(--reddish-pink);
  }
  .save-money-list__copied {
    border-radius: 0 0 8px 8px;
    align-items: center;
    justify-content: center;
    top: auto;
    height: 60px;
    padding: 10px 20px;
  }
}
@media screen and (max-width: 600px) {
  .save-money-list__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .save-money-list__title-wrap {
    width: calc(100% + 20px + 20px);
    margin: 0 -20px 40px;
  }
  .save-money-list__title-container {
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .save-money-list__title {
    font-size: 28px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .save-money-list__item-sum-wrap {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .save-money-list__item-deadline-wrap {
    width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.month-navigation {
  height: 30px;
  border-radius: 4px;
  border: 1px solid rgba(176, 181, 196, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.month-navigation__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.month-navigation__btn_invisible {
  visibility: hidden;
}
.month-navigation__btn:disabled {
  cursor: not-allowed;
}
.month-navigation__btn-icon {
  height: 10px;
}
.month-navigation__btn-icon path {
  stroke: var(--bluey-grey);
}
.month-navigation__btn:hover:not(:disabled) .month-navigation__btn-icon path {
  stroke: var(--dark-slate-blue);
}
.month-navigation__btn_prev .month-navigation__btn-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.month-navigation__date {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  white-space: nowrap;
}

@media screen and (max-width: 980px) {
  .month-navigation {
    height: 50px;
    border: solid 1px var(--bluey-grey);
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.deals {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  padding: 30px 0;
  width: 100%;
}
.deals__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
  padding-left: 30px;
  padding-right: 30px;
}
.deals__content-wrapper {
  height: 300px;
  overflow: hidden;
  padding: 0 30px;
  position: relative;
}
.deals__list {
  padding-bottom: 20px;
}
.deals__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.deals__item:hover {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item_open {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item:first-child {
  border-top: 1px solid var(--pale-grey);
}
.deals__item:first-child:hover {
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item:first-child_active {
  border-top-color: rgba(29, 46, 105, 0.2);
}
.deals__item-title-wrap {
  align-items: center;
  display: flex;
  width: calc(100% - 150px - 150px - 110px);
}
.deals__item-icon {
  margin-right: 20px;
}
.deals__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  width: calc(100% - 50px);
}
.deals__header .deals__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.deals__item:hover .deals__item-title {
  color: var(--dark-slate-blue);
}
.deals__item_active .deals__item-title {
  color: var(--dark-slate-blue);
}
.deals__item-title-service {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item-title-service:after {
  content: ",";
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item-title-service:last-child:after {
  display: none;
}
.deals__item-tax {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 12px;
  min-width: 150px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 150px;
}
.deals__header .deals__item-tax {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item:hover .deals__item-tax {
  color: var(--dark-slate-blue);
}
.deals__item_active .deals__item-tax {
  color: var(--dark-slate-blue);
}
.deals__item-date {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 12px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 150px;
}
.deals__item:hover .deals__item-date {
  color: var(--dark-slate-blue);
}
.deals__item_active .deals__item-date {
  color: var(--dark-slate-blue);
}
.deals__item-date-itself {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--turquoise-blue);
  font-size: 16px;
  min-width: 110px;
  text-align: right;
  width: auto;
}
.deals__item-sum_canceled {
  color: var(--bluey-grey);
}
.deals__header .deals__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.deals__loading {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
}
.deals__loading_full-block {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.deals__track {
  background: transparent;
  border-radius: 4px;
  height: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  right: -20px;
  top: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 4px;
}
.deals__thumb {
  background-color: var(--pale-grey);
  border-radius: 2px;
  position: absolute;
  width: 4px;
}

@media screen and (max-width: 1030px) {
  .deals {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    /* &__loading {
      position: static;
      height: 191px;
      display: flex;
      align-items: center;
      justify-content: center;
    }*/
  }
  .deals__date {
    width: 100%;
  }
  .deals__content-wrapper {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
  }
  .deals__item {
    padding-left: 8px;
    padding-right: 8px;
  }
  .deals__item-left-part {
    min-width: 70%;
    padding-right: 10px;
    width: 70%;
  }
  .deals__item-title-wrap {
    margin-bottom: 10px;
    width: auto;
  }
  .deals__item-icon {
    margin-right: 7px;
  }
  .deals__item-title {
    font-size: 16px;
  }
  .deals__item-right-part {
    min-width: 30%;
    text-align: right;
    width: 30%;
  }
  .deals__item-sum {
    font-size: 20px;
    max-width: 100%;
    min-width: 100%;
  }
  .deals__item-tax {
    margin-left: auto;
    margin-right: 0;
    min-width: 100%;
    text-align: right;
    text-transform: lowercase;
    width: 100%;
  }
  .deals__item-date {
    max-width: 100%;
    padding-left: 23px;
    text-align: left;
  }
  .deals__track {
    right: 0;
  }
}
@media screen and (max-width: 800px) {
  .deals_check-open {
    opacity: 0;
  }
}
@media screen and (max-width: 600px) {
  .deals__content-wrapper {
    height: 290px;
  }
  .deals__item {
    padding: 6px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint-annul-check__title {
  font-size: 28px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 30px;
}
.hint-annul-check__text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  margin-bottom: 30px;
  font-family: "Circe-Regular", sans-serif;
}
.hint-annul-check__options-list {
  margin-bottom: 50px;
}
.hint-annul-check__options-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.hint-annul-check__options-item:last-child {
  margin-bottom: 0;
}
.hint-annul-check__options-item-checkbox {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--dark-slate-blue);
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
}
.hint-annul-check__options-item-checkbox:after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--dark-slate-blue);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2px;
  margin-left: -2px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.hint-annul-check__options-item-checkbox[aria-checked=true]:after {
  opacity: 1;
  visibility: visible;
}
.hint-annul-check__options-item-title {
  font-size: 21px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.hint-annul-check__error-wrap {
  margin: 20px 0 0;
}
.hint-annul-check__icon-info {
  width: 16px;
  height: 16px;
  vertical-align: -2px;
  margin-right: 4px;
}
.hint-annul-check__error {
  font-size: 15px;
  line-height: 1.6;
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
}
.hint-annul-check__button-wrap {
  display: flex;
}
.hint-annul-check__button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 189px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  height: 60px;
  padding: 0 37px;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
  line-height: 60px;
  text-decoration: none;
}
.hint-annul-check__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.hint-annul-check__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}

@media screen and (max-width: 980px) {
  .hint-annul-check__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.service-card-content {
  position: relative;
  height: 100%;
  color: white;
  max-width: 70%;
}
.service-card-content_white-bg {
  color: var(--dark-slate-blue);
}
.service_big .service-card-content {
  max-width: 100%;
}
.service_special .service-card-content {
  max-width: 100%;
}
.profile-settings-sz-tab__service .service-card-content {
  max-width: 80%;
}
.service_company .service-card-content {
  max-width: 100%;
}
.service-card-content__title {
  font-size: 22px;
  color: inherit;
  font-family: "Circe-Bold", sans-serif;
  text-align: left;
  margin-bottom: 11px;
}
.service-card-content__desc {
  font-size: 16px;
  color: inherit;
  line-height: 1.5;
  text-align: left;
  font-family: "Circe-Regular", sans-serif;
  min-height: 48px;
}

@media screen and (max-width: 1194px) {
  .service-card-content {
    max-width: 75%;
  }
  .service_big .service-card-content {
    max-width: 75%;
  }
}
@media screen and (max-width: 600px) {
  .service_special .service-card-content {
    max-width: 80%;
  }
  .service-card-content__title {
    font-size: 18px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.service {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 20px 30px 23px 30px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  text-decoration: none;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.service_big {
  height: 300px;
  padding: 160px 37px 10px 30px;
}
.service_white {
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.service_white:hover:not(:disabled) {
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
}
.service_cards {
  background: linear-gradient(to right, rgb(255, 210, 84) 0%, rgb(255, 45, 85) 50%, rgb(255, 210, 84) 100%);
  background-size: 200% 100%;
}
.service_cards:hover:not(:disabled) {
  background-position: 100% 0;
}
.service_cash-back {
  background: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(40, 65, 149) 50%, rgb(0, 122, 255) 100%);
  background-size: 200% 100%;
}
.service_cash-back:hover:not(:disabled) {
  background-position: 100% 0;
}
.service_zkh {
  background: linear-gradient(to right, rgb(90, 200, 250) 0%, rgb(0, 122, 255) 50%, rgb(90, 200, 250) 100%);
  background-size: 200% 100%;
}
.service_zkh:hover:not(:disabled) {
  background-position: 100% 0;
}
.service:disabled {
  cursor: not-allowed;
  border: 1px solid #9da6ba;
  background-color: #e4e8ee;
  color: var(--bluey-grey);
  opacity: 0.2;
}
.service:disabled .service-card-content__icon {
  opacity: 0.2;
}
.profile-settings-sz-tab__service .service {
  height: 170px;
}
.service__new-label {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 26px;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 26px;
  background: rgba(255, 45, 85, 0.1);
  border-radius: 8px;
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--reddish-pink);
}
.service__icon-wrap {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 10;
}
.profile-settings-sz-tab__service .service__icon-wrap {
  right: 30px;
  top: 30px;
}
.profile-settings-sz-tab__service .service__icon {
  width: 50px;
  height: 50px;
}
.service__img-wrap {
  position: absolute;
  right: 40px;
  top: 38px;
  z-index: 10;
}
.service__img {
  position: absolute;
  right: 40px;
  top: 38px;
  z-index: 10;
}
.service__img_card {
  width: 100px;
  height: 129px;
}
.service_small .service__img_card {
  position: absolute;
  top: 14px;
  right: 16px;
}
.service__img_phone {
  width: 66px;
  height: 116px;
}
.service_small .service__img_phone {
  position: absolute;
  top: 19px;
  right: 25px;
}
.service__img_ruble {
  width: 68px;
  height: 82px;
}
.service_small .service__img_ruble {
  position: absolute;
  top: 25px;
  right: 33px;
  -webkit-transform: rotate(-19deg);
          transform: rotate(-19deg);
}
.service__img_shape1-with-ruble {
  width: 22px;
  height: 22px;
  right: 110px;
  top: 21px;
}
.service__img_shape2-with-ruble {
  width: 14px;
  height: 14px;
  top: 118px;
  right: 28px;
}
.service__line-group {
  position: absolute;
  right: -1px;
  bottom: -1px;
}

@media screen and (max-width: 1194px) {
  .service {
    height: 150px;
    padding: 30px 34px 24px 30px;
  }
  .profile-settings-sz-tab__service .service {
    padding: 20px 34px 24px 20px;
  }
  .service__img_card {
    min-width: 100px;
  }
  .service__img_phone {
    min-width: 66px;
  }
  .service__img_ruble {
    min-width: 68px;
  }
}
@media screen and (max-width: 1000px) {
  .service {
    height: auto;
    min-height: 150px;
  }
  .profile-settings-sz-tab__service .service {
    padding: 30px 34px 24px 30px;
  }
  .service__icon {
    width: 50px;
    height: 50px;
  }
  .service__img_card {
    width: 70px;
    min-width: 70px;
    height: 90px;
  }
  .service__img_phone {
    width: 46px;
    min-width: 46px;
    height: 81px;
  }
  .service__img_ruble {
    width: 47px;
    min-width: 47px;
    height: 57px;
  }
  .service__img_shape1-with-ruble {
    width: 15px;
    height: 15px;
  }
  .service__img_shape2-with-ruble {
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 600px) {
  .service {
    padding: 35px 20px 10px 18px;
  }
  .profile-settings-sz-tab__service .service {
    padding: 34px 34px 24px 18px;
    height: auto;
  }
  .service__icon-wrap {
    right: 20px;
    top: 50px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-certificate-card__card {
  width: 100%;
  padding: 20px 30px 23px 30px;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 4px;
  height: 150px;
}
.income-certificate-card__title {
  color: var(--dark-slate-blue);
  font-size: 22px;
  font-family: "Circe-Bold", sans-serif;
  text-align: left;
  margin-bottom: 36px;
}
.income-certificate-card__list {
  display: flex;
  flex-wrap: wrap;
}
.income-certificate-card__year {
  color: #9da6ba;
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 17px;
  transition: color 0.3s ease-in-out;
}
.income-certificate-card__year:last-child {
  margin-right: 0;
}
.income-certificate-card__year:hover {
  color: #284195;
}

@media screen and (max-width: 1194px) {
  .income-certificate-card__card {
    padding: 30px 34px 24px 30px;
  }
}
@media screen and (max-width: 1000px) {
  .income-certificate-card__card {
    height: auto;
    min-height: 150px;
  }
}
@media screen and (max-width: 600px) {
  .income-certificate-card__card {
    padding: 35px 20px 10px 18px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.lock-for-non-se {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
.lock-for-non-se__icon-wrapper {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: var(--white);
  margin-bottom: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.lock-for-non-se__icon {
  fill: var(--deep-sky-blue);
  width: 44px;
  height: 40px;
}
.lock-for-non-se__text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: var(--bluey-grey);
}
.lock-for-non-se__text-link {
  color: var(--grey-blue);
  text-decoration: underline;
}
.lock-for-non-se__text-link:hover {
  text-decoration: none;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.quarter-navigation {
  height: 30px;
  border-radius: 4px;
  border: 1px solid rgba(176, 181, 196, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.quarter-navigation__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quarter-navigation__btn_invisible {
  visibility: hidden;
}
.quarter-navigation__btn:disabled {
  cursor: not-allowed;
}
.quarter-navigation__btn-icon {
  height: 10px;
}
.quarter-navigation__btn-icon path {
  stroke: var(--bluey-grey);
}
.quarter-navigation__btn:hover:not(:disabled) .quarter-navigation__btn-icon path {
  stroke: var(--dark-slate-blue);
}
.quarter-navigation__btn_prev .quarter-navigation__btn-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.quarter-navigation__date {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  white-space: nowrap;
}

@media screen and (max-width: 800px) {
  .quarter-navigation {
    height: 50px;
    border: none;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 100%;
  height: 280px;
  min-height: 280px;
}
.taxes__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 36px;
  position: relative;
}
.taxes__header::after {
  background: var(--pale-grey);
  bottom: 0;
  content: "";
  height: 2px;
  left: 30px;
  position: absolute;
  right: 30px;
}
.taxes__wrap {
  bottom: 42px;
  left: 30px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: 68px;
}
.taxes__content {
  overflow-y: auto;
}
.taxes__title {
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}
.taxes__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.taxes__item-title-wrap {
  align-items: center;
  display: flex;
  flex-grow: 20;
  justify-content: space-between;
  padding-right: 60px;
}
.taxes__header .taxes__item-title-wrap {
  flex-grow: unset;
  justify-content: flex-start;
  min-width: 30%;
  padding-right: 0;
  width: 30%;
}
.taxes__item-title-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.taxes__item-icon {
  margin-right: 20px;
}
.taxes__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.taxes__header .taxes__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.taxes__item:hover .taxes__item-title {
  color: var(--dark-slate-blue);
}
.taxes__item_active .taxes__item-title {
  color: var(--dark-slate-blue);
}
.taxes__item-status {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border-radius: 10px;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  min-width: 100px;
  text-align: center;
  width: 100px;
}
.taxes__item-status_not-payed {
  background: rgba(255, 45, 85, 0.1);
  color: var(--reddish-pink);
}
.taxes__item-status_payed {
  background: rgba(0, 173, 181, 0.1);
  color: var(--turquoise-blue);
}
.taxes__item-date {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 12px;
  max-width: 150px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.taxes__header .taxes__item-date {
  align-items: center;
  border: 1px solid rgba(176, 181, 196, 0.1);
  border-radius: 4px;
  display: flex;
  height: 30px;
  justify-content: space-between;
  min-width: 180px;
  width: 180px;
}
.taxes__item:hover .taxes__item-date {
  color: var(--dark-slate-blue);
}
.taxes__item_active .taxes__item-date {
  color: var(--dark-slate-blue);
}
.taxes__item-date-itself {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
}
.taxes__item-date-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}
.taxes__item-date-btn-icon {
  height: 10px;
}
.taxes__item-date-btn-icon path {
  stroke: var(--bluey-grey);
}
.taxes__item-date-btn_prev .taxes__item-date-btn-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.taxes__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 16px;
  min-width: 90px;
  text-align: right;
  width: auto;
}
.taxes__header .taxes__item-sum {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 14px;
  min-width: 90px;
  width: auto;
}
.taxes__message {
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 16px;
  line-height: 1.5;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 33px;
}
.taxes__message-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 16px;
  text-decoration: underline;
}
.taxes__message-link:hover {
  text-decoration: none;
}
.taxes__year-nav {
  height: 30px;
  align-items: center;
  border: solid 1px var(--bluey-grey);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}
.taxes__year-nav-itself {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--grey-blue);
  font-size: 18px;
}
.taxes__year-nav-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}
.taxes__year-nav-btn-icon {
  height: 10px;
}
.taxes__year-nav-btn-icon path {
  stroke: var(--bluey-grey);
}
.taxes__item-date-btn_prev .taxes__year-nav-btn-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.taxes__track {
  background: transparent;
  border-radius: 4px;
  height: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 4px;
}
.taxes__thumb {
  background-color: var(--pale-grey);
  border-radius: 2px;
  position: absolute;
  width: 4px;
}
.taxes__item-left {
  min-width: 50%;
  width: 50%;
}
.taxes__item-right {
  min-width: 50%;
  width: 50%;
}
.taxes__loading {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 800px) {
  .taxes {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    width: 100%;
  }
  .taxes__list {
    padding: 0 10px;
  }
  .taxes__item {
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .taxes__item-title {
    margin-bottom: 10px;
    text-align: left;
  }
  .taxes__item-status {
    background: transparent;
    height: auto;
    line-height: normal;
    text-align: right;
    width: auto;
  }
  .taxes__item-date {
    text-align: left;
  }
  .taxes__item-sum {
    margin-bottom: 10px;
    text-align: right;
  }
  .taxes__message {
    line-height: 1.5;
    color: var(--bluey-grey);
    text-align: center;
    font-size: 16px;
    font-family: "Circe-Regular", sans-serif;
    max-width: 54%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 33px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.fns-error-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.fns-error-message__text {
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}
.fns-error-message__text-link {
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 16px;
  line-height: 1.5;
  margin-left: 5px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes-progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 16px;
  background: rgba(0, 173, 181, 0.2);
}
.taxes-progress-bar_limit {
  background: rgba(255, 45, 85, 0.2);
}
.taxes-progress-bar__itself {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  background: var(--turquoise-blue);
  max-width: 100%;
}
.taxes-progress-bar_limit .taxes-progress-bar__itself {
  background: var(--reddish-pink);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes-income-card {
  width: 100%;
  padding: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 280px;
}
.taxes-income-card__title {
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 16px;
}
.taxes-income-card__month-income {
  font-size: 40px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.taxes-income-card__list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.taxes-income-card__item {
  width: 50%;
  min-width: 50%;
}
.taxes-income-card__item:nth-child(2n) {
  text-align: right;
}
.taxes-income-card__item-title {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.taxes-income-card__item-sum {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.taxes-income-card__item_limit .taxes-income-card__item-sum {
  color: var(--turquoise-blue);
}
.taxes-income-card__item_limit-is-near .taxes-income-card__item-sum {
  color: var(--reddish-pink);
}
.taxes-income-card__item:nth-child(2) .taxes-income-card__item-sum {
  display: flex;
  justify-content: flex-end;
}
.taxes-income-card__limit-wrap {
  display: flex;
  flex-wrap: wrap;
}
.taxes-income-card__limit-title {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 2px;
}
.taxes-income-card__limit-sum {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
}
.taxes-income-card__error-message {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--reddish-pink);
}

@media screen and (max-width: 340px) {
  .taxes-income-card {
    padding: 30px 15px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes-to-pay-card-brief {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.taxes-to-pay-card-brief:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-card-brief__text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 14px;
  margin-right: 5px;
}
.taxes-to-pay-card-brief__sum {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes-to-pay-tooltip {
  position: absolute;
  top: 27px;
  left: 50%;
  margin-left: -132px;
  width: 265px;
  min-height: 220px;
  border-radius: 8px;
  background-color: var(--pale-grey);
  padding: 15px 14px 28px 25px;
  opacity: 0;
  visibility: hidden;
}
.taxes-to-pay-tooltip:before {
  border-bottom: 6px solid var(--pale-grey);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  top: -5px;
  width: 0;
}
.taxes-to-pay-tooltip_dark {
  background-color: #616a88;
  width: 280px;
  margin-left: -146px;
  top: 32px;
}
.taxes-to-pay-tooltip_dark:before {
  border-bottom: 6px solid #616a88;
}
.taxes-to-pay-tooltip_open {
  opacity: 1;
  visibility: visible;
}
.taxes-to-pay-tooltip__text-title {
  font-size: 10px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 16px;
}
.taxes-to-pay-tooltip_dark .taxes-to-pay-tooltip__text-title {
  margin-bottom: 19px;
  color: white;
  font-size: 14px;
}
.taxes-to-pay-tooltip__text {
  font-size: 10px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 16px;
}
.taxes-to-pay-tooltip__text:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-tooltip__text-wrap .taxes-to-pay-tooltip__text {
  margin-bottom: 8px;
}
.taxes-to-pay-tooltip__text-wrap .taxes-to-pay-tooltip__text:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-tooltip_dark .taxes-to-pay-tooltip__text {
  color: white;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
}

@media screen and (max-width: 1194px) {
  .taxes-to-pay-tooltip {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    margin-left: 0;
    height: auto;
    border-radius: 8px 8px 0 0;
    padding: 30px 20px 20px 20px;
  }
  .taxes-to-pay-tooltip_dark {
    border-radius: 8px;
  }
  .taxes-to-pay-tooltip_dark:before {
    display: none;
  }
  .taxes-to-pay-tooltip__text {
    font-size: 14px;
    line-height: 1.71;
  }
  .taxes-to-pay-tooltip__text-wrap .taxes-to-pay-tooltip__text {
    margin-bottom: 2px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes-to-pay-card {
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  height: 450px;
  padding: 30px 20px 26px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}
.taxes-to-pay-card__title {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 24px;
}
.taxes-to-pay-card__sum {
  margin-bottom: 10px;
  font-size: 40px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.taxes-to-pay-card__actions-wrap {
  margin-bottom: 8px;
}
.taxes-to-pay-card__actions-link {
  margin-bottom: 8px;
}
.taxes-to-pay-card__actions-link:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-card__message {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.taxes-to-pay-card__message_early-to-pay {
  color: var(--turquoise-blue);
}
.taxes-to-pay-card__message_time-to-pay {
  color: var(--reddish-pink);
}
.taxes-to-pay-card__message_time-to-pay:hover {
  text-decoration: none;
}
.taxes-to-pay-card__get-receipt {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
}
.taxes-to-pay-card__get-receipt:hover {
  color: var(--grey-blue);
  text-decoration: none;
}
.taxes-to-pay-card__bonus {
  margin-bottom: 30px;
}
.taxes-to-pay-card__bonus-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.taxes-to-pay-card__item {
  width: 50%;
  min-width: 50%;
}
.taxes-to-pay-card__item:nth-child(2n) {
  text-align: right;
}
.taxes-to-pay-card__item-title {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.taxes-to-pay-card__item-sum {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.taxes-to-pay-card__brief {
  border-top: 1px solid rgba(228, 232, 238, 0.5);
  border-bottom: 1px solid rgba(228, 232, 238, 0.5);
  padding: 23px 0;
}
.taxes-to-pay-card__button-wrap {
  display: flex;
}
.taxes-to-pay-card__button {
  width: 100%;
}
.taxes-to-pay-card__error-message {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--reddish-pink);
  font-size: 14px;
}
.taxes-to-pay-card__tooltip-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.taxes-to-pay-card__tooltip-btn-icon {
  height: 16px;
  width: 16px;
}
.taxes-to-pay-card__tooltip-btn-icon path {
  fill: var(--grey-blue);
}
.taxes-to-pay-card__tooltip-btn-icon g {
  fill: var(--grey-blue);
}
.taxes-to-pay-card__tooltip-wrap {
  position: absolute;
  top: 28px;
  right: 20px;
}

@media screen and (max-width: 1194px) {
  .taxes-to-pay-card {
    height: 420px;
  }
  .taxes-to-pay-card__tooltip-wrap {
    right: 20px;
  }
  .taxes-to-pay-card__tooltip-wrap_tooltip-open {
    z-index: 1;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.accounting {
  padding: 54px 50px 50px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.accounting__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 20px;
}
.accounting__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.accounting__nav-wrap {
  position: relative;
  margin-bottom: 41px;
}
.accounting__nav {
  display: flex;
}
.accounting__nav-item {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  margin-right: 40px;
}
.accounting__nav-item:last-child {
  margin-right: 0;
}
.accounting__nav-item_active {
  color: var(--dark-slate-blue);
}
.accounting__nav-bar {
  position: absolute;
  bottom: -6px;
  left: 0;
}
.accounting__nav-bar-active {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  transition: all 0.3s ease;
  border-bottom: 2px dotted var(--dark-slate-blue);
}
.accounting__content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  min-height: 610px;
}
.accounting__content-tab {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  z-index: 10;
}
.accounting__content-tab_active {
  z-index: 20;
}
.accounting__content-tab-list {
  width: calc((100% - 90px) / 3 * 2 + 60px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.accounting__item {
  width: calc((100% - 90px) / 3);
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 20px;
  text-decoration: none;
}
.accounting__item.service_small {
  padding: 17px 44px 23px 23px;
}
.accounting__item_taxes-to-pay-card {
  z-index: 1;
}
.accounting__content-tab-list .accounting__item {
  width: calc((100% - 60px) / 2);
}
.accounting__deals-wrap {
  width: 100%;
  display: flex;
}
.accounting__deals {
  height: 399px;
  width: 64.4%;
  margin-right: 30px;
  margin-left: 15px;
}
.accounting__check-wrap {
  width: 291px;
}
.accounting__check {
  margin-bottom: 18px;
}
.accounting__annul-check-button-wrap {
  display: flex;
  justify-content: center;
}
.accounting__annul-check-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-slate-blue);
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  padding: 0 0 6px 0;
  text-decoration: none;
}
.accounting__annul-check-button:hover {
  border-bottom-color: transparent;
}

@media screen and (max-width: 1194px) {
  .accounting__content {
    min-height: 1300px;
  }
  .accounting__content_deals {
    min-height: 540px;
  }
  .accounting__content-tab-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 900px;
  }
  .accounting__item {
    width: calc((100% - 60px) / 2);
  }
  .accounting__item_taxes {
    width: calc(100% - 30px);
  }
  .accounting__item:first-child {
    width: 100%;
  }
  .accounting__content-tab-list .accounting__item_income-card {
    width: 100%;
  }
  .accounting__deals {
    width: calc(100% - 291px - 45px);
  }
}
@media screen and (max-width: 1000px) {
  .accounting__content {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    min-height: 1400px;
  }
  .accounting__content_deals {
    min-height: 540px;
  }
  .accounting__item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .accounting__content-tab-list .accounting__item {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .accounting__top {
    border-bottom: 0;
  }
  .accounting__title {
    font-size: 28px;
  }
  .accounting__item_taxes {
    height: auto;
  }
  .accounting__item.service_small {
    padding: 17px 20px 23px 23px;
  }
  .accounting__item .service__icon {
    width: 50px;
    height: 70px;
  }
  .accounting__deals {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .accounting__check-wrap {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 600px) {
  .accounting {
    padding-top: 104px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .accounting__nav-wrap {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 20px;
  }
  .accounting__nav-item {
    width: 50%;
    margin-right: 0;
  }
  .accounting__nav-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--bluey-grey);
  }
  .accounting__nav-bar-active {
    position: absolute;
    bottom: -1px;
    top: auto;
    left: 0;
    height: 3px;
    transition: all 0.3s ease;
    border-bottom: 3px solid var(--dark-slate-blue);
  }
}
@media screen and (max-width: 350px) {
  .accounting {
    padding-left: 14px;
    padding-right: 14px;
  }
  .accounting__nav-item {
    font-size: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.bank-card {
  width: 100%;
  padding: 63px 31px 60px 31px;
  border-radius: 12px;
  box-shadow: 0 2px 24px 0 rgba(156, 171, 222, 0.39);
  position: relative;
}
.bank-card__logo {
  height: 21px;
  position: absolute;
  left: 32px;
  top: 19px;
}
.bank-card__number {
  width: 100%;
  margin-bottom: 10px;
}
.bank-card__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bank-card__expire {
  display: flex;
  align-items: center;
  margin-right: 8px;
  position: relative;
}
.bank-card__expire-text {
  font-size: 18px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 9px;
}
.bank-card__cvv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bank-card__cvv-text {
  font-size: 12px;
  color: var(--grey-blue);
  text-align: right;
  line-height: 1;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 7px;
}
.bank-card__number .bank-card__input-holder {
  width: 100%;
}
.bank-card__expire .bank-card__input-holder {
  width: 70px;
  min-width: 70px;
  margin-right: 9px;
}
.bank-card__expire .bank-card__input-holder:last-child {
  margin-right: 0;
}
.bank-card__cvv .bank-card__input-holder {
  width: 70px;
  min-width: 70px;
}
.bank-card__input {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: white;
  -webkit-appearance: none;
  box-shadow: none;
  text-align: center;
  border: 1px solid white;
  line-height: 50px;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.bank-card__input::-webkit-input-placeholder {
  opacity: 1;
  color: #9da6ba;
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
}
.bank-card__input::-moz-placeholder {
  opacity: 1;
  color: #9da6ba;
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
}
.bank-card__input:-moz-placeholder {
  opacity: 1;
  color: #9da6ba;
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
}
.bank-card__input:-ms-input-placeholder {
  opacity: 1;
  color: #9da6ba;
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
}
.bank-card__input::-ms-input-placeholder {
  opacity: 1;
  color: #9da6ba;
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
}
.bank-card__input::-ms-clear {
  display: none;
}
.bank-card__input[type=number]::-webkit-outer-spin-button, .bank-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bank-card__input[type=number] {
  -moz-appearance: textfield;
}
.bank-card__input-holder_error .bank-card__input {
  border-color: var(--reddish-pink);
}
.bank-card__cvv .bank-card__input {
  font-family: "password", sans-serif;
}
.bank-card__brand-name {
  position: absolute;
  right: 25px;
  bottom: 20px;
  height: 25px;
  max-width: 40px;
  max-height: 25px;
}
.bank-card__error-message {
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  position: absolute;
  left: 0;
  top: calc(100% + 15px);
}

@media screen and (max-width: 500px) {
  .bank-card {
    padding: 60px 10px 53px 10px;
  }
}
@media screen and (max-width: 360px) {
  .bank-card__expire .bank-card__input-holder {
    width: 55px;
    min-width: 55px;
  }
  .bank-card__cvv .bank-card__input-holder {
    width: 55px;
    min-width: 55px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.add-new-card {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.add-new-card__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.add-new-card__header {
  margin-bottom: 50px;
}
.add-new-card__header-logo-wrap {
  text-decoration: none;
  width: 38px;
  height: 33px;
  max-width: 38px;
  max-height: 33px;
}
.add-new-card__header-logo {
  display: none;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.add-new-card__title-wrap {
  margin-bottom: 39px;
}
.add-new-card__title {
  font-size: 36px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 10px;
}
.add-new-card__subtitle {
  font-size: 18px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
  padding-left: 50px;
  padding-right: 50px;
}
.add-new-card__content-inner {
  max-width: 400px;
}
.add-new-card__card {
  margin-bottom: 20px;
}
.add-new-card__input {
  margin-bottom: 20px;
}
.add-new-card__default-block {
  margin-bottom: 30px;
  display: flex;
}
.add-new-card__checkbox-wrap {
  display: flex;
  align-items: center;
}
.add-new-card__checkbox-itself {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 54px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #e4e8ee;
  background: var(--pale-grey);
  margin-right: 12px;
  position: relative;
  transition: background, border-color 0.3s ease-in-out;
}
.add-new-card__checkbox-itself:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 50%;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--bluey-grey);
  transition: background, left 0.3s ease-in-out;
}
.add-new-card__checkbox-itself[aria-checked=true] {
  border-color: rgba(29, 46, 105, 0.5);
  background: transparent;
}
.add-new-card__checkbox-itself[aria-checked=true]:after {
  background: rgba(29, 46, 105, 0.5);
  left: 33px;
}
.add-new-card__checkbox-title {
  font-size: 18px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.add-new-card__buttons {
  display: flex;
  width: 100%;
}
.add-new-card__buttons-item {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.add-new-card__buttons-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 980px) {
  .add-new-card__container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .add-new-card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .add-new-card__close {
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .add-new-card__title {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 20px;
  }
  .add-new-card__content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .add-new-card__title {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 28px;
  }
  .add-new-card__subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }
  .add-new-card__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .add-new-card__buttons {
    flex-direction: column-reverse;
  }
  .add-new-card__buttons-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .add-new-card__buttons-item:first-child {
    margin-bottom: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.bill-creation-card {
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  background-color: white;
  min-height: 390px;
  display: flex;
  flex-direction: column;
}
.bill-creation-card__header {
  padding: 21px 30px;
  min-height: 118px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  border-radius: 14px 14px 0 0;
  overflow: hidden;
}
.bill-creation-card__header-icon-wrap {
  width: 60px;
  height: 60px;
  min-width: 60px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}
.bill-creation-card__header-icon {
  width: 29px;
  height: 26px;
  fill: var(--deep-sky-blue);
}
.bill-creation-card__header-text {
  font-size: 15px;
  line-height: 1.6;
  font-family: "Circe-Regular", sans-serif;
  color: white;
  width: 50%;
}
.bill-creation-card__header-text-link {
  font-size: 15px;
  line-height: 1.6;
  font-family: "Circe-Regular", sans-serif;
  color: white;
  margin-left: 3px;
}
.bill-creation-card__header-text-link:hover {
  text-decoration: none;
}
.bill-creation-card__content {
  padding: 30px 20px 40px 20px;
  display: flex;
  flex-grow: 20;
}
.bill-creation-card__content_error {
  flex-direction: column;
  padding-top: 0;
}
.bill-creation-card__left-part {
  width: 58%;
  min-width: 58%;
  margin-right: 20px;
  flex-grow: 20;
}
.bill-creation-card__input-holder {
  margin-bottom: 10px;
  position: relative;
}
.bill-creation-card__input-holder:last-child {
  margin-bottom: 0;
}
.bill-creation-card__input {
  height: 60px;
  border-radius: 8px;
  background-color: rgba(228, 232, 238, 0.2);
  width: 100%;
  border: 1px solid rgba(228, 232, 238, 0.2);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 10px 10px 0 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.bill-creation-card__input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.bill-creation-card__input:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.bill-creation-card__input[type=number]::-webkit-outer-spin-button, .bill-creation-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bill-creation-card__input[type=number] {
  -moz-appearance: textfield;
}
.bill-creation-card__input_service-name {
  padding: 10px 40px 0 20px;
}
.bill-creation-card__input-label {
  font-size: 8px;
  text-transform: uppercase;
  color: #6b778d;
  position: absolute;
  left: 20px;
  top: 7px;
  font-family: "Circe-Bold", sans-serif;
}
.bill-creation-card__input-check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.bill-creation-card__input-check path {
  stroke: var(--dark-slate-blue);
}
.bill-creation-card__right-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 20;
}
.bill-creation-card__tabs {
  display: flex;
  margin-bottom: 10px;
  height: 36px;
}
.bill-creation-card__tabs-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 36px;
  width: 119px;
  border: 1px solid var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.bill-creation-card__tabs-item:first-child {
  border-radius: 18px 0 0 18px;
  border-right: none;
}
.bill-creation-card__tabs-item:last-child {
  border-radius: 0 18px 18px 0;
  border-left: none;
}
.bill-creation-card__tabs-item_active {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  color: white;
  border: transparent;
}
.bill-creation-card__tip {
  font-size: 14px;
  line-height: 1.71;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.bill-creation-card__main .bill-creation-card__tip {
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 20px;
}
.bill-creation-card__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  font-family: "Circe-Bold", sans-serif;
}
.bill-creation-card__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.bill-creation-card__info {
  width: 53%;
  min-width: 53%;
}
.bill-creation-card__info-block {
  margin-bottom: 15px;
}
.bill-creation-card__info-block:last-child {
  margin-bottom: 0;
}
.bill-creation-card__info-block-label {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.bill-creation-card__info-block-value {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  word-break: break-all;
}
.bill-creation-card__main {
  width: 47%;
  min-width: 47%;
  text-align: right;
}
.bill-creation-card__link-block {
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
}
.bill-creation-card__link-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  width: 100%;
  max-width: 100%;
}
.bill-creation-card__link-icon {
  margin-right: 10px;
  width: 12px;
  min-width: 12px;
  height: 14px;
}
.bill-creation-card__link-icon path {
  transition: all 0.3s ease;
}
.bill-creation-card__link-wrap:hover .bill-creation-card__link-icon path {
  fill: var(--deep-sky-blue);
}
.bill-creation-card__link {
  font-size: 14px;
  color: var(--dark-slate-blue);
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  transition: all 0.3s ease;
  max-width: calc(100% - 12px - 10px);
  word-break: break-all;
  text-align: left;
}
.bill-creation-card__link-wrap:hover .bill-creation-card__link {
  color: var(--deep-sky-blue);
}
.bill-creation-card__link-share-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bill-creation-card__link-share-block_with-copy {
  justify-content: space-between;
}
.bill-creation-card__copy-success {
  width: 100px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  background: rgba(0, 173, 181, 0.1);
  border-radius: 10px;
  font-size: 12px;
  color: var(--turquoise-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.bill-creation-card__link-share-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bill-creation-card__link-share-item {
  margin-right: 10px;
  padding: 0;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  outline: none;
}
.bill-creation-card__link-share-item-icon {
  width: 20px;
  height: 15px;
}
.bill-creation-card__link-share-item-icon_envelope {
  height: 14px;
}
.bill-creation-card__link-share-item-icon_envelope g {
  transition: all 0.3s ease;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_envelope g {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_share {
  width: 15px;
  height: 14px;
}
.bill-creation-card__link-share-item-icon_share path {
  transition: all 0.3s ease;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_share path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_vk path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_vk path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_wa path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_wa path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_ok path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_ok path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_fb path {
  transition: all 0.3s ease;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_fb path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__link-share-item-icon_tg path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.bill-creation-card__link-share-item:hover .bill-creation-card__link-share-item-icon_tg path {
  fill: var(--dark-slate-blue);
}
.bill-creation-card__requests-history-link {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.bill-creation-card__requests-history-link:hover {
  border-bottom-color: transparent;
}
.bill-creation-card__error-icon-wrap {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -45px auto 30px auto;
  position: relative;
}
.bill-creation-card__error-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -6px;
}
.bill-creation-card__error-icon path {
  fill: var(--reddish-pink);
}
.bill-creation-card__error-message {
  font-size: 12px;
  color: var(--bluey-grey);
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 53px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.bill-creation-card__error-message-link {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.bill-creation-card__error-message-link:hover {
  text-decoration: none;
}
.bill-creation-card__error-link-wrap {
  display: flex;
  justify-content: center;
}
.bill-creation-card__error-link {
  width: 240px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.bill-creation-card__error-link:hover {
  background: var(--dusk-blue);
}

@media screen and (max-width: 980px) {
  .bill-creation-card {
    border-radius: 0;
    box-shadow: none;
    min-height: auto;
    background: transparent;
    overflow: visible;
  }
  .bill-creation-card__content {
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
  }
  .bill-creation-card__content_error {
    flex-direction: column;
    padding-top: 0;
    margin-top: -45px;
  }
  .bill-creation-card__left-part {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .bill-creation-card__input-holder {
    margin-bottom: 20px;
  }
  .bill-creation-card__right-part {
    justify-content: flex-start;
    margin-bottom: 36px;
  }
  .bill-creation-card__tabs {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 36px;
  }
  .bill-creation-card__tip {
    text-align: center;
  }
  .bill-creation-card__main .bill-creation-card__tip {
    width: 100%;
  }
  .bill-creation-card__info .bill-creation-card__tip {
    margin-bottom: 20px;
    text-align: left;
  }
  .bill-creation-card__button {
    border-radius: 30px;
    box-shadow: 0 4px 27px 0 #e8ecf2;
    font-size: 16px;
    font-family: "Circe-Bold", sans-serif;
  }
  .bill-creation-card__info {
    width: 100%;
    min-width: 100%;
  }
  .bill-creation-card__info-block {
    margin-bottom: 15px;
    display: flex;
  }
  .bill-creation-card__info-block:last-child {
    margin-bottom: 0;
  }
  .bill-creation-card__info-block-label {
    margin-bottom: 0;
    margin-right: 5px;
  }
  .bill-creation-card__info-block-label:after {
    content: ":";
    font-size: 16px;
    color: var(--bluey-grey);
    font-family: "Rubik", sans-serif;
    font-weight: 400;
  }
  .bill-creation-card__main {
    width: 100%;
    min-width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .bill-creation-card__link-block {
    margin-bottom: 0;
  }
  .bill-creation-card__link-wrap {
    justify-content: center;
    width: 100%;
  }
  .bill-creation-card__link-icon {
    min-width: 12px;
  }
  .bill-creation-card__link-icon path {
    fill: var(--bluey-grey);
  }
  .bill-creation-card__link {
    color: var(--bluey-grey);
    word-wrap: break-word;
  }
  .bill-creation-card__link-share-block {
    justify-content: center;
    flex-direction: column;
  }
  .bill-creation-card__link-share-block_with-copy {
    justify-content: center;
  }
  .bill-creation-card__copy-success {
    margin-bottom: 10px;
  }
  .bill-creation-card__link-share-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .bill-creation-card__link-share-item {
    margin-right: 10px;
    padding: 0;
    border: none;
    cursor: pointer;
    box-shadow: none;
    background: transparent;
  }
  .bill-creation-card__link-share-item-icon_envelope g {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_share path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_vk path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_wa path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_ok path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_fb path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__link-share-item-icon_tg path {
    fill: var(--grey-blue);
  }
  .bill-creation-card__requests-history-link-wrap {
    display: flex;
    justify-content: center;
  }
  .bill-creation-card__error-message {
    -webkit-filter: drop-shadow(0 4px 27px #e8ecf2);
            filter: drop-shadow(0 4px 27px #e8ecf2);
    background: white;
    padding: 20px 30px;
    border-radius: 15px;
    position: relative;
    text-align: left;
    line-height: 1.2;
    font-size: 16px;
    color: var(--dark-slate-blue);
  }
  .bill-creation-card__error-message-triangle {
    width: 29px;
    height: 23px;
    position: absolute;
    bottom: -23px;
    left: 22px;
  }
  .bill-creation-card__error-message-link {
    font-size: 16px;
  }
  .bill-creation-card__error-icon-wrap {
    margin-top: 0;
  }
  .bill-creation-card__error-link {
    border-radius: 30px;
    width: 100%;
    min-width: 240px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.bill-creation {
  max-width: 1300px;
  padding: 50px 50px 130px 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.bill-creation__top {
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 39px;
}
.bill-creation__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.bill-creation__content {
  display: flex;
}
.bill-creation__card-wrap {
  width: 650px;
  min-width: 650px;
  margin-right: 60px;
  position: relative;
  z-index: 20;
}
.bill-creation__card-link-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.bill-creation__card-link {
  width: 252px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.bill-creation__card-link:hover {
  background: var(--dusk-blue);
}
.bill-creation__useful-links {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 1200px) {
  .bill-creation__content {
    display: block;
  }
  .bill-creation__card-wrap {
    margin-right: 0;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 980px) {
  .bill-creation {
    padding: 0 0 100px 0;
    margin-top: -90px;
  }
  .bill-creation__header {
    background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
    min-height: 220px;
    margin-bottom: 36px;
    position: relative;
    overflow: hidden;
    padding: 90px 50px 35px 50px;
  }
  .bill-creation__header_fail {
    margin-bottom: 0;
  }
  .bill-creation__header-icon-wrap {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .bill-creation__header-icon {
    width: 29px;
    height: 26px;
    fill: var(--deep-sky-blue);
  }
  .bill-creation__header-bg {
    position: absolute;
    right: -80px;
    bottom: -30px;
    width: 260px;
    height: 320px;
    object-fit: contain;
    z-index: 10;
  }
  .bill-creation__header-title {
    font-size: 28px;
    line-height: 1.29;
    font-family: "Circe-Bold", sans-serif;
    color: white;
    position: relative;
    z-index: 20;
  }
  .bill-creation__content {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    position: relative;
    z-index: 20;
  }
  .bill-creation__card-link-wrap {
    display: flex;
    justify-content: center;
  }
  .bill-creation__card-link {
    border-radius: 30px;
    max-width: 375px;
    width: 100%;
  }
  .bill-creation__card-wrap {
    width: 100%;
    min-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .bill-creation {
    margin-top: -70px;
  }
  .bill-creation__header {
    padding: 70px 50px 35px 50px;
  }
}
@media screen and (max-width: 600px) {
  .bill-creation__header {
    padding: 70px 20px 35px 20px;
  }
}
@media screen and (max-width: 350px) {
  .bill-creation__header {
    padding: 70px 14px 35px 14px;
  }
  .bill-creation__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-item-sum__label {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.show-case-item-sum__sum {
  font-size: 18px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.dashed-create-new-item-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 80px;
  border-radius: 8px;
  border: 1px dashed var(--bluey-grey);
  width: 100%;
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  transition: all 0.3s ease;
}
.dashed-create-new-item-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.dashed-create-new-item-button:hover:not(:disabled) {
  color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
}

@media screen and (max-width: 600px) {
  .dashed-create-new-item-button {
    height: 120px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.cash-box {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cash-box__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.cash-box__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cash-box__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.cash-box__title-wrap {
  margin-bottom: 40px;
}
.cash-box__title-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.cash-box__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.cash-box__showcase-count-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5px;
}
.cash-box__showcase-count-text {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 5px;
}
.cash-box__showcase-count {
  font-size: 16px;
  color: #5f6a8b;
  font-family: "Circe-Regular", sans-serif;
}
.cash-box__content {
  display: flex;
}
.cash-box__loader {
  display: flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
}
.cash-box__main {
  width: 65.83%;
  min-width: 65.83%;
  margin-right: 30px;
}
.cash-box__list {
  padding-top: 30px;
  border-top: 1px solid rgba(228, 232, 238, 0.5);
}
.cash-box__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #ffffff;
  padding: 23px 20px 18px 20px;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease;
}
.cash-box__item:last-child {
  margin-bottom: 0;
}
.cash-box__item:hover {
  box-shadow: 0 4px 27px 0 #c8cace;
}
.cash-box__item:disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}
.cash-box__item-top {
  display: flex;
  align-items: flex-start;
}
.cash-box__item_open .cash-box__item-top {
  padding-bottom: 18px;
}
.cash-box__item-label {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
}
.cash-box__item-title-wrap {
  max-width: 320px;
  min-width: 320px;
  width: 100%;
  margin-right: 80px;
  text-align: left;
}
.cash-box__item-title {
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  word-break: break-all;
  text-decoration: none;
}
.cash-box__item-top-right-part {
  width: calc(100% - 320px - 80px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cash-box__item-sum-wrap {
  text-align: left;
}
.cash-box__item-sum {
  font-size: 18px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.cash-box__item-controls {
  display: flex;
  align-items: center;
}
.cash-box__item-control {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.cash-box__item-control:last-child {
  margin-right: 0;
}
.cash-box__item-control-icon {
  pointer-events: none;
}
.cash-box__item-control-icon_copy path {
  fill: #b0b5c4;
  transition: all 0.3s ease;
}
.cash-box__item-control:hover .cash-box__item-control-icon_copy path {
  fill: var(--dark-slate-blue);
}
.cash-box__item-control-icon_edit {
  width: 14px;
  height: 15px;
}
.cash-box__item-control-icon_edit path {
  transition: all 0.3s ease;
}
.cash-box__item-control:hover .cash-box__item-control-icon_edit path {
  fill: var(--dark-slate-blue);
}
.cash-box__item-control-icon_basket path {
  transition: all 0.3s ease;
}
.cash-box__item-control:hover .cash-box__item-control-icon_basket path {
  fill: var(--reddish-pink);
}
.cash-box__item-bottom {
  display: none;
}
.cash-box__item_open .cash-box__item-bottom {
  display: flex;
  padding-top: 23px;
  border-top: 1px solid rgba(228, 232, 238, 0.5);
}
.cash-box__item-details {
  max-width: 320px;
  min-width: 320px;
  margin-right: 80px;
  width: 100%;
}
.cash-box__item-details-line {
  display: flex;
  flex-wrap: wrap;
}
.cash-box__item-details-line-key {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.67;
  margin-right: 5px;
}
.cash-box__item-details-line-desc {
  font-size: 12px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.67;
  margin-top: 1px;
}
.cash-box__item-total-income-wrap {
  width: calc(100% - 320px - 80px);
  text-align: left;
}
.cash-box__item-total-income {
  font-size: 36px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.cash-box__no-showcase .no-operations-made__icon {
  width: 42px;
  height: 42px;
}
.cash-box__no-showcase-message-container {
  position: relative;
}
.cash-box__no-showcase-line {
  position: absolute;
  width: 41px;
  height: 131px;
  top: 20px;
  left: 19%;
}
.cash-box__create-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 80px;
  border-radius: 8px;
  border: 1px dashed var(--bluey-grey);
  width: 100%;
  font-size: 14px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  transition: all 0.3s ease;
  margin-bottom: 30px;
}
.cash-box__create-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.cash-box__create-button:hover:not(:disabled) {
  color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
}
.cash-box__no-showcase-message-container {
  padding-top: 80px;
  padding-bottom: 108px;
}
.cash-box__no-showcase-message-container .no-operations-made__text {
  color: var(--dark-slate-blue);
  max-width: 408px;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 1194px) {
  .cash-box__content {
    display: block;
    padding-bottom: 67px;
  }
  .cash-box__main {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
  }
  .cash-box__no-showcase-message-container {
    padding-bottom: 0;
  }
  .cash-box__hints {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .cash-box__container {
    padding-top: 20px;
  }
  .cash-box__header {
    margin-bottom: 40px;
  }
  .cash-box__item_open {
    padding-bottom: 0;
  }
  .cash-box__item-bottom {
    position: relative;
  }
  .cash-box__item_open .cash-box__item-bottom {
    display: block;
    border-top: none;
  }
  .cash-box__item_open .cash-box__item-bottom:before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    height: 1px;
    background: rgba(228, 232, 238, 0.5);
  }
  .cash-box__item-sum-wrap {
    margin-bottom: 30px;
  }
  .cash-box__item-controls-wrap {
    position: relative;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    border-top: 1px solid rgba(228, 232, 238, 0.5);
  }
  .cash-box__item-control {
    width: 33.33%;
    height: 60px;
    margin-right: 0;
    border-right: 1px solid rgba(228, 232, 238, 0.5);
  }
  .cash-box__item-control:last-child {
    border-right: none;
  }
  .cash-box__copied {
    border-radius: 0 0 8px 8px;
    justify-content: center;
  }
  .cash-box__item-details {
    margin-bottom: 30px;
  }
  .cash-box__item-total-income-wrap {
    margin-bottom: 30px;
    width: 100%;
  }
  .cash-box__item-control-icon_copy path {
    fill: var(--dark-slate-blue);
  }
  .cash-box__item-control-icon_edit {
    width: 14px;
    height: 15px;
  }
  .cash-box__item-control-icon_edit path {
    fill: var(--dark-slate-blue);
  }
  .cash-box__item-control-icon_basket path {
    fill: var(--reddish-pink);
  }
}
@media screen and (max-width: 600px) {
  .cash-box__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cash-box__title-wrap {
    margin-bottom: 30px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
  .cash-box__title-container {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cash-box__title {
    margin-bottom: 25px;
    font-size: 28px;
  }
  .cash-box__item-title-wrap {
    max-width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .cash-box__item-details {
    max-width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .cash-box__showcase-count-wrap {
    justify-content: flex-start;
    padding-bottom: 0;
  }
  .cash-box__create-button {
    height: 120px;
  }
  .cash-box__no-showcase-line {
    left: 0;
    -webkit-transform: rotate(16deg);
            transform: rotate(16deg);
    width: 33px;
    height: 111px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.checkbox-with-label {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-with-label__checkbox {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 12px;
  border-radius: 4px;
  border: 1px solid #e4e8ee;
  background-color: var(--pale-grey);
  position: relative;
}
.checkbox-with-label__checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--dark-slate-blue);
  opacity: 0;
  pointer-events: none;
}
.checkbox-with-label__checkbox[aria-checked=true]:after {
  opacity: 1;
}
.checkbox-with-label__text-wrap {
  width: calc(100% - 20px - 12px);
  display: flex;
  flex-wrap: wrap;
}
.checkbox-with-label__text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 8px;
  white-space: nowrap;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-error-message {
  background: rgba(255, 45, 85, 0.05);
  padding: 21px 35px 20px 20px;
  display: flex;
  align-items: flex-start;
}
.profile-error-message__icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 2px;
}
.profile-error-message__icon path {
  fill: #616a88;
}
.profile-error-message__text {
  font-size: 14px;
  color: #616a88;
  line-height: 1.43;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-error-message__text_bold {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.profile-error-message__link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  color: var(--deep-sky-blue);
  line-height: 1.43;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-error-message__link:hover {
  text-decoration: none;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.copy-item-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy-item-button_with-text {
  width: auto;
  height: auto;
  justify-content: flex-start;
}
.copy-item-button:disabled {
  cursor: default;
}
.copy-item-button_disabled {
  cursor: default;
}
.copy-item-button__icon-wrap {
  width: 12px;
  height: 14px;
}
.copy-item-button_with-text .copy-item-button__icon-wrap {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.copy-item-button__icon path {
  fill: #b0b5c4;
}
.additional-actions-dropdown__item .copy-item-button__icon path {
  fill: var(--dark-slate-blue);
}
.copy-item-button__title {
  white-space: nowrap;
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.page-title {
  color: var(--dark-slate-blue);
  font-size: 32px;
  font-family: "Circe-Bold", sans-serif;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.switcher {
  display: flex;
  align-items: center;
}
.switcher.withSubTitle {
  align-items: flex-start;
}
.switcher__checkbox {
  width: 36px;
  height: 20px;
  min-width: 36px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 12px;
  position: relative;
}
.switcher__checkbox:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 6px;
  top: 5px;
  background: var(--light-grey-blue);
  transition: background, left 0.3s ease;
}
.switcher__checkbox[aria-checked=true] {
  border-color: var(--dark-slate-blue);
}
.switcher__checkbox[aria-checked=true]:after {
  background: var(--dark-slate-blue);
  left: 22px;
}
.switcher__title {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.switcher__subtitle {
  margin-top: 3px;
  color: var(--bluey-grey);
  font-size: 12px;
  font-family: "Circe-Regular", sans-serif;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-getting-step-3 {
  padding-bottom: 100px;
}
.acquiring-getting-step-3__title {
  margin-bottom: 43px;
}
.acquiring-getting-step-3__content {
  display: flex;
  width: 100%;
}
.acquiring-getting-step-3__inputs {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.acquiring-getting-step-3__inputs-block {
  margin-bottom: 45px;
}
.acquiring-getting-step-3__inputs-title {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 20px;
}
.acquiring-getting-step-3__inputs-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-blue);
  margin-bottom: 20px;
  font-family: "Circe-Regular", sans-serif;
  margin-top: -8px;
}
.acquiring-getting-step-3__input {
  margin-bottom: 20px;
}
.acquiring-getting-step-3__input .input-with-label-animation__input {
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
}
.acquiring-getting-step-3__input:last-child {
  margin-bottom: 0;
}
.acquiring-getting-step-3__input_password .input-with-label-animation__input {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-getting-step-3__input-copy-button {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 42px;
  top: 25px;
  z-index: 40;
}
.acquiring-getting-step-3__input-copy-button path {
  fill: #616a88;
}
.acquiring-getting-step-3__input-copy-button_alone {
  right: 17px;
  top: 20px;
}
.acquiring-getting-step-3__input-edit-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  right: 20px;
  top: 50%;
  margin-top: -6px;
}
.acquiring-getting-step-3__input-show-value {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: absolute;
  right: 58px;
  top: 25px;
  z-index: 40;
}
.acquiring-getting-step-3__input-show-value-icon {
  width: 21px;
  height: 14px;
}
.acquiring-getting-step-3__input-show-value-icon path {
  fill: #616a88;
}
.acquiring-getting-step-3__input-regenerate {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25px;
  right: 17px;
}
.acquiring-getting-step-3__switcher-item {
  margin-bottom: 18px;
}
.acquiring-getting-step-3__switcher-item:last-child {
  margin-bottom: 0;
}
.acquiring-getting-step-3__side {
  width: calc(100% - 350px - 60px);
}
.acquiring-getting-step-3__message {
  margin-bottom: 30px;
  border-radius: 8px;
}
.acquiring-getting-step-3__buttons-list {
  display: flex;
}
.acquiring-getting-step-3__buttons-item {
  width: 165px;
  margin-right: 20px;
}
.acquiring-getting-step-3__buttons-item:last-child {
  margin-right: 0;
}
.acquiring-getting-step-3__page-way {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  height: 72px;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 2px 20px 2px 52px;
  width: 100%;
}
.acquiring-getting-step-3__page-way_active {
  border-color: #00adb5;
}
.acquiring-getting-step-3__page-way:last-child {
  margin-bottom: 0;
}
.acquiring-getting-step-3 .acquiring-getting-step-3__page-way_disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.acquiring-getting-step-3__page-way-checkbox {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #e4e8ee;
  background: #f6f8fb;
  left: 20px;
  top: 16px;
}
.acquiring-getting-step-3__page-way_active .acquiring-getting-step-3__page-way-checkbox {
  border-color: var(--dark-slate-blue);
}
.acquiring-getting-step-3__page-way_active .acquiring-getting-step-3__page-way-checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--dark-slate-blue);
  margin-top: -4px;
  margin-left: -4px;
}
.acquiring-getting-step-3__page-way-title {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  display: block;
  text-align: left;
}
.acquiring-getting-step-3__page-way-desc {
  color: #b0b5c4;
  font-size: 12px;
  font-family: "Circe-Regular", sans-serif;
  display: block;
  text-align: left;
}
.acquiring-getting-step-3__copied-hover {
  z-index: 60;
}
.acquiring-getting-step-3__loader {
  min-height: 1500px;
  display: flex;
  padding-top: 160px;
  justify-content: center;
}

@media screen and (max-width: 1225px) {
  .acquiring-getting-step-3_step .acquiring-getting-step-3__inputs {
    margin-right: 20px;
  }
  .acquiring-getting-step-3_step .acquiring-getting-step-3__side {
    width: calc(100% - 350px - 20px);
  }
}
@media screen and (max-width: 1194px) {
  .acquiring-getting-step-3_step .acquiring-getting-step-3__content {
    display: block;
  }
  .acquiring-getting-step-3_step .acquiring-getting-step-3__inputs {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .acquiring-getting-step-3_step .acquiring-getting-step-3__side {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .acquiring-getting-step-3__content {
    display: block;
  }
  .acquiring-getting-step-3__inputs {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .acquiring-getting-step-3__side {
    width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-time-line-step__time-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.acquiring-time-line-step__circle {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
}
.acquiring-time-line-step__circle:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ffffff;
  opacity: 0.4;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: -2px;
}
.acquiring-time-line-step__line {
  height: 4px;
  width: calc(100% - 16px);
}
.acquiring-time-line-step__text-wrap {
  width: 130px;
  margin-left: -65px;
  text-align: center;
}
.acquiring-time-line-step_is-last .acquiring-time-line-step__text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: auto;
  margin-left: 0;
}
.acquiring-time-line-step:first-child .acquiring-time-line-step__text-wrap {
  width: auto;
  text-align: left;
  margin-left: 0;
}
.acquiring-time-line-step__title {
  color: var(--grey-blue);
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 5px;
}
.acquiring-time-line-step_is-last .acquiring-time-line-step__title {
  white-space: nowrap;
}
.acquiring-time-line-step__title-amount {
  display: inline-block;
}
.acquiring-time-line-step__date {
  font-size: 12px;
  color: var(--light-grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-time-line-step_is-last .acquiring-time-line-step__date {
  white-space: nowrap;
}

@media screen and (max-width: 880px) {
  .acquiring-time-line-step {
    display: flex;
  }
  .acquiring-time-line-step__time-line {
    flex-direction: column;
    margin-bottom: 0;
    margin-right: 12px;
  }
  .acquiring-time-line-step__line {
    height: 38px;
    width: 2px;
  }
  .acquiring-time-line-step__text-wrap {
    width: auto;
    margin-left: 0;
    text-align: left;
  }
  .acquiring-time-line-step_is-last .acquiring-time-line-step__text-wrap {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .acquiring-time-line-step:first-child .acquiring-time-line-step__text-wrap {
    width: auto;
    text-align: left;
    margin-left: 0;
  }
  .acquiring-time-line-step__title {
    display: flex;
  }
  .acquiring-time-line-step__title .taxes-amount {
    margin-left: 4px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-operations-time-line {
  display: flex;
}

@media screen and (max-width: 880px) {
  .acquiring-operations-time-line {
    flex-direction: column;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-operation-item {
  background: white;
  position: relative;
}
.acquiring-operation-item__colored-line {
  position: absolute;
  left: 0;
  top: 0;
  height: 79px;
  width: 2px;
}
.acquiring-operation-item__top {
  height: 80px;
  display: flex;
  padding: 23px 30px 18px 30px;
  width: 100%;
  border-bottom: 1px solid #e4e8ee;
  background: white;
  transition: background 0.3s ease;
  cursor: pointer;
}
.acquiring-operation-item_open .acquiring-operation-item__top {
  background: #f6f8fb;
}
.acquiring-operation-item_not-payed .acquiring-operation-item__top {
  cursor: default;
}
.acquiring-operation-item__column:first-child {
  width: 25%;
  min-width: 130px;
}
.acquiring-operation-item__column:nth-child(2) {
  width: 14%;
  min-width: 85px;
}
.acquiring-operation-item__column:nth-child(3) {
  width: 31.5%;
  min-width: 130px;
}
.acquiring-operation-item__column:nth-child(4) {
  width: calc(29.5% - 30px);
  padding-right: 20px;
  text-align: right;
}
.acquiring-operation-item__column:nth-child(5) {
  width: 30px;
  min-width: 30px;
}
.acquiring-operation-item__id {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
}
.acquiring-operation-item__creation-date {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-operation-item__payer-number {
  color: var(--grey-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.acquiring-operation-item__status {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 12px;
}
.acquiring-operation-item__last-gotten-date {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-operation-item__amount {
  color: var(--grey-blue);
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Circe-Regular", sans-serif;
  text-align: right;
}
.acquiring-operation-item__card-number {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  white-space: nowrap;
}
.acquiring-operation-item__refund {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
}
.acquiring-operation-item__bottom {
  background: #f6f8fb;
  transition: height 0.3s ease-in-out;
  height: 0;
  position: relative;
  visibility: hidden;
}
.acquiring-operation-item_open .acquiring-operation-item__bottom {
  height: 308px;
  visibility: visible;
}
.acquiring-operation-item__bottom-inner {
  padding: 30px;
}
.acquiring-operation-item__bottom-title {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.acquiring-operation-item__details .acquiring-operation-item__bottom-title {
  margin-bottom: 12px;
}
.acquiring-operation-item__history .acquiring-operation-item__bottom-title {
  margin-bottom: 20px;
}
.acquiring-operation-item__details {
  margin-bottom: 30px;
}
.acquiring-operation-item__details-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.acquiring-operation-item__details-item:last-child {
  margin-bottom: 0;
}
.acquiring-operation-item__details-key {
  color: #b0b5c4;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 184px;
  min-width: 184px;
}
.acquiring-operation-item__details-value {
  color: #b0b5c4;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: calc(100% - 184px);
}
.acquiring-operation-item__download-check {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 32px;
  right: 35px;
}
.acquiring-operation-item__download-check-text {
  font-size: 14px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 8px;
}
.acquiring-operation-item__download-check-icon {
  width: 14px;
  height: 14px;
}
.acquiring-operation-item__service {
  border-top: 1px solid #e4e8ee;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1100px) {
  .acquiring-operation-item__colored-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .acquiring-operation-item__id-wrap {
    display: flex;
  }
  .acquiring-operation-item__id {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .acquiring-operation-item__column:first-child {
    width: 50%;
    min-width: 50%;
  }
  .acquiring-operation-item__column:nth-child(2) {
    width: 50%;
    min-width: 50%;
    text-align: right;
  }
  .acquiring-operation-item_open .acquiring-operation-item__bottom {
    height: auto;
  }
  .acquiring-operation-item__bottom-title {
    font-size: 14px;
  }
  .acquiring-operation-item__history {
    margin-bottom: 19px;
  }
  .acquiring-operation-item__download-check {
    position: relative;
    top: auto;
    right: auto;
  }
  .acquiring-operation-item__refund {
    margin-top: 0;
    width: auto;
  }
  .acquiring-operation-item__refund-icon {
    margin-right: 12px;
  }
  .acquiring-operation-item__refund-text {
    font-size: 14px;
    color: #616a88;
    font-family: "Circe-Regular", sans-serif;
  }
}
@media screen and (max-width: 480px) {
  .acquiring-operation-item {
    padding-left: 20px;
    padding-right: 20px;
  }
  .acquiring-operation-item__top {
    padding: 23px 20px 18px 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.rdrDateRangeWrapper {
  width: 100%;
}

.rdrMonthAndYearWrapper {
  display: none !important;
}

.rdrMonthName {
  display: none !important;
}

.rdrMonth {
  width: 50% !important;
  padding: 0 !important;
}

.rdrWeekDay {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  height: 40px !important;
  text-transform: capitalize;
  font-size: 12px;
  color: #b0b5c4;
}
.rdrWeekDay:nth-child(6) {
  color: rgba(255, 45, 85, 0.5);
}
.rdrWeekDay:last-child {
  color: rgba(255, 45, 85, 0.5);
}

.rdrDay {
  height: 40px !important;
}

.rdrDayNumber {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rdrDayNumber span {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: rgba(97, 106, 136, 0.8);
}

.rdrInRange {
  background: rgba(0, 122, 255, 0.05) !important;
  top: 0 !important;
  bottom: 0 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #1d2e69 !important;
}

.rdrDayEndOfWeek .rdrInRange,
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfWeek .rdrInRange {
  border-radius: 0 !important;
}

.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-radius: 0 !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-radius: 0 !important;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfWeek .rdrInRange {
  border-radius: 0 !important;
}

.rdrDayEndOfWeek .rdrInRange {
  border-radius: 0 !important;
}

.rdrDayStartPreview {
  border-radius: 0 !important;
}

.rdrDayEndPreview {
  border-radius: 0 !important;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  display: none;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: rgba(0, 122, 255, 0.05);
}

.rdrStartEdge,
.rdrEndEdge {
  border-radius: 4px !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.rdrStartEdge ~ .rdrDayStartPreview,
.rdrEndEdge ~ .rdrDayEndPreview {
  display: none;
}

@media (max-width: 600px) {
  .rdrMonth {
    width: 100% !important;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.calendar-range-picker {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 23px 70px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
.calendar-range-picker__container {
  position: relative;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}
.calendar-range-picker__calendar {
  width: 100%;
  border-bottom: 1px solid rgba(176, 181, 196, 0.1);
}
.calendar-range-picker__calendar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar-range-picker__calendar-nav {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 8px);
  height: 40px;
  padding: 0 10px;
  border: 1px solid rgba(176, 181, 196, 0.1);
  border-radius: 4px;
}
.calendar-range-picker__calendar-nav > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 10px;
}
.calendar-range-picker__calendar-nav > button > svg {
  width: 100%;
  height: 100%;
}
.calendar-range-picker__calendar-nav > button > svg path {
  stroke: #b0b5c4;
}
.calendar-range-picker__calendar-nav > span {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}
.calendar-range-picker__calendar-left-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.calendar-range-picker__chosen-dates {
  display: flex;
  padding: 16px 0;
}
.calendar-range-picker__chosen-date {
  font-family: "Circe-Regular", sans-serif;
  width: 50%;
  padding-left: 13.33335px;
  opacity: 0.8;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}
.calendar-range-picker__sidebar {
  flex-shrink: 0;
  width: 190px;
  margin-left: 8px;
}
.calendar-range-picker__range-nav {
  display: flex;
  flex-direction: column;
}
.calendar-range-picker__range-nav > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  opacity: 0.8;
  border-radius: 4px;
  border: 1px solid rgba(97, 106, 136, 0.2);
  line-height: 20px;
  font-size: 14px;
  color: #616a88;
  transition: opacity 0.3s, border-color 0.3s;
}
.calendar-range-picker__range-nav > button:first-child {
  margin-top: 0;
}
.calendar-range-picker__range-nav > button.active {
  opacity: 1;
  border-color: #616a88;
}
.calendar-range-picker__range-nav > button:hover {
  opacity: 1;
}
.calendar-range-picker__dialog-nav {
  display: flex;
  margin-top: 8px;
}
.calendar-range-picker__dialog-nav > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  line-height: 20px;
  font-size: 14px;
}
.calendar-range-picker__dialog-nav > button:nth-child(1) {
  border-radius: 4px;
  background-color: #1d2e69;
  color: #fff;
}
.calendar-range-picker__dialog-nav > button:nth-child(2) {
  opacity: 0.5;
  color: #1d2e69;
  transition: opacity 0.3s;
}
.calendar-range-picker__dialog-nav > button:nth-child(2):hover {
  opacity: 1;
}

@media (min-width: 601px) and (max-width: 980px) {
  .calendar-range-picker {
    flex-direction: column;
  }
  .calendar-range-picker__sidebar {
    width: 100%;
    margin-left: 0;
  }
  .calendar-range-picker__range-nav {
    flex-direction: row;
  }
  .calendar-range-picker__range-nav > button {
    margin-top: 0;
    margin-left: 8px;
  }
  .calendar-range-picker__range-nav > button:first-child {
    margin-left: 0;
  }
  .calendar-range-picker__dialog-nav {
    justify-content: flex-end;
    margin-top: 16px;
  }
  .calendar-range-picker__dialog-nav > button {
    width: 25%;
  }
}
@media (max-width: 600px) {
  .calendar-range-picker {
    flex-direction: column;
  }
  .calendar-range-picker__sidebar {
    width: 100%;
    margin-left: 0;
  }
  .calendar-range-picker__calendar-nav {
    width: 100%;
  }
  .calendar-range-picker__calendar-nav:last-child {
    display: none;
  }
  .calendar-range-picker__chosen-dates {
    justify-content: space-between;
  }
  .calendar-range-picker__chosen-date {
    width: auto;
    padding-left: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.filters-bar {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
.filters-bar__search-wrap {
  margin-right: 20px;
  width: calc(100% - 140px - 245px);
  position: relative;
}
.filters-bar__search-input {
  width: 100%;
  height: 40px;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 40px;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.filters-bar__search-input:focus {
  border-color: var(--dark-slate-blue);
}
.filters-bar__search-input::-webkit-input-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input::-moz-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input:-moz-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input:-ms-input-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input::-ms-input-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input::-ms-clear {
  display: none;
}
.filters-bar__search-input_filled {
  border-color: var(--dark-slate-blue);
  background: white;
}
.filters-bar__search-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.filters-bar__search-icon {
  width: 20px;
  height: 22px;
}
.filters-bar__clear-search-icon {
  width: 20px;
  height: 22px;
}
.filters-bar__date-wrap {
  margin-right: 20px;
  width: 31%;
  min-width: 245px;
  position: relative;
}
.filters-bar__date-input {
  width: 100%;
  height: 40px;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  color: #9da6ba;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.filters-bar__date-input:focus {
  background: white;
  border-color: var(--dark-slate-blue);
}
.filters-bar__date-input_filled {
  color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
  background: white;
}
.filters-bar__date-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 18px;
  top: 50%;
  margin-top: -8px;
  z-index: 30;
}
.filters-bar__date-icon path {
  fill: #616a88;
}
.filters-bar__date-clear {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 22px;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -11px;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filters-bar__date-clear-icon {
  width: 20px;
  height: 22px;
}
.filters-bar__buttons-wrap {
  display: flex;
}
.filters-bar__download {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fb;
  border-radius: 8px;
  margin-right: 20px;
}
.filters-bar__indicator {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fb;
  border-radius: 8px;
}
.filters-bar__indicator_chosen {
  background: var(--deep-sky-blue);
}
.filters-bar__indicator_chosen .filters-bar__indicator-icon path {
  fill: white;
}
.filters-bar__calendar-drop {
  position: absolute;
  z-index: 1;
  top: 72px;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1220px) {
  .filters-bar {
    flex-wrap: wrap;
  }
  .filters-bar__search-wrap {
    margin-right: 0;
    width: 100%;
    margin-bottom: 12px;
  }
  .filters-bar__date-wrap {
    margin-right: 12px;
    width: calc(100% - 40px - 40px - 12px - 12px);
  }
  .filters-bar__download {
    margin-right: 12px;
  }
  .filters-bar__calendar-drop {
    top: 120px;
  }
}
@media screen and (max-width: 480px) {
  .filters-bar {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
  .filters-bar__date-wrap {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .filters-bar__buttons-wrap {
    width: 100%;
    flex-direction: row-reverse;
  }
  .filters-bar__download {
    width: calc((100% - 12px) / 2);
    margin-right: 0;
  }
  .filters-bar__download-icon {
    margin-right: 14px;
  }
  .filters-bar__indicator {
    width: calc((100% - 12px) / 2);
    margin-right: 12px;
  }
  .filters-bar__indicator-icon {
    margin-right: 12px;
  }
  .filters-bar__button-text {
    color: #616a88;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
  }
  .filters-bar__calendar-drop {
    top: 100px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.multiple-select {
  position: relative;
}
.multiple-select__control {
  min-height: 60px;
  border-radius: 8px;
  border: 1px solid var(--dark-slate-blue);
}
.multiple-select__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.multiple-select:focus-within .multiple-select__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.multiple-select_focused .multiple-select__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.filters-side-bar {
  position: fixed;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.filters-side-bar > div {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 415px;
  background: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.filters-side-bar__content {
  padding-top: 138px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.filters-side-bar__title {
  font-size: 24px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 40px;
}
.filters-side-bar__list {
  margin-bottom: 30px;
}
.filters-side-bar__list-item {
  margin-bottom: 20px;
}
.filters-side-bar__list-item:last-child {
  margin-bottom: 0;
}
.filters-side-bar__sum-wrap {
  display: flex;
}
.filters-side-bar__sum-item {
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
}
.filters-side-bar__sum-item:last-child {
  margin-right: 0;
}
.filters-side-bar__buttons-item {
  width: 100%;
  margin-bottom: 20px;
}
.filters-side-bar__buttons-item:last-child {
  margin-bottom: 0;
}
.filters-side-bar__close-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filters-side-bar__close-button-icon {
  width: 17px;
  height: 17px;
}
.filters-side-bar__close-button-icon path {
  stroke: #616a88;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.operations-tab_calendar-open {
  min-height: 1000px;
}
.operations-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 21px;
}
.operations-tab__filters {
  margin-bottom: 22px;
}
@media (max-width: 480px) {
  .operations-tab__filters {
    box-shadow: none;
    box-shadow: initial;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.operations-tab__content-wrap {
  position: relative;
  overflow: hidden;
  height: 480px;
}
@media (min-width: 481px) {
  .operations-tab__content-wrap {
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
    background: white;
    border-radius: 12px;
  }
}
.operations-tab__loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.operations-tab__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 3px;
}
.operations-tab__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--pale-grey);
  position: absolute;
}
.operations-tab__no-operations-message {
  padding: 100px 0;
}

@media screen and (max-width: 480px) {
  .operations-tab__list {
    box-shadow: none;
    border-radius: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
  .operations-tab__no-operations-message {
    padding: 50px 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.page-preview-popup {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  width: 70%;
  display: flex;
  align-items: center;
  z-index: 200;
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
}
.page-preview-popup-enter {
  opacity: 0;
  left: 100%;
}
.page-preview-popup-enter-active {
  opacity: 1;
  left: 30%;
}
.page-preview-popup-enter-done {
  opacity: 1;
  left: 30%;
}
.page-preview-popup-exit {
  opacity: 1;
  left: 30%;
}
.page-preview-popup-exit-active {
  opacity: 0;
  left: 100%;
}
.page-preview-popup__inner {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.page-preview-popup__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
.page-preview-popup__image {
  max-width: 900px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  max-height: 550px;
  border-radius: 12px;
}
.page-preview-popup__close {
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 38px;
  right: 58px;
  border: none;
}
.page-preview-popup__close path {
  transition: stroke 0.2s ease-in-out;
}
.page-preview-popup__close:hover path {
  stroke: var(--reddish-pink);
}

@media screen and (max-width: 1149px) {
  .page-preview-popup {
    width: 100%;
  }
  .page-preview-popup-enter-active {
    left: 0;
  }
  .page-preview-popup-enter-done {
    left: 0;
  }
  .page-preview-popup-exit {
    left: 0;
  }
}
@media screen and (max-width: 980px) {
  .page-preview-popup {
    align-items: flex-start;
    padding-top: 100px;
  }
  .page-preview-popup__close {
    right: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-pages-tab__top {
  margin-bottom: 70px;
}
.payment-pages-tab__title {
  margin-bottom: 30px;
}
.payment-pages-tab__desc {
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  max-width: 70%;
}
.payment-pages-tab__block {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 20px;
}
.payment-pages-tab__block_in-active {
  background: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
}
.payment-pages-tab__block:last-child {
  margin-bottom: 0;
}
.payment-pages-tab__block-top {
  margin-bottom: 20px;
}
.payment-pages-tab__block-top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-pages-tab__block-title {
  font-size: 20px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.payment-pages-tab__block_in-active .payment-pages-tab__block-title {
  color: #9da6ba;
}
.payment-pages-tab__block-desc {
  color: #b0b5c4;
  font-size: 12px;
  line-height: 16px;
  font-family: "Circe-Regular", sans-serif;
  max-width: 100%;
  margin-top: 8px;
}
.payment-pages-tab__block-active-badge {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.payment-pages-tab__block-active-badge-icon {
  margin-right: 12px;
  width: 11px;
  height: 8px;
}
.payment-pages-tab__block-active-badge-text {
  font-size: 14px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--turquoise-blue);
}
.payment-pages-tab__block-list {
  display: flex;
}
.payment-pages-tab__card {
  padding: 20px;
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
  border-radius: 6px;
  height: 138px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.payment-pages-tab__card:last-child {
  margin-right: 0;
}
.payment-pages-tab__card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-pages-tab__choose-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  line-height: 24px;
  color: white;
  background: rgba(255, 255, 255, 0.26);
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.payment-pages-tab__choose-button:hover:not(:disabled) {
  background: var(--deep-sky-blue);
}
.payment-pages-tab__choose-button_chosen {
  background: white !important;
  color: var(--dark-slate-blue);
  cursor: default;
}
.payment-pages-tab__choose-button_unavailable {
  background: rgba(255, 255, 255, 0.26) !important;
}
.payment-pages-tab__block_in-active .payment-pages-tab__choose-button {
  cursor: default;
}
.payment-pages-tab__card-preview {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.payment-pages-tab__card-preview-text {
  font-size: 12px;
  font-family: "Circe-Regular", sans-serif;
  color: white;
  margin-right: 9px;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.payment-pages-tab__card-preview:hover .payment-pages-tab__card-preview-text {
  color: var(--dark-slate-blue);
}
.payment-pages-tab__card-preview-icon {
  width: 13px;
  height: 10px;
}
.payment-pages-tab__card-preview-icon path {
  transition-property: stroke;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.payment-pages-tab__card-preview:hover .payment-pages-tab__card-preview-icon path {
  stroke: var(--dark-slate-blue);
}
.payment-pages-tab__card-type {
  font-size: 12px;
  color: white;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 4px;
}
.payment-pages-tab__card-title {
  color: #ffffff;
  font-size: 20px;
  font-family: "Circe-Bold", sans-serif;
}
.payment-pages-tab__popup .small-popup-with-emoji__popup {
  min-height: 410px;
}
.payment-pages-tab__popup-desc {
  text-align: center;
}
.payment-pages-tab__loader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 200px;
}

@media screen and (max-width: 780px) {
  .payment-pages-tab__block-list {
    display: block;
  }
  .payment-pages-tab__card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .payment-pages-tab__card:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 500px) {
  .payment-pages-tab__desc {
    max-width: 100%;
  }
  .payment-pages-tab__block-top-wrap {
    display: block;
  }
  .payment-pages-tab__block-active-badge {
    margin-top: 20px;
    justify-content: flex-start;
  }
  .payment-pages-tab__block-desc {
    max-width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-ways-item {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 12px;
  padding: 20px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}
.payment-ways-item__status {
  width: 96px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  border: 1px solid var(--deep-sky-blue);
  background: var(--deep-sky-blue);
  color: white;
  font-size: 12px;
  font-family: "Circe-Bold", sans-serif;
}
.payment-ways-item__status_turned-on {
  border-color: var(--turquoise-blue);
  background: white;
  color: var(--turquoise-blue);
}
.payment-ways-item__status_disabled {
  background: var(--light-grey-blue);
  border-color: var(--light-grey-blue);
}
.payment-ways-item__loader-wrap {
  background: var(--light-grey-blue);
  border-color: var(--light-grey-blue);
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 12px;
}
.payment-ways-item__title {
  font-size: 20px;
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 12px;
  color: var(--dark-slate-blue);
}
.payment-ways-item_disabled .payment-ways-item__title {
  opacity: 0.5;
}
.payment-ways-item__desc {
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  min-height: 48px;
}
.payment-ways-item_disabled .payment-ways-item__desc {
  opacity: 0.5;
}
.payment-ways-item__details {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.payment-ways-item__details-span {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-bottom: 3px;
  background: #c4c4c4;
}
.payment-ways-item__details-span:last-child {
  margin-bottom: 0;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.payment-ways-tab__top {
  margin-bottom: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-ways-tab__go-to-demo {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.payment-ways-tab__go-to-demo-text {
  font-size: 16px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 14px;
}
.payment-ways-tab__go-to-demo-icon {
  width: 20px;
  height: 20px;
}
.payment-ways-tab__list {
  display: flex;
  flex-wrap: wrap;
}
.payment-ways-tab__item {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
  margin-bottom: 20px;
}
.payment-ways-tab__item:last-child {
  margin-right: 0;
}
.payment-ways-tab__item:nth-child(2n) {
  margin-right: 0;
}
.payment-ways-tab__item:nth-last-child(-n+2) {
  margin-bottom: 0;
}

@media screen and (max-width: 900px) {
  .payment-ways-tab__top {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .payment-ways-tab__title {
    margin-bottom: 20px;
  }
  .payment-ways-tab__list {
    display: block;
  }
  .payment-ways-tab__item {
    width: 100%;
    margin-right: 0;
  }
  .payment-ways-tab__item:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .payment-ways-tab__item:nth-last-child(-n+2) {
    margin-bottom: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.status-badge {
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 1px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ffffff;
  font-family: "Circe-Bold", sans-serif;
  white-space: nowrap;
}

@media screen and (max-width: 1194px) {
  .status-badge {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
  }
}
@media screen and (max-width: 1100px) {
  .status-badge {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }
}
@media screen and (max-width: 380px) {
  .status-badge {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.shops-dropdown-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  background: white;
  transition: background 0.3s ease-in-out;
}
.shops-dropdown-item_top {
  border-radius: 8px;
}
.shops-dropdown-item_top.shops-dropdown-item_drop-open {
  border-radius: 8px 8px 0 0;
}
.shops-dropdown-item__left {
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
}
.shops-dropdown-item__icon-wrap {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shops-dropdown-item__icon {
  width: 20px;
  height: 17px;
}
.shops-dropdown-item__text-wrap {
  width: calc(100% - 52px);
}
.shops-dropdown-item__title {
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shops-dropdown__list .shops-dropdown-item__title {
  max-width: 132px;
}
.shops-dropdown-item__id {
  font-size: 12px;
  color: #8e97b4;
  font-family: "Circe-Regular", sans-serif;
}
.shops-dropdown-item__arrow {
  height: 12px;
  width: 6px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.shops-dropdown-item__arrow path {
  stroke: var(--light-grey-blue);
}
@media screen and (max-width: 1194px) {
  .shops-dropdown-item {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (max-width: 1100px) {
  .shops-dropdown-item {
    padding-left: 20px;
    padding-right: 20px;
  }
  .shops-dropdown-item_top {
    border-radius: 0;
  }
  .shops-dropdown-item_top.shops-dropdown-item_drop-open {
    border-radius: 0;
  }
}
@media screen and (max-width: 380px) {
  .shops-dropdown-item {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.shops-dropdown {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 8px;
  width: 100%;
  position: relative;
}
.shops-dropdown__main {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
}
.shops-dropdown__add-new {
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  background: white;
  transition: background 0.3s ease-in-out;
}
.shops-dropdown__add-new:hover {
  background: var(--pale-grey);
}
.shops-dropdown__add-new-icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 12px;
  border: 1px dashed #9da6ba;
  border-radius: 50%;
  position: relative;
}
.shops-dropdown__add-new-icon:before {
  content: "";
  width: 1px;
  height: 14px;
  position: absolute;
  background: #9da6ba;
  top: 50%;
  margin-top: -7px;
  left: 50%;
  margin-left: -1px;
}
.shops-dropdown__add-new-icon:after {
  content: "";
  width: 14px;
  height: 1px;
  position: absolute;
  background: #9da6ba;
  top: 50%;
  left: 50%;
  margin-left: -7px;
}
.shops-dropdown__add-new-text {
  color: #9da6ba;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.shops-dropdown__add-new-arrow {
  height: 12px;
  width: 6px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -6px;
}
.shops-dropdown__add-new-arrow path {
  stroke: var(--light-grey-blue);
}
.shops-dropdown__list {
  border-radius: 0 0 8px 8px;
}

@media screen and (max-width: 380px) {
  .shops-dropdown__add-new {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.shop-status-tab__top {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}
.shop-status-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-right: 20px;
}
.shop-status-tab__status {
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
  color: white;
  font-family: "Circe-Bold", sans-serif;
}
.shop-status-tab__main {
  max-width: 508px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.shop-status-tab__icon-wrap {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 40px;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.shop-status-tab__icon_check {
  width: 26.67px;
  height: 18.33px;
}
.shop-status-tab__icon_check path {
  stroke: var(--turquoise-blue);
}
.shop-status-tab__icon_detail {
  width: 30px;
  height: 30px;
}
.shop-status-tab__icon_detail path {
  fill: var(--turquoise-blue);
}
.shop-status-tab__icon_lock {
  width: 30px;
  height: 30px;
}
.shop-status-tab__icon_lock path {
  fill: #9da6ba;
}
.shop-status-tab__icon_close path {
  stroke: var(--reddish-pink);
}
.shop-status-tab__main-title {
  font-size: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
.shop-status-tab__text {
  font-size: 16px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.shop-status-tab__text:last-child {
  margin-bottom: 0;
}
.shop-status-tab__text:only-child {
  margin-bottom: 0;
}
.shop-status-tab__text-link {
  color: var(--deep-sky-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-dropdown {
  background: white;
  padding: 20px 50px 80px 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.profile-settings-dropdown__item-wrap {
  margin-bottom: 20px;
}
.profile-settings-dropdown__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  color: var(--bluey-grey);
  font-family: "Circe-Bold", sans-serif;
  font-size: 24px;
}
.profile-settings-dropdown__item_active {
  color: var(--dark-slate-blue);
}

@media screen and (max-width: 600px) {
  .profile-settings-dropdown {
    padding: 16px 20px 50px 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-nav {
  width: 100%;
}
.profile-settings-nav__block {
  margin-bottom: 48px;
}
.profile-settings-nav__block:last-child {
  margin-bottom: 0;
}
.profile-settings-nav__block-title {
  font-size: 12px;
  color: var(--normal-grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 26px;
}
.profile-settings-nav__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.profile-settings-nav__item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
}
.profile-settings-nav__item-wrap:last-child {
  margin-bottom: 0;
}
.profile-settings-nav__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.profile-settings-nav__item-icon-wrap {
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-settings-nav__item-icon {
  max-width: 100%;
  max-height: 100%;
}
.profile-settings-nav__item-title {
  font-size: 18px;
  color: var(--normal-grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.profile-settings-nav__item_active .profile-settings-nav__item-title {
  color: var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring {
  padding-top: 40px;
  min-height: 710px;
  position: relative;
}
.acquiring__container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  width: 100%;
  position: relative;
}
.acquiring__sidebar {
  width: 350px;
  min-width: 350px;
}
.acquiring__shops-dropdown {
  margin-bottom: 58px;
}
.acquiring__main {
  width: calc(100% - 350px);
  min-width: calc(100% - 350px);
  padding-left: 60px;
  padding-bottom: 100px;
}
.acquiring__tabs {
  padding-left: 20px;
  margin-bottom: 50px;
}
.acquiring__tabs__nav-icon {
  width: 21px;
  height: 23px;
}
.acquiring__tabs__nav-icon path {
  fill: var(--normal-grey-blue);
}
.acquiring__tabs__nav-icon__active path {
  fill: var(--dark-slate-blue);
}

@media screen and (max-width: 1194px) {
  .acquiring__sidebar {
    width: 300px;
    min-width: 300px;
  }
  .acquiring__main {
    width: calc(100% - 300px);
    min-width: calc(100% - 300px);
    padding-left: 30px;
  }
}
@media screen and (max-width: 1100px) {
  .acquiring {
    padding-top: 0;
  }
  .acquiring__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .acquiring__sidebar {
    width: 230px;
    min-width: 230px;
  }
  .acquiring__main {
    width: calc(100% - 230px);
    min-width: calc(100% - 230px);
    padding-left: 20px;
  }
}
@media screen and (max-width: 800px) {
  .acquiring {
    padding-top: 0;
  }
  .acquiring__tabs-wrap {
    margin-bottom: 40px;
    padding-left: 40px;
  }
  .acquiring__tabs .tabs-with-scrollable-active-state__nav-item {
    white-space: nowrap;
  }
  .acquiring__container {
    display: block;
  }
  .acquiring__nav-select {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: white;
    padding: 0 57px 0 50px;
    border-bottom: 1px solid #e8ecf2;
    margin-bottom: 20px;
  }
  .acquiring__nav-select-text {
    font-size: 24px;
    color: var(--dark-slate-blue);
    font-family: "Circe-Bold", sans-serif;
  }
  .acquiring__nav-select-icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 24px;
    height: 24px;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .acquiring__nav-select-icon path {
    stroke: var(--dark-slate-blue);
  }
  .acquiring__nav-select-icon_open {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .acquiring__main {
    width: 100%;
    min-width: 100%;
    padding-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .acquiring__container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .acquiring__nav-select {
    padding: 0 27px 0 20px;
  }
  .acquiring__main_analytics {
    padding-bottom: 0;
    overflow: hidden;
    width: calc(100% + 40px);
    margin-right: -20px;
    margin-left: -20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-header {
  margin-bottom: 50px;
}
.money-transfer-header_desktop, .money-transfer-header_mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.money-transfer-header_mobile {
  display: none;
  height: 90px;
}
.money-transfer-header .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.money-transfer-header__link {
  display: flex;
  width: 39px;
  height: 45px;
  text-decoration: none;
}
.money-transfer-header__logo {
  width: 130px;
  height: 45px;
}
.money-transfer-header__burger-menu-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
}
.money-transfer-header__burger-menu-button__inner {
  position: absolute;
  top: 10px;
  right: 5px;
  bottom: 10px;
  left: 5px;
}
.money-transfer-header__burger-menu-button__line {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3px;
  transition: all 0.3s ease;
}
.money-transfer-header__burger-menu-button__line path {
  transition: all 0.3s ease;
}
.money-transfer-header__burger-menu-button__line:first-child {
  -webkit-animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-transfer-header__burger-menu-button__line:nth-child(2) {
  -webkit-animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.money-transfer-header__burger-menu-button__line:nth-child(3) {
  -webkit-animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-transfer-header__burger-menu-button_pressed .money-transfer-header__burger-menu-button__line:first-child {
  -webkit-animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.money-transfer-header__burger-menu-button_pressed .money-transfer-header__burger-menu-button__line:nth-child(2) {
  -webkit-animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.money-transfer-header__burger-menu-button_pressed .money-transfer-header__burger-menu-button__line:nth-child(3) {
  -webkit-animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
          animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}

@media (max-width: 980px) {
  .money-transfer-header {
    margin-bottom: 17px;
  }
  .money-transfer-header_desktop {
    display: none;
  }
  .money-transfer-header_mobile {
    display: flex;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-title__desktop {
  font-family: "Circe-Bold", sans-serif;
  line-height: 48px;
  font-size: 36px;
  color: #1d2e69;
}
.money-transfer-title__mobile {
  display: none;
}
.money-transfer-title__mobile > h1 {
  font-family: "Circe-Bold", sans-serif;
  line-height: 36px;
  font-size: 28px;
  color: #1d2e69;
}
.money-transfer-title__mobile > h2 {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 26px;
  line-height: 24px;
  font-size: 18px;
  color: #b0b5c4;
}

@media (max-width: 980px) {
  .money-transfer-title__desktop {
    display: none;
  }
  .money-transfer-title__mobile {
    display: inline;
    display: initial;
  }
}
.money-transfer-progress-line {
  position: relative;
  height: 4px;
  margin-top: 10px;
}
.money-transfer-progress-line__wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  border-bottom: 1px solid #b0b5c4;
}
.money-transfer-progress-line__value {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-image: linear-gradient(90deg, #6c5ce7 0%, #5ac8fa 123.47%);
  transition: width 0.3s;
}

@media (max-width: 980px) {
  .money-transfer-progress-line {
    margin-top: 12px;
  }
  .money-transfer-progress-line__wrap {
    left: -20px;
    right: -20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer__sidebar {
  position: relative;
  flex: none;
  width: 320px;
  margin-right: 100px;
}
.money-transfer__sidebar-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  box-shadow: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link:hover {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  border-color: #fff;
}
.money-transfer__sidebar-link:last-child {
  margin-bottom: 0;
}
.money-transfer__sidebar-link-active {
  background-color: #fff;
  border-color: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  color: #9da6ba;
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-title {
  color: var(--grey-blue);
}
.money-transfer__sidebar-link:active .money-transfer__sidebar-link-title {
  color: var(--grey-slate-blue);
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-title {
  color: var(--grey-slate-blue);
}
.money-transfer__sidebar-link-icon_card path {
  width: 25px;
  height: 18px;
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_card path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_card path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-icon_wallet {
  width: 25px;
  height: 22px;
}
.money-transfer__sidebar-link-icon_wallet path {
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-icon_sbp {
  width: 25px;
  height: 24px;
}
.money-transfer__sidebar-link-icon_sbp path {
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-inactive:not(:hover) .money-transfer__sidebar-link-icon_sbp path {
  fill: var(--bluey-grey);
}
.money-transfer__sidebar-link-icon_webmoney {
  width: 25px;
  height: 24px;
}
.money-transfer__sidebar-link-icon_webmoney path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_webmoney path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_webmoney path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-icon_umoney {
  width: 30px;
  height: 22px;
}
.money-transfer__sidebar-link-icon_umoney path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_umoney path {
  fill: #8b3ffd;
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_umoney path {
  fill: #8b3ffd;
}
.money-transfer__sidebar-link-icon_qiwi {
  width: 31px;
  height: 32px;
}
.money-transfer__sidebar-link-icon_qiwi path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_qiwi path {
  fill: #ff8c00;
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_qiwi path {
  fill: #ff8c00;
}

@media (max-width: 980px) {
  .money-transfer__sidebar {
    width: 100%;
    margin-right: 0;
  }
  .money-transfer__sidebar-link-icon_card path {
    fill: var(--dark-slate-blue);
  }
  .money-transfer__sidebar-link-icon_wallet path {
    fill: var(--dark-slate-blue);
    stroke: var(--dark-slate-blue);
  }
  .money-transfer__sidebar-link-icon_webmoney path {
    fill: var(--dark-slate-blue);
  }
  .money-transfer__sidebar-link-icon_umoney path {
    fill: #8b3ffd;
  }
  .money-transfer__sidebar-link-icon_qiwi path {
    fill: #ff8c00;
  }
}
.money-transfer-card {
  position: relative;
  width: 400px;
  border-radius: 12px;
}

@media (max-width: 980px) {
  .money-transfer-card {
    width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-input {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  border: 1px solid #fff;
  background-color: #fff;
}
.money-input__form {
  width: 100%;
  height: 100%;
  padding: 0 40px 0 20px;
  border-width: 0;
  border-radius: 8px;
  font-family: "Circe-Bold", sans-serif;
  font-size: 18px;
  color: var(--dark-slate-blue);
}
.money-input__currency {
  position: absolute;
  top: 20px;
  right: 22px;
  font-family: "Circe-Bold", sans-serif;
  font-size: 18px;
  color: var(--dark-slate-blue);
}
.money-input_invalid {
  border-color: #ff2d55;
}

@media (max-width: 980px) {
  .money-input__form {
    text-align: center;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-input-and-payment-button {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.money-input-and-payment-button__input-wrap, .money-input-and-payment-button__button-wrap {
  display: flex;
  flex-direction: column;
}
.money-input-and-payment-button__input, .money-input-and-payment-button__button {
  width: 190px;
  height: 60px;
}
.money-input-and-payment-button__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  background-color: var(--dusk-blue);
  font-size: 16px;
  color: #fff;
  transition: all 0.3s ease;
}
.money-input-and-payment-button__button:hover {
  background-color: var(--dark-slate-blue);
}
.money-input-and-payment-button__button:disabled {
  background-color: rgba(40, 65, 149, 0.1);
  cursor: not-allowed;
}
.money-input-and-payment-button__error-message {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 8px;
  line-height: 20px;
  font-size: 14px;
  color: #ff2d55;
}
.money-input-and-payment-button__commission-message {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 8px;
  line-height: 16px;
  font-size: 12px;
  color: #616a88;
}
.money-input-and-payment-button__transfer-all {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 4px;
  line-height: 16px;
  font-size: 12px;
  color: var(--dark-slate-blue);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 980px) {
  .money-input-and-payment-button {
    flex-direction: column;
  }
  .money-input-and-payment-button__input, .money-input-and-payment-button__button {
    width: 100%;
  }
  .money-input-and-payment-button__button {
    margin-top: 18px;
  }
  .money-input-and-payment-button__error-message {
    margin-top: 10px;
    text-align: center;
    line-heigght: 16px;
    font-size: 12px;
  }
  .money-input-and-payment-button__commission-message {
    margin-top: 10px;
    text-align: center;
  }
  .money-input-and-payment-button__transfer-all {
    text-align: center;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-bank-card {
  position: relative;
}
.money-transfer-bank-card__background {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 400px;
  height: 250px;
  border-radius: 12px;
  box-shadow: 0 4px 27px #e8ecf2;
}
.money-transfer-bank-card__card {
  position: relative;
  height: 250px;
  margin-bottom: 20px;
  padding: 0 23px;
  box-shadow: 0 2px 24px 0 rgba(156, 171, 222, 0.39);
}
.money-transfer-bank-card__card-bank-logo {
  width: auto;
  height: 22px;
  margin-top: 34px;
  margin-bottom: 40px;
}
.money-transfer-bank-card__card-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding-top: 40px;
  margin-bottom: 16px;
  font-size: 16px;
  color: var(--dark-slate-blue);
}
.money-transfer-bank-card__card-input-wrap {
  position: relative;
}
.money-transfer-bank-card__card-input {
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  height: 60px;
  background: white;
  -webkit-appearance: none;
  box-shadow: none;
  text-align: left;
  border-radius: 8px;
  border: solid 1px #9da6ba;
  padding: 0 20px;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.money-transfer-bank-card__card-input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input::-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input:-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input:-ms-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input::-ms-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
  font-size: 22px;
  font-family: "Circe-Regular", sans-serif;
  text-align: center;
}
.money-transfer-bank-card__card-input::-ms-clear {
  display: none;
}
.money-transfer-bank-card__card-input[type=number]::-webkit-outer-spin-button, .money-transfer-bank-card__card-input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.money-transfer-bank-card__card-input[type=number] {
  -moz-appearance: textfield;
}
.money-transfer-bank-card__card-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  cursor: not-allowed;
}
.money-transfer-bank-card__card-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]::-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]:-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]:-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-input[disabled]::-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-bank-card__card-clear-button {
  position: absolute;
  right: 22px;
  top: 50%;
  width: 17px;
  height: 17px;
  margin-top: -8px;
  border-radius: 50%;
  background-color: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.money-transfer-bank-card__card-clear-button:hover {
  background: var(--dusk-blue);
}
.money-transfer-bank-card__card-clear-button-icon {
  width: 7px;
  height: 7px;
}
.money-transfer-bank-card__card-clear-button-icon path {
  stroke: white;
}
.money-transfer-bank-card__card-error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-bank-card__card-brand-name {
  position: absolute;
  right: 23px;
  bottom: 23px;
  max-width: 40px;
  max-height: 25px;
}
.money-transfer-bank-card__payment-providers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.money-transfer-bank-card__payment-providers_mobile {
  display: none;
  margin-top: 28px;
}
.money-transfer-bank-card__payment-provider_visa {
  width: 45px;
  height: 20px;
  margin-right: 30px;
}
.money-transfer-bank-card__payment-provider_msc {
  width: 31px;
  height: 19px;
  margin-right: 24px;
}
.money-transfer-bank-card__payment-provider_mir {
  width: 46px;
  height: 19px;
  margin-right: 19px;
}
.money-transfer-bank-card__payment-provider_pci {
  width: 44px;
  height: 18px;
}
.money-transfer-bank-card__nav {
  margin-top: 20px;
}

@media (max-width: 980px) {
  .money-transfer-bank-card__background {
    display: none;
  }
  .money-transfer-bank-card__payment-providers_desktop {
    display: none;
  }
  .money-transfer-bank-card__payment-providers_mobile {
    display: flex;
  }
}
@media (min-width: 1px) {
  .money-transfer-bank-card__card-input {
    font-size: 15px;
  }
}
@media (min-width: 375px) {
  .money-transfer-bank-card__card-input {
    font-size: 18px;
  }
}
@media (min-width: 414px) {
  .money-transfer-bank-card__card-input {
    font-size: 22px;
  }
}
.money-transfer-card-header-sz {
  position: relative;
  height: 92px;
  overflow: hidden;
  padding: 36px 36px 20px 36px;
  border-radius: 12px 12px 0 0;
  background-image: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(90, 200, 250) 100%);
}
.money-transfer-card-header-sz:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.money-transfer-card-header-sz__logo {
  position: relative;
  object-fit: contain;
  width: 110px;
  height: 38px;
}
.money-transfer-card-header-sz__bg {
  position: absolute;
  z-index: 1;
  right: -60px;
  top: -176px;
  width: 284px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-sz-card {
  position: relative;
}
.money-transfer-sz-card__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-sz-card__content {
  padding: 20px 23px 20px;
}
.money-transfer-sz-card__wallet-number, .money-transfer-sz-card__message {
  position: relative;
  margin-bottom: 10px;
}
.money-transfer-sz-card__wallet-number-input, .money-transfer-sz-card__message-input {
  -webkit-appearance: none;
          appearance: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-shadow: none;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  font-family: "Circe-Bold", sans-serif;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.money-transfer-sz-card__wallet-number-input::-webkit-input-placeholder, .money-transfer-sz-card__message-input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sz-card__wallet-number-input::-moz-placeholder, .money-transfer-sz-card__message-input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sz-card__wallet-number-input:-moz-placeholder, .money-transfer-sz-card__message-input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sz-card__wallet-number-input:-ms-input-placeholder, .money-transfer-sz-card__message-input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sz-card__wallet-number-input::-ms-input-placeholder, .money-transfer-sz-card__message-input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sz-card__wallet-number-input::-ms-clear, .money-transfer-sz-card__message-input::-ms-clear {
  display: none;
}
.money-transfer-sz-card__wallet-number-input:focus, .money-transfer-sz-card__message-input:focus {
  border-color: var(--dark-slate-blue);
}
.money-transfer-sz-card__wallet-number-input[disabled], .money-transfer-sz-card__message-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  cursor: not-allowed;
}
.money-transfer-sz-card__wallet-number-input[disabled]::-webkit-input-placeholder, .money-transfer-sz-card__message-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba;
}
.money-transfer-sz-card__wallet-number-input[disabled]::-moz-placeholder, .money-transfer-sz-card__message-input[disabled]::-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-sz-card__wallet-number-input[disabled]:-moz-placeholder, .money-transfer-sz-card__message-input[disabled]:-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-sz-card__wallet-number-input[disabled]:-ms-input-placeholder, .money-transfer-sz-card__message-input[disabled]:-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-sz-card__wallet-number-input[disabled]::-ms-input-placeholder, .money-transfer-sz-card__message-input[disabled]::-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-sz-card__wallet-number-input[disabled]::-ms-clear, .money-transfer-sz-card__message-input[disabled]::-ms-clear {
  display: none;
}
.money-transfer-sz-card__wallet-number-icon, .money-transfer-sz-card__message-icon {
  position: absolute;
  right: 15px;
  bottom: 25px;
}
.money-transfer-sz-card__wallet-number-icon path, .money-transfer-sz-card__message-icon path {
  stroke: var(--dark-slate-blue);
}
.money-transfer-sz-card__tip {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-sz-card__error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-sz-card__nav {
  margin-top: 20px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-sbp {
  position: relative;
}
.money-transfer-sbp__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-sbp__content {
  padding: 20px 23px 20px;
}
.money-transfer-sbp__wallet-number {
  position: relative;
  margin-bottom: 10px;
}
.money-transfer-sbp__wallet-number-input {
  -webkit-appearance: none !important;
          appearance: none !important;
  width: 100% !important;
  height: 60px !important;
  padding: 0 20px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  text-align: left !important;
  border: solid 1px rgba(157, 166, 186, 0.2) !important;
  background-color: rgba(228, 232, 238, 0.2) !important;
  font-family: "Circe-Bold", sans-serif;
  font-size: 18px !important;
  color: var(--dark-slate-blue) !important;
}
.money-transfer-sbp__wallet-number-input::-webkit-input-placeholder {
  opacity: 1 !important;
  color: var(--grey-blue) !important;
  font-size: 18px !important;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sbp__wallet-number-input::-moz-placeholder {
  opacity: 1 !important;
  color: var(--grey-blue) !important;
  font-size: 18px !important;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sbp__wallet-number-input:-moz-placeholder {
  opacity: 1 !important;
  color: var(--grey-blue) !important;
  font-size: 18px !important;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sbp__wallet-number-input:-ms-input-placeholder {
  opacity: 1 !important;
  color: var(--grey-blue) !important;
  font-size: 18px !important;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sbp__wallet-number-input::-ms-input-placeholder {
  opacity: 1 !important;
  color: var(--grey-blue) !important;
  font-size: 18px !important;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-sbp__wallet-number-input::-ms-clear {
  display: none !important;
}
.money-transfer-sbp__wallet-number-input:focus {
  border-color: var(--dark-slate-blue) !important;
  background-color: var(--white) !important;
}
.money-transfer-sbp__wallet-number-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2) !important;
  border: 1px solid rgba(157, 166, 186, 0.2) !important;
  cursor: not-allowed !important;
}
.money-transfer-sbp__wallet-number-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba !important;
}
.money-transfer-sbp__wallet-number-input[disabled]::-moz-placeholder {
  color: #9da6ba !important;
}
.money-transfer-sbp__wallet-number-input[disabled]:-moz-placeholder {
  color: #9da6ba !important;
}
.money-transfer-sbp__wallet-number-input[disabled]:-ms-input-placeholder {
  color: #9da6ba !important;
}
.money-transfer-sbp__wallet-number-input[disabled]::-ms-input-placeholder {
  color: #9da6ba !important;
}
.money-transfer-sbp__wallet-number-input[disabled]::-ms-clear {
  display: none !important;
}
.money-transfer-sbp__wallet-number-icon {
  position: absolute;
  right: 15px;
  bottom: 25px;
}
.money-transfer-sbp__wallet-number-icon path {
  stroke: var(--dark-slate-blue);
}
.money-transfer-sbp__error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-sbp__nav {
  margin-top: 20px;
}
.money-transfer-sbp__nav-empty {
  height: 164px;
}
.money-transfer-card-header-sbp {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 22px;
  border-radius: 12px 12px 0 0;
  background-color: #1d1346;
}
.money-transfer-card-header-sbp__logo {
  width: 167px;
  height: 46px;
}
.form-select__value-container {
  padding-left: 20px !important;
}
.form-select__input {
  font-family: "Circe-Regular", sans-serif !important;
  font-weight: normal !important;
  color: var(--dark-slate-blue) !important;
  font-size: 18px !important;
  line-height: 24px !important;
}
.form-select__value-container {
  grid-template-columns: 0 auto !important;
}
.form-select__input-container {
  grid-template-columns: 0 auto !important;
}
.form-select__menu {
  z-index: 99999;
}
.form-select__menu-notice {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
}
.form-select__placeholder {
  font-family: "Circe-Regular", sans-serif !important;
  font-weight: normal !important;
  color: #616a89 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}
.form-select__dropdown-indicator {
  color: #616a89 !important;
}
.form-select__dropdown-indicator:hover {
  color: var(--dark-slate-blue) !important;
}
.form-select__option {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
}
.form-select__option--is-focused {
  background-color: var(--white) !important;
  color: var(--dark-slate-blue) !important;
  cursor: pointer !important;
}
.form-select__option--is-selected {
  background-color: var(--pale-grey) !important;
  color: var(--dark-slate-blue) !important;
}
.form-select__control {
  height: 60px;
  border-radius: 8px !important;
  border: solid 1px rgba(157, 166, 186, 0.2) !important;
  background-color: rgba(228, 232, 238, 0.2) !important;
}
.form-select__control--is-focused {
  border-color: var(--dark-slate-blue) !important;
  background: var(--white) !important;
}
.form-select__control--is-disabled .form-select__placeholder {
  color: #9da6ba !important;
}
.form-select__control--is-disabled .form-select__dropdown-indicator {
  display: none !important;
}
.money-transfer-card-header-umoney {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 34px 26px 31px;
  border-radius: 12px 12px 0 0;
  background-color: #9f48fb;
}
.money-transfer-card-header-umoney__logo {
  width: 120px;
  height: 25px;
}
.money-transfer-card-header-umoney__wallet {
  position: absolute;
  top: 17px;
  right: 23px;
  width: 68px;
  height: 78px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-umoney-card {
  position: relative;
}
.money-transfer-umoney-card__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-umoney-card__content {
  padding: 20px 23px 16px;
}
.money-transfer-umoney-card__wallet-number {
  position: relative;
  margin-bottom: 10px;
}
.money-transfer-umoney-card__wallet-number-input {
  -webkit-appearance: none;
          appearance: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-shadow: none;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  font-family: "Circe-Bold", sans-serif;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.money-transfer-umoney-card__wallet-number-input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-umoney-card__wallet-number-input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-umoney-card__wallet-number-input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-umoney-card__wallet-number-input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-umoney-card__wallet-number-input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-umoney-card__wallet-number-input::-ms-clear {
  display: none;
}
.money-transfer-umoney-card__wallet-number-input:focus {
  border-color: var(--dark-slate-blue);
}
.money-transfer-umoney-card__wallet-number-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  cursor: not-allowed;
}
.money-transfer-umoney-card__wallet-number-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba;
}
.money-transfer-umoney-card__wallet-number-input[disabled]::-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-umoney-card__wallet-number-input[disabled]:-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-umoney-card__wallet-number-input[disabled]:-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-umoney-card__wallet-number-input[disabled]::-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-umoney-card__wallet-number-input[disabled]::-ms-clear {
  display: none;
}
.money-transfer-umoney-card__wallet-number-icon {
  position: absolute;
  right: 15px;
  bottom: 25px;
}
.money-transfer-umoney-card__wallet-number-icon path {
  stroke: var(--dark-slate-blue);
}
.money-transfer-umoney-card__error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-umoney-card__nav {
  margin-top: 20px;
}
.money-transfer-card-header-webmoney {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 36px 36px 20px 36px;
  border-radius: 12px 12px 0 0;
  background-color: #046cb5;
}
.money-transfer-card-header-webmoney__logo {
  width: 107px;
  height: 30px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-webmoney-card {
  position: relative;
}
.money-transfer-webmoney-card__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-webmoney-card__content {
  padding: 20px 23px 16px;
}
.money-transfer-webmoney-card__wallet-number {
  position: relative;
  margin-bottom: 10px;
}
.money-transfer-webmoney-card__wallet-number-input {
  -webkit-appearance: none;
          appearance: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-shadow: none;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  font-family: "Circe-Bold", sans-serif;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.money-transfer-webmoney-card__wallet-number-input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-webmoney-card__wallet-number-input::-ms-clear {
  display: none;
}
.money-transfer-webmoney-card__wallet-number-input:focus {
  border-color: var(--dark-slate-blue);
}
.money-transfer-webmoney-card__wallet-number-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  cursor: not-allowed;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]:-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]:-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-webmoney-card__wallet-number-input[disabled]::-ms-clear {
  display: none;
}
.money-transfer-webmoney-card__wallet-number-icon {
  position: absolute;
  right: 15px;
  bottom: 25px;
}
.money-transfer-webmoney-card__wallet-number-icon path {
  stroke: var(--dark-slate-blue);
}
.money-transfer-webmoney-card__error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-webmoney-card__nav {
  margin-top: 20px;
}
.money-transfer-card-header-qiwi {
  position: relative;
  overflow: hidden;
  height: 92px;
  padding: 29px 36px 20px 30px;
  border-radius: 12px 12px 0 0;
  background-color: #ff8c00;
}
.money-transfer-card-header-qiwi__logo {
  width: 100px;
  height: 45px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-qiwi-card {
  position: relative;
}
.money-transfer-qiwi-card__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-qiwi-card__content {
  padding: 20px 23px 16px;
}
.money-transfer-qiwi-card__wallet-number {
  position: relative;
  margin-bottom: 10px;
}
.money-transfer-qiwi-card__wallet-number-input {
  -webkit-appearance: none;
          appearance: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-shadow: none;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  font-family: "Circe-Bold", sans-serif;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.money-transfer-qiwi-card__wallet-number-input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-qiwi-card__wallet-number-input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-qiwi-card__wallet-number-input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-qiwi-card__wallet-number-input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-qiwi-card__wallet-number-input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.money-transfer-qiwi-card__wallet-number-input::-ms-clear {
  display: none;
}
.money-transfer-qiwi-card__wallet-number-input:focus {
  border-color: var(--dark-slate-blue);
}
.money-transfer-qiwi-card__wallet-number-input[disabled] {
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  cursor: not-allowed;
}
.money-transfer-qiwi-card__wallet-number-input[disabled]::-webkit-input-placeholder {
  color: #9da6ba;
}
.money-transfer-qiwi-card__wallet-number-input[disabled]::-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-qiwi-card__wallet-number-input[disabled]:-moz-placeholder {
  color: #9da6ba;
}
.money-transfer-qiwi-card__wallet-number-input[disabled]:-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-qiwi-card__wallet-number-input[disabled]::-ms-input-placeholder {
  color: #9da6ba;
}
.money-transfer-qiwi-card__wallet-number-input[disabled]::-ms-clear {
  display: none;
}
.money-transfer-qiwi-card__wallet-number-icon {
  position: absolute;
  right: 15px;
  bottom: 25px;
}
.money-transfer-qiwi-card__wallet-number-icon path {
  stroke: var(--dark-slate-blue);
}
.money-transfer-qiwi-card__error {
  padding-top: 10px;
  font-size: 12px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-qiwi-card__nav {
  margin-top: 20px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-receipt-card__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-receipt-card__nav {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.money-transfer-receipt-card__nav_back-button, .money-transfer-receipt-card__nav_pay-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  transition: all 0.3s ease;
  font-size: 16px;
  color: #fff;
}
.money-transfer-receipt-card__nav_back-button {
  border: 1px solid var(--grey-blue);
  background-color: #fff;
  color: var(--grey-blue);
}
.money-transfer-receipt-card__nav_back-button:hover {
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.money-transfer-receipt-card__nav_pay-button {
  background-color: var(--dusk-blue);
}
.money-transfer-receipt-card__nav_pay-button:hover {
  background-color: var(--dark-slate-blue);
}
.money-transfer-receipt-card__nav_pay-button:disabled {
  background-color: rgba(40, 65, 149, 0.1);
  cursor: not-allowed;
}

@media (max-width: 980px) {
  .money-transfer-receipt-card__nav {
    flex-direction: column-reverse;
  }
  .money-transfer-receipt-card__nav_back-button, .money-transfer-receipt-card__nav_pay-button {
    width: 100%;
    border-radius: 30px;
  }
  .money-transfer-receipt-card__nav_back-button {
    margin-top: 20px;
  }
}
.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  width: 60px;
  height: 60px;
}
.spinner .path {
  stroke: var(--bluey-grey);
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}
.spinner .total {
  stroke: var(--dusk-blue);
  opacity: 0.1;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.code-input {
  position: relative;
}
.code-input > input {
  width: 50px;
  height: 56px;
  margin-left: 16px;
  padding: 0;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.code-input > input:first-child {
  margin-left: 0;
}
.code-input > input:not(:-ms-input-placeholder):valid {
  border-color: #1d2e69;
  box-shadow: none;
}
.code-input > input:not(:placeholder-shown):valid {
  border-color: #1d2e69;
  box-shadow: none;
}
.code-input > input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code-input > input[type=number] {
  -moz-appearance: textfield;
}
.code-input_invalid > input {
  border-color: #ff2d55;
  box-shadow: none;
}
.code-input_invalid > input::-webkit-input-placeholder {
  opacity: 1;
  font-size: 10px;
  color: #ff2d55;
}
.code-input_invalid > input:-ms-input-placeholder {
  opacity: 1;
  font-size: 10px;
  color: #ff2d55;
}
.code-input_invalid > input::placeholder {
  opacity: 1;
  font-size: 10px;
  color: #ff2d55;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-confirmation-card__card {
  box-shadow: 0 4px 27px #e8ecf2;
}
.money-transfer-confirmation-card__header {
  padding: 20px;
}
.money-transfer-confirmation-card__header-row {
  display: flex;
  align-items: center;
}
.money-transfer-confirmation-card__header-row-title, .money-transfer-confirmation-card__header-row-value {
  flex-basis: 100%;
  line-height: 16px;
  font-size: 12px;
  color: #b0b5c4;
}
.money-transfer-confirmation-card__header-row-title {
  opacity: 0.7;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-confirmation-card__header-row-value {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.money-transfer-confirmation-card__header-row:last-child {
  margin-top: 4px;
}
.money-transfer-confirmation-card__content {
  padding: 30px 0;
  border-top: 1px solid #e4e8ee;
  text-align: center;
}
.money-transfer-confirmation-card__content-title {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-family: "Circe-Bold", sans-serif;
  font-size: 14px;
  color: #1d2e69;
}
.money-transfer-confirmation-card__content-loader {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}
.money-transfer-confirmation-card__content-notice {
  margin-top: 4px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #616a88;
}
.money-transfer-confirmation-card__content-change-method-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
  line-height: 20px;
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: #007aff;
}
.money-transfer-confirmation-card__content-input {
  margin-top: 20px;
}
.money-transfer-confirmation-card__content-info {
  margin-top: 20px;
}
.money-transfer-confirmation-card__content-info-message, .money-transfer-confirmation-card__content-info-message1 {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  text-alin: center;
  font-size: 14px;
  color: #b0b5c4;
}
.money-transfer-confirmation-card__content-info-message1 {
  color: #616a88;
}
.money-transfer-confirmation-card__content-info-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-top: 8px;
  line-height: 20px;
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: #007aff;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-status-loader-card {
  box-shadow: 0 4px 27px #e8ecf2;
}
.money-transfer-status-loader-card__content {
  padding: 30px 20px 32px;
}
.money-transfer-status-loader-card__content-loader {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 auto 16px;
}
.money-transfer-status-loader-card__content-loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 27px;
  height: 22px;
  margin-top: -11px;
  margin-left: -13px;
}
.money-transfer-status-loader-card__content-message {
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-status-error-card__card {
  box-shadow: 0 4px 27px #e8ecf2;
}
.money-transfer-status-error-card__content {
  padding: 30px 20px 32px;
}
.money-transfer-status-error-card__content-loader {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 auto 16px;
}
.money-transfer-status-error-card__content-loader-circle {
  width: 60px;
  height: 60px;
}
.money-transfer-status-error-card__content-loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 27px;
  height: 22px;
  margin-top: -11px;
  margin-left: -13px;
}
.money-transfer-status-error-card__content-loader-icon path {
  fill: var(--reddish-pink);
}
.money-transfer-status-error-card__content-message {
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-transfer-status-error-card__nav {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.money-transfer-status-error-card__nav-lk-button, .money-transfer-status-error-card__nav-retry-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  transition: all 0.3s ease;
  font-size: 16px;
  color: #fff;
}
.money-transfer-status-error-card__nav-lk-button {
  border: 1px solid var(--grey-blue);
  background-color: #fff;
  color: var(--grey-blue);
}
.money-transfer-status-error-card__nav-lk-button:hover {
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.money-transfer-status-error-card__nav-retry-button {
  background-color: var(--dusk-blue);
}
.money-transfer-status-error-card__nav-retry-button:hover {
  background-color: var(--dark-slate-blue);
}
.money-transfer-status-error-card__nav-retry-button:disabled {
  background-color: rgba(40, 65, 149, 0.1);
  cursor: not-allowed;
}

@media (max-width: 980px) {
  .money-transfer-status-error-card__nav {
    flex-direction: column-reverse;
  }
  .money-transfer-status-error-card__nav-lk-button, .money-transfer-status-error-card__nav-retry-button {
    width: 100%;
    border-radius: 30px;
  }
  .money-transfer-status-error-card__nav-lk-button {
    margin-top: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer-status-success-card {
  position: relative;
  width: 400px;
}
.money-transfer-status-success-card__card {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.money-transfer-status-success-card__header {
  display: flex;
  padding: 32px 20px 0 20px;
}
.money-transfer-status-success-card__header-icon {
  position: relative;
  width: 44px;
  height: 44px;
  min-width: 44px;
  margin-right: 27px;
}
.money-transfer-status-success-card__header-icon__circle {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.money-transfer-status-success-card__header-icon__check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 10px;
  margin-top: -5px;
  margin-left: -7px;
}
.money-transfer-status-success-card__header-icon__check-icon path {
  stroke: var(--turquoise-blue);
}
.money-transfer-status-success-card__header-text > p:first-child {
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
.money-transfer-status-success-card__header-text > p:last-child {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
}
.money-transfer-status-success-card__content {
  margin-top: 10px;
}
.money-transfer-status-success-card__nav {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.money-transfer-status-success-card__nav > button:first-child, .money-transfer-status-success-card__nav > button:last-child {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  transition: all 0.3s ease;
  font-size: 16px;
  color: #fff;
}
.money-transfer-status-success-card__nav > button:first-child {
  border: 1px solid var(--grey-blue);
  background-color: #fff;
  color: var(--grey-blue);
}
.money-transfer-status-success-card__nav > button:first-child:hover {
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.money-transfer-status-success-card__nav > button:last-child {
  background-color: var(--dusk-blue);
}
.money-transfer-status-success-card__nav > button:last-child:hover {
  background-color: var(--dark-slate-blue);
}
.money-transfer-status-success-card__notice {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.money-transfer-status-success-card__notice > p {
  padding: 0 49px;
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  color: #616a88;
}
.money-transfer-status-success-card__notice > p > a {
  color: #007aff;
}

@media (max-width: 980px) {
  .money-transfer-status-success-card {
    width: 100%;
  }
  .money-transfer-status-success-card__nav {
    flex-direction: column-reverse;
  }
  .money-transfer-status-success-card__nav > button:first-child, .money-transfer-status-success-card__nav > button:last-child {
    width: 100%;
    border-radius: 30px;
  }
  .money-transfer-status-success-card__nav > button:first-child {
    margin-top: 20px;
  }
}

.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer__content {
  position: relative;
}
.money-transfer__content-body {
  display: flex;
  margin-top: 40px;
}

@media (max-width: 980px) {
  .money-transfer__content-body {
    margin-top: 36px;
  }
  .money-transfer__content-mobile {
    display: inline;
    display: initial;
    width: 100%;
  }
  .money-transfer__content-not-mobile {
    display: none;
  }
}
.money-transfer {
  position: fixed;
  display: flex;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.money-transfer__wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 50px 100px;
  height: 100%;
}
.money-transfer__loader {
  width: 100%;
  height: 100%;
}
.money-transfer__error {
  width: 100%;
  height: 100%;
  flex-direction: column;
  grid-gap: 52px;
  gap: 52px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-slate-blue);
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe", sans-serif;
  font-weight: 400;
}
.money-transfer__error-img {
  width: 40px;
  height: 46px;
}
.money-transfer__error-text {
  max-width: 330px;
}
.money-transfer__error-text-support {
  text-decoration: underline;
  text-underline-offset: 4px;
}

@media (max-width: 980px) {
  .money-transfer__wrapper {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-request-card {
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  background-color: white;
  overflow: hidden;
  min-height: 390px;
  display: flex;
  flex-direction: column;
}
.money-request-card__header {
  padding: 29px 30px;
  min-height: 118px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.money-request-card__header-icon-wrap {
  width: 60px;
  height: 60px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.money-request-card__content {
  padding: 30px 30px 42px 30px;
  display: flex;
  flex-grow: 20;
}
.money-request-card__content_error {
  flex-direction: column;
  padding-top: 0;
}
.money-request-card__left-part {
  width: 52%;
  min-width: 52%;
  margin-right: 20px;
  flex-grow: 20;
}
.money-request-card__input-holder {
  margin-bottom: 10px;
  position: relative;
}
.money-request-card__input-holder:last-child {
  margin-bottom: 0;
}
.money-request-card__input {
  height: 60px;
  border-radius: 8px;
  background-color: rgba(228, 232, 238, 0.2);
  width: 100%;
  border: 1px solid rgba(228, 232, 238, 0.2);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 10px 40px 0 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.money-request-card__input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.money-request-card__input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.money-request-card__input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.money-request-card__input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.money-request-card__input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.money-request-card__input::-ms-clear {
  display: none;
}
.money-request-card__input:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.money-request-card__input[type=number]::-webkit-outer-spin-button, .money-request-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.money-request-card__input[type=number] {
  -moz-appearance: textfield;
}
.money-request-card__input-label {
  font-size: 8px;
  text-transform: uppercase;
  color: #6b778d;
  position: absolute;
  left: 20px;
  top: 7px;
  font-family: "Circe-Bold", sans-serif;
}
.money-request-card__input-check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.money-request-card__input-check path {
  stroke: var(--dark-slate-blue);
}
.money-request-card__right-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 20;
}
.money-request-card__tabs {
  display: flex;
  margin-bottom: 10px;
  height: 36px;
}
.money-request-card__tabs-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 36px;
  width: 119px;
  border: 1px solid var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.money-request-card__tabs-item:first-child {
  border-radius: 18px 0 0 18px;
  border-right: none;
}
.money-request-card__tabs-item:last-child {
  border-radius: 0 18px 18px 0;
  border-left: none;
}
.money-request-card__tabs-item_active {
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  color: white;
  border: transparent;
}
.money-request-card__tip {
  font-size: 14px;
  line-height: 1.71;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.money-request-card__main .money-request-card__tip {
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 20px;
}
.money-request-card__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
}
.money-request-card__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.money-request-card__info {
  width: 45%;
  min-width: 45%;
}
.money-request-card__info-block {
  margin-bottom: 15px;
}
.money-request-card__info-block:last-child {
  margin-bottom: 0;
}
.money-request-card__info-block-label {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.money-request-card__info-block-value {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  word-break: break-all;
}
.money-request-card__main {
  width: 55%;
  min-width: 55%;
  text-align: right;
}
.money-request-card__link-block {
  margin-bottom: 34px;
  width: 100%;
  max-width: 100%;
}
.money-request-card__link-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  width: 100%;
  max-width: 100%;
}
.money-request-card__link-icon {
  margin-right: 10px;
  width: 12px;
  height: 14px;
  min-width: 12px;
}
.money-request-card__link-icon path {
  transition: all 0.3s ease;
}
.money-request-card__link-wrap:hover .money-request-card__link-icon path {
  fill: var(--deep-sky-blue);
}
.money-request-card__link {
  font-size: 14px;
  color: var(--dark-slate-blue);
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: left;
  transition: all 0.3s ease;
  max-width: calc(100% - 12px - 10px);
  word-break: break-all;
}
.money-request-card__link-wrap:hover .money-request-card__link {
  color: var(--deep-sky-blue);
}
.money-request-card__link-share-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.money-request-card__link-share-block_with-copy {
  justify-content: space-between;
}
.money-request-card__copy-success {
  width: 100px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  background: rgba(0, 173, 181, 0.1);
  border-radius: 10px;
  font-size: 12px;
  color: var(--turquoise-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-request-card__link-share-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.money-request-card__link-share-item {
  margin-right: 10px;
  padding: 0;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  outline: none;
}
.money-request-card__link-share-item-icon {
  width: 20px;
  height: 15px;
}
.money-request-card__link-share-item-icon_envelope {
  height: 14px;
}
.money-request-card__link-share-item-icon_envelope g {
  transition: all 0.3s ease;
}
.money-request-card__link-share-item:hover .money-request-card__link-share-item-icon_envelope g {
  fill: var(--dark-slate-blue);
}
.money-request-card__link-share-item-icon_share {
  width: 15px;
  height: 14px;
}
.money-request-card__link-share-item-icon_share path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.money-request-card__link-share-item:hover .money-request-card__link-share-item-icon_share path {
  fill: var(--dark-slate-blue);
}
.money-request-card__link-share-item-icon_vk path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.money-request-card__link-share-item:hover .money-request-card__link-share-item-icon_vk path {
  fill: var(--dark-slate-blue);
}
.money-request-card__link-share-item-icon_ok path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.money-request-card__link-share-item:hover .money-request-card__link-share-item-icon_ok path {
  fill: var(--dark-slate-blue);
}
.money-request-card__link-share-item-icon_wa path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.money-request-card__link-share-item:hover .money-request-card__link-share-item-icon_wa path {
  fill: var(--dark-slate-blue);
}
.money-request-card__link-share-item-icon_fb path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.money-request-card__link-share-item:hover .money-request-card__link-share-item-icon_fb path {
  fill: var(--dark-slate-blue);
}
.money-request-card__link-share-item-icon_tg path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.money-request-card__link-share-item:hover .money-request-card__link-share-item-icon_tg path {
  fill: var(--dark-slate-blue);
}
.money-request-card__requests-history-link {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.money-request-card__requests-history-link:hover {
  border-bottom-color: transparent;
}
.money-request-card__error-icon-wrap {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -45px auto 30px auto;
  position: relative;
}
.money-request-card__error-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -6px;
}
.money-request-card__error-icon path {
  fill: var(--reddish-pink);
}
.money-request-card__error-message {
  font-size: 12px;
  color: var(--bluey-grey);
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 53px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.money-request-card__error-message-link {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.money-request-card__error-message-link:hover {
  text-decoration: none;
}
.money-request-card__error-link-wrap {
  display: flex;
  justify-content: center;
}
.money-request-card__error-link {
  width: 240px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.money-request-card__error-link:hover {
  background: var(--dusk-blue);
}

@media screen and (max-width: 980px) {
  .money-request-card {
    border-radius: 0;
    box-shadow: none;
    min-height: auto;
    background: transparent;
    overflow: visible;
  }
  .money-request-card__content {
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
  }
  .money-request-card__content_error {
    flex-direction: column;
    padding-top: 0;
    margin-top: -45px;
  }
  .money-request-card__left-part {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .money-request-card__input-holder {
    margin-bottom: 20px;
  }
  .money-request-card__right-part {
    justify-content: flex-start;
    margin-bottom: 36px;
  }
  .money-request-card__tabs {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 36px;
  }
  .money-request-card__tip {
    text-align: center;
  }
  .money-request-card__main .money-request-card__tip {
    width: 100%;
  }
  .money-request-card__info .money-request-card__tip {
    margin-bottom: 20px;
    text-align: left;
  }
  .money-request-card__button {
    border-radius: 30px;
    box-shadow: 0 4px 27px 0 #e8ecf2;
    font-size: 16px;
    font-family: "Circe-Bold", sans-serif;
  }
  .money-request-card__info {
    width: 100%;
    min-width: 100%;
  }
  .money-request-card__info-block {
    margin-bottom: 15px;
    display: flex;
  }
  .money-request-card__info-block:last-child {
    margin-bottom: 0;
  }
  .money-request-card__info-block-label {
    margin-bottom: 0;
    margin-right: 5px;
  }
  .money-request-card__info-block-label:after {
    content: ":";
    font-size: 16px;
    color: var(--bluey-grey);
    font-family: "Rubik", sans-serif;
    font-weight: 400;
  }
  .money-request-card__main {
    width: 100%;
    min-width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .money-request-card__link-block {
    margin-bottom: 0;
  }
  .money-request-card__link-wrap {
    justify-content: center;
    width: 100%;
  }
  .money-request-card__link-icon {
    min-width: 12px;
  }
  .money-request-card__link-icon path {
    fill: var(--bluey-grey);
  }
  .money-request-card__link {
    color: var(--bluey-grey);
    word-wrap: break-word;
  }
  .money-request-card__link-share-block {
    justify-content: center;
    flex-direction: column;
  }
  .money-request-card__link-share-block_with-copy {
    justify-content: center;
  }
  .money-request-card__copy-success {
    margin-bottom: 10px;
  }
  .money-request-card__link-share-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .money-request-card__link-share-item {
    margin-right: 10px;
    padding: 0;
    border: none;
    cursor: pointer;
    box-shadow: none;
    background: transparent;
  }
  .money-request-card__link-share-item-icon_envelope g {
    fill: var(--grey-blue);
  }
  .money-request-card__link-share-item-icon_share path {
    fill: var(--grey-blue);
  }
  .money-request-card__link-share-item-icon_vk path {
    fill: var(--grey-blue);
  }
  .money-request-card__link-share-item-icon_wa path {
    fill: var(--grey-blue);
  }
  .money-request-card__link-share-item-icon_ok path {
    fill: var(--grey-blue);
  }
  .money-request-card__link-share-item-icon_fb path {
    fill: var(--grey-blue);
  }
  .money-request-card__link-share-item-icon_tg path {
    fill: var(--grey-blue);
  }
  .money-request-card__requests-history-link-wrap {
    display: flex;
    justify-content: center;
  }
  .money-request-card__error-message {
    -webkit-filter: drop-shadow(0 4px 27px #e8ecf2);
            filter: drop-shadow(0 4px 27px #e8ecf2);
    background: white;
    padding: 20px 30px;
    border-radius: 15px;
    position: relative;
    text-align: left;
    line-height: 1.2;
    font-size: 16px;
    color: var(--dark-slate-blue);
  }
  .money-request-card__error-message-triangle {
    width: 29px;
    height: 23px;
    position: absolute;
    bottom: -23px;
    left: 22px;
  }
  .money-request-card__error-message-link {
    font-size: 16px;
  }
  .money-request-card__error-icon-wrap {
    margin-top: 0;
  }
  .money-request-card__error-link {
    border-radius: 30px;
    width: 100%;
    min-width: 240px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-request {
  max-width: 1300px;
  padding: 50px 50px 130px 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.money-request__top {
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 39px;
}
.money-request__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.money-request__content {
  display: flex;
}
.money-request__card-wrap {
  width: 600px;
  min-width: 600px;
  margin-right: 60px;
}
.money-request__card-link-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.money-request__card-link {
  width: 252px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.money-request__card-link:hover {
  background: var(--dusk-blue);
}
.money-request__header {
  display: none;
}

@media screen and (max-width: 1194px) {
  .money-request__card-wrap {
    width: 580px;
    min-width: 580px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 980px) {
  .money-request {
    padding: 0 0 100px 0;
    margin-top: -90px;
  }
  .money-request__header {
    display: block;
    background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
    min-height: 220px;
    margin-bottom: 36px;
    position: relative;
    overflow: hidden;
    padding: 90px 50px 35px 50px;
  }
  .money-request__header_fail {
    margin-bottom: 0;
  }
  .money-request__header__bg {
    position: absolute;
    right: -80px;
    bottom: -30px;
    width: 260px;
    height: 320px;
    object-fit: contain;
    z-index: 10;
  }
  .money-request__header-icon-wrap {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .money-request__header-title {
    font-size: 28px;
    line-height: 1.29;
    font-family: "Circe-Bold", sans-serif;
    color: white;
    position: relative;
    z-index: 20;
  }
  .money-request__content {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    position: relative;
    z-index: 20;
  }
  .money-request__card-link-wrap {
    display: flex;
    justify-content: center;
  }
  .money-request__card-link {
    border-radius: 30px;
    max-width: 375px;
    width: 100%;
  }
  .money-request__card-wrap {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 800px) {
  .money-request {
    margin-top: -70px;
  }
  .money-request__header {
    padding: 70px 50px 35px 50px;
  }
}
@media screen and (max-width: 600px) {
  .money-request__header {
    padding: 70px 20px 35px 20px;
  }
}
@media screen and (max-width: 350px) {
  .money-request__header {
    padding: 70px 14px 35px 14px;
  }
  .money-request__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.popup-page-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.popup-page-wrap__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 20;
  flex-shrink: 0;
}
.popup-page-wrap__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popup-page-wrap__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.popup-page-wrap__header-wrap_no-header {
  justify-content: flex-end;
}
.popup-page-wrap__title-wrap {
  margin-bottom: 40px;
}
.popup-page-wrap__title-container {
  display: flex;
  align-items: center;
  padding-bottom: 9px;
}
@media (min-width: 601px) {
  .popup-page-wrap__title-container {
    border-bottom: 1px solid rgba(176, 181, 196, 0.5);
  }
}
.popup-page-wrap__title {
  font-size: 36px;
  line-height: 48px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.popup-page-wrap__icon-wrap {
  margin-left: 32px;
  margin-bottom: 6px;
}
.popup-page-wrap__content {
  display: flex;
}
.popup-page-wrap__main {
  width: 65.83%;
  min-width: 65.83%;
  margin-right: 30px;
  position: relative;
  z-index: 20;
}
.popup-page-wrap__main_full-width {
  width: 100%;
  min-width: 100%;
  margin-right: 0;
}
.popup-page-wrap__hints {
  position: relative;
  z-index: 10;
}
.popup-page-wrap__hints-title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 24px;
}

@media screen and (max-width: 1194px) {
  .popup-page-wrap__content {
    flex-direction: column;
    flex-grow: 20;
  }
  .popup-page-wrap__main {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    flex-grow: 20;
  }
  .popup-page-wrap__main_full-width {
    margin-bottom: 0;
  }
  .popup-page-wrap__hints {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .popup-page-wrap__container {
    padding-top: 20px;
  }
  .popup-page-wrap__header {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .popup-page-wrap__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .popup-page-wrap__title-wrap {
    width: calc(100% + 20px + 20px);
    margin: 0 -20px 40px;
  }
}
@media screen and (max-width: 600px) and (max-width: 601px) {
  .popup-page-wrap__title-wrap {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 600px) {
  .popup-page-wrap__title-container {
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .popup-page-wrap__title {
    font-size: 28px;
    margin-bottom: 25px;
    line-height: 32px;
  }
}
@media screen and (max-width: 600px) {
  .popup-page-wrap__icon-wrap {
    margin-left: 0;
    order: -1;
    margin-bottom: 16px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-getting-step {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background: #f6f8fb;
  border-radius: 12px;
  height: 68px;
  padding: 12px 12px 12px 48px;
  text-align: left;
  width: 100%;
  position: relative;
  border: 1px solid #f6f8fb;
}
.acquiring-getting-step:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: -4px;
  background: var(--light-grey-blue);
}
.acquiring-getting-step_active {
  background: #ffffff;
  border-color: white;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  cursor: default;
}
.acquiring-getting-step_active:before {
  background: var(--deep-sky-blue);
}
.acquiring-getting-step_gotten {
  border-color: var(--turquoise-blue);
  background: white;
}
.acquiring-getting-step_gotten:before {
  background: var(--turquoise-blue);
}
.acquiring-getting-step:disabled {
  cursor: not-allowed;
}
.acquiring-getting-step__text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 6px;
  color: var(--light-grey-blue);
}
.acquiring-getting-step__title {
  font-size: 18px;
  color: var(--light-grey-blue);
  font-family: "Circe-Bold", sans-serif;
}
.acquiring-getting-step_active .acquiring-getting-step__title {
  color: var(--dark-slate-blue);
}
.acquiring-getting-step_gotten .acquiring-getting-step__title {
  color: var(--dark-slate-blue);
}
.acquiring-getting-step__check-icon {
  position: absolute;
  width: 10px;
  height: 8px;
  right: 24px;
  top: 30px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-settings-step-1 {
  width: 100%;
}
.acquiring-settings-step-1__title {
  margin-bottom: 50px;
}
.acquiring-settings-step-1__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.acquiring-settings-step-1__form {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.acquiring-settings-step-1__documentation {
  margin-bottom: 16px;
}
.acquiring-settings-step-1__documentation-item {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
}
.acquiring-settings-step-1__status-title-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.acquiring-settings-step-1__status-title {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  margin-right: 8px;
}
.acquiring-settings-step-1__status-title-button-wrap {
  width: 16px;
  height: 16px;
  position: relative;
}
.acquiring-settings-step-1__status-title-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.acquiring-settings-step-1__status-title-dropdown {
  position: absolute;
  background: #616a88;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  right: -20px;
  top: 32px;
  width: 290px;
  z-index: 20;
}
.acquiring-settings-step-1__status-title-dropdown:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: #616a88;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: 21px;
  top: -7px;
}
.acquiring-settings-step-1__status-title-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.acquiring-settings-step-1__status-title-icon path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-1__status-list-wrap {
  margin-bottom: 32px;
}
.acquiring-settings-step-1__status-list {
  display: flex;
  width: 100%;
}
.acquiring-settings-step-1__status-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  border-radius: 8px;
  font-size: 14px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
}
.acquiring-settings-step-1__status-item:last-child {
  margin-right: 0;
}
.acquiring-settings-step-1__status-item:disabled {
  cursor: default;
}
.acquiring-settings-step-1__status-item_active {
  background: white;
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.acquiring-settings-step-1__status-item-icon {
  height: 12px;
  margin-right: 10px;
}
.acquiring-settings-step-1__status-item-icon_user path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-1__status-item_active .acquiring-settings-step-1__status-item-icon_user path {
  fill: var(--dark-slate-blue);
}
.acquiring-settings-step-1__status-item-icon_bag path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-1__status-item_active .acquiring-settings-step-1__status-item-icon_bag path {
  fill: var(--dark-slate-blue);
}
.acquiring-settings-step-1__individual-warning {
  margin-top: 10px;
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: var(--turquoise-blue);
}
.acquiring-settings-step-1__input {
  margin-bottom: 20px;
}
.acquiring-settings-step-1__textarea {
  margin-bottom: 18px;
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea {
  height: 120px;
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-webkit-input-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-moz-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea:-moz-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea:-ms-input-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-ms-input-placeholder {
  opacity: 1;
  color: var(--light-grey-blue);
}
.acquiring-settings-step-1__textarea .textarea-with-label-animation__textarea::-ms-clear {
  display: none;
}
.acquiring-settings-step-1__buttons-list {
  display: flex;
}
.acquiring-settings-step-1__buttons-item {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
}
.acquiring-settings-step-1__buttons-item:last-child {
  margin-right: 0;
}
.acquiring-settings-step-1__message-wrap {
  width: calc(100% - 350px - 60px);
  margin-top: -7px;
}
.acquiring-settings-step-1__message {
  border-radius: 8px;
  margin-bottom: 20px;
}
.acquiring-settings-step-1__message:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-1__message:only-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .acquiring-settings-step-1__content {
    flex-direction: column-reverse;
  }
  .acquiring-settings-step-1__form {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .acquiring-settings-step-1__message-wrap {
    width: 100%;
    margin-top: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 980px) {
  .acquiring-settings-step-1__status-title-dropdown {
    right: auto;
    left: -22px;
  }
  .acquiring-settings-step-1__status-title-dropdown:after {
    left: 21px;
    right: auto;
  }
}
@media screen and (max-width: 400px) {
  .acquiring-settings-step-1__status-title-dropdown {
    left: -100px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-settings-step-2__title {
  margin-bottom: 22px;
}
.acquiring-settings-step-2__content {
  margin-bottom: 64px;
}
.acquiring-settings-step-2__block {
  margin-bottom: 33px;
}
.acquiring-settings-step-2__block:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-2__text {
  font-size: 18px;
  line-height: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 26px;
  max-width: 93%;
  width: 100%;
}
.acquiring-settings-step-2__text:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-2__block-title {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 19px;
}
.acquiring-settings-step-2__block-list-item {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  padding-left: 16px;
  position: relative;
}
.acquiring-settings-step-2__block-list-item:last-child {
  margin-bottom: 0;
}
.acquiring-settings-step-2__block-list-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--dark-slate-blue);
}
.acquiring-settings-step-2__block-link-wrap {
  margin-right: 5px;
}
.acquiring-settings-step-2__block-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--deep-sky-blue);
  word-break: break-word;
}
.acquiring-settings-step-2__block-link:hover {
  text-decoration: none;
}
.acquiring-settings-step-2__loader {
  display: inline-block;
  margin-left: 5px;
}
.acquiring-settings-step-2__code-wrap {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  text-align: left;
  position: relative;
}
.acquiring-settings-step-2__code {
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 24px;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 13px;
  word-break: break-all;
}
.acquiring-settings-step-2__code-copy-icon {
  margin-top: 2px;
}
.acquiring-settings-step-2__code-copy-icon path {
  fill: var(--grey-blue);
}
.acquiring-settings-step-2__error-message {
  margin-bottom: 40px;
  border-radius: 8px;
  max-width: 540px;
  width: 100%;
  margin-top: -24px;
}
.acquiring-settings-step-2__buttons-list {
  display: flex;
}
.acquiring-settings-step-2__buttons-item {
  width: 165px;
  margin-right: 20px;
}
.acquiring-settings-step-2__buttons-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 480px) {
  .acquiring-settings-step-2__buttons-list {
    flex-direction: column-reverse;
  }
  .acquiring-settings-step-2__buttons-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .acquiring-settings-step-2__buttons-item:first-child {
    margin-bottom: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-settings {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.acquiring-settings__steps {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.acquiring-settings__steps-item {
  margin-bottom: 30px;
}
.acquiring-settings__steps-item:last-child {
  margin-bottom: 0;
}
.acquiring-settings__main {
  width: calc(100% - 350px - 60px);
  min-width: calc(100% - 350px - 60px);
  padding-top: 5px;
}

@media screen and (max-width: 980px) {
  .acquiring-settings {
    flex-direction: column;
  }
  .acquiring-settings__steps {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .acquiring-settings__main {
    width: 100%;
    min-width: 100%;
    padding-top: 0;
  }
}
.acquiring-settings-wrap .popup-page-wrap__header-wrap {
  margin-bottom: 32px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-form {
  width: 100%;
}
.show-case-form__tabs {
  margin-bottom: 40px;
}
.show-case-form__block {
  margin-bottom: 42px;
}
.show-case-form__block_info {
  margin-bottom: 40px;
}
.show-case-form__form-nav {
  margin-bottom: 20px;
}
.show-case-form__block-title {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 14px;
}
.show-case-form__block-text {
  font-size: 16px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.2;
  margin-bottom: 20px;
}
.show-case-form__checkbox-list {
  display: flex;
  flex-wrap: wrap;
}
.show-case-form__checkbox-wrap {
  margin-top: 12px;
}
.show-case-form__checkbox-list .show-case-form__checkbox-wrap {
  width: auto;
  margin-right: 33px;
}
.show-case-form__checkbox-list .show-case-form__checkbox-wrap:last-child {
  margin-right: 0;
}
.show-case-form__button-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.show-case-form__button {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .show-case-form__checkbox-list .show-case-form__checkbox-wrap {
    margin-right: 35px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-mobile-popup {
  padding: 20px;
}
.show-case-mobile-popup #react-aria-modal-dialog {
  width: 100%;
}
.show-case-mobile-popup__header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.show-case-mobile-popup__arrow-wrap {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-case-mobile-popup__arrow {
  width: 15px;
  height: 30px;
}
.show-case-mobile-popup__arrow path {
  stroke: var(--dark-slate-blue);
}
.show-case-mobile-popup__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  text-decoration: none;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.show-case-mobile-popup__title {
  font-size: 28px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 25px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.show-case-creation {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.show-case-creation__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.show-case-creation__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.show-case-creation__header {
  margin-bottom: 55px;
}
.show-case-creation__header-logo-wrap {
  text-decoration: none;
  width: 38px;
  height: 33px;
  max-width: 38px;
  max-height: 33px;
}
.show-case-creation__header-logo {
  display: none;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.show-case-creation__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.show-case-creation__form-wrap {
  min-width: 380px;
  max-width: 380px;
  width: 100%;
}
.show-case-creation__form-nav {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.show-case-creation__form-nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 50%;
  min-width: 50%;
  height: 40px;
  line-height: 39px;
  border: 1px solid #e4e8ee;
  background-color: var(--pale-grey);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #616a88;
  text-align: center;
}
.show-case-creation__form-nav-item:first-child {
  border-radius: 8px 0 0 8px;
}
.show-case-creation__form-nav-item:last-child {
  border-radius: 0 8px 8px 0;
}
.show-case-creation__form-nav-item_active {
  background-color: var(--dark-slate-blue);
  color: white;
}
.show-case-creation__result {
  flex-grow: 10;
  width: calc(100% - 380px);
  padding-left: 132px;
  padding-top: 12px;
}
.show-case-creation__result-nav {
  margin-bottom: 20px;
}
.show-case-creation__result-nav-item-wrap {
  margin-bottom: 10px;
}
.show-case-creation__result-nav-item-wrap:last-child {
  margin-bottom: 0;
}
.show-case-creation__result-nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  border-bottom: 2px dashed var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.show-case-creation__result-nav-item:hover {
  border-color: transparent;
}
.show-case-creation__preview-wrap {
  width: 100%;
}
.show-case-creation__preview {
  pointer-events: none;
  border-radius: 8px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
@media screen and (max-width: 1194px) {
  .show-case-creation__result {
    padding-left: 50px;
  }
}
@media screen and (max-width: 980px) {
  .show-case-creation__container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .show-case-creation__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .show-case-creation__user {
    display: none;
  }
  .show-case-creation__header-logo {
    display: block;
  }
  .show-case-creation__close {
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .show-case-creation__content {
    display: block;
  }
  .show-case-creation__form-wrap {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .show-case-creation__result-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
  .show-case-creation__result-nav-item-wrap {
    display: block;
  }
  .show-case-creation__preview {
    margin-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .show-case-creation__container {
    padding-top: 20px;
  }
  .show-case-creation__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .show-case-creation__form-wrap {
    min-width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.get-money {
  padding-top: 84px;
  padding-bottom: 50px;
}
.get-money__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 20px;
}
.get-money__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.get-money__withdrawal-btn {
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.get-money__withdrawal-btn:hover {
  color: var(--dark-slate-blue);
}
.get-money__nav-wrap {
  margin-bottom: 34px;
}
.get-money__nav {
  display: flex;
  align-items: center;
  position: relative;
}
.get-money__nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--bluey-grey);
  font-size: 16px;
  padding: 0 0 4px 0;
  text-decoration: none;
  margin-right: 43px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.get-money__nav-item:last-child {
  margin-right: 0;
}
.get-money__nav-item:hover {
  color: var(--dusk-blue);
}
.get-money__nav-item_active {
  color: var(--dark-slate-blue);
}
.get-money__tabs-bar {
  position: absolute;
  bottom: -6px;
  left: 0;
}
.get-money__tabs-bar-active {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  transition: all 0.3s ease;
  border-bottom: 2px dotted var(--dark-slate-blue);
}
.get-money__warning__container {
  display: flex;
  flex-direction: column;
}
.get-money__warning__title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-blue);
}
.get-money__warning__text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 4px;
  line-height: 20px;
  font-size: 14px;
  color: var(--grey-blue);
}
.get-money__warning__text > button {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  color: var(--deep-sky-blue);
}
.get-money__content {
  position: relative;
  min-height: 490px;
}
.get-money__content-tab {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  z-index: 10;
}
.get-money__content-tab_active {
  z-index: 20;
}
.get-money__item {
  width: calc((100% - 56px) / 3);
  margin-right: 27px;
  margin-bottom: 30px;
  text-decoration: none;
}
.get-money__item:nth-child(3n) {
  margin-right: 0;
}
.get-money__item:last-child {
  margin-right: 0;
}
@media (max-width: 480px) {
  .get-money__desktop-notification {
    display: none;
  }
}
.get-money__mobile-notification {
  display: none;
}
@media (max-width: 480px) {
  .get-money__mobile-notification {
    display: block;
  }
}

@media screen and (max-width: 1194px) {
  .get-money__item {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .get-money__item:nth-child(3n) {
    margin-right: 20px;
  }
  .get-money__item:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  .get-money__content {
    min-height: 780px;
  }
  .get-money__item {
    width: 100%;
    margin-right: 0;
  }
  .get-money__item:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 800px) {
  .get-money__top {
    border-bottom: 0;
  }
  .get-money__title {
    font-size: 28px;
  }
}
@media screen and (max-width: 600px) {
  .get-money {
    padding-top: 40px;
  }
  .get-money__nav {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  .get-money__nav-item {
    width: 50%;
    margin-right: 0;
  }
  .get-money__tabs-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--bluey-grey);
    width: 100%;
  }
  .get-money__tabs-bar-active {
    position: absolute;
    bottom: -1px;
    top: auto;
    left: 0;
    height: 3px;
    transition: all 0.3s ease;
    border-bottom: 3px solid var(--dark-slate-blue);
  }
  .get-money__warning {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media screen and (max-width: 350px) {
  .get-money__nav-item {
    font-size: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.history-requests-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  padding: 6px 25px 6px 30px;
  transition: all 0.3s ease;
  position: relative;
}
.history-requests-item:hover {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.history-requests-item_open {
  background: rgba(246, 248, 251, 0.56);
}
.history-requests-item__top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history-requests-item__title-wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 460px);
}
.history-requests-item_open .history-requests-item__title-wrap {
  align-items: flex-start;
}
.history-requests-item__icon {
  margin-right: 21px;
}
.history-requests-item__icon_coins {
  width: 16px;
  height: 16px;
}
.history-requests-item__icon_coins path {
  fill: var(--bluey-grey);
}
.history-requests-item__icon_bill path {
  fill: var(--bluey-grey);
}
.history-requests-item_open .history-requests-item__icon path {
  fill: var(--dark-slate-blue);
}
.history-requests-item__title {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: left;
  transition: all 0.3s ease-in-out;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.history-requests-item:hover .history-requests-item__title {
  color: var(--dark-slate-blue);
}
.history-requests-item_open .history-requests-item__title {
  color: var(--dark-slate-blue);
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
  word-break: break-all;
}
.history-requests-item__payment-status {
  width: 100px;
  min-width: 100px;
  height: 20px;
  line-height: 18px;
  border-radius: 10px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: center;
  border: 1px solid var(--bluey-grey);
  color: var(--bluey-grey);
}
.history-requests-item_payed .history-requests-item__payment-status {
  border-color: rgba(0, 173, 181, 0.1);
  background: rgba(0, 173, 181, 0.1);
  color: var(--turquoise-blue);
}
.history-requests-item__date {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 150px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.history-requests-item:hover .history-requests-item__date {
  color: var(--dark-slate-blue);
}
.history-requests-item_open .history-requests-item__date {
  color: var(--dark-slate-blue);
}
.history-requests-item__sum {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 80px;
  width: 100%;
  text-align: right;
  color: var(--bluey-grey);
  white-space: nowrap;
}
.history-requests-item_payed .history-requests-item__sum {
  color: var(--turquoise-blue);
}
.history-requests-item__bottom {
  display: flex;
  justify-content: space-between;
  padding-left: 36px;
  padding-top: 10px;
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.history-requests-item_open .history-requests-item__bottom {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.history-requests-item__message-wrap {
  text-align: left;
}
.history-requests-item__message-title {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--bluey-grey);
  margin-right: 5px;
}
.history-requests-item__message-text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--grey-blue);
}
.history-requests-item__message-text_url {
  text-decoration: none;
}
.history-requests-item__message-text_url:hover {
  text-decoration: underline;
}
.history-requests-item__download-bill-link {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.58;
}
.history-requests-item__download-bill-link:hover {
  text-decoration: none;
}
.history-requests-item__copy-success {
  text-align: center;
  background: rgba(0, 173, 181, 0.1);
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 3px;
  border: 1px solid rgba(0, 173, 181, 0.1);
  margin-left: 4px;
  font-size: 10px;
  color: var(--turquoise-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 980px) {
  .history-requests-item__title-wrap {
    width: 70%;
    min-width: 70%;
    padding-right: 10px;
    flex-wrap: wrap;
  }
  .history-requests-item__title {
    font-size: 16px;
    width: calc(100% - 16px - 20px);
  }
  .history-requests-item__right-part {
    width: 30%;
    min-width: 30%;
    text-align: right;
  }
  .history-requests-item__sum {
    max-width: 100%;
    font-size: 20px;
  }
  .history-requests-item__date {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    padding-left: 36px;
  }
  .history-requests-item_open .history-requests-item__date {
    color: var(--bluey-grey);
  }
  .history-requests-item:hover .history-requests-item__date {
    color: var(--bluey-grey);
  }
  .history-requests-item__payment-status {
    border-color: transparent;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
    width: auto;
    text-align: right;
    background: transparent;
  }
  .history-requests-item_open .history-requests-item__payment-status {
    width: 100px;
    border-color: var(--bluey-grey);
    text-align: center;
  }
  .history-requests-item_payed .history-requests-item__payment-status {
    background: transparent;
    border-color: transparent;
  }
  .history-requests-item_payed.history-requests-item_open .history-requests-item__payment-status {
    border-color: rgba(0, 173, 181, 0.1);
    background: rgba(0, 173, 181, 0.1);
    text-align: center;
    width: 100px;
  }
  .history-requests-item__bottom {
    display: block;
    text-align: left;
  }
  .history-requests-item__additional-info {
    margin-bottom: 12px;
  }
  .history-requests-item__download-bill-link {
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .history-requests-item {
    padding: 10px 5px;
  }
  .history-requests-item__title-wrap {
    width: 60%;
    min-width: 60%;
  }
  .history-requests-item__icon {
    margin-right: 10px;
  }
  .history-requests-item__date {
    padding-left: 25px;
  }
  .history-requests-item__right-part {
    width: 40%;
    min-width: 40%;
  }
  .history-requests-item__bottom {
    padding-left: 25px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.history-requests {
  width: 100%;
  border-radius: 6px;
  background-color: white;
  padding: 30px 0;
  flex-grow: 20;
  position: relative;
}
.history-requests__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--pale-grey);
  padding-bottom: 19px;
  padding-left: 30px;
  padding-right: 43px;
}
.history-requests__header-title-wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 300px);
}
.history-requests__header-icon {
  margin-right: 20px;
  width: 18px;
  height: 18px;
}
.history-requests__header-title {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  max-width: 100%;
  color: var(--grey-blue);
  text-align: left;
}
.history-requests__header-sum {
  color: var(--grey-blue);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  max-width: 80px;
  width: 100%;
  text-align: right;
}
.history-requests__content {
  position: relative;
}
.history-requests__list {
  padding-bottom: 50px;
}
.history-requests__list:empty {
  padding-bottom: 0;
}
.history-requests__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.history-requests__no-operations-message {
  margin-top: 50px;
}
.history-requests__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 10px;
}
.history-requests__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--pale-grey);
  position: absolute;
}

@media screen and (max-width: 980px) {
  .history-requests__month-navigation {
    width: 100%;
    margin-bottom: 40px;
  }
  .history-requests__list {
    padding-bottom: 0;
  }
  .history-requests__loading {
    position: static;
    height: 191px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .history-requests__content-wrapper {
    height: 290px;
  }
  .history-requests__track {
    right: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.operations {
  width: 100%;
  border-radius: 6px;
  background-color: white;
  padding: 30px 0;
  flex-grow: 20;
  position: relative;
}
.operations__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--pale-grey);
  padding-bottom: 19px;
  padding-left: 30px;
  padding-right: 30px;
}
.operations__content-wrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.operations__list {
  padding-bottom: 50px;
}
.operations__list:empty {
  padding-bottom: 0;
}
.operations__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  padding: 10px 30px;
  transition: all 0.3s ease;
  position: relative;
}
.operations__item:hover {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.operations__item_open {
  background: rgba(246, 248, 251, 0.56);
}
.operations__item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operations__item-title-wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 300px);
}
.operations__item-icon-wrap {
  min-width: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.operations__item-icon {
  width: 16px;
  height: 16px;
  fill: var(--grey-blue);
}
.operations__item-icon_clock {
  width: 18px;
  height: 18px;
}
.operations__item-icon_card {
  width: 16px;
  height: 13px;
}
.operations__item-icon_card path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_card path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_coins {
  width: 16px;
  height: 16px;
}
.operations__item-icon_coins path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_coins path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_bill {
  height: 15px;
}
.operations__item-icon_bill path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_bill path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_business-card {
  height: 12px;
}
.operations__item-icon_business-card path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_business-card path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_sz {
  fill: var(--bluey-grey);
  height: 12px;
}
.operations__item-icon_sz path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_sz path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_web-money {
  width: 16px;
  height: 16px;
}
.operations__item-icon_web-money path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.operations__item_open .operations__item-icon_web-money path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_yandex {
  width: 14px;
  height: 18px;
}
.operations__item-icon_yandex path:first-child {
  fill: #c0c4d4;
  transition: all 0.3s ease;
}
.operations__item_open .operations__item-icon_yandex path:first-child {
  fill: var(--dark-slate-blue);
}
.operations__item_open .operations__item-icon_yandex path {
  fill: #c0c4d4;
}
.operations__item-icon_moneybox {
  width: 14px;
  height: 15px;
}
.operations__item_open .operations__item-icon_moneybox path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_chashbox {
  width: 17px;
  height: 16px;
  fill: #b0b5c4;
}
.operations__item_open .operations__item-icon_chashbox {
  fill: var(--dark-slate-blue);
}
.operations__item-title {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: left;
  transition: all 0.3s ease-in-out;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.operations__header .operations__item-title {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  overflow: visible;
  max-width: 100%;
}
.operations__item:hover .operations__item-title {
  color: var(--dark-slate-blue);
}
.operations__item_open .operations__item-title {
  color: var(--dark-slate-blue);
  word-break: break-all;
  overflow: visible;
  text-overflow: initial;
  width: 100%;
  white-space: normal;
}
.operations__item-date {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 150px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.operations__item:hover .operations__item-date {
  color: var(--dark-slate-blue);
}
.operations__item_open .operations__item-date {
  color: var(--dark-slate-blue);
}
.operations__item-date-itself {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  white-space: nowrap;
}
.operations__item-sum {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 150px;
  width: 100%;
  text-align: right;
  color: var(--turquoise-blue);
}
.operations__item-sum_minus {
  color: var(--reddish-pink);
}
.operations__item-sum_unsuccess {
  color: var(--bluey-grey);
}
.operations__header .operations__item-sum {
  color: var(--grey-blue);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.operations__item-bottom {
  display: flex;
  justify-content: space-between;
  padding-left: 36px;
  padding-top: 10px;
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.operations__item_open .operations__item-bottom {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.operations__item-message-wrap {
  text-align: left;
}
.operations__item-message-title {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--bluey-grey);
  margin-right: 5px;
}
.operations__item-message-text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--grey-blue);
  word-break: break-all;
}
.operations__item-message-text_green {
  color: var(--turquoise-blue);
}
.operations__item-message-text_orange {
  color: var(--light-mustard);
}
.operations__item-message-text_red {
  color: var(--reddish-pink);
}
.operations__item-download-bill-link {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.58;
}
.operations__item-download-bill-link:hover {
  text-decoration: none;
}
.operations__no-operations-message {
  margin-top: 50px;
}
.operations__next-page-btn-wrap {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.operations__next-page-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.operations__next-page-btn-icon {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.operations__next-page-btn-icon path {
  stroke: #e8eaf0;
  transition: all 0.3s ease;
}
.operations__next-page-btn:hover .operations__next-page-btn-icon path {
  stroke: var(--dark-slate-blue);
}
.operations__loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
}
.operations__loading_full-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.operations__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 10px;
}
.operations__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--pale-grey);
  position: absolute;
}

@media screen and (max-width: 980px) {
  .operations__month-navigation {
    width: 100%;
  }
  .operations__list {
    padding-bottom: 0;
  }
  .operations__item-title-wrap {
    width: 70%;
    min-width: 70%;
    padding-right: 10px;
  }
  .operations__item-title {
    font-size: 16px;
  }
  .operations__item-right-part {
    width: 30%;
    min-width: 30%;
    text-align: right;
  }
  .operations__item-sum {
    max-width: 100%;
    font-size: 20px;
  }
  .operations__item-date {
    max-width: 100%;
    text-align: right;
  }
  .operations__item-bottom {
    display: block;
    text-align: left;
  }
  .operations__item-additional-info {
    margin-bottom: 12px;
  }
  .operations__item-download-bill-link {
    font-size: 14px;
  }
  .operations__loading {
    position: static;
    height: 191px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 700px) {
  .operations__item_open .operations__item-title-wrap {
    align-items: flex-start;
  }
  .operations__item_open .operations__item-icon_card {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_coins {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_bill {
    margin-top: 2px;
  }
  .operations__item_open .operations__item-icon_business-card {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_sz {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_web-money {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_yandex {
    margin-top: 1px;
  }
}
@media screen and (max-width: 600px) {
  .operations__content-wrapper {
    height: 290px;
  }
  .operations__item {
    padding: 10px 5px;
  }
  .operations__track {
    right: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.user-balance-card {
  width: 100%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  height: 229px;
  padding: 30px 20px 20px 20px;
  border-radius: 6px;
  position: relative;
}
.user-balance-card__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.user-balance-card__top-main {
  display: flex;
  align-items: center;
}
.user-balance-card__avatar-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.user-balance-card__avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-balance-card__profile-status-wrap {
  display: flex;
  align-items: center;
}
.user-balance-card__profile-status-icon {
  margin-right: 10px;
}
.user-balance-card__profile-status-text {
  font-size: 12px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.user-balance-card__status {
  width: 35px;
  height: 35px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-balance-card__status-icon {
  width: 17px;
  height: 15px;
  fill: var(--deep-sky-blue);
}
.user-balance-card__status-icon path {
  fill: var(--deep-sky-blue);
}
.user-balance-card__balance-wrap {
  margin-bottom: 20px;
  padding-left: 11px;
}
.user-balance-card__balance-text {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}
.user-balance-card__balance {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.user-balance-card__balance-rubles {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.user-balance-card__balance-penny {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.user-balance-card__replenish-link-wrap {
  padding-left: 11px;
}
.user-balance-card__replenish-link {
  text-decoration: none;
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.history {
  padding-top: 50px;
  max-width: 1300px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.history__top {
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 50px;
}
.history__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.history__tabs-wrap {
  position: relative;
  margin-bottom: 41px;
}
.history__list {
  width: 100%;
  display: flex;
}
.history__left-column {
  width: calc((100% - 90px) / 3);
  min-width: calc((100% - 90px) / 3);
  margin-right: 30px;
}
.history__main-column {
  flex-grow: 100;
  display: flex;
  flex-wrap: wrap;
}
.history__item {
  margin-bottom: 20px;
}
.history__item.service_small {
  padding: 18px 48px 23px 30px;
}
.history__item .service__icon-wrap {
  right: 20px;
  top: 34px;
}
.history__item .service_special .service__icon-wrap {
  right: 0;
}
.history__main-column .history__item {
  width: calc((100% - 30px) / 2);
  margin-right: 30px;
}
.history__main-column .history__item:last-child {
  margin-right: 0;
}
.history__statistic-card {
  width: 100%;
  height: 400px;
  display: flex;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.history__statistic-card-nav {
  display: flex;
  position: absolute;
  right: 0;
  top: -30px;
  height: 30px;
  z-index: 10;
}
.history__statistic-card-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 150px;
  text-align: center;
  font-size: 14px;
  height: 30px;
  color: var(--bluey-grey);
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.history__statistic-card-btn_active {
  color: var(--grey-blue);
  background: white;
  position: relative;
  z-index: 30;
}
.history__statistic-card-btn_active + .history__statistic-card-btn {
  margin-left: -20px;
  padding-left: 20px;
}
.history__statistic-card-btn_active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 14px;
  right: 14px;
  height: 1px;
  background: var(--dark-slate-blue);
}
.history__statistic-card-btn:first-child:not(.history__statistic-card-btn_active) {
  margin-right: -20px;
  padding-right: 20px;
}
.history__responsive-table-content {
  margin-bottom: 20px;
  width: 100%;
}
.history__operations {
  position: relative;
  z-index: 20;
}
.history__requests {
  position: relative;
  z-index: 20;
}
.history__user-balance-card {
  margin-bottom: 20px;
}

@media screen and (max-width: 1194px) {
  .history__list {
    display: flex;
    flex-wrap: wrap;
  }
  .history__left-column {
    width: 100%;
    margin-right: 0;
  }
  .history__main-column {
    width: 100%;
  }
  .history__item {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;
  }
  .history__item:nth-child(3) {
    margin-right: 0;
  }
  .history__statistic-card {
    width: 100%;
    height: 400px;
    display: flex;
    margin-bottom: 20px;
  }
  .history__user-balance-card {
    width: 100%;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 980px) {
  .history__user-balance-card {
    margin-bottom: 20px;
  }
  .history__item {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .history__main-column .history__item {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .history {
    padding-left: 20px;
    padding-right: 20px;
  }
  .history__top {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 25px;
  }
  .history__title {
    font-size: 28px;
  }
}
@media screen and (max-width: 350px) {
  .history {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.company-name-input-with-dropdown {
  position: relative;
  height: 60px;
  z-index: 30;
}
.company-name-input-with-dropdown_dropdown-open {
  z-index: 50;
  box-shadow: 0px 4px 27px #e8ecf2;
  border-radius: 8px 8px 0 0;
}
.company-name-input-with-dropdown__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
  padding: 10px 40px 0 20px;
}
.company-name-input-with-dropdown__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.company-name-input-with-dropdown__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.company-name-input-with-dropdown__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.company-name-input-with-dropdown_focused .company-name-input-with-dropdown__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.company-name-input-with-dropdown_focused.company-name-input-with-dropdown_dropdown-open .company-name-input-with-dropdown__input {
  border-radius: 8px 8px 0px 0px;
  border-color: white;
  background: white;
}
.company-name-input-with-dropdown_std-styles .company-name-input-with-dropdown__input {
  background: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  box-shadow: none;
  border: 1px solid rgba(228, 232, 238, 0.2);
}
.company-name-input-with-dropdown__inner {
  box-shadow: 0px 4px 27px #e8ecf2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}
.company-name-input-with-dropdown_std-styles .company-name-input-with-dropdown__inner {
  box-shadow: none;
}
.company-name-input-with-dropdown__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.company-name-input-with-dropdown:focus-within .company-name-input-with-dropdown__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.company-name-input-with-dropdown_focused .company-name-input-with-dropdown__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.company-name-input-with-dropdown_std-styles .company-name-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
}
.company-name-input-with-dropdown_focused.company-name-input-with-dropdown:focus-within .company-name-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
}
.company-name-input-with-dropdown_focused.company-name-input-with-dropdown_focused .company-name-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
}
.company-name-input-with-dropdown__dropdown {
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  z-index: 20;
  box-shadow: 0px 4px 27px #e8ecf2;
}
.company-name-input-with-dropdown__dropdown-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  min-height: 60px;
  padding: 10px 20px;
  border-bottom: 1px solid #e4e8ee;
  text-align: left;
  display: flex;
  flex-direction: column;
  transition: background 0.3s ease-in-out;
}
.company-name-input-with-dropdown__dropdown-item:last-child {
  border-bottom: none;
}
.company-name-input-with-dropdown__dropdown-item:first-child {
  border-top: 1px solid #e4e8ee;
}
.company-name-input-with-dropdown__dropdown-item:hover {
  background: #f6f8fb;
}
.company-name-input-with-dropdown__dropdown-item-inn {
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #b0b5c4;
  transition: color 0.3s ease-in-out;
}
.company-name-input-with-dropdown__dropdown-item:hover .company-name-input-with-dropdown__dropdown-item-inn {
  color: var(--dark-slate-blue);
}
.company-name-input-with-dropdown__dropdown-item-company-name {
  font-size: 12px;
  color: #b0b5c4;
  font-family: "Circe-Regular", sans-serif;
  transition: color 0.3s ease-in-out;
}
.company-name-input-with-dropdown__dropdown-item:hover .company-name-input-with-dropdown__dropdown-item-company-name {
  color: #616a88;
}
.company-name-input-with-dropdown__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 4px;
}
.company-name-input-with-dropdown__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--grey-blue);
  opacity: 0.6;
  position: absolute;
}
.company-name-input-with-dropdown__check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.company-name-input-with-dropdown__check path {
  stroke: var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-registration-summary {
  width: 100%;
}
.income-registration-summary__item {
  margin-bottom: 15px;
}
.income-registration-summary__item-label {
  font-size: 18px;
  color: var(--bluey-grey);
  line-height: 1.33;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}
.income-registration-summary__item-text {
  font-size: 18px;
  color: var(--grey-blue);
  line-height: 1.33;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  word-break: break-all;
}
.income-registration-summary__button-wrap {
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}
@media (max-width: 600px) {
  .income-registration-summary__button-wrap {
    grid-template-columns: 1fr;
  }
}
.income-registration-summary__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
}
.income-registration-summary__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.income-registration-summary__button:disabled {
  cursor: not-allowed;
  background: rgba(29, 46, 105, 0.1);
}
.income-registration-summary__button_stroked {
  background: white;
  border: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
}
.income-registration-summary__button_stroked:hover:not(:disabled) {
  background: white;
}
.income-registration-summary__button_stroked:disabled {
  background: white;
}

@media screen and (max-width: 600px) {
  .income-registration-summary__button-wrap {
    flex-direction: column-reverse;
    margin-top: 40px;
  }
  .income-registration-summary__button {
    border-radius: 30px;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.inn-input-with-dropdown {
  position: relative;
  height: 60px;
  z-index: 30;
}
.inn-input-with-dropdown_dropdown-open {
  z-index: 50;
  box-shadow: 0px 4px 27px #e8ecf2;
  border-radius: 8px 8px 0 0;
}
.inn-input-with-dropdown__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
  transition: all 0.3s ease;
}
.inn-input-with-dropdown__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.inn-input-with-dropdown__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.inn-input-with-dropdown__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(228, 232, 238, 0.1);
  cursor: default;
  border-color: #e4e8ee;
}
.inn-input-with-dropdown_focused .inn-input-with-dropdown__input {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.inn-input-with-dropdown_focused.inn-input-with-dropdown_dropdown-open .inn-input-with-dropdown__input {
  border-radius: 8px 8px 0px 0px;
  border-color: white;
  background: white;
}
.inn-input-with-dropdown_std-styles .inn-input-with-dropdown__input {
  background: rgba(228, 232, 238, 0.2);
  color: var(--dark-slate-blue);
  box-shadow: none;
  border: 1px solid rgba(228, 232, 238, 0.2);
}
.inn-input-with-dropdown__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}
.inn-input-with-dropdown_std-styles .inn-input-with-dropdown__inner {
  box-shadow: none;
}
.inn-input-with-dropdown__label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.inn-input-with-dropdown:focus-within .inn-input-with-dropdown__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.inn-input-with-dropdown_focused .inn-input-with-dropdown__label {
  top: 10px;
  left: 20px;
  color: #616a88;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
  white-space: nowrap;
  text-transform: uppercase;
}
.inn-input-with-dropdown_std-styles .inn-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
}
.inn-input-with-dropdown_focused.inn-input-with-dropdown:focus-within .inn-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
}
.inn-input-with-dropdown_focused.inn-input-with-dropdown_focused .inn-input-with-dropdown__label {
  color: #6b778d;
  font-family: "Circe-Bold", sans-serif;
  -webkit-transform: scale(0.45);
          transform: scale(0.45);
}
.inn-input-with-dropdown__dropdown {
  box-shadow: 0px 4px 27px #e8ecf2;
  border-radius: 0 0 8px 8px;
}
.inn-input-with-dropdown__dropdown-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  min-height: 60px;
  padding: 10px 20px;
  border-bottom: 1px solid #e4e8ee;
  text-align: left;
  display: flex;
  flex-direction: column;
  transition: background 0.3s ease-in-out;
  background: #ffffff;
}
.inn-input-with-dropdown__dropdown-item:last-child {
  border-bottom: none;
}
.inn-input-with-dropdown__dropdown-item:first-child {
  border-top: 1px solid #e4e8ee;
}
.inn-input-with-dropdown__dropdown-item:hover {
  background: #f6f8fb;
}
.inn-input-with-dropdown__dropdown-item-inn {
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #b0b5c4;
  transition: color 0.3s ease-in-out;
}
.inn-input-with-dropdown__dropdown-item:hover .inn-input-with-dropdown__dropdown-item-inn {
  color: var(--dark-slate-blue);
}
.inn-input-with-dropdown__dropdown-item-company-name {
  font-size: 12px;
  color: #b0b5c4;
  font-family: "Circe-Regular", sans-serif;
  transition: color 0.3s ease-in-out;
}
.inn-input-with-dropdown__dropdown-item:hover .inn-input-with-dropdown__dropdown-item-company-name {
  color: #616a88;
}
.inn-input-with-dropdown__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 4px;
}
.inn-input-with-dropdown__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--grey-blue);
  opacity: 0.6;
  position: absolute;
}
.inn-input-with-dropdown__check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.inn-input-with-dropdown__check path {
  stroke: var(--dark-slate-blue);
}
.inn-input-with-dropdown__input-error {
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
  font-size: 12px;
  padding: 5px 5px 0;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-registration-card {
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  background-color: white;
  min-height: 390px;
  display: flex;
  flex-direction: column;
}
.income-registration-card__header {
  border-radius: 14px 14px 0 0;
  padding: 29px 30px;
  min-height: 118px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
}
.income-registration-card__header-icon-wrap {
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.income-registration-card__header-icon {
  fill: var(--deep-sky-blue);
  width: 29px;
  height: 26px;
}
.income-registration-card__header-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: white;
  margin-left: 30px;
}
.income-registration-card__content {
  padding: 30px 30px 30px 30px;
  display: flex;
  flex-grow: 20;
}
.income-registration-card__content_error {
  flex-direction: column;
  padding-top: 0;
}
.income-registration-card__left-part {
  width: 52%;
  min-width: 52%;
  margin-right: 20px;
  flex-grow: 20;
}
.income-registration-card__input-holder {
  margin-bottom: 22px;
  position: relative;
}
.income-registration-card__input-holder:last-child {
  margin-bottom: 0;
}
.income-registration-card__input-holder_hide-long-text:after {
  content: "";
  position: absolute;
  right: 34px;
  top: 1px;
  bottom: 1px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(250, 251, 252) 100%);
  width: 70px;
  z-index: 10;
  pointer-events: none;
  transition: all 0.3s ease;
}
.income-registration-card__input-holder_hide-long-text:focus-within:after {
  opacity: 0;
}
.income-registration-card__input {
  height: 60px;
  border-radius: 8px;
  background-color: rgba(228, 232, 238, 0.2);
  width: 100%;
  border: 1px solid rgba(228, 232, 238, 0.2);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 10px 34px 0 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.income-registration-card__input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__input::-ms-clear {
  display: none;
}
.income-registration-card__input:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.income-registration-card__input_error {
  border-color: var(--reddish-pink);
}
.income-registration-card__input_error:focus {
  border-color: var(--reddish-pink);
}
.income-registration-card__input[type=number]::-webkit-outer-spin-button, .income-registration-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.income-registration-card__input[type=number] {
  -moz-appearance: textfield;
}
.income-registration-card__input-label {
  font-size: 8px;
  text-transform: uppercase;
  color: #6b778d;
  position: absolute;
  left: 20px;
  top: 7px;
  font-family: "Circe-Bold", sans-serif;
}
.income-registration-card__input-check {
  position: absolute;
  right: 20px;
  bottom: 17px;
}
.income-registration-card__input-check path {
  stroke: var(--dark-slate-blue);
}
.income-registration-card__input-error {
  position: absolute;
  font-family: "Circe-Regular", sans-serif;
  color: var(--reddish-pink);
  font-size: 12px;
  top: 100%;
  padding: 5px 5px 0;
  left: 0;
}
.income-registration-card__textarea {
  height: 117px;
  border-radius: 8px;
  background-color: rgba(228, 232, 238, 0.2);
  width: 100%;
  border: 1px solid rgba(228, 232, 238, 0.2);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 29px 20px 10px 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.income-registration-card__textarea::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea::-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.income-registration-card__textarea::-ms-clear {
  display: none;
}
.income-registration-card__textarea:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.income-registration-card__calendar-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  pointer-events: none;
}
.income-registration-card__right-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 20;
  width: 100%;
}
.income-registration-card__select {
  margin-bottom: 20px;
}
.income-registration-card__tip {
  line-height: 1.71;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
}
.income-registration-card__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
}
.income-registration-card__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.income-registration-card__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.income-registration-card__button_again {
  width: auto;
  padding: 0 100px;
}
.income-registration-card__fail {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.income-registration-card__fail-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: var(--dark-slate-blue);
  margin-bottom: 30px;
  max-width: 408px;
  margin: 0 auto 30px;
}
.income-registration-card__fail-text button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  color: var(--dark-slate-blue);
  text-decoration: underline;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.income-registration-card__fail-text button:hover {
  text-decoration: none;
}
.income-registration-card__fail-icon-wrap {
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  position: relative;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 50%;
  background: white;
}
.income-registration-card__fail-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
}
.income-registration-card__fail-icon {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -7px;
}
.income-registration-card__fail-icon path {
  fill: var(--reddish-pink);
}
.income-registration-card__check-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #616a88;
  margin-bottom: 40px;
}

@media screen and (max-width: 980px) {
  .income-registration-card {
    border-radius: 0;
    box-shadow: none;
    min-height: auto;
    background: transparent;
    overflow: visible;
  }
  .income-registration-card__content {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
  .income-registration-card__content_error {
    flex-direction: column;
    padding-top: 0;
    margin-top: -45px;
  }
  .income-registration-card__left-part {
    order: 20;
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .income-registration-card__right-part {
    order: 10;
  }
  .income-registration-card__input-holder {
    margin-bottom: 20px;
  }
  .income-registration-card__textarea {
    height: 60px;
    padding-top: 24px;
  }
  .income-registration-card__right-part {
    justify-content: flex-start;
    margin-bottom: 36px;
  }
  .income-registration-card__button {
    border-radius: 30px;
    box-shadow: 0 4px 27px 0 #e8ecf2;
    font-size: 16px;
    font-family: "Circe-Bold", sans-serif;
  }
}
@media screen and (max-width: 400px) {
  .income-registration-card__button_again {
    width: 100%;
    padding: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.income-registration {
  max-width: 1300px;
  padding: 50px 50px 130px 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.income-registration__top {
  border-bottom: 1px solid var(--bluey-grey);
  padding-bottom: 17px;
  margin-bottom: 39px;
}
.income-registration__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.income-registration__content {
  display: flex;
}
.income-registration__card-wrap {
  width: 670px;
  min-width: 600px;
  margin-right: 60px;
}
.income-registration__card-link-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.income-registration__card-link {
  width: 252px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  text-decoration: none;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: white;
  transition: all 0.3s ease;
}
.income-registration__card-link:hover {
  background: var(--dusk-blue);
}
.income-registration__useful-links-title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.income-registration__useful-links-item-wrap {
  margin-bottom: 10px;
  display: flex;
}
.income-registration__useful-links-item-wrap:last-child {
  margin-bottom: 0;
}
.income-registration__useful-links-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
}
.income-registration__useful-links-item-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition: all 0.3s ease;
  text-decoration: none;
}
.income-registration__useful-links-item-text:hover {
  border-bottom-color: transparent;
}
.income-registration__useful-links-item:disabled .income-registration__useful-links-item-text {
  color: var(--grey-blue);
  border-bottom: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.income-registration__bill-wrap {
  width: 291px;
  min-width: 291px;
  margin-right: 52px;
}
.income-registration__success {
  width: 286px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.income-registration__success-tip {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.income-registration__success-tip-link {
  font-size: 15px;
  line-height: 1.6;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-left: 5px;
}
.income-registration__success-tip-link:hover {
  text-decoration: none;
}
.income-registration__share-block {
  display: flex;
  margin-bottom: 13px;
}
.income-registration__share-text {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-right: 10px;
  white-space: nowrap;
}
.income-registration__share-list {
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
}
.income-registration__share-item {
  margin-right: 10px;
  padding: 0;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  outline: none;
}
.income-registration__share-item-icon {
  width: 20px;
  height: 15px;
}
.income-registration__share-item-icon_envelope {
  height: 14px;
}
.income-registration__share-item-icon_envelope g {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_envelope g {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_share {
  width: 15px;
  height: 14px;
}
.income-registration__share-item-icon_share path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_share path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_vk path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_vk path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_wa path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_wa path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_ok path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_ok path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_fb path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_fb path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-item-icon_tg path {
  transition: all 0.3s ease;
  fill: #b0b5c4;
}
.income-registration__share-item:hover .income-registration__share-item-icon_tg path {
  fill: var(--dark-slate-blue);
}
.income-registration__share-link-copied {
  height: 20px;
  border-radius: 10px;
  background-color: #00adb5;
  padding: 0 12px;
  color: white;
  font-size: 12px;
  line-height: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 1194px) {
  .income-registration__card-wrap {
    width: 580px;
    min-width: 580px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 980px) {
  .income-registration {
    padding: 0 0 100px 0;
    margin-top: -90px;
  }
  .income-registration__header {
    background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
    min-height: 220px;
    margin-bottom: 36px;
    position: relative;
    overflow: hidden;
    padding: 90px 50px 35px 50px;
  }
  .income-registration__header_fail {
    margin-bottom: 0;
  }
  .income-registration__header-icon-wrap {
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .income-registration__header-icon {
    fill: var(--deep-sky-blue);
    width: 29px;
    height: 26px;
  }
  .income-registration__header-bg {
    position: absolute;
    right: -80px;
    bottom: -30px;
    width: 260px;
    height: 320px;
    object-fit: contain;
    z-index: 10;
  }
  .income-registration__header-title {
    font-size: 28px;
    line-height: 1.29;
    font-family: "Circe-Bold", sans-serif;
    color: white;
    position: relative;
    z-index: 20;
  }
  .income-registration__content {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    position: relative;
    z-index: 20;
  }
  .income-registration__bill-wrap {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .income-registration__success-tip {
    margin-bottom: 60px;
  }
  .income-registration__success {
    width: auto;
    max-width: 400px;
  }
  .income-registration__card-link-wrap {
    display: flex;
    justify-content: center;
  }
  .income-registration__card-link {
    border-radius: 30px;
    max-width: 375px;
    width: 100%;
  }
  .income-registration__card-wrap {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 800px) {
  .income-registration {
    margin-top: -70px;
  }
  .income-registration__header {
    padding: 70px 50px 35px 50px;
  }
}
@media screen and (max-width: 600px) {
  .income-registration__header {
    padding: 70px 20px 35px 20px;
  }
  .income-registration__share-list {
    flex-wrap: wrap;
  }
  .income-registration__share-link-copied {
    position: absolute;
    bottom: 25px;
  }
}
@media screen and (max-width: 350px) {
  .income-registration__header {
    padding: 70px 14px 35px 14px;
  }
  .income-registration__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
@media screen and (max-width: 980px) {
  .money-request__header__bg {
    position: absolute;
    right: -80px;
    bottom: -30px;
    width: 260px;
    height: 320px;
    object-fit: contain;
    z-index: 10;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.notifications-list__itself_loading {
  position: relative;
}
.notifications-list__itself_loading:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
}
.notifications-list__loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 40;
}
.notifications-list__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: block;
  margin-bottom: 13px;
  width: 370px;
  min-height: 105px;
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  padding: 17px 25px;
  background: white;
  text-align: left;
  transition: all 0.2s cubic-bezier(0.32, 0.94, 0.6, 1);
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.notifications-list__item:last-child {
  margin-bottom: 0;
}
.notifications-list__item:hover {
  box-shadow: 0px 1px 11px 1px #c6c8cc;
}
.notifications-list__item_has-to-fly-away {
  -webkit-transform: translateX(-500px);
          transform: translateX(-500px);
  opacity: 0;
}
.notifications-list__item-date {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.notifications-list__item-title {
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.notifications-list__item-title:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 7px;
  background-color: var(--turquoise-blue);
}
.notifications-list__item_unread .notifications-list__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.notifications-list__item_unread .notifications-list__item-title:before {
  background-color: var(--reddish-pink);
}
.notifications-list__item-type {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.notifications-list__item-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: normal;
  color: var(--grey-blue);
  word-break: break-word;
  white-space: pre-wrap;
}
.notifications-list__item-text a {
  color: var(--dark-slate-blue);
}
.notifications-list__item-text a:hover {
  text-decoration: none;
}
.notifications-list__pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
}
.notifications-list__pagination-item {
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0 10px 15px 0;
  white-space: nowrap;
  font-family: "Circe-Regular", sans-serif;
  color: var(--bluey-grey);
  font-size: 14px;
}
.notifications-list__pagination-item:hover:not(:disabled) {
  color: var(--dark-slate-blue);
  text-decoration: underline;
}
.notifications-list__pagination-item:disabled {
  cursor: not-allowed;
}
.notifications-list__pagination-item_current {
  color: var(--dark-slate-blue);
}
.notifications-list__pagination-item_prev {
  width: auto;
}
.notifications-list__pagination-item_next {
  width: auto;
}
.notifications-list__pagination-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 500px) {
  .notifications-list__item {
    width: 100%;
  }
  .notifications-list__pagination-item_prev {
    width: 20px;
    text-align: center;
    text-indent: -9999px;
  }
  .notifications-list__pagination-item_prev:after {
    content: "<";
    text-indent: 0;
  }
  .notifications-list__pagination-item_next {
    width: 20px;
    text-align: center;
    text-indent: -9999px;
  }
  .notifications-list__pagination-item_next:after {
    content: ">";
    text-indent: 0;
  }
  .notifications-list__pagination-item:last-child {
    margin-right: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.notifications {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
  box-sizing: border-box;
}
.notifications__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications__loading-icon {
  height: 40px;
  width: 40px;
}
.notifications__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(176, 181, 196, 0.5);
  margin-bottom: 27px;
  padding-bottom: 10px;
}
.notifications__main {
  display: flex;
  align-items: center;
}
.notifications__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
  margin-right: 30px;
  line-height: 1.33;
}
.notifications__info {
  display: flex;
  align-items: center;
}
.notifications__info-item {
  min-width: 150px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  height: 30px;
  border-radius: 15px;
  border: solid 1px var(--grey-blue);
  margin-right: 10px;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  background: transparent;
  cursor: pointer;
}
.notifications__info-item_active {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  border: 1px solid transparent;
  color: white;
  cursor: default;
}
.notifications__info-item:last-child {
  margin-right: 0;
}
.notifications__info-item:hover:not(.notifications__info-item_active):not(:disabled) {
  box-shadow: 0px 1px 11px #c6c8cc;
}
.notifications__info-item:disabled {
  cursor: not-allowed;
}
.notifications__mark-all-as-read {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.notifications__mark-all-as-read-icon {
  width: 8px;
  height: 8px;
  border: solid 1px var(--grey-blue);
  margin-right: 6px;
  border-radius: 50%;
  margin-top: 2px;
}
.notifications__mark-all-as-read-text {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
.notifications__mark-all-as-read:hover .notifications__mark-all-as-read-text {
  text-decoration: none;
}
@media screen and (max-width: 850px) {
  .notifications__top {
    flex-direction: column;
    align-items: flex-start;
  }
  .notifications__main {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .notifications__title {
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 600px) {
  .notifications {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 350px) {
  .notifications {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-info-card {
  width: 100%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  padding: 30px 20px 26px 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.tax-info-card__tooltip-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.tax-info-card__tooltip-btn-icon {
  width: 16px;
  height: 16px;
}
.tax-info-card__tooltip-btn-icon path {
  fill: var(--grey-blue);
}
.tax-info-card__tooltip-btn-icon g {
  fill: var(--grey-blue);
}
.tax-info-card__tooltip-wrap {
  position: absolute;
  top: 28px;
  right: 26px;
}
.tax-info-card__tooltip {
  position: absolute;
  top: 27px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 265px;
  min-height: 220px;
  border-radius: 8px;
  background-color: var(--pale-grey);
  padding: 15px 14px 28px 25px;
  opacity: 0;
  visibility: hidden;
}
.tax-info-card__tooltip_open {
  opacity: 1;
  visibility: visible;
}
.tax-info-card__tooltip:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--pale-grey);
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.tax-info-card__tooltip-text {
  font-size: 10px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 16px;
}
.tax-info-card__tooltip-text:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-card__tooltip-text-wrap .tax-info-card__tooltip-text {
  margin-bottom: 8px;
}
.taxes-to-pay-card__tooltip-text-wrap .tax-info-card__tooltip-text:last-child {
  margin-bottom: 0;
}
.tax-info-card__title {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 14px;
}
.tax-info-card__sum {
  margin-bottom: 15px;
  font-size: 40px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.tax-info-card__bank-details-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--bluey-grey);
  text-decoration: underline;
  transition: color 0.3s ease;
}
.tax-info-card__bank-details-btn:hover {
  color: var(--grey-blue);
}
.tax-info-card__sum-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
.tax-info-card__sum-input {
  background: #f6f8fb;
  border-radius: 8px;
  border: none;
  height: 60px;
  font-size: 40px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 14px;
}
.tax-info-card__sum-icon path {
  fill: #616a88;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment-card__top {
  padding-bottom: 13px;
  margin-bottom: 40px;
  position: relative;
}
.tax-payment-card__top-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--bluey-grey);
}
.tax-payment-card__top-progress-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  transition: all 0.3s ease;
}
.tax-payment-card__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 28px;
  margin-bottom: 20px;
}
.tax-payment-card__subtitle {
  font-size: 18px;
  line-height: 1.33;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.tax-payment-card__card {
  border-radius: 12px;
  margin-bottom: 20px;
}
.tax-payment-card__card_with-shadow {
  box-shadow: 0 4px 27px 0 #e8ecf2;
}
.tax-payment-card__header {
  height: 92px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-image: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(90, 200, 250) 100%);
  padding: 36px 36px 20px 36px;
  position: relative;
}
.tax-payment-card__header:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
.tax-payment-card__header-logo {
  object-fit: contain;
  width: 110px;
  height: 38px;
  position: relative;
  z-index: 20;
}
.tax-payment-card__header-bg {
  width: 284px;
  position: absolute;
  right: -60px;
  top: -176px;
  z-index: 20;
}
.tax-payment-card__success {
  display: flex;
  margin-bottom: 10px;
  padding: 32px 20px 0 20px;
}
.tax-payment-card__success-icon-wrap {
  width: 44px;
  min-width: 44px;
  height: 44px;
  margin-right: 27px;
  position: relative;
}
.tax-payment-card__success-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 10px;
  margin-top: -5px;
  margin-left: -7px;
}
.tax-payment-card__success-icon path {
  stroke: var(--turquoise-blue);
}
.tax-payment-card__in-process-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--turquoise-blue);
  text-align: center;
  line-height: 44px;
}
.tax-payment-card__success-text-title {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
}
.tax-payment-card__success-text-result {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
}
.tax-payment-card__iframe-wrap {
  height: 370px;
  position: relative;
}
.tax-payment-card__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.tax-payment-card__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 980px) {
  .tax-payment-card__responsive-header {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
  }
  .tax-payment-card__title {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment-card__subtitle {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment-card__card {
    max-width: 420px;
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment-card__responsive-header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-card__title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-card__subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-card__card {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 330px) {
  .tax-payment-card__card {
    max-width: calc(100% + 30px);
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment-action-buttons__wrap {
  display: flex;
  justify-content: flex-end;
}
.tax-payment-action-buttons__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: calc(50% - 10px);
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  background: var(--dusk-blue);
  transition: all 0.3s ease;
  margin-right: 20px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-payment-action-buttons__btn:hover {
  background: var(--dark-slate-blue);
}
.tax-payment-action-buttons__btn:disabled {
  box-shadow: none;
  color: #9da6ba;
  background: var(--pale-grey);
  cursor: not-allowed;
}
.tax-payment-action-buttons__btn_back {
  color: var(--grey-blue);
  border: 1px solid var(--grey-blue);
  background: white;
}
.tax-payment-action-buttons__btn_back:hover {
  border-color: var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  background: white;
}
.tax-payment-action-buttons__btn_to-accounting {
  flex: 0 1 215px;
}
.tax-payment-action-buttons__btn:last-child {
  margin-right: 0;
}

@media screen and (max-width: 980px) {
  .tax-payment-action-buttons {
    margin-left: 50px;
    margin-right: 50px;
  }
  .tax-payment-action-buttons__wrap {
    justify-content: flex-start;
    max-width: 420px;
    width: 100%;
    flex-direction: column-reverse;
  }
  .tax-payment-action-buttons__btn {
    border-radius: 30px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .tax-payment-action-buttons__btn_to-accounting {
    flex: 0 1 100%;
    margin-bottom: 0;
  }
  .tax-payment-action-buttons__btn:only-child {
    margin-bottom: 0;
  }
  .tax-payment-action-buttons__btn_back {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment-action-buttons {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment-sz__top {
  padding-bottom: 13px;
  margin-bottom: 40px;
  position: relative;
}
.tax-payment-sz__top-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--bluey-grey);
}
.tax-payment-sz__top-progress-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  transition: all 0.3s ease;
}
.tax-payment-sz__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 28px;
  margin-bottom: 20px;
}
.tax-payment-sz__subtitle {
  font-size: 18px;
  line-height: 1.33;
  color: var(--bluey-grey);
  font-family: "Circe-Regular", sans-serif;
}
.tax-payment-sz__card {
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  margin-bottom: 20px;
}
.tax-payment-sz__header {
  height: 92px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-image: linear-gradient(to right, rgb(0, 122, 255) 0%, rgb(90, 200, 250) 100%);
  padding: 36px 36px 20px 36px;
  position: relative;
}
.tax-payment-sz__header:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
.tax-payment-sz__header-logo {
  object-fit: contain;
  width: 110px;
  height: 38px;
  position: relative;
  z-index: 20;
}
.tax-payment-sz__header-bg {
  width: 284px;
  position: absolute;
  right: -60px;
  top: -176px;
  z-index: 20;
}
.tax-payment-sz__info {
  padding: 42px 20px;
}
.tax-payment-sz__info-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9da6ba;
  margin-bottom: 10px;
}
.tax-payment-sz__info-number {
  font-family: "Circe-Bold", sans-serif;
  font-size: 22px;
  color: var(--dark-slate-blue);
}
.tax-payment-sz__success {
  display: flex;
  margin-bottom: 10px;
  padding: 32px 20px 0 20px;
}
.tax-payment-sz__success-icon-wrap {
  width: 44px;
  min-width: 44px;
  height: 44px;
  margin-right: 27px;
  position: relative;
}
.tax-payment-sz__success-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 10px;
  margin-top: -5px;
  margin-left: -7px;
}
.tax-payment-sz__success-icon path {
  stroke: var(--turquoise-blue);
}
.tax-payment-sz__in-process-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--turquoise-blue);
  text-align: center;
  line-height: 44px;
}
.tax-payment-sz__success-text-title {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
}
.tax-payment-sz__success-text-result {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
}

@media screen and (max-width: 980px) {
  .tax-payment-sz__responsive-header {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
  }
  .tax-payment-sz__title {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment-sz__subtitle {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment-sz__card {
    max-width: 420px;
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment-sz__responsive-header {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;
  }
  .tax-payment-sz__title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-sz__subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment-sz__card {
    max-width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment__top {
  padding-bottom: 17px;
  margin-bottom: 40px;
  position: relative;
}
.tax-payment__title {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 36px;
}
.tax-payment__top-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid var(--bluey-grey);
}
.tax-payment__top-progress-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  transition: all 0.3s ease;
}
.tax-payment__content {
  display: flex;
}
.tax-payment__tabs-wrap {
  flex: 0 1 320px;
  margin-right: 100px;
}
.tax-payment__tabs-item {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  box-shadow: none;
  background-color: rgba(228, 232, 238, 0.2);
  border: 1px solid rgba(157, 166, 186, 0.2);
  transition: all 0.3s ease;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
}
.tax-payment__tabs-item:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  border-color: white;
}
.tax-payment__tabs-item_active {
  background: white;
  border-color: var(--dark-slate-blue);
}
.tax-payment__tabs-item:active {
  background: white;
  border-color: var(--dark-slate-blue);
}
.tax-payment__tabs-item:last-child {
  margin-bottom: 0;
}
.tax-payment__tabs-item:disabled {
  cursor: default;
}
.tax-payment__tabs-item-title {
  font-size: 18px;
  color: #9da6ba;
  font-family: "Circe-Regular", sans-serif;
  transition: all 0.3s ease;
}
.tax-payment__tabs-item:hover:not(:disabled) .tax-payment__tabs-item-title {
  color: var(--grey-blue);
}
.tax-payment__tabs-item:active .tax-payment__tabs-item-title {
  color: var(--dark-slate-blue);
}
.tax-payment__tabs-item_active .tax-payment__tabs-item-title {
  color: var(--dark-slate-blue);
}
.tax-payment__tabs-item-icon_card path {
  width: 25px;
  height: 18px;
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.tax-payment__tabs-item_active .tax-payment__tabs-item-icon_card path {
  fill: var(--dark-slate-blue);
}
.tax-payment__tabs-item:hover:not(:disabled) .tax-payment__tabs-item-icon_card path {
  fill: var(--dark-slate-blue);
}
.tax-payment__tabs-item-icon_wallet {
  width: 25px;
  height: 22px;
}
.tax-payment__tabs-item-icon_wallet path {
  transition: all 0.3s ease;
}
.tax-payment__tabs-item_active .tax-payment__tabs-item-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.tax-payment__tabs-item:hover:not(:disabled) .tax-payment__tabs-item-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.tax-payment__card-wrap {
  flex: 0 1 400px;
}
.tax-payment__warning {
  margin-bottom: 40px;
}

@media screen and (max-width: 980px) {
  .tax-payment__header {
    margin-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment__title {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tax-payment__sub-title {
    font-size: 18px;
    line-height: 1.33;
    color: var(--bluey-grey);
    font-family: "Circe-Regular", sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 20px;
  }
  .tax-payment__content {
    padding-right: 50px;
    padding-left: 50px;
  }
  .tax-payment__tabs-wrap {
    flex: 0 1 100%;
    margin-right: 0;
  }
  .tax-payment__tabs-item-title {
    color: var(--grey-blue);
  }
  .tax-payment__tabs-item-icon_card path {
    fill: var(--dark-slate-blue);
  }
  .tax-payment__tabs-item-icon_wallet path {
    fill: var(--dark-slate-blue);
    stroke: var(--dark-slate-blue);
  }
  .tax-payment__tabs-item-icon_web-money path {
    fill: var(--dark-slate-blue);
  }
  .tax-payment__tabs-item-icon_yandex path:first-child {
    fill: #ffcc00;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment__title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment__sub-title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tax-payment__content {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 350px) {
  .tax-payment__title {
    padding-left: 14px;
    padding-right: 14px;
  }
  .tax-payment__sub-title {
    padding-left: 14px;
    padding-right: 14px;
  }
  .tax-payment__content {
    padding-right: 14px;
    padding-left: 14px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-payment-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tax-payment-container__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.tax-payment-container__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tax-payment-container__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}

@media screen and (max-width: 980px) {
  .tax-payment-container__container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .tax-payment-container__container {
    padding-top: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.month-tax-card {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e4e8ee;
  border-radius: 6px;
  background: #f6f8fb;
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.month-tax-card:hover {
  background: white;
  border-color: var(--dark-slate-blue);
}
.month-tax-card_active {
  background: white;
  border-color: var(--dark-slate-blue);
}
.month-tax-card__month {
  font-size: 18px;
  font-family: "Circe-Regular", sans-serif;
  color: #616a88;
  transition: color 0.3s ease-in-out;
}
.month-tax-card_active .month-tax-card__month {
  color: var(--dark-slate-blue);
}
.month-tax-card:hover .month-tax-card__month {
  color: var(--dark-slate-blue);
}
.month-tax-card__sum {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: #616a88;
  transition: color 0.3s ease-in-out;
}
.month-tax-card_active .month-tax-card__sum {
  color: var(--dark-slate-blue);
}
.month-tax-card:hover .month-tax-card__sum {
  color: var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.no-taxes-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
.no-taxes-message__icon-wrapper {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: var(--white);
  margin-bottom: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.no-taxes-message__title {
  font-size: 24px;
  font-family: "Circe-Bold", sans-serif;
  text-align: center;
  margin-bottom: 8px;
  color: var(--dark-slate-blue);
}
.no-taxes-message__text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  line-height: 1.33;
  text-align: center;
  color: var(--dark-slate-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-list {
  background: #ffffff;
  box-shadow: 0 4px 27px #e8ecf2;
  border-radius: 8px;
}
.tax-list__header {
  padding: 28px 20px 18px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tax-list__column:first-child {
  width: calc(62% - 104px);
}
.tax-list__column:nth-child(2) {
  width: 38%;
  min-width: 38%;
}
.tax-list__column:nth-child(3) {
  width: 104px;
  min-width: 104px;
}
.tax-list__header .tax-list__column {
  font-size: 16px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.tax-list__main {
  padding-bottom: 16px;
}
.tax-list__item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 18px 20px 4px 20px;
  transition: background 0.3s ease-in-out;
  border-bottom: 1px solid #f6f8fb;
}
.tax-list__item:hover {
  background: #f6f8fb;
}
.tax-list__purpose-wrap {
  display: flex;
  align-items: center;
  padding-right: 37px;
}
.tax-list__checkbox {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 20px;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  border-radius: 4px;
  position: relative;
}
.tax-list__checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--dark-slate-blue);
  opacity: 0;
  pointer-events: none;
}
.tax-list__checkbox[aria-checked=true]:after {
  opacity: 1;
}
.tax-list__purpose {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.3;
}
.tax-list__type {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.tax-list__sum {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  white-space: nowrap;
  margin-bottom: 8px;
}
.tax-list__pay-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.tax-list__item:hover .tax-list__pay-button {
  text-decoration: none;
}

@media screen and (max-width: 980px) {
  .tax-list {
    box-shadow: none;
    border-radius: 0;
    width: calc(100% + 100px);
    margin-left: -50px;
    margin-right: -50px;
  }
  .tax-list__header {
    display: none;
  }
  .tax-list__column:first-child {
    width: 60%;
    min-width: 60%;
  }
  .tax-list__column:nth-child(2) {
    width: 40%;
    min-width: 40%;
  }
  .tax-list__column:nth-child(3) {
    display: none;
  }
  .tax-list__column:last-child {
    width: 40%;
    min-width: 40%;
  }
  .tax-list__main {
    padding-bottom: 0;
  }
  .tax-list__item {
    display: block;
    padding: 12px 50px 8px 50px;
    transition: background 0.3s ease-in-out;
    border-bottom: 1px solid #f6f8fb;
  }
  .tax-list__item_open {
    padding-bottom: 29px;
    background: #f6f8fb;
  }
  .tax-list__item_not_only-child {
    border-bottom-color: var(--dark-slate-blue);
    padding-bottom: 20px;
  }
  .tax-list__item_not_only-child:first-child {
    border-top: 1px solid var(--dark-slate-blue);
  }
  .tax-list__item:hover {
    background: white;
  }
  .tax-list__item-main {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 31px;
  }
  .tax-list__item_not_only-child .tax-list__item-main {
    padding-bottom: 10px;
  }
  .tax-list__purpose {
    line-height: 20px;
    text-align: left;
  }
  .tax-list__type {
    font-size: 12px;
    color: #b0b5c4;
    font-family: "Circe-Regular", sans-serif;
    text-align: right;
  }
  .tax-list__sum {
    margin-bottom: 3px;
    text-align: right;
  }
  .tax-list__pay-button {
    display: none;
  }
  .tax-list__item-bottom {
    display: flex;
    justify-content: flex-end;
  }
  .tax-list__item-button {
    width: 100px;
    height: 30px;
    font-size: 14px;
    border-radius: 4px;
  }
}
@media screen and (max-width: 600px) {
  .tax-list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
  .tax-list__item {
    padding: 12px 20px 8px 20px;
  }
  .tax-list__item_not_only-child {
    padding-bottom: 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.total-tax-to-pay {
  background: #ffffff;
  border: 1px solid var(--dark-slate-blue);
  border-radius: 6px;
  padding: 32px 20px 23px 20px;
  position: relative;
}
.total-tax-to-pay__title {
  color: #616a88;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 15px;
}
.total-tax-to-pay__sum {
  color: var(--dark-slate-blue);
  font-size: 40px;
  font-family: "Circe-Regular", sans-serif;
}
.total-tax-to-pay__tooltip-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.total-tax-to-pay__tooltip-btn-icon {
  height: 16px;
  width: 16px;
}
.total-tax-to-pay__tooltip-btn-icon path {
  fill: var(--dark-slate-blue);
}
.total-tax-to-pay__tooltip-btn-icon g {
  fill: var(--dark-slate-blue);
}
.total-tax-to-pay__tooltip-wrap {
  position: absolute;
  top: 28px;
  right: 20px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-list-container {
  display: flex;
  flex-grow: 20;
}
.tax-list-container__left-part {
  width: 370px;
  min-width: 370px;
  margin-right: 30px;
  padding-top: 0;
  transition: padding 0.3s ease-in;
  position: relative;
}
.tax-list-container__left-part_notice-message {
  padding-top: 140px;
}
.tax-list-container__warning {
  margin-bottom: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.tax-list-container__total {
  margin-bottom: 40px;
  position: relative;
  z-index: 20;
}
.tax-list-container__months {
  position: relative;
  z-index: 10;
}
.tax-list-container__months-title {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 20px;
}
.tax-list-container__months-list {
  padding-right: 5px;
}
.tax-list-container__months-item {
  margin-bottom: 20px;
}
.tax-list-container__months-item:last-child {
  margin-bottom: 0;
}
.tax-list-container__months-item_inner-page {
  cursor: default;
}
.tax-list-container__months-item_inner-page:last-child {
  margin-bottom: 30px;
}
.tax-list-container__table-wrap {
  width: calc(100% - 370px - 30px);
}
.tax-list-container__table {
  margin-bottom: 20px;
}
.tax-list-container__table-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.tax-list-container__table-bottom-notification {
  font-size: 14px;
  line-height: 20px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-right: 70px;
}
.tax-list-container__table-bottom-button {
  width: 270px;
  min-width: 270px;
}
.tax-list-container__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: -7px;
}
.tax-list-container__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--pale-grey);
  position: absolute;
}
.tax-list-container__button {
  width: 100%;
  margin-bottom: 20px;
}
.tax-list-container__button:last-child {
  margin-bottom: 0;
}
.tax-list-container__button:only-child {
  margin-bottom: 0;
}
.tax-list-container__no-taxes {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1149px) {
  .tax-list-container__left-part {
    width: 300px;
    min-width: 300px;
  }
  .tax-list-container__left-part_notice-message {
    padding-top: 160px;
  }
  .tax-list-container__table-wrap {
    width: calc(100% - 300px - 30px);
  }
}
@media screen and (max-width: 980px) {
  .tax-list-container {
    flex-direction: column;
  }
  .tax-list-container__left-part {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .tax-list-container__table-wrap {
    width: 100%;
    margin-bottom: 40px;
  }
  .tax-list-container__table-bottom {
    display: block;
    margin-top: 20px;
  }
  .tax-list-container__table-bottom-notification {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .tax-list-container__button-list .tax-list-container__table-bottom-button {
    width: 100%;
    margin-bottom: 20px;
  }
  .tax-list-container__no-taxes {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 200;
    position: relative;
  }
  .tax-list-container__no-taxes-return-button {
    max-width: 335px;
    width: 100%;
  }
  .tax-list-container__message {
    display: none;
  }
}

.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-list-page .popup-page-wrap__container {
  max-width: 1270px;
}
.tax-list-page__title-wrap {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 13px;
}
.tax-list-page__title-wrap:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -50px;
  right: -50px;
  background: rgba(176, 181, 196, 0.5);
  height: 1px;
}
.tax-list-page__title {
  font-size: 36px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 20px;
}
.tax-list-page__sub-title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  color: var(--light-grey-blue);
}

@media screen and (max-width: 600px) {
  .tax-list-page__title {
    font-size: 28px;
  }
}
.PartnerNav_animation-text-enter__1S7g- {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerNav_animation-text-enter-active__bDRsR {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerNav_animation-text-enter-done__1cVYk {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PartnerNav_animation-text-exit__1m5LX {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PartnerNav_animation-text-exit-active__3Ne7n {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PartnerNav_delay-100__2Rp9z {
  transition-delay: 100ms;
}

.PartnerNav_delay-200__31auR {
  transition-delay: 200ms;
}

.PartnerNav_delay-300__3lE5h {
  transition-delay: 300ms;
}

.PartnerNav_delay-400__mEU4X {
  transition-delay: 400ms;
}

.PartnerNav_delay-500__1N8G8 {
  transition-delay: 500ms;
}

.PartnerNav_delay-600__9rCPQ {
  transition-delay: 600ms;
}

.PartnerNav_delay-700__35zAB {
  transition-delay: 700ms;
}

.PartnerNav_delay-800__2KJm- {
  transition-delay: 800ms;
}

.PartnerNav_delay-900__Hch-S {
  transition-delay: 900ms;
}

.PartnerNav_delay-1000__2euRF {
  transition-delay: 1000ms;
}

.PartnerNav_delay-1100__3iUbX {
  transition-delay: 1100ms;
}

.PartnerNav_delay-1200__3UUBy {
  transition-delay: 1200ms;
}

.PartnerNav_delay-1300__1cB3C {
  transition-delay: 1300ms;
}

.PartnerNav_delay-1400__2T0L9 {
  transition-delay: 1400ms;
}

.PartnerNav_delay-1500__anXLK {
  transition-delay: 1500ms;
}

.PartnerNav_delay-1600__2uwZH {
  transition-delay: 1600ms;
}

.PartnerNav_delay-1700__2WhXS {
  transition-delay: 1700ms;
}

.PartnerNav_delay-1800__3LI9e {
  transition-delay: 1800ms;
}

.PartnerNav_delay-1900__qwXCx {
  transition-delay: 1900ms;
}

.PartnerNav_delay-2000__2IRUK {
  transition-delay: 2000ms;
}

.PartnerNav_partnerNav__2v7QR {
  display: flex;
  width: 100%;
  margin-top: 42px;
}
.PartnerNav_partnerNavLink__2-07M {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  border: 0 solid transparent;
  text-decoration: none;
  line-height: 24px;
  font-size: 16px;
  color: #b0b5c4;
}
.PartnerNav_partnerNavLink__2-07M:first-child {
  margin-left: 0;
}
.PartnerNav_partnerNavLinkActive__2Tx3N {
  border-bottom: 2px dotted #1d2e69;
  color: #1d2e69;
}

@media (max-width: 980px) {
  .PartnerNav_partnerNav__2v7QR {
    width: calc(100% + 40px);
    height: 60px;
    margin-top: 12px;
    margin-left: -20px;
    border-bottom: 1px solid rgba(176, 181, 196, 0.5);
  }
  .PartnerNav_partnerNavLink__2-07M {
    width: 33.3333333333%;
    height: 100%;
    margin-left: 0;
  }
  .PartnerNav_partnerNavLinkActive__2Tx3N {
    border-bottom: 3px solid #1d2e69;
  }
}
.PartnerAboutTab_animation-text-enter__2g2Gy {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerAboutTab_animation-text-enter-active__3vl_o {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerAboutTab_animation-text-enter-done__17znT {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PartnerAboutTab_animation-text-exit__3zD-L {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PartnerAboutTab_animation-text-exit-active__3zEYX {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PartnerAboutTab_delay-100__2oZ5k {
  transition-delay: 100ms;
}

.PartnerAboutTab_delay-200__2y7iq {
  transition-delay: 200ms;
}

.PartnerAboutTab_delay-300__38HTv {
  transition-delay: 300ms;
}

.PartnerAboutTab_delay-400__2u4Ub {
  transition-delay: 400ms;
}

.PartnerAboutTab_delay-500__3K6Ve {
  transition-delay: 500ms;
}

.PartnerAboutTab_delay-600__H1TiA {
  transition-delay: 600ms;
}

.PartnerAboutTab_delay-700__1kVUk {
  transition-delay: 700ms;
}

.PartnerAboutTab_delay-800__2kuHA {
  transition-delay: 800ms;
}

.PartnerAboutTab_delay-900__1mwiL {
  transition-delay: 900ms;
}

.PartnerAboutTab_delay-1000__2LdX5 {
  transition-delay: 1000ms;
}

.PartnerAboutTab_delay-1100__2O_y6 {
  transition-delay: 1100ms;
}

.PartnerAboutTab_delay-1200__3KJeC {
  transition-delay: 1200ms;
}

.PartnerAboutTab_delay-1300__1mga_ {
  transition-delay: 1300ms;
}

.PartnerAboutTab_delay-1400__10R4e {
  transition-delay: 1400ms;
}

.PartnerAboutTab_delay-1500__3GR8C {
  transition-delay: 1500ms;
}

.PartnerAboutTab_delay-1600__2nP_h {
  transition-delay: 1600ms;
}

.PartnerAboutTab_delay-1700__2_tfU {
  transition-delay: 1700ms;
}

.PartnerAboutTab_delay-1800__3m5jS {
  transition-delay: 1800ms;
}

.PartnerAboutTab_delay-1900__3Nbh7 {
  transition-delay: 1900ms;
}

.PartnerAboutTab_delay-2000__1Q63S {
  transition-delay: 2000ms;
}

.PartnerAboutTab_partnerAboutTab__ZEzQu {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-direction: column;
}
.PartnerAboutTab_partnerAboutTabInfo__Mkshb, .PartnerAboutTab_partnerAboutTabStats__1hx-1 {
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.PartnerAboutTab_partnerAboutTabInfo__Mkshb {
  width: 790px;
}
.PartnerAboutTab_partnerAboutTabInfoRow__RvHoA {
  margin-top: 16px;
}
.PartnerAboutTab_partnerAboutTabInfoRow__RvHoA:first-child {
  margin-top: 0;
}
.PartnerAboutTab_partnerAboutTabInfoRowTitle__h0lAH {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  color: #b0b5c4;
}
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs > span,
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs button > span {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #616a88;
}
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs > span + button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs > button > svg {
  width: 12px;
  height: 12px;
  opacity: 1;
}
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs > button > svg > path {
  fill: #616a88;
}
.PartnerAboutTab_partnerAboutTabInfoRowValue__1atRs > button:hover > svg {
  opacity: 1;
}
.PartnerAboutTab_partnerAboutTabStats__1hx-1 {
  width: 380px;
}
.PartnerAboutTab_partnerAboutTabStatsRow__13mz4 {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.PartnerAboutTab_partnerAboutTabStatsRow__13mz4:first-child {
  margin-top: 0;
}
.PartnerAboutTab_partnerAboutTabStatsRow__13mz4:nth-child(2) {
  margin-top: 20px;
}
.PartnerAboutTab_partnerAboutTabStatsRowColumn__1Ymq- {
  align-items: normal;
  flex-direction: column;
}
.PartnerAboutTab_partnerAboutTabStatsRowTitle__1YU77, .PartnerAboutTab_partnerAboutTabStatsRowValue__D4ZDv {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
}
.PartnerAboutTab_partnerAboutTabStatsRowTitle__1YU77 {
  color: #b0b5b4;
}
.PartnerAboutTab_partnerAboutTabStatsRowValue__D4ZDv {
  margin-left: 2px;
}
.PartnerAboutTab_partnerAboutTabStatsRowValueLarge__qgU0p {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 4px;
  margin-left: 0;
  line-height: 48px;
  font-size: 36px;
  color: #1d2e69;
}
.PartnerAboutTab_partnerAboutTab__ZEzQu .PartnerAboutTab_errorText__9XBka {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 16px;
  margin-left: 0;
  color: var(--reddish-pink);
}

@media (max-width: 980px) {
  .PartnerAboutTab_partnerAboutTab__ZEzQu {
    margin-top: 40px;
  }
  .PartnerAboutTab_partnerAboutTabInfo__Mkshb {
    width: 100%;
  }
  .PartnerAboutTab_partnerAboutTabStats__1hx-1 {
    width: 100%;
  }
}
.style_animation-text-enter__EV96V {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-active__21xRj {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-done__ueqFz {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.style_animation-text-exit__3XtnF {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.style_animation-text-exit-active__v5Fwj {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.style_delay-100__3GQgl {
  transition-delay: 100ms;
}

.style_delay-200__N70WE {
  transition-delay: 200ms;
}

.style_delay-300__egKjk {
  transition-delay: 300ms;
}

.style_delay-400__1m76D {
  transition-delay: 400ms;
}

.style_delay-500__1yiJ_ {
  transition-delay: 500ms;
}

.style_delay-600__1P8mZ {
  transition-delay: 600ms;
}

.style_delay-700__11GSz {
  transition-delay: 700ms;
}

.style_delay-800__2I03c {
  transition-delay: 800ms;
}

.style_delay-900__37j0t {
  transition-delay: 900ms;
}

.style_delay-1000__2_Pmd {
  transition-delay: 1000ms;
}

.style_delay-1100__1umb- {
  transition-delay: 1100ms;
}

.style_delay-1200__2jcop {
  transition-delay: 1200ms;
}

.style_delay-1300__3PRn5 {
  transition-delay: 1300ms;
}

.style_delay-1400__2bNZa {
  transition-delay: 1400ms;
}

.style_delay-1500__3Oo2B {
  transition-delay: 1500ms;
}

.style_delay-1600__3d4CD {
  transition-delay: 1600ms;
}

.style_delay-1700__8-lOI {
  transition-delay: 1700ms;
}

.style_delay-1800__eH8rt {
  transition-delay: 1800ms;
}

.style_delay-1900__8wy3W {
  transition-delay: 1900ms;
}

.style_delay-2000__NjbXx {
  transition-delay: 2000ms;
}

.style_profileNavigationWidget__3sknC {
  margin-top: 20px;
  width: 100%;
  margin-right: 60px;
}
.style_profileNavigationWidget__3sknC nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB {
  font-size: 18px;
  color: var(--normal-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB .style_icon__2GCKN {
  padding-right: 18px;
}
.style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB .style_icon__2GCKN.style_activeIcon__2ZdqZ {
  display: none;
}
.style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB:hover, .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB.style_activeLink__3gg69 {
  color: var(--dark-slate-blue);
}
.style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB:hover .style_icon__2GCKN.style_defaultIcon__22Pbq, .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB.style_activeLink__3gg69 .style_icon__2GCKN.style_defaultIcon__22Pbq {
  display: none;
}
.style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB:hover .style_icon__2GCKN.style_activeIcon__2ZdqZ, .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB.style_activeLink__3gg69 .style_icon__2GCKN.style_activeIcon__2ZdqZ {
  display: inline;
  display: initial;
}
.style_profileNavigationWidget__3sknC nav ul li + * {
  margin-top: 30px;
}
.style_profile-settings-nav__item_active__1ip_z .style_profileNavigationWidget__3sknC {
  color: var(--dark-slate-blue);
}
@media (max-width: 980px) {
  .style_profileNavigationWidget__3sknC {
    width: 100vw;
    margin-top: 0;
    background: #fff;
    padding: 20px 50px 80px;
  }
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB:hover,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB.style_activeLink__3gg69 {
    font-family: "Circe-Bold", sans-serif;
    font-size: 24px;
  }
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB .style_icon__2GCKN,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB .style_icon__2GCKN:hover,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB .style_icon__2GCKN.style_activeIcon__2ZdqZ,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB:hover .style_icon__2GCKN,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB:hover .style_icon__2GCKN:hover,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB:hover .style_icon__2GCKN.style_activeIcon__2ZdqZ,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB.style_activeLink__3gg69 .style_icon__2GCKN,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB.style_activeLink__3gg69 .style_icon__2GCKN:hover,
  .style_profileNavigationWidget__3sknC nav ul li .style_link__3ODbB.style_activeLink__3gg69 .style_icon__2GCKN.style_activeIcon__2ZdqZ {
    display: none;
  }
}
@media (max-width: 600px) {
  .style_profileNavigationWidget__3sknC {
    padding-left: 22px;
    padding-right: 22px;
  }
}
.style_animation-text-enter__1K9XU {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-active__3SsDd {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-done__3_X5B {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.style_animation-text-exit__7xZp6 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.style_animation-text-exit-active__2gEzg {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.style_delay-100__2qFQR {
  transition-delay: 100ms;
}

.style_delay-200__1pwBL {
  transition-delay: 200ms;
}

.style_delay-300__3PgZp {
  transition-delay: 300ms;
}

.style_delay-400__3ustG {
  transition-delay: 400ms;
}

.style_delay-500__1dcaC {
  transition-delay: 500ms;
}

.style_delay-600__3wIF1 {
  transition-delay: 600ms;
}

.style_delay-700__16ej8 {
  transition-delay: 700ms;
}

.style_delay-800__3Zpcr {
  transition-delay: 800ms;
}

.style_delay-900__2pSbe {
  transition-delay: 900ms;
}

.style_delay-1000__337l1 {
  transition-delay: 1000ms;
}

.style_delay-1100__25zIv {
  transition-delay: 1100ms;
}

.style_delay-1200__23Uxm {
  transition-delay: 1200ms;
}

.style_delay-1300__3bYmX {
  transition-delay: 1300ms;
}

.style_delay-1400__2AWrE {
  transition-delay: 1400ms;
}

.style_delay-1500__sPk5S {
  transition-delay: 1500ms;
}

.style_delay-1600__mO9OJ {
  transition-delay: 1600ms;
}

.style_delay-1700__3CjfF {
  transition-delay: 1700ms;
}

.style_delay-1800__qhNy5 {
  transition-delay: 1800ms;
}

.style_delay-1900__3EbkL {
  transition-delay: 1900ms;
}

.style_delay-2000__2JawT {
  transition-delay: 2000ms;
}

.style_mobileNavigation__vIKJD {
  padding-top: 0;
}
.style_mobileNavigation__vIKJD .style_navSelect__WC7ri {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: white;
  padding-left: 50px;
  padding-right: 50px;
  border-bottom: 1px solid #e8ecf2;
  margin-bottom: 20px;
}
.style_mobileNavigation__vIKJD .style_navSelectText__3zZ5W {
  font-size: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.style_mobileNavigation__vIKJD .style_navSelectIcon__3AjEG {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 24px;
  height: 24px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.style_mobileNavigation__vIKJD .style_navSelectIcon__3AjEG path {
  stroke: var(--dark-slate-blue);
}
.style_mobileNavigation__vIKJD .style_navSelectIcon__3AjEG .style_open__nL9sb {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

@media screen and (max-width: 600px) {
  .style_mobileNavigation__vIKJD .style_navSelect__WC7ri {
    padding: 0 27px 0 20px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.integration-widget {
  padding-left: 110px;
}
.integration-widget__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 43px;
  margin-top: 16px;
}
.integration-widget__content-wrap {
  width: 100%;
}
.integration-widget__content {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.integration-widget__create-container {
  position: relative;
  background: var(--white);
  box-shadow: 0px 16px 60px rgba(0, 22, 98, 0.07);
  border-radius: 6px;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  height: 124px;
  align-items: center;
  width: 100%;
  padding: 40px;
  grid-gap: 20px;
  gap: 20px;
}
.integration-widget__create-title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-slate-blue);
}
.integration-widget__btn-create {
  min-width: 210px;
}
.integration-widget__btn-add {
  min-width: 198px;
  font-weight: bold;
  margin-top: 40px;
  transition: all 0.3s ease;
}
.integration-widget__error-add {
  color: var(--reddish-pink);
  margin-top: 25px;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.integration-widget__divider {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: #e4e8ee;
  margin-top: 50px;
}
.integration-widget__list-title {
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-slate-blue);
}
.integration-widget__list {
  padding-bottom: 40px;
}
.integration-widget__list-item {
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  grid-gap: 20px;
  gap: 20px;
  box-shadow: 0px 2px 6px 0px rgba(0, 22, 98, 0.1215686275);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}
.integration-widget__list-item-field.date {
  margin-left: auto;
  flex: none;
}
.integration-widget__list-item-field.name {
  overflow: hidden;
}
.integration-widget__list-item-actions {
  display: flex;
}
.integration-widget__list-item-btn {
  border: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.integration-widget__list-item-copied {
  position: absolute;
  top: 40px;
  left: -34px;
  padding: 10px;
  border-radius: 4px;
  background: rgba(29, 46, 105, 0.6);
  color: var(--white);
  transition: all 0.3s ease;
  cursor: auto;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.integration-widget__list-item-copied:before {
  border-bottom: 6px solid rgba(29, 46, 105, 0.6);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -6px;
  position: absolute;
  top: -6px;
  width: 0;
}
.integration-widget__list-item-name {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--light-grey-blue);
}
.integration-widget__list-item-value {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-slate-blue);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.integration-widget__input-holder {
  margin-bottom: 10px;
  position: relative;
}
.integration-widget__input-holder:last-child {
  margin-bottom: 0;
}
.integration-widget__input-name {
  height: 60px;
  border-radius: 8px;
  background-color: var(--white);
  width: 100%;
  border: 1px solid rgba(29, 46, 105, 0.4);
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 10px 50px 10px 20px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-shadow: none;
}
.integration-widget__input-name::-webkit-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name::-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name:-moz-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name:-ms-input-placeholder {
  opacity: 1;
  color: var(--grey-blue);
}
.integration-widget__input-name:focus {
  border-color: var(--dark-slate-blue);
  background: white;
}
.integration-widget__input-name[type=number]::-webkit-outer-spin-button, .integration-widget__input-name[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.integration-widget__input-name[type=number] {
  -moz-appearance: textfield;
}
.integration-widget__input-name_service-name {
  padding: 10px 40px 0 20px;
}
.integration-widget__input-edit {
  position: absolute;
  right: 20px;
  bottom: 24px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
}
.integration-widget__input-edit path {
  fill: var(--dark-slate-blue);
}

@media screen and (max-width: 1100px) {
  .integration-widget {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .integration-widget {
    padding-top: 10px;
  }
  .integration-widget__create-container {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
  }
}
@media screen and (max-width: 800px) {
  .integration-widget {
    padding-bottom: 69px;
  }
  .integration-widget__content-wrap {
    display: block;
  }
  .integration-widget__content {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 710px) {
  .integration-widget {
    padding-bottom: 69px;
  }
  .integration-widget__list-item {
    flex-direction: column;
    padding: 20px;
  }
  .integration-widget__list-item-field {
    width: 100% !important;
    max-width: 100% !important;
  }
  .integration-widget__list-item-actions {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
@media screen and (max-width: 560px) {
  .integration-widget__create-container {
    flex-direction: column;
  }
  .integration-widget__btn-create {
    min-width: 100%;
  }
  .integration-widget__btn-add {
    min-width: 100%;
  }
}
@charset "UTF-8";
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-hold-widget {
  padding-left: 110px;
}
.tax-hold-widget__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 43px;
  margin-top: 16px;
}
.tax-hold-widget__content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
}
.tax-hold-widget__head {
  display: flex;
  align-items: center;
  grid-gap: 22px;
  gap: 22px;
}
.tax-hold-widget__head-block {
  box-shadow: 0px 4px 27px 0px rgb(232, 236, 242);
  width: 290px;
  height: 120px;
  display: flex;
  grid-gap: 9px;
  gap: 9px;
  flex-direction: column;
  padding: 20px;
  font-family: "Circe-Regular", sans-serif;
}
.tax-hold-widget__head-block-desc {
  color: var(--grey-blue);
  font-size: 14px;
}
.tax-hold-widget__head-block-value {
  color: var(--dark-slate-blue);
  font-size: 36px;
  line-height: 48px;
  display: flex;
}
.tax-hold-widget__head-block-value-rubles {
  font-family: "Circe-Bold", sans-serif;
}
.tax-hold-widget__head-block-value-penny {
  font-family: "Circe-Regular", sans-serif;
}
.tax-hold-widget__head-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-slate-blue);
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition-property: borderColor;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.tax-hold-widget__content-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-slate-blue);
}
.tax-hold-widget__table {
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0px 16px 60px 0px rgba(0, 22, 98, 0.0705882353);
  border-radius: 12px;
  margin-top: 20px;
  padding-bottom: 10px;
  background: var(--white);
}
.tax-hold-widget__table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  transition: all 0.15s ease-in-out;
  padding: 10px 20px;
  grid-gap: 20px;
  gap: 20px;
  background: var(--white);
}
.tax-hold-widget__table-row-date {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--light-grey-blue);
  width: 130px;
}
.tax-hold-widget__table-row-info {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--dark-slate-blue);
  width: 100%;
}
.tax-hold-widget__table-row-hold {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--light-grey-blue);
  width: 130px;
}
.tax-hold-widget__table-row-sum {
  display: flex;
  align-items: center;
  font-family: "Circe", sans-serif;
  font-size: 14px;
  color: var(--dark-slate-blue);
  width: 180px;
}
.tax-hold-widget__table-row.head {
  color: var(--light-grey-blue);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--pale-grey);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.tax-hold-widget__table-row.head div {
  color: var(--light-grey-blue);
}
.tax-hold-widget__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  grid-gap: 20px;
  gap: 20px;
}
.tax-hold-widget__empty-icon {
  height: 80px;
  width: 80px;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.0705882353);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.tax-hold-widget__empty-text {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #b0b5c4;
  text-align: center;
  max-width: 390px;
  padding: 0 20px;
}

@media screen and (max-width: 1100px) {
  .tax-hold-widget {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .tax-hold-widget {
    padding-top: 10px;
  }
  .tax-hold-widget__head {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .tax-hold-widget__head-block {
    width: 100%;
  }
  .tax-hold-widget__table-row {
    flex-flow: row wrap;
    grid-gap: 6px;
    gap: 6px;
    border-bottom: 1px solid var(--pale-grey);
  }
  .tax-hold-widget__table-row-hold, .tax-hold-widget__table-row-sum {
    justify-content: flex-end;
    width: 100%;
    color: var(--dark-slate-blue);
  }
  .tax-hold-widget__table-row-hold:before {
    color: var(--light-grey-blue);
    display: contents;
    content: "Удержано: ";
  }
  .tax-hold-widget__table-row-sum:before {
    color: var(--light-grey-blue);
    display: contents;
    content: "Сумма выплаты: ";
  }
  .tax-hold-widget__table-row.head {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .tax-hold-widget {
    padding-bottom: 69px;
  }
}
@media screen and (max-width: 710px) {
  .tax-hold-widget {
    padding-bottom: 69px;
  }
}
.PartnerPaymentsMenu_animation-text-enter__27Mhv {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerPaymentsMenu_animation-text-enter-active__32toA {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerPaymentsMenu_animation-text-enter-done__1Du_7 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PartnerPaymentsMenu_animation-text-exit__1hBEg {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PartnerPaymentsMenu_animation-text-exit-active__2aP8v {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PartnerPaymentsMenu_delay-100__3ZkLC {
  transition-delay: 100ms;
}

.PartnerPaymentsMenu_delay-200__7qIgo {
  transition-delay: 200ms;
}

.PartnerPaymentsMenu_delay-300__2_3nn {
  transition-delay: 300ms;
}

.PartnerPaymentsMenu_delay-400__3nL3o {
  transition-delay: 400ms;
}

.PartnerPaymentsMenu_delay-500__2Un2d {
  transition-delay: 500ms;
}

.PartnerPaymentsMenu_delay-600__3Zzjn {
  transition-delay: 600ms;
}

.PartnerPaymentsMenu_delay-700__3RAW4 {
  transition-delay: 700ms;
}

.PartnerPaymentsMenu_delay-800__3TokO {
  transition-delay: 800ms;
}

.PartnerPaymentsMenu_delay-900__2wrkM {
  transition-delay: 900ms;
}

.PartnerPaymentsMenu_delay-1000__3Bw0n {
  transition-delay: 1000ms;
}

.PartnerPaymentsMenu_delay-1100__268ia {
  transition-delay: 1100ms;
}

.PartnerPaymentsMenu_delay-1200__29GzM {
  transition-delay: 1200ms;
}

.PartnerPaymentsMenu_delay-1300__3xYOP {
  transition-delay: 1300ms;
}

.PartnerPaymentsMenu_delay-1400__1hgbx {
  transition-delay: 1400ms;
}

.PartnerPaymentsMenu_delay-1500__10Ehg {
  transition-delay: 1500ms;
}

.PartnerPaymentsMenu_delay-1600__HLxmg {
  transition-delay: 1600ms;
}

.PartnerPaymentsMenu_delay-1700__3tB3A {
  transition-delay: 1700ms;
}

.PartnerPaymentsMenu_delay-1800__3LqNa {
  transition-delay: 1800ms;
}

.PartnerPaymentsMenu_delay-1900__tBneH {
  transition-delay: 1900ms;
}

.PartnerPaymentsMenu_delay-2000__2Vuaq {
  transition-delay: 2000ms;
}

.PartnerPaymentsMenu_partnerPaymentsMenu__E9Lpl {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
}

.PartnerPaymentsMenu_statsSection__1e8v8 {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
}
.PartnerPaymentsMenu_statsSectionBlock__2_3gy {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.PartnerPaymentsMenu_statsSectionBlock__2_3gy > div:nth-child(1) {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #9da6ba;
}
.PartnerPaymentsMenu_statsSectionBlock__2_3gy > div:nth-child(2) {
  font-family: "Circe-Regular", sans-serif;
  line-height: 28px;
  font-size: 20px;
  color: #1d2e69;
}

.PartnerPaymentsMenu_navSection__1DAnb {
  position: relative;
}

.PartnerPaymentsMenu_navButtonGroup__B0OA9 {
  display: flex;
  align-items: center;
  height: 40px;
}

.PartnerPaymentsMenu_navButton__3y_zV {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  border: 1px solid #e4e8ee;
  background-color: #f6f8fb;
  line-height: 20px;
  font-size: 14px;
  color: #616a88;
}
.PartnerPaymentsMenu_navButton__3y_zV:first-child {
  border-radius: 8px 0 0 8px;
}
.PartnerPaymentsMenu_navButton__3y_zV:last-child {
  border-radius: 0 8px 8px 0;
}
.PartnerPaymentsMenu_navButton__3y_zV > svg {
  width: 16px;
  height: 16px;
}
.PartnerPaymentsMenu_navButtonActive__1QQ5L {
  border-color: #1d2e69;
  background-color: #1d2e69;
  color: #fff;
}

.PartnerPaymentsMenu_navMobileButton__2OARZ {
  display: none;
}

@media (max-width: 980px) {
  .PartnerPaymentsMenu_partnerPaymentsMenu__E9Lpl {
    flex-direction: column-reverse;
    padding: 20px 0;
    box-shadow: none;
    background-color: transparent;
  }
  .PartnerPaymentsMenu_statsSection__1e8v8 {
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 6px;
    box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
    background-color: #fff;
  }
  .PartnerPaymentsMenu_statsSectionBlock__2_3gy {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 2px;
  }
  .PartnerPaymentsMenu_statsSectionBlock__2_3gy:first-child {
    margin-top: 0;
  }
  .PartnerPaymentsMenu_statsSectionBlock__2_3gy > div {
    width: 50%;
  }
  .PartnerPaymentsMenu_navSection__1DAnb {
    width: 100%;
  }
  .PartnerPaymentsMenu_navButton__3y_zV {
    flex: 1 1;
    paddin: 0;
  }
  .PartnerPaymentsMenu_navDesktopButton__28n-P {
    display: none;
  }
  .PartnerPaymentsMenu_navMobileButton__2OARZ {
    display: flex;
  }
}
.StubPayments_animation-text-enter__212Fa {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.StubPayments_animation-text-enter-active__1eXiG {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.StubPayments_animation-text-enter-done__2q41y {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.StubPayments_animation-text-exit__3exQE {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.StubPayments_animation-text-exit-active__3wVT7 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.StubPayments_delay-100__2cLyX {
  transition-delay: 100ms;
}

.StubPayments_delay-200__3TSGN {
  transition-delay: 200ms;
}

.StubPayments_delay-300__dyoFP {
  transition-delay: 300ms;
}

.StubPayments_delay-400__1hiHx {
  transition-delay: 400ms;
}

.StubPayments_delay-500__3uRQh {
  transition-delay: 500ms;
}

.StubPayments_delay-600__fKTxE {
  transition-delay: 600ms;
}

.StubPayments_delay-700__eno1w {
  transition-delay: 700ms;
}

.StubPayments_delay-800__3BzWs {
  transition-delay: 800ms;
}

.StubPayments_delay-900__3R3zC {
  transition-delay: 900ms;
}

.StubPayments_delay-1000__2LKCy {
  transition-delay: 1000ms;
}

.StubPayments_delay-1100__2kQOZ {
  transition-delay: 1100ms;
}

.StubPayments_delay-1200__1t8-b {
  transition-delay: 1200ms;
}

.StubPayments_delay-1300__FcNEK {
  transition-delay: 1300ms;
}

.StubPayments_delay-1400__tejAV {
  transition-delay: 1400ms;
}

.StubPayments_delay-1500__1qMBd {
  transition-delay: 1500ms;
}

.StubPayments_delay-1600__3gljM {
  transition-delay: 1600ms;
}

.StubPayments_delay-1700__1zMJ- {
  transition-delay: 1700ms;
}

.StubPayments_delay-1800__b-b5- {
  transition-delay: 1800ms;
}

.StubPayments_delay-1900__3KUlx {
  transition-delay: 1900ms;
}

.StubPayments_delay-2000__1O8S7 {
  transition-delay: 2000ms;
}

.StubPayments_stubPayments__2mRwB {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 67px 0 117px;
}
.StubPayments_stubPaymentsIcon__99vpK {
  width: 42px;
  height: 42px;
}
.StubPayments_stubPaymentsText__1Gq8I {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 20px;
  line-height: 24px;
  font-size: 16px;
  color: #b0b5c4;
}

@media (max-width: 980px) {
  .StubPayments_stubPayments__2mRwB {
    padding: 95px 0 117px;
  }
}
.Tip_tip__3pWNT {
  position: absolute;
  pointer-events: none;
}
.Tip_tip__3pWNT::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #616a88;
}
.Tip_tipToTop__12Nni {
  bottom: calc(100% + 8px + 8px);
}
.Tip_tipToTop__12Nni::before {
  top: 100%;
  left: calc(50% - 8px);
}
.PartnerPaymentsTable_animation-text-enter__3qUPA {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerPaymentsTable_animation-text-enter-active__39iH9 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerPaymentsTable_animation-text-enter-done__2BsJI {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PartnerPaymentsTable_animation-text-exit__12gBI {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PartnerPaymentsTable_animation-text-exit-active__2uT3U {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PartnerPaymentsTable_delay-100__2MtsB {
  transition-delay: 100ms;
}

.PartnerPaymentsTable_delay-200__1olBZ {
  transition-delay: 200ms;
}

.PartnerPaymentsTable_delay-300__iEXO4 {
  transition-delay: 300ms;
}

.PartnerPaymentsTable_delay-400__3vBL_ {
  transition-delay: 400ms;
}

.PartnerPaymentsTable_delay-500__3WC6k {
  transition-delay: 500ms;
}

.PartnerPaymentsTable_delay-600__NBngy {
  transition-delay: 600ms;
}

.PartnerPaymentsTable_delay-700__ISdkT {
  transition-delay: 700ms;
}

.PartnerPaymentsTable_delay-800__3Zitg {
  transition-delay: 800ms;
}

.PartnerPaymentsTable_delay-900__2dgvO {
  transition-delay: 900ms;
}

.PartnerPaymentsTable_delay-1000__1CP-W {
  transition-delay: 1000ms;
}

.PartnerPaymentsTable_delay-1100__2G9d- {
  transition-delay: 1100ms;
}

.PartnerPaymentsTable_delay-1200__1fXGk {
  transition-delay: 1200ms;
}

.PartnerPaymentsTable_delay-1300__37kjl {
  transition-delay: 1300ms;
}

.PartnerPaymentsTable_delay-1400__3-VyE {
  transition-delay: 1400ms;
}

.PartnerPaymentsTable_delay-1500__kw5vR {
  transition-delay: 1500ms;
}

.PartnerPaymentsTable_delay-1600__3JATF {
  transition-delay: 1600ms;
}

.PartnerPaymentsTable_delay-1700__eRSvl {
  transition-delay: 1700ms;
}

.PartnerPaymentsTable_delay-1800__3edE3 {
  transition-delay: 1800ms;
}

.PartnerPaymentsTable_delay-1900__2rvIz {
  transition-delay: 1900ms;
}

.PartnerPaymentsTable_delay-2000__3y6T2 {
  transition-delay: 2000ms;
}

.PartnerPaymentsTable_partnerPaymentsTable__17fBH {
  margin-top: 12px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.PartnerPaymentsTable_partnerPaymentsTableHeader__2YZES {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 10px;
  border-bottom: 1px solid #e4e8ee;
}
.PartnerPaymentsTable_partnerPaymentsTableHeader__2YZES > div {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}
.PartnerPaymentsTable_partnerPaymentsTableHeader__2YZES > div:nth-child(1) {
  flex: 1 1;
}
.PartnerPaymentsTable_partnerPaymentsTableHeader__2YZES > div:nth-child(2) {
  flex: 4 1;
}
.PartnerPaymentsTable_partnerPaymentsTableHeader__2YZES > div:nth-child(3) {
  flex: 2 1;
  text-align: center;
}
.PartnerPaymentsTable_partnerPaymentsTableHeader__2YZES > div:nth-child(4) {
  flex: 2 1;
  text-align: right;
}
.PartnerPaymentsTable_partnerPaymentsTableContent__fCP8O {
  padding: 10px 0;
}
.PartnerPaymentsTable_partnerPaymentsTableRow__wAsr5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.PartnerPaymentsTable_partnerPaymentsTableRow__wAsr5:hover {
  background-color: #f6f8fb;
}
.PartnerPaymentsTable_partnerPaymentsTableRowDate__rmPyM, .PartnerPaymentsTable_partnerPaymentsTableRowService__2URXi, .PartnerPaymentsTable_partnerPaymentsTableRowTax__guGlp, .PartnerPaymentsTable_partnerPaymentsTableRowSum__2fYJk {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
}
.PartnerPaymentsTable_partnerPaymentsTableRowDate__rmPyM {
  flex: 1 1;
  color: #b0b5c4;
}
.PartnerPaymentsTable_partnerPaymentsTableRowService__2URXi {
  flex: 4 1;
  color: #616a88;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTax__guGlp {
  display: flex;
  justify-content: center;
  flex: 2 1;
  color: #b0b5c4;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxMobile__15Ts7 {
  display: none;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxDesktopIcon__1xFcv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  opacity: 0.4;
  cursor: pointer;
  border-radius: 50%;
  background-color: #b0b5c4;
  transition: opacity 0.3s;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxDesktopIcon__1xFcv > svg {
  width: 50%;
  height: auto;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxDesktopIcon__1xFcv > svg path {
  stroke: #fff;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxDesktopIcon__1xFcv:hover {
  opacity: 1;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxDesktopIcon__1xFcv:hover > [data-tip] {
  opacity: 1;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxDesktopIconTip__3T72e {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 280px;
  height: 80px;
  padding: 20px;
  opacity: 0;
  border-radius: 8px;
  background-color: #616a88;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  transition: opacity 0.3s;
}
.PartnerPaymentsTable_partnerPaymentsTableRowTaxMobileSum__37qCy > span:last-child {
  margin-left: 4px;
}
.PartnerPaymentsTable_partnerPaymentsTableRowSum__2fYJk {
  flex: 2 1;
  text-align: right;
  color: #1d2e69;
}
.PartnerPaymentsTable_partnerPaymentsTableLoader__2I_AC {
  margin: 0 auto;
}

@media (max-width: 980px) {
  .PartnerPaymentsTable_partnerPaymentsTable__17fBH {
    width: calc(100% + 40px);
    margin-top: 0;
    margin-left: -20px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .PartnerPaymentsTable_partnerPaymentsTableHeader__2YZES {
    display: none;
  }
  .PartnerPaymentsTable_partnerPaymentsTableContent__fCP8O {
    padding: 0;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRow__wAsr5 {
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    border-bottom: 1px solid #f6f8fb;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRowTaxDesktop__1pmOl {
    display: none;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRowTaxMobile__15Ts7 {
    display: inline;
    display: initial;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRowDate__rmPyM, .PartnerPaymentsTable_partnerPaymentsTableRowService__2URXi, .PartnerPaymentsTable_partnerPaymentsTableRowTax__guGlp, .PartnerPaymentsTable_partnerPaymentsTableRowSum__2fYJk {
    flex-basis: 50%;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRowService__2URXi {
    order: 1;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRowSum__2fYJk {
    order: 2;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRowDate__rmPyM {
    order: 3;
    margin-top: 2px;
  }
  .PartnerPaymentsTable_partnerPaymentsTableRowTax__guGlp {
    order: 4;
    justify-content: flex-end;
    margin-top: 2px;
  }
}
.PartnerPaymentsContainer_partnerPaymentsContainer__b843X {
  position: relative;
  width: 100%;
}
.Check_animation-text-enter__yE0hg {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Check_animation-text-enter-active__2yr94 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Check_animation-text-enter-done__3_YMq {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.Check_animation-text-exit__1ATs9 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.Check_animation-text-exit-active__2ZLsW {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.Check_delay-100__1PG9j {
  transition-delay: 100ms;
}

.Check_delay-200__2o7SM {
  transition-delay: 200ms;
}

.Check_delay-300__3H2D6 {
  transition-delay: 300ms;
}

.Check_delay-400__2zDAg {
  transition-delay: 400ms;
}

.Check_delay-500__janBs {
  transition-delay: 500ms;
}

.Check_delay-600__3BaiM {
  transition-delay: 600ms;
}

.Check_delay-700__KfZm7 {
  transition-delay: 700ms;
}

.Check_delay-800__2PYT2 {
  transition-delay: 800ms;
}

.Check_delay-900__2gd2V {
  transition-delay: 900ms;
}

.Check_delay-1000__3mE81 {
  transition-delay: 1000ms;
}

.Check_delay-1100__3XRPt {
  transition-delay: 1100ms;
}

.Check_delay-1200__6hF2_ {
  transition-delay: 1200ms;
}

.Check_delay-1300__1OI_x {
  transition-delay: 1300ms;
}

.Check_delay-1400__1KrRx {
  transition-delay: 1400ms;
}

.Check_delay-1500__RM_M0 {
  transition-delay: 1500ms;
}

.Check_delay-1600__19cAS {
  transition-delay: 1600ms;
}

.Check_delay-1700__1N4Jo {
  transition-delay: 1700ms;
}

.Check_delay-1800__3hv5p {
  transition-delay: 1800ms;
}

.Check_delay-1900__3venz {
  transition-delay: 1900ms;
}

.Check_delay-2000__30Ep7 {
  transition-delay: 2000ms;
}

.Check_check__gEjOl {
  position: relative;
  width: 100%;
}
.Check_checkContainer__27Sbh {
  position: relative;
  padding: 19px;
  border-radius: 6px 6px 1px 1px;
  box-shadow: 0 4px 27px #e8ecf2;
  background-color: #fff;
}
.Check_checkSection__2uD5Q {
  padding: 12px 0;
  border-bottom: 1px solid #f6f8fb;
}
.Check_checkTitle__18VuN {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.Check_checkDateTime__2KiXA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
}
.Check_checkDate__2LL8Y, .Check_checkTime__2gWIt {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  color: #b0b5c4;
}
.Check_checkPayer__fFszR, .Check_checkInn__207r4, .Check_checkRegime__2RMDn {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
}
.Check_checkPayer__fFszR {
  color: #1d2e69;
}
.Check_checkInn__207r4, .Check_checkRegime__2RMDn {
  color: #b0b5c4;
}
.Check_checkServiceListHeader__1kiVe {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Check_checkServiceListHeader__1kiVe > span {
  font-family: "Circe-Bold", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.Check_checkServiceListItem__XYQkC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.Check_checkServiceListItem__XYQkC > span {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.Check_checkTotal__7ixns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Check_checkTotal__7ixns > span {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.Check_checkQrCode__1Orbv {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.Check_checkQrCodeCanvas__2xR60 {
  width: 72px;
  height: 72px;
}
.Check_checkWave__167Yq {
  position: relative;
  width: 100%;
  height: 30px;
  background-image: url(/static/media/check-triangle.ad965840.svg);
  background-repeat: repeat-x;
  background-size: 5%;
}

@media (max-width: 600px) {
  .Check_checkWave__167Yq {
    background-size: 4%;
  }
}
.PartnerPaymentsCheck_animation-text-enter__20cxs {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerPaymentsCheck_animation-text-enter-active__32RCe {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerPaymentsCheck_animation-text-enter-done__sYJY1 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PartnerPaymentsCheck_animation-text-exit__4Gnad {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PartnerPaymentsCheck_animation-text-exit-active__3qRpj {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PartnerPaymentsCheck_delay-100__2sN5I {
  transition-delay: 100ms;
}

.PartnerPaymentsCheck_delay-200__3i5lI {
  transition-delay: 200ms;
}

.PartnerPaymentsCheck_delay-300__3g5u_ {
  transition-delay: 300ms;
}

.PartnerPaymentsCheck_delay-400__3MWMY {
  transition-delay: 400ms;
}

.PartnerPaymentsCheck_delay-500__2uMCE {
  transition-delay: 500ms;
}

.PartnerPaymentsCheck_delay-600__2W3-K {
  transition-delay: 600ms;
}

.PartnerPaymentsCheck_delay-700__2kqV1 {
  transition-delay: 700ms;
}

.PartnerPaymentsCheck_delay-800__cve_s {
  transition-delay: 800ms;
}

.PartnerPaymentsCheck_delay-900__Q0gzx {
  transition-delay: 900ms;
}

.PartnerPaymentsCheck_delay-1000__1HdOF {
  transition-delay: 1000ms;
}

.PartnerPaymentsCheck_delay-1100__3udjJ {
  transition-delay: 1100ms;
}

.PartnerPaymentsCheck_delay-1200__2c4rh {
  transition-delay: 1200ms;
}

.PartnerPaymentsCheck_delay-1300__29Wd4 {
  transition-delay: 1300ms;
}

.PartnerPaymentsCheck_delay-1400__1MGok {
  transition-delay: 1400ms;
}

.PartnerPaymentsCheck_delay-1500__BlMyU {
  transition-delay: 1500ms;
}

.PartnerPaymentsCheck_delay-1600__3YNSo {
  transition-delay: 1600ms;
}

.PartnerPaymentsCheck_delay-1700__1qCTv {
  transition-delay: 1700ms;
}

.PartnerPaymentsCheck_delay-1800__1rNPu {
  transition-delay: 1800ms;
}

.PartnerPaymentsCheck_delay-1900__3EU1M {
  transition-delay: 1900ms;
}

.PartnerPaymentsCheck_delay-2000__6Vze2 {
  transition-delay: 2000ms;
}

.PartnerPaymentsCheck_partnerPaymentsCheck__1W97a {
  position: relative;
  width: 290px;
}
.PartnerPaymentsCheck_partnerPaymentsCheckButton__zf17J {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.PartnerPaymentsCheck_partnerPaymentsCheckButton__zf17J > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  padding: 10px 16px;
  border-radius: 6px;
  background-color: #f6f8fb;
  line-height: 20px;
  font-size: 14px;
  color: #616a88;
}
.PartnerPaymentsCalendar_partnerPaymentsCalendar__2zlrV {
  position: absolute;
  z-index: 1;
  top: 80px;
  width: 100%;
}
.Header_animation-text-enter__2-J1z {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Header_animation-text-enter-active__ul5bg {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Header_animation-text-enter-done__13sbD {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.Header_animation-text-exit__3dEP1 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.Header_animation-text-exit-active__fQhqL {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.Header_delay-100__O3c7M {
  transition-delay: 100ms;
}

.Header_delay-200__39L3t {
  transition-delay: 200ms;
}

.Header_delay-300__129uF {
  transition-delay: 300ms;
}

.Header_delay-400__5GfFI {
  transition-delay: 400ms;
}

.Header_delay-500__3WRLr {
  transition-delay: 500ms;
}

.Header_delay-600__1OzAm {
  transition-delay: 600ms;
}

.Header_delay-700__1xd43 {
  transition-delay: 700ms;
}

.Header_delay-800__2DE0f {
  transition-delay: 800ms;
}

.Header_delay-900__37Bgq {
  transition-delay: 900ms;
}

.Header_delay-1000__21dmy {
  transition-delay: 1000ms;
}

.Header_delay-1100__2eRDy {
  transition-delay: 1100ms;
}

.Header_delay-1200__3x7kg {
  transition-delay: 1200ms;
}

.Header_delay-1300__1DUP7 {
  transition-delay: 1300ms;
}

.Header_delay-1400__2CmvQ {
  transition-delay: 1400ms;
}

.Header_delay-1500__20YIi {
  transition-delay: 1500ms;
}

.Header_delay-1600__SvyrE {
  transition-delay: 1600ms;
}

.Header_delay-1700__gfsFP {
  transition-delay: 1700ms;
}

.Header_delay-1800__2iqxh {
  transition-delay: 1800ms;
}

.Header_delay-1900__Ff2k- {
  transition-delay: 1900ms;
}

.Header_delay-2000__2Y3-X {
  transition-delay: 2000ms;
}

.Header_header__Z1Xqc {
  margin-bottom: 50px;
}
.Header_headerDesktop__1fo_m, .Header_headerMobile__sGik2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header_headerMobile__sGik2 {
  display: none;
  height: 90px;
}
.Header_header__Z1Xqc .Header_popup-close-with-text__2lNwj {
  position: relative;
  top: auto;
  right: auto;
}
.Header_headerLink__1SN9j {
  display: flex;
  width: 39px;
  height: 45px;
  text-decoration: none;
}
.Header_headerLogo__2NWMZ {
  width: 130px;
  height: 45px;
}
.Header_headerCloseButton__3Jru6 {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 19px;
  height: 19px;
}
.Header_headerCloseButtonIcon__inhPp {
  width: 100%;
  height: 100%;
}

@media (max-width: 980px) {
  .Header_header__Z1Xqc {
    margin-bottom: 14px;
  }
  .Header_headerDesktop__1fo_m {
    display: none;
  }
  .Header_headerMobile__sGik2 {
    display: flex;
  }
}
.PopupCheck_popupCheck__IWjxV {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.PopupCheck_popupCheckWrapper__2xuOW {
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 50px 100px;
}
.PopupCheck_popupCheckContainer__36cim {
  width: 290px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .PopupCheck_popupCheckWrapper__2xuOW {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  .PopupCheck_popupCheckContainer__36cim {
    width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.mobile-calendar-range-picker {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 23px 70px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
.mobile-calendar-range-picker__calendar {
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(176, 181, 196, 0.1);
}
.mobile-calendar-range-picker__calendar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-calendar-range-picker__calendar-nav {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid rgba(176, 181, 196, 0.1);
  border-radius: 4px;
}
.mobile-calendar-range-picker__calendar-nav > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 10px;
}
.mobile-calendar-range-picker__calendar-nav > button > svg {
  width: 100%;
  height: 100%;
}
.mobile-calendar-range-picker__calendar-nav > button > svg path {
  stroke: #b0b5c4;
}
.mobile-calendar-range-picker__calendar-nav > span {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}
.mobile-calendar-range-picker__calendar-left-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.mobile-calendar-range-picker__chosen-dates {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.mobile-calendar-range-picker__chosen-date {
  font-family: "Circe-Regular", sans-serif;
  width: 50%;
  padding-left: 13.33335px;
  opacity: 0.8;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}
.CalendarMobile_calendarMobile__-cT0a {
  position: relative;
}
.PopupCalendar_animation-text-enter__2gzW8 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupCalendar_animation-text-enter-active__2G9K1 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupCalendar_animation-text-enter-done__2o3dM {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupCalendar_animation-text-exit__2KPJv {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupCalendar_animation-text-exit-active__3BXRY {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupCalendar_delay-100__2YWeP {
  transition-delay: 100ms;
}

.PopupCalendar_delay-200__1XL3n {
  transition-delay: 200ms;
}

.PopupCalendar_delay-300__1BDFN {
  transition-delay: 300ms;
}

.PopupCalendar_delay-400__1PwIy {
  transition-delay: 400ms;
}

.PopupCalendar_delay-500__Fa82Y {
  transition-delay: 500ms;
}

.PopupCalendar_delay-600__1PPFJ {
  transition-delay: 600ms;
}

.PopupCalendar_delay-700__1Y56- {
  transition-delay: 700ms;
}

.PopupCalendar_delay-800__9WdOv {
  transition-delay: 800ms;
}

.PopupCalendar_delay-900__1AQks {
  transition-delay: 900ms;
}

.PopupCalendar_delay-1000__3e-Ta {
  transition-delay: 1000ms;
}

.PopupCalendar_delay-1100__2wq2E {
  transition-delay: 1100ms;
}

.PopupCalendar_delay-1200__27A0f {
  transition-delay: 1200ms;
}

.PopupCalendar_delay-1300__q2I06 {
  transition-delay: 1300ms;
}

.PopupCalendar_delay-1400__3WVGP {
  transition-delay: 1400ms;
}

.PopupCalendar_delay-1500__13wO5 {
  transition-delay: 1500ms;
}

.PopupCalendar_delay-1600__3fYQ2 {
  transition-delay: 1600ms;
}

.PopupCalendar_delay-1700__1znCM {
  transition-delay: 1700ms;
}

.PopupCalendar_delay-1800__1Myp2 {
  transition-delay: 1800ms;
}

.PopupCalendar_delay-1900__x_7T2 {
  transition-delay: 1900ms;
}

.PopupCalendar_delay-2000__1C8HL {
  transition-delay: 2000ms;
}

.PopupCalendar_popupCalendar__3PwJ5 {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.PopupCalendar_popupCalendarWrapper__2IEYa {
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 50px 100px;
}
.PopupCalendar_popupCalendarContainer__2eW5M {
  position: relative;
  width: 100%;
}
.PopupCalendar_popupCalendarTitle__17nJO {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 16px;
  line-height: 36px;
  font-size: 28px;
  color: #1d2e69;
}
.PopupCalendar_popupCalendarDatePicker__2evis {
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 40px;
  border: 1px solid #1d2e69;
  border-radius: 8px;
}
.PopupCalendar_popupCalendarDatePickerTitle__39iYi {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 10px;
  margin-left: 20px;
  line-height: 8px;
  text-transform: uppercase;
  font-size: 8px;
  color: #616a88;
}
.PopupCalendar_popupCalendarDatePickerPeriod__3393V {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 20px;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.PopupCalendar_popupCalendarDatePickerCalendarIcon__2rN_i {
  position: absolute;
  top: 20px;
  right: 16px;
  width: 20px;
  height: 20px;
  opacity: 0.2;
}
.PopupCalendar_popupCalendarDatePickerActive__2moNI {
  border-color: transparent;
  box-shadow: 0 8px 27px rgba(23, 28, 36, 0.14);
}
.PopupCalendar_popupCalendarApplyButton__2Cq0a {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  margin-top: 20px;
  padding: 18px 0;
  border-radius: 8px;
  background-color: #1d2e69;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}
.PopupCalendar_popupCalendarBlock__Lz-Xi {
  position: absolute;
  top: 135px;
  left: 0;
  width: 100%;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 8px 27px rgba(23, 28, 36, 0.14);
  background-color: #fff;
}

@media (max-width: 980px) {
  .PopupCalendar_popupCalendarWrapper__2IEYa {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.PartnerPaymentsTab_partnerPaymentsTab__32npk {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.PartnerPaymentsTab_partnerPaymentsTabContent__2o0JN {
  position: relative;
  width: 790px;
}
.PartnerPaymentsTab_partnerPaymentsTabCheck__33Jvt {
  width: 290px;
}

@media (max-width: 1199px) {
  .PartnerPaymentsTab_partnerPaymentsTabCheck__33Jvt {
    display: none;
  }
}
@media (max-width: 980px) {
  .PartnerPaymentsTab_partnerPaymentsTab__32npk {
    margin-top: 10px;
  }
  .PartnerPaymentsTab_partnerPaymentsTabContent__2o0JN {
    width: 100%;
  }
}
.PartnerNavContainer_partnerNavContainer__2U40j {
  position: relative;
}
.FloatingDetails_floatingDetails__xnZFL {
  position: absolute;
  top: 120px;
  right: 64px;
  width: 663px;
  height: 440px;
}
.FloatingDetails_floatingDetailsShape1__2kVos, .FloatingDetails_floatingDetailsShape2__pP_GV, .FloatingDetails_floatingDetailsShape3__3L_Yz, .FloatingDetails_floatingDetailsShape4__3f_Mj, .FloatingDetails_floatingDetailsShape5__eCbTZ, .FloatingDetails_floatingDetailsShape6__aeqAZ, .FloatingDetails_floatingDetailsShape7__3fWZO, .FloatingDetails_floatingDetailsShape8__3vqUz, .FloatingDetails_floatingDetailsShape9__BzkJF {
  position: absolute;
  pointer-events: none;
}
.FloatingDetails_floatingDetailsShape1__2kVos {
  top: 52.27%;
  left: 93.97%;
  width: 20px;
  height: 20px;
}
.FloatingDetails_floatingDetailsShape2__pP_GV {
  top: 24.09%;
  left: 0;
  width: 20px;
  height: 20px;
}
.FloatingDetails_floatingDetailsShape3__3L_Yz {
  top: 94.42%;
  left: 31.83%;
  width: 23px;
  height: 24px;
}
.FloatingDetails_floatingDetailsShape4__3f_Mj {
  top: 0;
  left: 33.24%;
  width: 44.83px;
  height: 48.21px;
}
.FloatingDetails_floatingDetailsShape5__eCbTZ {
  top: 4.7%;
  left: 59.2%;
  width: 25px;
  height: 27px;
}
.FloatingDetails_floatingDetailsShape6__aeqAZ {
  top: 61.77%;
  left: 70.69%;
  width: 60.28px;
  height: 77.32px;
}
.FloatingDetails_floatingDetailsShape7__3fWZO {
  top: 7.5%;
  left: 48.11%;
  width: 228.04px;
  height: 293.49px;
}
.FloatingDetails_floatingDetailsShape8__3vqUz {
  top: 29.09%;
  left: 45.85%;
  width: 56px;
  height: 59px;
}
.FloatingDetails_floatingDetailsShape9__BzkJF {
  top: 49.59%;
  left: -0.39%;
  width: 84.55px;
  height: 88.45px;
}

@media (max-width: 980px) {
  .FloatingDetails_floatingDetails__xnZFL {
    top: 90px;
    right: 0;
    width: 50%;
    height: 170px;
  }
}
.Footer_footer__2Yl5M {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

@media (max-width: 980px) {
  .Footer_footer__2Yl5M {
    padding: 50px 20px;
  }
  .Footer_footerDesktop__tG4uF {
    display: none;
  }
}
.PartnerBankCard_animation-text-enter__2e-ph {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerBankCard_animation-text-enter-active__Ik-vK {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PartnerBankCard_animation-text-enter-done__1qDRW {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PartnerBankCard_animation-text-exit__6YbFm {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PartnerBankCard_animation-text-exit-active__3G4R7 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PartnerBankCard_delay-100__1nEag {
  transition-delay: 100ms;
}

.PartnerBankCard_delay-200__3kJvC {
  transition-delay: 200ms;
}

.PartnerBankCard_delay-300__1P3Ff {
  transition-delay: 300ms;
}

.PartnerBankCard_delay-400__36E56 {
  transition-delay: 400ms;
}

.PartnerBankCard_delay-500__2g7Zx {
  transition-delay: 500ms;
}

.PartnerBankCard_delay-600__1OT2I {
  transition-delay: 600ms;
}

.PartnerBankCard_delay-700__2gRnl {
  transition-delay: 700ms;
}

.PartnerBankCard_delay-800__rR1uy {
  transition-delay: 800ms;
}

.PartnerBankCard_delay-900__3f9QB {
  transition-delay: 900ms;
}

.PartnerBankCard_delay-1000__1K2-C {
  transition-delay: 1000ms;
}

.PartnerBankCard_delay-1100__1pvlL {
  transition-delay: 1100ms;
}

.PartnerBankCard_delay-1200__2hKG0 {
  transition-delay: 1200ms;
}

.PartnerBankCard_delay-1300__1qm28 {
  transition-delay: 1300ms;
}

.PartnerBankCard_delay-1400__2v1mG {
  transition-delay: 1400ms;
}

.PartnerBankCard_delay-1500__2GYmH {
  transition-delay: 1500ms;
}

.PartnerBankCard_delay-1600__1hV3Z {
  transition-delay: 1600ms;
}

.PartnerBankCard_delay-1700__3se8d {
  transition-delay: 1700ms;
}

.PartnerBankCard_delay-1800__3Vlef {
  transition-delay: 1800ms;
}

.PartnerBankCard_delay-1900__3AVXu {
  transition-delay: 1900ms;
}

.PartnerBankCard_delay-2000__1oye6 {
  transition-delay: 2000ms;
}

.PartnerBankCard_partnerBankCard__xTBqY {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.PartnerBankCard_partnerBankCardWrapper__2RvcI {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 50px 100px;
}
.PartnerBankCard_partnerBankCardFooter__3CySQ {
  margin-top: auto;
}
.PartnerBankCard_partnerBankCardTitle__1vmkU {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 102px;
  line-height: 40px;
  font-size: 32px;
  color: #1d2e69;
}
.PartnerBankCard_partnerBankCardForm__d5y_p {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.PartnerBankCard_partnerBankCardInputWrap__1i7HQ {
  width: 240px;
  height: 60px;
  overflow: hidden;
  border: 1px solid #e4e8ee;
  border-radius: 8px;
  background-color: #fff;
}
.PartnerBankCard_partnerBankCardInputTitle__24EAc {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 10px;
  margin-left: 20px;
  text-transform: uppercase;
  line-height: 8px;
  font-size: 8px;
  color: #616a88;
}
.PartnerBankCard_partnerBankCardInput__gViCQ {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin-top: 8px;
  padding: 0 20px;
  border: 0;
  background-color: transparent;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.PartnerBankCard_partnerBankCardInputWrapEmpty__3SB1t {
  background-color: #f6f8fb;
}
.PartnerBankCard_partnerBankCardInputWrapEmpty__3SB1t .PartnerBankCard_partnerBankCardInput__gViCQ {
  font-family: "Circe-Regular", sans-serif;
  height: 100%;
  margin-top: 0;
  border-color: #1d2e69;
  font-size: 18px;
  color: #b0b5c4;
}
.PartnerBankCard_partnerBankCardButton__n1R4j {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 60px;
  margin-left: 16px;
  border-radius: 8px;
  background-color: #1d2e69;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}
.PartnerBankCard_partnerBankCardButton__n1R4j:disabled {
  cursor: not-allowed;
  background-color: #f6f8fb;
  color: #9da6ba;
}
.PartnerBankCard_partnerBankCardText__3XMie {
  width: 389px;
  margin-top: 50px;
}
.PartnerBankCard_partnerBankCardDescription__ZD6c0, .PartnerBankCard_partnerBankCardNotice__3GT3P {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
}
.PartnerBankCard_partnerBankCardDescription__ZD6c0 {
  color: #616a88;
}
.PartnerBankCard_partnerBankCardNotice__3GT3P {
  margin-top: 16px;
  color: #b0b5c4;
}

@media (max-width: 980px) {
  .PartnerBankCard_partnerBankCardWrapper__2RvcI {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  .PartnerBankCard_partnerBankCardTitle__1vmkU {
    margin-top: 190px;
    line-height: 32px;
    font-size: 24px;
  }
  .PartnerBankCard_partnerBankCardForm__d5y_p {
    flex-direction: column;
    margin-top: 30px;
  }
  .PartnerBankCard_partnerBankCardInputWrap__1i7HQ {
    width: 100%;
  }
  .PartnerBankCard_partnerBankCardButton__n1R4j {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
  .PartnerBankCard_partnerBankCardText__3XMie {
    width: 100%;
    margin-top: 40px;
  }
}
.Overlay_animation-text-enter__31UxA {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Overlay_animation-text-enter-active__1c4Jm {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Overlay_animation-text-enter-done__3b_j9 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.Overlay_animation-text-exit__aQ0Kp {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.Overlay_animation-text-exit-active__1IZEH {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.Overlay_delay-100__3_kBP {
  transition-delay: 100ms;
}

.Overlay_delay-200__1SgfG {
  transition-delay: 200ms;
}

.Overlay_delay-300__TAeI_ {
  transition-delay: 300ms;
}

.Overlay_delay-400__3Myte {
  transition-delay: 400ms;
}

.Overlay_delay-500__3RSKO {
  transition-delay: 500ms;
}

.Overlay_delay-600__3-lVP {
  transition-delay: 600ms;
}

.Overlay_delay-700__2judX {
  transition-delay: 700ms;
}

.Overlay_delay-800__QydhY {
  transition-delay: 800ms;
}

.Overlay_delay-900__3oZle {
  transition-delay: 900ms;
}

.Overlay_delay-1000__2H-m7 {
  transition-delay: 1000ms;
}

.Overlay_delay-1100__nPbkr {
  transition-delay: 1100ms;
}

.Overlay_delay-1200__3qh9a {
  transition-delay: 1200ms;
}

.Overlay_delay-1300__w2a8V {
  transition-delay: 1300ms;
}

.Overlay_delay-1400__3CL0K {
  transition-delay: 1400ms;
}

.Overlay_delay-1500__IKMJa {
  transition-delay: 1500ms;
}

.Overlay_delay-1600__uMcia {
  transition-delay: 1600ms;
}

.Overlay_delay-1700__1W1ah {
  transition-delay: 1700ms;
}

.Overlay_delay-1800__3RSS8 {
  transition-delay: 1800ms;
}

.Overlay_delay-1900___L74f {
  transition-delay: 1900ms;
}

.Overlay_delay-2000__2fIid {
  transition-delay: 2000ms;
}

.Overlay_overlay__uZx2A {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Overlay_overlayBg__2dfzz {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #0a1125;
}
.Overlay_overlayContainer__3hvk8 {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.Overlay_overlayWrapper__24epI {
  position: relative;
  width: 553px;
  margin: auto;
  padding-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 27px rgba(125, 133, 148, 0.16);
  background-color: #fff;
}
.Overlay_overlayMobileHeader__3VzdU {
  display: none;
}
.Overlay_overlayCloseButton__3wG2J {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
  width: 24px;
  height: 24px;
}
.Overlay_overlayCloseIcon__1gZ4t {
  width: 100%;
  height: 100%;
}
.Overlay_overlayCloseIcon__1gZ4t path {
  fill: #1d2e69;
}
.Overlay_overlayTitle__2N--z {
  font-family: "Circe-ExtraBold", sans-serif;
  margin-top: 40px;
  text-align: center;
  line-height: 53px;
  font-size: 36px;
  color: #1d2e69;
}
.Overlay_overlayIcon__3jdJv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  box-shadow: 0 4px 27px #e8ecf2;
  background-color: #fff;
}
.Overlay_overlayIcon__3jdJv > img {
  width: 36%;
  height: 36%;
}
.Overlay_overlayText__3-bwP {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 30px;
  padding: 0 72px;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  color: #b0b5c4;
}
.Overlay_overlayText__3-bwP a {
  color: #1d2e69;
}
.Overlay_overlayMobileFooter__bsItQ {
  display: none;
  margin-top: auto;
}

@media (max-width: 980px) {
  .Overlay_overlay__uZx2A {
    display: block;
    background-color: #fff;
  }
  .Overlay_overlayBg__2dfzz {
    display: none;
  }
  .Overlay_overlayContainer__3hvk8 {
    flex-direction: column;
  }
  .Overlay_overlayWrapper__24epI {
    box-sizing: border-box;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 0;
    box-shadow: none;
  }
  .Overlay_overlayMobileHeader__3VzdU {
    display: block;
  }
  .Overlay_overlayCloseButton__3wG2J {
    display: none;
  }
  .Overlay_overlayTitle__2N--z {
    margin-top: 50px;
  }
  .Overlay_overlayIcon__3jdJv {
    margin-top: 40px;
  }
  .Overlay_overlayText__3-bwP {
    margin-top: 43px;
    padding: 0;
    text-align: left;
  }
  .Overlay_overlayMobileFooter__bsItQ {
    display: inline;
    display: initial;
  }
}
.PopupEditBankCardFail_animation-text-enter__2gMO_ {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupEditBankCardFail_animation-text-enter-active__1zDSy {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupEditBankCardFail_animation-text-enter-done__2uaa8 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupEditBankCardFail_animation-text-exit__35oJI {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupEditBankCardFail_animation-text-exit-active__yO-J_ {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupEditBankCardFail_delay-100__23Uv7 {
  transition-delay: 100ms;
}

.PopupEditBankCardFail_delay-200__2Rd6P {
  transition-delay: 200ms;
}

.PopupEditBankCardFail_delay-300__3tFM2 {
  transition-delay: 300ms;
}

.PopupEditBankCardFail_delay-400__1sJ7J {
  transition-delay: 400ms;
}

.PopupEditBankCardFail_delay-500__g-eHu {
  transition-delay: 500ms;
}

.PopupEditBankCardFail_delay-600__3weyJ {
  transition-delay: 600ms;
}

.PopupEditBankCardFail_delay-700__3XenK {
  transition-delay: 700ms;
}

.PopupEditBankCardFail_delay-800__1sUaF {
  transition-delay: 800ms;
}

.PopupEditBankCardFail_delay-900__2N2se {
  transition-delay: 900ms;
}

.PopupEditBankCardFail_delay-1000__n9_7I {
  transition-delay: 1000ms;
}

.PopupEditBankCardFail_delay-1100__3YXM_ {
  transition-delay: 1100ms;
}

.PopupEditBankCardFail_delay-1200__1CNwx {
  transition-delay: 1200ms;
}

.PopupEditBankCardFail_delay-1300__3Y2eh {
  transition-delay: 1300ms;
}

.PopupEditBankCardFail_delay-1400__1QtDw {
  transition-delay: 1400ms;
}

.PopupEditBankCardFail_delay-1500__2BmZB {
  transition-delay: 1500ms;
}

.PopupEditBankCardFail_delay-1600__2ePvS {
  transition-delay: 1600ms;
}

.PopupEditBankCardFail_delay-1700__38L8d {
  transition-delay: 1700ms;
}

.PopupEditBankCardFail_delay-1800__2DIwA {
  transition-delay: 1800ms;
}

.PopupEditBankCardFail_delay-1900__3NZmV {
  transition-delay: 1900ms;
}

.PopupEditBankCardFail_delay-2000__1wjO1 {
  transition-delay: 2000ms;
}

.PopupEditBankCardFail_popupEditBankCardFailNav__2qhcE {
  display: flex;
  justify-content: center;
  margin-top: 64px;
}
.PopupEditBankCardFail_popupEditBankCardFailNav__2qhcE button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  padding: 18px 63px;
  border-radius: 8px;
  background-color: #1d2e69;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}

@media (max-width: 980px) {
  .PopupEditBankCardFail_popupEditBankCardFailNav__2qhcE button {
    width: 100%;
  }
}
.PopupEditBankCardSuccess_animation-text-enter__10eBu {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupEditBankCardSuccess_animation-text-enter-active__2Bm66 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupEditBankCardSuccess_animation-text-enter-done__7eNWJ {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupEditBankCardSuccess_animation-text-exit__1pAsj {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupEditBankCardSuccess_animation-text-exit-active__2lEfU {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupEditBankCardSuccess_delay-100__2tbHF {
  transition-delay: 100ms;
}

.PopupEditBankCardSuccess_delay-200__11FKU {
  transition-delay: 200ms;
}

.PopupEditBankCardSuccess_delay-300__1ROTt {
  transition-delay: 300ms;
}

.PopupEditBankCardSuccess_delay-400__21rH6 {
  transition-delay: 400ms;
}

.PopupEditBankCardSuccess_delay-500__1UtJ_ {
  transition-delay: 500ms;
}

.PopupEditBankCardSuccess_delay-600__1r0n4 {
  transition-delay: 600ms;
}

.PopupEditBankCardSuccess_delay-700__2awip {
  transition-delay: 700ms;
}

.PopupEditBankCardSuccess_delay-800__1YWCb {
  transition-delay: 800ms;
}

.PopupEditBankCardSuccess_delay-900__36n6U {
  transition-delay: 900ms;
}

.PopupEditBankCardSuccess_delay-1000__N2wbO {
  transition-delay: 1000ms;
}

.PopupEditBankCardSuccess_delay-1100__3FKRA {
  transition-delay: 1100ms;
}

.PopupEditBankCardSuccess_delay-1200__1fwUC {
  transition-delay: 1200ms;
}

.PopupEditBankCardSuccess_delay-1300__3d739 {
  transition-delay: 1300ms;
}

.PopupEditBankCardSuccess_delay-1400__3I5fA {
  transition-delay: 1400ms;
}

.PopupEditBankCardSuccess_delay-1500__2Pzwy {
  transition-delay: 1500ms;
}

.PopupEditBankCardSuccess_delay-1600__1X7e4 {
  transition-delay: 1600ms;
}

.PopupEditBankCardSuccess_delay-1700__3qYmi {
  transition-delay: 1700ms;
}

.PopupEditBankCardSuccess_delay-1800__3qTua {
  transition-delay: 1800ms;
}

.PopupEditBankCardSuccess_delay-1900__2MqKu {
  transition-delay: 1900ms;
}

.PopupEditBankCardSuccess_delay-2000__ZoNa3 {
  transition-delay: 2000ms;
}

.PopupEditBankCardSuccess_popupEditBankCardSuccessNav__1g2dU {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.PopupEditBankCardSuccess_popupEditBankCardSuccessNav__1g2dU button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  padding: 18px 28px;
  border-radius: 8px;
  background-color: #1d2e69;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}

@media (max-width: 980px) {
  .PopupEditBankCardSuccess_popupEditBankCardSuccessNav__1g2dU button {
    width: 100%;
  }
}
.ScrollToTopButton_animation-text-enter__1pFz- {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.ScrollToTopButton_animation-text-enter-active__bGsCo {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.ScrollToTopButton_animation-text-enter-done__1BkmC {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.ScrollToTopButton_animation-text-exit__2pnDr {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.ScrollToTopButton_animation-text-exit-active__25Vre {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.ScrollToTopButton_delay-100__2ylPD {
  transition-delay: 100ms;
}

.ScrollToTopButton_delay-200__X4Oaj {
  transition-delay: 200ms;
}

.ScrollToTopButton_delay-300__2GKHd {
  transition-delay: 300ms;
}

.ScrollToTopButton_delay-400__2d_hp {
  transition-delay: 400ms;
}

.ScrollToTopButton_delay-500__13yTe {
  transition-delay: 500ms;
}

.ScrollToTopButton_delay-600__6xHA3 {
  transition-delay: 600ms;
}

.ScrollToTopButton_delay-700__1sB6n {
  transition-delay: 700ms;
}

.ScrollToTopButton_delay-800__gVLjN {
  transition-delay: 800ms;
}

.ScrollToTopButton_delay-900__286cw {
  transition-delay: 900ms;
}

.ScrollToTopButton_delay-1000__1KHH8 {
  transition-delay: 1000ms;
}

.ScrollToTopButton_delay-1100__2X9xi {
  transition-delay: 1100ms;
}

.ScrollToTopButton_delay-1200__FxzKs {
  transition-delay: 1200ms;
}

.ScrollToTopButton_delay-1300__3OHph {
  transition-delay: 1300ms;
}

.ScrollToTopButton_delay-1400__IM_l- {
  transition-delay: 1400ms;
}

.ScrollToTopButton_delay-1500__1f53n {
  transition-delay: 1500ms;
}

.ScrollToTopButton_delay-1600__3UQrj {
  transition-delay: 1600ms;
}

.ScrollToTopButton_delay-1700__23Lu4 {
  transition-delay: 1700ms;
}

.ScrollToTopButton_delay-1800__3IWuo {
  transition-delay: 1800ms;
}

.ScrollToTopButton_delay-1900__3udkF {
  transition-delay: 1900ms;
}

.ScrollToTopButton_delay-2000__rsRZb {
  transition-delay: 2000ms;
}

.ScrollToTopButton_scrollToTopButton__2-MdT {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 50px;
  bottom: 120px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  -webkit-perspective: 0;
          perspective: 0;
  opacity: 0;
  border-radius: 50%;
  background-color: #f6f8fb;
  transition: opacity 0.3s;
}
.ScrollToTopButton_scrollToTopButton__2-MdT:hover {
  opacity: 1;
}
.ScrollToTopButton_scrollToTopButtonVisible__2BZ4A {
  pointer-events: auto;
  opacity: 0.8;
}
.ScrollToTopButton_scrollToTopButtonIcon__2-t9d {
  width: 20px;
  height: 20px;
}
.ScrollToTopButton_scrollToTopButtonIcon__2-t9d path {
  fill: #1d2469;
}

@media (max-width: 600px) {
  .ScrollToTopButton_scrollToTopButton__2-MdT {
    right: 8px;
  }
}
.PopupHeader_animation-text-enter__2_BRl {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupHeader_animation-text-enter-active__3ElIo {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupHeader_animation-text-enter-done__2CSXH {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupHeader_animation-text-exit__kCZem {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupHeader_animation-text-exit-active__2C5kp {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupHeader_delay-100__1_e4a {
  transition-delay: 100ms;
}

.PopupHeader_delay-200__IGN4E {
  transition-delay: 200ms;
}

.PopupHeader_delay-300__2t00L {
  transition-delay: 300ms;
}

.PopupHeader_delay-400__xbOYD {
  transition-delay: 400ms;
}

.PopupHeader_delay-500__3atTY {
  transition-delay: 500ms;
}

.PopupHeader_delay-600__iS62M {
  transition-delay: 600ms;
}

.PopupHeader_delay-700__u0gWc {
  transition-delay: 700ms;
}

.PopupHeader_delay-800__21mDy {
  transition-delay: 800ms;
}

.PopupHeader_delay-900__30TcF {
  transition-delay: 900ms;
}

.PopupHeader_delay-1000__ODwu1 {
  transition-delay: 1000ms;
}

.PopupHeader_delay-1100__UKI18 {
  transition-delay: 1100ms;
}

.PopupHeader_delay-1200__2VPwZ {
  transition-delay: 1200ms;
}

.PopupHeader_delay-1300__3qHYJ {
  transition-delay: 1300ms;
}

.PopupHeader_delay-1400__35km0 {
  transition-delay: 1400ms;
}

.PopupHeader_delay-1500__26qMZ {
  transition-delay: 1500ms;
}

.PopupHeader_delay-1600__22ufc {
  transition-delay: 1600ms;
}

.PopupHeader_delay-1700__1jTzd {
  transition-delay: 1700ms;
}

.PopupHeader_delay-1800__3niFv {
  transition-delay: 1800ms;
}

.PopupHeader_delay-1900__vUFpw {
  transition-delay: 1900ms;
}

.PopupHeader_delay-2000__1jW3C {
  transition-delay: 2000ms;
}

.PopupHeader_popupHeader__1IW4g {
  padding: 50px 0;
}
@media (max-width: 992px) {
  .PopupHeader_popupHeader__1IW4g {
    padding: 27px 0;
  }
}
.PopupHeader_popupHeaderDesktop__3mJXg, .PopupHeader_popupHeaderMobile__1nGTw {
  justify-content: space-between;
  align-items: center;
}
.PopupHeader_popupHeaderDesktop__3mJXg {
  display: flex;
}
@media (max-width: 992px) {
  .PopupHeader_popupHeaderDesktop__3mJXg {
    display: none;
  }
}
.PopupHeader_popupHeaderMobile__1nGTw {
  display: none;
}
@media (max-width: 992px) {
  .PopupHeader_popupHeaderMobile__1nGTw {
    display: flex;
  }
}
.PopupHeader_popupHeaderLink__3CAFT {
  display: flex;
  align-items: center;
  width: 39px;
  height: 45px;
  text-decoration: none;
}
.PopupHeader_popupHeaderDesktopClose__-2QY5 {
  position: relative;
  top: 0;
  right: 0;
}
.PopupHeader_popupHeaderClose__N0tF0 {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 19px;
  height: 19px;
}
.PopupHeader_popupHeaderCloseIcon__34OTz {
  width: 100%;
  height: 100%;
}
.PopupFooter_animation-text-enter__1Bmty {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupFooter_animation-text-enter-active__3mumM {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupFooter_animation-text-enter-done__3fcIu {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupFooter_animation-text-exit__sb0br {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupFooter_animation-text-exit-active__2nzna {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupFooter_delay-100__z6QKr {
  transition-delay: 100ms;
}

.PopupFooter_delay-200__-W4mD {
  transition-delay: 200ms;
}

.PopupFooter_delay-300__2Mo5N {
  transition-delay: 300ms;
}

.PopupFooter_delay-400__1i7iv {
  transition-delay: 400ms;
}

.PopupFooter_delay-500__3HumZ {
  transition-delay: 500ms;
}

.PopupFooter_delay-600__1kZdY {
  transition-delay: 600ms;
}

.PopupFooter_delay-700__28fL6 {
  transition-delay: 700ms;
}

.PopupFooter_delay-800__2ilqr {
  transition-delay: 800ms;
}

.PopupFooter_delay-900__np8we {
  transition-delay: 900ms;
}

.PopupFooter_delay-1000__1Zmlj {
  transition-delay: 1000ms;
}

.PopupFooter_delay-1100__2WC_p {
  transition-delay: 1100ms;
}

.PopupFooter_delay-1200__3azHk {
  transition-delay: 1200ms;
}

.PopupFooter_delay-1300__1IF6C {
  transition-delay: 1300ms;
}

.PopupFooter_delay-1400__1ffFa {
  transition-delay: 1400ms;
}

.PopupFooter_delay-1500__3au7N {
  transition-delay: 1500ms;
}

.PopupFooter_delay-1600__AeSl4 {
  transition-delay: 1600ms;
}

.PopupFooter_delay-1700__3bCnh {
  transition-delay: 1700ms;
}

.PopupFooter_delay-1800__1VNNq {
  transition-delay: 1800ms;
}

.PopupFooter_delay-1900__1xOwg {
  transition-delay: 1900ms;
}

.PopupFooter_delay-2000__3BSRy {
  transition-delay: 2000ms;
}

.PopupFooter_popupFooter__5aD9l {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 50px;
  background-color: var(--pale-grey);
}
@media (max-width: 480px) {
  .PopupFooter_popupFooter__5aD9l {
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
  }
}
.PopupFooter_popupFooterLinks__1fgOC {
  display: flex;
  align-items: center;
}
.PopupFooter_popupFooterLinks__1fgOC > p {
  font-family: "Circe-Regular", sans-serif;
  font-size: 14px;
  color: var(--light-grey-blue);
}
.PopupFooter_popupFooterLink__3jyBH {
  margin-left: 28px;
  text-decoration: none;
}
.PopupFooter_popupFooterLinkIconAppStore__MQeZR {
  width: 18px;
  height: 22px;
}
.PopupFooter_popupFooterLinkIconGooglePlay__kK_hD {
  width: 16px;
  height: 18px;
  margin-top: 4px;
}
.PopupFooter_popupFooterSupport__2kdq5 {
  font-family: "Circe-Regular", sans-serif;
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: 14px;
  color: var(--light-grey-blue);
}
@media (max-width: 480px) {
  .PopupFooter_popupFooterSupport__2kdq5 {
    display: none;
  }
}
.PopupFooter_popupFooterSupport__2kdq5:hover {
  text-decoration: none;
}
.PopupFooter_popupFooterSupport__2kdq5:hover > div {
  -webkit-animation: PopupFooter_support-button-shaking__1FqWd 0.2s ease-in-out forwards;
          animation: PopupFooter_support-button-shaking__1FqWd 0.2s ease-in-out forwards;
  -webkit-animation-iteration-count: 3;
          animation-iteration-count: 3;
}
.PopupFooter_popupFooterSupportIconWrapper__3jxOt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-left: 17px;
  border-radius: 50%;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
}
.PopupFooter_popupFooterSupportIcon__1kpsQ {
  width: 21px;
  height: 21px;
}

@-webkit-keyframes PopupFooter_support-button-shaking__1FqWd {
  from, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
}

@keyframes PopupFooter_support-button-shaking__1FqWd {
  from, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
}
.Dialog_animation-text-enter__1QSGw {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Dialog_animation-text-enter-active__2pqZa {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Dialog_animation-text-enter-done__32Ao4 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.Dialog_animation-text-exit__38RuO {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.Dialog_animation-text-exit-active__xtgbt {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.Dialog_delay-100__10AIs {
  transition-delay: 100ms;
}

.Dialog_delay-200__1Kn58 {
  transition-delay: 200ms;
}

.Dialog_delay-300__BOKmR {
  transition-delay: 300ms;
}

.Dialog_delay-400__8s6L9 {
  transition-delay: 400ms;
}

.Dialog_delay-500__s_RZt {
  transition-delay: 500ms;
}

.Dialog_delay-600__2U8VV {
  transition-delay: 600ms;
}

.Dialog_delay-700__WJS8R {
  transition-delay: 700ms;
}

.Dialog_delay-800__3To19 {
  transition-delay: 800ms;
}

.Dialog_delay-900__1WiCQ {
  transition-delay: 900ms;
}

.Dialog_delay-1000__1B4UU {
  transition-delay: 1000ms;
}

.Dialog_delay-1100__n0FlO {
  transition-delay: 1100ms;
}

.Dialog_delay-1200__3dDs1 {
  transition-delay: 1200ms;
}

.Dialog_delay-1300__2Cu8M {
  transition-delay: 1300ms;
}

.Dialog_delay-1400__1h30G {
  transition-delay: 1400ms;
}

.Dialog_delay-1500__3B1L_ {
  transition-delay: 1500ms;
}

.Dialog_delay-1600__Hlvj7 {
  transition-delay: 1600ms;
}

.Dialog_delay-1700__29lrR {
  transition-delay: 1700ms;
}

.Dialog_delay-1800__1LPi7 {
  transition-delay: 1800ms;
}

.Dialog_delay-1900__6v798 {
  transition-delay: 1900ms;
}

.Dialog_delay-2000__1okr_ {
  transition-delay: 2000ms;
}

.Dialog_dialog__3_mHm {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 480px) {
  .Dialog_dialog__3_mHm {
    display: block;
    background-color: #fff;
  }
}
.Dialog_dialogBg__12Ubs {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #0a1125;
}
@media (max-width: 480px) {
  .Dialog_dialogBg__12Ubs {
    display: none;
  }
}
.Dialog_dialogContainer__3nzDE {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 480px) {
  .Dialog_dialogContainer__3nzDE {
    flex-direction: column;
  }
}
.Dialog_dialogWrapper__30nUT {
  position: relative;
  width: 553px;
  margin: auto;
  padding: 0 75px 60px 75px;
  border-radius: 10px;
  box-shadow: 0 4px 27px rgba(125, 133, 148, 0.16);
  background-color: #fff;
}
@media (max-width: 480px) {
  .Dialog_dialogWrapper__30nUT {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 0;
    box-shadow: none;
  }
}
.Dialog_dialogMobileHeader__bSX_E, .Dialog_dialogMobileFooter__3UqUp {
  display: none;
}
@media (max-width: 480px) {
  .Dialog_dialogMobileHeader__bSX_E {
    display: block;
  }
}
@media (max-width: 480px) {
  .Dialog_dialogMobileFooter__3UqUp {
    display: flex;
  }
}
.Dialog_dialogCloseButton__3i0rc {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
  width: 24px;
  height: 24px;
}
@media (max-width: 480px) {
  .Dialog_dialogCloseButton__3i0rc {
    display: none;
  }
}
.Dialog_dialogCloseButtonIcon__3S39h {
  width: 100%;
  height: 100%;
}
.Dialog_dialogCloseButtonIcon__3S39h path {
  fill: var(--dark-slate-blue);
}
.SupportButton_animation-text-enter__2W7Z3 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.SupportButton_animation-text-enter-active__2H7gg {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.SupportButton_animation-text-enter-done__3HObP {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.SupportButton_animation-text-exit__1ib73 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.SupportButton_animation-text-exit-active__sx_Di {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.SupportButton_delay-100__2jQEa {
  transition-delay: 100ms;
}

.SupportButton_delay-200__1Gtui {
  transition-delay: 200ms;
}

.SupportButton_delay-300__1ZHb_ {
  transition-delay: 300ms;
}

.SupportButton_delay-400__3Hwir {
  transition-delay: 400ms;
}

.SupportButton_delay-500__1Y1_V {
  transition-delay: 500ms;
}

.SupportButton_delay-600__2qwdN {
  transition-delay: 600ms;
}

.SupportButton_delay-700__1RlbS {
  transition-delay: 700ms;
}

.SupportButton_delay-800__gbq2r {
  transition-delay: 800ms;
}

.SupportButton_delay-900__1TVJP {
  transition-delay: 900ms;
}

.SupportButton_delay-1000__1OtlH {
  transition-delay: 1000ms;
}

.SupportButton_delay-1100__1dBRm {
  transition-delay: 1100ms;
}

.SupportButton_delay-1200__3w7jJ {
  transition-delay: 1200ms;
}

.SupportButton_delay-1300__1Y0ue {
  transition-delay: 1300ms;
}

.SupportButton_delay-1400__cqCGV {
  transition-delay: 1400ms;
}

.SupportButton_delay-1500__rfxQO {
  transition-delay: 1500ms;
}

.SupportButton_delay-1600__1D9sx {
  transition-delay: 1600ms;
}

.SupportButton_delay-1700__2YIn3 {
  transition-delay: 1700ms;
}

.SupportButton_delay-1800__ix6at {
  transition-delay: 1800ms;
}

.SupportButton_delay-1900__TVj5w {
  transition-delay: 1900ms;
}

.SupportButton_delay-2000__cgjJP {
  transition-delay: 2000ms;
}

.SupportButton_supportButton__3WhAY {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.PopupBalance_animation-text-enter__12Q9q {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupBalance_animation-text-enter-active__2EyKs {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupBalance_animation-text-enter-done__sE2rV {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupBalance_animation-text-exit__283mq {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupBalance_animation-text-exit-active__ZX3Gm {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupBalance_delay-100__1j1KH {
  transition-delay: 100ms;
}

.PopupBalance_delay-200__1Qgd9 {
  transition-delay: 200ms;
}

.PopupBalance_delay-300__3BUmw {
  transition-delay: 300ms;
}

.PopupBalance_delay-400__gwGxN {
  transition-delay: 400ms;
}

.PopupBalance_delay-500__1owbo {
  transition-delay: 500ms;
}

.PopupBalance_delay-600__3CJnR {
  transition-delay: 600ms;
}

.PopupBalance_delay-700__1mPWv {
  transition-delay: 700ms;
}

.PopupBalance_delay-800__2W9kB {
  transition-delay: 800ms;
}

.PopupBalance_delay-900__3rzWx {
  transition-delay: 900ms;
}

.PopupBalance_delay-1000__lMUiO {
  transition-delay: 1000ms;
}

.PopupBalance_delay-1100__7Ix62 {
  transition-delay: 1100ms;
}

.PopupBalance_delay-1200__1MjSA {
  transition-delay: 1200ms;
}

.PopupBalance_delay-1300__rrk1r {
  transition-delay: 1300ms;
}

.PopupBalance_delay-1400__3xx7l {
  transition-delay: 1400ms;
}

.PopupBalance_delay-1500__15TmJ {
  transition-delay: 1500ms;
}

.PopupBalance_delay-1600__a2rvN {
  transition-delay: 1600ms;
}

.PopupBalance_delay-1700__2wTb_ {
  transition-delay: 1700ms;
}

.PopupBalance_delay-1800__EZGpZ {
  transition-delay: 1800ms;
}

.PopupBalance_delay-1900__3AsjP {
  transition-delay: 1900ms;
}

.PopupBalance_delay-2000__3u8hm {
  transition-delay: 2000ms;
}

.PopupBalance_popupBalanceTitle__2h1-k {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
}
.PopupBalance_popupBalanceText__eXhMP {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 16px;
  line-height: 24px;
  font-size: 18px;
  color: var(--light-grey-blue);
}
.PopupBalance_popupBalanceText__eXhMP > a {
  text-decoration: none;
  color: var(--deep-sky-blue);
}
.PopupBalance_popupBalanceText__eXhMP > a:hover {
  text-decoration: underline;
}
@media (max-width: 480px) {
  .PopupBalance_popupBalanceText__eXhMP {
    padding: 0;
  }
}
.PopupBalance_popupBalanceNav__3QtwN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
@media (max-width: 480px) {
  .PopupBalance_popupBalanceNav__3QtwN {
    flex-direction: column;
  }
}
.PopupBalance_popupBalanceNavButton__9Z-7a {
  width: calc(50% - 10px);
}
@media (max-width: 480px) {
  .PopupBalance_popupBalanceNavButton__9Z-7a {
    width: 100%;
  }
  .PopupBalance_popupBalanceNavButton__9Z-7a:first-child {
    order: 2;
    margin-top: 20px;
  }
}
.PopupBalance_popupBalanceNavLeftButton__2AVdx {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border: 1px solid var(--grey-blue);
  border-radius: 8px;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-blue);
}
.Popup_animation-text-enter__3s1jA {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Popup_animation-text-enter-active__3cbxu {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.Popup_animation-text-enter-done__1y-C5 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.Popup_animation-text-exit__2_KwY {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.Popup_animation-text-exit-active__11MoB {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.Popup_delay-100__14U3t {
  transition-delay: 100ms;
}

.Popup_delay-200__1fJuV {
  transition-delay: 200ms;
}

.Popup_delay-300__2HVAF {
  transition-delay: 300ms;
}

.Popup_delay-400__2W0QW {
  transition-delay: 400ms;
}

.Popup_delay-500__9ipMV {
  transition-delay: 500ms;
}

.Popup_delay-600__ez7V2 {
  transition-delay: 600ms;
}

.Popup_delay-700__wBncl {
  transition-delay: 700ms;
}

.Popup_delay-800__1IHLs {
  transition-delay: 800ms;
}

.Popup_delay-900__1an6l {
  transition-delay: 900ms;
}

.Popup_delay-1000__1HPlW {
  transition-delay: 1000ms;
}

.Popup_delay-1100__3YoiR {
  transition-delay: 1100ms;
}

.Popup_delay-1200__3SKv_ {
  transition-delay: 1200ms;
}

.Popup_delay-1300__2Pxxq {
  transition-delay: 1300ms;
}

.Popup_delay-1400__10vCR {
  transition-delay: 1400ms;
}

.Popup_delay-1500__2g3BC {
  transition-delay: 1500ms;
}

.Popup_delay-1600__1yNH8 {
  transition-delay: 1600ms;
}

.Popup_delay-1700__TK9Ry {
  transition-delay: 1700ms;
}

.Popup_delay-1800__3hIhH {
  transition-delay: 1800ms;
}

.Popup_delay-1900__2oIBM {
  transition-delay: 1900ms;
}

.Popup_delay-2000__Ku2Xn {
  transition-delay: 2000ms;
}

.Popup_popup__1fG2G {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.Popup_popupContainer__2Aydw {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.Popup_popupWrapper__13iGX {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 50px 100px;
}
@media (max-width: 480px) {
  .Popup_popupWrapper__13iGX {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.Popup_popupTitle__QimRi {
  font-family: "Circe-Bold", sans-serif;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(157, 166, 186, 0.5);
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
}
@media (max-width: 480px) {
  .Popup_popupTitle__QimRi {
    box-sizing: border-box;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 0 20px;
  }
}
.Popup_popupContent__3LCUY {
  position: relative;
}
.Popup_popupMobileHeader__3Mwvf {
  display: none;
}
.PopupConfirmation_animation-text-enter__1y1a9 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupConfirmation_animation-text-enter-active__1Dgxu {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupConfirmation_animation-text-enter-done__2Lucx {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupConfirmation_animation-text-exit__34wBB {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupConfirmation_animation-text-exit-active__1_He1 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupConfirmation_delay-100__2xP_T {
  transition-delay: 100ms;
}

.PopupConfirmation_delay-200__3Sc7- {
  transition-delay: 200ms;
}

.PopupConfirmation_delay-300__1cmVp {
  transition-delay: 300ms;
}

.PopupConfirmation_delay-400__3PIrf {
  transition-delay: 400ms;
}

.PopupConfirmation_delay-500__1lfmU {
  transition-delay: 500ms;
}

.PopupConfirmation_delay-600__48oR9 {
  transition-delay: 600ms;
}

.PopupConfirmation_delay-700__3_T6L {
  transition-delay: 700ms;
}

.PopupConfirmation_delay-800__2rHAM {
  transition-delay: 800ms;
}

.PopupConfirmation_delay-900__3mQss {
  transition-delay: 900ms;
}

.PopupConfirmation_delay-1000__ZcQZ0 {
  transition-delay: 1000ms;
}

.PopupConfirmation_delay-1100__Uzr3G {
  transition-delay: 1100ms;
}

.PopupConfirmation_delay-1200__2Hej5 {
  transition-delay: 1200ms;
}

.PopupConfirmation_delay-1300__M3LyH {
  transition-delay: 1300ms;
}

.PopupConfirmation_delay-1400__1-1-O {
  transition-delay: 1400ms;
}

.PopupConfirmation_delay-1500__3y-Ap {
  transition-delay: 1500ms;
}

.PopupConfirmation_delay-1600__28XQ3 {
  transition-delay: 1600ms;
}

.PopupConfirmation_delay-1700__3KuqM {
  transition-delay: 1700ms;
}

.PopupConfirmation_delay-1800__12hGt {
  transition-delay: 1800ms;
}

.PopupConfirmation_delay-1900__15Fxt {
  transition-delay: 1900ms;
}

.PopupConfirmation_delay-2000__1GBPD {
  transition-delay: 2000ms;
}

.PopupConfirmation_popupConfirmationTitle__1YpVX {
  font-family: "Circe-Bold", sans-serif;
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
}
@media (max-width: 480px) {
  .PopupConfirmation_popupConfirmationTitle__1YpVX {
    line-height: 32px;
    font-size: 24px;
  }
}
.PopupConfirmation_popupConfirmationContent__1krAg {
  margin-top: 20px;
}
.PopupConfirmation_popupConfirmationMessage1__1cWBs {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-blue);
}
@media (max-width: 480px) {
  .PopupConfirmation_popupConfirmationMessage1__1cWBs {
    width: 100%;
  }
}
.PopupConfirmation_popupConfirmationMessage2__Lv2hi {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: var(--light-grey-blue);
}
.PopupConfirmation_popupConfirmationMessage3__Ae_KV {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 14px;
  color: var(--reddish-pink);
}
.PopupConfirmation_popupConfirmationCode__1aq3g {
  margin-top: 30px;
}
.PopupConfirmation_popupConfirmationSendSmsButton__KWLUv {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  background-color: transparent;
  line-height: 20px;
  text-decoration: underline;
  font-size: 14px;
  color: var(--dusk-blue);
}
.PopupConfirmation_popupConfirmationMessages__3kS6W {
  margin-top: 30px;
}
.PopupUnprocessingOperation_animation-text-enter__2lkcm {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupUnprocessingOperation_animation-text-enter-active__3K5pJ {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupUnprocessingOperation_animation-text-enter-done__3V3B9 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupUnprocessingOperation_animation-text-exit__15RlB {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupUnprocessingOperation_animation-text-exit-active__1lhBr {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupUnprocessingOperation_delay-100__32UtS {
  transition-delay: 100ms;
}

.PopupUnprocessingOperation_delay-200__2y-c3 {
  transition-delay: 200ms;
}

.PopupUnprocessingOperation_delay-300__3-TSK {
  transition-delay: 300ms;
}

.PopupUnprocessingOperation_delay-400__MnlGt {
  transition-delay: 400ms;
}

.PopupUnprocessingOperation_delay-500__1b__J {
  transition-delay: 500ms;
}

.PopupUnprocessingOperation_delay-600__2yHG4 {
  transition-delay: 600ms;
}

.PopupUnprocessingOperation_delay-700__rq-U0 {
  transition-delay: 700ms;
}

.PopupUnprocessingOperation_delay-800__xK_u7 {
  transition-delay: 800ms;
}

.PopupUnprocessingOperation_delay-900__3siAF {
  transition-delay: 900ms;
}

.PopupUnprocessingOperation_delay-1000__2Wfn8 {
  transition-delay: 1000ms;
}

.PopupUnprocessingOperation_delay-1100___3pzf {
  transition-delay: 1100ms;
}

.PopupUnprocessingOperation_delay-1200__1m4xe {
  transition-delay: 1200ms;
}

.PopupUnprocessingOperation_delay-1300__2wPmN {
  transition-delay: 1300ms;
}

.PopupUnprocessingOperation_delay-1400__1VleQ {
  transition-delay: 1400ms;
}

.PopupUnprocessingOperation_delay-1500__2Jxim {
  transition-delay: 1500ms;
}

.PopupUnprocessingOperation_delay-1600__3Zg9k {
  transition-delay: 1600ms;
}

.PopupUnprocessingOperation_delay-1700__2ylUE {
  transition-delay: 1700ms;
}

.PopupUnprocessingOperation_delay-1800__3zuba {
  transition-delay: 1800ms;
}

.PopupUnprocessingOperation_delay-1900__15yIx {
  transition-delay: 1900ms;
}

.PopupUnprocessingOperation_delay-2000__101UW {
  transition-delay: 2000ms;
}

.PopupUnprocessingOperation_popupUnprocessingOperationTitle__1hiBt {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
}
.PopupUnprocessingOperation_popupUnprocessingOperationText__JLAD0 {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 16px;
  line-height: 24px;
  font-size: 18px;
  color: var(--light-grey-blue);
}
@media (max-width: 480px) {
  .PopupUnprocessingOperation_popupUnprocessingOperationText__JLAD0 {
    padding: 0;
  }
}
.PopupUnprocessingOperation_popupUnprocessingOperationNav__3QFJx {
  margin-top: 50px;
}
.PopupUnprocessingOperation_popupUnprocessingOperationNavButton__V6zfQ {
  width: 100%;
}
@charset "UTF-8";
.PopupSuccess_animation-text-enter__1tvFP {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupSuccess_animation-text-enter-active__1xekU {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupSuccess_animation-text-enter-done__1pLt6 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupSuccess_animation-text-exit__1Ua4D {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupSuccess_animation-text-exit-active__3HdMm {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupSuccess_delay-100__3y2p_ {
  transition-delay: 100ms;
}

.PopupSuccess_delay-200__1AP0w {
  transition-delay: 200ms;
}

.PopupSuccess_delay-300__1Qg95 {
  transition-delay: 300ms;
}

.PopupSuccess_delay-400__1K_9X {
  transition-delay: 400ms;
}

.PopupSuccess_delay-500__ZBngH {
  transition-delay: 500ms;
}

.PopupSuccess_delay-600__3ApX4 {
  transition-delay: 600ms;
}

.PopupSuccess_delay-700__3kYSf {
  transition-delay: 700ms;
}

.PopupSuccess_delay-800__hq_uD {
  transition-delay: 800ms;
}

.PopupSuccess_delay-900__1Bukk {
  transition-delay: 900ms;
}

.PopupSuccess_delay-1000__3I4Wh {
  transition-delay: 1000ms;
}

.PopupSuccess_delay-1100__2xlCy {
  transition-delay: 1100ms;
}

.PopupSuccess_delay-1200__2o60c {
  transition-delay: 1200ms;
}

.PopupSuccess_delay-1300__2dE-1 {
  transition-delay: 1300ms;
}

.PopupSuccess_delay-1400__M0OCU {
  transition-delay: 1400ms;
}

.PopupSuccess_delay-1500__Upcw3 {
  transition-delay: 1500ms;
}

.PopupSuccess_delay-1600__3G1S1 {
  transition-delay: 1600ms;
}

.PopupSuccess_delay-1700__1CRQy {
  transition-delay: 1700ms;
}

.PopupSuccess_delay-1800__3ChtP {
  transition-delay: 1800ms;
}

.PopupSuccess_delay-1900__3cvII {
  transition-delay: 1900ms;
}

.PopupSuccess_delay-2000__3kpg5 {
  transition-delay: 2000ms;
}

.PopupSuccess_popupSuccess__yb4Sg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
}
.PopupSuccess_popupSuccessTitle__1PdtR {
  font-family: "Circe-Bold", sans-serif;
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
  text-align: center;
}
@media (max-width: 480px) {
  .PopupSuccess_popupSuccessTitle__1PdtR {
    line-height: 32px;
    font-size: 24px;
  }
}
.PopupSuccess_popupSuccessContent__1-_4_ {
  margin-top: 20px;
}
.PopupSuccess_popupSuccessContent__1-_4_ > p {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 440px;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-blue);
  text-align: center;
}
@media (max-width: 480px) {
  .PopupSuccess_popupSuccessContent__1-_4_ > p {
    width: 100%;
  }
}
.PopupSuccess_popupSuccessButton__rmno0 {
  width: 220px;
  margin-top: 50px;
  font-family: "Circe-Bold", sans-serif;
  /* TODO: возможно потребуется раскоментатить после соглосования макета 
   * по ссылке https://www.figma.com/file/WCeDQghWyZcWKFLTF9sFyx/Web?node-id=9786%3A148016
   */
}
.DialogError_animation-text-enter__1rZzH {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.DialogError_animation-text-enter-active__13-b9 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.DialogError_animation-text-enter-done__dz4Ux {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.DialogError_animation-text-exit__M7i3X {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.DialogError_animation-text-exit-active__3KPmA {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.DialogError_delay-100__3WIxZ {
  transition-delay: 100ms;
}

.DialogError_delay-200__3RxPv {
  transition-delay: 200ms;
}

.DialogError_delay-300__J8ssn {
  transition-delay: 300ms;
}

.DialogError_delay-400__24TsQ {
  transition-delay: 400ms;
}

.DialogError_delay-500__3vy5V {
  transition-delay: 500ms;
}

.DialogError_delay-600__3M-Bs {
  transition-delay: 600ms;
}

.DialogError_delay-700__1mlLO {
  transition-delay: 700ms;
}

.DialogError_delay-800__NcPQz {
  transition-delay: 800ms;
}

.DialogError_delay-900__LqWjP {
  transition-delay: 900ms;
}

.DialogError_delay-1000__xcrqe {
  transition-delay: 1000ms;
}

.DialogError_delay-1100__24TSp {
  transition-delay: 1100ms;
}

.DialogError_delay-1200__16OIW {
  transition-delay: 1200ms;
}

.DialogError_delay-1300__2mliX {
  transition-delay: 1300ms;
}

.DialogError_delay-1400__2ViBf {
  transition-delay: 1400ms;
}

.DialogError_delay-1500__1E35P {
  transition-delay: 1500ms;
}

.DialogError_delay-1600__3Be22 {
  transition-delay: 1600ms;
}

.DialogError_delay-1700__yKojs {
  transition-delay: 1700ms;
}

.DialogError_delay-1800__cxYJs {
  transition-delay: 1800ms;
}

.DialogError_delay-1900__UnM2_ {
  transition-delay: 1900ms;
}

.DialogError_delay-2000__29mZA {
  transition-delay: 2000ms;
}

.DialogError_dialogErrorTitle__2j8vl {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
}
.DialogError_dialogErrorIcon__f2V4t {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  box-shadow: 0 4px 27px #e8ecf2;
  background-color: #fff;
}
.DialogError_dialogErrorIcon__f2V4t > img {
  width: 36%;
  height: 36%;
}
.DialogError_dialogErrorText__2R7SO {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 40px;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  color: var(--light-grey-blue);
}
.DialogError_dialogErrorNav__3qRmV {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.DialogError_dialogErrorNav__3qRmV > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 220px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}
@media (max-width: 480px) {
  .DialogError_dialogErrorNav__3qRmV > button {
    width: 100%;
  }
}
.WalletClosing_animation-text-enter__1d0ET {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.WalletClosing_animation-text-enter-active__15PEB {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.WalletClosing_animation-text-enter-done__ZCov1 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.WalletClosing_animation-text-exit__3jHrq {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.WalletClosing_animation-text-exit-active__ybMSy {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.WalletClosing_delay-100__3zEh0 {
  transition-delay: 100ms;
}

.WalletClosing_delay-200__20idx {
  transition-delay: 200ms;
}

.WalletClosing_delay-300__1GdI5 {
  transition-delay: 300ms;
}

.WalletClosing_delay-400__3W_Zu {
  transition-delay: 400ms;
}

.WalletClosing_delay-500__1ZPTE {
  transition-delay: 500ms;
}

.WalletClosing_delay-600__3j5b- {
  transition-delay: 600ms;
}

.WalletClosing_delay-700__OI02E {
  transition-delay: 700ms;
}

.WalletClosing_delay-800__1KbLs {
  transition-delay: 800ms;
}

.WalletClosing_delay-900__312g6 {
  transition-delay: 900ms;
}

.WalletClosing_delay-1000__ZsDSS {
  transition-delay: 1000ms;
}

.WalletClosing_delay-1100__3ZAWI {
  transition-delay: 1100ms;
}

.WalletClosing_delay-1200__KidA7 {
  transition-delay: 1200ms;
}

.WalletClosing_delay-1300__2T_Q_ {
  transition-delay: 1300ms;
}

.WalletClosing_delay-1400__9uhhS {
  transition-delay: 1400ms;
}

.WalletClosing_delay-1500__3vHK0 {
  transition-delay: 1500ms;
}

.WalletClosing_delay-1600__2X5B2 {
  transition-delay: 1600ms;
}

.WalletClosing_delay-1700__3BtV6 {
  transition-delay: 1700ms;
}

.WalletClosing_delay-1800__2ik-M {
  transition-delay: 1800ms;
}

.WalletClosing_delay-1900__2qXwo {
  transition-delay: 1900ms;
}

.WalletClosing_delay-2000__2aruc {
  transition-delay: 2000ms;
}

.WalletClosing_walletClosingTitle__2ihSC {
  font-family: "Circe-Bold", sans-serif;
  line-height: 40px;
  font-size: 32px;
  color: var(--dark-slate-blue);
}
@media (max-width: 480px) {
  .WalletClosing_walletClosingTitle__2ihSC {
    line-height: 32px;
    font-size: 24px;
  }
}
.WalletClosing_walletClosingContent__nEMO0 {
  margin-top: 20px;
}
.WalletClosing_walletClosingContent__nEMO0 > p {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 390px;
  line-height: 24px;
  font-size: 16px;
  color: var(--grey-blue);
}
@media (max-width: 480px) {
  .WalletClosing_walletClosingContent__nEMO0 > p {
    width: 100%;
  }
}
.WalletClosing_walletClosingContent__nEMO0 > p:nth-child(2) {
  margin-top: 16px;
}
.WalletClosing_walletClosingButton___bgc8 {
  width: 220px;
  margin-top: 50px;
}
@media (max-width: 480px) {
  .WalletClosing_walletClosingButton___bgc8 {
    width: 100%;
  }
}
.PopupAddPartnerFail_animation-text-enter__H7JZl {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupAddPartnerFail_animation-text-enter-active__1kuVl {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupAddPartnerFail_animation-text-enter-done__2kMY5 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupAddPartnerFail_animation-text-exit__2yLjy {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupAddPartnerFail_animation-text-exit-active__1-HPq {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupAddPartnerFail_delay-100__2V3O5 {
  transition-delay: 100ms;
}

.PopupAddPartnerFail_delay-200__DL0EZ {
  transition-delay: 200ms;
}

.PopupAddPartnerFail_delay-300__xLy0f {
  transition-delay: 300ms;
}

.PopupAddPartnerFail_delay-400__1T6Jr {
  transition-delay: 400ms;
}

.PopupAddPartnerFail_delay-500__17Zja {
  transition-delay: 500ms;
}

.PopupAddPartnerFail_delay-600__3QJH5 {
  transition-delay: 600ms;
}

.PopupAddPartnerFail_delay-700__2FXvX {
  transition-delay: 700ms;
}

.PopupAddPartnerFail_delay-800__7-Zbo {
  transition-delay: 800ms;
}

.PopupAddPartnerFail_delay-900__2mDa2 {
  transition-delay: 900ms;
}

.PopupAddPartnerFail_delay-1000__ikirx {
  transition-delay: 1000ms;
}

.PopupAddPartnerFail_delay-1100__YX7Z5 {
  transition-delay: 1100ms;
}

.PopupAddPartnerFail_delay-1200__vNFPK {
  transition-delay: 1200ms;
}

.PopupAddPartnerFail_delay-1300__2ZfS9 {
  transition-delay: 1300ms;
}

.PopupAddPartnerFail_delay-1400__3My9v {
  transition-delay: 1400ms;
}

.PopupAddPartnerFail_delay-1500__28oOP {
  transition-delay: 1500ms;
}

.PopupAddPartnerFail_delay-1600__3znvp {
  transition-delay: 1600ms;
}

.PopupAddPartnerFail_delay-1700__1aoRJ {
  transition-delay: 1700ms;
}

.PopupAddPartnerFail_delay-1800__3ak-7 {
  transition-delay: 1800ms;
}

.PopupAddPartnerFail_delay-1900__1IFUs {
  transition-delay: 1900ms;
}

.PopupAddPartnerFail_delay-2000__3dplW {
  transition-delay: 2000ms;
}

.PopupAddPartnerFail_popupAddPartnerFailNav__1uJ1R {
  display: flex;
  justify-content: center;
  margin-top: 64px;
}
.PopupAddPartnerFail_popupAddPartnerFailNav__1uJ1R button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  padding: 18px 63px;
  border-radius: 8px;
  background-color: #1d2e69;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}

@media (max-width: 980px) {
  .PopupAddPartnerFail_popupAddPartnerFailNav__1uJ1R button {
    width: 100%;
  }
}
.PopupAddPartnerSuccess_animation-text-enter__3hWaU {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupAddPartnerSuccess_animation-text-enter-active__2WXeH {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupAddPartnerSuccess_animation-text-enter-done__1e16G {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupAddPartnerSuccess_animation-text-exit__OwWoV {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupAddPartnerSuccess_animation-text-exit-active__2HcrO {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupAddPartnerSuccess_delay-100__2yP2p {
  transition-delay: 100ms;
}

.PopupAddPartnerSuccess_delay-200__gCHYP {
  transition-delay: 200ms;
}

.PopupAddPartnerSuccess_delay-300__RPJoH {
  transition-delay: 300ms;
}

.PopupAddPartnerSuccess_delay-400__2PejZ {
  transition-delay: 400ms;
}

.PopupAddPartnerSuccess_delay-500__16zEO {
  transition-delay: 500ms;
}

.PopupAddPartnerSuccess_delay-600__2LY6r {
  transition-delay: 600ms;
}

.PopupAddPartnerSuccess_delay-700__u3NLF {
  transition-delay: 700ms;
}

.PopupAddPartnerSuccess_delay-800__3_eL8 {
  transition-delay: 800ms;
}

.PopupAddPartnerSuccess_delay-900__1IX3K {
  transition-delay: 900ms;
}

.PopupAddPartnerSuccess_delay-1000__2PJFX {
  transition-delay: 1000ms;
}

.PopupAddPartnerSuccess_delay-1100__3kX0d {
  transition-delay: 1100ms;
}

.PopupAddPartnerSuccess_delay-1200__17Nmh {
  transition-delay: 1200ms;
}

.PopupAddPartnerSuccess_delay-1300__rWyK3 {
  transition-delay: 1300ms;
}

.PopupAddPartnerSuccess_delay-1400__uG0c5 {
  transition-delay: 1400ms;
}

.PopupAddPartnerSuccess_delay-1500__2d_Ll {
  transition-delay: 1500ms;
}

.PopupAddPartnerSuccess_delay-1600__10YZc {
  transition-delay: 1600ms;
}

.PopupAddPartnerSuccess_delay-1700__2gy41 {
  transition-delay: 1700ms;
}

.PopupAddPartnerSuccess_delay-1800__1fFAd {
  transition-delay: 1800ms;
}

.PopupAddPartnerSuccess_delay-1900__2JBXS {
  transition-delay: 1900ms;
}

.PopupAddPartnerSuccess_delay-2000__3OPvK {
  transition-delay: 2000ms;
}

.PopupAddPartnerSuccess_popupAddPartnerSuccessNav__3TRbr {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.PopupAddPartnerSuccess_popupAddPartnerSuccessNav__3TRbr button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  padding: 18px 20px;
  border-radius: 8px;
  background-color: #1d2e69;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}

@media (max-width: 980px) {
  .PopupAddPartnerSuccess_popupAddPartnerSuccessNav__3TRbr button {
    width: 100%;
  }
}
.PopupDeclinePartner_animation-text-enter__1jDIn {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupDeclinePartner_animation-text-enter-active__3nlrz {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.PopupDeclinePartner_animation-text-enter-done__2Ykn_ {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.PopupDeclinePartner_animation-text-exit__1oBqT {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.PopupDeclinePartner_animation-text-exit-active__3_WIL {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.PopupDeclinePartner_delay-100__2sl6i {
  transition-delay: 100ms;
}

.PopupDeclinePartner_delay-200__3T0-Q {
  transition-delay: 200ms;
}

.PopupDeclinePartner_delay-300__qJqCe {
  transition-delay: 300ms;
}

.PopupDeclinePartner_delay-400__4V4W2 {
  transition-delay: 400ms;
}

.PopupDeclinePartner_delay-500__DGjQ2 {
  transition-delay: 500ms;
}

.PopupDeclinePartner_delay-600__kkX2K {
  transition-delay: 600ms;
}

.PopupDeclinePartner_delay-700__3DnMu {
  transition-delay: 700ms;
}

.PopupDeclinePartner_delay-800__1T90F {
  transition-delay: 800ms;
}

.PopupDeclinePartner_delay-900__2ehlw {
  transition-delay: 900ms;
}

.PopupDeclinePartner_delay-1000__2AQdQ {
  transition-delay: 1000ms;
}

.PopupDeclinePartner_delay-1100__ZE9FW {
  transition-delay: 1100ms;
}

.PopupDeclinePartner_delay-1200__qXHYx {
  transition-delay: 1200ms;
}

.PopupDeclinePartner_delay-1300__1TGXq {
  transition-delay: 1300ms;
}

.PopupDeclinePartner_delay-1400__1Ao-P {
  transition-delay: 1400ms;
}

.PopupDeclinePartner_delay-1500__2A_xA {
  transition-delay: 1500ms;
}

.PopupDeclinePartner_delay-1600__3h2IF {
  transition-delay: 1600ms;
}

.PopupDeclinePartner_delay-1700__177ql {
  transition-delay: 1700ms;
}

.PopupDeclinePartner_delay-1800__pdcOh {
  transition-delay: 1800ms;
}

.PopupDeclinePartner_delay-1900__2Z6hG {
  transition-delay: 1900ms;
}

.PopupDeclinePartner_delay-2000__VTEpu {
  transition-delay: 2000ms;
}

.PopupDeclinePartner_popupDeclinePartnerNav__3CbWL {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 0 66px;
}
.PopupDeclinePartner_popupDeclinePartnerNav__3CbWL button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  border: 1px solid #616a88;
  border-radius: 8px;
  background-color: transparent;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
}
.PopupDeclinePartner_popupDeclinePartnerNav__3CbWL button:nth-child(1) {
  color: #616a88;
}
.PopupDeclinePartner_popupDeclinePartnerNav__3CbWL button:nth-child(2) {
  color: #1d2e69;
}

@media (max-width: 980px) {
  .PopupDeclinePartner_popupDeclinePartnerNav__3CbWL {
    flex-direction: column;
    padding: 0;
  }
  .PopupDeclinePartner_popupDeclinePartnerNav__3CbWL button {
    width: 100%;
  }
  .PopupDeclinePartner_popupDeclinePartnerNav__3CbWL button:nth-child(1) {
    order: 2;
    margin-top: 20px;
  }
  .PopupDeclinePartner_popupDeclinePartnerNav__3CbWL button:nth-child(2) {
    order: 1;
  }
}
.StubPartners_animation-text-enter__3r6pS {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.StubPartners_animation-text-enter-active__7QPp5 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.StubPartners_animation-text-enter-done__1rE-q {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.StubPartners_animation-text-exit__29D1j {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.StubPartners_animation-text-exit-active__1xNkO {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.StubPartners_delay-100__17xtP {
  transition-delay: 100ms;
}

.StubPartners_delay-200__3wSxN {
  transition-delay: 200ms;
}

.StubPartners_delay-300__1_6Jj {
  transition-delay: 300ms;
}

.StubPartners_delay-400__3hh-I {
  transition-delay: 400ms;
}

.StubPartners_delay-500__3vEwI {
  transition-delay: 500ms;
}

.StubPartners_delay-600__3EOWi {
  transition-delay: 600ms;
}

.StubPartners_delay-700__2akax {
  transition-delay: 700ms;
}

.StubPartners_delay-800__364ys {
  transition-delay: 800ms;
}

.StubPartners_delay-900__6Bk9Z {
  transition-delay: 900ms;
}

.StubPartners_delay-1000__IRHsk {
  transition-delay: 1000ms;
}

.StubPartners_delay-1100__1p8Es {
  transition-delay: 1100ms;
}

.StubPartners_delay-1200__3X1iv {
  transition-delay: 1200ms;
}

.StubPartners_delay-1300__3CNUn {
  transition-delay: 1300ms;
}

.StubPartners_delay-1400__rqeRV {
  transition-delay: 1400ms;
}

.StubPartners_delay-1500__2U16t {
  transition-delay: 1500ms;
}

.StubPartners_delay-1600__29ywR {
  transition-delay: 1600ms;
}

.StubPartners_delay-1700__achcI {
  transition-delay: 1700ms;
}

.StubPartners_delay-1800__2_bqf {
  transition-delay: 1800ms;
}

.StubPartners_delay-1900__2ofHz {
  transition-delay: 1900ms;
}

.StubPartners_delay-2000__faxU9 {
  transition-delay: 2000ms;
}

.StubPartners_stubPartners__1ocBg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 90px 0 120px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.StubPartners_stubPartnersIcon__3SdZj {
  width: 42px;
  height: 42px;
}
.StubPartners_stubPartnersText__33i1m {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 20px;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #b0b5c4;
}

@media (max-width: 980px) {
  .StubPartners_stubPartners__1ocBg {
    padding: 100px 0 120px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-partners-tab__partners {
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.profile-settings-partners-tab__partners__item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 12px;
  text-decoration: none;
}
.profile-settings-partners-tab__partners__item:hover {
  background-color: var(--pale-grey);
}
.profile-settings-partners-tab__partners__item:hover .profile-settings-partners-tab__partners__arrow {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.profile-settings-partners-tab__partners__status {
  font-family: "Circe-Regular", sans-serif;
  line-height: 16px;
  font-size: 12px;
}
.profile-settings-partners-tab__partners__status_connected {
  color: var(--grey-blue);
}
.profile-settings-partners-tab__partners__status_archived {
  color: var(--light-grey-blue);
}
.profile-settings-partners-tab__partners__status_other {
  color: var(--marigold);
}
.profile-settings-partners-tab__partners__name {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 2px;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.profile-settings-partners-tab__partners__arrow {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  pointer-events: none;
  width: 20px;
  height: 20px;
  margin: auto 0;
  opacity: 0;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

@media (max-width: 980px) {
  .profile-settings-partners-tab__partners_arrow {
    display: none;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-partners-tab__requests {
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.profile-settings-partners-tab__requests__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px 16px;
  border-top: 1px solid #f6f8fb;
}
.profile-settings-partners-tab__requests__info {
  display: flex;
  flex-direction: column;
}
.profile-settings-partners-tab__requests__status {
  font-family: "Circe-Regular", sans-serif;
  line-height: 16px;
  font-size: 12px;
  color: #00ad85;
}
.profile-settings-partners-tab__requests__name {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 2px;
  line-height: 20px;
  font-size: 14px;
  color: #1d2e69;
}
.profile-settings-partners-tab__requests__nav {
  display: flex;
  align-items: center;
}
.profile-settings-partners-tab__requests__confirm-button, .profile-settings-partners-tab__requests__decline-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  border-radius: 8px;
  line-height: 20px;
  font-size: 14px;
}
.profile-settings-partners-tab__requests__confirm-button {
  padding: 10px 16px;
  background-color: #f6f8fb;
  color: #616a88;
}
.profile-settings-partners-tab__requests__decline-button {
  margin-left: 16px;
  padding: 10px 0;
  background-color: transparent;
  color: #b0b5c4;
}

@media (max-width: 980px) {
  .profile-settings-partners-tab__requests {
    margin-top: 30px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .profile-settings-partners-tab__requests__item {
    flex-direction: column;
    align-items: initial;
    margin-top: 20px;
    padding: 20px;
    border-top: 0;
    border-radius: 6px;
    box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
    background-color: #fff;
  }
  .profile-settings-partners-tab__requests__item:first-child {
    margin-top: 0;
  }
  .profile-settings-partners-tab__requests__nav {
    margin-top: 20px;
  }
  .profile-settings-partners-tab__requests__status {
    display: none;
  }
  .profile-settings-partners-tab__requests__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .profile-settings-partners-tab__requests__nav {
    justify-content: flex-end;
  }
  .profile-settings-partners-tab__requests__confirm-button {
    order: 2;
    margin-left: 16px;
  }
  .profile-settings-partners-tab__requests__decline-button {
    order: 1;
    margin-left: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-partners-tab {
  padding-left: 110px;
}
.profile-settings-partners-tab__title {
  font-family: "Circe-Bold", sans-serif;
  font-size: 32px;
  margin-top: 16px;
  margin-bottom: 42px;
  color: var(--dark-slate-blue);
}
.profile-settings-partners-tab__container {
  margin-top: 20px;
}
.profile-settings-partners-tab__container__table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 320px;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.profile-settings-partners-tab__container__table svg {
  margin-bottom: 20px;
  width: 42px;
  height: 42px;
}
.profile-settings-partners-tab__error {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #b0b5c4;
  text-align: center;
  max-width: 390px;
  padding: 0 20px;
}

@media screen and (max-width: 1194px) {
  .profile-settings-partners-tab {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .profile-settings-partners-tab__title {
    display: none;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.personal-data-notice-text__icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 2px;
}
.personal-data-notice-text__icon path {
  fill: #616a88;
}
.personal-data-notice-text__massage {
  max-width: 320px;
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #b0b5c4;
}
.personal-data-notice-text__massage-link {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1d2e69;
  text-decoration: none;
  cursor: pointer;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.hint {
  transition: all 0.6s cubic-bezier(0.66, 0, 0.34, 1);
  background: white;
  bottom: 0;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  display: flex;
  align-items: flex-start;
  padding-top: 22%;
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 200;
}
.hint-enter {
  opacity: 0;
  left: 100%;
}
.hint-enter-active {
  opacity: 1;
  left: 50%;
}
.hint-enter-done {
  opacity: 1;
  left: 50%;
}
.hint-exit {
  opacity: 1;
  left: 50%;
}
.hint-exit-active {
  opacity: 0;
  left: 100%;
}
@media screen and (max-width: 1000px) {
  .hint-enter-active {
    left: 0;
  }
  .hint-enter-done {
    left: 0;
  }
  .hint-exit {
    left: 0;
  }
}
.hint_confirm-data {
  align-items: flex-start;
}
.hint_change-data {
  align-items: flex-start;
}
.hint_lift-limits {
  align-items: flex-start;
}
.hint_hint_no-russian-passport {
  align-items: flex-start;
}
.hint_cant-read-passport {
  align-items: flex-start;
}
.hint__inner {
  padding-left: 63px;
  padding-right: 15px;
  max-width: 601px;
  width: 100%;
}
.hint_change-phone .hint__inner {
  margin-top: -90px;
  max-width: 678px;
}
.hint_lift-limits .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_confirm-data .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_change-data .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_no-russian-passport .hint__inner {
  max-width: 603px;
  padding-top: 300px;
}
.hint_cant-read-passport .hint__inner {
  max-width: 629px;
  padding-top: 300px;
}
.hint__title {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 28px;
  margin-bottom: 28px;
}
.hint_change-password .hint__title {
  margin-bottom: 38px;
}
.hint__text-wrap {
  margin-bottom: 30px;
}
.hint__text {
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
  font-size: 15px;
  line-height: 1.6;
}
.hint__text_error {
  color: var(--reddish-pink);
}
.hint__text p {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin-bottom: 20px;
}
.hint__text p.mod-no-margin-bottom {
  margin-bottom: 0;
}
.hint__text ul {
  padding: 20px 0;
}
.hint__text ul li {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding-left: 20px;
  position: relative;
}
.hint__text ul li::before {
  background: var(--dark-slate-blue);
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 8px;
}
.hint__text-link {
  font-family: "Circe-Regular", sans-serif;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  color: var(--grey-blue);
  cursor: pointer;
  font-size: 15px;
  line-height: 1.6;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0 0 3px;
  text-decoration: underline;
}
.hint__text-link:hover {
  text-decoration: none;
}
.hint__dropzone-wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.hint__dropzone-wrap section {
  width: calc(100% - 68px - 10px - 60px - 10px);
}
.hint__dropzone-icon {
  margin-right: 10px;
}
.hint__dropzone {
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  border: 1px dashed var(--bluey-grey);
  border-radius: 6px;
  color: var(--grey-blue);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 92px;
  justify-content: center;
}
.hint__dropzone_error {
  border: 1px solid var(--reddish-pink);
}
.hint__dropzone-loader {
  min-width: 60px;
  position: relative;
  width: 60px;
}
.hint__dropzone-loader-progress-check {
  height: 11px;
  left: 50%;
  margin-left: -15px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  width: 14px;
}
.hint__dropzone-loader-progress-lock {
  height: 16px;
  left: 50%;
  margin-left: -6px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 12px;
}
.hint__dropzone-loader-progress-lock path {
  fill: var(--grey-blue);
}
.hint__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 56px;
  top: 48px;
  width: 48px;
}
.hint__close-icon path {
  transition: stroke 0.2s ease-in;
}
.hint__close:hover .hint__close-icon path {
  stroke: var(--reddish-pink);
}
.hint__input-block {
  align-items: center;
  display: flex;
}
.hint__input-holder {
  margin-bottom: 20px;
  position: relative;
}
.hint_change-phone .hint__input-holder::before {
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  content: "+7 (";
  font-size: 28px;
  left: 0;
  position: absolute;
  top: 16px;
}
.hint_change-phone .hint__input-holder:focus-within::before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.hint_change-phone .hint__input-holder_filled::before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.hint__input-holder_email {
  max-width: 355px;
}
.hint__input {
  font-family: "Circe-Bold", sans-serif;
  border: none;
  border-bottom: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  font-size: 28px;
  height: 60px;
  padding: 0 20px 0 0;
  width: 100%;
}
.hint_change-phone .hint__input {
  padding: 0 20px 0 46px;
}
.hint_change-phone .hint__input:focus {
  padding: 0 20px 0 0;
}
.hint_change-phone .hint__input-holder_filled .hint__input {
  padding: 0 20px 0 0;
}
.hint__input::-webkit-input-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input::-moz-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input:-moz-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input:-ms-input-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input::-ms-input-placeholder {
  color: var(--bluey-grey);
  opacity: 1;
}
.hint__input::-ms-clear {
  display: none;
}
.hint__input-loader-wrap {
  margin-top: -10px;
  position: relative;
}
.hint_change-phone .hint__input-loader-wrap {
  margin-left: 15px;
  margin-top: 0;
}
.hint__input-loader-icon {
  left: 50%;
  position: absolute;
  top: 50%;
}
.hint__input-loader-icon_lock {
  margin-left: -6px;
  margin-top: -8px;
}
.hint__input-loader-wrap_fail .hint__input-loader-icon_lock path {
  fill: var(--grey-blue);
}
.hint__input-loader-wrap_loading .hint__input-loader-icon_lock path {
  fill: var(--grey-blue);
}
.hint__input-loader-icon_lock .hint__input-loader-icon_lock path {
  fill: var(--reddish-pink);
}
.hint__input-loader-icon_check {
  margin-left: -7px;
  margin-top: -6px;
}
.hint__btn-wrap {
  display: flex;
}
.hint__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  padding: 0 60px;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.hint__btn:hover {
  background: var(--dusk-blue);
  transition: all 0.2s ease-in-out;
}
.hint__btn_disabled {
  background: var(--light-grey-blue);
  cursor: not-allowed;
  opacity: 0.2;
}
.hint__btn_disabled:hover {
  background: var(--light-grey-blue);
}
.hint__tip {
  font-family: "Circe-Regular", sans-serif;
  color: var(--light-grey-blue);
  font-size: 14px;
  line-height: 1.5;
  max-width: 348px;
  width: 100%;
}
.hint__tip_error {
  color: var(--reddish-pink);
}
.hint__bank-card-details {
  margin-bottom: 20px;
}
.hint__bank-card-details:last-child {
  margin-bottom: 0;
}
.hint__bank-card-details-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #9da6ba;
  font-size: 14px;
  margin-bottom: 5px;
}
.hint__bank-card-details-info-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.hint__bank-card-details-info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 18px;
  line-height: 1.33;
  margin-right: 12px;
  word-break: break-word;
}
.hint__copied {
  display: flex;
  align-items: center;
}
.hint__copied-icon-wrap {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.hint__copied-icon path {
  fill: var(--turquoise-blue);
}
.hint__copied-text {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--turquoise-blue);
}

@media screen and (max-width: 1100px) {
  .hint_change-password .hint__content {
    max-width: 400px;
  }
}
@media screen and (max-width: 1000px) {
  .hint {
    align-items: flex-start;
    overflow: auto;
    overflow-x: hidden;
    padding-top: 141px;
    width: 100%;
  }
  .hint__inner {
    overflow-y: scroll;
    padding-bottom: 70px;
  }
  .hint_change-phone .hint__inner {
    margin-top: 0;
  }
  .hint_lift-limits .hint__inner {
    padding-top: 0;
  }
  .hint_confirm-data .hint__inner {
    padding-top: 0;
  }
  .hint_change-data .hint__inner {
    padding-top: 0;
  }
  .hint_change-password .hint__inner {
    padding-top: 0;
  }
  .hint_no-russian-passport .hint__inner {
    padding-top: 0;
  }
  .hint_cant-read-passport .hint__inner {
    padding-top: 0;
  }
  .hint__title {
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    font-size: 36px;
    line-height: 1.11;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .hint__inner {
    padding-left: 15px;
  }
  .hint__close {
    right: 20px;
    top: 25px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.open-popup-button__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-slate-blue);
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 2px dotted var(--dark-slate-blue);
  transition-property: borderColor;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.open-popup-button__item:hover {
  border-bottom-color: transparent;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-personal-data-tab {
  padding-left: 110px;
}
.profile-settings-personal-data-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 43px;
  margin-top: 16px;
}
.profile-settings-personal-data-tab__content-wrap {
  display: flex;
  width: 100%;
}
.profile-settings-personal-data-tab__content {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.profile-settings-personal-data-tab__input-list {
  display: flex;
}
.profile-settings-personal-data-tab__input-holder {
  position: relative;
  margin-bottom: 12px;
}
.profile-settings-personal-data-tab__input-holder:last-child {
  margin-bottom: 0;
}
.profile-settings-personal-data-tab__input-holder .input-with-label-animation__input {
  text-transform: capitalize;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-settings-personal-data-tab__input-list .profile-settings-personal-data-tab__input-holder {
  margin-right: 11px;
}
.profile-settings-personal-data-tab__input-list .profile-settings-personal-data-tab__input-holder:last-child {
  margin-right: 0;
}
.profile-settings-personal-data-tab__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: solid 1px rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  color: #616a88;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 29px 20px 16px 20px;
  transition: all 0.3s ease;
  text-transform: capitalize;
}
.profile-settings-personal-data-tab__input:focus {
  border-color: var(--dark-slate-blue);
  box-shadow: none;
  background: white;
}
.profile-settings-personal-data-tab__input:hover:not(:disabled) {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
}
.profile-settings-personal-data-tab__input:disabled {
  color: var(--grey-blue);
  background-color: rgba(246, 248, 251, 0.5);
  border-color: rgba(228, 232, 238, 0.5);
}
.profile-settings-personal-data-tab__input-label {
  font-size: 18px;
  color: #9da6ba;
  position: absolute;
  left: 24px;
  top: 22px;
  font-family: "Circe-Regular", sans-serif;
  pointer-events: none;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: left top;
          transform-origin: left top;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.profile-settings-personal-data-tab__input-holder:focus-within .profile-settings-personal-data-tab__input-label {
  -webkit-transform: scale(0.5) translate(-6px, -20px);
          transform: scale(0.5) translate(-6px, -20px);
  color: #616a88;
  text-transform: uppercase;
}
.profile-settings-personal-data-tab__input-holder_focused .profile-settings-personal-data-tab__input-label {
  -webkit-transform: scale(0.5) translate(-6px, -20px);
          transform: scale(0.5) translate(-6px, -20px);
  color: #616a88;
  text-transform: uppercase;
}
.profile-settings-personal-data-tab__additional-info {
  width: calc(100% - 350px);
}
.profile-settings-personal-data-tab__additional-info__confirmPersonalDataWrap {
  padding-bottom: 40px;
}
.profile-settings-personal-data-tab__error-massage {
  width: 380px;
  min-width: 380px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1100px) {
  .profile-settings-personal-data-tab {
    padding-left: 0;
  }
  .profile-settings-personal-data-tab__error-massage {
    width: 100%;
    min-width: 100%;
    max-width: 380px;
  }
}
@media screen and (max-width: 980px) {
  .profile-settings-personal-data-tab {
    padding-top: 10px;
  }
}
@media screen and (max-width: 800px) {
  .profile-settings-personal-data-tab {
    padding-bottom: 69px;
  }
  .profile-settings-personal-data-tab__content-wrap {
    display: block;
  }
  .profile-settings-personal-data-tab__content {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .profile-settings-personal-data-tab__additional-info {
    width: 100%;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.account-limits__item {
  margin-bottom: 28px;
  position: relative;
  padding-left: 20px;
}
.account-limits__item:last-child {
  margin-bottom: 0;
}
.account-limits__item-title {
  font-size: 16px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 12px;
}
.account-limits__item-desc {
  color: var(--dark-slate-blue);
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.account-limits__item-bar {
  left: 0;
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 1.5px;
  background: rgba(176, 181, 196, 0.2);
}
.account-limits__item-bar-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 3px;
  border-radius: 1.5px;
  background-color: var(--turquoise-blue);
}
.account-limits_simple .account-limits__item-bar-progress {
  height: 10px;
}
.account-limits_auth .account-limits__item-bar-progress {
  height: 100%;
}
.account-limits_auth-gotten .account-limits__item-bar-progress {
  height: 100%;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.avatar-upload {
  position: relative;
}
.avatar-upload__avatar-small {
  width: 90px;
  height: 90px;
}
.avatar-upload__avatar-large {
  width: 120px;
  height: 120px;
}
.avatar-upload__avatar-wrap {
  width: inherit;
  height: inherit;
  border: solid 2px white;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-upload__avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-upload__avatar-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 20px;
  margin-top: -10px;
  margin-left: -12px;
  pointer-events: none;
}
.avatar-upload__avatar-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
}
.avatar-upload__avatar-loading {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--bluey-grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
}
.avatar-upload__dropdown {
  position: absolute;
  top: calc(100% + 3px);
  left: calc(50% - 90px);
  background: var(--white);
  width: 180px;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0px 4px 27px 0px #e8ecf2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px 10px 18px;
}
.avatar-upload__dropdown-item {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.avatar-upload__dropdown-item-icon {
  width: 14px;
  height: 14px;
  margin-right: 9px;
}
.avatar-upload__dropdown-item-name {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-slate-blue);
  width: 100%;
}
.avatar-upload__dropzone-wrap {
  width: inherit;
  height: inherit;
  border: solid 2px white;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.avatar-upload__dropzone-wrap_error {
  border-color: var(--reddish-pink);
}
.avatar-upload__dropzone-wrap section {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
}
.avatar-upload__dropzone-wrap section div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
}

@media screen and (max-width: 800px) {
  .avatar-upload__dropdown {
    left: 0;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.edit-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-button__icon {
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
}
.edit-button__icon path {
  transition: all 0.3s ease;
  fill: var(--dark-slate-blue);
}
.edit-button:hover .edit-button__icon path {
  fill: var(--deep-sky-blue);
}

@media screen and (max-width: 800px) {
  .edit-button {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .edit-button__icon {
    width: 12px;
    min-width: 12px;
    height: 12px;
    min-height: 12px;
  }
  .edit-button__icon path {
    transition: all 0.3s ease;
    fill: var(--dark-slate-blue);
  }
  .edit-button:hover .edit-button__icon path {
    fill: var(--deep-sky-blue);
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.change-password__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
@media screen and (max-width: 800px) {
  .change-password__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.change-password-success {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.change-password-success__icon-wrap {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--turquoise-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
.change-password-success__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
  text-align: center;
}
.change-password-success__content {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: var(--bluey-grey);
}

@media screen and (max-width: 800px) {
  .change-password-success__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.password-input {
  margin-bottom: 11px;
  position: relative;
  max-width: 400px;
  width: 100%;
}
.password-input__itself {
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  padding: 0 24px;
  transition: border-color 0.3s ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  letter-spacing: 16px;
  line-height: 60px;
}
.password-input__itself::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-input__itself::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-input__itself:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-input__itself:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-input__itself::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.password-input__itself::-ms-clear {
  display: none;
}
.password-input__itself:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.password-input__itself_password-visible {
  letter-spacing: 0;
}
.password-input__itself_fail {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  box-shadow: none;
}
.password-input__password-controls {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.password-block_password-recovery .password-input__password-controls {
  margin-top: -8px;
}
.password-input__password-visibility {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.password-input__password-visibility-icon {
  pointer-events: none;
}
.password-input__password-visibility-icon_closed {
  margin-top: 1px;
}
.password-input__password-visibility-icon_opened {
  margin-top: 1px;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.old-password {
  margin-bottom: 20px;
}
.old-password__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
@media screen and (max-width: 800px) {
  .old-password__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    color: var(--dark-slate-blue);
    line-height: 1.11;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.setting-new-password__code-block .code-block__title {
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 28px;
}
.setting-new-password__error-block {
  color: var(--reddish-pink);
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  width: 70%;
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .setting-new-password__code-block .code-block__title {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: "Circe-ExtraBold", sans-serif;
    line-height: 1.11;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.change-email__input-block {
  display: flex;
  align-items: center;
}
.change-email__input-holder {
  margin-bottom: 20px;
  position: relative;
}
.hint_change-phone .change-email__input-holder:before {
  content: "+7 (";
  position: absolute;
  left: 0;
  top: 16px;
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Bold", sans-serif;
}
.hint_change-phone .change-email__input-holder:focus-within:before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.hint_change-phone .change-email__input-holder_filled:before {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.change-email__input-holder_email {
  max-width: 355px;
}
.change-email__input {
  border: none;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid var(--dark-slate-blue);
  color: var(--dark-slate-blue);
  font-size: 28px;
  font-family: "Circe-Bold", sans-serif;
  line-height: 60px;
  padding: 0 20px 0 0;
}
.hint_change-phone .change-email__input {
  padding: 0 20px 0 46px;
}
.hint_change-phone .change-email__input:focus {
  padding: 0 20px 0 0;
}
.hint_change-phone .hint__input-holder_filled .change-email__input {
  padding: 0 20px 0 0;
}
.change-email__input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input::-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input:-moz-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input:-ms-input-placeholder {
  opacity: 1;
  color: var(--bluey-grey);
}
.change-email__input-loader-wrap {
  position: relative;
  margin-top: -10px;
}
.hint_change-phone .change-email__input-loader-wrap {
  margin-top: 0;
  margin-left: 15px;
}
.change-email__input-loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
}
.change-email__input-loader-icon_loading {
  margin-top: -8px;
  margin-left: -6px;
}
.change-email__input-loader-icon_loading path {
  fill: var(--grey-blue);
}
.change-email__input-loader-icon_check {
  margin-top: -6px;
  margin-left: -7px;
}
.change-email__input-loader-icon_fail {
  margin-top: -8px;
  margin-left: -6px;
}
.change-email__input-loader-icon_fail path {
  fill: var(--reddish-pink);
}
.change-email__btn-wrap {
  display: flex;
}
.change-email__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  text-decoration: none;
  height: 60px;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  font-size: 18px;
  color: white;
  transition: all 0.2s ease-in-out;
}
.change-email__btn:hover:not(:disabled) {
  background: var(--dusk-blue);
  transition: all 0.2s ease-in-out;
}
.change-email__btn:disabled {
  cursor: not-allowed;
  opacity: 0.2;
  background: var(--light-grey-blue);
}
.change-email__btn_disabled {
  cursor: not-allowed;
  opacity: 0.2;
  background: var(--light-grey-blue);
}
.change-email__btn_disabled:hover {
  background: var(--light-grey-blue);
}
.change-email__tip {
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Circe-Regular", sans-serif;
  max-width: 348px;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 20px;
}
.change-email__tip_error {
  color: var(--reddish-pink);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-wallet-closing-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-top: 12px;
}
.profile-settings-wallet-closing-button > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
}
.profile-settings-wallet-closing-button > button > svg {
  width: 20px;
  height: 20px;
}
.profile-settings-wallet-closing-button > button > svg path {
  fill: var(--light-grey-blue);
}
.profile-settings-wallet-closing-button > button > span {
  font-family: "Circe-Regular", sans-serif;
  margin-left: 8px;
  line-height: 24px;
  font-size: 16px;
  color: var(--light-grey-blue);
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.unconfirmed-email-message {
  background: rgba(255, 45, 85, 0.05);
  padding: 21px 35px 20px 20px;
  display: flex;
  align-items: flex-start;
}
.unconfirmed-email-message__icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 2px;
}
.unconfirmed-email-message__icon path {
  fill: #616a88;
}
.unconfirmed-email-message__text {
  font-size: 14px;
  color: #616a88;
  line-height: 1.43;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.unconfirmed-email-message__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.unconfirmed-email-message__button:hover {
  text-decoration: none;
}
.unconfirmed-email-message__error {
  font-size: 14px;
  color: var(--reddish-pink);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-profile-tab {
  width: 100%;
  padding-left: 110px;
}
.profile-settings-profile-tab__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}
.profile-settings-profile-tab__top-main {
  width: 100%;
}
.profile-settings-profile-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 43px;
  padding-top: 16px;
}
.profile-settings-profile-tab__avatar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-settings-profile-tab__error-massage {
  position: relative;
}
.profile-settings-profile-tab__content {
  display: flex;
  width: 100%;
}
.profile-settings-profile-tab__content-col:first-child {
  width: 310px;
  min-width: 310px;
  margin-right: 60px;
}
.profile-settings-profile-tab__content-col:last-child {
  width: calc(100% - 310px);
}
.profile-settings-profile-tab__limits {
  margin-bottom: 40px;
}
.profile-settings-profile-tab__input {
  margin-bottom: 12px;
}
.profile-settings-profile-tab__input:last-child {
  margin-bottom: 0;
}
.profile-settings-profile-tab__input .input-with-label-animation__input {
  color: #616a88;
}
.profile-settings-profile-tab__input_password .input-with-label-animation__input {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input:-moz-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input:-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-ms-input-placeholder {
  opacity: 1;
  color: rgba(159, 167, 179, 0.7);
}
.profile-settings-profile-tab__input_email .input-with-label-animation__input::-ms-clear {
  display: none;
}
.profile-settings-profile-tab__input-list {
  display: flex;
}
.profile-settings-profile-tab__password-change-sidebar {
  align-items: flex-start;
}
.profile-settings-profile-tab__password-change-inner {
  max-width: 603px;
}
.profile-settings-profile-tab__change-phone-sidebar {
  align-items: center;
}
.profile-settings-profile-tab__change-phone-inner {
  margin-top: -90px;
  max-width: 678px;
}

@media screen and (max-width: 1194px) {
  .profile-settings-profile-tab {
    padding-left: 0;
  }
}
@media screen and (max-width: 800px) {
  .profile-settings-profile-tab {
    padding-bottom: 60px;
  }
  .profile-settings-profile-tab__top {
    display: block;
  }
  .profile-settings-profile-tab__top-main {
    width: 100%;
    margin-bottom: 30px;
  }
  .profile-settings-profile-tab__avatar {
    display: block;
  }
  .profile-settings-profile-tab__avatar .profile-settings-profile-tab__content-col {
    margin-bottom: 30px !important;
  }
  .profile-settings-profile-tab__error-massage-wrap {
    width: 100%;
    min-width: 100%;
    margin-top: 0;
  }
  .profile-settings-profile-tab__content {
    display: block;
  }
  .profile-settings-profile-tab__content-col:first-child {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
  }
  .profile-settings-profile-tab__content-col:last-child {
    width: 100%;
  }
  .profile-settings-profile-tab__input-list {
    display: flex;
  }
  .profile-settings-profile-tab__password-change-sidebar {
    align-items: flex-start;
  }
  .profile-settings-profile-tab__password-change-inner {
    max-width: 603px;
  }
  .profile-settings-profile-tab__change-phone-sidebar {
    align-items: center;
  }
  .profile-settings-profile-tab__change-phone-inner {
    margin-top: -90px;
    max-width: 678px;
  }
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.sz-status-card {
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  padding: 32px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 360px;
}
.sz-status-card__main {
  width: 100%;
}
.sz-status-card__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 11px;
}
.sz-status-card__service-title {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.sz-status-card__title {
  font-size: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.sz-status-card__avatar-wrap {
  width: 70px;
  height: 70px;
  min-width: 70px;
  position: relative;
}
.sz-status-card__top-avatar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
.sz-status-card__top-avatar-badge {
  position: absolute;
  right: -12px;
  top: -10px;
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #3094ff 0%, #5ac8fa 100%);
}
.sz-status-card__top-avatar-badge-icon {
  width: 18px;
  height: 16px;
}
.sz-status-card__top-avatar-badge-icon path {
  fill: white;
}
.sz-status-card__img-wrap {
  width: 50px;
  height: 50px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sz-status-card__img-icon {
  width: 18px;
  height: 16px;
  fill: var(--deep-sky-blue);
}
.sz-status-card__text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.sz-status-card__button {
  width: 100%;
}
.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-sz-tab {
  width: 100%;
  margin-bottom: 102px;
  padding-left: 110px;
}
.profile-settings-sz-tab__title {
  font-size: 32px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  margin-bottom: 42px;
  padding-top: 16px;
}
.profile-settings-sz-tab__main {
  display: flex;
  align-items: flex-start;
}
.profile-settings-sz-tab__services-title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}
.profile-settings-sz-tab__item {
  width: calc((100% - 30px) / 2);
  margin-right: 30px;
}
.profile-settings-sz-tab__item:last-child {
  margin-right: 0;
}
.profile-settings-sz-tab__service {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.profile-settings-sz-tab__service:last-child {
  margin-bottom: 0;
}
.profile-settings-sz-tab__service-notice {
  position: absolute;
  width: 100%;
  top: 26px;
  max-width: 280px;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(13, 25, 66, 0.1);
  background-color: var(--pale-grey);
  padding: 20px;
  font-size: 14px;
  color: #616a88;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  left: 50%;
  margin-left: -140px;
  z-index: 50;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.profile-settings-sz-tab__service-notice:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -3px;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--pale-grey) transparent transparent transparent;
}
.profile-settings-sz-tab__service:hover .profile-settings-sz-tab__service-notice {
  opacity: 1;
}

@media screen and (max-width: 1194px) {
  .profile-settings-sz-tab {
    padding-left: 0;
  }
}
@media screen and (max-width: 780px) {
  .profile-settings-sz-tab__main {
    flex-direction: column;
  }
  .profile-settings-sz-tab__item {
    width: 100%;
    margin-right: 0;
  }
  .profile-settings-sz-tab__item:first-child {
    margin-bottom: 40px;
  }
  .profile-settings-sz-tab__item:last-child {
    margin-right: 0;
  }
}
.style_animation-text-enter__2KWPh {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-active__1_2AS {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-done__1d-Wh {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.style_animation-text-exit__BqvN5 {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.style_animation-text-exit-active__2gzpy {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.style_delay-100__uabSS {
  transition-delay: 100ms;
}

.style_delay-200__2bMpm {
  transition-delay: 200ms;
}

.style_delay-300__3gg1Y {
  transition-delay: 300ms;
}

.style_delay-400__z07qa {
  transition-delay: 400ms;
}

.style_delay-500__3_xC3 {
  transition-delay: 500ms;
}

.style_delay-600__1Y_qR {
  transition-delay: 600ms;
}

.style_delay-700__8RG2a {
  transition-delay: 700ms;
}

.style_delay-800__2im2- {
  transition-delay: 800ms;
}

.style_delay-900__2r7BI {
  transition-delay: 900ms;
}

.style_delay-1000__304KT {
  transition-delay: 1000ms;
}

.style_delay-1100__18rSx {
  transition-delay: 1100ms;
}

.style_delay-1200__UqFnS {
  transition-delay: 1200ms;
}

.style_delay-1300__3nTSY {
  transition-delay: 1300ms;
}

.style_delay-1400__3X8nc {
  transition-delay: 1400ms;
}

.style_delay-1500__1QaGI {
  transition-delay: 1500ms;
}

.style_delay-1600__2okNL {
  transition-delay: 1600ms;
}

.style_delay-1700__24teF {
  transition-delay: 1700ms;
}

.style_delay-1800__3aQfx {
  transition-delay: 1800ms;
}

.style_delay-1900__2GOlR {
  transition-delay: 1900ms;
}

.style_delay-2000__BATPI {
  transition-delay: 2000ms;
}

.style_profileSettings__1m9yj {
  padding-top: 45px;
  min-height: 710px;
  position: relative;
}
.style_profileSettings__1m9yj .style_container__3h78F {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  width: 100%;
  position: relative;
}
.style_profileSettings__1m9yj .style_nav__38oMg {
  width: 300px;
  min-width: 300px;
}
.style_profileSettings__1m9yj .style_main__1yCsM {
  width: calc(100% - 300px);
  min-width: calc(100% - 300px);
}

@media screen and (max-width: 1194px) {
  .style_profileSettings__1m9yj .style_nav__38oMg {
    width: 250px;
    min-width: 250px;
  }
  .style_profileSettings__1m9yj .style_main__1yCsM {
    width: calc(100% - 250px);
    min-width: calc(100% - 250px);
  }
}
@media screen and (max-width: 980px) {
  .style_profileSettings__1m9yj {
    padding-top: 0;
  }
  .style_profileSettings__1m9yj .style_container__3h78F {
    display: block;
  }
  .style_profileSettings__1m9yj .style_navSelect__11KR1 {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: white;
    padding: 0 57px 0 50px;
    border-bottom: 1px solid #e8ecf2;
    margin-bottom: 20px;
  }
  .style_profileSettings__1m9yj .style_navSelectText__27HMS {
    font-size: 24px;
    color: var(--dark-slate-blue);
    font-family: "Circe-Bold", sans-serif;
  }
  .style_profileSettings__1m9yj .style_navSelectIcon__2MD8O {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 24px;
    height: 24px;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .style_profileSettings__1m9yj .style_navSelectIcon__2MD8O path {
    stroke: var(--dark-slate-blue);
  }
  .style_profileSettings__1m9yj .style_navSelectIcon__2MD8O .style_open__3ZtxT {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .style_profileSettings__1m9yj .style_main__1yCsM {
    width: 100%;
    min-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .style_profileSettings__1m9yj .style_container__3h78F {
    padding-left: 20px;
    padding-right: 20px;
  }
  .style_profileSettings__1m9yj .style_navSelect__11KR1 {
    padding: 0 27px 0 20px;
  }
}
.lk {
  position: relative;
  z-index: 10;
  flex-grow: 100;
}
.lk_menu-open {
  z-index: 10;
}
.lk_low-zindex {
  z-index: 10;
}
.lk__container {
  max-width: 1300px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 600px) {
  .lk__container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.style_animation-text-enter__1n2GC {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-active__3Lkj2 {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.style_animation-text-enter-done__1kUeJ {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.style_animation-text-exit__1pjYK {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.style_animation-text-exit-active__1alvd {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.style_delay-100__2LJzD {
  transition-delay: 100ms;
}

.style_delay-200__9yrfr {
  transition-delay: 200ms;
}

.style_delay-300__1xrT2 {
  transition-delay: 300ms;
}

.style_delay-400__DwBzL {
  transition-delay: 400ms;
}

.style_delay-500__u2Q0y {
  transition-delay: 500ms;
}

.style_delay-600__2HL3f {
  transition-delay: 600ms;
}

.style_delay-700__22ZgD {
  transition-delay: 700ms;
}

.style_delay-800__12e-2 {
  transition-delay: 800ms;
}

.style_delay-900__1yE8h {
  transition-delay: 900ms;
}

.style_delay-1000__1WJNR {
  transition-delay: 1000ms;
}

.style_delay-1100__3PdwB {
  transition-delay: 1100ms;
}

.style_delay-1200__3QeIn {
  transition-delay: 1200ms;
}

.style_delay-1300__10qNn {
  transition-delay: 1300ms;
}

.style_delay-1400__1wd9- {
  transition-delay: 1400ms;
}

.style_delay-1500__3iyUi {
  transition-delay: 1500ms;
}

.style_delay-1600___YrCP {
  transition-delay: 1600ms;
}

.style_delay-1700__3BRk_ {
  transition-delay: 1700ms;
}

.style_delay-1800__3LYrx {
  transition-delay: 1800ms;
}

.style_delay-1900__3PTVZ {
  transition-delay: 1900ms;
}

.style_delay-2000__uAd_8 {
  transition-delay: 2000ms;
}

.style_devWidget__2aLD_ {
  padding-top: 60px;
  padding-bottom: 60px;
}

.style_devWidgetItem__osSOw {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  font-size: 14px;
  margin-right: 40px;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  width: 170px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
