.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.tax-list {
  background: #ffffff;
  box-shadow: 0 4px 27px #e8ecf2;
  border-radius: 8px;
}
.tax-list__header {
  padding: 28px 20px 18px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tax-list__column:first-child {
  width: calc(62% - 104px);
}
.tax-list__column:nth-child(2) {
  width: 38%;
  min-width: 38%;
}
.tax-list__column:nth-child(3) {
  width: 104px;
  min-width: 104px;
}
.tax-list__header .tax-list__column {
  font-size: 16px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
}
.tax-list__main {
  padding-bottom: 16px;
}
.tax-list__item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 18px 20px 4px 20px;
  transition: background 0.3s ease-in-out;
  border-bottom: 1px solid #f6f8fb;
}
.tax-list__item:hover {
  background: #f6f8fb;
}
.tax-list__purpose-wrap {
  display: flex;
  align-items: center;
  padding-right: 37px;
}
.tax-list__checkbox {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 20px;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  border-radius: 4px;
  position: relative;
}
.tax-list__checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--dark-slate-blue);
  opacity: 0;
  pointer-events: none;
}
.tax-list__checkbox[aria-checked=true]:after {
  opacity: 1;
}
.tax-list__purpose {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  line-height: 1.3;
}
.tax-list__type {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.tax-list__sum {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  white-space: nowrap;
  margin-bottom: 8px;
}
.tax-list__pay-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Circe-Regular", sans-serif;
}
.tax-list__item:hover .tax-list__pay-button {
  text-decoration: none;
}

@media screen and (max-width: 980px) {
  .tax-list {
    box-shadow: none;
    border-radius: 0;
    width: calc(100% + 100px);
    margin-left: -50px;
    margin-right: -50px;
  }
  .tax-list__header {
    display: none;
  }
  .tax-list__column:first-child {
    width: 60%;
    min-width: 60%;
  }
  .tax-list__column:nth-child(2) {
    width: 40%;
    min-width: 40%;
  }
  .tax-list__column:nth-child(3) {
    display: none;
  }
  .tax-list__column:last-child {
    width: 40%;
    min-width: 40%;
  }
  .tax-list__main {
    padding-bottom: 0;
  }
  .tax-list__item {
    display: block;
    padding: 12px 50px 8px 50px;
    transition: background 0.3s ease-in-out;
    border-bottom: 1px solid #f6f8fb;
  }
  .tax-list__item_open {
    padding-bottom: 29px;
    background: #f6f8fb;
  }
  .tax-list__item_not_only-child {
    border-bottom-color: var(--dark-slate-blue);
    padding-bottom: 20px;
  }
  .tax-list__item_not_only-child:first-child {
    border-top: 1px solid var(--dark-slate-blue);
  }
  .tax-list__item:hover {
    background: white;
  }
  .tax-list__item-main {
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 31px;
  }
  .tax-list__item_not_only-child .tax-list__item-main {
    padding-bottom: 10px;
  }
  .tax-list__purpose {
    line-height: 20px;
    text-align: left;
  }
  .tax-list__type {
    font-size: 12px;
    color: #b0b5c4;
    font-family: "Circe-Regular", sans-serif;
    text-align: right;
  }
  .tax-list__sum {
    margin-bottom: 3px;
    text-align: right;
  }
  .tax-list__pay-button {
    display: none;
  }
  .tax-list__item-bottom {
    display: flex;
    justify-content: flex-end;
  }
  .tax-list__item-button {
    width: 100px;
    height: 30px;
    font-size: 14px;
    border-radius: 4px;
  }
}
@media screen and (max-width: 600px) {
  .tax-list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
  .tax-list__item {
    padding: 12px 20px 8px 20px;
  }
  .tax-list__item_not_only-child {
    padding-bottom: 20px;
  }
}