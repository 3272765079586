.circle-with-emoji {
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__image {
    width: 40px;
    height: 40px;
  }
}
