.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-operation-item {
  background: white;
  position: relative;
}
.acquiring-operation-item__colored-line {
  position: absolute;
  left: 0;
  top: 0;
  height: 79px;
  width: 2px;
}
.acquiring-operation-item__top {
  height: 80px;
  display: flex;
  padding: 23px 30px 18px 30px;
  width: 100%;
  border-bottom: 1px solid #e4e8ee;
  background: white;
  transition: background 0.3s ease;
  cursor: pointer;
}
.acquiring-operation-item_open .acquiring-operation-item__top {
  background: #f6f8fb;
}
.acquiring-operation-item_not-payed .acquiring-operation-item__top {
  cursor: default;
}
.acquiring-operation-item__column:first-child {
  width: 25%;
  min-width: 130px;
}
.acquiring-operation-item__column:nth-child(2) {
  width: 14%;
  min-width: 85px;
}
.acquiring-operation-item__column:nth-child(3) {
  width: 31.5%;
  min-width: 130px;
}
.acquiring-operation-item__column:nth-child(4) {
  width: calc(29.5% - 30px);
  padding-right: 20px;
  text-align: right;
}
.acquiring-operation-item__column:nth-child(5) {
  width: 30px;
  min-width: 30px;
}
.acquiring-operation-item__id {
  font-size: 16px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
}
.acquiring-operation-item__creation-date {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-operation-item__payer-number {
  color: var(--grey-blue);
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.acquiring-operation-item__status {
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  margin-bottom: 12px;
}
.acquiring-operation-item__last-gotten-date {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-operation-item__amount {
  color: var(--grey-blue);
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Circe-Regular", sans-serif;
  text-align: right;
}
.acquiring-operation-item__card-number {
  font-size: 14px;
  color: #9da6ba;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  white-space: nowrap;
}
.acquiring-operation-item__refund {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
}
.acquiring-operation-item__bottom {
  background: #f6f8fb;
  transition: height 0.3s ease-in-out;
  height: 0;
  position: relative;
  visibility: hidden;
}
.acquiring-operation-item_open .acquiring-operation-item__bottom {
  height: 308px;
  visibility: visible;
}
.acquiring-operation-item__bottom-inner {
  padding: 30px;
}
.acquiring-operation-item__bottom-title {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
}
.acquiring-operation-item__details .acquiring-operation-item__bottom-title {
  margin-bottom: 12px;
}
.acquiring-operation-item__history .acquiring-operation-item__bottom-title {
  margin-bottom: 20px;
}
.acquiring-operation-item__details {
  margin-bottom: 30px;
}
.acquiring-operation-item__details-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.acquiring-operation-item__details-item:last-child {
  margin-bottom: 0;
}
.acquiring-operation-item__details-key {
  color: #b0b5c4;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 184px;
  min-width: 184px;
}
.acquiring-operation-item__details-value {
  color: #b0b5c4;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: calc(100% - 184px);
}
.acquiring-operation-item__download-check {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 32px;
  right: 35px;
}
.acquiring-operation-item__download-check-text {
  font-size: 14px;
  color: #616a88;
  font-family: "Circe-Regular", sans-serif;
  margin-right: 8px;
}
.acquiring-operation-item__download-check-icon {
  width: 14px;
  height: 14px;
}
.acquiring-operation-item__service {
  border-top: 1px solid #e4e8ee;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1100px) {
  .acquiring-operation-item__colored-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .acquiring-operation-item__id-wrap {
    display: flex;
  }
  .acquiring-operation-item__id {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .acquiring-operation-item__column:first-child {
    width: 50%;
    min-width: 50%;
  }
  .acquiring-operation-item__column:nth-child(2) {
    width: 50%;
    min-width: 50%;
    text-align: right;
  }
  .acquiring-operation-item_open .acquiring-operation-item__bottom {
    height: auto;
  }
  .acquiring-operation-item__bottom-title {
    font-size: 14px;
  }
  .acquiring-operation-item__history {
    margin-bottom: 19px;
  }
  .acquiring-operation-item__download-check {
    position: relative;
    top: auto;
    right: auto;
  }
  .acquiring-operation-item__refund {
    margin-top: 0;
    width: auto;
  }
  .acquiring-operation-item__refund-icon {
    margin-right: 12px;
  }
  .acquiring-operation-item__refund-text {
    font-size: 14px;
    color: #616a88;
    font-family: "Circe-Regular", sans-serif;
  }
}
@media screen and (max-width: 480px) {
  .acquiring-operation-item {
    padding-left: 20px;
    padding-right: 20px;
  }
  .acquiring-operation-item__top {
    padding: 23px 20px 18px 20px;
  }
}