.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.transfer-result {
  padding-bottom: 53px;
}
.transfer-result_has-invoice-inside {
  padding-bottom: 20px;
}
.transfer-result__text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-slate-blue);
  text-align: center;
  line-height: 1.2;
  margin-bottom: 30px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.transfer-result__text-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-slate-blue);
  text-align: center;
  line-height: 1.2;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  -webkit-appearance: none;
  box-shadow: none;
}
.transfer-result__text-link:hover {
  text-decoration: none;
}
.transfer-result__icon-wrap {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: white;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-result__icon {
  position: absolute;
  top: 50%;
  left: 50%;
}
.transfer-result__icon_loading {
  width: 27px;
  height: 21px;
  margin-top: -10px;
  margin-left: -13px;
}
.transfer-result__icon_check {
  width: 14px;
  height: 12px;
  margin-top: -5px;
  margin-left: -6px;
}
.transfer-result__icon_fail {
  width: 12px;
  height: 16px;
  margin-top: -8px;
  margin-left: -6px;
}
.transfer-result__icon_fail path {
  fill: var(--reddish-pink);
}
.transfer-result__button-wrap {
  display: flex;
  justify-content: center;
}
.transfer-result__button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 320px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--dark-slate-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  transition: all 0.3s ease;
  text-decoration: none;
}
.transfer-result__button:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.transfer-result__button:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}