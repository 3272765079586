.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.taxes-to-pay-tooltip {
  position: absolute;
  top: 27px;
  left: 50%;
  margin-left: -132px;
  width: 265px;
  min-height: 220px;
  border-radius: 8px;
  background-color: var(--pale-grey);
  padding: 15px 14px 28px 25px;
  opacity: 0;
  visibility: hidden;
}
.taxes-to-pay-tooltip:before {
  border-bottom: 6px solid var(--pale-grey);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  top: -5px;
  width: 0;
}
.taxes-to-pay-tooltip_dark {
  background-color: #616a88;
  width: 280px;
  margin-left: -146px;
  top: 32px;
}
.taxes-to-pay-tooltip_dark:before {
  border-bottom: 6px solid #616a88;
}
.taxes-to-pay-tooltip_open {
  opacity: 1;
  visibility: visible;
}
.taxes-to-pay-tooltip__text-title {
  font-size: 10px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 16px;
}
.taxes-to-pay-tooltip_dark .taxes-to-pay-tooltip__text-title {
  margin-bottom: 19px;
  color: white;
  font-size: 14px;
}
.taxes-to-pay-tooltip__text {
  font-size: 10px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 16px;
}
.taxes-to-pay-tooltip__text:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-tooltip__text-wrap .taxes-to-pay-tooltip__text {
  margin-bottom: 8px;
}
.taxes-to-pay-tooltip__text-wrap .taxes-to-pay-tooltip__text:last-child {
  margin-bottom: 0;
}
.taxes-to-pay-tooltip_dark .taxes-to-pay-tooltip__text {
  color: white;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
}

@media screen and (max-width: 1194px) {
  .taxes-to-pay-tooltip {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    margin-left: 0;
    height: auto;
    border-radius: 8px 8px 0 0;
    padding: 30px 20px 20px 20px;
  }
  .taxes-to-pay-tooltip_dark {
    border-radius: 8px;
  }
  .taxes-to-pay-tooltip_dark:before {
    display: none;
  }
  .taxes-to-pay-tooltip__text {
    font-size: 14px;
    line-height: 1.71;
  }
  .taxes-to-pay-tooltip__text-wrap .taxes-to-pay-tooltip__text {
    margin-bottom: 2px;
  }
}