.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.profile-settings-partners-tab {
  padding-left: 110px;
}
.profile-settings-partners-tab__title {
  font-family: "Circe-Bold", sans-serif;
  font-size: 32px;
  margin-top: 16px;
  margin-bottom: 42px;
  color: var(--dark-slate-blue);
}
.profile-settings-partners-tab__container {
  margin-top: 20px;
}
.profile-settings-partners-tab__container__table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 320px;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.profile-settings-partners-tab__container__table svg {
  margin-bottom: 20px;
  width: 42px;
  height: 42px;
}
.profile-settings-partners-tab__error {
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #b0b5c4;
  text-align: center;
  max-width: 390px;
  padding: 0 20px;
}

@media screen and (max-width: 1194px) {
  .profile-settings-partners-tab {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  .profile-settings-partners-tab__title {
    display: none;
  }
}