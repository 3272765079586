.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.business-card {
  background: white;
  border-radius: 14px;
  box-shadow: 0 4px 28px 0 rgba(156, 171, 222, 0.39);
  position: relative;
  width: 600px;
}
.business-card__header {
  background-image: linear-gradient(to right, rgb(108, 92, 231) 0%, rgb(90, 200, 250) 100%);
  border-radius: 14px 14px 0 0;
  min-height: 121px;
  overflow: hidden;
  padding: 16px;
  position: relative;
}
.business-card__header_menu-open {
  background: white;
}
.business-card__header_another {
  padding: 16px;
}
@media (min-width: 981px) {
  .business-card__header__whiteLogoWrap {
    display: none;
  }
}
.business-card__header__whiteLogoWrap svg {
  z-index: 100;
  height: 40px;
  width: 40px;
}
.business-card__header-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 22px;
  position: relative;
  z-index: 30;
}
.business-card__header-subtitle {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 22px;
  position: relative;
  z-index: 30;
}
.business-card__header-link-block {
  align-items: center;
  display: flex;
  margin-bottom: 14px;
  position: relative;
  z-index: 30;
}
.business-card__header-link-block-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 14px;
  margin-right: 10px;
  width: 12px;
}
.business-card__header-link-block-icon {
  height: 14px;
  width: 12px;
}
.business-card__header-link-block-icon path {
  fill: white;
}
.business-card__header-link-block-url {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 14px;
  margin-right: 10px;
  text-decoration: underline;
  word-break: break-all;
}
.business-card__header-link-block-copied {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: #00adb5;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  padding: 0 12px;
}
.business-card__header-bg {
  position: absolute;
  right: -52px;
  top: -120px;
  width: 254px;
  z-index: 20;
}
.business-card__top-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.business-card__top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 80;
  height: 30px;
  margin-bottom: 16px;
}
.business-card__top-nav_another {
  height: 30px;
}
.business-card__back-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  transform: rotate(180deg);
  flex-direction: row-reverse;
  gap: 10px;
}
.business-card__back-button-text {
  transform: rotate(180deg);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}
.business-card__back-button-icon {
  height: 23px;
  width: 13px;
}
.business-card__back-button-icon_menu-open path {
  stroke: var(--dark-slate-blue);
}
.business-card__responsive-logo-icon path {
  fill: white;
}
.business-card__burger {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  height: 20px;
  opacity: 1;
  position: relative;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  width: 30px;
  z-index: 20;
}
.business-card__burger-span {
  display: flex;
  height: 3px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
.business-card__burger-span path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__burger-span:first-child {
  animation: burger-animation-line-1-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card__burger-span:nth-child(2) {
  animation: burger-animation-line-2-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  top: 9px;
}
.business-card__burger-span:nth-child(3) {
  animation: burger-animation-line-3-out 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card__burger_menu-open .business-card__burger-span:first-child {
  animation: burger-animation-line-1 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card__burger_menu-open .business-card__burger-span:first-child path {
  fill: var(--dark-slate-blue);
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(2) {
  animation: burger-animation-line-2 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(2) path {
  fill: var(--dark-slate-blue);
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(3) {
  animation: burger-animation-line-3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) forwards;
  background: var(--dark-slate-blue);
  border-radius: 4px;
}
.business-card__burger_menu-open .business-card__burger-span:nth-child(3) path {
  fill: var(--dark-slate-blue);
}
.business-card__close-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.business-card__share-block {
  align-items: center;
  display: flex;
  padding-left: 22px;
  position: relative;
  z-index: 30;
}
.business-card__share-item {
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin-right: 10px;
  padding: 0;
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}
.business-card__share-item-icon {
  height: 15px;
  width: 20px;
  pointer-events: none;
}
.business-card__share-item-icon_envelope {
  height: 14px;
}
.business-card__share-item-icon_envelope g {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_envelope g {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_vk path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_vk path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_ok path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_ok path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_wa path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_wa path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_fb path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_fb path {
  fill: var(--dark-slate-blue);
}
.business-card__share-item-icon_tg path {
  fill: white;
  transition: all 0.3s ease;
}
.business-card__share-item:hover .business-card__share-item-icon_tg path {
  fill: var(--dark-slate-blue);
}
.business-card__content {
  padding-bottom: 18px;
  position: relative;
}
.business-card__content-text {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
  margin-bottom: 14px;
  text-align: center;
}
.business-card__content-text-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  font-size: 18px;
  margin-bottom: 8px;
  text-align: center;
}
.business-card__content-text_error {
  color: var(--reddish-pink);
}
.business-card__avatar-wrap {
  border-radius: 50%;
  height: 90px;
  margin: -45px auto 20px auto;
  z-index: 40;
  position: relative;
  width: 90px;
}
.business-card__avatar-wrap_business-card-to-pay {
  margin-bottom: 20px;
}
.business-card__avatar-wrap_avatar {
  border-radius: 50%;
  background: #f6f8fb;
  border: 2px solid white;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.business-card__avatar-wrap_icon-payment {
  padding: 23px;
  border-radius: 50%;
  background: #f6f8fb;
  border: 2px solid white;
}
.business-card__avatar-wrap_error {
  border-color: var(--reddish-pink);
  margin-bottom: 0;
}
.business-card__avatar {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.business-card__avatar-error {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--reddish-pink);
  font-size: 10px;
  margin: 10px 0 20px;
  text-align: center;
}
.business-card__card {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
  width: 100%;
}
.business-card__iframe-wrap {
  height: 421px;
  position: relative;
}
.business-card__iframe-wrap-sbp {
  height: 690px;
  position: relative;
  margin-bottom: 0px;
}
.business-card__iframe-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.business-card__iframe {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}
.business-card__block-list {
  margin-bottom: 30px;
}
.business-card__block {
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
  width: 100%;
}
.business-card__block:last-child {
  margin-bottom: 0;
}
.business-card__input-holder {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}
.business-card__input {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  background-color: rgba(228, 232, 238, 0.2);
  border: solid 1px rgba(157, 166, 186, 0.2);
  border-radius: 8px;
  color: var(--dark-slate-blue);
  font-size: 16px;
  height: 60px;
  padding: 10px 20px 0;
  transition: all 0.3s ease;
  width: 100%;
}
.business-card__input-holder_error .business-card__input {
  color: var(--reddish-pink);
}
.business-card__input:focus {
  border-color: var(--dark-slate-blue);
}
.business-card__input::-webkit-input-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input::-moz-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input:-moz-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input:-ms-input-placeholder {
  color: #9da6ba;
  opacity: 1;
}
.business-card__input[type=number]::-webkit-outer-spin-button, .business-card__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.business-card__input[type=number] {
  -moz-appearance: textfield;
}
.business-card__input_message {
  font-size: 14px;
}
.business-card__input:disabled {
  cursor: default;
}
.business-card__label {
  font-family: "Circe-Regular", sans-serif;
  color: #9da6ba;
  font-size: 18px;
  left: 24px;
  pointer-events: none;
  position: absolute;
  top: 22px;
  transform: scale(1);
  transform-origin: 0 0;
  transition: all 0.3s ease;
}
.business-card__input-holder:focus-within .business-card__label {
  color: #616a88;
  left: 20px;
  top: 10px;
  transform: scale(0.6);
  white-space: nowrap;
}
.business-card__input-holder_focused .business-card__label {
  color: #616a88;
  left: 20px;
  top: 10px;
  transform: scale(0.6);
  white-space: nowrap;
}
.business-card__input-holder_focused.business-card__input-holder_error .business-card__label {
  color: var(--reddish-pink);
}
.business-card__input-holder_error .business-card__label {
  color: var(--reddish-pink);
}
.business-card__input-ruble {
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 22px;
}
.business-card__block-text {
  font-family: "Circe-Regular", sans-serif;
  color: var(--grey-blue);
  font-size: 12px;
  line-height: 1.17;
}
.business-card__block-text_error {
  color: var(--reddish-pink);
}
.business-card__btn-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 382px;
}
.business-card__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  align-items: center;
  background-color: var(--dark-slate-blue);
  border-radius: 8px;
  color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  transition: all 0.3s ease;
  width: 320px;
}
.business-card__btn:hover:not(:disabled) {
  background: var(--dusk-blue);
}
.business-card__btn:disabled {
  background: rgba(29, 46, 105, 0.1);
  cursor: not-allowed;
}
.business-card__payment-ways {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.business-card__payment-ways-item_visa {
  height: 20px;
  margin-right: 30px;
  width: 45px;
}
.business-card__payment-ways-item_msc {
  height: 19px;
  margin-right: 24px;
  width: 31px;
}
.business-card__payment-ways-item_mir {
  height: 19px;
  margin-right: 19px;
  width: 46px;
}
.business-card__payment-ways-item_pci {
  height: 18px;
  width: 44px;
}
.business-card__info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
  padding-left: 23px;
  padding-right: 23px;
  text-align: center;
  max-width: 520px;
  margin: 0 auto;
}
.business-card__info-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--bluey-grey);
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.business-card__info-link:hover {
  text-decoration: none;
}
.business-card__close {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 rgba(219, 225, 235, 0.4);
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: -58px;
  top: 0;
  width: 48px;
}
.business-card__close-icon path {
  transition: stroke 0.2s ease-in;
}
.business-card__close:hover .business-card__close-icon path {
  stroke: var(--reddish-pink);
}
.business-card__translate-to-ru {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media screen and (max-width: 980px) {
  .business-card {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }
  .business-card__header {
    border-radius: 0;
    min-height: 150px;
    height: 150px;
    padding: 20px 50px;
  }
  .business-card__back-button {
    width: 30px;
  }
  .business-card__header-title {
    padding-left: 0;
    text-align: center;
    font-size: 12px;
    margin-bottom: 4px;
    z-index: 21;
  }
  .business-card__header-subtitle {
    padding-left: 0;
    text-align: center;
    font-size: 12px;
    margin-bottom: 4px;
    z-index: 21;
  }
  .business-card__close-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 999;
  }
  .business-card__header-link-block {
    justify-content: center;
    margin-bottom: 10px;
  }
  .business-card__header-link-block-icon path {
    fill: var(--bluey-grey);
  }
  .business-card__header-link-block-url {
    color: var(--bluey-grey);
    margin-right: 0;
  }
  .business-card__header-link-block-copied {
    align-self: center;
    background-color: rgba(0, 173, 181, 0.1);
    color: var(--turquoise-blue);
    display: flex;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }
  .business-card__block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 382px;
    width: 100%;
  }
  .business-card__input-holder {
    margin-bottom: 10px;
    width: 100%;
  }
  .business-card__block-text {
    text-align: center;
  }
  .business-card__avatar-wrap {
    margin-bottom: 10px;
  }
  .business-card__avatar-wrap_error {
    margin-bottom: 0;
  }
  .business-card__share-block {
    justify-content: center;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .business-card__share-item-icon_envelope {
    height: 14px;
  }
  .business-card__share-item-icon_envelope g {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_share {
    height: 18px;
    width: 18px;
  }
  .business-card__share-item-icon_share path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_vk path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_ok path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_wa path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_fb path {
    fill: var(--grey-blue);
  }
  .business-card__share-item-icon_tg path {
    fill: var(--grey-blue);
  }
  .business-card__btn {
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
    width: 100%;
  }
  .business-card__close {
    bottom: 0;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    z-index: 30;
  }
  .business-card__translate-to-ru {
    position: static;
    margin-right: 10px;
  }
}
@media screen and (max-width: 700px) {
  .business-card__block-list {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .business-card__header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .business-card__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .business-card__avatar-error {
    font-size: 14px;
    line-height: 1.42;
  }
  .business-card__iframe-wrap-sbp {
    height: 710px;
  }
}
@media screen and (max-width: 350px) {
  .business-card__header {
    padding-left: 14px;
    padding-right: 14px;
  }
  .business-card__content {
    padding-left: 14px;
    padding-right: 14px;
  }
}