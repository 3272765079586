.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.code-block__title {
  color: var(--dark-slate-blue);
  font-size: 36px;
  font-family: "Circe-ExtraBold", sans-serif;
  font-size: 36px;
  line-height: 53px;
  margin-bottom: 8px;
}
.code-block_sidebar .code-block__title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 28px;
}
.code-block__info {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 40px;
  text-align: center;
  color: var(--light-grey-blue);
}
.code-block__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code-block__subtitle {
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
  font-size: 18px;
  margin-bottom: 30px;
}
.code-block__block {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}
.code-block__input-holder {
  width: 48px;
  margin-right: 16px;
  position: relative;
}
.code-block__input-holder_filled:after {
  opacity: 0;
}
.code-block__input-holder_blur {
  position: relative;
}
.code-block__input-holder_blur:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: black;
  pointer-events: none;
}
.code-block__input-holder_error:after {
  background: var(--reddish-pink);
}
.code-block__input {
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  text-align: center;
  color: var(--dark-slate-blue);
  font-size: 16px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  font-family: "Circe-Bold", sans-serif;
  height: 56px;
  border-radius: 9px;
  padding: 0;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  margin-right: 16px;
  pointer-events: auto;
}
.code-block__input::-webkit-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input::-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input:-moz-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input:-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input::-ms-input-placeholder {
  opacity: 1;
  letter-spacing: 0;
  font-family: "Circe-Regular", sans-serif;
  color: rgba(159, 167, 179, 0.7);
}
.code-block__input::-ms-clear {
  display: none;
}
.code-block__input:focus {
  border-color: var(--dark-blue);
  box-shadow: none;
}
.code-block__input:focus:after {
  opacity: 0;
}
.code-block__input_fail {
  border: solid 1px var(--reddish-pink);
  color: var(--reddish-pink);
  box-shadow: none;
}
.code-block__input[type=number]::-webkit-outer-spin-button, .code-block__input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code-block__input[type=number] {
  -moz-appearance: textfield;
}
.code-block__input-check {
  position: relative;
  right: auto;
  top: 0;
}
.code-block__error-wrap {
  margin-bottom: 20px;
}
.code-block__error-message {
  color: var(--reddish-pink);
  text-align: center;
  font-family: "Circe-Regular", sans-serif;
  font-size: 16px;
  width: 100%;
}
.code-block__tip {
  padding-top: 50px;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--light-grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.code-block__tip-link {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  font-size: 14px;
  margin-left: 3px;
  text-decoration: none;
  cursor: pointer;
  color: var(--light-grey-blue);
}
.code-block__tip-link__btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: var(--dark-slate-blue);
  font-size: 14px;
}
.code-block__hint {
  padding-bottom: 50px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ff2d55;
}
.code-block__help-block {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: center;
  color: var(--light-grey-blue);
  font-size: 14px;
}