.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.popupCalendar {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.popupCalendarWrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 50px 100px;
}
.popupCalendarContainer {
  position: relative;
  width: 100%;
}
.popupCalendarTitle {
  font-family: "Circe-Bold", sans-serif;
  margin-top: 16px;
  line-height: 36px;
  font-size: 28px;
  color: #1d2e69;
}
.popupCalendarDatePicker {
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 40px;
  border: 1px solid #1d2e69;
  border-radius: 8px;
}
.popupCalendarDatePickerTitle {
  font-family: "Circe-Regular", sans-serif;
  margin-top: 10px;
  margin-left: 20px;
  line-height: 8px;
  text-transform: uppercase;
  font-size: 8px;
  color: #616a88;
}
.popupCalendarDatePickerPeriod {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 20px;
  line-height: 24px;
  font-size: 16px;
  color: #1d2e69;
}
.popupCalendarDatePickerCalendarIcon {
  position: absolute;
  top: 20px;
  right: 16px;
  width: 20px;
  height: 20px;
  opacity: 0.2;
}
.popupCalendarDatePickerActive {
  border-color: transparent;
  box-shadow: 0 8px 27px rgba(23, 28, 36, 0.14);
}
.popupCalendarApplyButton {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-family: "Circe-Bold", sans-serif;
  width: 100%;
  margin-top: 20px;
  padding: 18px 0;
  border-radius: 8px;
  background-color: #1d2e69;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}
.popupCalendarBlock {
  position: absolute;
  top: 135px;
  left: 0;
  width: 100%;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 8px 27px rgba(23, 28, 36, 0.14);
  background-color: #fff;
}

@media (max-width: 980px) {
  .popupCalendarWrapper {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}