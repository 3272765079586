.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.operations {
  width: 100%;
  border-radius: 6px;
  background-color: white;
  padding: 30px 0;
  flex-grow: 20;
  position: relative;
}
.operations__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--pale-grey);
  padding-bottom: 19px;
  padding-left: 30px;
  padding-right: 30px;
}
.operations__content-wrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.operations__list {
  padding-bottom: 50px;
}
.operations__list:empty {
  padding-bottom: 0;
}
.operations__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid var(--pale-grey);
  border-top: 1px solid transparent;
  padding: 10px 30px;
  transition: all 0.3s ease;
  position: relative;
}
.operations__item:hover {
  border-bottom-color: rgba(29, 46, 105, 0.2);
  border-top-color: rgba(29, 46, 105, 0.2);
}
.operations__item_open {
  background: rgba(246, 248, 251, 0.56);
}
.operations__item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operations__item-title-wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 300px);
}
.operations__item-icon-wrap {
  min-width: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.operations__item-icon {
  width: 16px;
  height: 16px;
  fill: var(--grey-blue);
}
.operations__item-icon_clock {
  width: 18px;
  height: 18px;
}
.operations__item-icon_card {
  width: 16px;
  height: 13px;
}
.operations__item-icon_card path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_card path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_coins {
  width: 16px;
  height: 16px;
}
.operations__item-icon_coins path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_coins path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_bill {
  height: 15px;
}
.operations__item-icon_bill path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_bill path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_business-card {
  height: 12px;
}
.operations__item-icon_business-card path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_business-card path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_sz {
  fill: var(--bluey-grey);
  height: 12px;
}
.operations__item-icon_sz path {
  fill: var(--bluey-grey);
}
.operations__item_open .operations__item-icon_sz path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_web-money {
  width: 16px;
  height: 16px;
}
.operations__item-icon_web-money path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.operations__item_open .operations__item-icon_web-money path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_yandex {
  width: 14px;
  height: 18px;
}
.operations__item-icon_yandex path:first-child {
  fill: #c0c4d4;
  transition: all 0.3s ease;
}
.operations__item_open .operations__item-icon_yandex path:first-child {
  fill: var(--dark-slate-blue);
}
.operations__item_open .operations__item-icon_yandex path {
  fill: #c0c4d4;
}
.operations__item-icon_moneybox {
  width: 14px;
  height: 15px;
}
.operations__item_open .operations__item-icon_moneybox path {
  fill: var(--dark-slate-blue);
}
.operations__item-icon_chashbox {
  width: 17px;
  height: 16px;
  fill: #b0b5c4;
}
.operations__item_open .operations__item-icon_chashbox {
  fill: var(--dark-slate-blue);
}
.operations__item-title {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: left;
  transition: all 0.3s ease-in-out;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.operations__header .operations__item-title {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  overflow: visible;
  max-width: 100%;
}
.operations__item:hover .operations__item-title {
  color: var(--dark-slate-blue);
}
.operations__item_open .operations__item-title {
  color: var(--dark-slate-blue);
  word-break: break-all;
  overflow: visible;
  text-overflow: initial;
  width: 100%;
  white-space: normal;
}
.operations__item-date {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 150px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.operations__item:hover .operations__item-date {
  color: var(--dark-slate-blue);
}
.operations__item_open .operations__item-date {
  color: var(--dark-slate-blue);
}
.operations__item-date-itself {
  font-size: 14px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  white-space: nowrap;
}
.operations__item-sum {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  max-width: 150px;
  width: 100%;
  text-align: right;
  color: var(--turquoise-blue);
}
.operations__item-sum_minus {
  color: var(--reddish-pink);
}
.operations__item-sum_unsuccess {
  color: var(--bluey-grey);
}
.operations__header .operations__item-sum {
  color: var(--grey-blue);
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.operations__item-bottom {
  display: flex;
  justify-content: space-between;
  padding-left: 36px;
  padding-top: 10px;
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.operations__item_open .operations__item-bottom {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.operations__item-message-wrap {
  text-align: left;
}
.operations__item-message-title {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--bluey-grey);
  margin-right: 5px;
}
.operations__item-message-text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  display: inline;
  color: var(--grey-blue);
  word-break: break-all;
}
.operations__item-message-text_green {
  color: var(--turquoise-blue);
}
.operations__item-message-text_orange {
  color: var(--light-mustard);
}
.operations__item-message-text_red {
  color: var(--reddish-pink);
}
.operations__item-download-bill-link {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.58;
}
.operations__item-download-bill-link:hover {
  text-decoration: none;
}
.operations__no-operations-message {
  margin-top: 50px;
}
.operations__next-page-btn-wrap {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.operations__next-page-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.operations__next-page-btn-icon {
  transform: rotate(90deg);
}
.operations__next-page-btn-icon path {
  stroke: #e8eaf0;
  transition: all 0.3s ease;
}
.operations__next-page-btn:hover .operations__next-page-btn-icon path {
  stroke: var(--dark-slate-blue);
}
.operations__loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
}
.operations__loading_full-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.operations__track {
  width: 4px;
  background: transparent;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  user-select: none;
  height: calc(100% - 20px);
  top: 10px;
  right: 10px;
}
.operations__thumb {
  width: 4px;
  border-radius: 2px;
  background-color: var(--pale-grey);
  position: absolute;
}

@media screen and (max-width: 980px) {
  .operations__month-navigation {
    width: 100%;
  }
  .operations__list {
    padding-bottom: 0;
  }
  .operations__item-title-wrap {
    width: 70%;
    min-width: 70%;
    padding-right: 10px;
  }
  .operations__item-title {
    font-size: 16px;
  }
  .operations__item-right-part {
    width: 30%;
    min-width: 30%;
    text-align: right;
  }
  .operations__item-sum {
    max-width: 100%;
    font-size: 20px;
  }
  .operations__item-date {
    max-width: 100%;
    text-align: right;
  }
  .operations__item-bottom {
    display: block;
    text-align: left;
  }
  .operations__item-additional-info {
    margin-bottom: 12px;
  }
  .operations__item-download-bill-link {
    font-size: 14px;
  }
  .operations__loading {
    position: static;
    height: 191px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 700px) {
  .operations__item_open .operations__item-title-wrap {
    align-items: flex-start;
  }
  .operations__item_open .operations__item-icon_card {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_coins {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_bill {
    margin-top: 2px;
  }
  .operations__item_open .operations__item-icon_business-card {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_sz {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_web-money {
    margin-top: 5px;
  }
  .operations__item_open .operations__item-icon_yandex {
    margin-top: 1px;
  }
}
@media screen and (max-width: 600px) {
  .operations__content-wrapper {
    height: 290px;
  }
  .operations__item {
    padding: 10px 5px;
  }
  .operations__track {
    right: 0;
  }
}