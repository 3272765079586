.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-getting-step {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background: #f6f8fb;
  border-radius: 12px;
  height: 68px;
  padding: 12px 12px 12px 48px;
  text-align: left;
  width: 100%;
  position: relative;
  border: 1px solid #f6f8fb;
}
.acquiring-getting-step:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: -4px;
  background: var(--light-grey-blue);
}
.acquiring-getting-step_active {
  background: #ffffff;
  border-color: white;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  cursor: default;
}
.acquiring-getting-step_active:before {
  background: var(--deep-sky-blue);
}
.acquiring-getting-step_gotten {
  border-color: var(--turquoise-blue);
  background: white;
}
.acquiring-getting-step_gotten:before {
  background: var(--turquoise-blue);
}
.acquiring-getting-step:disabled {
  cursor: not-allowed;
}
.acquiring-getting-step__text {
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 6px;
  color: var(--light-grey-blue);
}
.acquiring-getting-step__title {
  font-size: 18px;
  color: var(--light-grey-blue);
  font-family: "Circe-Bold", sans-serif;
}
.acquiring-getting-step_active .acquiring-getting-step__title {
  color: var(--dark-slate-blue);
}
.acquiring-getting-step_gotten .acquiring-getting-step__title {
  color: var(--dark-slate-blue);
}
.acquiring-getting-step__check-icon {
  position: absolute;
  width: 10px;
  height: 8px;
  right: 24px;
  top: 30px;
}