.show-case {
  display: flex;
  flex-grow: 200;
}
.show-case__loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}