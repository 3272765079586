.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.notifications-dropdown {
  width: 298px;
  min-height: 375px;
  height: auto;
  padding: 15px 0 40px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  position: absolute;
  top: 77px;
  right: 17px;
  border-radius: 8px;
  z-index: 1070;
  margin-right: -136px;
}
.notifications-dropdown:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -9px;
  margin-left: -5px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}
.notifications-dropdown__mark-all-as-read-btn {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.notifications-dropdown__mark-all-as-read-icon {
  width: 8px;
  height: 8px;
  border: solid 1px var(--grey-blue);
  margin-right: 6px;
  border-radius: 50%;
}
.notifications-dropdown__mark-all-as-read-text {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
.notifications-dropdown__mark-all-as-read-btn:hover .notifications-dropdown__mark-all-as-read-text {
  text-decoration: none;
}
.notifications-dropdown__no-messages-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications-dropdown__no-messages {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--grey-blue);
  text-align: center;
}
.notifications-dropdown__item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 5px 20px 15px 30px;
  text-align: left;
  transition: all 0.3s ease;
  background: white;
}
.notifications-dropdown__item:hover {
  background-color: var(--pale-grey);
}
.notifications-dropdown__item-date {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}
.notifications-dropdown__item-title {
  font-size: 12px;
  line-height: 1.33;
  color: var(--dark-slate-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  position: relative;
}
.notifications-dropdown__item-title:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 2px;
  background-color: var(--turquoise-blue);
}
.notifications-dropdown__item_unread .notifications-dropdown__item-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.notifications-dropdown__item_unread .notifications-dropdown__item-title:before {
  background-color: var(--reddish-pink);
}
.notifications-dropdown__item-type {
  font-size: 12px;
  color: var(--grey-blue);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.notifications-dropdown__show-more {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--grey-blue);
  text-decoration: underline;
}
.notifications-dropdown__show-more:hover {
  text-decoration: none;
}
.notifications-dropdown__loader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}