.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.money-transfer__sidebar {
  position: relative;
  flex: none;
  width: 320px;
  margin-right: 100px;
}
.money-transfer__sidebar-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid rgba(157, 166, 186, 0.2);
  background-color: rgba(228, 232, 238, 0.2);
  box-shadow: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link:hover {
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  border-color: #fff;
}
.money-transfer__sidebar-link:last-child {
  margin-bottom: 0;
}
.money-transfer__sidebar-link-active {
  background-color: #fff;
  border-color: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-title {
  font-family: "Circe-Regular", sans-serif;
  font-size: 18px;
  color: #9da6ba;
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-title {
  color: var(--grey-blue);
}
.money-transfer__sidebar-link:active .money-transfer__sidebar-link-title {
  color: var(--grey-slate-blue);
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-title {
  color: var(--grey-slate-blue);
}
.money-transfer__sidebar-link-icon_card path {
  width: 25px;
  height: 18px;
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_card path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_card path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-icon_wallet {
  width: 25px;
  height: 22px;
}
.money-transfer__sidebar-link-icon_wallet path {
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_wallet path {
  fill: var(--dark-slate-blue);
  stroke: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-icon_sbp {
  width: 25px;
  height: 24px;
}
.money-transfer__sidebar-link-icon_sbp path {
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-inactive:not(:hover) .money-transfer__sidebar-link-icon_sbp path {
  fill: var(--bluey-grey);
}
.money-transfer__sidebar-link-icon_webmoney {
  width: 25px;
  height: 24px;
}
.money-transfer__sidebar-link-icon_webmoney path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_webmoney path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_webmoney path {
  fill: var(--dark-slate-blue);
}
.money-transfer__sidebar-link-icon_umoney {
  width: 30px;
  height: 22px;
}
.money-transfer__sidebar-link-icon_umoney path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_umoney path {
  fill: #8b3ffd;
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_umoney path {
  fill: #8b3ffd;
}
.money-transfer__sidebar-link-icon_qiwi {
  width: 31px;
  height: 32px;
}
.money-transfer__sidebar-link-icon_qiwi path {
  fill: var(--bluey-grey);
  transition: all 0.3s ease;
}
.money-transfer__sidebar-link-active .money-transfer__sidebar-link-icon_qiwi path {
  fill: #ff8c00;
}
.money-transfer__sidebar-link:hover .money-transfer__sidebar-link-icon_qiwi path {
  fill: #ff8c00;
}

@media (max-width: 980px) {
  .money-transfer__sidebar {
    width: 100%;
    margin-right: 0;
  }
  .money-transfer__sidebar-link-icon_card path {
    fill: var(--dark-slate-blue);
  }
  .money-transfer__sidebar-link-icon_wallet path {
    fill: var(--dark-slate-blue);
    stroke: var(--dark-slate-blue);
  }
  .money-transfer__sidebar-link-icon_webmoney path {
    fill: var(--dark-slate-blue);
  }
  .money-transfer__sidebar-link-icon_umoney path {
    fill: #8b3ffd;
  }
  .money-transfer__sidebar-link-icon_qiwi path {
    fill: #ff8c00;
  }
}