.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.acquiring-getting-step-3 {
  padding-bottom: 100px;
}
.acquiring-getting-step-3__title {
  margin-bottom: 43px;
}
.acquiring-getting-step-3__content {
  display: flex;
  width: 100%;
}
.acquiring-getting-step-3__inputs {
  width: 350px;
  min-width: 350px;
  margin-right: 60px;
}
.acquiring-getting-step-3__inputs-block {
  margin-bottom: 45px;
}
.acquiring-getting-step-3__inputs-title {
  font-size: 18px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  margin-bottom: 20px;
}
.acquiring-getting-step-3__inputs-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-blue);
  margin-bottom: 20px;
  font-family: "Circe-Regular", sans-serif;
  margin-top: -8px;
}
.acquiring-getting-step-3__input {
  margin-bottom: 20px;
}
.acquiring-getting-step-3__input .input-with-label-animation__input {
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
}
.acquiring-getting-step-3__input:last-child {
  margin-bottom: 0;
}
.acquiring-getting-step-3__input_password .input-with-label-animation__input {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
.acquiring-getting-step-3__input-copy-button {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 42px;
  top: 25px;
  z-index: 40;
}
.acquiring-getting-step-3__input-copy-button path {
  fill: #616a88;
}
.acquiring-getting-step-3__input-copy-button_alone {
  right: 17px;
  top: 20px;
}
.acquiring-getting-step-3__input-edit-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  right: 20px;
  top: 50%;
  margin-top: -6px;
}
.acquiring-getting-step-3__input-show-value {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: absolute;
  right: 58px;
  top: 25px;
  z-index: 40;
}
.acquiring-getting-step-3__input-show-value-icon {
  width: 21px;
  height: 14px;
}
.acquiring-getting-step-3__input-show-value-icon path {
  fill: #616a88;
}
.acquiring-getting-step-3__input-regenerate {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25px;
  right: 17px;
}
.acquiring-getting-step-3__switcher-item {
  margin-bottom: 18px;
}
.acquiring-getting-step-3__switcher-item:last-child {
  margin-bottom: 0;
}
.acquiring-getting-step-3__side {
  width: calc(100% - 350px - 60px);
}
.acquiring-getting-step-3__message {
  margin-bottom: 30px;
  border-radius: 8px;
}
.acquiring-getting-step-3__buttons-list {
  display: flex;
}
.acquiring-getting-step-3__buttons-item {
  width: 165px;
  margin-right: 20px;
}
.acquiring-getting-step-3__buttons-item:last-child {
  margin-right: 0;
}
.acquiring-getting-step-3__page-way {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  height: 72px;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 2px 20px 2px 52px;
  width: 100%;
}
.acquiring-getting-step-3__page-way_active {
  border-color: #00adb5;
}
.acquiring-getting-step-3__page-way:last-child {
  margin-bottom: 0;
}
.acquiring-getting-step-3 .acquiring-getting-step-3__page-way_disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.acquiring-getting-step-3__page-way-checkbox {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #e4e8ee;
  background: #f6f8fb;
  left: 20px;
  top: 16px;
}
.acquiring-getting-step-3__page-way_active .acquiring-getting-step-3__page-way-checkbox {
  border-color: var(--dark-slate-blue);
}
.acquiring-getting-step-3__page-way_active .acquiring-getting-step-3__page-way-checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--dark-slate-blue);
  margin-top: -4px;
  margin-left: -4px;
}
.acquiring-getting-step-3__page-way-title {
  font-size: 14px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
  display: block;
  text-align: left;
}
.acquiring-getting-step-3__page-way-desc {
  color: #b0b5c4;
  font-size: 12px;
  font-family: "Circe-Regular", sans-serif;
  display: block;
  text-align: left;
}
.acquiring-getting-step-3__copied-hover {
  z-index: 60;
}
.acquiring-getting-step-3__loader {
  min-height: 1500px;
  display: flex;
  padding-top: 160px;
  justify-content: center;
}

@media screen and (max-width: 1225px) {
  .acquiring-getting-step-3_step .acquiring-getting-step-3__inputs {
    margin-right: 20px;
  }
  .acquiring-getting-step-3_step .acquiring-getting-step-3__side {
    width: calc(100% - 350px - 20px);
  }
}
@media screen and (max-width: 1194px) {
  .acquiring-getting-step-3_step .acquiring-getting-step-3__content {
    display: block;
  }
  .acquiring-getting-step-3_step .acquiring-getting-step-3__inputs {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .acquiring-getting-step-3_step .acquiring-getting-step-3__side {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .acquiring-getting-step-3__content {
    display: block;
  }
  .acquiring-getting-step-3__inputs {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .acquiring-getting-step-3__side {
    width: 100%;
  }
}