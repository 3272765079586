.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.save-money-creation {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.save-money-creation__container {
  padding: 50px 50px 100px 50px;
  max-width: 1300px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.save-money-creation__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.save-money-creation__header-wrap {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.save-money-creation__header-wrap .popup-close-with-text {
  position: relative;
  top: auto;
  right: auto;
}
.save-money-creation__header-logo-wrap {
  text-decoration: none;
  width: 38px;
  height: 33px;
  max-width: 38px;
  max-height: 33px;
}
.save-money-creation__header-logo {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.save-money-creation__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.save-money-creation__form-wrap {
  max-width: 380px;
  width: 100%;
}
.save-money-creation__result {
  display: flex;
  justify-content: flex-end;
  flex-grow: 10;
  width: calc(100% - 380px);
  padding-left: 22px;
}
.save-money-creation__result-inner-wrap {
  width: 100%;
  max-width: 688px;
}
.save-money-creation__result-nav {
  margin-bottom: 20px;
}
.save-money-creation__result-nav-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--bluey-grey);
  margin-bottom: 10px;
}
.save-money-creation__result-nav-item-wrap {
  margin-bottom: 10px;
}
.save-money-creation__result-nav-item-wrap:last-child {
  margin-bottom: 0;
}
.save-money-creation__result-nav-item {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: var(--dark-slate-blue);
  border-bottom: 2px dashed var(--dark-slate-blue);
  transition: all 0.3s ease;
}
.save-money-creation__result-nav-item:hover {
  border-color: transparent;
}
.save-money-creation__preview-wrap {
  width: 100%;
}

@media screen and (max-width: 980px) {
  .save-money-creation__container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .save-money-creation__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
  }
  .save-money-creation__close {
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .save-money-creation__content {
    display: block;
  }
  .save-money-creation__form-wrap {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .save-money-creation__result-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
  .save-money-creation__result-nav-item-wrap {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .save-money-creation__container {
    padding-top: 20px;
  }
  .save-money-creation__header {
    padding-left: 20px;
    padding-right: 20px;
  }
}