.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.partnerPaymentsTable {
  margin-top: 12px;
  border-radius: 6px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: #fff;
}
.partnerPaymentsTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 10px;
  border-bottom: 1px solid #e4e8ee;
}
.partnerPaymentsTableHeader > div {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}
.partnerPaymentsTableHeader > div:nth-child(1) {
  flex: 1;
}
.partnerPaymentsTableHeader > div:nth-child(2) {
  flex: 4;
}
.partnerPaymentsTableHeader > div:nth-child(3) {
  flex: 2;
  text-align: center;
}
.partnerPaymentsTableHeader > div:nth-child(4) {
  flex: 2;
  text-align: right;
}
.partnerPaymentsTableContent {
  padding: 10px 0;
}
.partnerPaymentsTableRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.partnerPaymentsTableRow:hover {
  background-color: #f6f8fb;
}
.partnerPaymentsTableRowDate, .partnerPaymentsTableRowService, .partnerPaymentsTableRowTax, .partnerPaymentsTableRowSum {
  font-family: "Circe-Regular", sans-serif;
  line-height: 20px;
  font-size: 14px;
}
.partnerPaymentsTableRowDate {
  flex: 1;
  color: #b0b5c4;
}
.partnerPaymentsTableRowService {
  flex: 4;
  color: #616a88;
}
.partnerPaymentsTableRowTax {
  display: flex;
  justify-content: center;
  flex: 2;
  color: #b0b5c4;
}
.partnerPaymentsTableRowTaxMobile {
  display: none;
}
.partnerPaymentsTableRowTaxDesktopIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  opacity: 0.4;
  cursor: pointer;
  border-radius: 50%;
  background-color: #b0b5c4;
  transition: opacity 0.3s;
}
.partnerPaymentsTableRowTaxDesktopIcon > svg {
  width: 50%;
  height: auto;
}
.partnerPaymentsTableRowTaxDesktopIcon > svg path {
  stroke: #fff;
}
.partnerPaymentsTableRowTaxDesktopIcon:hover {
  opacity: 1;
}
.partnerPaymentsTableRowTaxDesktopIcon:hover > [data-tip] {
  opacity: 1;
}
.partnerPaymentsTableRowTaxDesktopIconTip {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  width: 280px;
  height: 80px;
  padding: 20px;
  opacity: 0;
  border-radius: 8px;
  background-color: #616a88;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  transition: opacity 0.3s;
}
.partnerPaymentsTableRowTaxMobileSum > span:last-child {
  margin-left: 4px;
}
.partnerPaymentsTableRowSum {
  flex: 2;
  text-align: right;
  color: #1d2e69;
}
.partnerPaymentsTableLoader {
  margin: 0 auto;
}

@media (max-width: 980px) {
  .partnerPaymentsTable {
    width: calc(100% + 40px);
    margin-top: 0;
    margin-left: -20px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .partnerPaymentsTableHeader {
    display: none;
  }
  .partnerPaymentsTableContent {
    padding: 0;
  }
  .partnerPaymentsTableRow {
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    border-bottom: 1px solid #f6f8fb;
  }
  .partnerPaymentsTableRowTaxDesktop {
    display: none;
  }
  .partnerPaymentsTableRowTaxMobile {
    display: initial;
  }
  .partnerPaymentsTableRowDate, .partnerPaymentsTableRowService, .partnerPaymentsTableRowTax, .partnerPaymentsTableRowSum {
    flex-basis: 50%;
  }
  .partnerPaymentsTableRowService {
    order: 1;
  }
  .partnerPaymentsTableRowSum {
    order: 2;
  }
  .partnerPaymentsTableRowDate {
    order: 3;
    margin-top: 2px;
  }
  .partnerPaymentsTableRowTax {
    order: 4;
    justify-content: flex-end;
    margin-top: 2px;
  }
}