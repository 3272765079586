.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.sz-status-card {
  border-radius: 6px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background: white;
  padding: 32px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 360px;
}
.sz-status-card__main {
  width: 100%;
}
.sz-status-card__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 11px;
}
.sz-status-card__service-title {
  font-size: 12px;
  color: var(--bluey-grey);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.sz-status-card__title {
  font-size: 24px;
  color: var(--dark-slate-blue);
  font-family: "Circe-Bold", sans-serif;
}
.sz-status-card__avatar-wrap {
  width: 70px;
  height: 70px;
  min-width: 70px;
  position: relative;
}
.sz-status-card__top-avatar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
.sz-status-card__top-avatar-badge {
  position: absolute;
  right: -12px;
  top: -10px;
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #3094ff 0%, #5ac8fa 100%);
}
.sz-status-card__top-avatar-badge-icon {
  width: 18px;
  height: 16px;
}
.sz-status-card__top-avatar-badge-icon path {
  fill: white;
}
.sz-status-card__img-wrap {
  width: 50px;
  height: 50px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sz-status-card__img-icon {
  width: 18px;
  height: 16px;
  fill: var(--deep-sky-blue);
}
.sz-status-card__text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-slate-blue);
  font-family: "Circe-Regular", sans-serif;
}
.sz-status-card__button {
  width: 100%;
}