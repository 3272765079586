.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.filters-bar {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.0688972);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
.filters-bar__search-wrap {
  margin-right: 20px;
  width: calc(100% - 140px - 245px);
  position: relative;
}
.filters-bar__search-input {
  width: 100%;
  height: 40px;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 40px;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
  color: var(--dark-slate-blue);
}
.filters-bar__search-input:focus {
  border-color: var(--dark-slate-blue);
}
.filters-bar__search-input::-webkit-input-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input::-moz-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input:-moz-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input:-ms-input-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input::-ms-input-placeholder {
  opacity: 1;
  color: #9da6ba;
}
.filters-bar__search-input::-ms-clear {
  display: none;
}
.filters-bar__search-input_filled {
  border-color: var(--dark-slate-blue);
  background: white;
}
.filters-bar__search-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.filters-bar__search-icon {
  width: 20px;
  height: 22px;
}
.filters-bar__clear-search-icon {
  width: 20px;
  height: 22px;
}
.filters-bar__date-wrap {
  margin-right: 20px;
  width: 31%;
  min-width: 245px;
  position: relative;
}
.filters-bar__date-input {
  width: 100%;
  height: 40px;
  background: #f6f8fb;
  border: 1px solid #e4e8ee;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  color: #9da6ba;
  font-size: 16px;
  font-family: "Circe-Regular", sans-serif;
}
.filters-bar__date-input:focus {
  background: white;
  border-color: var(--dark-slate-blue);
}
.filters-bar__date-input_filled {
  color: var(--dark-slate-blue);
  border-color: var(--dark-slate-blue);
  background: white;
}
.filters-bar__date-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 18px;
  top: 50%;
  margin-top: -8px;
  z-index: 30;
}
.filters-bar__date-icon path {
  fill: #616a88;
}
.filters-bar__date-clear {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 22px;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -11px;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filters-bar__date-clear-icon {
  width: 20px;
  height: 22px;
}
.filters-bar__buttons-wrap {
  display: flex;
}
.filters-bar__download {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fb;
  border-radius: 8px;
  margin-right: 20px;
}
.filters-bar__indicator {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fb;
  border-radius: 8px;
}
.filters-bar__indicator_chosen {
  background: var(--deep-sky-blue);
}
.filters-bar__indicator_chosen .filters-bar__indicator-icon path {
  fill: white;
}
.filters-bar__calendar-drop {
  position: absolute;
  z-index: 1;
  top: 72px;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1220px) {
  .filters-bar {
    flex-wrap: wrap;
  }
  .filters-bar__search-wrap {
    margin-right: 0;
    width: 100%;
    margin-bottom: 12px;
  }
  .filters-bar__date-wrap {
    margin-right: 12px;
    width: calc(100% - 40px - 40px - 12px - 12px);
  }
  .filters-bar__download {
    margin-right: 12px;
  }
  .filters-bar__calendar-drop {
    top: 120px;
  }
}
@media screen and (max-width: 480px) {
  .filters-bar {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
  .filters-bar__date-wrap {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .filters-bar__buttons-wrap {
    width: 100%;
    flex-direction: row-reverse;
  }
  .filters-bar__download {
    width: calc((100% - 12px) / 2);
    margin-right: 0;
  }
  .filters-bar__download-icon {
    margin-right: 14px;
  }
  .filters-bar__indicator {
    width: calc((100% - 12px) / 2);
    margin-right: 12px;
  }
  .filters-bar__indicator-icon {
    margin-right: 12px;
  }
  .filters-bar__button-text {
    color: #616a88;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
  }
  .filters-bar__calendar-drop {
    top: 100px;
  }
}