.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.standard-hover-button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  height: 60px;
  background: #ffffff;
  border: 1px solid var(--dark-slate-blue);
  border-radius: 8px;
  position: relative;
  display: block;
}
.standard-hover-button:disabled {
  cursor: not-allowed;
  border-color: var(--bluey-grey);
}
.standard-hover-button a {
  text-decoration: none;
}
.standard-hover-button__main {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
.standard-hover-button__icon {
  margin-right: 9px;
  width: 17px;
  height: 15px;
}
.standard-hover-button__icon path {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}
.standard-hover-button:hover:not(:disabled) .standard-hover-button__icon path {
  fill: white;
  stroke: white;
}
.standard-hover-button:disabled .standard-hover-button__icon path {
  fill: var(--bluey-grey);
  stroke: var(--bluey-grey);
}
.standard-hover-button__text {
  font-size: 16px;
  font-family: "Circe-Bold", sans-serif;
  color: var(--dark-slate-blue);
  transition: color 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}
.standard-hover-button:hover:not(:disabled) .standard-hover-button__text {
  color: white;
}
.standard-hover-button:disabled .standard-hover-button__text {
  color: var(--bluey-grey);
}
.standard-hover-button__hover {
  background: var(--dusk-blue);
  position: absolute;
  width: 240px;
  height: 60px;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
  border-radius: 8px;
  opacity: 0;
  transform: scale(0);
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -120px;
  transform-origin: center;
  z-index: 10;
}
.standard-hover-button:hover:not(:disabled) .standard-hover-button__hover {
  transform: scale(1);
  opacity: 1;
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
}