.animation-text-enter {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-active {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 0;
  transform: translateY(30px);
}
.animation-text-enter-done {
  transition: all 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.animation-text-exit {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 1;
  transform: translateY(-30px);
}
.animation-text-exit-active {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.68, 0.06);
  opacity: 0;
  transform: translateY(-30px);
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-400 {
  transition-delay: 400ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-600 {
  transition-delay: 600ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-800 {
  transition-delay: 800ms;
}

.delay-900 {
  transition-delay: 900ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.delay-1100 {
  transition-delay: 1100ms;
}

.delay-1200 {
  transition-delay: 1200ms;
}

.delay-1300 {
  transition-delay: 1300ms;
}

.delay-1400 {
  transition-delay: 1400ms;
}

.delay-1500 {
  transition-delay: 1500ms;
}

.delay-1600 {
  transition-delay: 1600ms;
}

.delay-1700 {
  transition-delay: 1700ms;
}

.delay-1800 {
  transition-delay: 1800ms;
}

.delay-1900 {
  transition-delay: 1900ms;
}

.delay-2000 {
  transition-delay: 2000ms;
}

.mobile-calendar-range-picker {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 23px 70px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}
.mobile-calendar-range-picker__calendar {
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(176, 181, 196, 0.1);
}
.mobile-calendar-range-picker__calendar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-calendar-range-picker__calendar-nav {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid rgba(176, 181, 196, 0.1);
  border-radius: 4px;
}
.mobile-calendar-range-picker__calendar-nav > button {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 10px;
}
.mobile-calendar-range-picker__calendar-nav > button > svg {
  width: 100%;
  height: 100%;
}
.mobile-calendar-range-picker__calendar-nav > button > svg path {
  stroke: #b0b5c4;
}
.mobile-calendar-range-picker__calendar-nav > span {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}
.mobile-calendar-range-picker__calendar-left-arrow {
  transform: rotate(180deg);
}
.mobile-calendar-range-picker__chosen-dates {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.mobile-calendar-range-picker__chosen-date {
  font-family: "Circe-Regular", sans-serif;
  width: 50%;
  padding-left: 13.33335px;
  opacity: 0.8;
  line-height: 20px;
  font-size: 14px;
  color: #b0b5c4;
}